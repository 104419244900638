import { HTMLAttributes, useEffect } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Route } from 'react-router-dom';
import styled from 'styled-components';

import { Flex, Pattern } from '../@DesignSystem';
import { theme } from '../@DesignSystem/@Style/theme';
import { Routes } from '../@Enums/routes';
import { Footer } from './components/Footer/Footer';
import { Characteristics } from './pages/Characteristics/Characteristitcs';
import { Details } from './pages/Details/Details';
import { Dishes } from './pages/Dishes/Dishes';
import { DishSelector } from './pages/DishSelector/DishSelector';
import { Home } from './pages/Home/Home';
import { Ingredients } from './pages/Ingredients/Ingredients';
import { Results } from './pages/Results/Results';
import { getCharacteristics, getIngredients, getSelectableDishes } from './store/api';
import { HarmonizerDispatch } from './store/store';

const Background = styled(Pattern)`
    mask-image: linear-gradient(to right bottom, transparent 35%, black 75%);
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    transform: scale(3);
    @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
        mask-image: linear-gradient(to right bottom, transparent 10%, black 75%);
        transform: scale(2);
    }
`;

export interface HarmonizerProps extends ReturnType<typeof mapDispatchToProps>, HTMLAttributes<HTMLDivElement> {}

export const HarmonizerPage = (props: HarmonizerProps) => {
    const { fetchSelectableDishes, fetchCharacteristics, fetchIngredients } = props;

    const backgroundColor = theme.colors.terciary[0] + '40';

    useEffect(() => {
        fetchCharacteristics();
        fetchIngredients();
        fetchSelectableDishes();
    });

    return (
        <Flex
            className={props.className}
            position="relative"
            flexDirection="column"
            minHeight="100vh"
            width="100%"
            overflow="hidden"
            bg={backgroundColor}
            minWidth={360}
        >
            <Background color={theme.colors.terciary[0]} />
            <Flex flex="1">
                <BrowserRouter>
                    <Route path={Routes.details} component={Details} />
                    <Route path={Routes.results} component={Results} />
                    <Route path={Routes.characteristics} component={Characteristics} />
                    <Route path={Routes.ingredients} component={Ingredients} />
                    <Route path={`${Routes.dishes}${Routes.selector}`} component={DishSelector} />
                    <Route exact path={Routes.dishes} component={Dishes} />

                    <Route exact path="/" component={Home} />
                </BrowserRouter>
            </Flex>
            <Footer />
        </Flex>
    );
};

const mapDispatchToProps = (dispatch: HarmonizerDispatch) => ({
    fetchIngredients: () => dispatch(getIngredients()),
    fetchCharacteristics: () => dispatch(getCharacteristics()),
    fetchSelectableDishes: () => dispatch(getSelectableDishes()),
});

export const Harmonizer = connect(null, mapDispatchToProps)(HarmonizerPage);
