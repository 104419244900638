import styled, { css } from 'styled-components';
import {
    border,
    BorderProps,
    color,
    flexbox,
    FlexboxProps,
    layout,
    LayoutProps,
    position,
    space,
    SpaceProps,
    typography,
    TypographyProps,
    variant,
} from 'styled-system';

import { ColorProps } from '../../@Style/colorFix';
import buttons from '../../@Style/variants/buttons';
import { RawButton, RawButtonProps } from './RawButton';

export interface ButtonProps
    extends RawButtonProps,
        SpaceProps,
        LayoutProps,
        BorderProps,
        ColorProps,
        TypographyProps,
        FlexboxProps {
    variant: keyof typeof buttons;
}

const buttonStyle = variant({ key: 'buttons' });

export const Button = styled(RawButton)<ButtonProps>`
    ${({ theme }) => css`
        position: relative;

        display: flex;
        justify-content: center;
        align-items: center;

        margin: ${theme.space[0]};
        padding: ${theme.space[3]}px ${theme.space[4]}px;

        height: ${theme.sizes[5]}px;
        min-width: ${theme.sizes[8]}px;

        border: 2px solid ${theme.colors.primary[0]};
        border-radius: ${theme.radii[2]}px;
        outline: none;

        font-family: ${theme.fonts.noto};
        font-size: ${theme.fontSizes[2]};
        line-height: 1;
        font-weight: ${theme.fontWeights[3]};

        &:focus-visible {
            box-shadow: 0 0 3px 2px ${theme.colors.black[3]};
        }
    `};

    ${({ theme, small }) =>
        small &&
        css`
            min-height: fit-content;
            min-width: fit-content;
            padding: ${theme.space[3]}px ${theme.space[3]}px;
            border-radius: ${theme.radii[2]}px;
        `};

    ${({ icon, theme, small }) =>
        icon &&
        css`
            justify-content: space-between;
            padding-right: ${theme.space[4]}px;
            padding-left: ${theme.space[4]}px;
            border-radius: ${theme.radii[4]}px;
            height: ${theme.sizes[3]}px;
            width: ${theme.sizes[3]}px;

            ${small &&
            css`
                padding-right: ${theme.space[3]}px;
            `}
        `};

    cursor: pointer;

    ${buttonStyle};

    ${space};
    ${color};
    ${typography};
    ${layout};
    ${border};
    ${position};
    ${flexbox};
`;
