import styled, { css } from 'styled-components';
import { space, SpaceProps, variant } from 'styled-system';

import tags from '../../@Style/variants/tags';
import { RawTag, RawTagProps } from './RawTag';

export interface TagProps extends RawTagProps, SpaceProps {
    variant: keyof typeof tags;
}

const tagStyle = variant({ key: 'tags' });

export const Tag = styled(RawTag)<TagProps>`
    @keyframes spin {
        100% {
            transform: rotate(360deg);
        }
    }

    .spin {
        animation: spin 1s linear infinite;
    }

    ${({ theme }) => css`
        position: relative;

        display: flex;
        justify-content: center;
        align-items: center;

        margin: ${theme.space[0]};

        height: fit-content;
        width: fit-content;

        border: 1px solid ${theme.colors.primary[0]};
        border-radius: ${theme.radii[4]}px;
    `};

    cursor: pointer;
    text-transform: capitalize;

    ${tagStyle};

    ${space};
`;
