import { useTranslation } from 'react-i18next';

import { Box, Flex, LightParagraph, Logo } from '../../../@DesignSystem';
import ShortLogo from '../../assets/logo/harmonizer-short.png';

export const Footer = () => {
    const { t } = useTranslation();
    return (
        <Box mt="auto">
            <Flex p={3} mt={5} width="100vw" alignItems="center">
                <Logo height={35} src={ShortLogo} />
                <LightParagraph ml={3} fontSize={1} textColor="black.0">
                    {t(`components.footer.copyright`)}
                </LightParagraph>
            </Flex>
        </Box>
    );
};
