import { BrowserRouter, Route } from 'react-router-dom';

import { Harmonizer } from './harmonizer/Harmonizer';

export const App = () => {
    return (
        <BrowserRouter>
            <Route path="/harmonizer" component={Harmonizer} />
            <Route path="/" component={Harmonizer} />
        </BrowserRouter>
    );
};
