import { HTMLAttributes } from 'react';

import { Box } from '../Box/Box';
import { Flex } from '../Flex/Flex';

export interface RawSliderProps extends HTMLAttributes<HTMLElement> {
    sliderText: [string, string];
    onToggle: (toggled: boolean) => void;
    toggled?: boolean;
}

export const RawSlider = (props: RawSliderProps) => {
    const { sliderText, className, toggled, onToggle } = props;

    const onClick = () => {
        onToggle(!toggled);
    };

    return (
        <Flex bg="primary.3" className={className} borderRadius={5} onClick={onClick}>
            <Flex
                data-testid="slider-perDish"
                p={3}
                flex="1"
                className={toggled ? 'sides toggled' : 'sides'}
                borderRadius={5}
                centerContent
            >
                {sliderText[0]}
            </Flex>
            <Flex
                data-testid="slider-general"
                p={3}
                flex="1"
                className={toggled ? 'sides' : 'sides toggled'}
                borderRadius={5}
                centerContent
            >
                {sliderText[1]}
            </Flex>
            <Box borderRadius={5} m={2} className={toggled ? 'slider toggled' : 'slider'} />
        </Flex>
    );
};
