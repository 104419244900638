import { InputHTMLAttributes } from 'react';

export interface RawRadioProps extends InputHTMLAttributes<HTMLInputElement> {}

export const RawRadio = (props: RawRadioProps) => {
    const { title, className, children, ...properties } = props;
    return (
        <label data-testid={`option-${title}`} className={className}>
            {children}
            <input type="radio" {...properties} />
            <span />
        </label>
    );
};
