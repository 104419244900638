import styled, { css } from 'styled-components';
import { layout, LayoutProps, PositionProps, space, SpaceProps } from 'styled-system';

import { RawModal, RawModalProps } from './RawModal';

export interface ModalProps extends RawModalProps, PositionProps, SpaceProps, LayoutProps {}

export const Modal = styled(RawModal)<ModalProps>`
    ${({ theme }) => css`
        box-shadow: 0px 4px 16px ${theme.colors.black[3]};
        border-radius: ${theme.radii[1]}px;
        background-color: ${theme.colors.white[0]};
    `};

    ${space}
    ${layout}
`;
