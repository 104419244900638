import { ChevronDown } from '../../@Assets/icons/commom/ChevronDown';
import { ChevronUp } from '../../@Assets/icons/commom/ChevronUp';
import { Edit } from '../../@Assets/icons/commom/Edit';
import { Menu } from '../../@Assets/icons/commom/Menu';
import { Plus } from '../../@Assets/icons/commom/Plus';
import { Remove } from '../../@Assets/icons/commom/Remove';
import { SpinnerIcon } from '../../@Assets/icons/commom/SpinnerIcon';
import { Star } from '../../@Assets/icons/commom/Star';
import { Trash } from '../../@Assets/icons/commom/Trash';
import { Base } from '../../@Assets/icons/harmonize/ingredients/Base';
import { Cheese } from '../../@Assets/icons/harmonize/ingredients/Cheese';
import { Condiments } from '../../@Assets/icons/harmonize/ingredients/Condiments';
import { Dessert } from '../../@Assets/icons/harmonize/ingredients/Dessert';
import { FruitsAndVegetables } from '../../@Assets/icons/harmonize/ingredients/FruitsAndVegetables';
import { Fungi } from '../../@Assets/icons/harmonize/ingredients/Fungi';
import { Meats } from '../../@Assets/icons/harmonize/ingredients/Meats';
import { Sausages } from '../../@Assets/icons/harmonize/ingredients/Sausages';
import { Seafood } from '../../@Assets/icons/harmonize/ingredients/Seafood';
import { Souces } from '../../@Assets/icons/harmonize/ingredients/Souce';
import { Veggie } from '../../@Assets/icons/harmonize/ingredients/Veggie';
import { Wines } from '../../@Assets/icons/harmonize/ingredients/Wines';
import { Plate } from '../../@Assets/icons/harmonize/Plate';
import { Bovine } from '../../@Assets/icons/harmonize/selectableDishes/Bovine';
import { Bread } from '../../@Assets/icons/harmonize/selectableDishes/Bread';
import { Chicken } from '../../@Assets/icons/harmonize/selectableDishes/Chicken';
import { MashPie } from '../../@Assets/icons/harmonize/selectableDishes/MashPie';
import { Other } from '../../@Assets/icons/harmonize/selectableDishes/Other';
import { Pasta } from '../../@Assets/icons/harmonize/selectableDishes/Pasta';
import { Pie } from '../../@Assets/icons/harmonize/selectableDishes/Pie';
import { Risotto } from '../../@Assets/icons/harmonize/selectableDishes/Risotto';
import { Seafoods } from '../../@Assets/icons/harmonize/selectableDishes/SeaFoods';
import { Soup } from '../../@Assets/icons/harmonize/selectableDishes/Soup';
import { IconProps } from './Icon';

export const icons = {
    base(props: IconProps) {
        return <Base {...props} />;
    },
    bovine(props: IconProps) {
        return <Bovine {...props} />;
    },
    bread(props: IconProps) {
        return <Bread {...props} />;
    },
    cheesesAndDairy(props: IconProps) {
        return <Cheese {...props} />;
    },
    chevronDown(props: IconProps) {
        return <ChevronDown {...props} />;
    },
    chevronUp(props: IconProps) {
        return <ChevronUp {...props} />;
    },
    chicken(props: IconProps) {
        return <Chicken {...props} />;
    },
    desserts(props: IconProps) {
        return <Dessert {...props} />;
    },
    edit(props: IconProps) {
        return <Edit {...props} />;
    },
    fungi(props: IconProps) {
        return <Fungi {...props} />;
    },
    mashPie(props: IconProps) {
        return <MashPie {...props} />;
    },
    meats(props: IconProps) {
        return <Meats {...props} />;
    },
    menu(props: IconProps) {
        return <Menu {...props} />;
    },
    other(props: IconProps) {
        return <Other {...props} />;
    },
    pasta(props: IconProps) {
        return <Pasta {...props} />;
    },
    pie(props: IconProps) {
        return <Pie {...props} />;
    },
    plate(props: IconProps) {
        return <Plate {...props} />;
    },
    plus(props: IconProps) {
        return <Plus {...props} />;
    },
    remove(props: IconProps) {
        return <Remove {...props} />;
    },
    risotto(props: IconProps) {
        return <Risotto {...props} />;
    },
    seafood(props: IconProps) {
        return <Seafood {...props} />;
    },
    seafoods(props: IconProps) {
        return <Seafoods {...props} />;
    },
    sauces(props: IconProps) {
        return <Souces {...props} />;
    },
    sausages(props: IconProps) {
        return <Sausages {...props} />;
    },
    soup(props: IconProps) {
        return <Soup {...props} />;
    },
    spinnerIcon(props: IconProps) {
        return <SpinnerIcon {...props} />;
    },
    star(props: IconProps) {
        return <Star {...props} />;
    },
    wines(props: IconProps) {
        return <Wines {...props} />;
    },
    condimentsAndSeasonings(props: IconProps) {
        return <Condiments {...props} />;
    },
    fruitsAndGreens(props: IconProps) {
        return <FruitsAndVegetables {...props} />;
    },
    veggie(props: IconProps) {
        return <Veggie {...props} />;
    },
    trash(props: IconProps) {
        return <Trash {...props} />;
    },
} as const;
