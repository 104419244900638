import styled, { css } from 'styled-components';
import { space, SpaceProps, typography, TypographyProps } from 'styled-system';

import { RawRadio, RawRadioProps } from './RawRadio';

export interface RadioProps extends SpaceProps, TypographyProps, RawRadioProps {}

export const Radio = styled(RawRadio)<RadioProps>`
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    user-select: none;
    padding: ${({ theme }) => theme.space[3]}px;
    font-size: ${({ theme }) => theme.fontSizes[2]}px;

    &:hover {
        color: ${({ theme }) => theme.colors.primary[0]};
        font-weight: bold;
        background-color: ${({ theme }) => theme.colors.primary[3]};
    }

    ${({ checked, theme }) =>
        checked &&
        css`
            font-weight: bold;
            color: ${theme.colors.primary[0]};
            background-color: ${({ theme }) => theme.colors.primary[4]};
        `};

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    span {
        margin-left: auto;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 20px;
        width: 20px;
        background-color: ${({ theme }) => theme.colors.primary[3]};
        border-radius: 50%;

        &:after {
            content: '';
            display: none;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background: ${({ theme }) => theme.colors.primary[0]};
        }
    }

    & input:checked ~ * {
    }

    & input:checked ~ span:after {
        display: block;
    }

    & input:checked ~ span {
        border: 1px solid ${({ theme }) => theme.colors.primary[0]};
    }

    ${space}
    ${typography}
`;

Radio.displayName = 'Radio';
