import { theme } from '../../../@Style/theme';
import { IconProps } from '../../../components/Icon/Icon';

export const Edit = (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.size || 16} height={props.size || 16} viewBox="0 0 24 24">
        <path
            fill={props.color || theme.colors.primary[0]}
            d="M14.06,9.02l.92.92L5.92,19H5v-.92l9.06-9.06M17.66,3a1,1,0,0,0-.7.29L15.13,5.12l3.75,3.75,1.83-1.83a1,1,0,0,0,0-1.41L18.37,3.29A.982.982,0,0,0,17.66,3Zm-3.6,3.19L3,17.25V21H6.75L17.81,9.94,14.06,6.19Z"
        />
    </svg>
);
