import './i18n';

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import GlobalStyle from './@DesignSystem/@Style/global';
import { theme } from './@DesignSystem/@Style/theme';
import { HarmonizeHelmet } from './@DesignSystem/components/HarmonizeHelmet/HarmonizeHelmet';
import { App } from './App';
import store from './harmonizer/store/store';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
    <Suspense fallback={null}>
        <React.StrictMode>
            <HelmetProvider>
                <Provider store={store}>
                    <ThemeProvider theme={theme}>
                        <HarmonizeHelmet />
                        <GlobalStyle />
                        <App />
                    </ThemeProvider>
                </Provider>
            </HelmetProvider>
        </React.StrictMode>
    </Suspense>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
