

export const palette = {
    primary: '#ac145a',
    secondary: '#00bfb2',
    terciary: '#f1c6a6',
    black: '#000000',
    white: '#ffffff'
} as const;

const { primary, secondary, terciary, black, white } = palette;

export const colors = {
    primary: [primary, `#e41b77`, `#eb5499`, `#f18dbb`, `#f8c6dd`],
    secondary: [secondary, `#00feed`, `#3ffff1`, `#7ffff6`, `#bffffa`],
    terciary: [terciary, `#f3d1b7`, `#f6dcc9`, `#f9e8db`, `#fcf3ed`],
    black: [black, `${black}BF`, `${black}80`, `${black}40`, `${black}20`],
    white: [white, `${white}BF`, `${white}80`, `${white}40`, `${white}20`],
};

export default colors;
