import { HTMLAttributes } from 'react';

import { Flex } from '../..';

export interface ActionBarProps extends HTMLAttributes<HTMLElement> {}

export const ActionBar = (props: ActionBarProps) => {
    return (
        <Flex
            m={2}
            p={3}
            height={60}
            width="calc(100% - 16px)"
            position="fixed"
            bottom={2}
            left={2}
            borderRadius={2}
            zIndex={50}
            alignItems="center"
            justifyContent="space-between"
            bg="white.0"
            shadow
        >
            {props.children}
        </Flex>
    );
};
