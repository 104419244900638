import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import { ActionBar, Button, Flex, HeavyParagraph, LightParagraph, Paragraph, Slider } from '../../../@DesignSystem';
import { Routes } from '../../../@Enums/routes';
import { StyleEntity } from '../../../@Typings';
import { Dish } from '../../components/Dish/Dish';
import { Header } from '../../components/Header/Header';
import { ScoreCard } from '../../components/ScoreCard/ScoreCard';
import { setStyle } from '../../store/actions';
import { HarmonizerState } from '../../store/reducer';
import { HarmonizerDispatch } from '../../store/store';

export interface ResultsComponentsProps
    extends RouteComponentProps,
        ReturnType<typeof mapStateToProps>,
        ReturnType<typeof mapDispatchToProps> {}

export const ResultsComponent = (props: ResultsComponentsProps) => {
    window.scrollTo(0, 0);

    const { t } = useTranslation();

    const { dishes, styles, history, onSelectStyle } = props;

    const [perDish, setPerDish] = useState(false);
    const [topStyles, setTopStyles] = useState(styles);
    const [otherStyles, setOtherStyles] = useState(styles);
    const [isShowingMore, setIsShowingMore] = useState(false);

    useEffect(() => {
        if (!dishes.length) {
            history.replace(Routes.dishes);
        } else {
            stylesHandler(styles);
        }
    }, [dishes, history]);

    const stylesHandler = (styles: StyleEntity[]) => {
        setTopStyles(styles.slice(0, 5));
        setOtherStyles(styles.slice(5));
    };

    const isShowingHandler = () => {
        setIsShowingMore(!isShowingMore);
    };

    const onToggle = (toggle: boolean) => {
        setPerDish(toggle);
    };

    const onSelect = (style: StyleEntity) => {
        onSelectStyle(style);
        history.push(Routes.details);
    };

    const onBack = () => {
        history.goBack();
    };

    return (
        <Flex flexDirection="column" flex="1" width="100%" position="relative" minWidth={360}>
            <Header>
                <HeavyParagraph textAlign="center" fontWeight={900} textColor="white.0">
                    {t('pages.results.header')}
                </HeavyParagraph>
            </Header>
            <Flex height="100%" width="100%" maxWidth={{ _: '100%', md: 800 }} m="auto" flexDirection="column" px={4}>
                <LightParagraph textAlign="center" m={5} fontSize={{ _: 2, md: 3 }}>
                    {t('pages.results.hint')}
                </LightParagraph>
                <Flex mx={6} mb={5} centerContent>
                    <Slider
                        toggled={perDish}
                        onToggle={onToggle}
                        sliderText={[t('pages.results.dishes'), t('pages.results.general')]}
                    />
                </Flex>
                {perDish &&
                    dishes.map((dish, index) => (
                        <Dish dishNumber={index + 1} key={`dish-${index}`} dish={dish} onSelectStyle={onSelect} />
                    ))}
                {!perDish && (
                    <Flex
                        height="100%"
                        maxWidth={{ _: '100%', md: 800 }}
                        m={{ _: 0, md: 'auto' }}
                        p={4}
                        flexDirection="column"
                        width="100%"
                        data-testid="general-harmonization"
                    >
                        {topStyles.map((style, index) => (
                            <ScoreCard
                                key={`style-${index}`}
                                style={style}
                                position={style.position}
                                width="auto"
                                onClick={onSelect}
                            />
                        ))}
                        {!isShowingMore ? (
                            <Flex mt={4} alignItems="center" width="100%" justifyContent="space-around">
                                <Button
                                    data-testid="show-more"
                                    ml={2}
                                    variant="link"
                                    p={2}
                                    m={0}
                                    height="100%"
                                    onClick={isShowingHandler}
                                >
                                    {t('components.buttons.showMore')}
                                </Button>
                            </Flex>
                        ) : (
                            <>
                                <Paragraph ml={2} mt={5} fontSize={20} fontWeight="bold" textColor="primary.0">
                                    {t('pages.results.others')}
                                </Paragraph>
                                {otherStyles.map((style, index) => (
                                    <ScoreCard
                                        key={`style-${index}`}
                                        style={style}
                                        position={style.position}
                                        width="auto"
                                        onClick={onSelect}
                                    />
                                ))}
                                <Flex mt={4} alignItems="center" width="100%" justifyContent="space-around">
                                    <Button
                                        data-testid="show-less"
                                        ml={2}
                                        variant="link"
                                        p={2}
                                        m={0}
                                        height="100%"
                                        onClick={isShowingHandler}
                                    >
                                        {t('components.buttons.showLess')}
                                    </Button>
                                </Flex>
                            </>
                        )}
                    </Flex>
                )}
            </Flex>
            <ActionBar>
                <Button data-testid="components-buttons-back-results" variant="secondary" onClick={onBack}>
                    {t('components.buttons.back')}
                </Button>
            </ActionBar>
        </Flex>
    );
};

const mapStateToProps = (state: HarmonizerState) => ({
    dishes: state.dishes,
    styles: state.styles,
});

const mapDispatchToProps = (dispatch: HarmonizerDispatch) => ({
    onSelectStyle: (style: StyleEntity) => dispatch(setStyle(style)),
});

export const Results = connect(mapStateToProps, mapDispatchToProps)(withRouter(ResultsComponent));
