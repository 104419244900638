import { lighten } from 'polished';

import colors from '../styles/colors';
import { space } from '../styles/layout';

const buttons = {
    primary: {
        color: colors.white[0],
        backgroundColor: colors.primary[0],
        '&:hover': {
            backgroundColor: lighten(0.1, colors.primary[0]),
            borderColor: lighten(0.1, colors.primary[0]),
        },
        '&:active': {
            backgroundColor: colors.primary[0],
            borderColor: colors.primary[0],
        },
        '&[disabled]': {
            color: colors.primary[0],
            backgroundColor: lighten(0.5, colors.primary[0]),
            cursor: 'not-allowed',
            borderColor: lighten(0.5, colors.primary[0]),
            path: {
                fill: colors.primary[0],
            },
            circle: {
                fill: colors.primary[0],
            },
        },
        path: {
            fill: colors.white[0],
        },
        circle: {
            fill: colors.white[0],
        },
    },
    secondary: {
        color: colors.primary[0],
        backgroundColor: colors.white[0],
        borderColor: colors.primary[0],
        '&:hover': {
            color: lighten(0.1, colors.primary[0]),
            borderColor: lighten(0.1, colors.primary[0]),
            path: {
                fill: lighten(0.1, colors.primary[0]),
            },
            circle: {
                fill: lighten(0.1, colors.primary[0]),
            },
        },
        '&:active': {
            color: colors.primary[0],
            borderColor: colors.primary[0],
            path: {
                fill: colors.primary[0],
            },
            circle: {
                fill: colors.primary[0],
            },
        },
        '&[disabled]': {
            cursor: 'not-allowed',
            borderColor: lighten(0.5, colors.primary[0]),
        },
        path: {
            fill: colors.primary[0],
        },
        circle: {
            fill: colors.primary[0],
        },
    },
    link: {
        color: colors.primary[0],
        backgroundColor: 'transparent',
        border: 'none',
        '&:hover': {
            backgroundColor: lighten(0.5, colors.primary[0]),
        },
        '&[disabled]': {
            cursor: 'not-allowed',
            color: colors.black[5],
            path: {
                fill: colors.black[5],
            },
            circle: {
                fill: colors.black[5],
            },
        },
        path: {
            fill: colors.primary[0],
        },
        circle: {
            fill: colors.primary[0],
        },
    },
    icon: {
        color: colors.primary[0],
        backgroundColor: 'transparent',
        border: 'none',
        padding: space[3],
        paddingLeft: 0,
        '&:hover': {
            backgroundColor: lighten(0.5, colors.primary[0]),
        },
        '&[disabled]': {
            cursor: 'not-allowed',
            color: colors.black[5],
            path: {
                fill: colors.black[5],
            },
            circle: {
                fill: colors.black[5],
            },
        },
        path: {
            fill: colors.primary[0],
        },
        circle: {
            fill: colors.primary[0],
        },
    },
};

export default buttons;
