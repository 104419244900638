import { theme } from '../../../../@Style/theme';
import { IconProps } from '../../../../components/Icon/Icon';

export const Seafoods = (props: IconProps) => {
    const svgProps = {
        fill: 'none',
        stroke: props.color || theme.colors.primary[0],
        strokeWidth: 17,
        strokeMiterlimit: 10,
    } as const;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={props.size || 36} viewBox="0 0 653.736 837.748">
            <g>
                <path
                    {...svgProps}
                    d="M392.974,539.384h-132.21c0,0-54.98-81.716-25.267-190.189s91.362-138.206,91.362-138.206
		s61.67,29.734,91.383,138.206C447.954,457.667,392.974,539.384,392.974,539.384z"
                />
                <polyline {...svgProps} points="260.763,539.384 268.513,592.937 284.155,701.123 	" />
                <polyline {...svgProps} points="369.562,701.123 369.582,701.123 385.224,592.937 392.974,539.384 	" />
                <path
                    {...svgProps}
                    d="M432.455,764.017c-21.433,35.954-60.691,60.018-105.597,60.018s-84.164-24.064-105.597-60.018l62.893-62.893
		h85.407L432.455,764.017z"
                />
                <line {...svgProps} x1="267.234" y1="584.097" x2="386.503" y2="584.097" />
                <line {...svgProps} x1="267.234" y1="628.81" x2="386.503" y2="628.81" />
                <path
                    {...svgProps}
                    d="M118.642,168.591c20.475,87.773,3.569,116.773-58.692,140.062c-22.738-32.772-36.055-72.56-36.055-115.447
		c0-77.556,43.528-144.929,107.465-179.035c3.282-1.751,6.859,1.857,5.082,5.124C124.382,41.457,101.672,95.834,118.642,168.591z"
                />
                <path {...svgProps} d="M59.95,308.653c36.606,52.778,97.623,87.345,166.736,87.345" />
                <g>
                    <path {...svgProps} d="M104.968,430.118c38.366,11.797,80.612,12.349,121.719-0.92" />
                    <path {...svgProps} d="M113.578,492.651c39.924,4.148,81.478-3.487,119.239-24.462" />
                    <path {...svgProps} d="M134.534,559.102c39.949-3.901,79.144-19.674,111.961-47.761" />
                </g>
                <path
                    {...svgProps}
                    d="M112.729,125.024l67.97-65.94c3.051-2.96,8.147-1.439,9.103,2.703c6.427,27.826,17.993,112.843-62.916,166.33"
                />
                <path
                    {...svgProps}
                    d="M535.094,168.591c-20.475,87.773-3.569,116.773,58.692,140.062c22.738-32.772,36.055-72.56,36.055-115.447
		c0-77.556-43.528-144.929-107.465-179.035c-3.282-1.751-6.859,1.857-5.082,5.124C529.354,41.457,552.065,95.834,535.094,168.591z"
                />
                <path {...svgProps} d="M427.05,395.998c69.113,0,130.13-34.567,166.736-87.345" />
                <path
                    {...svgProps}
                    d="M541.008,125.024l-67.97-65.94c-3.051-2.96-8.147-1.439-9.103,2.703
		c-6.427,27.826-17.993,112.843,62.916,166.33"
                />
                <ellipse {...svgProps} cx="247.186" cy="259.107" rx="13.577" ry="37.086" />
                <ellipse {...svgProps} cx="406.551" cy="259.107" rx="13.577" ry="37.086" />
                <g>
                    <path {...svgProps} d="M423.199,429.198c41.107,13.269,83.353,12.717,121.719,0.92" />
                    <path {...svgProps} d="M417.068,468.19c37.762,20.975,79.315,28.61,119.239,24.462" />
                    <path {...svgProps} d="M403.39,511.34c32.817,28.087,72.012,43.86,111.961,47.761" />
                </g>
            </g>
        </svg>
    );
};
