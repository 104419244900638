import { theme } from '../../../../@Style/theme';
import { IconProps } from '../../../../components/Icon/Icon';

export const Sausages = (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.size || 35.831} viewBox="0 0 35.831 22.837">
        <g transform="translate(-10.169 -14.234)">
            <g transform="translate(17.884 21.588)">
                <path
                    fill={props.color || theme.colors.primary[0]}
                    d="M265.768-613.169,264.4-613.3l.027-.584a7.7,7.7,0,0,1,1.422.131Z"
                    transform="translate(-264.4 613.88)"
                />
            </g>
            <g transform="translate(10.169 21.639)">
                <path
                    fill={props.color || theme.colors.primary[0]}
                    d="M93.952-597.264a7.75,7.75,0,0,1-7.742-7.742,7.736,7.736,0,0,1,6.835-7.688.294.294,0,0,1,.325.257.293.293,0,0,1-.257.325,7.151,7.151,0,0,0-6.317,7.107,7.164,7.164,0,0,0,7.156,7.156,7.12,7.12,0,0,0,1.3-.119,7.158,7.158,0,0,0,5.755-5.849.292.292,0,0,1,.337-.241.293.293,0,0,1,.241.337,7.746,7.746,0,0,1-6.227,6.328A7.644,7.644,0,0,1,93.952-597.264Z"
                    transform="translate(-86.21 612.696)"
                />
            </g>
            <g transform="translate(19.215 21.715)">
                <path
                    fill={props.color || theme.colors.primary[0]}
                    d="M295.2-595.719l-.08-.58a7.158,7.158,0,0,0,5.754-5.849,7.213,7.213,0,0,0,.1-1.188,7.151,7.151,0,0,0-5.842-7.035l.08-.58,14.357,1.324a6.235,6.235,0,0,1,1.7.237,6.309,6.309,0,0,1,4.581,6.054,6.3,6.3,0,0,1-6.276,6.291C308.922-596.979,295.339-595.732,295.2-595.719Zm2.3-14.432a7.744,7.744,0,0,1,4.062,6.816,7.814,7.814,0,0,1-.107,1.284,7.749,7.749,0,0,1-3.965,5.534c4.977-.458,11.871-1.092,12.033-1.111l.035,0a5.711,5.711,0,0,0,5.7-5.705,5.721,5.721,0,0,0-4.154-5.49,5.646,5.646,0,0,0-1.55-.215h-.027Z"
                    transform="translate(-295.12 610.95)"
                />
            </g>
            <g transform="translate(17.618 21.588)">
                <path
                    fill={props.color || theme.colors.primary[0]}
                    d="M259.921-613.188h-.027l-1.368-.126a.293.293,0,0,1-.265-.319.292.292,0,0,1,.319-.265l1.368.126a.292.292,0,0,1,.265.319A.293.293,0,0,1,259.921-613.188Z"
                    transform="translate(-258.26 613.9)"
                />
            </g>
            <g transform="translate(17.618 36.361)">
                <path
                    fill={props.color || theme.colors.primary[0]}
                    d="M258.544-271.957a.293.293,0,0,1-.291-.266.293.293,0,0,1,.265-.318l1.356-.125a.294.294,0,0,1,.319.265.293.293,0,0,1-.265.319l-1.356.125Z"
                    transform="translate(-258.251 272.667)"
                />
            </g>
            <g transform="translate(39.619 25.629)">
                <path
                    fill={props.color || theme.colors.primary[0]}
                    d="M770.719-513.482a4.338,4.338,0,0,1-4.239-3.2c-.016-.052-.028-.1-.038-.137l-.022-.083.009-.061c0-.032.011-.07.02-.111a4.391,4.391,0,0,1,1.956-3.043,4.817,4.817,0,0,1,4.17.083l.227.089-.046.239a4.724,4.724,0,0,1-1.606,2.6,4.758,4.758,0,0,1,1.61,3.053l.024.248-.241.064A7.089,7.089,0,0,1,770.719-513.482Zm-3.7-3.45c.007.024.014.052.023.082.249.846,1.342,3.478,5.094,2.607a3.84,3.84,0,0,0-1.652-2.6l-.435-.244.425-.261a4.036,4.036,0,0,0,1.643-2.231,4.106,4.106,0,0,0-3.427-.026,3.816,3.816,0,0,0-1.665,2.641C767.022-516.953,767.019-516.942,767.018-516.932Z"
                    transform="translate(-766.42 520.55)"
                />
            </g>
            <g transform="translate(16.693 14.235)">
                <path
                    fill={props.color || theme.colors.primary[0]}
                    d="M245.469-767.111a.313.313,0,0,1-.048,0,.294.294,0,0,1-.241-.337,7.2,7.2,0,0,0,.1-1.187,7.151,7.151,0,0,0-5.828-7.032l-1.341-.124a7.147,7.147,0,0,0-.825.049l-.289.034-.036-.288a6.851,6.851,0,0,1,1.308-4.961,6.84,6.84,0,0,1,4.523-2.7,6.839,6.839,0,0,1,5.107,1.289l5.286,3.935a6.881,6.881,0,0,1,2.592,3.96.293.293,0,0,1-.219.352.293.293,0,0,1-.352-.219,6.293,6.293,0,0,0-2.372-3.622l-5.286-3.935a6.253,6.253,0,0,0-4.672-1.179,6.255,6.255,0,0,0-4.138,2.469,6.266,6.266,0,0,0-1.226,4.255c.2-.016.409-.024.611-.024h.027l1.394.13a7.736,7.736,0,0,1,6.321,7.611,7.813,7.813,0,0,1-.106,1.284A.293.293,0,0,1,245.469-767.111Z"
                    transform="translate(-236.905 783.735)"
                />
            </g>
        </g>
    </svg>
);
