import { theme } from '../../../@Style/theme';
import { IconProps } from '../../../components/Icon/Icon';

export const Remove = (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.size || 16} height={props.size || 16} viewBox="0 0 16 16">
        <path
            fill={props.color || theme.colors.primary[0]}
            d="M4.467 3.053l3.535 3.535 3.536-3.535c.39-.39 1.024-.39 1.414 0 .39.39.39 1.024 0 1.414L9.417 8.002l3.535 3.536c.39.39.39 1.024 0 1.414-.39.39-1.023.39-1.414 0L8.002 9.417l-3.535 3.535c-.39.39-1.024.39-1.414 0-.39-.39-.39-1.023 0-1.414l3.535-3.536-3.535-3.535c-.39-.39-.39-1.024 0-1.414.39-.39 1.024-.39 1.414 0z"
        />
    </svg>
);
