import { theme } from '../../../../@Style/theme';
import { IconProps } from '../../../../components/Icon/Icon';

export const MashPie = (props: IconProps) => {
    const svgProps = {
        fill: 'none',
        stroke: props.color || theme.colors.primary[0],
        strokeWidth: 17,
        strokeLinecap: 'round',
        strokeMiterlimit: 10,
    } as const;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={props.size || 36} viewBox="0 0 710.811 647.436">
            <g>
                <g>
                    <path
                        {...svgProps}
                        d="M641.236,314.027H67.6c-27.878,0-50.477-22.599-50.477-50.477v-7.556c0-27.878,22.599-50.477,50.477-50.477
			h573.636c27.878,0,50.477,22.599,50.477,50.477v7.556C691.714,291.428,669.114,314.027,641.236,314.027z"
                    />
                    <path
                        {...svgProps}
                        d="M641.236,628.133H67.6c-27.878,0-50.477-22.599-50.477-50.477V570.1c0-27.878,22.599-50.477,50.477-50.477
			h573.636c27.878,0,50.477,22.599,50.477,50.477v7.556C691.714,605.534,669.114,628.133,641.236,628.133z"
                    />
                    <path
                        {...svgProps}
                        d="M685.509,437.749l-12.649,2.454c-3.675,0.713-6.077,4.271-5.364,7.946l2.057,10.609
			c1.005,5.181-4.034,9.472-8.989,7.657l-23.917-8.765c-3.125-1.145-6.62,0.148-8.247,3.051l-9.737,17.373
			c-2.079,3.71-7.02,4.612-10.276,1.876l-17.537-14.736c-2.522-2.119-6.201-2.119-8.723,0l-19.695,16.549
			c-2.523,2.12-6.206,2.119-8.727-0.004l-21.546-18.135c-2.522-2.123-6.205-2.124-8.729-0.003l-20.03,16.838
			c-2.522,2.12-6.204,2.12-8.726,0l-20.324-17.088c-2.523-2.122-6.207-2.121-8.73,0.003l-19.989,16.828
			c-2.522,2.123-6.206,2.124-8.73,0.003l-18.47-15.53c-2.524-2.122-6.208-2.121-8.731,0.004l-18.976,15.982
			c-2.523,2.125-6.211,2.125-8.734,0l-21.078-17.748c-2.522-2.124-6.207-2.125-8.73-0.003l-21.118,17.757
			c-2.524,2.122-6.209,2.121-8.731-0.004l-18.729-15.775c-2.521-2.124-6.204-2.126-8.728-0.006l-17.544,14.737
			c-2.524,2.12-6.208,2.118-8.729-0.006l-17.172-14.468c-2.523-2.125-6.209-2.127-8.733-0.003l-17.502,14.725
			c-2.523,2.123-6.208,2.123-8.731-0.001l-16.249-13.677c-2.521-2.122-6.202-2.124-8.726-0.005l-15.657,13.147
			c-2.523,2.118-6.203,2.117-8.724-0.004l-15.618-13.136c-2.523-2.122-6.207-2.122-8.73,0.001l-12.501,10.52
			c-2.524,2.124-6.21,2.123-8.733-0.002l-12.951-10.909c-2.601-2.191-6.424-2.116-8.937,0.176l-13.572,12.38
			c-2.572,2.346-6.504,2.363-9.096,0.038L65.72,468.301c-1.813-1.626-4.36-2.155-6.67-1.385l-12.745,4.248
			c-4.096,1.365-8.407-1.395-8.882-5.685l-0.889-8.026c-0.337-3.041-2.669-5.479-5.691-5.952l-8.098-1.266
			c-4.437-0.694-6.986-5.431-5.119-9.516l7.306-15.99c1.022-2.237,0.753-4.852-0.705-6.834l-10.955-14.898
			c-3.059-4.16-0.531-10.076,4.59-10.74l11.832-1.535c3.531-0.458,6.106-3.57,5.896-7.125l-0.576-9.719
			c-0.31-5.226,5.169-8.817,9.837-6.447l13.798,7.005c2.495,1.267,5.513,0.87,7.596-0.998l10.735-9.628
			c2.59-2.323,6.518-2.308,9.091,0.034l13.578,12.36c2.511,2.286,6.326,2.361,8.926,0.177l12.958-10.89
			c2.523-2.12,6.206-2.119,8.727,0.003l12.501,10.521c2.524,2.125,6.212,2.123,8.735-0.003l15.612-13.158
			c2.523-2.126,6.21-2.128,8.734-0.004l15.651,13.168c2.523,2.123,6.208,2.123,8.731-0.001l16.244-13.674
			c2.523-2.124,6.208-2.124,8.731-0.001l17.502,14.725c2.524,2.124,6.211,2.122,8.733-0.003l17.172-14.468
			c2.521-2.124,6.205-2.127,8.729-0.007l17.544,14.737c2.524,2.12,6.207,2.118,8.728-0.006l18.729-15.775
			c2.522-2.124,6.207-2.126,8.731-0.004l21.118,17.757c2.524,2.122,6.208,2.121,8.73-0.003l21.078-17.749
			c2.524-2.125,6.211-2.125,8.734,0l18.976,15.982c2.522,2.124,6.207,2.126,8.731,0.004l18.47-15.53
			c2.523-2.122,6.207-2.121,8.73,0.003l19.989,16.828c2.522,2.123,6.206,2.124,8.73,0.003l20.324-17.088
			c2.522-2.121,6.204-2.121,8.726,0l20.03,16.838c2.523,2.121,6.207,2.12,8.729-0.003l21.546-18.135
			c2.522-2.122,6.204-2.124,8.727-0.004l19.695,16.549c2.522,2.119,6.201,2.119,8.723,0l17.541-14.74
			c3.254-2.735,8.193-1.835,10.274,1.872l9.736,17.347c1.627,2.899,5.117,4.191,8.239,3.05l23.931-8.745
			c4.954-1.81,9.987,2.481,8.983,7.659l-2.06,10.622c-0.713,3.676,1.689,7.233,5.364,7.946l12.649,2.454
			c5.463,1.06,7.393,7.887,3.293,11.651l0,0c-2.927,2.687-2.927,7.303,0,9.99v0C692.902,429.862,690.972,436.689,685.509,437.749z"
                    />
                </g>
                <g>
                    <path
                        {...svgProps}
                        d="M377.37,72.365c0,21.534-8.873,41.005-23.152,54.964c-14.278-13.959-23.152-33.43-23.152-54.964
			c0-21.556,8.873-41.027,23.152-54.965C368.497,31.339,377.37,50.809,377.37,72.365z"
                    />
                    <polyline
                        {...svgProps}
                        points="354.223,201.066 354.223,196.788 354.223,185 354.223,127.333 354.223,56.217 		"
                    />
                    <path
                        {...svgProps}
                        d="M302.961,101.017c15.227,15.227,22.721,35.27,22.495,55.237c-19.967,0.226-40.009-7.268-55.236-22.495
			c-15.243-15.243-22.736-35.285-22.495-55.237C267.676,78.281,287.718,85.774,302.961,101.017z"
                    />
                    <polyline {...svgProps} points="354.218,184.997 325.449,156.248 325.449,156.227 275.187,105.965 		" />
                </g>
            </g>
        </svg>
    );
};
