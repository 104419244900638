export interface PaternProps {
    color: string;
    className?: string;
}

export const Pattern = (props: PaternProps) => (
    <svg
        className={props.className}
        version="1.1"
        id="Camada_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 2000 2000"
    >
        <style type="text/css">{`.st0{fill:${props.color};}`}</style>
        <g>
            <g>
                <g>
                    <g>
                        <circle className="st0" cx="299.67" cy="315.12" r="4.61" />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M306.44,294.67c0.17,0.52,1.79,5.47-1.01,8.07c-2.6,2.41-7.32,1.2-9.79-0.86c-1.51-1.26-1.27-2.08-2.88-4.47
					c-3.59-5.31-7.5-5.22-7.78-7.78c-0.44-3.98,8.47-9.09,10.66-7.35c1.47,1.17-0.79,4.87,1.15,6.63c1.55,1.4,3.66-0.33,6.34,1.15
					C305.33,291.29,306.12,293.71,306.44,294.67z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M289.87,311.09c0.13,1.37-0.3,2.5-1.15,4.75c-1.22,3.2-1.83,4.8-3.02,5.04c-2.15,0.43-5.53-3.7-4.75-6.19
					c0.54-1.74,2.72-1.42,3.89-3.6c1.42-2.65-0.53-5.46,0.29-5.91C286.02,304.69,289.53,307.53,289.87,311.09z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M371.35,296.56c-0.7,0-1.4-0.07-2.1-0.22c-2.68-0.55-4.98-2.12-6.49-4.41l-77.85-118.48c-0.04-0.06-0.08-0.12-0.11-0.18
					c-6-11.09-5.25-24.42,1.96-34.8c0.25-0.36,0.51-0.71,0.77-1.06c0.18-0.24,0.19-0.56,0.03-0.81l-36.2-55.09
					c-0.34-0.52-0.46-1.14-0.34-1.76s0.48-1.14,1-1.48l13.98-9.19c0.52-0.34,1.15-0.46,1.76-0.34c0.61,0.13,1.14,0.48,1.48,1
					l36.2,55.09c0.16,0.25,0.46,0.37,0.75,0.3c0.42-0.1,0.85-0.2,1.28-0.28c12.38-2.51,24.92,2.09,32.72,12.01
					c0.05,0.06,0.09,0.12,0.13,0.18l77.85,118.47c3.1,4.72,1.79,11.09-2.94,14.19l-38.28,25.16
					C375.26,295.98,373.32,296.56,371.35,296.56z M267.29,70.34c-0.14,0-0.27,0.04-0.39,0.12l-13.98,9.19
					c-0.16,0.1-0.27,0.26-0.3,0.45c-0.04,0.18,0,0.37,0.1,0.53l36.2,55.09c0.54,0.82,0.51,1.9-0.09,2.69
					c-0.25,0.33-0.49,0.67-0.73,1.01c-6.86,9.87-7.57,22.55-1.87,33.09c0.01,0.02,0.02,0.04,0.04,0.06l77.86,118.48
					c1.26,1.92,3.2,3.24,5.45,3.71c2.25,0.47,4.55,0.03,6.48-1.24l38.28-25.16c1.92-1.26,3.24-3.2,3.7-5.45
					c0.47-2.25,0.03-4.55-1.24-6.48l-77.86-118.48c-0.01-0.02-0.03-0.04-0.04-0.06c-7.42-9.42-19.34-13.8-31.11-11.42
					c-0.41,0.08-0.81,0.17-1.22,0.27c-0.95,0.23-1.96-0.16-2.5-0.99l-36.2-55.09c-0.1-0.16-0.26-0.27-0.45-0.3
					C267.38,70.34,267.34,70.34,267.29,70.34z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M367.76,291.11c-1.03,0-2.07-0.16-3.09-0.48l0.49-1.56c2.48,0.78,5.15,0.41,7.32-1.02l15.3-10.05l0.9,1.36l-15.3,10.05
					C371.68,290.54,369.73,291.11,367.76,291.11z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M365.21,287.23c-0.7,0-1.4-0.07-2.1-0.22l0.33-1.6c2.26,0.47,4.55,0.03,6.48-1.24l3.89-2.56l0.89,1.36l-3.89,2.56
					C369.13,286.65,367.19,287.23,365.21,287.23z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M333.6,143.47c-5.36-6.84-14.34-10.15-22.87-8.42l-0.32-1.6c9.27-1.88,18.65,1.58,24.48,9.01L333.6,143.47z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M247.44,81.99c-0.27,0-0.54-0.03-0.81-0.08c-1.03-0.21-1.92-0.82-2.5-1.7l-4.89-7.45c-1.2-1.82-0.69-4.27,1.13-5.47
					l20.18-13.26c0.88-0.58,1.94-0.78,2.97-0.57c1.03,0.21,1.92,0.82,2.5,1.7l4.89,7.45c1.2,1.82,0.69,4.27-1.13,5.47L249.6,81.34
					C248.95,81.77,248.2,81.99,247.44,81.99z M262.71,55.01c-0.45,0-0.89,0.13-1.27,0.38l-20.18,13.26
					c-1.07,0.7-1.37,2.14-0.66,3.21l4.89,7.45c0.34,0.52,0.86,0.87,1.47,1c0.61,0.13,1.23,0.01,1.74-0.33l20.18-13.26
					c1.07-0.7,1.37-2.14,0.66-3.21l-4.89-7.45c-0.34-0.52-0.86-0.87-1.47-1C263.03,55.03,262.87,55.01,262.71,55.01z"
                        />
                    </g>
                </g>
                <path
                    className="st0"
                    d="M315.03,216.33l29.26,44.53c13.36-5.83,33.07-16.53,52.58-34.78l-37.36-56.85c-19.48,18.17-39.15,28.9-52.58,34.78
			l4.04,6.15c5.98-2.93,12.02-6.19,17.97-9.72l0.83,1.4c-5.93,3.51-11.94,6.76-17.9,9.69l2.25,3.42c5.97-2.92,12.02-6.19,17.97-9.72
			c0.1-0.06,10.3-6.16,12.26-7.81l1.05,1.25c-2.06,1.73-12.05,7.71-12.47,7.96C327,210.16,320.98,213.41,315.03,216.33z"
                />
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="255.94"
                            y="72.12"
                            transform="matrix(0.8357 -0.5492 0.5492 0.8357 -2.4855 159.7821)"
                            width="19.74"
                            height="23.84"
                        />
                    </g>
                    <g>
                        <path
                            className="st0"
                            d="M263.87,100.56l-13.99-21.29l17.86-11.74l13.99,21.29L263.87,100.56z M252.14,79.74l12.2,18.57l15.14-9.95l-12.2-18.57
					L252.14,79.74z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M103.74,249.57c-1.5-0.45-3.08-0.69-4.73-0.69c-9.07,0-16.42,7.35-16.42,16.42c0,2.49,0.55,4.85,1.55,6.96l-0.92,1.06
					l-18.05,3.26l10.56-16.9c0,0-26.12,2.3-26.69,2.3c-0.58,0,13.06-15.75,13.06-15.75l-19.39-21.32l25.16,6.34V208.6l20.16,20.36
					l9.03-17.48L103.74,249.57z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M115.13,352.25c-9.5,0-17.24-7.73-17.24-17.24c0-2.45,0.5-4.81,1.49-7.02l0.24-0.53l0.58,0.05
						c0.45,0.04,0.92,0.06,1.39,0.06c6.15,0,11.75-3.63,14.26-9.25l0.24-0.53l0.58,0.05c8.81,0.77,15.7,8.31,15.7,17.17
						C132.37,344.52,124.64,352.25,115.13,352.25z M100.66,329.18c-0.75,1.85-1.13,3.81-1.13,5.84c0,8.6,7,15.61,15.61,15.61
						s15.61-7,15.61-15.61c0-7.83-5.95-14.52-13.65-15.48c-2.88,5.89-8.9,9.67-15.49,9.67C101.28,329.2,100.97,329.19,100.66,329.18
						z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M100.06,329.13c-8.81-0.77-15.7-8.31-15.7-17.17c0-9.5,7.73-17.24,17.24-17.24s17.24,7.73,17.24,17.24
						c0,2.44-0.5,4.81-1.49,7.02l-1.49-0.66c0.9-2.01,1.35-4.15,1.35-6.36c0-8.6-7-15.61-15.61-15.61s-15.61,7-15.61,15.61
						c0,8.02,6.24,14.84,14.22,15.54L100.06,329.13z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M130.98,331.22h-0.08v-1.63h0.08c8.6,0,15.61-7,15.61-15.61c0-3.75-1.35-7.38-3.81-10.22
						c-2.97-3.42-7.27-5.39-11.8-5.39c-0.19,0-0.38,0-0.57,0.01l-0.06-1.63c0.21-0.01,0.42-0.01,0.63-0.01c5,0,9.75,2.17,13.03,5.95
						c2.71,3.13,4.21,7.14,4.21,11.29C148.22,323.49,140.48,331.22,130.98,331.22z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M117.51,308.3l-0.22-1.61c7.67-1.06,13.45-7.7,13.45-15.46c0-8.6-7-15.61-15.61-15.61s-15.61,7-15.61,15.61
						c0,1.46,0.2,2.89,0.59,4.25l-1.57,0.45c-0.43-1.51-0.65-3.09-0.65-4.7c0-9.5,7.73-17.24,17.24-17.24s17.24,7.73,17.24,17.24
						C132.37,299.79,125.98,307.13,117.51,308.3z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M86.68,305.59c-8.19-1.49-14.13-8.62-14.13-16.95c0-9.5,7.73-17.24,17.23-17.24c5.82,0,11.21,2.91,14.41,7.78
						l-1.36,0.9c-2.9-4.41-7.78-7.05-13.05-7.05c-8.6,0-15.6,7-15.6,15.61c0,7.55,5.38,14,12.79,15.35L86.68,305.59z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M143.48,304.04l-0.17-1.62c7.94-0.84,13.94-7.52,13.94-15.52c0-8.6-7-15.61-15.61-15.61c-6.03,0-11.41,3.37-14.04,8.8
						l-1.47-0.71c2.87-5.9,8.95-9.71,15.51-9.71c9.5,0,17.24,7.73,17.24,17.24C158.87,295.74,152.25,303.11,143.48,304.04z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M140.76,270.96l-1.41-0.81c1.34-2.34,2.05-5.02,2.05-7.74c0-8.6-7-15.61-15.61-15.61c-5.7,0-10.94,3.1-13.69,8.1
						l-1.43-0.78c3.03-5.52,8.82-8.95,15.11-8.95c9.5,0,17.24,7.73,17.24,17.24C143.03,265.42,142.25,268.37,140.76,270.96z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M112.86,275.55l-1.31-0.97c0.41-0.56,0.8-1.16,1.14-1.78c1.25-2.29,1.92-4.88,1.92-7.5c0-3.77-1.36-7.4-3.83-10.24
						c-1.94-2.23-4.46-3.86-7.28-4.7c-1.44-0.44-2.96-0.66-4.5-0.66c-8.6,0-15.61,7-15.61,15.61c0,2.32,0.49,4.54,1.47,6.61
						c0.12,0.27,0.25,0.52,0.38,0.77l-1.43,0.78c-0.15-0.28-0.3-0.57-0.43-0.86c-1.07-2.28-1.62-4.74-1.62-7.3
						c0-9.5,7.73-17.24,17.24-17.24c1.7,0,3.37,0.24,4.97,0.73c3.11,0.93,5.89,2.73,8.04,5.2c2.73,3.14,4.23,7.15,4.23,11.31
						c0,2.89-0.73,5.76-2.12,8.29C113.74,274.27,113.32,274.93,112.86,275.55z"
                            />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M112.69,252.48c-2.1-4.93-2.4-8.9-2.28-11.64c0.22-5.05,1.91-6.82,2.86-14.58c0.43-3.53,0.71-5.81,0.29-8.93
					c-0.39-2.91-1.04-7.73-4.9-11.81c-4.24-4.49-9.68-5.35-11.63-5.51c-3.92-0.32-7.09,0.32-7.09,0.32
					c21.03,0.58,23.33,19.3,19.3,30.82c-4.03,11.52,1.15,22.76,1.15,22.76l1.01,0.6l1.59-2.19"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M111.68,226.26c0,0,9.36-3.02,8.79-12.96c-0.58-9.94-3.17-13.25-3.17-13.25s3.89,3.6,4.61,7.06
					c0.72,3.46,2.59,11.52-2.45,15.99c-5.04,4.47-7.49,6.34-8.21,9.22L111.68,226.26z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="87.27" y="319.17" width="15.15" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="90.45" y="323.2" width="11.97" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="84.94" y="310.91" width="17.48" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="85.62" y="314.94" width="16.8" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="87.91" y="302.08" width="14.5" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="85.96" y="306.11" width="16.45" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <rect className="st0" x="75.55" y="295.55" width="12.22" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="78.73" y="299.58" width="5.44" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="73.22" y="287.29" width="17.48" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="73.9" y="291.32" width="16.8" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="76.2" y="278.46" width="7.97" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="74.25" y="282.49" width="13.38" height="1.63" />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <rect className="st0" x="81.17" y="266.88" width="12.22" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="78.84" y="258.63" width="17.48" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="79.52" y="262.66" width="16.8" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="79.87" y="253.82" width="13.38" height="1.63" />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="98.72" y="336.79" width="15.99" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="99.72" y="340.82" width="11.52" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <polygon
                            className="st0"
                            points="174.22,219.87 158.64,186.41 147.23,210.39 133.39,163.26 134.95,162.8 147.59,205.84 158.66,182.58 
					174.19,215.95 185.2,191.44 215.97,192.27 197.86,169.71 217.85,151.87 176.9,156.36 186.9,134.43 136.77,160.03 136.03,158.58 
					190.32,130.85 179.57,154.43 222.74,149.7 200.1,169.89 219.44,194 186.25,193.1 				"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M136.38,159.33l-2.8,1.26l0.58,2.44c-1.7-1.27-4.33-2.9-8.36-4.8c-9.79-4.61-5.57-14.98-5.57-14.98s0.24,2.64,1.08,5.45
					c1.18,3.95,4.07,5.97,4.68,6.46c1.85,1.47,3.54,2.08,5.76,2.88c1.73,0.62,3.31,1.01,4.64,1.26L136.38,159.33z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M79.27,180.12c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42S82.26,180.12,79.27,180.12z
					 M79.27,170.91c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79c2.09,0,3.79-1.7,3.79-3.79
					C83.07,172.61,81.36,170.91,79.27,170.91z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M38.37,297.95c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
					C43.79,295.51,41.36,297.95,38.37,297.95z M38.37,288.73c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79
					c2.09,0,3.79-1.7,3.79-3.79S40.46,288.73,38.37,288.73z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M164.54,266.26c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42s5.42,2.43,5.42,5.42
					C169.96,263.82,167.53,266.26,164.54,266.26z M164.54,257.04c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
					s3.79-1.7,3.79-3.79C168.33,258.74,166.63,257.04,164.54,257.04z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M162.24,330.5c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
					C167.66,328.06,165.23,330.5,162.24,330.5z M162.24,321.28c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
					c2.09,0,3.79-1.7,3.79-3.79C166.03,322.98,164.33,321.28,162.24,321.28z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <polygon
                            className="st0"
                            points="217.41,193.9 139.05,163.36 133.32,161.35 133.86,159.81 139.62,161.83 218,192.38 				"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <polygon
                            className="st0"
                            points="170.21,187.63 167.82,173.5 180.79,167.49 181.48,168.97 169.64,174.46 171.82,187.36 				"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <polygon
                            className="st0"
                            points="149.93,179.84 148.3,179.67 149.66,166.67 157.95,159.24 159.04,160.46 151.22,167.46 				"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M300.62,332.29l-3.25,13.48c-0.08,0.38-0.16,0.76-0.25,1.14c-3.67,15.22-18.99,24.59-34.21,20.92
					c-15.23-3.67-24.59-18.99-20.92-34.22c0.1-0.4,0.2-0.8,0.32-1.2l3.23-13.4c0,0,8.64,15.52,20.14,15.33
					S285.43,318.56,300.62,332.29z"
                        />
                    </g>
                    <g>
                        <path
                            className="st0"
                            d="M269.55,369.44c-2.26,0-4.55-0.26-6.84-0.82c-7.58-1.83-13.99-6.49-18.05-13.14c-4.07-6.65-5.3-14.48-3.47-22.06
					c0.1-0.42,0.21-0.83,0.33-1.23l3.71-15.4l1.02,1.82c0.08,0.15,8.44,14.91,19.22,14.91c0.06,0,0.13,0,0.19,0
					c3.74-0.06,7.12-1.83,10.69-3.71c7.07-3.71,14.39-7.55,24.81,1.88l0.36,0.32l-3.36,13.95c-0.07,0.35-0.15,0.75-0.25,1.14
					C294.7,360.45,282.72,369.44,269.55,369.44z M245.86,321.12l-2.77,11.49c-0.12,0.42-0.22,0.81-0.32,1.2
					c-1.72,7.15-0.56,14.55,3.28,20.82c3.84,6.28,9.89,10.68,17.04,12.41c14.76,3.56,29.67-5.56,33.23-20.32
					c0.09-0.37,0.17-0.74,0.24-1.11l3.14-13.03c-9.38-8.25-15.8-4.88-22.6-1.32c-3.59,1.88-7.3,3.83-11.42,3.9
					C256.06,335.3,248.54,325.24,245.86,321.12z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M264.26,324.94c-11.6,0-16.45-14.38-16.5-14.53l-0.07-0.22l6.6-27.39l1.58,0.38l-6.49,26.93
					c0.65,1.78,5.59,14.19,16.17,13.13l0.16,1.62C265.22,324.91,264.73,324.94,264.26,324.94z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M264.82,308.38c-0.1-0.01-10.25-0.71-13.69-12l1.56-0.47c3.09,10.17,11.85,10.82,12.22,10.84L264.82,308.38z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M249.58,450.64l-1.62-0.14c2.67-30.48,10.63-55.61,15.14-67.87c0.43-1.17,1.59-1.93,2.85-1.84
						c17.88,1.31,33.91-10.41,38.12-27.87c0.12-0.49,0.23-0.98,0.32-1.47l16.13-66.92c0.07-0.31,0.02-0.63-0.14-0.9
						c-0.17-0.27-0.43-0.46-0.74-0.54l-68.64-16.55c-0.31-0.07-0.63-0.02-0.9,0.14c-0.27,0.17-0.46,0.43-0.54,0.74l-16.1,66.79
						c-0.15,0.53-0.29,1.03-0.42,1.58c-4.21,17.45,4.72,35.19,21.23,42.18c1.15,0.49,1.85,1.7,1.7,2.94
						c-1.58,12.97-5.94,38.97-17.45,67.32l-1.51-0.61c11.44-28.18,15.78-54.01,17.34-66.9c0.06-0.53-0.22-1.03-0.72-1.24
						c-17.25-7.3-26.57-25.83-22.17-44.06c0.14-0.57,0.28-1.1,0.43-1.62L248,267.04c0.18-0.73,0.63-1.35,1.27-1.74
						c0.64-0.39,1.4-0.51,2.13-0.34l68.64,16.55c0.73,0.18,1.35,0.63,1.74,1.27c0.39,0.64,0.51,1.4,0.34,2.13l-16.13,66.88
						c-0.09,0.48-0.2,0.99-0.33,1.5c-4.4,18.23-21.15,30.48-39.82,29.11c-0.53-0.04-1.02,0.28-1.2,0.77
						C260.15,395.38,252.24,420.35,249.58,450.64z"
                            />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M268.92,468.25c-8.69,0-18.95-1.34-29.48-3.88c-12.57-3.03-24.03-7.41-32.27-12.33c-8.5-5.08-12.62-10.23-11.59-14.49
					c1.96-8.14,22.46-10.17,47.68-4.7l-0.34,1.59c-23.53-5.1-44.06-3.53-45.75,3.49c-0.84,3.46,3.12,8.1,10.84,12.71
					c8.1,4.84,19.4,9.15,31.81,12.15c12.41,2.99,24.44,4.3,33.85,3.69c8.98-0.59,14.61-2.91,15.44-6.37
					c1.68-6.96-15.71-17.67-38.76-23.86l0.42-1.57c11.65,3.13,22.14,7.41,29.52,12.06c7.79,4.9,11.39,9.66,10.4,13.76
					c-1.03,4.27-7.04,6.97-16.92,7.62C272.22,468.2,270.6,468.25,268.92,468.25z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="211.51"
                            y="425.16"
                            transform="matrix(0.2344 -0.9721 0.9721 0.2344 -266.7192 544.4933)"
                            width="1.63"
                            height="32.84"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="207.37"
                            y="436.05"
                            transform="matrix(0.2344 -0.9721 0.9721 0.2344 -274.3961 544.0052)"
                            width="1.63"
                            height="20.31"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="211"
                            y="425.3"
                            transform="matrix(0.2344 -0.9721 0.9721 0.2344 -260.3662 538.684)"
                            width="1.63"
                            height="18.68"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M416.53,156.09c-0.93,0.73-2.11,1.15-3.27,1.23l-0.57,0.05c-0.17,0.02-0.35,0.03-0.52,0.04c-0.35,0.02-0.7,0.02-1.05,0
						l-4.19-0.39l-2.09-0.22l-0.26-0.03c-0.08-0.01-0.2-0.02-0.22-0.03c-0.1-0.02-0.2-0.03-0.3-0.03c-0.21-0.01-0.42,0.02-0.63,0.08
						c-0.21,0.06-0.42,0.16-0.62,0.29c-0.1,0.07-0.19,0.14-0.28,0.22l-0.13,0.14l-0.17,0.2l-1.34,1.63
						c-0.91,1.08-1.8,2.17-2.73,3.23c0.49-1.32,1.02-2.63,1.53-3.94l0.79-1.96l0.1-0.25c0.04-0.09,0.1-0.2,0.15-0.3
						c0.11-0.2,0.24-0.4,0.38-0.58c0.28-0.38,0.63-0.71,1.03-0.99c0.39-0.28,0.84-0.5,1.31-0.65c0.24-0.07,0.48-0.13,0.73-0.17
						l0.55-0.09l2.08-0.35l4.15-0.66c0.35-0.05,0.69-0.07,1.04-0.07c0.17,0,0.35,0,0.52,0l0.26,0.01l0.13,0l0.06,0l0.02-0.01
						c0.23,0.01,0.41-0.07,0.58-0.19c0.16-0.13,0.29-0.32,0.36-0.53c0.06-0.21,0.05-0.41,0-0.61c0-0.05-0.05-0.1-0.06-0.16
						c0,0-0.02-0.05-0.04-0.08l-0.06-0.12l-0.12-0.24l-0.47-0.93l-0.94-1.86l-1.88-3.72l-3.76-7.45l-0.12-0.23
						c-0.04-0.08-0.07-0.13-0.14-0.29c-0.11-0.27-0.24-0.53-0.31-0.82c-0.09-0.28-0.16-0.57-0.19-0.87
						c-0.05-0.29-0.06-0.59-0.06-0.89c0.01-1.19,0.41-2.35,1.08-3.3c0.67-0.96,1.66-1.71,2.78-2.11c0.56-0.21,1.14-0.31,1.72-0.34
						l1.07-0.04l8.34-0.27l4.17-0.13l2.08-0.06c0.06,0,0.05,0,0.07-0.01l0.04-0.01l0.08,0c0.05-0.03,0.11-0.02,0.15-0.04
						c0.04-0.02,0.1-0.03,0.14-0.06c0.02-0.01,0.05-0.02,0.07-0.03c0.02-0.02,0.04-0.04,0.06-0.05c0.17-0.11,0.31-0.31,0.38-0.51
						c0.08-0.21,0.08-0.41,0.02-0.61c-0.01-0.05-0.03-0.1-0.06-0.15c0.01-0.01-0.11-0.23-0.18-0.37l-0.47-0.93l-0.94-1.86
						l-3.76-7.45l-1.88-3.72l-0.24-0.47l-0.06-0.12l-0.03-0.06l-0.04-0.1l-0.17-0.4c-0.12-0.26-0.18-0.56-0.26-0.84
						c-0.06-0.29-0.1-0.59-0.13-0.88c-0.07-1.19,0.22-2.38,0.82-3.39c0.61-1,1.52-1.83,2.6-2.32c0.55-0.23,1.12-0.4,1.7-0.47
						l0.44-0.04c0.08-0.01,0.12-0.01,0.16-0.01l0.13,0l0.52-0.02l4.17-0.13l1.04-0.03l0.52-0.02l0.12,0l0.04,0
						c0.03,0,0.05-0.01,0.08-0.01c0.21-0.03,0.41-0.13,0.56-0.27c0.04-0.03,0.07-0.08,0.11-0.12l0.05-0.07
						c0.02-0.03,0.01-0.01,0.07-0.11l3.73-6.28c0.35-0.6,0.72-1.19,1.05-1.8c0.17-0.3,0.32-0.62,0.49-0.92
						c0.18-0.3,0.37-0.59,0.58-0.87c0.69-0.88,1.38-1.77,2.07-2.65c0.11-0.15,0.23-0.29,0.34-0.44c0.12-0.15,0.23-0.29,0.35-0.44
						l3.13,1.86c-0.07,0.17-0.14,0.34-0.22,0.51c-0.07,0.17-0.14,0.34-0.22,0.51c-0.44,1.03-0.89,2.06-1.34,3.09
						c-0.15,0.32-0.3,0.63-0.49,0.93c-0.18,0.3-0.38,0.58-0.57,0.87c-0.37,0.59-0.72,1.19-1.07,1.79l-3.73,6.28
						c-0.03,0.05-0.13,0.22-0.21,0.33c-0.08,0.12-0.17,0.24-0.26,0.36c-0.18,0.23-0.38,0.45-0.6,0.65
						c-0.86,0.81-1.97,1.34-3.13,1.52c-0.15,0.02-0.29,0.04-0.44,0.05l-0.22,0.02l-0.14,0.01l-0.52,0.02l-1.04,0.03l-4.17,0.13
						l-0.52,0.02l-0.13,0c-0.04,0-0.09,0-0.09,0.01c-0.03,0-0.05,0.01-0.08,0.01c-0.11,0-0.21,0.04-0.31,0.08
						c-0.18,0.09-0.34,0.23-0.46,0.42c-0.11,0.19-0.16,0.41-0.15,0.61c0.01,0.05,0.01,0.1,0.02,0.15c0.03,0.05,0.02,0.1,0.05,0.15
						c0.01,0.02,0.02,0.05,0.03,0.08l0.01,0.02l0.03,0.05l0.06,0.12l0.23,0.47l1.88,3.73l3.76,7.45l0.94,1.86l0.47,0.93
						c0.08,0.17,0.14,0.25,0.27,0.57c0.12,0.27,0.23,0.54,0.3,0.82c0.32,1.14,0.28,2.39-0.12,3.49c-0.39,1.11-1.11,2.09-2.07,2.78
						l-0.37,0.25c-0.13,0.08-0.26,0.15-0.39,0.21c-0.26,0.15-0.54,0.25-0.82,0.35s-0.57,0.15-0.86,0.22
						c-0.14,0.02-0.29,0.04-0.43,0.05l-0.22,0.02c-0.07,0-0.16,0.01-0.19,0.01l-2.09,0.07l-4.17,0.13l-8.35,0.26l-1.02,0.03
						c-0.12,0.02-0.22,0.02-0.32,0.06c-0.18,0.07-0.37,0.18-0.49,0.37c-0.12,0.18-0.21,0.39-0.2,0.6c0,0.05,0,0.1,0.01,0.15
						c0,0.05,0.02,0.1,0.03,0.15c0,0.06,0.05,0.1,0.06,0.16c0,0,0.05,0.11,0.09,0.18l0.12,0.23l3.76,7.45l1.88,3.72l0.94,1.86
						l0.47,0.93l0.18,0.35c0.02,0.04,0.03,0.07,0.07,0.15c0.11,0.27,0.24,0.53,0.32,0.82c0.36,1.12,0.36,2.38,0,3.5
						C418.15,154.36,417.46,155.36,416.53,156.09z"
                            />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M479.04,100.64c-2.05,0-4.07-0.55-5.88-1.62c-5.49-3.29-7.28-10.38-4.04-15.84c3.24-5.46,10.32-7.28,15.79-4.07
					c2.7,1.6,4.58,4.12,5.35,7.11c0.76,2.99,0.31,6.1-1.26,8.76l0,0c-1.58,2.65-4.09,4.54-7.08,5.3
					C480.96,100.52,480,100.64,479.04,100.64z M479.07,79.17c-3.4,0-6.7,1.74-8.55,4.85c-2.78,4.69-1.24,10.78,3.43,13.58
					c2.32,1.38,4.99,1.76,7.56,1.11c2.57-0.65,4.73-2.27,6.08-4.55l0,0c1.35-2.28,1.74-4.95,1.08-7.52s-2.27-4.73-4.55-6.08
					C482.54,79.61,480.79,79.17,479.07,79.17z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M473.12,98.99l-46.79-27.78c-2.65-1.58-4.54-4.09-5.3-7.08c-0.76-2.99-0.32-6.1,1.26-8.75c1.58-2.66,4.09-4.54,7.08-5.3
					c2.99-0.76,6.1-0.31,8.76,1.26l46.79,27.78l-0.83,1.4c-4.7-2.76-10.78-1.19-13.56,3.49c-2.78,4.69-1.24,10.78,3.43,13.58
					L473.12,98.99z M432.24,51.34c-0.82,0-1.65,0.1-2.47,0.31c-2.57,0.65-4.73,2.27-6.08,4.55c-1.35,2.28-1.74,4.95-1.08,7.52
					s2.27,4.73,4.55,6.08l41.76,24.79c-1.87-3.46-1.94-7.8,0.21-11.42c2.15-3.62,5.99-5.64,9.93-5.65l-41.76-24.79
					C435.74,51.82,434,51.34,432.24,51.34z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M473.11,98.99c-5.44-3.26-7.23-10.35-3.99-15.81c3.24-5.46,10.32-7.28,15.79-4.07l-0.83,1.4
					c-4.7-2.76-10.78-1.19-13.56,3.49c-2.78,4.69-1.24,10.78,3.43,13.58L473.11,98.99z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="437.78"
                            y="82.14"
                            transform="matrix(0.8599 0.5105 -0.5105 0.8599 107.3356 -214.6422)"
                            width="13.77"
                            height="12.12"
                        />
                    </g>
                    <g>
                        <path
                            className="st0"
                            d="M447.78,98.05l-13.24-7.86l7.02-11.82l13.24,7.86L447.78,98.05z M436.77,89.61l10.44,6.2l5.36-9.02l-10.44-6.2
					L436.77,89.61z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M91.31,131.22c-47.23-24.74-57.82-87.48-59.47-99.81l1.62-0.22c1.62,12.19,12.09,74.21,58.61,98.58L91.31,131.22z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M32.89,32.32l-0.43-0.2c-0.13-0.06-13.34-6.55-4.37-28.18l0.51-1.23l0.86,1.02c0.4,0.47,9.69,11.53,8.51,20.73
					c-0.4,3.14-1.99,5.7-4.7,7.59L32.89,32.32z M29.08,5.86c-6.62,17.21,1.63,23.36,3.66,24.56c2.08-1.59,3.29-3.66,3.62-6.17
					C37.25,17.34,31.35,8.83,29.08,5.86z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M38.01,42.89c-1.2,0-2.42-0.2-3.67-0.61l-0.45-0.15l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.41L55,20.93l-0.29,1.3
					c-0.14,0.6-3.41,14.67-11.48,19.25C41.57,42.42,39.83,42.89,38.01,42.89z M35.32,40.87c2.52,0.71,4.91,0.43,7.11-0.81
					c6.07-3.44,9.33-13.27,10.34-16.86C35.03,28.24,35.07,38.52,35.32,40.87z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M41.94,58.28c-1.2,0-2.42-0.2-3.67-0.61l-0.45-0.15l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.41l1.29-0.33l-0.29,1.3
					c-0.14,0.6-3.41,14.67-11.48,19.25C45.51,57.81,43.76,58.28,41.94,58.28z M39.26,56.26c2.52,0.71,4.91,0.43,7.11-0.81
					c6.07-3.44,9.33-13.27,10.34-16.86C38.96,43.63,39,53.92,39.26,56.26z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M47.74,74.76c-1.2,0-2.42-0.2-3.67-0.61L43.62,74l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.41l1.29-0.33l-0.29,1.3
					c-0.14,0.6-3.41,14.67-11.48,19.25C51.3,74.29,49.55,74.76,47.74,74.76z M45.05,72.74c2.52,0.71,4.91,0.43,7.11-0.81
					c6.07-3.44,9.33-13.27,10.34-16.86C44.75,60.11,44.79,70.4,45.05,72.74z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M55.47,90.98c-1.2,0-2.42-0.2-3.67-0.61l-0.45-0.15l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.4l1.29-0.33l-0.29,1.3
					c-0.14,0.6-3.41,14.67-11.48,19.24C59.04,90.5,57.29,90.98,55.47,90.98z M52.79,88.96c2.52,0.71,4.91,0.44,7.11-0.81
					c6.07-3.43,9.33-13.26,10.34-16.85C52.49,76.33,52.53,86.61,52.79,88.96z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M64.79,105.65c-1.2,0-2.42-0.2-3.67-0.61l-0.45-0.15l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.4l1.29-0.33l-0.29,1.3
					c-0.14,0.6-3.41,14.67-11.48,19.24C68.35,105.18,66.6,105.65,64.79,105.65z M62.11,103.63c2.52,0.7,4.9,0.43,7.1-0.81
					c6.07-3.44,9.33-13.26,10.34-16.85C61.98,90.96,61.89,101.33,62.11,103.63z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M27.66,49.05c-0.12,0-0.24,0-0.37-0.01C18.03,48.76,8.57,37.83,8.17,37.37L7.3,36.35l1.3-0.31
					c22.78-5.44,27.09,8.63,27.13,8.77l0.13,0.45l-0.33,0.34C33.32,47.89,30.67,49.05,27.66,49.05z M10.34,37.32
					c2.57,2.7,10.04,9.88,17.01,10.09c2.54,0.08,4.77-0.8,6.67-2.59C33.14,42.63,28.39,33.5,10.34,37.32z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M33.32,68.35c-0.12,0-0.24,0-0.37-0.01c-9.27-0.28-18.73-11.21-19.12-11.68l-0.87-1.01l1.3-0.31
					c22.78-5.44,27.09,8.62,27.13,8.77l0.13,0.45L41.2,64.9C38.99,67.19,36.34,68.35,33.32,68.35z M16.01,56.62
					c2.57,2.7,10.04,9.88,17.01,10.09c2.54,0.07,4.77-0.8,6.67-2.59C38.81,61.93,34.05,52.81,16.01,56.62z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M40.25,85.7c-0.12,0-0.24,0-0.37-0.01c-9.27-0.28-18.73-11.21-19.12-11.68L19.89,73l1.3-0.31
					c22.78-5.44,27.09,8.62,27.13,8.77l0.13,0.45l-0.33,0.34C45.91,84.54,43.26,85.7,40.25,85.7z M22.93,73.97
					c2.57,2.7,10.04,9.88,17.01,10.09c2.52,0.07,4.77-0.8,6.66-2.59C45.73,79.28,40.97,70.16,22.93,73.97z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M49.31,104.29c-8.92,0-18.77-8.75-19.2-9.14l-0.99-0.89l1.25-0.48c21.87-8.36,27.99,5.01,28.05,5.15l0.19,0.43
					l-0.28,0.38c-1.97,2.66-4.57,4.17-7.72,4.49C50.16,104.27,49.74,104.29,49.31,104.29z M32.25,94.82
					c2.9,2.34,11.24,8.49,18.18,7.79c2.52-0.25,4.62-1.41,6.27-3.44C55.55,97.1,49.65,88.68,32.25,94.82z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M57.09,120.86c-7.57,0-15.58-3.74-16.01-3.94l-1.21-0.57l1.06-0.81c18.57-14.27,28.25-3.19,28.35-3.08l0.3,0.36
					l-0.16,0.44c-1.13,3.11-3.19,5.3-6.12,6.51C61.4,120.55,59.26,120.86,57.09,120.86z M43.04,115.99
					c3.45,1.42,13.2,4.92,19.65,2.27c2.34-0.96,4.03-2.67,5.02-5.09C66.03,111.52,57.96,105.14,43.04,115.99z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M401.46,453c-1.53-6.09-3.51-12.35-5.89-18.6c-5.16-13.58-4.97-25.87,0.57-36.53c11-21.18,39.75-28.72,40.04-28.79
					l0.4,1.58c-0.28,0.07-28.34,7.44-39,27.97c-5.31,10.23-5.47,22.07-0.48,35.19c2.4,6.31,4.4,12.63,5.94,18.78L401.46,453z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M442.9,373.36c-2.86,0-5.41-1.07-7.61-3.19l-0.34-0.33l0.12-0.46c0.04-0.14,3.89-14.34,26.83-9.65l1.31,0.27l-0.83,1.04
					c-0.38,0.48-9.48,11.71-18.73,12.3C443.4,373.35,443.15,373.36,442.9,373.36z M436.8,369.33c1.96,1.74,4.22,2.54,6.75,2.37
					c6.96-0.44,14.19-7.86,16.67-10.64C442.05,357.84,437.59,367.11,436.8,369.33z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M424.46,374.83l-0.32-0.35c-0.1-0.11-9.83-11.14,6.71-27.71l0.94-0.94l0.41,1.27c0.19,0.58,4.56,14.36-0.05,22.41
					c-1.57,2.75-4.01,4.51-7.24,5.23L424.46,374.83z M431.04,348.92c-12.69,13.39-7.4,22.21-5.99,24.1c2.53-0.68,4.44-2.13,5.7-4.32
					C434.21,362.65,432,352.53,431.04,348.92z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M409.13,383.79l-0.36-0.31c-0.11-0.1-11.03-9.96,3.54-28.3l0.83-1.04l0.55,1.21c0.25,0.56,6.15,13.75,2.49,22.27
					c-1.25,2.91-3.47,4.93-6.61,6.01L409.13,383.79z M412.74,357.3c-10.98,14.6-4.78,22.91-3.22,24.62
					c2.43-0.96,4.17-2.62,5.17-4.94C417.44,370.57,414.1,360.78,412.74,357.3z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M399.17,395.42l-0.46-0.12c-0.14-0.04-14.29-4.09-9.27-26.97l0.28-1.3l1.03,0.85c0.47,0.39,11.58,9.64,12.03,18.9
					c0.16,3.16-0.95,5.96-3.29,8.31L399.17,395.42z M390.75,370.05c-3.48,18.11,5.73,22.7,7.94,23.53c1.76-1.93,2.59-4.19,2.47-6.71
					C400.82,379.9,393.5,372.57,390.75,370.05z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M392.38,405.32c-4.26,0-13.93-2.03-19.08-19.28l-0.38-1.28l1.31,0.24c0.6,0.11,14.8,2.82,19.69,10.7
					c1.67,2.69,2.06,5.67,1.15,8.86l-0.13,0.45l-0.46,0.11C394.45,405.14,393.65,405.32,392.38,405.32z M375.27,386.9
					c5.74,17.53,16.02,17.08,18.35,16.73c0.61-2.54,0.24-4.92-1.09-7.07C388.85,390.64,378.9,387.77,375.27,386.9z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M387.92,422.51c-4.94,0-12.63-2.44-19.68-14.88l-0.66-1.16l1.33-0.06c0.61-0.03,15.05-0.56,21.58,6.03
					c2.23,2.25,3.27,5.07,3.1,8.38l-0.02,0.47l-0.42,0.21C393.1,421.52,391.06,422.51,387.92,422.51z M370.35,408.03
					c9.51,15.8,19.43,13.06,21.63,12.2c0.02-2.61-0.87-4.85-2.65-6.64C384.43,408.63,374.08,408.07,370.35,408.03z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M433.95,388.39c-4.18,0-8.84-0.66-12.23-2.98c-2.61-1.79-4.17-4.36-4.62-7.64l-0.06-0.47l0.37-0.29
					c0.12-0.09,11.9-8.89,27.08,8.94l0.86,1.02l-1.3,0.31C443.72,387.35,439.21,388.39,433.95,388.39z M418.8,378.04
					c0.47,2.57,1.76,4.59,3.85,6.02c5.76,3.94,16.01,2.56,19.68,1.89C430.13,372.36,420.78,376.83,418.8,378.04z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M414.74,397.59c-2.26,0-4.48-0.32-6.46-1.16c-2.92-1.24-4.95-3.45-6.05-6.57l-0.16-0.44l0.31-0.36
					c0.1-0.11,9.9-11.08,28.31,3.39l1.05,0.82l-1.21,0.56C430.11,394.02,422.24,397.59,414.74,397.59z M403.95,389.78
					c0.97,2.43,2.64,4.16,4.97,5.15c6.42,2.72,16.2-0.67,19.67-2.05C413.79,381.86,405.65,388.15,403.95,389.78z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M405.33,415.98c-2.26,0-4.48-0.32-6.46-1.16c-2.92-1.24-4.95-3.45-6.05-6.57l-0.16-0.44l0.31-0.36
					c0.1-0.11,9.9-11.08,28.31,3.39l1.05,0.82l-1.21,0.56C420.7,412.41,412.82,415.98,405.33,415.98z M394.54,408.17
					c0.97,2.43,2.64,4.16,4.97,5.15c6.42,2.72,16.2-0.67,19.67-2.05C404.38,400.25,396.24,406.54,394.54,408.17z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M401.75,433.19c-2.75,0-5.24-1.01-7.41-3.02l-0.35-0.32l0.1-0.46c0.03-0.14,3.56-14.43,26.6-10.28l1.31,0.24l-0.81,1.06
					c-0.37,0.49-9.2,11.93-18.44,12.73C402.42,433.18,402.08,433.19,401.75,433.19z M395.82,429.31c1.99,1.69,4.28,2.43,6.79,2.21
					c6.94-0.6,14-8.19,16.41-11.03C400.97,417.73,396.58,427.13,395.82,429.31z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M154.34,408.38c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C159.76,405.95,157.33,408.38,154.34,408.38z M154.34,399.16c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79C158.13,400.86,156.43,399.16,154.34,399.16z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M137.76,461.21c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C143.18,458.77,140.75,461.21,137.76,461.21z M137.76,451.99c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79C141.55,453.69,139.85,451.99,137.76,451.99z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M350.13,364.69c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42s5.42,2.43,5.42,5.42
				C355.55,362.26,353.12,364.69,350.13,364.69z M350.13,355.47c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79C353.92,357.18,352.22,355.47,350.13,355.47z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M454.72,302.83c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C460.14,300.4,457.71,302.83,454.72,302.83z M454.72,293.61c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79C458.51,295.31,456.81,293.61,454.72,293.61z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M257.65,229.7c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42S260.64,229.7,257.65,229.7z
				 M257.65,220.48c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79c2.09,0,3.79-1.7,3.79-3.79
				C261.45,222.19,259.75,220.48,257.65,220.48z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M202.17,113.42c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				S205.16,113.42,202.17,113.42z M202.17,104.2c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79c2.09,0,3.79-1.7,3.79-3.79
				S204.26,104.2,202.17,104.2z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M310.87,30.93c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42s5.42,2.43,5.42,5.42S313.86,30.93,310.87,30.93z
				 M310.87,21.72c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79c2.09,0,3.79-1.7,3.79-3.79S312.96,21.72,310.87,21.72z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M130.74,44.54c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C136.16,42.11,133.73,44.54,130.74,44.54z M130.74,35.32c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79C134.53,37.02,132.83,35.32,130.74,35.32z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M452.81,462.91c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42s5.42,2.43,5.42,5.42
				C458.23,460.47,455.8,462.91,452.81,462.91z M452.81,453.69c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79C456.6,455.39,454.9,453.69,452.81,453.69z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M344.39,465.46c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C349.81,463.03,347.38,465.46,344.39,465.46z M344.39,456.24c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79C348.18,457.94,346.48,456.24,344.39,456.24z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M120.24,453.39l-6.88,3.15l-0.3-0.92c-2.45-5.35-8.79-7.71-14.13-5.26c-5.35,2.45-7.71,8.78-5.26,14.13l0.02,0.05
				l0.42,0.77l-0.78,0.39l-44.17,20.19l-18.21-39.83l0.82-0.3c0.11-0.04,0.22-0.09,0.33-0.14c5.35-2.45,7.71-8.78,5.26-14.13
				c-2.45-5.35-8.78-7.71-14.13-5.26c-0.11,0.05-0.22,0.1-0.32,0.16l-0.77,0.43l-5.48-11.99l12.29-5.62l0.35,0.7
				c2.58,5.15,8.71,7.3,13.96,4.91s7.62-8.44,5.42-13.77l-0.3-0.73l39.36-18l3.15,6.89l-0.74,0.34c-5.36,2.45-7.72,8.79-5.28,14.14
				c2.45,5.35,8.78,7.71,14.13,5.26l0.74-0.34l0.35,0.73L120.24,453.39z M114.25,454.34l3.84-1.75l-19.13-41.85
				c-6.01,2.29-12.85-0.46-15.56-6.38c-2.71-5.92-0.3-12.9,5.35-15.94l-1.81-3.95l-36.47,16.67c2.04,5.95-0.72,12.48-6.53,15.13
				s-12.55,0.47-15.72-4.96l-9.4,4.3l4.1,8.96c6.09-2.56,13.17,0.18,15.93,6.23c2.76,6.05,0.2,13.19-5.72,16.12l16.83,36.81
				l41.95-19.18c-2.37-6.03,0.37-12.95,6.33-15.68S111.24,448.6,114.25,454.34z"
                    />
                </g>
                <g>
                    <path
                        className="st0"
                        d="M68.87,447.29c-7.24,3.31-15.82,0.11-19.13-7.13s-0.11-15.82,7.13-19.13c7.24-3.31,15.82-0.11,19.13,7.13
				C79.31,435.4,76.11,443.98,68.87,447.29z M57.54,422.51c-6.42,2.94-9.26,10.55-6.32,16.97s10.55,9.26,16.97,6.32
				c6.42-2.94,9.26-10.55,6.32-16.97S63.97,419.57,57.54,422.51z"
                    />
                </g>
                <g>
                    <path
                        className="st0"
                        d="M66.21,468.74c-2.97,1.36-6.49,0.05-7.84-2.92c-1.36-2.97-0.05-6.49,2.92-7.84c2.97-1.36,6.49-0.05,7.84,2.92
				C70.49,463.87,69.18,467.39,66.21,468.74z M61.97,459.46c-2.15,0.98-3.1,3.53-2.12,5.68c0.98,2.15,3.53,3.1,5.68,2.12
				c2.15-0.98,3.1-3.53,2.12-5.68C66.67,459.43,64.12,458.48,61.97,459.46z"
                    />
                </g>
                <g>
                    <path
                        className="st0"
                        d="M93.52,435.07c-2.97,1.36-6.49,0.05-7.84-2.92c-1.36-2.97-0.05-6.49,2.92-7.84c2.97-1.36,6.49-0.05,7.84,2.92
				C97.79,430.19,96.48,433.71,93.52,435.07z M89.27,425.79c-2.15,0.98-3.1,3.53-2.12,5.68s3.53,3.1,5.68,2.12
				c2.15-0.98,3.1-3.53,2.12-5.68C93.97,425.75,91.42,424.8,89.27,425.79z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <circle className="st0" cx="799.67" cy="315.12" r="4.61" />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M806.44,294.67c0.17,0.52,1.79,5.47-1.01,8.07c-2.6,2.41-7.32,1.2-9.79-0.86c-1.52-1.26-1.27-2.08-2.88-4.47
					c-3.59-5.31-7.5-5.22-7.78-7.78c-0.44-3.98,8.47-9.09,10.66-7.35c1.47,1.17-0.79,4.87,1.15,6.63c1.55,1.4,3.66-0.33,6.34,1.15
					C805.33,291.29,806.12,293.71,806.44,294.67z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M789.87,311.09c0.13,1.37-0.3,2.5-1.15,4.75c-1.22,3.2-1.83,4.8-3.02,5.04c-2.15,0.43-5.53-3.7-4.75-6.19
					c0.54-1.74,2.72-1.42,3.89-3.6c1.42-2.65-0.53-5.46,0.29-5.91C786.02,304.69,789.53,307.53,789.87,311.09z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M871.35,296.56c-0.7,0-1.4-0.07-2.1-0.22c-2.68-0.55-4.98-2.12-6.49-4.41l-77.85-118.48c-0.04-0.06-0.08-0.12-0.11-0.18
					c-6-11.09-5.25-24.42,1.96-34.8c0.25-0.36,0.51-0.71,0.77-1.06c0.18-0.24,0.19-0.56,0.03-0.81l-36.2-55.09
					c-0.34-0.52-0.46-1.14-0.34-1.76s0.48-1.14,1-1.48l13.98-9.19c0.52-0.34,1.15-0.46,1.76-0.34c0.61,0.13,1.14,0.48,1.48,1
					l36.2,55.09c0.16,0.25,0.46,0.37,0.75,0.3c0.42-0.1,0.85-0.2,1.28-0.28c12.38-2.51,24.92,2.09,32.72,12.01
					c0.05,0.06,0.09,0.12,0.13,0.18l77.85,118.47c3.1,4.72,1.79,11.09-2.94,14.19l-38.28,25.16
					C875.26,295.98,873.32,296.56,871.35,296.56z M767.29,70.34c-0.14,0-0.27,0.04-0.39,0.12l-13.98,9.19
					c-0.16,0.1-0.27,0.26-0.3,0.45c-0.04,0.18,0,0.37,0.1,0.53l36.2,55.09c0.54,0.82,0.51,1.9-0.09,2.69
					c-0.25,0.33-0.49,0.67-0.73,1.01c-6.86,9.87-7.57,22.55-1.87,33.09c0.01,0.02,0.02,0.04,0.04,0.06l77.86,118.48
					c1.26,1.92,3.2,3.24,5.45,3.71c2.25,0.47,4.55,0.03,6.48-1.24l38.28-25.16c1.92-1.26,3.24-3.2,3.7-5.45
					c0.47-2.25,0.03-4.55-1.24-6.48l-77.86-118.48c-0.01-0.02-0.03-0.04-0.04-0.06c-7.42-9.42-19.34-13.8-31.11-11.42
					c-0.41,0.08-0.81,0.17-1.22,0.27c-0.95,0.23-1.96-0.16-2.5-0.99l-36.2-55.09c-0.1-0.16-0.26-0.27-0.45-0.3
					C767.38,70.34,767.34,70.34,767.29,70.34z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M867.76,291.11c-1.03,0-2.07-0.16-3.09-0.48l0.49-1.56c2.48,0.78,5.15,0.41,7.32-1.02l15.3-10.05l0.9,1.36l-15.3,10.05
					C871.68,290.54,869.73,291.11,867.76,291.11z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M865.21,287.23c-0.7,0-1.4-0.07-2.1-0.22l0.33-1.6c2.26,0.47,4.55,0.03,6.48-1.24l3.89-2.56l0.89,1.36l-3.89,2.56
					C869.13,286.65,867.19,287.23,865.21,287.23z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M833.6,143.47c-5.36-6.84-14.34-10.15-22.87-8.42l-0.32-1.6c9.27-1.88,18.65,1.58,24.48,9.01L833.6,143.47z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M747.44,81.99c-0.27,0-0.54-0.03-0.81-0.08c-1.03-0.21-1.92-0.82-2.5-1.7l-4.89-7.45c-1.2-1.82-0.69-4.27,1.13-5.47
					l20.18-13.26c0.88-0.58,1.94-0.78,2.97-0.57c1.03,0.21,1.92,0.82,2.5,1.7l4.89,7.45c1.2,1.82,0.69,4.27-1.13,5.47L749.6,81.34
					C748.95,81.77,748.2,81.99,747.44,81.99z M762.71,55.01c-0.45,0-0.89,0.13-1.27,0.38l-20.18,13.26
					c-1.07,0.7-1.37,2.14-0.66,3.21l4.89,7.45c0.34,0.52,0.86,0.87,1.47,1c0.61,0.13,1.23,0.01,1.74-0.33l20.18-13.26
					c1.07-0.7,1.37-2.14,0.66-3.21l-4.89-7.45c-0.34-0.52-0.86-0.87-1.47-1C763.03,55.03,762.87,55.01,762.71,55.01z"
                        />
                    </g>
                </g>
                <path
                    className="st0"
                    d="M815.03,216.33l29.26,44.53c13.36-5.83,33.07-16.53,52.58-34.78l-37.36-56.85c-19.48,18.17-39.15,28.9-52.58,34.78
			l4.04,6.15c5.98-2.93,12.02-6.19,17.97-9.72l0.83,1.4c-5.93,3.51-11.94,6.76-17.9,9.69l2.25,3.42c5.97-2.92,12.02-6.19,17.97-9.72
			c0.1-0.06,10.3-6.16,12.26-7.81l1.05,1.25c-2.06,1.73-12.05,7.71-12.47,7.96C827,210.16,820.98,213.41,815.03,216.33z"
                />
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="755.94"
                            y="72.12"
                            transform="matrix(0.8357 -0.5492 0.5492 0.8357 79.658 434.3653)"
                            width="19.74"
                            height="23.84"
                        />
                    </g>
                    <g>
                        <path
                            className="st0"
                            d="M763.87,100.56l-13.99-21.29l17.86-11.74l13.99,21.29L763.87,100.56z M752.14,79.74l12.2,18.57l15.14-9.95l-12.2-18.57
					L752.14,79.74z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M603.74,249.57c-1.5-0.45-3.08-0.69-4.73-0.69c-9.07,0-16.42,7.35-16.42,16.42c0,2.49,0.55,4.85,1.55,6.96l-0.92,1.06
					l-18.05,3.26l10.56-16.9c0,0-26.12,2.3-26.69,2.3c-0.58,0,13.06-15.75,13.06-15.75l-19.39-21.32l25.16,6.34V208.6l20.16,20.36
					l9.03-17.48L603.74,249.57z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M615.13,352.25c-9.5,0-17.24-7.73-17.24-17.24c0-2.45,0.5-4.81,1.49-7.02l0.24-0.53l0.58,0.05
						c0.45,0.04,0.92,0.06,1.39,0.06c6.15,0,11.75-3.63,14.26-9.25l0.24-0.53l0.58,0.05c8.81,0.77,15.7,8.31,15.7,17.17
						C632.37,344.52,624.64,352.25,615.13,352.25z M600.66,329.18c-0.75,1.85-1.13,3.81-1.13,5.84c0,8.6,7,15.61,15.61,15.61
						c8.6,0,15.61-7,15.61-15.61c0-7.83-5.95-14.52-13.65-15.48c-2.88,5.89-8.9,9.67-15.49,9.67
						C601.28,329.2,600.97,329.19,600.66,329.18z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M600.06,329.13c-8.81-0.77-15.7-8.31-15.7-17.17c0-9.5,7.73-17.24,17.24-17.24s17.24,7.73,17.24,17.24
						c0,2.44-0.5,4.81-1.49,7.02l-1.49-0.66c0.9-2.01,1.35-4.15,1.35-6.36c0-8.6-7-15.61-15.61-15.61c-8.6,0-15.61,7-15.61,15.61
						c0,8.02,6.24,14.84,14.22,15.54L600.06,329.13z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M630.98,331.22h-0.08v-1.63h0.08c8.6,0,15.61-7,15.61-15.61c0-3.75-1.35-7.38-3.81-10.22
						c-2.97-3.42-7.27-5.39-11.8-5.39c-0.19,0-0.38,0-0.57,0.01l-0.06-1.63c0.21-0.01,0.42-0.01,0.63-0.01c5,0,9.75,2.17,13.03,5.95
						c2.71,3.13,4.21,7.14,4.21,11.29C648.22,323.49,640.48,331.22,630.98,331.22z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M617.51,308.3l-0.22-1.61c7.67-1.06,13.45-7.7,13.45-15.46c0-8.6-7-15.61-15.61-15.61c-8.6,0-15.61,7-15.61,15.61
						c0,1.46,0.2,2.89,0.59,4.25l-1.57,0.45c-0.43-1.51-0.65-3.09-0.65-4.7c0-9.5,7.73-17.24,17.24-17.24
						c9.5,0,17.24,7.73,17.24,17.24C632.37,299.79,625.98,307.13,617.51,308.3z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M586.68,305.59c-8.19-1.49-14.13-8.62-14.13-16.95c0-9.5,7.73-17.24,17.23-17.24c5.82,0,11.21,2.91,14.41,7.78
						l-1.36,0.9c-2.9-4.41-7.78-7.05-13.05-7.05c-8.6,0-15.6,7-15.6,15.61c0,7.55,5.38,14,12.79,15.35L586.68,305.59z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M643.48,304.04l-0.17-1.62c7.94-0.84,13.94-7.52,13.94-15.52c0-8.6-7-15.61-15.61-15.61c-6.03,0-11.41,3.37-14.04,8.8
						l-1.47-0.71c2.87-5.9,8.95-9.71,15.51-9.71c9.5,0,17.24,7.73,17.24,17.24C658.87,295.74,652.25,303.11,643.48,304.04z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M640.76,270.96l-1.41-0.81c1.34-2.34,2.05-5.02,2.05-7.74c0-8.6-7-15.61-15.61-15.61c-5.7,0-10.94,3.1-13.69,8.1
						l-1.43-0.78c3.03-5.52,8.82-8.95,15.11-8.95c9.5,0,17.24,7.73,17.24,17.24C643.03,265.42,642.25,268.37,640.76,270.96z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M612.86,275.55l-1.31-0.97c0.41-0.56,0.8-1.16,1.14-1.78c1.25-2.29,1.92-4.88,1.92-7.5c0-3.77-1.36-7.4-3.83-10.24
						c-1.94-2.23-4.46-3.86-7.28-4.7c-1.44-0.44-2.96-0.66-4.5-0.66c-8.6,0-15.61,7-15.61,15.61c0,2.32,0.49,4.54,1.47,6.61
						c0.12,0.27,0.25,0.52,0.38,0.77l-1.43,0.78c-0.15-0.28-0.3-0.57-0.43-0.86c-1.07-2.28-1.62-4.74-1.62-7.3
						c0-9.5,7.73-17.24,17.24-17.24c1.7,0,3.37,0.24,4.97,0.73c3.11,0.93,5.89,2.73,8.04,5.2c2.73,3.14,4.23,7.15,4.23,11.31
						c0,2.89-0.73,5.76-2.12,8.29C613.74,274.27,613.32,274.93,612.86,275.55z"
                            />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M612.69,252.48c-2.1-4.93-2.4-8.9-2.28-11.64c0.22-5.05,1.91-6.82,2.86-14.58c0.43-3.53,0.71-5.81,0.29-8.93
					c-0.39-2.91-1.04-7.73-4.9-11.81c-4.24-4.49-9.68-5.35-11.63-5.51c-3.92-0.32-7.09,0.32-7.09,0.32
					c21.03,0.58,23.33,19.3,19.3,30.82c-4.03,11.52,1.15,22.76,1.15,22.76l1.01,0.6l1.59-2.19"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M611.68,226.26c0,0,9.36-3.02,8.79-12.96c-0.58-9.94-3.17-13.25-3.17-13.25s3.89,3.6,4.61,7.06
					c0.72,3.46,2.59,11.52-2.45,15.99c-5.04,4.47-7.49,6.34-8.21,9.22L611.68,226.26z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="587.27" y="319.17" width="15.15" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="590.45" y="323.2" width="11.97" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="584.94" y="310.91" width="17.48" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="585.62" y="314.94" width="16.8" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="587.91" y="302.08" width="14.5" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="585.96" y="306.11" width="16.45" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <rect className="st0" x="575.55" y="295.55" width="12.22" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="578.73" y="299.58" width="5.44" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="573.22" y="287.29" width="17.48" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="573.9" y="291.32" width="16.8" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="576.2" y="278.46" width="7.97" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="574.25" y="282.49" width="13.38" height="1.63" />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <rect className="st0" x="581.17" y="266.88" width="12.22" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="578.84" y="258.63" width="17.48" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="579.52" y="262.66" width="16.8" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="579.87" y="253.82" width="13.38" height="1.63" />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="598.72" y="336.79" width="15.99" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="599.72" y="340.82" width="11.52" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <polygon
                            className="st0"
                            points="674.22,219.87 658.64,186.41 647.23,210.39 633.39,163.26 634.95,162.8 647.59,205.84 658.66,182.58 
					674.19,215.95 685.2,191.44 715.97,192.27 697.86,169.71 717.85,151.87 676.9,156.36 686.9,134.43 636.77,160.03 636.03,158.58 
					690.32,130.85 679.57,154.43 722.74,149.7 700.1,169.89 719.44,194 686.25,193.1 				"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M636.38,159.33l-2.8,1.26l0.58,2.44c-1.7-1.27-4.33-2.9-8.36-4.8c-9.79-4.61-5.57-14.98-5.57-14.98s0.24,2.64,1.08,5.45
					c1.18,3.95,4.07,5.97,4.68,6.46c1.85,1.47,3.54,2.08,5.76,2.88c1.73,0.62,3.31,1.01,4.64,1.26L636.38,159.33z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M579.27,180.12c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
					S582.26,180.12,579.27,180.12z M579.27,170.91c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79c2.09,0,3.79-1.7,3.79-3.79
					C583.07,172.61,581.36,170.91,579.27,170.91z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M538.37,297.95c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
					C543.79,295.51,541.36,297.95,538.37,297.95z M538.37,288.73c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79
					c2.09,0,3.79-1.7,3.79-3.79S540.46,288.73,538.37,288.73z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M664.54,266.26c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
					C669.96,263.82,667.53,266.26,664.54,266.26z M664.54,257.04c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
					c2.09,0,3.79-1.7,3.79-3.79C668.33,258.74,666.63,257.04,664.54,257.04z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M662.24,330.5c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
					C667.66,328.06,665.23,330.5,662.24,330.5z M662.24,321.28c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
					c2.09,0,3.79-1.7,3.79-3.79C666.03,322.98,664.33,321.28,662.24,321.28z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <polygon
                            className="st0"
                            points="717.41,193.9 639.05,163.36 633.32,161.35 633.86,159.81 639.62,161.83 718,192.38 				"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <polygon
                            className="st0"
                            points="670.21,187.63 667.82,173.5 680.79,167.49 681.48,168.97 669.64,174.46 671.82,187.36 				"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <polygon
                            className="st0"
                            points="649.93,179.84 648.3,179.67 649.66,166.67 657.95,159.24 659.04,160.46 651.22,167.46 				"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M800.62,332.29l-3.25,13.48c-0.08,0.38-0.16,0.76-0.25,1.14c-3.67,15.22-18.99,24.59-34.21,20.92
					c-15.23-3.67-24.59-18.99-20.92-34.22c0.1-0.4,0.2-0.8,0.32-1.2l3.23-13.4c0,0,8.64,15.52,20.15,15.33
					C777.18,334.15,785.43,318.56,800.62,332.29z"
                        />
                    </g>
                    <g>
                        <path
                            className="st0"
                            d="M769.55,369.44c-2.26,0-4.55-0.26-6.84-0.82c-7.58-1.83-13.99-6.49-18.05-13.14c-4.07-6.65-5.3-14.48-3.47-22.06
					c0.1-0.42,0.21-0.83,0.33-1.23l3.71-15.4l1.02,1.82c0.08,0.15,8.44,14.91,19.22,14.91c0.06,0,0.13,0,0.19,0
					c3.74-0.06,7.12-1.83,10.69-3.71c7.07-3.71,14.39-7.55,24.81,1.88l0.36,0.32l-3.36,13.95c-0.07,0.35-0.15,0.75-0.25,1.14
					C794.7,360.45,782.72,369.44,769.55,369.44z M745.86,321.12l-2.77,11.49c-0.12,0.42-0.22,0.81-0.32,1.2
					c-1.72,7.15-0.56,14.55,3.28,20.82c3.84,6.28,9.89,10.68,17.04,12.41c14.76,3.56,29.67-5.56,33.23-20.32
					c0.09-0.37,0.17-0.74,0.24-1.11l3.14-13.03c-9.38-8.25-15.8-4.88-22.6-1.32c-3.59,1.88-7.3,3.83-11.42,3.9
					C756.06,335.3,748.54,325.24,745.86,321.12z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M764.26,324.94c-11.6,0-16.45-14.38-16.5-14.53l-0.07-0.22l6.6-27.39l1.58,0.38l-6.49,26.93
					c0.65,1.78,5.59,14.19,16.17,13.13l0.16,1.62C765.22,324.91,764.73,324.94,764.26,324.94z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M764.82,308.38c-0.1-0.01-10.25-0.71-13.69-12l1.56-0.47c3.09,10.17,11.85,10.82,12.22,10.84L764.82,308.38z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M749.58,450.64l-1.62-0.14c2.67-30.48,10.63-55.61,15.14-67.87c0.43-1.17,1.59-1.93,2.85-1.84
						c17.88,1.31,33.91-10.41,38.12-27.87c0.12-0.49,0.23-0.98,0.32-1.47l16.13-66.92c0.07-0.31,0.02-0.63-0.14-0.9
						c-0.17-0.27-0.43-0.46-0.74-0.54l-68.64-16.55c-0.31-0.07-0.63-0.02-0.9,0.14c-0.27,0.17-0.46,0.43-0.54,0.74l-16.1,66.79
						c-0.15,0.53-0.29,1.03-0.42,1.58c-4.21,17.45,4.72,35.19,21.23,42.18c1.15,0.49,1.85,1.7,1.7,2.94
						c-1.58,12.97-5.94,38.97-17.45,67.32l-1.51-0.61c11.44-28.18,15.78-54.01,17.34-66.9c0.06-0.53-0.22-1.03-0.72-1.24
						c-17.25-7.3-26.57-25.83-22.17-44.06c0.14-0.57,0.28-1.1,0.43-1.62L748,267.04c0.18-0.73,0.63-1.35,1.27-1.74
						c0.64-0.39,1.4-0.51,2.13-0.34l68.64,16.55c0.73,0.18,1.35,0.63,1.74,1.27c0.39,0.64,0.51,1.4,0.34,2.13l-16.13,66.88
						c-0.09,0.48-0.2,0.99-0.33,1.5c-4.4,18.23-21.15,30.48-39.82,29.11c-0.53-0.04-1.02,0.28-1.2,0.77
						C760.15,395.38,752.24,420.35,749.58,450.64z"
                            />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M768.92,468.25c-8.69,0-18.95-1.34-29.48-3.88c-12.57-3.03-24.03-7.41-32.27-12.33c-8.5-5.08-12.62-10.23-11.59-14.49
					c1.96-8.14,22.46-10.17,47.68-4.7l-0.34,1.59c-23.53-5.1-44.06-3.53-45.75,3.49c-0.84,3.46,3.12,8.1,10.84,12.71
					c8.1,4.84,19.4,9.15,31.81,12.15c12.41,2.99,24.44,4.3,33.85,3.69c8.98-0.59,14.61-2.91,15.44-6.37
					c1.68-6.96-15.71-17.67-38.76-23.86l0.42-1.57c11.65,3.13,22.14,7.41,29.52,12.06c7.79,4.9,11.39,9.66,10.4,13.76
					c-1.03,4.27-7.04,6.97-16.92,7.62C772.22,468.2,770.6,468.25,768.92,468.25z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="711.51"
                            y="425.16"
                            transform="matrix(0.2344 -0.9721 0.9721 0.2344 116.0864 1030.5648)"
                            width="1.63"
                            height="32.84"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="707.37"
                            y="436.05"
                            transform="matrix(0.2344 -0.9721 0.9721 0.2344 108.4083 1030.0764)"
                            width="1.63"
                            height="20.31"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="711"
                            y="425.3"
                            transform="matrix(0.2344 -0.9721 0.9721 0.2344 122.4441 1024.7566)"
                            width="1.63"
                            height="18.68"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M916.53,156.09c-0.93,0.73-2.11,1.15-3.27,1.23l-0.57,0.05c-0.17,0.02-0.35,0.03-0.52,0.04c-0.35,0.02-0.7,0.02-1.05,0
						l-4.19-0.39l-2.09-0.22l-0.26-0.03c-0.08-0.01-0.2-0.02-0.22-0.03c-0.1-0.02-0.2-0.03-0.3-0.03c-0.21-0.01-0.42,0.02-0.63,0.08
						c-0.21,0.06-0.42,0.16-0.62,0.29c-0.1,0.07-0.19,0.14-0.28,0.22l-0.13,0.14l-0.17,0.2l-1.34,1.63
						c-0.91,1.08-1.8,2.17-2.73,3.23c0.49-1.32,1.02-2.63,1.53-3.94l0.79-1.96l0.1-0.25c0.04-0.09,0.1-0.2,0.15-0.3
						c0.11-0.2,0.24-0.4,0.38-0.58c0.28-0.38,0.63-0.71,1.03-0.99c0.39-0.28,0.84-0.5,1.31-0.65c0.24-0.07,0.48-0.13,0.73-0.17
						l0.55-0.09l2.08-0.35l4.15-0.66c0.35-0.05,0.69-0.07,1.04-0.07c0.17,0,0.35,0,0.52,0l0.26,0.01l0.13,0l0.06,0l0.02-0.01
						c0.23,0.01,0.41-0.07,0.58-0.19c0.16-0.13,0.29-0.32,0.36-0.53c0.06-0.21,0.05-0.41,0-0.61c0-0.05-0.05-0.1-0.06-0.16
						c0,0-0.02-0.05-0.04-0.08l-0.06-0.12l-0.12-0.24l-0.47-0.93l-0.94-1.86l-1.88-3.72l-3.76-7.45l-0.12-0.23
						c-0.04-0.08-0.07-0.13-0.14-0.29c-0.11-0.27-0.24-0.53-0.31-0.82c-0.09-0.28-0.16-0.57-0.19-0.87
						c-0.05-0.29-0.06-0.59-0.06-0.89c0.01-1.19,0.41-2.35,1.08-3.3c0.67-0.96,1.66-1.71,2.78-2.11c0.56-0.21,1.14-0.31,1.72-0.34
						l1.07-0.04l8.34-0.27l4.17-0.13l2.08-0.06c0.06,0,0.05,0,0.07-0.01l0.04-0.01l0.08,0c0.05-0.03,0.11-0.02,0.15-0.04
						c0.04-0.02,0.1-0.03,0.14-0.06c0.02-0.01,0.05-0.02,0.07-0.03c0.02-0.02,0.04-0.04,0.06-0.05c0.17-0.11,0.31-0.31,0.38-0.51
						c0.08-0.21,0.08-0.41,0.02-0.61c-0.01-0.05-0.03-0.1-0.06-0.15c0.01-0.01-0.11-0.23-0.18-0.37l-0.47-0.93l-0.94-1.86
						l-3.76-7.45l-1.88-3.72l-0.24-0.47l-0.06-0.12l-0.03-0.06l-0.04-0.1l-0.17-0.4c-0.12-0.26-0.18-0.56-0.26-0.84
						c-0.06-0.29-0.1-0.59-0.13-0.88c-0.07-1.19,0.22-2.38,0.82-3.39c0.61-1,1.52-1.83,2.6-2.32c0.55-0.23,1.12-0.4,1.7-0.47
						l0.44-0.04c0.08-0.01,0.12-0.01,0.16-0.01l0.13,0l0.52-0.02l4.17-0.13l1.04-0.03l0.52-0.02l0.12,0l0.04,0
						c0.03,0,0.05-0.01,0.08-0.01c0.21-0.03,0.41-0.13,0.56-0.27c0.04-0.03,0.07-0.08,0.11-0.12l0.05-0.07
						c0.02-0.03,0.01-0.01,0.07-0.11l3.73-6.28c0.35-0.6,0.72-1.19,1.05-1.8c0.17-0.3,0.32-0.62,0.49-0.92
						c0.18-0.3,0.37-0.59,0.58-0.87c0.69-0.88,1.38-1.77,2.07-2.65c0.11-0.15,0.23-0.29,0.34-0.44c0.12-0.15,0.23-0.29,0.35-0.44
						l3.13,1.86c-0.07,0.17-0.14,0.34-0.22,0.51c-0.07,0.17-0.14,0.34-0.22,0.51c-0.44,1.03-0.89,2.06-1.34,3.09
						c-0.15,0.32-0.3,0.63-0.49,0.93c-0.18,0.3-0.38,0.58-0.57,0.87c-0.37,0.59-0.72,1.19-1.07,1.79l-3.73,6.28
						c-0.03,0.05-0.13,0.22-0.21,0.33c-0.08,0.12-0.17,0.24-0.26,0.36c-0.18,0.23-0.38,0.45-0.6,0.65
						c-0.86,0.81-1.97,1.34-3.13,1.52c-0.15,0.02-0.29,0.04-0.44,0.05l-0.22,0.02l-0.14,0.01l-0.52,0.02l-1.04,0.03l-4.17,0.13
						l-0.52,0.02l-0.13,0c-0.04,0-0.09,0-0.09,0.01c-0.03,0-0.05,0.01-0.08,0.01c-0.11,0-0.21,0.04-0.31,0.08
						c-0.18,0.09-0.34,0.23-0.46,0.42c-0.11,0.19-0.16,0.41-0.15,0.61c0.01,0.05,0.01,0.1,0.02,0.15c0.03,0.05,0.02,0.1,0.05,0.15
						c0.01,0.02,0.02,0.05,0.03,0.08l0.01,0.02l0.03,0.05l0.06,0.12l0.23,0.47l1.88,3.73l3.76,7.45l0.94,1.86l0.47,0.93
						c0.08,0.17,0.14,0.25,0.27,0.57c0.12,0.27,0.23,0.54,0.3,0.82c0.32,1.14,0.28,2.39-0.12,3.49c-0.39,1.11-1.11,2.09-2.07,2.78
						l-0.37,0.25c-0.13,0.08-0.26,0.15-0.39,0.21c-0.26,0.15-0.54,0.25-0.82,0.35c-0.28,0.1-0.57,0.15-0.86,0.22
						c-0.14,0.02-0.29,0.04-0.43,0.05l-0.22,0.02c-0.07,0-0.16,0.01-0.19,0.01l-2.09,0.07l-4.17,0.13l-8.35,0.26l-1.02,0.03
						c-0.12,0.02-0.22,0.02-0.32,0.06c-0.18,0.07-0.37,0.18-0.49,0.37c-0.12,0.18-0.21,0.39-0.2,0.6c0,0.05,0,0.1,0.01,0.15
						c0,0.05,0.02,0.1,0.03,0.15c0,0.06,0.05,0.1,0.06,0.16c0,0,0.05,0.11,0.09,0.18l0.12,0.23l3.76,7.45l1.88,3.72l0.94,1.86
						l0.47,0.93l0.18,0.35c0.02,0.04,0.03,0.07,0.07,0.15c0.11,0.27,0.24,0.53,0.32,0.82c0.36,1.12,0.36,2.38,0,3.5
						C918.15,154.36,917.46,155.36,916.53,156.09z"
                            />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M979.04,100.64c-2.05,0-4.07-0.55-5.88-1.62c-5.49-3.29-7.28-10.38-4.04-15.84c3.24-5.46,10.32-7.28,15.79-4.07
					c2.7,1.6,4.58,4.12,5.35,7.11c0.76,2.99,0.31,6.1-1.26,8.76l0,0c-1.58,2.65-4.09,4.54-7.08,5.3
					C980.96,100.52,980,100.64,979.04,100.64z M979.07,79.17c-3.4,0-6.7,1.74-8.55,4.85c-2.78,4.69-1.24,10.78,3.43,13.58
					c2.32,1.38,4.99,1.76,7.56,1.11c2.57-0.65,4.73-2.27,6.08-4.55l0,0c1.35-2.28,1.74-4.95,1.08-7.52s-2.27-4.73-4.55-6.08
					C982.54,79.61,980.79,79.17,979.07,79.17z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M973.12,98.99l-46.79-27.78c-2.65-1.58-4.54-4.09-5.3-7.08c-0.76-2.99-0.32-6.1,1.26-8.75c1.58-2.66,4.09-4.54,7.08-5.3
					c2.99-0.76,6.1-0.31,8.76,1.26l46.79,27.78l-0.83,1.4c-4.7-2.76-10.78-1.19-13.56,3.49c-2.78,4.69-1.24,10.78,3.43,13.58
					L973.12,98.99z M932.24,51.34c-0.82,0-1.65,0.1-2.47,0.31c-2.57,0.65-4.73,2.27-6.08,4.55c-1.35,2.28-1.74,4.95-1.08,7.52
					c0.66,2.57,2.27,4.73,4.55,6.08l41.76,24.79c-1.87-3.46-1.94-7.8,0.21-11.42c2.15-3.62,5.99-5.64,9.93-5.65l-41.76-24.79
					C935.74,51.82,934,51.34,932.24,51.34z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M973.11,98.99c-5.44-3.26-7.23-10.35-3.99-15.81c3.24-5.46,10.32-7.28,15.79-4.07l-0.83,1.4
					c-4.7-2.76-10.78-1.19-13.56,3.49c-2.78,4.69-1.24,10.78,3.43,13.58L973.11,98.99z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="937.78"
                            y="82.14"
                            transform="matrix(0.8599 0.5105 -0.5105 0.8599 177.3967 -469.8923)"
                            width="13.77"
                            height="12.12"
                        />
                    </g>
                    <g>
                        <path
                            className="st0"
                            d="M947.78,98.05l-13.24-7.86l7.02-11.82l13.24,7.86L947.78,98.05z M936.77,89.61l10.44,6.2l5.36-9.02l-10.44-6.2
					L936.77,89.61z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M591.31,131.22c-47.23-24.74-57.82-87.48-59.47-99.81l1.62-0.22c1.62,12.19,12.09,74.21,58.61,98.58L591.31,131.22z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M532.89,32.32l-0.43-0.2c-0.13-0.06-13.34-6.55-4.37-28.18l0.51-1.23l0.86,1.02c0.4,0.47,9.69,11.53,8.51,20.73
					c-0.4,3.14-1.99,5.7-4.7,7.59L532.89,32.32z M529.08,5.86c-6.62,17.21,1.63,23.36,3.66,24.56c2.08-1.59,3.29-3.66,3.62-6.17
					C537.25,17.34,531.35,8.83,529.08,5.86z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M538.01,42.89c-1.2,0-2.42-0.2-3.67-0.61l-0.45-0.15l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.41l1.29-0.33l-0.29,1.3
					c-0.14,0.6-3.41,14.67-11.48,19.25C541.57,42.42,539.83,42.89,538.01,42.89z M535.32,40.87c2.52,0.71,4.91,0.43,7.11-0.81
					c6.07-3.44,9.33-13.27,10.34-16.86C535.03,28.24,535.07,38.52,535.32,40.87z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M541.94,58.28c-1.2,0-2.42-0.2-3.67-0.61l-0.45-0.15l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.41l1.29-0.33l-0.29,1.3
					c-0.14,0.6-3.41,14.67-11.48,19.25C545.51,57.81,543.76,58.28,541.94,58.28z M539.26,56.26c2.52,0.71,4.91,0.43,7.11-0.81
					c6.07-3.44,9.33-13.27,10.34-16.86C538.96,43.63,539,53.92,539.26,56.26z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M547.74,74.76c-1.2,0-2.42-0.2-3.67-0.61L543.62,74l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.41l1.29-0.33l-0.29,1.3
					c-0.14,0.6-3.41,14.67-11.48,19.25C551.3,74.29,549.55,74.76,547.74,74.76z M545.05,72.74c2.52,0.71,4.91,0.43,7.11-0.81
					c6.07-3.44,9.33-13.27,10.34-16.86C544.75,60.11,544.79,70.4,545.05,72.74z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M555.47,90.98c-1.2,0-2.42-0.2-3.67-0.61l-0.45-0.15l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.4l1.29-0.33l-0.29,1.3
					c-0.14,0.6-3.41,14.67-11.48,19.24C559.04,90.5,557.29,90.98,555.47,90.98z M552.79,88.96c2.52,0.71,4.91,0.44,7.11-0.81
					c6.07-3.43,9.33-13.26,10.34-16.85C552.49,76.33,552.53,86.61,552.79,88.96z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M564.79,105.65c-1.2,0-2.42-0.2-3.67-0.61l-0.45-0.15l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.4l1.29-0.33l-0.29,1.3
					c-0.14,0.6-3.41,14.67-11.48,19.24C568.35,105.18,566.6,105.65,564.79,105.65z M562.11,103.63c2.52,0.7,4.9,0.43,7.1-0.81
					c6.07-3.44,9.33-13.26,10.34-16.85C561.98,90.96,561.89,101.33,562.11,103.63z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M527.66,49.05c-0.12,0-0.24,0-0.37-0.01c-9.27-0.28-18.73-11.21-19.12-11.68l-0.87-1.01l1.3-0.31
					c22.78-5.44,27.09,8.63,27.13,8.77l0.13,0.45l-0.33,0.34C533.32,47.89,530.67,49.05,527.66,49.05z M510.34,37.32
					c2.57,2.7,10.04,9.88,17.01,10.09c2.54,0.08,4.77-0.8,6.67-2.59C533.14,42.63,528.39,33.5,510.34,37.32z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M533.32,68.35c-0.12,0-0.24,0-0.37-0.01c-9.27-0.28-18.73-11.21-19.12-11.68l-0.87-1.01l1.3-0.31
					c22.78-5.44,27.09,8.62,27.13,8.77l0.13,0.45l-0.33,0.34C538.99,67.19,536.34,68.35,533.32,68.35z M516.01,56.62
					c2.57,2.7,10.04,9.88,17.01,10.09c2.54,0.07,4.77-0.8,6.67-2.59C538.81,61.93,534.05,52.81,516.01,56.62z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M540.25,85.7c-0.12,0-0.24,0-0.36-0.01c-9.27-0.28-18.73-11.21-19.12-11.68L519.89,73l1.3-0.31
					c22.78-5.44,27.09,8.62,27.13,8.77l0.13,0.45l-0.33,0.34C545.91,84.54,543.26,85.7,540.25,85.7z M522.93,73.97
					c2.57,2.7,10.04,9.88,17.01,10.09c2.52,0.07,4.77-0.8,6.66-2.59C545.73,79.28,540.97,70.16,522.93,73.97z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M549.31,104.29c-8.92,0-18.77-8.75-19.2-9.14l-0.99-0.89l1.25-0.48c21.87-8.36,27.99,5.01,28.05,5.15l0.19,0.43
					l-0.28,0.38c-1.97,2.66-4.57,4.17-7.72,4.49C550.16,104.27,549.74,104.29,549.31,104.29z M532.25,94.82
					c2.9,2.34,11.24,8.49,18.18,7.79c2.52-0.25,4.62-1.41,6.27-3.44C555.55,97.1,549.65,88.68,532.25,94.82z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M557.09,120.86c-7.57,0-15.58-3.74-16.01-3.94l-1.21-0.57l1.06-0.81c18.57-14.27,28.25-3.19,28.35-3.08l0.3,0.36
					l-0.16,0.44c-1.13,3.11-3.19,5.3-6.12,6.51C561.4,120.55,559.26,120.86,557.09,120.86z M543.04,115.99
					c3.45,1.42,13.2,4.92,19.65,2.27c2.34-0.96,4.03-2.67,5.02-5.09C566.03,111.52,557.96,105.14,543.04,115.99z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M901.46,453c-1.53-6.09-3.51-12.35-5.89-18.6c-5.16-13.58-4.97-25.87,0.57-36.53c11-21.18,39.75-28.72,40.04-28.79
					l0.4,1.58c-0.28,0.07-28.34,7.44-39,27.97c-5.31,10.23-5.47,22.07-0.48,35.19c2.4,6.31,4.4,12.63,5.94,18.78L901.46,453z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M942.9,373.36c-2.86,0-5.41-1.07-7.61-3.19l-0.34-0.33l0.12-0.46c0.04-0.14,3.89-14.34,26.83-9.65l1.31,0.27l-0.83,1.04
					c-0.38,0.48-9.48,11.71-18.73,12.3C943.4,373.35,943.15,373.36,942.9,373.36z M936.8,369.33c1.96,1.74,4.22,2.54,6.75,2.37
					c6.96-0.44,14.19-7.86,16.67-10.64C942.05,357.84,937.59,367.11,936.8,369.33z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M924.46,374.83l-0.32-0.35c-0.1-0.11-9.83-11.14,6.71-27.71l0.94-0.94l0.41,1.27c0.19,0.58,4.56,14.36-0.05,22.41
					c-1.57,2.75-4.01,4.51-7.24,5.23L924.46,374.83z M931.04,348.92c-12.69,13.39-7.4,22.21-5.99,24.1c2.53-0.68,4.44-2.13,5.7-4.32
					C934.21,362.65,932,352.53,931.04,348.92z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M909.13,383.79l-0.36-0.31c-0.11-0.1-11.03-9.96,3.54-28.3l0.83-1.04l0.55,1.21c0.25,0.56,6.15,13.75,2.49,22.27
					c-1.25,2.91-3.47,4.93-6.61,6.01L909.13,383.79z M912.74,357.3c-10.98,14.6-4.78,22.91-3.22,24.62
					c2.43-0.96,4.17-2.62,5.17-4.94C917.44,370.57,914.1,360.78,912.74,357.3z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M899.17,395.42l-0.46-0.12c-0.14-0.04-14.29-4.09-9.27-26.97l0.28-1.3l1.03,0.85c0.47,0.39,11.58,9.64,12.03,18.9
					c0.16,3.16-0.95,5.96-3.29,8.31L899.17,395.42z M890.75,370.05c-3.48,18.11,5.73,22.7,7.94,23.53c1.76-1.93,2.59-4.19,2.47-6.71
					C900.82,379.9,893.5,372.57,890.75,370.05z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M892.38,405.32c-4.26,0-13.93-2.03-19.08-19.28l-0.38-1.28l1.31,0.24c0.6,0.11,14.8,2.82,19.69,10.7
					c1.67,2.69,2.06,5.67,1.15,8.86l-0.13,0.45l-0.46,0.11C894.45,405.14,893.65,405.32,892.38,405.32z M875.27,386.9
					c5.74,17.53,16.02,17.08,18.35,16.73c0.61-2.54,0.24-4.92-1.09-7.07C888.85,390.64,878.9,387.77,875.27,386.9z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M887.92,422.51c-4.94,0-12.63-2.44-19.68-14.88l-0.66-1.16l1.33-0.06c0.61-0.03,15.05-0.56,21.58,6.03
					c2.23,2.25,3.27,5.07,3.1,8.38l-0.02,0.47l-0.42,0.21C893.1,421.52,891.06,422.51,887.92,422.51z M870.35,408.03
					c9.51,15.8,19.43,13.06,21.63,12.2c0.02-2.61-0.87-4.85-2.65-6.64C884.43,408.63,874.08,408.07,870.35,408.03z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M933.95,388.39c-4.18,0-8.84-0.66-12.23-2.98c-2.61-1.79-4.17-4.36-4.62-7.64l-0.06-0.47l0.37-0.29
					c0.12-0.09,11.9-8.89,27.08,8.94l0.86,1.02l-1.3,0.31C943.72,387.35,939.21,388.39,933.95,388.39z M918.8,378.04
					c0.47,2.57,1.76,4.59,3.85,6.02c5.76,3.94,16.01,2.56,19.68,1.89C930.13,372.36,920.78,376.83,918.8,378.04z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M914.74,397.59c-2.26,0-4.48-0.32-6.46-1.16c-2.92-1.24-4.95-3.45-6.05-6.57l-0.16-0.44l0.31-0.36
					c0.1-0.11,9.9-11.08,28.31,3.39l1.05,0.82l-1.21,0.56C930.11,394.02,922.24,397.59,914.74,397.59z M903.95,389.78
					c0.97,2.43,2.64,4.16,4.97,5.15c6.42,2.72,16.2-0.67,19.67-2.05C913.79,381.86,905.65,388.15,903.95,389.78z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M905.33,415.98c-2.26,0-4.48-0.32-6.46-1.16c-2.92-1.24-4.95-3.45-6.05-6.57l-0.16-0.44l0.31-0.36
					c0.1-0.11,9.9-11.08,28.31,3.39l1.05,0.82l-1.21,0.56C920.7,412.41,912.82,415.98,905.33,415.98z M894.54,408.17
					c0.97,2.43,2.64,4.16,4.97,5.15c6.42,2.72,16.2-0.67,19.67-2.05C904.38,400.25,896.24,406.54,894.54,408.17z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M901.75,433.19c-2.75,0-5.24-1.01-7.41-3.02l-0.35-0.32l0.1-0.46c0.03-0.14,3.56-14.43,26.6-10.28l1.31,0.24l-0.81,1.06
					c-0.37,0.49-9.2,11.93-18.44,12.73C902.42,433.18,902.08,433.19,901.75,433.19z M895.82,429.31c1.99,1.69,4.28,2.43,6.79,2.21
					c6.94-0.6,14-8.19,16.41-11.03C900.97,417.73,896.58,427.13,895.82,429.31z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M654.34,408.38c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C659.76,405.95,657.33,408.38,654.34,408.38z M654.34,399.16c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79C658.13,400.86,656.43,399.16,654.34,399.16z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M637.76,461.21c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C643.18,458.77,640.75,461.21,637.76,461.21z M637.76,451.99c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
				s3.79-1.7,3.79-3.79C641.55,453.69,639.85,451.99,637.76,451.99z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M850.13,364.69c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C855.55,362.26,853.12,364.69,850.13,364.69z M850.13,355.47c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79C853.92,357.18,852.22,355.47,850.13,355.47z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M954.72,302.83c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C960.14,300.4,957.71,302.83,954.72,302.83z M954.72,293.61c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79C958.51,295.31,956.81,293.61,954.72,293.61z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M757.65,229.7c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42S760.64,229.7,757.65,229.7z
				 M757.65,220.48c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79c2.09,0,3.79-1.7,3.79-3.79
				C761.45,222.19,759.75,220.48,757.65,220.48z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M702.17,113.42c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				S705.16,113.42,702.17,113.42z M702.17,104.2c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79c2.09,0,3.79-1.7,3.79-3.79
				S704.26,104.2,702.17,104.2z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M810.87,30.93c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42S813.86,30.93,810.87,30.93z
				 M810.87,21.72c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79c2.09,0,3.79-1.7,3.79-3.79S812.96,21.72,810.87,21.72z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M630.74,44.54c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C636.16,42.11,633.73,44.54,630.74,44.54z M630.74,35.32c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79C634.53,37.02,632.83,35.32,630.74,35.32z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M952.81,462.91c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C958.23,460.47,955.8,462.91,952.81,462.91z M952.81,453.69c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
				s3.79-1.7,3.79-3.79C956.6,455.39,954.9,453.69,952.81,453.69z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M844.39,465.46c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C849.81,463.03,847.38,465.46,844.39,465.46z M844.39,456.24c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79C848.18,457.94,846.48,456.24,844.39,456.24z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M620.24,453.39l-6.88,3.15l-0.3-0.92c-2.45-5.35-8.79-7.71-14.13-5.26c-5.35,2.45-7.71,8.78-5.26,14.13l0.02,0.05
				l0.42,0.77l-0.78,0.39l-44.17,20.19l-18.21-39.83l0.82-0.3c0.11-0.04,0.22-0.09,0.33-0.14c5.35-2.45,7.71-8.78,5.26-14.13
				s-8.78-7.71-14.13-5.26c-0.11,0.05-0.22,0.1-0.32,0.16l-0.77,0.43l-5.48-11.99l12.29-5.62l0.35,0.7
				c2.58,5.15,8.71,7.3,13.96,4.91s7.62-8.44,5.42-13.77l-0.3-0.73l39.36-18l3.15,6.89l-0.74,0.34c-5.36,2.45-7.72,8.79-5.28,14.14
				c2.45,5.35,8.78,7.71,14.13,5.26l0.74-0.34l0.35,0.73L620.24,453.39z M614.25,454.34l3.84-1.75l-19.13-41.85
				c-6.01,2.29-12.85-0.46-15.56-6.38c-2.71-5.92-0.3-12.9,5.35-15.94l-1.81-3.95l-36.47,16.67c2.04,5.95-0.72,12.48-6.53,15.13
				c-5.8,2.65-12.55,0.47-15.72-4.96l-9.4,4.3l4.1,8.96c6.09-2.56,13.17,0.18,15.93,6.23c2.76,6.05,0.2,13.19-5.72,16.12
				l16.83,36.81l41.95-19.18c-2.37-6.03,0.37-12.95,6.33-15.68S611.24,448.6,614.25,454.34z"
                    />
                </g>
                <g>
                    <path
                        className="st0"
                        d="M568.87,447.29c-7.24,3.31-15.82,0.11-19.13-7.13c-3.31-7.24-0.11-15.82,7.13-19.13c7.24-3.31,15.82-0.11,19.13,7.13
				C579.31,435.4,576.11,443.98,568.87,447.29z M557.54,422.51c-6.42,2.94-9.26,10.55-6.32,16.97s10.55,9.26,16.97,6.32
				c6.42-2.94,9.26-10.55,6.32-16.97S563.97,419.57,557.54,422.51z"
                    />
                </g>
                <g>
                    <path
                        className="st0"
                        d="M566.21,468.74c-2.97,1.36-6.49,0.05-7.84-2.92c-1.36-2.97-0.05-6.49,2.92-7.84c2.97-1.36,6.48-0.05,7.84,2.92
				C570.49,463.87,569.18,467.39,566.21,468.74z M561.97,459.46c-2.15,0.98-3.1,3.53-2.12,5.68c0.98,2.15,3.53,3.1,5.68,2.12
				c2.15-0.98,3.1-3.53,2.12-5.68C566.67,459.43,564.12,458.48,561.97,459.46z"
                    />
                </g>
                <g>
                    <path
                        className="st0"
                        d="M593.52,435.07c-2.97,1.36-6.49,0.05-7.84-2.92c-1.36-2.97-0.05-6.49,2.92-7.84c2.97-1.36,6.48-0.05,7.84,2.92
				C597.79,430.19,596.48,433.71,593.52,435.07z M589.27,425.79c-2.15,0.98-3.1,3.53-2.12,5.68c0.98,2.15,3.53,3.1,5.68,2.12
				c2.15-0.98,3.1-3.53,2.12-5.68C593.97,425.75,591.42,424.8,589.27,425.79z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <circle className="st0" cx="1299.67" cy="315.12" r="4.61" />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1306.44,294.67c0.17,0.52,1.79,5.47-1.01,8.07c-2.6,2.41-7.32,1.2-9.79-0.86c-1.51-1.26-1.27-2.08-2.88-4.47
					c-3.59-5.31-7.5-5.22-7.78-7.78c-0.44-3.98,8.47-9.09,10.66-7.35c1.47,1.17-0.79,4.87,1.15,6.63c1.55,1.4,3.66-0.33,6.34,1.15
					C1305.33,291.29,1306.12,293.71,1306.44,294.67z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1289.87,311.09c0.13,1.37-0.3,2.5-1.15,4.75c-1.22,3.2-1.83,4.8-3.02,5.04c-2.15,0.43-5.53-3.7-4.75-6.19
					c0.54-1.74,2.72-1.42,3.89-3.6c1.42-2.65-0.53-5.46,0.29-5.91C1286.02,304.69,1289.53,307.53,1289.87,311.09z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1371.35,296.56c-0.7,0-1.4-0.07-2.1-0.22c-2.68-0.55-4.98-2.12-6.49-4.41l-77.85-118.48
					c-0.04-0.06-0.08-0.12-0.11-0.18c-6-11.09-5.25-24.42,1.96-34.8c0.25-0.36,0.51-0.71,0.77-1.06c0.18-0.24,0.19-0.56,0.03-0.81
					l-36.2-55.09c-0.34-0.52-0.46-1.14-0.34-1.76s0.48-1.14,1-1.48l13.98-9.19c0.52-0.34,1.15-0.46,1.76-0.34
					c0.61,0.13,1.14,0.48,1.48,1l36.2,55.09c0.16,0.25,0.46,0.37,0.75,0.3c0.42-0.1,0.85-0.2,1.28-0.28
					c12.38-2.51,24.92,2.09,32.72,12.01c0.05,0.06,0.09,0.12,0.13,0.18l77.85,118.47c3.1,4.72,1.79,11.09-2.94,14.19l-38.28,25.16
					C1375.26,295.98,1373.32,296.56,1371.35,296.56z M1267.29,70.34c-0.14,0-0.27,0.04-0.39,0.12l-13.98,9.19
					c-0.16,0.1-0.27,0.26-0.3,0.45s0,0.37,0.1,0.53l36.2,55.09c0.54,0.82,0.51,1.9-0.09,2.69c-0.25,0.33-0.49,0.67-0.73,1.01
					c-6.86,9.87-7.57,22.55-1.87,33.09c0.01,0.02,0.02,0.04,0.04,0.06l77.86,118.48c1.26,1.92,3.2,3.24,5.45,3.71
					c2.25,0.47,4.55,0.03,6.48-1.24l38.28-25.16c1.92-1.26,3.24-3.2,3.7-5.45c0.47-2.25,0.03-4.55-1.24-6.48l-77.86-118.48
					c-0.01-0.02-0.03-0.04-0.04-0.06c-7.42-9.42-19.34-13.8-31.11-11.42c-0.41,0.08-0.81,0.17-1.22,0.27
					c-0.95,0.23-1.96-0.16-2.5-0.99l-36.2-55.09c-0.1-0.16-0.26-0.27-0.45-0.3C1267.38,70.34,1267.34,70.34,1267.29,70.34z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1367.76,291.11c-1.03,0-2.07-0.16-3.09-0.48l0.49-1.56c2.48,0.78,5.15,0.41,7.32-1.02l15.3-10.05l0.9,1.36l-15.3,10.05
					C1371.68,290.54,1369.73,291.11,1367.76,291.11z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1365.21,287.23c-0.7,0-1.4-0.07-2.1-0.22l0.33-1.6c2.26,0.47,4.55,0.03,6.48-1.24l3.89-2.56l0.89,1.36l-3.89,2.56
					C1369.13,286.65,1367.19,287.23,1365.21,287.23z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1333.6,143.47c-5.36-6.84-14.34-10.15-22.87-8.42l-0.32-1.6c9.27-1.88,18.65,1.58,24.48,9.01L1333.6,143.47z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1247.44,81.99c-0.27,0-0.54-0.03-0.81-0.08c-1.03-0.21-1.92-0.82-2.5-1.7l-4.89-7.45c-1.2-1.82-0.69-4.27,1.13-5.47
					l20.18-13.26c0.88-0.58,1.94-0.78,2.97-0.57c1.03,0.21,1.92,0.82,2.5,1.7l4.89,7.45c1.2,1.82,0.69,4.27-1.13,5.47l-20.18,13.26
					C1248.95,81.77,1248.2,81.99,1247.44,81.99z M1262.71,55.01c-0.45,0-0.89,0.13-1.27,0.38l-20.18,13.26
					c-1.07,0.7-1.37,2.14-0.66,3.21l4.89,7.45c0.34,0.52,0.86,0.87,1.47,1c0.61,0.13,1.23,0.01,1.74-0.33l20.18-13.26
					c1.07-0.7,1.37-2.14,0.66-3.21l-4.89-7.45c-0.34-0.52-0.86-0.87-1.47-1C1263.03,55.03,1262.87,55.01,1262.71,55.01z"
                        />
                    </g>
                </g>
                <path
                    className="st0"
                    d="M1315.03,216.33l29.26,44.53c13.36-5.83,33.07-16.53,52.58-34.78l-37.36-56.85c-19.48,18.17-39.15,28.9-52.58,34.78
			l4.04,6.15c5.98-2.93,12.02-6.19,17.97-9.72l0.83,1.4c-5.93,3.51-11.94,6.76-17.9,9.69l2.25,3.42c5.97-2.92,12.02-6.19,17.97-9.72
			c0.1-0.06,10.3-6.16,12.26-7.81l1.05,1.25c-2.06,1.73-12.05,7.71-12.47,7.96C1327,210.16,1320.98,213.41,1315.03,216.33z"
                />
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="1255.94"
                            y="72.12"
                            transform="matrix(0.8357 -0.5492 0.5492 0.8357 161.8015 708.9485)"
                            width="19.74"
                            height="23.84"
                        />
                    </g>
                    <g>
                        <path
                            className="st0"
                            d="M1263.87,100.56l-13.99-21.29l17.86-11.74l13.99,21.29L1263.87,100.56z M1252.14,79.74l12.2,18.57l15.14-9.95
					l-12.2-18.57L1252.14,79.74z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1103.74,249.57c-1.5-0.45-3.08-0.69-4.73-0.69c-9.07,0-16.42,7.35-16.42,16.42c0,2.49,0.55,4.85,1.55,6.96l-0.92,1.06
					l-18.05,3.26l10.56-16.9c0,0-26.12,2.3-26.69,2.3c-0.58,0,13.06-15.75,13.06-15.75l-19.39-21.32l25.16,6.34V208.6l20.16,20.36
					l9.03-17.48L1103.74,249.57z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1115.13,352.25c-9.5,0-17.24-7.73-17.24-17.24c0-2.45,0.5-4.81,1.49-7.02l0.24-0.53l0.58,0.05
						c0.45,0.04,0.92,0.06,1.39,0.06c6.15,0,11.75-3.63,14.26-9.25l0.24-0.53l0.58,0.05c8.81,0.77,15.7,8.31,15.7,17.17
						C1132.37,344.52,1124.64,352.25,1115.13,352.25z M1100.66,329.18c-0.75,1.85-1.13,3.81-1.13,5.84c0,8.6,7,15.61,15.61,15.61
						s15.61-7,15.61-15.61c0-7.83-5.95-14.52-13.65-15.48c-2.88,5.89-8.9,9.67-15.49,9.67
						C1101.28,329.2,1100.97,329.19,1100.66,329.18z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1100.06,329.13c-8.81-0.77-15.7-8.31-15.7-17.17c0-9.5,7.73-17.24,17.24-17.24s17.24,7.73,17.24,17.24
						c0,2.44-0.5,4.81-1.49,7.02l-1.49-0.66c0.9-2.01,1.35-4.15,1.35-6.36c0-8.6-7-15.61-15.61-15.61s-15.61,7-15.61,15.61
						c0,8.02,6.24,14.84,14.22,15.54L1100.06,329.13z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1130.98,331.22h-0.08v-1.63h0.08c8.6,0,15.61-7,15.61-15.61c0-3.75-1.35-7.38-3.81-10.22
						c-2.97-3.42-7.27-5.39-11.8-5.39c-0.19,0-0.38,0-0.57,0.01l-0.06-1.63c0.21-0.01,0.42-0.01,0.63-0.01c5,0,9.75,2.17,13.03,5.95
						c2.71,3.13,4.21,7.14,4.21,11.29C1148.22,323.49,1140.48,331.22,1130.98,331.22z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1117.51,308.3l-0.22-1.61c7.67-1.06,13.45-7.7,13.45-15.46c0-8.6-7-15.61-15.61-15.61s-15.61,7-15.61,15.61
						c0,1.46,0.2,2.89,0.59,4.25l-1.57,0.45c-0.43-1.51-0.65-3.09-0.65-4.7c0-9.5,7.73-17.24,17.24-17.24s17.24,7.73,17.24,17.24
						C1132.37,299.79,1125.98,307.13,1117.51,308.3z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1086.68,305.59c-8.19-1.49-14.13-8.62-14.13-16.95c0-9.5,7.73-17.24,17.23-17.24c5.82,0,11.21,2.91,14.41,7.78
						l-1.36,0.9c-2.9-4.41-7.78-7.05-13.05-7.05c-8.6,0-15.6,7-15.6,15.61c0,7.55,5.38,14,12.79,15.35L1086.68,305.59z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1143.48,304.04l-0.17-1.62c7.94-0.84,13.94-7.52,13.94-15.52c0-8.6-7-15.61-15.61-15.61c-6.03,0-11.41,3.37-14.04,8.8
						l-1.47-0.71c2.87-5.9,8.95-9.71,15.51-9.71c9.5,0,17.24,7.73,17.24,17.24C1158.87,295.74,1152.25,303.11,1143.48,304.04z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1140.76,270.96l-1.41-0.81c1.34-2.34,2.05-5.02,2.05-7.74c0-8.6-7-15.61-15.61-15.61c-5.7,0-10.94,3.1-13.69,8.1
						l-1.43-0.78c3.03-5.52,8.82-8.95,15.11-8.95c9.5,0,17.24,7.73,17.24,17.24C1143.03,265.42,1142.25,268.37,1140.76,270.96z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1112.86,275.55l-1.31-0.97c0.41-0.56,0.8-1.16,1.14-1.78c1.25-2.29,1.92-4.88,1.92-7.5c0-3.77-1.36-7.4-3.83-10.24
						c-1.94-2.23-4.46-3.86-7.28-4.7c-1.44-0.44-2.96-0.66-4.5-0.66c-8.6,0-15.61,7-15.61,15.61c0,2.32,0.49,4.54,1.47,6.61
						c0.12,0.27,0.25,0.52,0.38,0.77l-1.43,0.78c-0.15-0.28-0.3-0.57-0.43-0.86c-1.07-2.28-1.62-4.74-1.62-7.3
						c0-9.5,7.73-17.24,17.24-17.24c1.7,0,3.37,0.24,4.97,0.73c3.11,0.93,5.89,2.73,8.04,5.2c2.73,3.14,4.23,7.15,4.23,11.31
						c0,2.89-0.73,5.76-2.12,8.29C1113.74,274.27,1113.32,274.93,1112.86,275.55z"
                            />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1112.69,252.48c-2.1-4.93-2.4-8.9-2.28-11.64c0.22-5.05,1.91-6.82,2.86-14.58c0.43-3.53,0.71-5.81,0.29-8.93
					c-0.39-2.91-1.04-7.73-4.9-11.81c-4.24-4.49-9.68-5.35-11.63-5.51c-3.93-0.32-7.09,0.32-7.09,0.32
					c21.03,0.58,23.33,19.3,19.3,30.82c-4.03,11.52,1.15,22.76,1.15,22.76l1.01,0.6l1.59-2.19"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1111.68,226.26c0,0,9.36-3.02,8.79-12.96c-0.58-9.94-3.17-13.25-3.17-13.25s3.89,3.6,4.61,7.06
					c0.72,3.46,2.59,11.52-2.45,15.99c-5.04,4.47-7.49,6.34-8.21,9.22L1111.68,226.26z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="1087.27" y="319.17" width="15.15" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="1090.45" y="323.2" width="11.97" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="1084.94" y="310.91" width="17.48" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="1085.62" y="314.94" width="16.8" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="1087.91" y="302.08" width="14.5" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="1085.96" y="306.11" width="16.45" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <rect className="st0" x="1075.55" y="295.55" width="12.22" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="1078.73" y="299.58" width="5.44" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="1073.22" y="287.29" width="17.48" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="1073.9" y="291.32" width="16.8" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="1076.2" y="278.46" width="7.97" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="1074.25" y="282.49" width="13.38" height="1.63" />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <rect className="st0" x="1081.17" y="266.88" width="12.22" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="1078.84" y="258.63" width="17.48" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="1079.52" y="262.66" width="16.8" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="1079.87" y="253.82" width="13.38" height="1.63" />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="1098.72" y="336.79" width="15.99" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="1099.72" y="340.82" width="11.52" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <polygon
                            className="st0"
                            points="1174.22,219.87 1158.64,186.41 1147.23,210.39 1133.39,163.26 1134.95,162.8 1147.59,205.84 1158.66,182.58 
					1174.19,215.95 1185.2,191.44 1215.97,192.27 1197.86,169.71 1217.85,151.87 1176.9,156.36 1186.9,134.43 1136.77,160.03 
					1136.03,158.58 1190.32,130.85 1179.57,154.43 1222.74,149.7 1200.1,169.89 1219.44,194 1186.25,193.1 				"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1136.38,159.33l-2.8,1.26l0.58,2.44c-1.7-1.27-4.33-2.9-8.36-4.8c-9.79-4.61-5.57-14.98-5.57-14.98
					s0.24,2.64,1.08,5.45c1.18,3.95,4.07,5.97,4.68,6.46c1.85,1.47,3.54,2.08,5.76,2.88c1.73,0.62,3.31,1.01,4.64,1.26
					L1136.38,159.33z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1079.27,180.12c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
					S1082.26,180.12,1079.27,180.12z M1079.27,170.91c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
					c2.09,0,3.79-1.7,3.79-3.79C1083.07,172.61,1081.36,170.91,1079.27,170.91z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1038.37,297.95c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
					C1043.79,295.51,1041.36,297.95,1038.37,297.95z M1038.37,288.73c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79
					c2.09,0,3.79-1.7,3.79-3.79S1040.46,288.73,1038.37,288.73z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1164.54,266.26c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42s5.42,2.43,5.42,5.42
					C1169.96,263.82,1167.53,266.26,1164.54,266.26z M1164.54,257.04c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
					c2.09,0,3.79-1.7,3.79-3.79C1168.33,258.74,1166.63,257.04,1164.54,257.04z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1162.24,330.5c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
					C1167.66,328.06,1165.23,330.5,1162.24,330.5z M1162.24,321.28c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
					c2.09,0,3.79-1.7,3.79-3.79C1166.03,322.98,1164.33,321.28,1162.24,321.28z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <polygon
                            className="st0"
                            points="1217.41,193.9 1139.05,163.36 1133.32,161.35 1133.86,159.81 1139.62,161.83 1218,192.38 				"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <polygon
                            className="st0"
                            points="1170.21,187.63 1167.82,173.5 1180.79,167.49 1181.48,168.97 1169.64,174.46 1171.82,187.36 				"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <polygon
                            className="st0"
                            points="1149.93,179.84 1148.3,179.67 1149.66,166.67 1157.95,159.24 1159.04,160.46 1151.22,167.46 				"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1300.62,332.29l-3.25,13.48c-0.08,0.38-0.16,0.76-0.25,1.14c-3.67,15.22-18.99,24.59-34.21,20.92
					c-15.23-3.67-24.59-18.99-20.92-34.22c0.1-0.4,0.2-0.8,0.32-1.2l3.23-13.4c0,0,8.64,15.52,20.14,15.33
					S1285.43,318.56,1300.62,332.29z"
                        />
                    </g>
                    <g>
                        <path
                            className="st0"
                            d="M1269.55,369.44c-2.26,0-4.55-0.26-6.84-0.82c-7.58-1.83-13.99-6.49-18.05-13.14c-4.07-6.65-5.3-14.48-3.47-22.06
					c0.1-0.42,0.21-0.83,0.33-1.23l3.71-15.4l1.02,1.82c0.08,0.15,8.44,14.91,19.22,14.91c0.06,0,0.13,0,0.19,0
					c3.74-0.06,7.12-1.83,10.69-3.71c7.07-3.71,14.39-7.55,24.81,1.88l0.36,0.32l-3.36,13.95c-0.07,0.35-0.15,0.75-0.25,1.14
					C1294.7,360.45,1282.72,369.44,1269.55,369.44z M1245.86,321.12l-2.77,11.49c-0.12,0.42-0.22,0.81-0.32,1.2
					c-1.72,7.15-0.56,14.55,3.28,20.82c3.84,6.28,9.89,10.68,17.04,12.41c14.76,3.56,29.67-5.56,33.23-20.32
					c0.09-0.37,0.17-0.74,0.24-1.11l3.14-13.03c-9.38-8.25-15.8-4.88-22.6-1.32c-3.59,1.88-7.3,3.83-11.42,3.9
					C1256.06,335.3,1248.54,325.24,1245.86,321.12z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1264.26,324.94c-11.6,0-16.45-14.38-16.5-14.53l-0.07-0.22l6.6-27.39l1.58,0.38l-6.49,26.93
					c0.65,1.78,5.59,14.19,16.17,13.13l0.16,1.62C1265.22,324.91,1264.73,324.94,1264.26,324.94z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1264.82,308.38c-0.1-0.01-10.25-0.71-13.69-12l1.56-0.47c3.09,10.17,11.85,10.82,12.22,10.84L1264.82,308.38z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1249.58,450.64l-1.62-0.14c2.67-30.48,10.63-55.61,15.14-67.87c0.43-1.17,1.59-1.93,2.85-1.84
						c17.88,1.31,33.91-10.41,38.12-27.87c0.12-0.49,0.23-0.98,0.32-1.47l16.13-66.92c0.07-0.31,0.02-0.63-0.14-0.9
						c-0.17-0.27-0.43-0.46-0.74-0.54l-68.64-16.55c-0.31-0.07-0.63-0.02-0.9,0.14c-0.27,0.17-0.46,0.43-0.54,0.74l-16.1,66.79
						c-0.15,0.53-0.29,1.03-0.42,1.58c-4.21,17.45,4.72,35.19,21.23,42.18c1.15,0.49,1.85,1.7,1.7,2.94
						c-1.58,12.97-5.94,38.97-17.45,67.32l-1.51-0.61c11.44-28.18,15.78-54.01,17.34-66.9c0.06-0.53-0.22-1.03-0.72-1.24
						c-17.25-7.3-26.57-25.83-22.17-44.06c0.14-0.57,0.28-1.1,0.43-1.62l16.09-66.75c0.18-0.73,0.63-1.35,1.27-1.74
						c0.64-0.39,1.4-0.51,2.13-0.34l68.64,16.55c0.73,0.18,1.35,0.63,1.74,1.27c0.39,0.64,0.51,1.4,0.34,2.13l-16.13,66.88
						c-0.09,0.48-0.2,0.99-0.33,1.5c-4.4,18.23-21.15,30.48-39.82,29.11c-0.53-0.04-1.02,0.28-1.2,0.77
						C1260.15,395.38,1252.24,420.35,1249.58,450.64z"
                            />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1268.92,468.25c-8.69,0-18.95-1.34-29.48-3.88c-12.57-3.03-24.03-7.41-32.27-12.33c-8.5-5.08-12.62-10.23-11.59-14.49
					c1.96-8.14,22.46-10.17,47.68-4.7l-0.34,1.59c-23.53-5.1-44.06-3.53-45.75,3.49c-0.84,3.46,3.12,8.1,10.84,12.71
					c8.1,4.84,19.4,9.15,31.81,12.15c12.41,2.99,24.44,4.3,33.85,3.69c8.98-0.59,14.61-2.91,15.44-6.37
					c1.68-6.96-15.71-17.67-38.76-23.86l0.42-1.57c11.65,3.13,22.14,7.41,29.52,12.06c7.79,4.9,11.39,9.66,10.4,13.76
					c-1.03,4.27-7.04,6.97-16.92,7.62C1272.22,468.2,1270.6,468.25,1268.92,468.25z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="1211.51"
                            y="425.16"
                            transform="matrix(0.2344 -0.9721 0.9721 0.2344 498.8919 1516.6362)"
                            width="1.63"
                            height="32.84"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="1207.37"
                            y="436.05"
                            transform="matrix(0.2344 -0.9721 0.9721 0.2344 491.2127 1516.1476)"
                            width="1.63"
                            height="20.31"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="1211"
                            y="425.3"
                            transform="matrix(0.2344 -0.9721 0.9721 0.2344 505.2543 1510.8291)"
                            width="1.63"
                            height="18.68"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1416.53,156.09c-0.93,0.73-2.11,1.15-3.27,1.23l-0.57,0.05c-0.17,0.02-0.35,0.03-0.52,0.04
						c-0.35,0.02-0.7,0.02-1.05,0l-4.19-0.39l-2.09-0.22l-0.26-0.03c-0.08-0.01-0.2-0.02-0.22-0.03c-0.1-0.02-0.2-0.03-0.3-0.03
						c-0.21-0.01-0.42,0.02-0.63,0.08c-0.21,0.06-0.42,0.16-0.62,0.29c-0.1,0.07-0.19,0.14-0.28,0.22l-0.13,0.14l-0.17,0.2
						l-1.34,1.63c-0.91,1.08-1.8,2.17-2.73,3.23c0.49-1.32,1.02-2.63,1.53-3.94l0.79-1.96l0.1-0.25c0.04-0.09,0.1-0.2,0.15-0.3
						c0.11-0.2,0.24-0.4,0.38-0.58c0.28-0.38,0.63-0.71,1.03-0.99c0.39-0.28,0.84-0.5,1.31-0.65c0.24-0.07,0.48-0.13,0.73-0.17
						l0.55-0.09l2.08-0.35l4.15-0.66c0.35-0.05,0.69-0.07,1.04-0.07c0.17,0,0.35,0,0.52,0l0.26,0.01l0.13,0l0.06,0l0.02-0.01
						c0.23,0.01,0.41-0.07,0.58-0.19c0.16-0.13,0.29-0.32,0.36-0.53c0.06-0.21,0.05-0.41,0-0.61c0-0.05-0.05-0.1-0.06-0.16
						c0,0-0.02-0.05-0.04-0.08l-0.06-0.12l-0.12-0.24l-0.47-0.93l-0.94-1.86l-1.88-3.72l-3.76-7.45l-0.12-0.23
						c-0.04-0.08-0.07-0.13-0.14-0.29c-0.11-0.27-0.24-0.53-0.31-0.82c-0.09-0.28-0.16-0.57-0.19-0.87
						c-0.05-0.29-0.06-0.59-0.06-0.89c0.01-1.19,0.41-2.35,1.08-3.3c0.67-0.96,1.67-1.71,2.78-2.11c0.56-0.21,1.14-0.31,1.72-0.34
						l1.07-0.04l8.34-0.27l4.17-0.13l2.08-0.06c0.06,0,0.05,0,0.07-0.01l0.04-0.01l0.08,0c0.05-0.03,0.11-0.02,0.15-0.04
						c0.04-0.02,0.1-0.03,0.14-0.06c0.02-0.01,0.05-0.02,0.07-0.03c0.02-0.02,0.04-0.04,0.06-0.05c0.17-0.11,0.31-0.31,0.38-0.51
						c0.08-0.21,0.08-0.41,0.02-0.61c-0.01-0.05-0.03-0.1-0.06-0.15c0.01-0.01-0.11-0.23-0.18-0.37l-0.47-0.93l-0.94-1.86
						l-3.76-7.45l-1.88-3.72l-0.24-0.47l-0.06-0.12l-0.03-0.06l-0.04-0.1l-0.17-0.4c-0.12-0.26-0.18-0.56-0.26-0.84
						c-0.06-0.29-0.1-0.59-0.13-0.88c-0.07-1.19,0.22-2.38,0.82-3.39c0.61-1,1.52-1.83,2.6-2.32c0.55-0.23,1.12-0.4,1.7-0.47
						l0.44-0.04c0.08-0.01,0.12-0.01,0.16-0.01l0.13,0l0.52-0.02l4.17-0.13l1.04-0.03l0.52-0.02l0.12,0l0.04,0
						c0.03,0,0.05-0.01,0.08-0.01c0.21-0.03,0.41-0.13,0.56-0.27c0.04-0.03,0.07-0.08,0.11-0.12l0.05-0.07
						c0.02-0.03,0.01-0.01,0.07-0.11l3.73-6.28c0.35-0.6,0.72-1.19,1.05-1.8c0.17-0.3,0.32-0.62,0.49-0.92
						c0.18-0.3,0.37-0.59,0.58-0.87c0.69-0.88,1.38-1.77,2.07-2.65c0.11-0.15,0.23-0.29,0.34-0.44c0.12-0.15,0.23-0.29,0.35-0.44
						l3.13,1.86c-0.07,0.17-0.14,0.34-0.22,0.51c-0.07,0.17-0.14,0.34-0.22,0.51c-0.44,1.03-0.89,2.06-1.34,3.09
						c-0.15,0.32-0.3,0.63-0.49,0.93c-0.18,0.3-0.38,0.58-0.57,0.87c-0.37,0.59-0.72,1.19-1.07,1.79l-3.73,6.28
						c-0.03,0.05-0.13,0.22-0.21,0.33c-0.08,0.12-0.17,0.24-0.26,0.36c-0.18,0.23-0.38,0.45-0.6,0.65
						c-0.86,0.81-1.97,1.34-3.13,1.52c-0.15,0.02-0.29,0.04-0.44,0.05l-0.22,0.02l-0.14,0.01l-0.52,0.02l-1.04,0.03l-4.17,0.13
						l-0.52,0.02l-0.13,0c-0.04,0-0.09,0-0.09,0.01c-0.03,0-0.05,0.01-0.08,0.01c-0.11,0-0.21,0.04-0.31,0.08
						c-0.18,0.09-0.34,0.23-0.46,0.42c-0.11,0.19-0.16,0.41-0.15,0.61c0.01,0.05,0.01,0.1,0.02,0.15c0.03,0.05,0.02,0.1,0.05,0.15
						c0.01,0.02,0.02,0.05,0.03,0.08l0.01,0.02l0.03,0.05l0.06,0.12l0.23,0.47l1.88,3.73l3.76,7.45l0.94,1.86l0.47,0.93
						c0.08,0.17,0.14,0.25,0.27,0.57c0.12,0.27,0.23,0.54,0.3,0.82c0.32,1.14,0.28,2.39-0.12,3.49c-0.39,1.11-1.11,2.09-2.07,2.78
						l-0.37,0.25c-0.13,0.08-0.26,0.15-0.39,0.21c-0.26,0.15-0.54,0.25-0.82,0.35s-0.57,0.15-0.86,0.22
						c-0.14,0.02-0.29,0.04-0.43,0.05l-0.22,0.02c-0.07,0-0.16,0.01-0.19,0.01l-2.09,0.07l-4.17,0.13l-8.35,0.26l-1.02,0.03
						c-0.12,0.02-0.22,0.02-0.32,0.06c-0.18,0.07-0.37,0.18-0.49,0.37c-0.12,0.18-0.21,0.39-0.2,0.6c0,0.05,0,0.1,0.01,0.15
						c0,0.05,0.02,0.1,0.03,0.15c0,0.06,0.05,0.1,0.06,0.16c0,0,0.05,0.11,0.09,0.18l0.12,0.23l3.76,7.45l1.88,3.72l0.94,1.86
						l0.47,0.93l0.18,0.35c0.02,0.04,0.03,0.07,0.07,0.15c0.11,0.27,0.24,0.53,0.32,0.82c0.36,1.12,0.36,2.38,0,3.5
						C1418.15,154.36,1417.46,155.36,1416.53,156.09z"
                            />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1479.04,100.64c-2.05,0-4.07-0.55-5.88-1.62c-5.49-3.29-7.28-10.38-4.04-15.84c3.24-5.46,10.32-7.28,15.79-4.07
					c2.7,1.6,4.58,4.12,5.35,7.11c0.76,2.99,0.31,6.1-1.26,8.76l0,0c-1.58,2.65-4.09,4.54-7.08,5.3
					C1480.96,100.52,1480,100.64,1479.04,100.64z M1479.07,79.17c-3.4,0-6.7,1.74-8.55,4.85c-2.78,4.69-1.24,10.78,3.43,13.58
					c2.32,1.38,4.99,1.76,7.56,1.11c2.57-0.65,4.73-2.27,6.08-4.55l0,0c1.35-2.28,1.74-4.95,1.08-7.52
					c-0.66-2.57-2.27-4.73-4.55-6.08C1482.54,79.61,1480.79,79.17,1479.07,79.17z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1473.12,98.99l-46.79-27.78c-2.65-1.58-4.54-4.09-5.3-7.08c-0.76-2.99-0.32-6.1,1.26-8.75
					c1.58-2.66,4.09-4.54,7.08-5.3c2.99-0.76,6.1-0.31,8.76,1.26l46.79,27.78l-0.83,1.4c-4.7-2.76-10.78-1.19-13.56,3.49
					c-2.78,4.69-1.24,10.78,3.43,13.58L1473.12,98.99z M1432.24,51.34c-0.82,0-1.65,0.1-2.47,0.31c-2.57,0.65-4.73,2.27-6.08,4.55
					c-1.35,2.28-1.74,4.95-1.08,7.52c0.66,2.57,2.27,4.73,4.55,6.08l41.76,24.79c-1.87-3.46-1.94-7.8,0.21-11.42
					c2.15-3.62,5.99-5.64,9.93-5.65l-41.76-24.79C1435.74,51.82,1434,51.34,1432.24,51.34z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1473.11,98.99c-5.44-3.26-7.23-10.35-3.99-15.81c3.24-5.46,10.32-7.28,15.79-4.07l-0.83,1.4
					c-4.7-2.76-10.78-1.19-13.56,3.49c-2.78,4.69-1.24,10.78,3.43,13.58L1473.11,98.99z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="1437.78"
                            y="82.14"
                            transform="matrix(0.8599 0.5105 -0.5105 0.8599 247.4579 -725.1425)"
                            width="13.77"
                            height="12.12"
                        />
                    </g>
                    <g>
                        <path
                            className="st0"
                            d="M1447.78,98.05l-13.24-7.86l7.02-11.82l13.24,7.86L1447.78,98.05z M1436.77,89.61l10.44,6.2l5.36-9.02l-10.44-6.2
					L1436.77,89.61z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1091.31,131.22c-47.23-24.74-57.82-87.48-59.47-99.81l1.62-0.22c1.62,12.19,12.09,74.21,58.61,98.58L1091.31,131.22z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1032.89,32.32l-0.43-0.2c-0.13-0.06-13.34-6.55-4.37-28.18l0.51-1.23l0.86,1.02c0.4,0.47,9.69,11.53,8.51,20.73
					c-0.4,3.14-1.99,5.7-4.7,7.59L1032.89,32.32z M1029.08,5.86c-6.62,17.21,1.63,23.36,3.66,24.56c2.08-1.59,3.29-3.66,3.62-6.17
					C1037.25,17.34,1031.35,8.83,1029.08,5.86z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1038.01,42.89c-1.2,0-2.42-0.2-3.67-0.61l-0.45-0.15l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.41l1.29-0.33l-0.3,1.3
					c-0.14,0.6-3.41,14.67-11.47,19.25C1041.57,42.42,1039.83,42.89,1038.01,42.89z M1035.32,40.87c2.52,0.71,4.91,0.43,7.11-0.81
					c6.07-3.44,9.33-13.27,10.34-16.86C1035.03,28.24,1035.07,38.52,1035.32,40.87z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1041.94,58.28c-1.2,0-2.42-0.2-3.67-0.61l-0.45-0.15l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.41l1.29-0.33l-0.3,1.3
					c-0.14,0.6-3.41,14.67-11.47,19.25C1045.51,57.81,1043.76,58.28,1041.94,58.28z M1039.26,56.26c2.52,0.71,4.91,0.43,7.11-0.81
					c6.07-3.44,9.33-13.27,10.34-16.86C1038.96,43.63,1039,53.92,1039.26,56.26z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1047.74,74.76c-1.2,0-2.42-0.2-3.67-0.61l-0.45-0.15l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.41l1.29-0.33l-0.29,1.3
					c-0.14,0.6-3.41,14.67-11.48,19.25C1051.3,74.29,1049.55,74.76,1047.74,74.76z M1045.05,72.74c2.52,0.71,4.91,0.43,7.11-0.81
					c6.07-3.44,9.33-13.27,10.34-16.86C1044.75,60.11,1044.79,70.4,1045.05,72.74z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1055.47,90.98c-1.2,0-2.42-0.2-3.67-0.61l-0.45-0.15l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.4l1.29-0.33l-0.3,1.3
					c-0.14,0.6-3.41,14.67-11.48,19.24C1059.04,90.5,1057.29,90.98,1055.47,90.98z M1052.79,88.96c2.52,0.71,4.91,0.44,7.11-0.81
					c6.07-3.43,9.33-13.26,10.34-16.85C1052.49,76.33,1052.53,86.61,1052.79,88.96z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1064.79,105.65c-1.2,0-2.42-0.2-3.67-0.61l-0.45-0.15l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.4l1.29-0.33l-0.3,1.3
					c-0.14,0.6-3.41,14.67-11.48,19.24C1068.35,105.18,1066.6,105.65,1064.79,105.65z M1062.11,103.63c2.52,0.7,4.9,0.43,7.1-0.81
					c6.07-3.44,9.33-13.26,10.34-16.85C1061.98,90.96,1061.89,101.33,1062.11,103.63z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1027.66,49.05c-0.12,0-0.24,0-0.37-0.01c-9.27-0.28-18.73-11.21-19.12-11.68l-0.87-1.01l1.3-0.31
					c22.78-5.44,27.09,8.63,27.13,8.77l0.13,0.45l-0.33,0.34C1033.32,47.89,1030.67,49.05,1027.66,49.05z M1010.34,37.32
					c2.57,2.7,10.04,9.88,17.01,10.09c2.54,0.08,4.77-0.8,6.67-2.59C1033.14,42.63,1028.39,33.5,1010.34,37.32z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1033.32,68.35c-0.12,0-0.24,0-0.37-0.01c-9.27-0.28-18.73-11.21-19.12-11.68l-0.87-1.01l1.3-0.31
					c22.78-5.44,27.09,8.62,27.13,8.77l0.13,0.45l-0.33,0.34C1038.99,67.19,1036.34,68.35,1033.32,68.35z M1016.01,56.62
					c2.57,2.7,10.04,9.88,17.01,10.09c2.54,0.07,4.77-0.8,6.67-2.59C1038.81,61.93,1034.05,52.81,1016.01,56.62z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1040.25,85.7c-0.12,0-0.24,0-0.36-0.01c-9.27-0.28-18.73-11.21-19.12-11.68l-0.87-1.01l1.3-0.31
					c22.78-5.44,27.09,8.62,27.13,8.77l0.13,0.45l-0.33,0.34C1045.91,84.54,1043.26,85.7,1040.25,85.7z M1022.93,73.97
					c2.57,2.7,10.04,9.88,17.01,10.09c2.52,0.07,4.77-0.8,6.66-2.59C1045.73,79.28,1040.97,70.16,1022.93,73.97z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1049.31,104.29c-8.92,0-18.77-8.75-19.2-9.14l-0.99-0.89l1.25-0.48c21.87-8.36,27.99,5.01,28.05,5.15l0.19,0.43
					l-0.28,0.38c-1.97,2.66-4.57,4.17-7.72,4.49C1050.16,104.27,1049.74,104.29,1049.31,104.29z M1032.25,94.82
					c2.9,2.34,11.24,8.49,18.18,7.79c2.52-0.25,4.62-1.41,6.27-3.44C1055.55,97.1,1049.65,88.68,1032.25,94.82z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1057.09,120.86c-7.57,0-15.58-3.74-16.01-3.94l-1.21-0.57l1.06-0.81c18.57-14.27,28.25-3.19,28.35-3.08l0.3,0.36
					l-0.16,0.44c-1.13,3.11-3.19,5.3-6.12,6.51C1061.4,120.55,1059.26,120.86,1057.09,120.86z M1043.04,115.99
					c3.45,1.42,13.2,4.92,19.65,2.27c2.34-0.96,4.03-2.67,5.02-5.09C1066.03,111.52,1057.96,105.14,1043.04,115.99z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1401.46,453c-1.53-6.09-3.51-12.35-5.89-18.6c-5.16-13.58-4.97-25.87,0.56-36.53c11-21.18,39.75-28.72,40.04-28.79
					l0.4,1.58c-0.28,0.07-28.34,7.44-39,27.97c-5.31,10.23-5.47,22.07-0.48,35.19c2.4,6.31,4.4,12.63,5.94,18.78L1401.46,453z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1442.9,373.36c-2.86,0-5.41-1.07-7.61-3.19l-0.34-0.33l0.12-0.46c0.04-0.14,3.89-14.34,26.83-9.65l1.31,0.27
					l-0.83,1.04c-0.38,0.48-9.48,11.71-18.73,12.3C1443.4,373.35,1443.15,373.36,1442.9,373.36z M1436.8,369.33
					c1.96,1.74,4.22,2.54,6.75,2.37c6.96-0.44,14.19-7.86,16.67-10.64C1442.05,357.84,1437.59,367.11,1436.8,369.33z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1424.46,374.83l-0.32-0.35c-0.1-0.11-9.83-11.14,6.71-27.71l0.94-0.94l0.41,1.27c0.19,0.58,4.56,14.36-0.05,22.41
					c-1.57,2.75-4.01,4.51-7.24,5.23L1424.46,374.83z M1431.04,348.92c-12.69,13.39-7.4,22.21-5.99,24.1
					c2.53-0.68,4.44-2.13,5.7-4.32C1434.21,362.65,1432,352.53,1431.04,348.92z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1409.13,383.79l-0.36-0.31c-0.11-0.1-11.03-9.96,3.54-28.3l0.83-1.04l0.55,1.21c0.25,0.56,6.15,13.75,2.48,22.27
					c-1.25,2.91-3.47,4.93-6.61,6.01L1409.13,383.79z M1412.74,357.3c-10.98,14.6-4.78,22.91-3.22,24.62
					c2.43-0.96,4.17-2.62,5.17-4.94C1417.44,370.57,1414.1,360.78,1412.74,357.3z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1399.17,395.42l-0.46-0.12c-0.14-0.04-14.29-4.09-9.27-26.97l0.28-1.3l1.03,0.85c0.47,0.39,11.58,9.64,12.03,18.9
					c0.16,3.16-0.95,5.96-3.29,8.31L1399.17,395.42z M1390.75,370.05c-3.48,18.11,5.73,22.7,7.94,23.53
					c1.76-1.93,2.59-4.19,2.47-6.71C1400.82,379.9,1393.5,372.57,1390.75,370.05z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1392.38,405.32c-4.26,0-13.93-2.03-19.08-19.28l-0.38-1.28l1.31,0.24c0.6,0.11,14.8,2.82,19.69,10.7
					c1.67,2.69,2.06,5.67,1.15,8.86l-0.13,0.45l-0.46,0.11C1394.45,405.14,1393.65,405.32,1392.38,405.32z M1375.27,386.9
					c5.74,17.53,16.02,17.08,18.35,16.73c0.61-2.54,0.24-4.92-1.09-7.07C1388.85,390.64,1378.9,387.77,1375.27,386.9z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1387.92,422.51c-4.94,0-12.63-2.44-19.68-14.88l-0.66-1.16l1.33-0.06c0.61-0.03,15.05-0.56,21.58,6.03
					c2.23,2.25,3.27,5.07,3.1,8.38l-0.02,0.47l-0.42,0.21C1393.1,421.52,1391.06,422.51,1387.92,422.51z M1370.35,408.03
					c9.51,15.8,19.43,13.06,21.63,12.2c0.02-2.61-0.87-4.85-2.65-6.64C1384.43,408.63,1374.08,408.07,1370.35,408.03z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1433.95,388.39c-4.18,0-8.84-0.66-12.23-2.98c-2.61-1.79-4.17-4.36-4.62-7.64l-0.06-0.47l0.37-0.29
					c0.12-0.09,11.9-8.89,27.08,8.94l0.86,1.02l-1.3,0.31C1443.72,387.35,1439.21,388.39,1433.95,388.39z M1418.8,378.04
					c0.47,2.57,1.76,4.59,3.85,6.02c5.76,3.94,16.01,2.56,19.68,1.89C1430.13,372.36,1420.78,376.83,1418.8,378.04z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1414.74,397.59c-2.26,0-4.48-0.32-6.46-1.16c-2.92-1.24-4.95-3.45-6.05-6.57l-0.16-0.44l0.31-0.36
					c0.1-0.11,9.9-11.08,28.31,3.39l1.05,0.82l-1.21,0.56C1430.11,394.02,1422.24,397.59,1414.74,397.59z M1403.95,389.78
					c0.97,2.43,2.64,4.16,4.97,5.15c6.42,2.72,16.2-0.67,19.67-2.05C1413.79,381.86,1405.65,388.15,1403.95,389.78z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1405.33,415.98c-2.26,0-4.48-0.32-6.46-1.16c-2.92-1.24-4.95-3.45-6.05-6.57l-0.16-0.44l0.31-0.36
					c0.1-0.11,9.9-11.08,28.31,3.39l1.05,0.82l-1.21,0.56C1420.7,412.41,1412.82,415.98,1405.33,415.98z M1394.54,408.17
					c0.97,2.43,2.64,4.16,4.97,5.15c6.42,2.72,16.2-0.67,19.67-2.05C1404.38,400.25,1396.24,406.54,1394.54,408.17z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1401.75,433.19c-2.75,0-5.24-1.01-7.41-3.02l-0.35-0.32l0.1-0.46c0.03-0.14,3.56-14.43,26.6-10.28l1.31,0.24
					l-0.81,1.06c-0.37,0.49-9.2,11.93-18.44,12.73C1402.42,433.18,1402.08,433.19,1401.75,433.19z M1395.82,429.31
					c1.99,1.69,4.28,2.43,6.79,2.21c6.94-0.6,14-8.19,16.41-11.03C1400.97,417.73,1396.58,427.13,1395.82,429.31z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1154.34,408.38c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C1159.76,405.95,1157.33,408.38,1154.34,408.38z M1154.34,399.16c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79C1158.13,400.86,1156.43,399.16,1154.34,399.16z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1137.76,461.21c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C1143.18,458.77,1140.75,461.21,1137.76,461.21z M1137.76,451.99c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79C1141.55,453.69,1139.85,451.99,1137.76,451.99z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1350.13,364.69c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C1355.55,362.26,1353.12,364.69,1350.13,364.69z M1350.13,355.47c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79C1353.92,357.18,1352.22,355.47,1350.13,355.47z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1454.72,302.83c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C1460.14,300.4,1457.71,302.83,1454.72,302.83z M1454.72,293.61c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79C1458.51,295.31,1456.81,293.61,1454.72,293.61z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1257.65,229.7c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				S1260.64,229.7,1257.65,229.7z M1257.65,220.48c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79c2.09,0,3.79-1.7,3.79-3.79
				C1261.45,222.19,1259.75,220.48,1257.65,220.48z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1202.17,113.42c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				S1205.16,113.42,1202.17,113.42z M1202.17,104.2c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79c2.09,0,3.79-1.7,3.79-3.79
				S1204.26,104.2,1202.17,104.2z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1310.87,30.93c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42s5.42,2.43,5.42,5.42S1313.86,30.93,1310.87,30.93z
				 M1310.87,21.72c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79c2.09,0,3.79-1.7,3.79-3.79S1312.96,21.72,1310.87,21.72z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1130.74,44.54c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C1136.16,42.11,1133.73,44.54,1130.74,44.54z M1130.74,35.32c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79C1134.53,37.02,1132.83,35.32,1130.74,35.32z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1452.81,462.91c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C1458.23,460.47,1455.8,462.91,1452.81,462.91z M1452.81,453.69c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79C1456.6,455.39,1454.9,453.69,1452.81,453.69z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1344.39,465.46c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C1349.81,463.03,1347.38,465.46,1344.39,465.46z M1344.39,456.24c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79C1348.18,457.94,1346.48,456.24,1344.39,456.24z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1120.24,453.39l-6.88,3.15l-0.3-0.92c-2.45-5.35-8.79-7.71-14.13-5.26c-5.35,2.45-7.71,8.78-5.26,14.13l0.02,0.05
				l0.42,0.77l-0.78,0.39l-44.17,20.19l-18.21-39.83l0.82-0.3c0.11-0.04,0.22-0.09,0.33-0.14c5.35-2.45,7.71-8.78,5.26-14.13
				c-2.45-5.35-8.78-7.71-14.13-5.26c-0.11,0.05-0.22,0.1-0.32,0.16l-0.77,0.43l-5.48-11.99l12.29-5.62l0.35,0.7
				c2.58,5.15,8.71,7.3,13.96,4.91s7.62-8.44,5.42-13.77l-0.3-0.73l39.36-18l3.15,6.89l-0.74,0.34c-5.36,2.45-7.72,8.79-5.28,14.14
				c2.45,5.35,8.78,7.71,14.13,5.26l0.74-0.34l0.35,0.73L1120.24,453.39z M1114.25,454.34l3.84-1.75l-19.13-41.85
				c-6.01,2.29-12.85-0.46-15.56-6.38c-2.71-5.92-0.3-12.9,5.35-15.94l-1.81-3.95l-36.47,16.67c2.04,5.95-0.72,12.48-6.53,15.13
				s-12.55,0.47-15.72-4.96l-9.4,4.3l4.1,8.96c6.09-2.56,13.17,0.18,15.93,6.23c2.76,6.05,0.2,13.19-5.72,16.12l16.83,36.81
				l41.95-19.18c-2.37-6.03,0.37-12.95,6.33-15.68C1104.21,446.15,1111.24,448.6,1114.25,454.34z"
                    />
                </g>
                <g>
                    <path
                        className="st0"
                        d="M1068.87,447.29c-7.24,3.31-15.82,0.11-19.13-7.13s-0.11-15.82,7.13-19.13c7.24-3.31,15.82-0.11,19.13,7.13
				S1076.11,443.98,1068.87,447.29z M1057.54,422.51c-6.42,2.94-9.26,10.55-6.32,16.97c2.94,6.42,10.55,9.26,16.97,6.32
				c6.42-2.94,9.26-10.55,6.32-16.97C1071.58,422.41,1063.97,419.57,1057.54,422.51z"
                    />
                </g>
                <g>
                    <path
                        className="st0"
                        d="M1066.21,468.74c-2.97,1.36-6.48,0.05-7.84-2.92c-1.36-2.97-0.05-6.49,2.92-7.84c2.97-1.36,6.48-0.05,7.84,2.92
				C1070.49,463.87,1069.18,467.39,1066.21,468.74z M1061.97,459.46c-2.15,0.98-3.1,3.53-2.12,5.68c0.98,2.15,3.53,3.1,5.68,2.12
				s3.1-3.53,2.12-5.68C1066.67,459.43,1064.12,458.48,1061.97,459.46z"
                    />
                </g>
                <g>
                    <path
                        className="st0"
                        d="M1093.52,435.07c-2.97,1.36-6.48,0.05-7.84-2.92c-1.36-2.97-0.05-6.49,2.92-7.84c2.97-1.36,6.48-0.05,7.84,2.92
				C1097.79,430.19,1096.48,433.71,1093.52,435.07z M1089.27,425.79c-2.15,0.98-3.1,3.53-2.12,5.68c0.98,2.15,3.53,3.1,5.68,2.12
				s3.1-3.53,2.12-5.68C1093.97,425.75,1091.42,424.8,1089.27,425.79z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <circle className="st0" cx="1799.67" cy="315.12" r="4.61" />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1806.44,294.67c0.17,0.52,1.79,5.47-1.01,8.07c-2.6,2.41-7.32,1.2-9.79-0.86c-1.51-1.26-1.27-2.08-2.88-4.47
					c-3.59-5.31-7.5-5.22-7.78-7.78c-0.44-3.98,8.47-9.09,10.66-7.35c1.47,1.17-0.79,4.87,1.15,6.63c1.55,1.4,3.66-0.33,6.34,1.15
					C1805.33,291.29,1806.12,293.71,1806.44,294.67z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1789.87,311.09c0.13,1.37-0.3,2.5-1.15,4.75c-1.22,3.2-1.83,4.8-3.02,5.04c-2.15,0.43-5.53-3.7-4.75-6.19
					c0.54-1.74,2.72-1.42,3.89-3.6c1.42-2.65-0.53-5.46,0.29-5.91C1786.02,304.69,1789.53,307.53,1789.87,311.09z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1871.35,296.56c-0.7,0-1.4-0.07-2.1-0.22c-2.68-0.55-4.98-2.12-6.49-4.41l-77.85-118.48
					c-0.04-0.06-0.08-0.12-0.11-0.18c-6-11.09-5.25-24.42,1.96-34.8c0.25-0.36,0.51-0.71,0.77-1.06c0.18-0.24,0.19-0.56,0.03-0.81
					l-36.2-55.09c-0.34-0.52-0.46-1.14-0.34-1.76s0.48-1.14,1-1.48l13.98-9.19c0.52-0.34,1.15-0.46,1.76-0.34
					c0.61,0.13,1.14,0.48,1.48,1l36.2,55.09c0.16,0.25,0.46,0.37,0.75,0.3c0.42-0.1,0.85-0.2,1.28-0.28
					c12.38-2.51,24.92,2.09,32.72,12.01c0.05,0.06,0.09,0.12,0.13,0.18l77.85,118.47c3.1,4.72,1.79,11.09-2.94,14.19l-38.28,25.16
					C1875.26,295.98,1873.32,296.56,1871.35,296.56z M1767.29,70.34c-0.14,0-0.27,0.04-0.39,0.12l-13.98,9.19
					c-0.16,0.1-0.27,0.26-0.3,0.45s0,0.37,0.1,0.53l36.2,55.09c0.54,0.82,0.51,1.9-0.09,2.69c-0.25,0.33-0.49,0.67-0.73,1.01
					c-6.86,9.87-7.57,22.55-1.87,33.09c0.01,0.02,0.02,0.04,0.04,0.06l77.86,118.48c1.26,1.92,3.2,3.24,5.45,3.71
					c2.25,0.47,4.55,0.03,6.48-1.24l38.28-25.16c1.92-1.26,3.24-3.2,3.7-5.45c0.47-2.25,0.03-4.55-1.24-6.48l-77.86-118.48
					c-0.01-0.02-0.03-0.04-0.04-0.06c-7.42-9.42-19.34-13.8-31.11-11.42c-0.41,0.08-0.81,0.17-1.22,0.27
					c-0.95,0.23-1.96-0.16-2.5-0.99l-36.2-55.09c-0.1-0.16-0.26-0.27-0.45-0.3C1767.38,70.34,1767.34,70.34,1767.29,70.34z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1867.76,291.11c-1.03,0-2.07-0.16-3.09-0.48l0.49-1.56c2.48,0.78,5.15,0.41,7.32-1.02l15.3-10.05l0.9,1.36l-15.3,10.05
					C1871.68,290.54,1869.73,291.11,1867.76,291.11z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1865.21,287.23c-0.7,0-1.4-0.07-2.1-0.22l0.33-1.6c2.26,0.47,4.55,0.03,6.48-1.24l3.89-2.56l0.89,1.36l-3.89,2.56
					C1869.13,286.65,1867.19,287.23,1865.21,287.23z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1833.6,143.47c-5.36-6.84-14.34-10.15-22.87-8.42l-0.32-1.6c9.27-1.88,18.65,1.58,24.48,9.01L1833.6,143.47z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1747.44,81.99c-0.27,0-0.54-0.03-0.81-0.08c-1.03-0.21-1.92-0.82-2.5-1.7l-4.89-7.45c-1.2-1.82-0.69-4.27,1.13-5.47
					l20.18-13.26c0.88-0.58,1.94-0.78,2.97-0.57c1.03,0.21,1.92,0.82,2.5,1.7l4.89,7.45c1.2,1.82,0.69,4.27-1.13,5.47l-20.18,13.26
					C1748.95,81.77,1748.2,81.99,1747.44,81.99z M1762.71,55.01c-0.45,0-0.89,0.13-1.27,0.38l-20.18,13.26
					c-1.07,0.7-1.37,2.14-0.66,3.21l4.89,7.45c0.34,0.52,0.86,0.87,1.47,1c0.61,0.13,1.23,0.01,1.74-0.33l20.18-13.26
					c1.07-0.7,1.37-2.14,0.66-3.21l-4.89-7.45c-0.34-0.52-0.86-0.87-1.47-1C1763.03,55.03,1762.87,55.01,1762.71,55.01z"
                        />
                    </g>
                </g>
                <path
                    className="st0"
                    d="M1815.03,216.33l29.26,44.53c13.36-5.83,33.07-16.53,52.58-34.78l-37.36-56.85c-19.48,18.17-39.15,28.9-52.58,34.78
			l4.04,6.15c5.98-2.93,12.02-6.19,17.97-9.72l0.83,1.4c-5.93,3.51-11.94,6.76-17.9,9.69l2.25,3.42c5.97-2.92,12.02-6.19,17.97-9.72
			c0.1-0.06,10.3-6.16,12.26-7.81l1.05,1.25c-2.06,1.73-12.05,7.71-12.47,7.96C1827,210.16,1820.98,213.41,1815.03,216.33z"
                />
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="1755.94"
                            y="72.12"
                            transform="matrix(0.8357 -0.5492 0.5492 0.8357 243.945 983.5318)"
                            width="19.74"
                            height="23.84"
                        />
                    </g>
                    <g>
                        <path
                            className="st0"
                            d="M1763.87,100.56l-13.99-21.29l17.86-11.74l13.99,21.29L1763.87,100.56z M1752.14,79.74l12.2,18.57l15.14-9.95
					l-12.2-18.57L1752.14,79.74z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1603.74,249.57c-1.5-0.45-3.08-0.69-4.73-0.69c-9.07,0-16.42,7.35-16.42,16.42c0,2.49,0.55,4.85,1.55,6.96l-0.92,1.06
					l-18.05,3.26l10.56-16.9c0,0-26.12,2.3-26.69,2.3c-0.58,0,13.06-15.75,13.06-15.75l-19.39-21.32l25.16,6.34V208.6l20.16,20.36
					l9.03-17.48L1603.74,249.57z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1615.13,352.25c-9.5,0-17.24-7.73-17.24-17.24c0-2.45,0.5-4.81,1.49-7.02l0.24-0.53l0.58,0.05
						c0.45,0.04,0.92,0.06,1.39,0.06c6.15,0,11.75-3.63,14.26-9.25l0.24-0.53l0.58,0.05c8.81,0.77,15.7,8.31,15.7,17.17
						C1632.37,344.52,1624.64,352.25,1615.13,352.25z M1600.66,329.18c-0.75,1.85-1.13,3.81-1.13,5.84c0,8.6,7,15.61,15.61,15.61
						s15.61-7,15.61-15.61c0-7.83-5.95-14.52-13.65-15.48c-2.88,5.89-8.9,9.67-15.49,9.67
						C1601.28,329.2,1600.97,329.19,1600.66,329.18z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1600.06,329.13c-8.81-0.77-15.7-8.31-15.7-17.17c0-9.5,7.73-17.24,17.24-17.24s17.24,7.73,17.24,17.24
						c0,2.44-0.5,4.81-1.49,7.02l-1.49-0.66c0.9-2.01,1.35-4.15,1.35-6.36c0-8.6-7-15.61-15.61-15.61s-15.61,7-15.61,15.61
						c0,8.02,6.24,14.84,14.22,15.54L1600.06,329.13z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1630.98,331.22h-0.08v-1.63h0.08c8.6,0,15.61-7,15.61-15.61c0-3.75-1.35-7.38-3.81-10.22
						c-2.97-3.42-7.27-5.39-11.8-5.39c-0.19,0-0.38,0-0.57,0.01l-0.06-1.63c0.21-0.01,0.42-0.01,0.63-0.01c5,0,9.75,2.17,13.03,5.95
						c2.71,3.13,4.21,7.14,4.21,11.29C1648.22,323.49,1640.48,331.22,1630.98,331.22z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1617.51,308.3l-0.22-1.61c7.67-1.06,13.45-7.7,13.45-15.46c0-8.6-7-15.61-15.61-15.61s-15.61,7-15.61,15.61
						c0,1.46,0.2,2.89,0.59,4.25l-1.57,0.45c-0.43-1.51-0.65-3.09-0.65-4.7c0-9.5,7.73-17.24,17.24-17.24s17.24,7.73,17.24,17.24
						C1632.37,299.79,1625.98,307.13,1617.51,308.3z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1586.68,305.59c-8.19-1.49-14.13-8.62-14.13-16.95c0-9.5,7.73-17.24,17.23-17.24c5.82,0,11.21,2.91,14.41,7.78
						l-1.36,0.9c-2.9-4.41-7.78-7.05-13.05-7.05c-8.6,0-15.6,7-15.6,15.61c0,7.55,5.38,14,12.79,15.35L1586.68,305.59z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1643.48,304.04l-0.17-1.62c7.94-0.84,13.94-7.52,13.94-15.52c0-8.6-7-15.61-15.61-15.61c-6.03,0-11.41,3.37-14.04,8.8
						l-1.47-0.71c2.87-5.9,8.95-9.71,15.51-9.71c9.5,0,17.24,7.73,17.24,17.24C1658.87,295.74,1652.25,303.11,1643.48,304.04z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1640.76,270.96l-1.41-0.81c1.34-2.34,2.05-5.02,2.05-7.74c0-8.6-7-15.61-15.61-15.61c-5.7,0-10.94,3.1-13.69,8.1
						l-1.43-0.78c3.03-5.52,8.82-8.95,15.11-8.95c9.5,0,17.24,7.73,17.24,17.24C1643.03,265.42,1642.25,268.37,1640.76,270.96z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1612.86,275.55l-1.31-0.97c0.41-0.56,0.8-1.16,1.14-1.78c1.25-2.29,1.92-4.88,1.92-7.5c0-3.77-1.36-7.4-3.83-10.24
						c-1.94-2.23-4.46-3.86-7.28-4.7c-1.44-0.44-2.96-0.66-4.5-0.66c-8.6,0-15.61,7-15.61,15.61c0,2.32,0.49,4.54,1.47,6.61
						c0.12,0.27,0.25,0.52,0.38,0.77l-1.43,0.78c-0.15-0.28-0.3-0.57-0.43-0.86c-1.07-2.28-1.62-4.74-1.62-7.3
						c0-9.5,7.73-17.24,17.24-17.24c1.7,0,3.37,0.24,4.97,0.73c3.11,0.93,5.89,2.73,8.04,5.2c2.73,3.14,4.23,7.15,4.23,11.31
						c0,2.89-0.73,5.76-2.12,8.29C1613.74,274.27,1613.32,274.93,1612.86,275.55z"
                            />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1612.69,252.48c-2.1-4.93-2.4-8.9-2.28-11.64c0.22-5.05,1.91-6.82,2.86-14.58c0.43-3.53,0.71-5.81,0.29-8.93
					c-0.39-2.91-1.04-7.73-4.9-11.81c-4.24-4.49-9.68-5.35-11.63-5.51c-3.93-0.32-7.09,0.32-7.09,0.32
					c21.03,0.58,23.33,19.3,19.3,30.82c-4.03,11.52,1.15,22.76,1.15,22.76l1.01,0.6l1.59-2.19"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1611.68,226.26c0,0,9.36-3.02,8.79-12.96c-0.58-9.94-3.17-13.25-3.17-13.25s3.89,3.6,4.61,7.06
					c0.72,3.46,2.59,11.52-2.45,15.99c-5.04,4.47-7.49,6.34-8.21,9.22L1611.68,226.26z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="1587.27" y="319.17" width="15.15" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="1590.45" y="323.2" width="11.97" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="1584.94" y="310.91" width="17.48" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="1585.62" y="314.94" width="16.8" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="1587.91" y="302.08" width="14.5" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="1585.96" y="306.11" width="16.45" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <rect className="st0" x="1575.55" y="295.55" width="12.22" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="1578.73" y="299.58" width="5.44" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="1573.22" y="287.29" width="17.48" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="1573.9" y="291.32" width="16.8" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="1576.2" y="278.46" width="7.97" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="1574.25" y="282.49" width="13.38" height="1.63" />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <rect className="st0" x="1581.17" y="266.88" width="12.22" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="1578.84" y="258.63" width="17.48" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="1579.52" y="262.66" width="16.8" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="1579.87" y="253.82" width="13.38" height="1.63" />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="1598.72" y="336.79" width="15.99" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="1599.72" y="340.82" width="11.52" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <polygon
                            className="st0"
                            points="1674.22,219.87 1658.64,186.41 1647.23,210.39 1633.39,163.26 1634.95,162.8 1647.59,205.84 1658.66,182.58 
					1674.19,215.95 1685.2,191.44 1715.97,192.27 1697.86,169.71 1717.85,151.87 1676.9,156.36 1686.9,134.43 1636.77,160.03 
					1636.03,158.58 1690.32,130.85 1679.57,154.43 1722.74,149.7 1700.1,169.89 1719.44,194 1686.25,193.1 				"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1636.38,159.33l-2.8,1.26l0.58,2.44c-1.7-1.27-4.33-2.9-8.36-4.8c-9.79-4.61-5.57-14.98-5.57-14.98
					s0.24,2.64,1.08,5.45c1.18,3.95,4.07,5.97,4.68,6.46c1.85,1.47,3.54,2.08,5.76,2.88c1.73,0.62,3.31,1.01,4.64,1.26
					L1636.38,159.33z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1579.27,180.12c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
					S1582.26,180.12,1579.27,180.12z M1579.27,170.91c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
					c2.09,0,3.79-1.7,3.79-3.79C1583.07,172.61,1581.36,170.91,1579.27,170.91z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1538.37,297.95c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
					C1543.79,295.51,1541.36,297.95,1538.37,297.95z M1538.37,288.73c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79
					c2.09,0,3.79-1.7,3.79-3.79S1540.46,288.73,1538.37,288.73z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1664.54,266.26c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42s5.42,2.43,5.42,5.42
					C1669.96,263.82,1667.53,266.26,1664.54,266.26z M1664.54,257.04c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
					c2.09,0,3.79-1.7,3.79-3.79C1668.33,258.74,1666.63,257.04,1664.54,257.04z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1662.24,330.5c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
					C1667.66,328.06,1665.23,330.5,1662.24,330.5z M1662.24,321.28c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
					c2.09,0,3.79-1.7,3.79-3.79C1666.03,322.98,1664.33,321.28,1662.24,321.28z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <polygon
                            className="st0"
                            points="1717.41,193.9 1639.05,163.36 1633.32,161.35 1633.86,159.81 1639.62,161.83 1718,192.38 				"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <polygon
                            className="st0"
                            points="1670.21,187.63 1667.82,173.5 1680.79,167.49 1681.48,168.97 1669.64,174.46 1671.82,187.36 				"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <polygon
                            className="st0"
                            points="1649.93,179.84 1648.3,179.67 1649.66,166.67 1657.95,159.24 1659.04,160.46 1651.22,167.46 				"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1800.62,332.29l-3.25,13.48c-0.08,0.38-0.16,0.76-0.25,1.14c-3.67,15.22-18.99,24.59-34.21,20.92
					c-15.23-3.67-24.59-18.99-20.92-34.22c0.1-0.4,0.2-0.8,0.32-1.2l3.23-13.4c0,0,8.64,15.52,20.14,15.33
					S1785.43,318.56,1800.62,332.29z"
                        />
                    </g>
                    <g>
                        <path
                            className="st0"
                            d="M1769.55,369.44c-2.26,0-4.55-0.26-6.84-0.82c-7.58-1.83-13.99-6.49-18.05-13.14c-4.07-6.65-5.3-14.48-3.47-22.06
					c0.1-0.42,0.21-0.83,0.33-1.23l3.71-15.4l1.02,1.82c0.08,0.15,8.44,14.91,19.22,14.91c0.06,0,0.13,0,0.19,0
					c3.74-0.06,7.12-1.83,10.69-3.71c7.07-3.71,14.39-7.55,24.81,1.88l0.36,0.32l-3.36,13.95c-0.07,0.35-0.15,0.75-0.25,1.14
					C1794.7,360.45,1782.72,369.44,1769.55,369.44z M1745.86,321.12l-2.77,11.49c-0.12,0.42-0.22,0.81-0.32,1.2
					c-1.72,7.15-0.56,14.55,3.28,20.82c3.84,6.28,9.89,10.68,17.04,12.41c14.76,3.56,29.67-5.56,33.23-20.32
					c0.09-0.37,0.17-0.74,0.24-1.11l3.14-13.03c-9.38-8.25-15.8-4.88-22.6-1.32c-3.59,1.88-7.3,3.83-11.42,3.9
					C1756.06,335.3,1748.54,325.24,1745.86,321.12z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1764.26,324.94c-11.6,0-16.45-14.38-16.5-14.53l-0.07-0.22l6.6-27.39l1.58,0.38l-6.49,26.93
					c0.65,1.78,5.59,14.19,16.17,13.13l0.16,1.62C1765.22,324.91,1764.73,324.94,1764.26,324.94z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1764.82,308.38c-0.1-0.01-10.25-0.71-13.69-12l1.56-0.47c3.09,10.17,11.85,10.82,12.22,10.84L1764.82,308.38z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1749.58,450.64l-1.62-0.14c2.67-30.48,10.63-55.61,15.14-67.87c0.43-1.17,1.59-1.93,2.85-1.84
						c17.88,1.31,33.91-10.41,38.12-27.87c0.12-0.49,0.23-0.98,0.32-1.47l16.13-66.92c0.07-0.31,0.02-0.63-0.14-0.9
						c-0.17-0.27-0.43-0.46-0.74-0.54l-68.64-16.55c-0.31-0.07-0.63-0.02-0.9,0.14c-0.27,0.17-0.46,0.43-0.54,0.74l-16.1,66.79
						c-0.15,0.53-0.29,1.03-0.42,1.58c-4.21,17.45,4.72,35.19,21.23,42.18c1.15,0.49,1.85,1.7,1.7,2.94
						c-1.58,12.97-5.94,38.97-17.45,67.32l-1.51-0.61c11.44-28.18,15.78-54.01,17.34-66.9c0.06-0.53-0.22-1.03-0.72-1.24
						c-17.25-7.3-26.57-25.83-22.17-44.06c0.14-0.57,0.28-1.1,0.43-1.62l16.09-66.75c0.18-0.73,0.63-1.35,1.27-1.74
						c0.64-0.39,1.4-0.51,2.13-0.34l68.64,16.55c0.73,0.18,1.35,0.63,1.74,1.27c0.39,0.64,0.51,1.4,0.34,2.13l-16.13,66.88
						c-0.09,0.48-0.2,0.99-0.33,1.5c-4.4,18.23-21.15,30.48-39.82,29.11c-0.53-0.04-1.02,0.28-1.2,0.77
						C1760.15,395.38,1752.24,420.35,1749.58,450.64z"
                            />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1768.92,468.25c-8.69,0-18.95-1.34-29.48-3.88c-12.57-3.03-24.03-7.41-32.27-12.33c-8.5-5.08-12.62-10.23-11.59-14.49
					c1.96-8.14,22.46-10.17,47.68-4.7l-0.34,1.59c-23.53-5.1-44.06-3.53-45.75,3.49c-0.84,3.46,3.12,8.1,10.84,12.71
					c8.1,4.84,19.4,9.15,31.81,12.15c12.41,2.99,24.44,4.3,33.85,3.69c8.98-0.59,14.61-2.91,15.44-6.37
					c1.68-6.96-15.71-17.67-38.76-23.86l0.42-1.57c11.65,3.13,22.14,7.41,29.52,12.06c7.79,4.9,11.39,9.66,10.4,13.76
					c-1.03,4.27-7.04,6.97-16.92,7.62C1772.22,468.2,1770.6,468.25,1768.92,468.25z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="1711.51"
                            y="425.16"
                            transform="matrix(0.2344 -0.9721 0.9721 0.2344 881.6974 2002.7076)"
                            width="1.63"
                            height="32.84"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="1707.37"
                            y="436.05"
                            transform="matrix(0.2344 -0.9721 0.9721 0.2344 874.0172 2002.2188)"
                            width="1.63"
                            height="20.31"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="1711"
                            y="425.3"
                            transform="matrix(0.2344 -0.9721 0.9721 0.2344 888.0646 1996.9017)"
                            width="1.63"
                            height="18.68"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1916.53,156.09c-0.93,0.73-2.11,1.15-3.27,1.23l-0.57,0.05c-0.17,0.02-0.35,0.03-0.52,0.04
						c-0.35,0.02-0.7,0.02-1.05,0l-4.19-0.39l-2.09-0.22l-0.26-0.03c-0.08-0.01-0.2-0.02-0.22-0.03c-0.1-0.02-0.2-0.03-0.3-0.03
						c-0.21-0.01-0.42,0.02-0.63,0.08c-0.21,0.06-0.42,0.16-0.62,0.29c-0.1,0.07-0.19,0.14-0.28,0.22l-0.13,0.14l-0.17,0.2
						l-1.34,1.63c-0.91,1.08-1.8,2.17-2.73,3.23c0.49-1.32,1.02-2.63,1.53-3.94l0.79-1.96l0.1-0.25c0.04-0.09,0.1-0.2,0.15-0.3
						c0.11-0.2,0.24-0.4,0.38-0.58c0.28-0.38,0.63-0.71,1.03-0.99c0.39-0.28,0.84-0.5,1.31-0.65c0.24-0.07,0.48-0.13,0.73-0.17
						l0.55-0.09l2.08-0.35l4.15-0.66c0.35-0.05,0.69-0.07,1.04-0.07c0.17,0,0.35,0,0.52,0l0.26,0.01l0.13,0l0.06,0l0.02-0.01
						c0.23,0.01,0.41-0.07,0.58-0.19c0.16-0.13,0.29-0.32,0.36-0.53c0.06-0.21,0.05-0.41,0-0.61c0-0.05-0.05-0.1-0.06-0.16
						c0,0-0.02-0.05-0.04-0.08l-0.06-0.12l-0.12-0.24l-0.47-0.93l-0.94-1.86l-1.88-3.72l-3.76-7.45l-0.12-0.23
						c-0.04-0.08-0.07-0.13-0.14-0.29c-0.11-0.27-0.24-0.53-0.31-0.82c-0.09-0.28-0.16-0.57-0.19-0.87
						c-0.05-0.29-0.06-0.59-0.06-0.89c0.01-1.19,0.41-2.35,1.08-3.3c0.67-0.96,1.67-1.71,2.78-2.11c0.56-0.21,1.14-0.31,1.72-0.34
						l1.07-0.04l8.34-0.27l4.17-0.13l2.08-0.06c0.06,0,0.05,0,0.07-0.01l0.04-0.01l0.08,0c0.05-0.03,0.11-0.02,0.15-0.04
						c0.04-0.02,0.1-0.03,0.14-0.06c0.02-0.01,0.05-0.02,0.07-0.03c0.02-0.02,0.04-0.04,0.06-0.05c0.17-0.11,0.31-0.31,0.38-0.51
						c0.08-0.21,0.08-0.41,0.02-0.61c-0.01-0.05-0.03-0.1-0.06-0.15c0.01-0.01-0.11-0.23-0.18-0.37l-0.47-0.93l-0.94-1.86
						l-3.76-7.45l-1.88-3.72l-0.24-0.47l-0.06-0.12l-0.03-0.06l-0.04-0.1l-0.17-0.4c-0.12-0.26-0.18-0.56-0.26-0.84
						c-0.06-0.29-0.1-0.59-0.13-0.88c-0.07-1.19,0.22-2.38,0.82-3.39c0.61-1,1.52-1.83,2.6-2.32c0.55-0.23,1.12-0.4,1.7-0.47
						l0.44-0.04c0.08-0.01,0.12-0.01,0.16-0.01l0.13,0l0.52-0.02l4.17-0.13l1.04-0.03l0.52-0.02l0.12,0l0.04,0
						c0.03,0,0.05-0.01,0.08-0.01c0.21-0.03,0.41-0.13,0.56-0.27c0.04-0.03,0.07-0.08,0.11-0.12l0.05-0.07
						c0.02-0.03,0.01-0.01,0.07-0.11l3.73-6.28c0.35-0.6,0.72-1.19,1.05-1.8c0.17-0.3,0.32-0.62,0.49-0.92
						c0.18-0.3,0.37-0.59,0.58-0.87c0.69-0.88,1.38-1.77,2.07-2.65c0.11-0.15,0.23-0.29,0.34-0.44c0.12-0.15,0.23-0.29,0.35-0.44
						l3.13,1.86c-0.07,0.17-0.14,0.34-0.22,0.51c-0.07,0.17-0.14,0.34-0.22,0.51c-0.44,1.03-0.89,2.06-1.34,3.09
						c-0.15,0.32-0.3,0.63-0.49,0.93c-0.18,0.3-0.38,0.58-0.57,0.87c-0.37,0.59-0.72,1.19-1.07,1.79l-3.73,6.28
						c-0.03,0.05-0.13,0.22-0.21,0.33c-0.08,0.12-0.17,0.24-0.26,0.36c-0.18,0.23-0.38,0.45-0.6,0.65
						c-0.86,0.81-1.97,1.34-3.13,1.52c-0.15,0.02-0.29,0.04-0.44,0.05l-0.22,0.02l-0.14,0.01l-0.52,0.02l-1.04,0.03l-4.17,0.13
						l-0.52,0.02l-0.13,0c-0.04,0-0.09,0-0.09,0.01c-0.03,0-0.05,0.01-0.08,0.01c-0.11,0-0.21,0.04-0.31,0.08
						c-0.18,0.09-0.34,0.23-0.46,0.42c-0.11,0.19-0.16,0.41-0.15,0.61c0.01,0.05,0.01,0.1,0.02,0.15c0.03,0.05,0.02,0.1,0.05,0.15
						c0.01,0.02,0.02,0.05,0.03,0.08l0.01,0.02l0.03,0.05l0.06,0.12l0.23,0.47l1.88,3.73l3.76,7.45l0.94,1.86l0.47,0.93
						c0.08,0.17,0.14,0.25,0.27,0.57c0.12,0.27,0.23,0.54,0.3,0.82c0.32,1.14,0.28,2.39-0.12,3.49c-0.39,1.11-1.11,2.09-2.07,2.78
						l-0.37,0.25c-0.13,0.08-0.26,0.15-0.39,0.21c-0.26,0.15-0.54,0.25-0.82,0.35s-0.57,0.15-0.86,0.22
						c-0.14,0.02-0.29,0.04-0.43,0.05l-0.22,0.02c-0.07,0-0.16,0.01-0.19,0.01l-2.09,0.07l-4.17,0.13l-8.35,0.26l-1.02,0.03
						c-0.12,0.02-0.22,0.02-0.32,0.06c-0.18,0.07-0.37,0.18-0.49,0.37c-0.12,0.18-0.21,0.39-0.2,0.6c0,0.05,0,0.1,0.01,0.15
						c0,0.05,0.02,0.1,0.03,0.15c0,0.06,0.05,0.1,0.06,0.16c0,0,0.05,0.11,0.09,0.18l0.12,0.23l3.76,7.45l1.88,3.72l0.94,1.86
						l0.47,0.93l0.18,0.35c0.02,0.04,0.03,0.07,0.07,0.15c0.11,0.27,0.24,0.53,0.32,0.82c0.36,1.12,0.36,2.38,0,3.5
						C1918.15,154.36,1917.46,155.36,1916.53,156.09z"
                            />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1979.04,100.64c-2.05,0-4.07-0.55-5.88-1.62c-5.49-3.29-7.28-10.38-4.04-15.84c3.24-5.46,10.32-7.28,15.79-4.07
					c2.7,1.6,4.58,4.12,5.35,7.11c0.76,2.99,0.31,6.1-1.26,8.76l0,0c-1.58,2.65-4.09,4.54-7.08,5.3
					C1980.96,100.52,1980,100.64,1979.04,100.64z M1979.07,79.17c-3.4,0-6.7,1.74-8.55,4.85c-2.78,4.69-1.24,10.78,3.43,13.58
					c2.32,1.38,4.99,1.76,7.56,1.11c2.57-0.65,4.73-2.27,6.08-4.55l0,0c1.35-2.28,1.74-4.95,1.08-7.52
					c-0.66-2.57-2.27-4.73-4.55-6.08C1982.54,79.61,1980.79,79.17,1979.07,79.17z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1973.12,98.99l-46.79-27.78c-2.65-1.58-4.54-4.09-5.3-7.08c-0.76-2.99-0.32-6.1,1.26-8.75
					c1.58-2.66,4.09-4.54,7.08-5.3c2.99-0.76,6.1-0.31,8.76,1.26l46.79,27.78l-0.83,1.4c-4.7-2.76-10.78-1.19-13.56,3.49
					c-2.78,4.69-1.24,10.78,3.43,13.58L1973.12,98.99z M1932.24,51.34c-0.82,0-1.65,0.1-2.47,0.31c-2.57,0.65-4.73,2.27-6.08,4.55
					c-1.35,2.28-1.74,4.95-1.08,7.52c0.66,2.57,2.27,4.73,4.55,6.08l41.76,24.79c-1.87-3.46-1.94-7.8,0.21-11.42
					c2.15-3.62,5.99-5.64,9.93-5.65l-41.76-24.79C1935.74,51.82,1934,51.34,1932.24,51.34z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1973.11,98.99c-5.44-3.26-7.23-10.35-3.99-15.81c3.24-5.46,10.32-7.28,15.79-4.07l-0.83,1.4
					c-4.7-2.76-10.78-1.19-13.56,3.49c-2.78,4.69-1.24,10.78,3.43,13.58L1973.11,98.99z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="1937.78"
                            y="82.14"
                            transform="matrix(0.8599 0.5105 -0.5105 0.8599 317.5191 -980.3926)"
                            width="13.77"
                            height="12.12"
                        />
                    </g>
                    <g>
                        <path
                            className="st0"
                            d="M1947.78,98.05l-13.24-7.86l7.02-11.82l13.24,7.86L1947.78,98.05z M1936.77,89.61l10.44,6.2l5.36-9.02l-10.44-6.2
					L1936.77,89.61z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1591.31,131.22c-47.23-24.74-57.82-87.48-59.47-99.81l1.62-0.22c1.62,12.19,12.09,74.21,58.61,98.58L1591.31,131.22z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1532.89,32.32l-0.43-0.2c-0.13-0.06-13.34-6.55-4.37-28.18l0.51-1.23l0.86,1.02c0.4,0.47,9.69,11.53,8.51,20.73
					c-0.4,3.14-1.99,5.7-4.7,7.59L1532.89,32.32z M1529.08,5.86c-6.62,17.21,1.63,23.36,3.66,24.56c2.08-1.59,3.29-3.66,3.62-6.17
					C1537.25,17.34,1531.35,8.83,1529.08,5.86z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1538.01,42.89c-1.2,0-2.42-0.2-3.67-0.61l-0.45-0.15l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.41l1.29-0.33l-0.3,1.3
					c-0.14,0.6-3.41,14.67-11.47,19.25C1541.57,42.42,1539.83,42.89,1538.01,42.89z M1535.32,40.87c2.52,0.71,4.91,0.43,7.11-0.81
					c6.07-3.44,9.33-13.27,10.34-16.86C1535.03,28.24,1535.07,38.52,1535.32,40.87z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1541.94,58.28c-1.2,0-2.42-0.2-3.67-0.61l-0.45-0.15l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.41l1.29-0.33l-0.3,1.3
					c-0.14,0.6-3.41,14.67-11.47,19.25C1545.51,57.81,1543.76,58.28,1541.94,58.28z M1539.26,56.26c2.52,0.71,4.91,0.43,7.11-0.81
					c6.07-3.44,9.33-13.27,10.34-16.86C1538.96,43.63,1539,53.92,1539.26,56.26z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1547.74,74.76c-1.2,0-2.42-0.2-3.67-0.61l-0.45-0.15l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.41l1.29-0.33l-0.29,1.3
					c-0.14,0.6-3.41,14.67-11.48,19.25C1551.3,74.29,1549.55,74.76,1547.74,74.76z M1545.05,72.74c2.52,0.71,4.91,0.43,7.11-0.81
					c6.07-3.44,9.33-13.27,10.34-16.86C1544.75,60.11,1544.79,70.4,1545.05,72.74z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1555.47,90.98c-1.2,0-2.42-0.2-3.67-0.61l-0.45-0.15l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.4l1.29-0.33l-0.3,1.3
					c-0.14,0.6-3.41,14.67-11.48,19.24C1559.04,90.5,1557.29,90.98,1555.47,90.98z M1552.79,88.96c2.52,0.71,4.91,0.44,7.11-0.81
					c6.07-3.43,9.33-13.26,10.34-16.85C1552.49,76.33,1552.53,86.61,1552.79,88.96z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1564.79,105.65c-1.2,0-2.42-0.2-3.67-0.61l-0.45-0.15l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.4l1.29-0.33l-0.3,1.3
					c-0.14,0.6-3.41,14.67-11.48,19.24C1568.35,105.18,1566.6,105.65,1564.79,105.65z M1562.11,103.63c2.52,0.7,4.9,0.43,7.1-0.81
					c6.07-3.44,9.33-13.26,10.34-16.85C1561.98,90.96,1561.89,101.33,1562.11,103.63z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1527.66,49.05c-0.12,0-0.24,0-0.37-0.01c-9.27-0.28-18.73-11.21-19.12-11.68l-0.87-1.01l1.3-0.31
					c22.78-5.44,27.09,8.63,27.13,8.77l0.13,0.45l-0.33,0.34C1533.32,47.89,1530.67,49.05,1527.66,49.05z M1510.34,37.32
					c2.57,2.7,10.04,9.88,17.01,10.09c2.54,0.08,4.77-0.8,6.67-2.59C1533.14,42.63,1528.39,33.5,1510.34,37.32z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1533.32,68.35c-0.12,0-0.24,0-0.37-0.01c-9.27-0.28-18.73-11.21-19.12-11.68l-0.87-1.01l1.3-0.31
					c22.78-5.44,27.09,8.62,27.13,8.77l0.13,0.45l-0.33,0.34C1538.99,67.19,1536.34,68.35,1533.32,68.35z M1516.01,56.62
					c2.57,2.7,10.04,9.88,17.01,10.09c2.54,0.07,4.77-0.8,6.67-2.59C1538.81,61.93,1534.05,52.81,1516.01,56.62z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1540.25,85.7c-0.12,0-0.24,0-0.36-0.01c-9.27-0.28-18.73-11.21-19.12-11.68l-0.87-1.01l1.3-0.31
					c22.78-5.44,27.09,8.62,27.13,8.77l0.13,0.45l-0.33,0.34C1545.91,84.54,1543.26,85.7,1540.25,85.7z M1522.93,73.97
					c2.57,2.7,10.04,9.88,17.01,10.09c2.52,0.07,4.77-0.8,6.66-2.59C1545.73,79.28,1540.97,70.16,1522.93,73.97z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1549.31,104.29c-8.92,0-18.77-8.75-19.2-9.14l-0.99-0.89l1.25-0.48c21.87-8.36,27.99,5.01,28.05,5.15l0.19,0.43
					l-0.28,0.38c-1.97,2.66-4.57,4.17-7.72,4.49C1550.16,104.27,1549.74,104.29,1549.31,104.29z M1532.25,94.82
					c2.9,2.34,11.24,8.49,18.18,7.79c2.52-0.25,4.62-1.41,6.27-3.44C1555.55,97.1,1549.65,88.68,1532.25,94.82z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1557.09,120.86c-7.57,0-15.58-3.74-16.01-3.94l-1.21-0.57l1.06-0.81c18.57-14.27,28.25-3.19,28.35-3.08l0.3,0.36
					l-0.16,0.44c-1.13,3.11-3.19,5.3-6.12,6.51C1561.4,120.55,1559.26,120.86,1557.09,120.86z M1543.04,115.99
					c3.45,1.42,13.2,4.92,19.65,2.27c2.34-0.96,4.03-2.67,5.02-5.09C1566.03,111.52,1557.96,105.14,1543.04,115.99z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1901.46,453c-1.53-6.09-3.51-12.35-5.89-18.6c-5.16-13.58-4.97-25.87,0.56-36.53c11-21.18,39.75-28.72,40.04-28.79
					l0.4,1.58c-0.28,0.07-28.34,7.44-39,27.97c-5.31,10.23-5.47,22.07-0.48,35.19c2.4,6.31,4.4,12.63,5.94,18.78L1901.46,453z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1942.9,373.36c-2.86,0-5.41-1.07-7.61-3.19l-0.34-0.33l0.12-0.46c0.04-0.14,3.89-14.34,26.83-9.65l1.31,0.27
					l-0.83,1.04c-0.38,0.48-9.48,11.71-18.73,12.3C1943.4,373.35,1943.15,373.36,1942.9,373.36z M1936.8,369.33
					c1.96,1.74,4.22,2.54,6.75,2.37c6.96-0.44,14.19-7.86,16.67-10.64C1942.05,357.84,1937.59,367.11,1936.8,369.33z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1924.46,374.83l-0.32-0.35c-0.1-0.11-9.83-11.14,6.71-27.71l0.94-0.94l0.41,1.27c0.19,0.58,4.56,14.36-0.05,22.41
					c-1.57,2.75-4.01,4.51-7.24,5.23L1924.46,374.83z M1931.04,348.92c-12.69,13.39-7.4,22.21-5.99,24.1
					c2.53-0.68,4.44-2.13,5.7-4.32C1934.21,362.65,1932,352.53,1931.04,348.92z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1909.13,383.79l-0.36-0.31c-0.11-0.1-11.03-9.96,3.54-28.3l0.83-1.04l0.55,1.21c0.25,0.56,6.15,13.75,2.48,22.27
					c-1.25,2.91-3.47,4.93-6.61,6.01L1909.13,383.79z M1912.74,357.3c-10.98,14.6-4.78,22.91-3.22,24.62
					c2.43-0.96,4.17-2.62,5.17-4.94C1917.44,370.57,1914.1,360.78,1912.74,357.3z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1899.17,395.42l-0.46-0.12c-0.14-0.04-14.29-4.09-9.27-26.97l0.28-1.3l1.03,0.85c0.47,0.39,11.58,9.64,12.03,18.9
					c0.16,3.16-0.95,5.96-3.29,8.31L1899.17,395.42z M1890.75,370.05c-3.48,18.11,5.73,22.7,7.94,23.53
					c1.76-1.93,2.59-4.19,2.47-6.71C1900.82,379.9,1893.5,372.57,1890.75,370.05z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1892.38,405.32c-4.26,0-13.93-2.03-19.08-19.28l-0.38-1.28l1.31,0.24c0.6,0.11,14.8,2.82,19.69,10.7
					c1.67,2.69,2.06,5.67,1.15,8.86l-0.13,0.45l-0.46,0.11C1894.45,405.14,1893.65,405.32,1892.38,405.32z M1875.27,386.9
					c5.74,17.53,16.02,17.08,18.35,16.73c0.61-2.54,0.24-4.92-1.09-7.07C1888.85,390.64,1878.9,387.77,1875.27,386.9z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1887.92,422.51c-4.94,0-12.63-2.44-19.68-14.88l-0.66-1.16l1.33-0.06c0.61-0.03,15.05-0.56,21.58,6.03
					c2.23,2.25,3.27,5.07,3.1,8.38l-0.02,0.47l-0.42,0.21C1893.1,421.52,1891.06,422.51,1887.92,422.51z M1870.35,408.03
					c9.51,15.8,19.43,13.06,21.63,12.2c0.02-2.61-0.87-4.85-2.65-6.64C1884.43,408.63,1874.08,408.07,1870.35,408.03z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1933.95,388.39c-4.18,0-8.84-0.66-12.23-2.98c-2.61-1.79-4.17-4.36-4.62-7.64l-0.06-0.47l0.37-0.29
					c0.12-0.09,11.9-8.89,27.08,8.94l0.86,1.02l-1.3,0.31C1943.72,387.35,1939.21,388.39,1933.95,388.39z M1918.8,378.04
					c0.47,2.57,1.76,4.59,3.85,6.02c5.76,3.94,16.01,2.56,19.68,1.89C1930.13,372.36,1920.78,376.83,1918.8,378.04z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1914.74,397.59c-2.26,0-4.48-0.32-6.46-1.16c-2.92-1.24-4.95-3.45-6.05-6.57l-0.16-0.44l0.31-0.36
					c0.1-0.11,9.9-11.08,28.31,3.39l1.05,0.82l-1.21,0.56C1930.11,394.02,1922.24,397.59,1914.74,397.59z M1903.95,389.78
					c0.97,2.43,2.64,4.16,4.97,5.15c6.42,2.72,16.2-0.67,19.67-2.05C1913.79,381.86,1905.65,388.15,1903.95,389.78z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1905.33,415.98c-2.26,0-4.48-0.32-6.46-1.16c-2.92-1.24-4.95-3.45-6.05-6.57l-0.16-0.44l0.31-0.36
					c0.1-0.11,9.9-11.08,28.31,3.39l1.05,0.82l-1.21,0.56C1920.7,412.41,1912.82,415.98,1905.33,415.98z M1894.54,408.17
					c0.97,2.43,2.64,4.16,4.97,5.15c6.42,2.72,16.2-0.67,19.67-2.05C1904.38,400.25,1896.24,406.54,1894.54,408.17z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1901.75,433.19c-2.75,0-5.24-1.01-7.41-3.02l-0.35-0.32l0.1-0.46c0.03-0.14,3.56-14.43,26.6-10.28l1.31,0.24
					l-0.81,1.06c-0.37,0.49-9.2,11.93-18.44,12.73C1902.42,433.18,1902.08,433.19,1901.75,433.19z M1895.82,429.31
					c1.99,1.69,4.28,2.43,6.79,2.21c6.94-0.6,14-8.19,16.41-11.03C1900.97,417.73,1896.58,427.13,1895.82,429.31z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1654.34,408.38c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C1659.76,405.95,1657.33,408.38,1654.34,408.38z M1654.34,399.16c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79C1658.13,400.86,1656.43,399.16,1654.34,399.16z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1637.76,461.21c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C1643.18,458.77,1640.75,461.21,1637.76,461.21z M1637.76,451.99c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79C1641.55,453.69,1639.85,451.99,1637.76,451.99z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1850.13,364.69c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C1855.55,362.26,1853.12,364.69,1850.13,364.69z M1850.13,355.47c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79C1853.92,357.18,1852.22,355.47,1850.13,355.47z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1954.72,302.83c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C1960.14,300.4,1957.71,302.83,1954.72,302.83z M1954.72,293.61c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79C1958.51,295.31,1956.81,293.61,1954.72,293.61z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1757.65,229.7c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				S1760.64,229.7,1757.65,229.7z M1757.65,220.48c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79c2.09,0,3.79-1.7,3.79-3.79
				C1761.45,222.19,1759.75,220.48,1757.65,220.48z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1702.17,113.42c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				S1705.16,113.42,1702.17,113.42z M1702.17,104.2c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79c2.09,0,3.79-1.7,3.79-3.79
				S1704.26,104.2,1702.17,104.2z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1810.87,30.93c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42s5.42,2.43,5.42,5.42S1813.86,30.93,1810.87,30.93z
				 M1810.87,21.72c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79c2.09,0,3.79-1.7,3.79-3.79S1812.96,21.72,1810.87,21.72z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1630.74,44.54c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C1636.16,42.11,1633.73,44.54,1630.74,44.54z M1630.74,35.32c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79C1634.53,37.02,1632.83,35.32,1630.74,35.32z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1952.81,462.91c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C1958.23,460.47,1955.8,462.91,1952.81,462.91z M1952.81,453.69c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79C1956.6,455.39,1954.9,453.69,1952.81,453.69z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1844.39,465.46c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C1849.81,463.03,1847.38,465.46,1844.39,465.46z M1844.39,456.24c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79C1848.18,457.94,1846.48,456.24,1844.39,456.24z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1620.24,453.39l-6.88,3.15l-0.3-0.92c-2.45-5.35-8.79-7.71-14.13-5.26c-5.35,2.45-7.71,8.78-5.26,14.13l0.02,0.05
				l0.42,0.77l-0.78,0.39l-44.17,20.19l-18.21-39.83l0.82-0.3c0.11-0.04,0.22-0.09,0.33-0.14c5.35-2.45,7.71-8.78,5.26-14.13
				c-2.45-5.35-8.78-7.71-14.13-5.26c-0.11,0.05-0.22,0.1-0.32,0.16l-0.77,0.43l-5.48-11.99l12.29-5.62l0.35,0.7
				c2.58,5.15,8.71,7.3,13.96,4.91s7.62-8.44,5.42-13.77l-0.3-0.73l39.36-18l3.15,6.89l-0.74,0.34c-5.36,2.45-7.72,8.79-5.28,14.14
				c2.45,5.35,8.78,7.71,14.13,5.26l0.74-0.34l0.35,0.73L1620.24,453.39z M1614.25,454.34l3.84-1.75l-19.13-41.85
				c-6.01,2.29-12.85-0.46-15.56-6.38c-2.71-5.92-0.3-12.9,5.35-15.94l-1.81-3.95l-36.47,16.67c2.04,5.95-0.72,12.48-6.53,15.13
				s-12.55,0.47-15.72-4.96l-9.4,4.3l4.1,8.96c6.09-2.56,13.17,0.18,15.93,6.23c2.76,6.05,0.2,13.19-5.72,16.12l16.83,36.81
				l41.95-19.18c-2.37-6.03,0.37-12.95,6.33-15.68C1604.21,446.15,1611.24,448.6,1614.25,454.34z"
                    />
                </g>
                <g>
                    <path
                        className="st0"
                        d="M1568.87,447.29c-7.24,3.31-15.82,0.11-19.13-7.13s-0.11-15.82,7.13-19.13c7.24-3.31,15.82-0.11,19.13,7.13
				S1576.11,443.98,1568.87,447.29z M1557.54,422.51c-6.42,2.94-9.26,10.55-6.32,16.97c2.94,6.42,10.55,9.26,16.97,6.32
				c6.42-2.94,9.26-10.55,6.32-16.97C1571.58,422.41,1563.97,419.57,1557.54,422.51z"
                    />
                </g>
                <g>
                    <path
                        className="st0"
                        d="M1566.21,468.74c-2.97,1.36-6.48,0.05-7.84-2.92c-1.36-2.97-0.05-6.49,2.92-7.84c2.97-1.36,6.48-0.05,7.84,2.92
				C1570.49,463.87,1569.18,467.39,1566.21,468.74z M1561.97,459.46c-2.15,0.98-3.1,3.53-2.12,5.68c0.98,2.15,3.53,3.1,5.68,2.12
				s3.1-3.53,2.12-5.68C1566.67,459.43,1564.12,458.48,1561.97,459.46z"
                    />
                </g>
                <g>
                    <path
                        className="st0"
                        d="M1593.52,435.07c-2.97,1.36-6.48,0.05-7.84-2.92c-1.36-2.97-0.05-6.49,2.92-7.84c2.97-1.36,6.48-0.05,7.84,2.92
				C1597.79,430.19,1596.48,433.71,1593.52,435.07z M1589.27,425.79c-2.15,0.98-3.1,3.53-2.12,5.68c0.98,2.15,3.53,3.1,5.68,2.12
				s3.1-3.53,2.12-5.68C1593.97,425.75,1591.42,424.8,1589.27,425.79z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <circle className="st0" cx="299.67" cy="815.13" r="4.61" />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M306.44,794.68c0.17,0.52,1.79,5.47-1.01,8.07c-2.6,2.41-7.32,1.2-9.79-0.86c-1.51-1.26-1.27-2.08-2.88-4.47
					c-3.59-5.31-7.5-5.22-7.78-7.78c-0.44-3.98,8.47-9.09,10.66-7.35c1.47,1.17-0.79,4.87,1.15,6.63c1.55,1.4,3.66-0.33,6.34,1.15
					C305.33,791.3,306.12,793.72,306.44,794.68z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M289.87,811.1c0.13,1.37-0.3,2.5-1.15,4.75c-1.22,3.2-1.83,4.8-3.02,5.04c-2.15,0.43-5.53-3.7-4.75-6.19
					c0.54-1.74,2.72-1.42,3.89-3.6c1.42-2.65-0.53-5.46,0.29-5.91C286.02,804.71,289.53,807.54,289.87,811.1z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M371.35,796.57c-0.7,0-1.4-0.07-2.1-0.22c-2.68-0.55-4.98-2.12-6.49-4.41l-77.85-118.48c-0.04-0.06-0.08-0.12-0.11-0.18
					c-6-11.09-5.25-24.42,1.96-34.8c0.25-0.36,0.51-0.71,0.77-1.06c0.18-0.24,0.19-0.56,0.03-0.81l-36.2-55.09
					c-0.34-0.52-0.46-1.14-0.34-1.76c0.13-0.61,0.48-1.14,1-1.48l13.98-9.19c0.52-0.34,1.15-0.46,1.76-0.34
					c0.61,0.13,1.14,0.48,1.48,1l36.2,55.09c0.16,0.25,0.46,0.37,0.75,0.3c0.42-0.1,0.85-0.2,1.28-0.28
					c12.38-2.51,24.92,2.09,32.72,12.01c0.05,0.06,0.09,0.12,0.13,0.18l77.85,118.47c3.1,4.72,1.79,11.09-2.94,14.19l-38.28,25.16
					C375.26,795.99,373.32,796.57,371.35,796.57z M267.29,570.35c-0.14,0-0.27,0.04-0.39,0.12l-13.98,9.19
					c-0.16,0.1-0.27,0.26-0.3,0.45c-0.04,0.18,0,0.37,0.1,0.53l36.2,55.09c0.54,0.82,0.51,1.9-0.09,2.69
					c-0.25,0.33-0.49,0.67-0.73,1.01c-6.86,9.87-7.57,22.54-1.87,33.09c0.01,0.02,0.02,0.04,0.04,0.06l77.86,118.48
					c1.26,1.92,3.2,3.24,5.45,3.71c2.25,0.47,4.55,0.03,6.48-1.24l38.28-25.16c1.92-1.26,3.24-3.2,3.7-5.45
					c0.47-2.25,0.03-4.55-1.24-6.48l-77.86-118.48c-0.01-0.02-0.03-0.04-0.04-0.06c-7.42-9.42-19.34-13.8-31.11-11.42
					c-0.41,0.08-0.81,0.17-1.22,0.27c-0.95,0.23-1.96-0.16-2.5-0.99l-36.2-55.09c-0.1-0.16-0.26-0.26-0.45-0.3
					C267.38,570.36,267.34,570.35,267.29,570.35z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M367.76,791.13c-1.03,0-2.07-0.16-3.09-0.48l0.49-1.55c2.48,0.78,5.15,0.41,7.32-1.02l15.3-10.05l0.9,1.36l-15.3,10.05
					C371.68,790.55,369.73,791.13,367.76,791.13z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M365.21,787.24c-0.7,0-1.4-0.07-2.1-0.22l0.33-1.6c2.26,0.47,4.55,0.03,6.48-1.24l3.89-2.56l0.89,1.36l-3.89,2.56
					C369.13,786.66,367.19,787.24,365.21,787.24z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M333.6,643.48c-5.36-6.84-14.34-10.15-22.87-8.42l-0.32-1.6c9.27-1.88,18.65,1.58,24.48,9.01L333.6,643.48z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M247.44,582c-0.27,0-0.54-0.03-0.81-0.08c-1.03-0.21-1.92-0.82-2.5-1.7l-4.89-7.45c-1.2-1.82-0.69-4.27,1.13-5.47
					l20.18-13.26c0.88-0.58,1.94-0.78,2.97-0.57c1.03,0.21,1.92,0.82,2.5,1.7l4.89,7.45c1.2,1.82,0.69,4.27-1.13,5.47l-20.18,13.26
					C248.95,581.78,248.2,582,247.44,582z M262.71,555.02c-0.45,0-0.89,0.13-1.27,0.38l-20.18,13.26c-1.07,0.7-1.37,2.14-0.66,3.21
					l4.89,7.45c0.34,0.52,0.86,0.87,1.47,1c0.61,0.13,1.23,0.01,1.74-0.33l20.18-13.26c1.07-0.7,1.37-2.14,0.66-3.21l-4.89-7.45
					c-0.34-0.52-0.86-0.87-1.47-1C263.03,555.04,262.87,555.02,262.71,555.02z"
                        />
                    </g>
                </g>
                <path
                    className="st0"
                    d="M315.03,716.34l29.26,44.53c13.36-5.83,33.07-16.53,52.58-34.78l-37.36-56.85c-19.48,18.17-39.15,28.9-52.58,34.78
			l4.04,6.15c5.98-2.93,12.02-6.19,17.97-9.72l0.83,1.4c-5.93,3.51-11.94,6.76-17.9,9.69l2.25,3.42c5.97-2.92,12.01-6.19,17.97-9.72
			c0.1-0.06,10.3-6.16,12.26-7.81l1.05,1.25c-2.06,1.73-12.05,7.71-12.47,7.96C327,710.17,320.98,713.42,315.03,716.34z"
                />
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="255.94"
                            y="572.14"
                            transform="matrix(0.8357 -0.5492 0.5492 0.8357 -277.0754 241.9276)"
                            width="19.74"
                            height="23.84"
                        />
                    </g>
                    <g>
                        <path
                            className="st0"
                            d="M263.87,600.57l-13.99-21.29l17.86-11.74l13.99,21.29L263.87,600.57z M252.14,579.75l12.2,18.57l15.14-9.95l-12.2-18.57
					L252.14,579.75z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M103.74,749.58c-1.5-0.45-3.08-0.69-4.73-0.69c-9.07,0-16.42,7.35-16.42,16.42c0,2.49,0.55,4.85,1.55,6.96l-0.92,1.06
					l-18.05,3.26l10.56-16.9c0,0-26.12,2.3-26.69,2.3c-0.58,0,13.06-15.75,13.06-15.75l-19.39-21.32l25.16,6.34v-22.66l20.16,20.36
					l9.03-17.48L103.74,749.58z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M115.13,852.26c-9.5,0-17.24-7.73-17.24-17.24c0-2.45,0.5-4.81,1.49-7.02l0.24-0.53l0.58,0.05
						c0.45,0.04,0.92,0.06,1.39,0.06c6.15,0,11.75-3.63,14.26-9.25l0.24-0.53l0.58,0.05c8.81,0.77,15.7,8.31,15.7,17.17
						C132.37,844.53,124.64,852.26,115.13,852.26z M100.66,829.19c-0.75,1.85-1.13,3.81-1.13,5.84c0,8.6,7,15.61,15.61,15.61
						s15.61-7,15.61-15.61c0-7.83-5.95-14.52-13.65-15.48c-2.88,5.89-8.9,9.67-15.49,9.67C101.28,829.21,100.97,829.2,100.66,829.19
						z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M100.06,829.14c-8.81-0.77-15.7-8.31-15.7-17.17c0-9.5,7.73-17.24,17.24-17.24s17.24,7.73,17.24,17.24
						c0,2.45-0.5,4.81-1.49,7.02l-1.49-0.66c0.9-2.01,1.35-4.15,1.35-6.36c0-8.6-7-15.61-15.61-15.61s-15.61,7-15.61,15.61
						c0,8.02,6.24,14.84,14.22,15.54L100.06,829.14z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M130.98,831.23h-0.08v-1.63h0.08c8.6,0,15.61-7,15.61-15.61c0-3.75-1.35-7.38-3.81-10.22
						c-2.97-3.42-7.27-5.39-11.8-5.39c-0.19,0-0.38,0-0.57,0.01l-0.06-1.63c0.21-0.01,0.42-0.01,0.63-0.01c5,0,9.75,2.17,13.03,5.95
						c2.71,3.13,4.21,7.14,4.21,11.29C148.22,823.5,140.48,831.23,130.98,831.23z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M117.51,808.31l-0.22-1.61c7.67-1.06,13.45-7.7,13.45-15.46c0-8.6-7-15.61-15.61-15.61s-15.61,7-15.61,15.61
						c0,1.46,0.2,2.89,0.59,4.25l-1.57,0.45c-0.43-1.51-0.65-3.09-0.65-4.7c0-9.5,7.73-17.24,17.24-17.24s17.24,7.73,17.24,17.24
						C132.37,799.8,125.98,807.14,117.51,808.31z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M86.68,805.6c-8.19-1.49-14.13-8.62-14.13-16.95c0-9.5,7.73-17.24,17.23-17.24c5.82,0,11.21,2.91,14.41,7.78l-1.36,0.9
						c-2.9-4.41-7.78-7.05-13.05-7.05c-8.6,0-15.6,7-15.6,15.61c0,7.55,5.38,14,12.79,15.35L86.68,805.6z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M143.48,804.05l-0.17-1.62c7.94-0.84,13.94-7.52,13.94-15.52c0-8.6-7-15.61-15.61-15.61c-6.03,0-11.41,3.37-14.04,8.8
						l-1.47-0.71c2.87-5.9,8.95-9.71,15.51-9.71c9.5,0,17.24,7.73,17.24,17.24C158.87,795.75,152.25,803.12,143.48,804.05z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M140.76,770.98l-1.41-0.81c1.34-2.34,2.05-5.02,2.05-7.74c0-8.6-7-15.61-15.61-15.61c-5.7,0-10.94,3.1-13.69,8.1
						l-1.43-0.78c3.03-5.52,8.82-8.95,15.11-8.95c9.5,0,17.24,7.73,17.24,17.24C143.03,765.43,142.25,768.39,140.76,770.98z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M112.86,775.56l-1.31-0.97c0.41-0.56,0.8-1.16,1.14-1.78c1.25-2.29,1.92-4.88,1.92-7.5c0-3.77-1.36-7.4-3.83-10.24
						c-1.94-2.23-4.46-3.86-7.28-4.7c-1.44-0.44-2.96-0.66-4.5-0.66c-8.6,0-15.61,7-15.61,15.61c0,2.32,0.49,4.54,1.47,6.61
						c0.12,0.27,0.25,0.52,0.38,0.77l-1.43,0.78c-0.15-0.28-0.3-0.57-0.43-0.86c-1.07-2.28-1.62-4.74-1.62-7.3
						c0-9.5,7.73-17.24,17.24-17.24c1.7,0,3.37,0.24,4.97,0.73c3.11,0.93,5.89,2.73,8.04,5.2c2.73,3.14,4.23,7.15,4.23,11.31
						c0,2.89-0.73,5.76-2.12,8.29C113.74,774.28,113.32,774.94,112.86,775.56z"
                            />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M112.69,752.49c-2.1-4.93-2.4-8.9-2.28-11.64c0.22-5.05,1.91-6.82,2.86-14.58c0.43-3.53,0.71-5.81,0.29-8.93
					c-0.39-2.91-1.04-7.73-4.9-11.81c-4.24-4.49-9.68-5.35-11.63-5.51c-3.92-0.32-7.09,0.32-7.09,0.32
					c21.03,0.58,23.33,19.3,19.3,30.82c-4.03,11.52,1.15,22.76,1.15,22.76l1.01,0.6l1.59-2.19"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M111.68,726.28c0,0,9.36-3.02,8.79-12.96s-3.17-13.25-3.17-13.25s3.89,3.6,4.61,7.06s2.59,11.52-2.45,15.99
					c-5.04,4.47-7.49,6.34-8.21,9.22L111.68,726.28z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="87.27" y="819.18" width="15.15" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="90.45" y="823.21" width="11.97" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="84.94" y="810.92" width="17.48" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="85.62" y="814.95" width="16.8" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="87.91" y="802.09" width="14.5" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="85.96" y="806.12" width="16.45" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <rect className="st0" x="75.55" y="795.56" width="12.22" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="78.73" y="799.59" width="5.44" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="73.22" y="787.3" width="17.48" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="73.9" y="791.33" width="16.8" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="76.2" y="778.47" width="7.97" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="74.25" y="782.5" width="13.38" height="1.63" />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <rect className="st0" x="81.17" y="766.9" width="12.22" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="78.84" y="758.64" width="17.48" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="79.52" y="762.67" width="16.8" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="79.87" y="753.84" width="13.38" height="1.63" />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="98.72" y="836.8" width="15.99" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="99.72" y="840.83" width="11.52" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <polygon
                            className="st0"
                            points="174.22,719.88 158.64,686.42 147.23,710.4 133.39,663.27 134.95,662.81 147.59,705.85 158.66,682.59 
					174.19,715.96 185.2,691.45 215.97,692.29 197.86,669.72 217.85,651.89 176.9,656.37 186.9,634.44 136.77,660.04 136.03,658.59 
					190.32,630.86 179.57,654.44 222.74,649.71 200.1,669.9 219.44,694.01 186.25,693.11 				"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M136.38,659.34l-2.8,1.26l0.58,2.44c-1.7-1.27-4.33-2.9-8.36-4.8c-9.79-4.61-5.57-14.98-5.57-14.98s0.24,2.64,1.08,5.45
					c1.18,3.95,4.07,5.97,4.68,6.46c1.85,1.47,3.54,2.08,5.76,2.88c1.73,0.62,3.31,1.01,4.64,1.26L136.38,659.34z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M79.27,680.14c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42S82.26,680.14,79.27,680.14z
					 M79.27,670.92c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79c2.09,0,3.79-1.7,3.79-3.79
					C83.07,672.62,81.36,670.92,79.27,670.92z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M38.37,797.96c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42S41.36,797.96,38.37,797.96z
					 M38.37,788.74c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79c2.09,0,3.79-1.7,3.79-3.79
					C42.16,790.44,40.46,788.74,38.37,788.74z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M164.54,766.27c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
					S167.53,766.27,164.54,766.27z M164.54,757.05c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79c2.09,0,3.79-1.7,3.79-3.79
					S166.63,757.05,164.54,757.05z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M162.24,830.51c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
					S165.23,830.51,162.24,830.51z M162.24,821.29c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79c2.09,0,3.79-1.7,3.79-3.79
					S164.33,821.29,162.24,821.29z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <polygon
                            className="st0"
                            points="217.41,693.91 139.05,663.37 133.32,661.36 133.86,659.83 139.62,661.84 218,692.39 				"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <polygon
                            className="st0"
                            points="170.21,687.64 167.82,673.51 180.79,667.5 181.48,668.98 169.64,674.47 171.82,687.37 				"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <polygon
                            className="st0"
                            points="149.93,679.85 148.3,679.68 149.66,666.68 157.95,659.26 159.04,660.47 151.22,667.47 				"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M300.62,832.3l-3.25,13.48c-0.08,0.38-0.16,0.76-0.25,1.14c-3.67,15.22-18.99,24.59-34.21,20.92
					c-15.23-3.67-24.59-18.99-20.92-34.22c0.1-0.4,0.2-0.8,0.32-1.2l3.23-13.4c0,0,8.64,15.52,20.14,15.33
					C277.18,834.16,285.43,818.57,300.62,832.3z"
                        />
                    </g>
                    <g>
                        <path
                            className="st0"
                            d="M269.55,869.45c-2.26,0-4.55-0.26-6.84-0.82c-7.58-1.83-13.99-6.49-18.05-13.14c-4.07-6.65-5.3-14.48-3.47-22.06
					c0.1-0.42,0.21-0.83,0.33-1.23l3.71-15.4l1.02,1.82c0.08,0.15,8.44,14.91,19.22,14.91c0.06,0,0.13,0,0.19,0
					c3.74-0.06,7.12-1.83,10.69-3.71c7.07-3.71,14.39-7.55,24.81,1.88l0.36,0.32l-3.36,13.95c-0.07,0.35-0.15,0.75-0.25,1.14
					C294.7,860.46,282.72,869.45,269.55,869.45z M245.86,821.13l-2.77,11.49c-0.12,0.42-0.22,0.81-0.32,1.2
					c-1.72,7.15-0.56,14.55,3.28,20.82c3.84,6.28,9.89,10.68,17.04,12.41c14.76,3.56,29.67-5.56,33.23-20.32
					c0.09-0.37,0.17-0.74,0.24-1.11l3.14-13.03c-9.38-8.25-15.8-4.88-22.6-1.32c-3.59,1.88-7.3,3.83-11.42,3.9
					C256.06,835.32,248.54,825.25,245.86,821.13z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M264.26,824.95c-11.6,0-16.45-14.38-16.5-14.53l-0.07-0.22l6.6-27.39l1.58,0.38l-6.49,26.93
					c0.65,1.78,5.59,14.19,16.17,13.13l0.16,1.62C265.22,824.92,264.73,824.95,264.26,824.95z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M264.82,808.39c-0.1-0.01-10.25-0.71-13.69-12l1.56-0.47c3.09,10.17,11.85,10.82,12.22,10.84L264.82,808.39z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M249.58,950.65l-1.62-0.14c2.67-30.48,10.63-55.61,15.14-67.87c0.43-1.17,1.59-1.93,2.85-1.84
						c17.88,1.31,33.91-10.41,38.12-27.87c0.12-0.49,0.23-0.98,0.32-1.47l16.13-66.92c0.07-0.31,0.02-0.63-0.14-0.9
						c-0.17-0.27-0.43-0.46-0.74-0.54l-68.64-16.55c-0.31-0.07-0.63-0.02-0.9,0.14c-0.27,0.17-0.46,0.43-0.54,0.74l-16.1,66.79
						c-0.15,0.53-0.29,1.03-0.42,1.58c-4.21,17.45,4.72,35.19,21.23,42.18c1.15,0.49,1.85,1.7,1.7,2.94
						c-1.58,12.97-5.94,38.97-17.45,67.32l-1.51-0.61c11.44-28.18,15.78-54.01,17.34-66.9c0.06-0.53-0.22-1.03-0.72-1.24
						c-17.25-7.3-26.57-25.83-22.17-44.06c0.14-0.57,0.28-1.1,0.43-1.62L248,767.05c0.18-0.73,0.63-1.35,1.27-1.74
						c0.64-0.39,1.4-0.51,2.13-0.34l68.64,16.55c0.73,0.18,1.35,0.63,1.74,1.27c0.39,0.64,0.51,1.4,0.34,2.13l-16.13,66.88
						c-0.09,0.48-0.2,0.99-0.33,1.5c-4.4,18.23-21.15,30.48-39.82,29.11c-0.53-0.04-1.02,0.28-1.2,0.77
						C260.15,895.39,252.24,920.36,249.58,950.65z"
                            />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M268.92,968.26c-8.69,0-18.95-1.34-29.48-3.88c-12.57-3.03-24.03-7.41-32.27-12.33c-8.5-5.08-12.62-10.23-11.59-14.49
					c1.96-8.14,22.46-10.17,47.68-4.7l-0.34,1.59c-23.53-5.1-44.06-3.53-45.75,3.49c-0.84,3.46,3.12,8.1,10.84,12.71
					c8.1,4.84,19.4,9.15,31.81,12.15c12.41,2.99,24.44,4.3,33.85,3.69c8.98-0.59,14.61-2.91,15.44-6.37
					c1.68-6.96-15.71-17.67-38.76-23.86l0.42-1.57c11.65,3.13,22.14,7.41,29.52,12.06c7.79,4.9,11.39,9.66,10.4,13.76
					c-1.03,4.27-7.04,6.97-16.92,7.62C272.22,968.21,270.6,968.26,268.92,968.26z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="211.51"
                            y="925.17"
                            transform="matrix(0.2344 -0.9721 0.9721 0.2344 -752.8024 927.3081)"
                            width="1.63"
                            height="32.84"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="207.37"
                            y="936.07"
                            transform="matrix(0.2344 -0.9721 0.9721 0.2344 -760.479 926.8188)"
                            width="1.63"
                            height="20.31"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="211"
                            y="925.32"
                            transform="matrix(0.2344 -0.9721 0.9721 0.2344 -746.4505 921.5034)"
                            width="1.63"
                            height="18.68"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M416.53,656.1c-0.93,0.73-2.11,1.15-3.27,1.23l-0.57,0.05c-0.17,0.02-0.35,0.03-0.52,0.04c-0.35,0.02-0.7,0.02-1.05,0
						l-4.19-0.39l-2.09-0.22l-0.26-0.03c-0.08-0.01-0.2-0.02-0.22-0.03c-0.1-0.02-0.2-0.03-0.3-0.03c-0.21-0.01-0.42,0.02-0.63,0.08
						c-0.21,0.06-0.42,0.16-0.62,0.29c-0.1,0.07-0.19,0.14-0.28,0.22l-0.13,0.14l-0.17,0.2l-1.34,1.63
						c-0.91,1.08-1.8,2.17-2.73,3.23c0.49-1.32,1.02-2.63,1.53-3.94l0.79-1.96l0.1-0.25c0.04-0.09,0.1-0.2,0.15-0.3
						c0.11-0.2,0.24-0.4,0.38-0.58c0.28-0.38,0.63-0.71,1.03-0.99c0.39-0.28,0.84-0.5,1.31-0.65c0.24-0.07,0.48-0.13,0.73-0.17
						l0.55-0.09l2.08-0.35l4.15-0.66c0.35-0.05,0.69-0.07,1.04-0.07c0.17,0,0.35,0,0.52,0l0.26,0.01l0.13,0l0.06,0l0.02-0.01
						c0.23,0.01,0.41-0.07,0.58-0.19c0.16-0.13,0.29-0.32,0.36-0.53c0.06-0.21,0.05-0.41,0-0.61c0-0.05-0.05-0.1-0.06-0.16
						c0,0-0.02-0.05-0.04-0.08l-0.06-0.12l-0.12-0.24l-0.47-0.93l-0.94-1.86l-1.88-3.72l-3.76-7.45l-0.12-0.23
						c-0.04-0.08-0.07-0.13-0.14-0.29c-0.11-0.27-0.24-0.53-0.31-0.82c-0.09-0.28-0.16-0.57-0.19-0.87
						c-0.05-0.29-0.06-0.59-0.06-0.89c0.01-1.19,0.41-2.35,1.08-3.3c0.67-0.96,1.66-1.71,2.78-2.11c0.56-0.21,1.14-0.31,1.72-0.34
						l1.07-0.04l8.34-0.27l4.17-0.13l2.08-0.06c0.06,0,0.05,0,0.07-0.01l0.04-0.01l0.08,0c0.05-0.03,0.11-0.02,0.15-0.04
						c0.04-0.02,0.1-0.03,0.14-0.06c0.02-0.01,0.05-0.02,0.07-0.03c0.02-0.02,0.04-0.04,0.06-0.05c0.17-0.11,0.31-0.31,0.38-0.51
						c0.08-0.21,0.08-0.41,0.02-0.61c-0.01-0.05-0.03-0.1-0.06-0.15c0.01-0.01-0.11-0.23-0.18-0.37l-0.47-0.93l-0.94-1.86
						l-3.76-7.45l-1.88-3.72l-0.24-0.47l-0.06-0.12l-0.03-0.06l-0.04-0.1l-0.17-0.4c-0.12-0.26-0.18-0.56-0.26-0.84
						c-0.06-0.29-0.1-0.59-0.13-0.88c-0.07-1.19,0.22-2.38,0.82-3.39c0.61-1,1.52-1.83,2.6-2.32c0.55-0.23,1.12-0.4,1.7-0.47
						l0.44-0.04c0.08-0.01,0.12-0.01,0.16-0.01l0.13,0l0.52-0.02l4.17-0.13l1.04-0.03l0.52-0.02l0.12,0l0.04,0
						c0.03,0,0.05-0.01,0.08-0.01c0.21-0.03,0.41-0.13,0.56-0.27c0.04-0.03,0.07-0.08,0.11-0.12l0.05-0.07
						c0.02-0.03,0.01-0.01,0.07-0.11l3.73-6.28c0.35-0.6,0.72-1.19,1.05-1.8c0.17-0.3,0.32-0.62,0.49-0.92
						c0.18-0.3,0.37-0.59,0.58-0.87c0.69-0.88,1.38-1.77,2.07-2.65c0.11-0.15,0.23-0.29,0.34-0.44c0.12-0.15,0.23-0.29,0.35-0.44
						l3.13,1.86c-0.07,0.17-0.14,0.34-0.22,0.51c-0.07,0.17-0.14,0.34-0.22,0.51c-0.44,1.03-0.89,2.06-1.34,3.09
						c-0.15,0.32-0.3,0.63-0.49,0.93c-0.18,0.3-0.38,0.58-0.57,0.87c-0.37,0.59-0.72,1.19-1.07,1.79l-3.73,6.28
						c-0.03,0.05-0.13,0.22-0.21,0.33c-0.08,0.12-0.17,0.24-0.26,0.36c-0.18,0.23-0.38,0.45-0.6,0.65
						c-0.86,0.81-1.97,1.34-3.13,1.52c-0.15,0.02-0.29,0.04-0.44,0.05l-0.22,0.02l-0.14,0.01l-0.52,0.02l-1.04,0.03l-4.17,0.13
						l-0.52,0.02l-0.13,0c-0.04,0-0.09,0-0.09,0.01c-0.03,0-0.05,0.01-0.08,0.01c-0.11,0-0.21,0.04-0.31,0.08
						c-0.18,0.09-0.34,0.23-0.46,0.42c-0.11,0.19-0.16,0.41-0.15,0.61c0.01,0.05,0.01,0.1,0.02,0.15c0.03,0.05,0.02,0.1,0.05,0.15
						c0.01,0.02,0.02,0.05,0.03,0.08l0.01,0.02l0.03,0.05l0.06,0.12l0.23,0.47l1.88,3.73l3.76,7.45l0.94,1.86l0.47,0.93
						c0.08,0.17,0.14,0.25,0.27,0.57c0.12,0.27,0.23,0.54,0.3,0.82c0.32,1.14,0.28,2.39-0.12,3.49c-0.39,1.11-1.11,2.09-2.07,2.78
						l-0.37,0.25c-0.13,0.08-0.26,0.15-0.39,0.21c-0.26,0.15-0.54,0.25-0.82,0.35s-0.57,0.15-0.86,0.22
						c-0.14,0.02-0.29,0.04-0.43,0.05l-0.22,0.02c-0.07,0-0.16,0.01-0.19,0.01l-2.09,0.07l-4.17,0.13l-8.35,0.26l-1.02,0.03
						c-0.12,0.02-0.22,0.02-0.32,0.06c-0.18,0.07-0.37,0.18-0.49,0.37c-0.12,0.18-0.21,0.39-0.2,0.6c0,0.05,0,0.1,0.01,0.15
						c0,0.05,0.02,0.1,0.03,0.15c0,0.06,0.05,0.1,0.06,0.16c0,0,0.05,0.11,0.09,0.18l0.12,0.23l3.76,7.45l1.88,3.72l0.94,1.86
						l0.47,0.93l0.18,0.35c0.02,0.04,0.03,0.07,0.07,0.15c0.11,0.27,0.24,0.53,0.32,0.82c0.36,1.12,0.36,2.38,0,3.5
						C418.15,654.37,417.46,655.38,416.53,656.1z"
                            />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M479.04,600.66c-2.05,0-4.07-0.55-5.88-1.62c-5.49-3.29-7.28-10.38-4.04-15.84c3.24-5.46,10.32-7.28,15.79-4.07
					c2.7,1.6,4.58,4.12,5.35,7.11c0.76,2.99,0.31,6.1-1.26,8.76l0,0c-1.58,2.65-4.09,4.54-7.08,5.3
					C480.96,600.54,480,600.66,479.04,600.66z M479.07,579.18c-3.4,0-6.7,1.74-8.55,4.85c-2.78,4.69-1.24,10.78,3.43,13.58
					c2.32,1.38,4.99,1.76,7.56,1.11c2.57-0.65,4.73-2.27,6.08-4.55l0,0c1.35-2.28,1.74-4.95,1.08-7.52
					c-0.66-2.57-2.27-4.73-4.55-6.08C482.54,579.62,480.79,579.18,479.07,579.18z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M473.12,599.01l-46.79-27.78c-2.65-1.58-4.54-4.09-5.3-7.08c-0.76-2.99-0.32-6.1,1.26-8.75
					c1.58-2.66,4.09-4.54,7.08-5.3c2.99-0.76,6.1-0.31,8.76,1.26l46.79,27.78l-0.83,1.4c-4.7-2.76-10.78-1.19-13.56,3.49
					c-2.78,4.69-1.24,10.78,3.43,13.58L473.12,599.01z M432.24,551.36c-0.82,0-1.65,0.1-2.47,0.31c-2.57,0.65-4.73,2.27-6.08,4.55
					c-1.35,2.28-1.74,4.95-1.08,7.52c0.66,2.57,2.27,4.73,4.55,6.08l41.76,24.79c-1.87-3.46-1.94-7.8,0.21-11.42
					c2.15-3.62,5.99-5.64,9.93-5.65l-41.76-24.79C435.74,551.83,434,551.36,432.24,551.36z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M473.11,599c-5.44-3.26-7.23-10.35-3.99-15.81c3.24-5.46,10.32-7.28,15.79-4.07l-0.83,1.4
					c-4.7-2.76-10.78-1.19-13.56,3.49c-2.78,4.69-1.24,10.78,3.43,13.58L473.11,599z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="437.78"
                            y="582.16"
                            transform="matrix(0.8599 0.5105 -0.5105 0.8599 362.5919 -144.5793)"
                            width="13.77"
                            height="12.12"
                        />
                    </g>
                    <g>
                        <path
                            className="st0"
                            d="M447.78,598.06l-13.24-7.86l7.02-11.82l13.24,7.86L447.78,598.06z M436.77,589.63l10.44,6.2l5.36-9.02l-10.44-6.2
					L436.77,589.63z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M91.31,631.23c-47.23-24.74-57.82-87.48-59.47-99.81l1.62-0.22c1.62,12.19,12.09,74.21,58.61,98.58L91.31,631.23z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M32.89,532.33l-0.43-0.2c-0.13-0.06-13.34-6.55-4.37-28.18l0.51-1.23l0.86,1.02c0.4,0.47,9.69,11.53,8.51,20.73
					c-0.4,3.14-1.99,5.7-4.7,7.59L32.89,532.33z M29.08,505.88c-6.62,17.21,1.63,23.36,3.66,24.56c2.08-1.59,3.29-3.66,3.62-6.17
					C37.25,517.35,31.35,508.84,29.08,505.88z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M38.01,542.9c-1.2,0-2.42-0.2-3.67-0.61l-0.45-0.15l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.41l1.29-0.33l-0.29,1.3
					c-0.14,0.6-3.41,14.67-11.48,19.25C41.57,542.43,39.83,542.9,38.01,542.9z M35.32,540.88c2.52,0.71,4.91,0.43,7.11-0.81
					c6.07-3.44,9.33-13.27,10.34-16.86C35.03,528.25,35.07,538.54,35.32,540.88z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M41.94,558.29c-1.2,0-2.42-0.2-3.67-0.61l-0.45-0.15l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.41l1.29-0.33l-0.29,1.3
					c-0.14,0.6-3.41,14.67-11.48,19.25C45.51,557.82,43.76,558.29,41.94,558.29z M39.26,556.27c2.52,0.71,4.91,0.43,7.11-0.81
					c6.07-3.44,9.33-13.27,10.34-16.86C38.96,543.64,39,553.93,39.26,556.27z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M47.74,574.77c-1.2,0-2.42-0.2-3.67-0.61l-0.45-0.15l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.41l1.29-0.33l-0.29,1.3
					c-0.14,0.6-3.41,14.67-11.48,19.25C51.3,574.3,49.55,574.77,47.74,574.77z M45.05,572.75c2.52,0.71,4.91,0.43,7.11-0.81
					c6.07-3.44,9.33-13.27,10.34-16.86C44.75,560.12,44.79,570.41,45.05,572.75z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M55.47,590.99c-1.2,0-2.42-0.2-3.67-0.61l-0.45-0.15l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.4l1.29-0.33l-0.29,1.3
					c-0.14,0.6-3.41,14.67-11.48,19.24C59.04,590.52,57.29,590.99,55.47,590.99z M52.79,588.97c2.52,0.71,4.91,0.44,7.11-0.81
					c6.07-3.43,9.33-13.26,10.34-16.85C52.49,576.34,52.53,586.63,52.79,588.97z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M64.79,605.66c-1.2,0-2.42-0.2-3.67-0.61l-0.45-0.15l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.4l1.29-0.33l-0.29,1.3
					c-0.14,0.6-3.41,14.67-11.48,19.24C68.35,605.19,66.6,605.66,64.79,605.66z M62.11,603.65c2.52,0.7,4.9,0.43,7.1-0.81
					c6.07-3.44,9.33-13.26,10.34-16.85C61.98,590.98,61.89,601.34,62.11,603.65z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M27.66,549.06c-0.12,0-0.24,0-0.37-0.01c-9.27-0.28-18.73-11.21-19.12-11.68l-0.87-1.01l1.3-0.31
					c22.78-5.44,27.09,8.63,27.13,8.77l0.13,0.45l-0.33,0.34C33.32,547.9,30.67,549.06,27.66,549.06z M10.34,537.33
					c2.57,2.7,10.04,9.88,17.01,10.09c2.54,0.08,4.77-0.8,6.67-2.59C33.14,542.64,28.39,533.52,10.34,537.33z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M33.32,568.36c-0.12,0-0.24,0-0.37-0.01c-9.27-0.28-18.73-11.21-19.12-11.68l-0.87-1.01l1.3-0.31
					c22.78-5.44,27.09,8.62,27.13,8.77l0.13,0.45l-0.33,0.34C38.99,567.2,36.34,568.36,33.32,568.36z M16.01,556.63
					c2.57,2.7,10.04,9.88,17.01,10.09c2.54,0.07,4.77-0.8,6.67-2.59C38.81,561.94,34.05,552.82,16.01,556.63z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M40.25,585.71c-0.12,0-0.24,0-0.37-0.01c-9.27-0.28-18.73-11.21-19.12-11.68l-0.87-1.01l1.3-0.31
					c22.78-5.44,27.09,8.62,27.13,8.77l0.13,0.45l-0.33,0.34C45.91,584.55,43.26,585.71,40.25,585.71z M22.93,573.99
					c2.57,2.7,10.04,9.88,17.01,10.09c2.52,0.07,4.77-0.8,6.66-2.59C45.73,579.29,40.97,570.17,22.93,573.99z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M49.31,604.3c-8.92,0-18.77-8.75-19.2-9.14l-0.99-0.89l1.25-0.48c21.87-8.36,27.99,5.01,28.05,5.15l0.19,0.43
					l-0.28,0.38c-1.97,2.66-4.57,4.17-7.72,4.49C50.16,604.28,49.74,604.3,49.31,604.3z M32.25,594.83
					c2.9,2.34,11.24,8.49,18.18,7.79c2.52-0.25,4.62-1.41,6.27-3.44C55.55,597.11,49.65,588.69,32.25,594.83z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M57.09,620.87c-7.57,0-15.58-3.74-16.01-3.94l-1.21-0.57l1.06-0.81c18.57-14.27,28.25-3.19,28.35-3.08l0.3,0.36
					l-0.16,0.44c-1.13,3.11-3.19,5.3-6.12,6.51C61.4,620.56,59.26,620.87,57.09,620.87z M43.04,616c3.45,1.42,13.2,4.92,19.65,2.27
					c2.34-0.96,4.03-2.67,5.02-5.09C66.03,611.53,57.96,605.15,43.04,616z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M401.46,953.01c-1.53-6.09-3.51-12.35-5.89-18.6c-5.16-13.58-4.97-25.87,0.57-36.53c11-21.18,39.75-28.72,40.04-28.79
					l0.4,1.58c-0.28,0.07-28.34,7.44-39,27.97c-5.31,10.23-5.47,22.07-0.48,35.19c2.4,6.31,4.4,12.63,5.94,18.78L401.46,953.01z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M442.9,873.37c-2.86,0-5.41-1.07-7.61-3.19l-0.34-0.33l0.12-0.46c0.04-0.14,3.89-14.34,26.83-9.65l1.31,0.27l-0.83,1.04
					c-0.38,0.48-9.48,11.71-18.73,12.3C443.4,873.36,443.15,873.37,442.9,873.37z M436.8,869.35c1.96,1.74,4.22,2.54,6.75,2.37
					c6.96-0.44,14.19-7.86,16.67-10.64C442.05,857.85,437.59,867.13,436.8,869.35z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M424.46,874.85l-0.32-0.35c-0.1-0.11-9.83-11.14,6.71-27.71l0.94-0.94l0.41,1.27c0.19,0.58,4.56,14.36-0.05,22.41
					c-1.57,2.75-4.01,4.51-7.24,5.23L424.46,874.85z M431.04,848.94c-12.69,13.39-7.4,22.21-5.99,24.1c2.53-0.68,4.44-2.13,5.7-4.32
					C434.21,862.66,432,852.54,431.04,848.94z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M409.13,883.8l-0.36-0.31c-0.11-0.1-11.03-9.96,3.54-28.3l0.83-1.04l0.55,1.21c0.25,0.56,6.15,13.75,2.49,22.27
					c-1.25,2.91-3.47,4.93-6.61,6.01L409.13,883.8z M412.74,857.32c-10.98,14.6-4.78,22.91-3.22,24.62
					c2.43-0.96,4.17-2.62,5.17-4.94C417.44,870.58,414.1,860.79,412.74,857.32z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M399.17,895.44l-0.46-0.12c-0.14-0.04-14.29-4.09-9.27-26.97l0.28-1.3l1.03,0.85c0.47,0.39,11.58,9.64,12.03,18.9
					c0.16,3.16-0.95,5.96-3.29,8.31L399.17,895.44z M390.75,870.06c-3.48,18.11,5.73,22.7,7.94,23.53c1.76-1.93,2.59-4.19,2.47-6.71
					C400.82,879.92,393.5,872.58,390.75,870.06z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M392.38,905.33c-4.26,0-13.93-2.03-19.08-19.28l-0.38-1.28l1.31,0.24c0.6,0.11,14.8,2.82,19.69,10.7
					c1.67,2.69,2.06,5.67,1.15,8.86l-0.13,0.45l-0.46,0.11C394.45,905.15,393.65,905.33,392.38,905.33z M375.27,886.92
					c5.74,17.53,16.02,17.08,18.35,16.73c0.61-2.54,0.24-4.92-1.09-7.07C388.85,890.65,378.9,887.79,375.27,886.92z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M387.92,922.52c-4.94,0-12.63-2.44-19.68-14.88l-0.66-1.16l1.33-0.06c0.61-0.03,15.05-0.56,21.58,6.03
					c2.23,2.25,3.27,5.07,3.1,8.38l-0.02,0.47l-0.42,0.21C393.1,921.53,391.06,922.52,387.92,922.52z M370.35,908.04
					c9.51,15.8,19.43,13.06,21.63,12.2c0.02-2.61-0.87-4.85-2.65-6.64C384.43,908.65,374.08,908.08,370.35,908.04z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M433.95,888.4c-4.18,0-8.84-0.66-12.23-2.98c-2.61-1.79-4.17-4.36-4.62-7.64l-0.06-0.47l0.37-0.29
					c0.12-0.09,11.9-8.89,27.08,8.94l0.86,1.02l-1.3,0.31C443.72,887.36,439.21,888.4,433.95,888.4z M418.8,878.05
					c0.47,2.57,1.76,4.59,3.85,6.02c5.76,3.94,16.01,2.56,19.68,1.89C430.13,872.37,420.78,876.85,418.8,878.05z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M414.74,897.6c-2.26,0-4.48-0.32-6.46-1.16c-2.92-1.24-4.95-3.45-6.05-6.57l-0.16-0.44l0.31-0.36
					c0.1-0.11,9.9-11.08,28.31,3.39l1.05,0.82l-1.21,0.56C430.11,894.03,422.24,897.6,414.74,897.6z M403.95,889.79
					c0.97,2.43,2.64,4.16,4.97,5.15c6.42,2.72,16.2-0.67,19.67-2.05C413.79,881.87,405.65,888.16,403.95,889.79z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M405.33,915.99c-2.26,0-4.48-0.32-6.46-1.16c-2.92-1.24-4.95-3.45-6.05-6.57l-0.16-0.44l0.31-0.36
					c0.1-0.11,9.9-11.08,28.31,3.39l1.05,0.82l-1.21,0.56C420.7,912.42,412.82,915.99,405.33,915.99z M394.54,908.18
					c0.97,2.43,2.64,4.16,4.97,5.15c6.42,2.72,16.2-0.67,19.67-2.05C404.38,900.26,396.24,906.55,394.54,908.18z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M401.75,933.2c-2.75,0-5.24-1.01-7.41-3.02l-0.35-0.32l0.1-0.46c0.03-0.14,3.56-14.43,26.6-10.28l1.31,0.24l-0.81,1.06
					c-0.37,0.49-9.2,11.93-18.44,12.73C402.42,933.19,402.08,933.2,401.75,933.2z M395.82,929.33c1.99,1.69,4.28,2.43,6.79,2.21
					c6.94-0.6,14-8.19,16.41-11.03C400.97,917.75,396.58,927.14,395.82,929.33z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M154.34,908.39c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C159.76,905.96,157.33,908.39,154.34,908.39z M154.34,899.17c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79C158.13,900.87,156.43,899.17,154.34,899.17z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M137.76,961.22c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42s5.42,2.43,5.42,5.42
				C143.18,958.79,140.75,961.22,137.76,961.22z M137.76,952c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79C141.55,953.7,139.85,952,137.76,952z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M350.13,864.7c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42s5.42,2.43,5.42,5.42S353.12,864.7,350.13,864.7z
				 M350.13,855.49c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79s3.79-1.7,3.79-3.79S352.22,855.49,350.13,855.49z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M454.72,802.84c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C460.14,800.41,457.71,802.84,454.72,802.84z M454.72,793.62c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79C458.51,795.33,456.81,793.62,454.72,793.62z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M257.65,729.71c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C263.08,727.28,260.64,729.71,257.65,729.71z M257.65,720.49c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79C261.45,722.2,259.75,720.49,257.65,720.49z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M202.17,613.43c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				S205.16,613.43,202.17,613.43z M202.17,604.21c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79c2.09,0,3.79-1.7,3.79-3.79
				C205.96,605.91,204.26,604.21,202.17,604.21z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M310.87,530.95c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42s5.42,2.43,5.42,5.42S313.86,530.95,310.87,530.95z
				 M310.87,521.73c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79c2.09,0,3.79-1.7,3.79-3.79S312.96,521.73,310.87,521.73z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M130.74,544.55c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C136.16,542.12,133.73,544.55,130.74,544.55z M130.74,535.33c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79C134.53,537.04,132.83,535.33,130.74,535.33z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M452.81,962.92c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42s5.42,2.43,5.42,5.42S455.8,962.92,452.81,962.92z
				 M452.81,953.7c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79c2.09,0,3.79-1.7,3.79-3.79S454.9,953.7,452.81,953.7z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M344.39,965.47c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				S347.38,965.47,344.39,965.47z M344.39,956.25c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79c2.09,0,3.79-1.7,3.79-3.79
				C348.18,957.95,346.48,956.25,344.39,956.25z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M120.24,953.41l-6.88,3.15l-0.3-0.92c-2.45-5.35-8.79-7.71-14.13-5.26s-7.71,8.78-5.26,14.13l0.02,0.05l0.42,0.77
				l-0.78,0.39L49.16,985.9l-18.21-39.83l0.82-0.3c0.11-0.04,0.22-0.09,0.33-0.14c5.35-2.45,7.71-8.78,5.26-14.13
				c-2.45-5.35-8.78-7.71-14.13-5.26c-0.11,0.05-0.22,0.1-0.32,0.16l-0.77,0.43l-5.48-11.99l12.29-5.62l0.35,0.7
				c2.58,5.15,8.71,7.3,13.96,4.91s7.62-8.44,5.42-13.77l-0.3-0.73l39.36-18l3.15,6.89l-0.74,0.34c-5.36,2.45-7.72,8.79-5.28,14.14
				c2.45,5.35,8.78,7.71,14.13,5.26l0.74-0.34l0.35,0.73L120.24,953.41z M114.25,954.36l3.84-1.75l-19.13-41.85
				c-6.01,2.29-12.85-0.46-15.56-6.38c-2.71-5.92-0.3-12.9,5.35-15.94l-1.81-3.95l-36.47,16.67c2.04,5.95-0.72,12.48-6.53,15.13
				s-12.55,0.47-15.72-4.96l-9.4,4.3l4.1,8.96c6.09-2.56,13.17,0.18,15.93,6.23c2.76,6.05,0.2,13.19-5.72,16.12l16.83,36.81
				l41.95-19.18c-2.37-6.03,0.37-12.95,6.33-15.68C104.21,946.16,111.24,948.62,114.25,954.36z"
                    />
                </g>
                <g>
                    <path
                        className="st0"
                        d="M68.87,947.3c-7.24,3.31-15.82,0.11-19.13-7.13c-3.31-7.24-0.11-15.82,7.13-19.13c7.24-3.31,15.82-0.11,19.13,7.13
				C79.31,935.41,76.11,943.99,68.87,947.3z M57.54,922.52c-6.42,2.94-9.26,10.55-6.32,16.97s10.55,9.26,16.97,6.32
				s9.26-10.55,6.32-16.97S63.97,919.59,57.54,922.52z"
                    />
                </g>
                <g>
                    <path
                        className="st0"
                        d="M66.21,968.76c-2.97,1.36-6.49,0.05-7.84-2.92c-1.36-2.97-0.05-6.49,2.92-7.84c2.97-1.36,6.49-0.05,7.84,2.92
				C70.49,963.88,69.18,967.4,66.21,968.76z M61.97,959.47c-2.15,0.98-3.1,3.53-2.12,5.68c0.98,2.15,3.53,3.1,5.68,2.12
				c2.15-0.98,3.1-3.53,2.12-5.68C66.67,959.44,64.12,958.49,61.97,959.47z"
                    />
                </g>
                <g>
                    <path
                        className="st0"
                        d="M93.52,935.08c-2.97,1.36-6.49,0.05-7.84-2.92c-1.36-2.97-0.05-6.49,2.92-7.84c2.97-1.36,6.49-0.05,7.84,2.92
				C97.79,930.21,96.48,933.72,93.52,935.08z M89.27,925.8c-2.15,0.98-3.1,3.53-2.12,5.68c0.98,2.15,3.53,3.1,5.68,2.12
				c2.15-0.98,3.1-3.53,2.12-5.68C93.97,925.77,91.42,924.82,89.27,925.8z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <circle className="st0" cx="799.67" cy="815.13" r="4.61" />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M806.44,794.68c0.17,0.52,1.79,5.47-1.01,8.07c-2.6,2.41-7.32,1.2-9.79-0.86c-1.51-1.26-1.27-2.08-2.88-4.47
					c-3.59-5.31-7.5-5.22-7.78-7.78c-0.44-3.98,8.47-9.09,10.66-7.35c1.47,1.17-0.79,4.87,1.15,6.63c1.55,1.4,3.66-0.33,6.34,1.15
					C805.33,791.3,806.12,793.72,806.44,794.68z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M789.87,811.1c0.13,1.37-0.3,2.5-1.15,4.75c-1.22,3.2-1.83,4.8-3.02,5.04c-2.15,0.43-5.53-3.7-4.75-6.19
					c0.54-1.74,2.72-1.42,3.89-3.6c1.42-2.65-0.53-5.46,0.29-5.91C786.02,804.71,789.53,807.54,789.87,811.1z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M871.35,796.57c-0.7,0-1.4-0.07-2.1-0.22c-2.68-0.55-4.98-2.12-6.49-4.41l-77.85-118.48c-0.04-0.06-0.08-0.12-0.11-0.18
					c-6-11.09-5.25-24.42,1.96-34.8c0.25-0.36,0.51-0.71,0.77-1.06c0.18-0.24,0.19-0.56,0.03-0.81l-36.2-55.09
					c-0.34-0.52-0.46-1.14-0.34-1.76c0.13-0.61,0.48-1.14,1-1.48l13.98-9.19c0.52-0.34,1.15-0.46,1.76-0.34
					c0.61,0.13,1.14,0.48,1.48,1l36.2,55.09c0.16,0.25,0.46,0.37,0.75,0.3c0.42-0.1,0.85-0.2,1.28-0.28
					c12.38-2.51,24.92,2.09,32.72,12.01c0.05,0.06,0.09,0.12,0.13,0.18l77.85,118.47c3.1,4.72,1.79,11.09-2.94,14.19l-38.28,25.16
					C875.26,795.99,873.32,796.57,871.35,796.57z M767.29,570.35c-0.14,0-0.27,0.04-0.39,0.12l-13.98,9.19
					c-0.16,0.1-0.27,0.26-0.3,0.45c-0.04,0.18,0,0.37,0.1,0.53l36.2,55.09c0.54,0.82,0.51,1.9-0.09,2.69
					c-0.25,0.33-0.49,0.67-0.73,1.01c-6.86,9.87-7.57,22.54-1.87,33.09c0.01,0.02,0.02,0.04,0.04,0.06l77.86,118.48
					c1.26,1.92,3.2,3.24,5.45,3.71c2.25,0.47,4.55,0.03,6.48-1.24l38.28-25.16c1.92-1.26,3.24-3.2,3.7-5.45
					c0.47-2.25,0.03-4.55-1.24-6.48l-77.86-118.48c-0.01-0.02-0.03-0.04-0.04-0.06c-7.42-9.42-19.34-13.8-31.11-11.42
					c-0.41,0.08-0.81,0.17-1.22,0.27c-0.95,0.23-1.96-0.16-2.5-0.99l-36.2-55.09c-0.1-0.16-0.26-0.26-0.45-0.3
					C767.38,570.36,767.34,570.35,767.29,570.35z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M867.76,791.13c-1.03,0-2.07-0.16-3.09-0.48l0.49-1.55c2.48,0.78,5.15,0.41,7.32-1.02l15.3-10.05l0.9,1.36l-15.3,10.05
					C871.68,790.55,869.73,791.13,867.76,791.13z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M865.21,787.24c-0.7,0-1.4-0.07-2.1-0.22l0.33-1.6c2.26,0.47,4.55,0.03,6.48-1.24l3.89-2.56l0.89,1.36l-3.89,2.56
					C869.13,786.66,867.19,787.24,865.21,787.24z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M833.6,643.48c-5.36-6.84-14.34-10.15-22.87-8.42l-0.32-1.6c9.27-1.88,18.65,1.58,24.48,9.01L833.6,643.48z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M747.44,582c-0.27,0-0.54-0.03-0.81-0.08c-1.03-0.21-1.92-0.82-2.5-1.7l-4.89-7.45c-1.2-1.82-0.69-4.27,1.13-5.47
					l20.18-13.26c0.88-0.58,1.94-0.78,2.97-0.57c1.03,0.21,1.92,0.82,2.5,1.7l4.89,7.45c1.2,1.82,0.69,4.27-1.13,5.47l-20.18,13.26
					C748.95,581.78,748.2,582,747.44,582z M762.71,555.02c-0.45,0-0.89,0.13-1.27,0.38l-20.18,13.26c-1.07,0.7-1.37,2.14-0.66,3.21
					l4.89,7.45c0.34,0.52,0.86,0.87,1.47,1c0.61,0.13,1.23,0.01,1.74-0.33l20.18-13.26c1.07-0.7,1.37-2.14,0.66-3.21l-4.89-7.45
					c-0.34-0.52-0.86-0.87-1.47-1C763.03,555.04,762.87,555.02,762.71,555.02z"
                        />
                    </g>
                </g>
                <path
                    className="st0"
                    d="M815.03,716.34l29.26,44.53c13.36-5.83,33.07-16.53,52.58-34.78l-37.36-56.85c-19.48,18.17-39.15,28.9-52.58,34.78
			l4.04,6.15c5.98-2.93,12.02-6.19,17.97-9.72l0.83,1.4c-5.93,3.51-11.94,6.76-17.9,9.69l2.25,3.42c5.97-2.92,12.02-6.19,17.97-9.72
			c0.1-0.06,10.3-6.16,12.26-7.81l1.05,1.25c-2.06,1.73-12.05,7.71-12.47,7.96C827,710.17,820.98,713.42,815.03,716.34z"
                />
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="755.94"
                            y="572.14"
                            transform="matrix(0.8357 -0.5492 0.5492 0.8357 -194.9319 516.5108)"
                            width="19.74"
                            height="23.84"
                        />
                    </g>
                    <g>
                        <path
                            className="st0"
                            d="M763.87,600.57l-13.99-21.29l17.86-11.74l13.99,21.29L763.87,600.57z M752.14,579.75l12.2,18.57l15.14-9.95l-12.2-18.57
					L752.14,579.75z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M603.74,749.58c-1.5-0.45-3.08-0.69-4.73-0.69c-9.07,0-16.42,7.35-16.42,16.42c0,2.49,0.55,4.85,1.55,6.96l-0.92,1.06
					l-18.05,3.26l10.56-16.9c0,0-26.12,2.3-26.69,2.3c-0.58,0,13.06-15.75,13.06-15.75l-19.39-21.32l25.16,6.34v-22.66l20.16,20.36
					l9.03-17.48L603.74,749.58z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M615.13,852.26c-9.5,0-17.24-7.73-17.24-17.24c0-2.45,0.5-4.81,1.49-7.02l0.24-0.53l0.58,0.05
						c0.45,0.04,0.92,0.06,1.39,0.06c6.15,0,11.75-3.63,14.26-9.25l0.24-0.53l0.58,0.05c8.81,0.77,15.7,8.31,15.7,17.17
						C632.37,844.53,624.64,852.26,615.13,852.26z M600.66,829.19c-0.75,1.85-1.13,3.81-1.13,5.84c0,8.6,7,15.61,15.61,15.61
						c8.6,0,15.61-7,15.61-15.61c0-7.83-5.95-14.52-13.65-15.48c-2.88,5.89-8.9,9.67-15.49,9.67
						C601.28,829.21,600.97,829.2,600.66,829.19z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M600.06,829.14c-8.81-0.77-15.7-8.31-15.7-17.17c0-9.5,7.73-17.24,17.24-17.24c9.5,0,17.24,7.73,17.24,17.24
						c0,2.45-0.5,4.81-1.49,7.02l-1.49-0.66c0.9-2.01,1.35-4.15,1.35-6.36c0-8.6-7-15.61-15.61-15.61c-8.6,0-15.61,7-15.61,15.61
						c0,8.02,6.24,14.84,14.22,15.54L600.06,829.14z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M630.98,831.23h-0.08v-1.63h0.08c8.6,0,15.61-7,15.61-15.61c0-3.75-1.35-7.38-3.81-10.22
						c-2.97-3.42-7.27-5.39-11.8-5.39c-0.19,0-0.38,0-0.57,0.01l-0.06-1.63c0.21-0.01,0.42-0.01,0.63-0.01c5,0,9.75,2.17,13.03,5.95
						c2.71,3.13,4.21,7.14,4.21,11.29C648.22,823.5,640.48,831.23,630.98,831.23z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M617.51,808.31l-0.22-1.61c7.67-1.06,13.45-7.7,13.45-15.46c0-8.6-7-15.61-15.61-15.61c-8.6,0-15.61,7-15.61,15.61
						c0,1.46,0.2,2.89,0.59,4.25l-1.57,0.45c-0.43-1.51-0.65-3.09-0.65-4.7c0-9.5,7.73-17.24,17.24-17.24s17.24,7.73,17.24,17.24
						C632.37,799.8,625.98,807.14,617.51,808.31z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M586.68,805.6c-8.19-1.49-14.13-8.62-14.13-16.95c0-9.5,7.73-17.24,17.23-17.24c5.82,0,11.21,2.91,14.41,7.78
						l-1.36,0.9c-2.9-4.41-7.78-7.05-13.05-7.05c-8.6,0-15.6,7-15.6,15.61c0,7.55,5.38,14,12.79,15.35L586.68,805.6z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M643.48,804.05l-0.17-1.62c7.94-0.84,13.94-7.52,13.94-15.52c0-8.6-7-15.61-15.61-15.61c-6.03,0-11.41,3.37-14.04,8.8
						l-1.47-0.71c2.87-5.9,8.95-9.71,15.51-9.71c9.5,0,17.24,7.73,17.24,17.24C658.87,795.75,652.25,803.12,643.48,804.05z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M640.76,770.98l-1.41-0.81c1.34-2.34,2.05-5.02,2.05-7.74c0-8.6-7-15.61-15.61-15.61c-5.7,0-10.94,3.1-13.69,8.1
						l-1.43-0.78c3.03-5.52,8.82-8.95,15.11-8.95c9.5,0,17.24,7.73,17.24,17.24C643.03,765.43,642.25,768.39,640.76,770.98z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M612.86,775.56l-1.31-0.97c0.41-0.56,0.8-1.16,1.14-1.78c1.25-2.29,1.92-4.88,1.92-7.5c0-3.77-1.36-7.4-3.83-10.24
						c-1.94-2.23-4.46-3.86-7.28-4.7c-1.44-0.44-2.96-0.66-4.5-0.66c-8.6,0-15.61,7-15.61,15.61c0,2.32,0.49,4.54,1.47,6.61
						c0.12,0.27,0.25,0.52,0.38,0.77l-1.43,0.78c-0.15-0.28-0.3-0.57-0.43-0.86c-1.07-2.28-1.62-4.74-1.62-7.3
						c0-9.5,7.73-17.24,17.24-17.24c1.7,0,3.37,0.24,4.97,0.73c3.11,0.93,5.89,2.73,8.04,5.2c2.73,3.14,4.23,7.15,4.23,11.31
						c0,2.89-0.73,5.76-2.12,8.29C613.74,774.28,613.32,774.94,612.86,775.56z"
                            />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M612.69,752.49c-2.1-4.93-2.4-8.9-2.28-11.64c0.22-5.05,1.91-6.82,2.86-14.58c0.43-3.53,0.71-5.81,0.29-8.93
					c-0.39-2.91-1.04-7.73-4.9-11.81c-4.24-4.49-9.68-5.35-11.63-5.51c-3.92-0.32-7.09,0.32-7.09,0.32
					c21.03,0.58,23.33,19.3,19.3,30.82c-4.03,11.52,1.15,22.76,1.15,22.76l1.01,0.6l1.59-2.19"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M611.68,726.28c0,0,9.36-3.02,8.79-12.96c-0.58-9.94-3.17-13.25-3.17-13.25s3.89,3.6,4.61,7.06
					c0.72,3.46,2.59,11.52-2.45,15.99c-5.04,4.47-7.49,6.34-8.21,9.22L611.68,726.28z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="587.27" y="819.18" width="15.15" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="590.45" y="823.21" width="11.97" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="584.94" y="810.92" width="17.48" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="585.62" y="814.95" width="16.8" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="587.91" y="802.09" width="14.5" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="585.96" y="806.12" width="16.45" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <rect className="st0" x="575.55" y="795.56" width="12.22" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="578.73" y="799.59" width="5.44" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="573.22" y="787.3" width="17.48" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="573.9" y="791.33" width="16.8" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="576.2" y="778.47" width="7.97" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="574.25" y="782.5" width="13.38" height="1.63" />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <rect className="st0" x="581.17" y="766.9" width="12.22" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="578.84" y="758.64" width="17.48" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="579.52" y="762.67" width="16.8" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="579.87" y="753.84" width="13.38" height="1.63" />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="598.72" y="836.8" width="15.99" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="599.72" y="840.83" width="11.52" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <polygon
                            className="st0"
                            points="674.22,719.88 658.64,686.42 647.23,710.4 633.39,663.27 634.95,662.81 647.59,705.85 658.66,682.59 
					674.19,715.96 685.2,691.45 715.97,692.29 697.86,669.72 717.85,651.89 676.9,656.37 686.9,634.44 636.77,660.04 636.03,658.59 
					690.32,630.86 679.57,654.44 722.74,649.71 700.1,669.9 719.44,694.01 686.25,693.11 				"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M636.38,659.34l-2.8,1.26l0.58,2.44c-1.7-1.27-4.33-2.9-8.36-4.8c-9.79-4.61-5.57-14.98-5.57-14.98s0.24,2.64,1.08,5.45
					c1.18,3.95,4.07,5.97,4.68,6.46c1.85,1.47,3.54,2.08,5.76,2.88c1.73,0.62,3.31,1.01,4.64,1.26L636.38,659.34z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M579.27,680.14c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
					S582.26,680.14,579.27,680.14z M579.27,670.92c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79c2.09,0,3.79-1.7,3.79-3.79
					C583.07,672.62,581.36,670.92,579.27,670.92z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M538.37,797.96c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
					S541.36,797.96,538.37,797.96z M538.37,788.74c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79c2.09,0,3.79-1.7,3.79-3.79
					C542.16,790.44,540.46,788.74,538.37,788.74z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M664.54,766.27c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
					S667.53,766.27,664.54,766.27z M664.54,757.05c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79c2.09,0,3.79-1.7,3.79-3.79
					S666.63,757.05,664.54,757.05z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M662.24,830.51c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
					S665.23,830.51,662.24,830.51z M662.24,821.29c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79c2.09,0,3.79-1.7,3.79-3.79
					S664.33,821.29,662.24,821.29z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <polygon
                            className="st0"
                            points="717.41,693.91 639.05,663.37 633.32,661.36 633.86,659.83 639.62,661.84 718,692.39 				"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <polygon
                            className="st0"
                            points="670.21,687.64 667.82,673.51 680.79,667.5 681.48,668.98 669.64,674.47 671.82,687.37 				"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <polygon
                            className="st0"
                            points="649.93,679.85 648.3,679.68 649.66,666.68 657.95,659.26 659.04,660.47 651.22,667.47 				"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M800.62,832.3l-3.25,13.48c-0.08,0.38-0.16,0.76-0.25,1.14c-3.67,15.22-18.99,24.59-34.21,20.92
					c-15.23-3.67-24.59-18.99-20.92-34.22c0.1-0.4,0.2-0.8,0.32-1.2l3.23-13.4c0,0,8.64,15.52,20.15,15.33
					C777.18,834.16,785.43,818.57,800.62,832.3z"
                        />
                    </g>
                    <g>
                        <path
                            className="st0"
                            d="M769.55,869.45c-2.26,0-4.55-0.26-6.84-0.82c-7.58-1.83-13.99-6.49-18.05-13.14c-4.07-6.65-5.3-14.48-3.47-22.06
					c0.1-0.42,0.21-0.83,0.33-1.23l3.71-15.4l1.02,1.82c0.08,0.15,8.44,14.91,19.22,14.91c0.06,0,0.13,0,0.19,0
					c3.74-0.06,7.12-1.83,10.69-3.71c7.07-3.71,14.39-7.55,24.81,1.88l0.36,0.32l-3.36,13.95c-0.07,0.35-0.15,0.75-0.25,1.14
					C794.7,860.46,782.72,869.45,769.55,869.45z M745.86,821.13l-2.77,11.49c-0.12,0.42-0.22,0.81-0.32,1.2
					c-1.72,7.15-0.56,14.55,3.28,20.82c3.84,6.28,9.89,10.68,17.04,12.41c14.76,3.56,29.67-5.56,33.23-20.32
					c0.09-0.37,0.17-0.74,0.24-1.11l3.14-13.03c-9.38-8.25-15.8-4.88-22.6-1.32c-3.59,1.88-7.3,3.83-11.42,3.9
					C756.06,835.32,748.54,825.25,745.86,821.13z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M764.26,824.95c-11.6,0-16.45-14.38-16.5-14.53l-0.07-0.22l6.6-27.39l1.58,0.38l-6.49,26.93
					c0.65,1.78,5.59,14.19,16.17,13.13l0.16,1.62C765.22,824.92,764.73,824.95,764.26,824.95z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M764.82,808.39c-0.1-0.01-10.25-0.71-13.69-12l1.56-0.47c3.09,10.17,11.85,10.82,12.22,10.84L764.82,808.39z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M749.58,950.65l-1.62-0.14c2.67-30.48,10.63-55.61,15.14-67.87c0.43-1.17,1.59-1.93,2.85-1.84
						c17.88,1.31,33.91-10.41,38.12-27.87c0.12-0.49,0.23-0.98,0.32-1.47l16.13-66.92c0.07-0.31,0.02-0.63-0.14-0.9
						c-0.17-0.27-0.43-0.46-0.74-0.54l-68.64-16.55c-0.31-0.07-0.63-0.02-0.9,0.14c-0.27,0.17-0.46,0.43-0.54,0.74l-16.1,66.79
						c-0.15,0.53-0.29,1.03-0.42,1.58c-4.21,17.45,4.72,35.19,21.23,42.18c1.15,0.49,1.85,1.7,1.7,2.94
						c-1.58,12.97-5.94,38.97-17.45,67.32l-1.51-0.61c11.44-28.18,15.78-54.01,17.34-66.9c0.06-0.53-0.22-1.03-0.72-1.24
						c-17.25-7.3-26.57-25.83-22.17-44.06c0.14-0.57,0.28-1.1,0.43-1.62L748,767.05c0.18-0.73,0.63-1.35,1.27-1.74
						c0.64-0.39,1.4-0.51,2.13-0.34l68.64,16.55c0.73,0.18,1.35,0.63,1.74,1.27c0.39,0.64,0.51,1.4,0.34,2.13l-16.13,66.88
						c-0.09,0.48-0.2,0.99-0.33,1.5c-4.4,18.23-21.15,30.48-39.82,29.11c-0.53-0.04-1.02,0.28-1.2,0.77
						C760.15,895.39,752.24,920.36,749.58,950.65z"
                            />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M768.92,968.26c-8.69,0-18.95-1.34-29.48-3.88c-12.57-3.03-24.03-7.41-32.27-12.33c-8.5-5.08-12.62-10.23-11.59-14.49
					c1.96-8.14,22.46-10.17,47.68-4.7l-0.34,1.59c-23.53-5.1-44.06-3.53-45.75,3.49c-0.84,3.46,3.12,8.1,10.84,12.71
					c8.1,4.84,19.4,9.15,31.81,12.15c12.41,2.99,24.44,4.3,33.85,3.69c8.98-0.59,14.61-2.91,15.44-6.37
					c1.68-6.96-15.71-17.67-38.76-23.86l0.42-1.57c11.65,3.13,22.14,7.41,29.52,12.06c7.79,4.9,11.39,9.66,10.4,13.76
					c-1.03,4.27-7.04,6.97-16.92,7.62C772.22,968.21,770.6,968.26,768.92,968.26z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="711.51"
                            y="925.17"
                            transform="matrix(0.2344 -0.9721 0.9721 0.2344 -369.9969 1413.3795)"
                            width="1.63"
                            height="32.84"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="707.37"
                            y="936.07"
                            transform="matrix(0.2344 -0.9721 0.9721 0.2344 -377.6747 1412.89)"
                            width="1.63"
                            height="20.31"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="711"
                            y="925.32"
                            transform="matrix(0.2344 -0.9721 0.9721 0.2344 -363.6403 1407.5759)"
                            width="1.63"
                            height="18.68"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M916.53,656.1c-0.93,0.73-2.11,1.15-3.27,1.23l-0.57,0.05c-0.17,0.02-0.35,0.03-0.52,0.04c-0.35,0.02-0.7,0.02-1.05,0
						l-4.19-0.39l-2.09-0.22l-0.26-0.03c-0.08-0.01-0.2-0.02-0.22-0.03c-0.1-0.02-0.2-0.03-0.3-0.03c-0.21-0.01-0.42,0.02-0.63,0.08
						c-0.21,0.06-0.42,0.16-0.62,0.29c-0.1,0.07-0.19,0.14-0.28,0.22l-0.13,0.14l-0.17,0.2l-1.34,1.63
						c-0.91,1.08-1.8,2.17-2.73,3.23c0.49-1.32,1.02-2.63,1.53-3.94l0.79-1.96l0.1-0.25c0.04-0.09,0.1-0.2,0.15-0.3
						c0.11-0.2,0.24-0.4,0.38-0.58c0.28-0.38,0.63-0.71,1.03-0.99c0.39-0.28,0.84-0.5,1.31-0.65c0.24-0.07,0.48-0.13,0.73-0.17
						l0.55-0.09l2.08-0.35l4.15-0.66c0.35-0.05,0.69-0.07,1.04-0.07c0.17,0,0.35,0,0.52,0l0.26,0.01l0.13,0l0.06,0l0.02-0.01
						c0.23,0.01,0.41-0.07,0.58-0.19c0.16-0.13,0.29-0.32,0.36-0.53c0.06-0.21,0.05-0.41,0-0.61c0-0.05-0.05-0.1-0.06-0.16
						c0,0-0.02-0.05-0.04-0.08l-0.06-0.12l-0.12-0.24l-0.47-0.93l-0.94-1.86l-1.88-3.72l-3.76-7.45l-0.12-0.23
						c-0.04-0.08-0.07-0.13-0.14-0.29c-0.11-0.27-0.24-0.53-0.31-0.82c-0.09-0.28-0.16-0.57-0.19-0.87
						c-0.05-0.29-0.06-0.59-0.06-0.89c0.01-1.19,0.41-2.35,1.08-3.3c0.67-0.96,1.66-1.71,2.78-2.11c0.56-0.21,1.14-0.31,1.72-0.34
						l1.07-0.04l8.34-0.27l4.17-0.13l2.08-0.06c0.06,0,0.05,0,0.07-0.01l0.04-0.01l0.08,0c0.05-0.03,0.11-0.02,0.15-0.04
						c0.04-0.02,0.1-0.03,0.14-0.06c0.02-0.01,0.05-0.02,0.07-0.03c0.02-0.02,0.04-0.04,0.06-0.05c0.17-0.11,0.31-0.31,0.38-0.51
						c0.08-0.21,0.08-0.41,0.02-0.61c-0.01-0.05-0.03-0.1-0.06-0.15c0.01-0.01-0.11-0.23-0.18-0.37l-0.47-0.93l-0.94-1.86
						l-3.76-7.45l-1.88-3.72l-0.24-0.47l-0.06-0.12l-0.03-0.06l-0.04-0.1l-0.17-0.4c-0.12-0.26-0.18-0.56-0.26-0.84
						c-0.06-0.29-0.1-0.59-0.13-0.88c-0.07-1.19,0.22-2.38,0.82-3.39c0.61-1,1.52-1.83,2.6-2.32c0.55-0.23,1.12-0.4,1.7-0.47
						l0.44-0.04c0.08-0.01,0.12-0.01,0.16-0.01l0.13,0l0.52-0.02l4.17-0.13l1.04-0.03l0.52-0.02l0.12,0l0.04,0
						c0.03,0,0.05-0.01,0.08-0.01c0.21-0.03,0.41-0.13,0.56-0.27c0.04-0.03,0.07-0.08,0.11-0.12l0.05-0.07
						c0.02-0.03,0.01-0.01,0.07-0.11l3.73-6.28c0.35-0.6,0.72-1.19,1.05-1.8c0.17-0.3,0.32-0.62,0.49-0.92
						c0.18-0.3,0.37-0.59,0.58-0.87c0.69-0.88,1.38-1.77,2.07-2.65c0.11-0.15,0.23-0.29,0.34-0.44c0.12-0.15,0.23-0.29,0.35-0.44
						l3.13,1.86c-0.07,0.17-0.14,0.34-0.22,0.51c-0.07,0.17-0.14,0.34-0.22,0.51c-0.44,1.03-0.89,2.06-1.34,3.09
						c-0.15,0.32-0.3,0.63-0.49,0.93c-0.18,0.3-0.38,0.58-0.57,0.87c-0.37,0.59-0.72,1.19-1.07,1.79l-3.73,6.28
						c-0.03,0.05-0.13,0.22-0.21,0.33c-0.08,0.12-0.17,0.24-0.26,0.36c-0.18,0.23-0.38,0.45-0.6,0.65
						c-0.86,0.81-1.97,1.34-3.13,1.52c-0.15,0.02-0.29,0.04-0.44,0.05l-0.22,0.02l-0.14,0.01l-0.52,0.02l-1.04,0.03l-4.17,0.13
						l-0.52,0.02l-0.13,0c-0.04,0-0.09,0-0.09,0.01c-0.03,0-0.05,0.01-0.08,0.01c-0.11,0-0.21,0.04-0.31,0.08
						c-0.18,0.09-0.34,0.23-0.46,0.42c-0.11,0.19-0.16,0.41-0.15,0.61c0.01,0.05,0.01,0.1,0.02,0.15c0.03,0.05,0.02,0.1,0.05,0.15
						c0.01,0.02,0.02,0.05,0.03,0.08l0.01,0.02l0.03,0.05l0.06,0.12l0.23,0.47l1.88,3.73l3.76,7.45l0.94,1.86l0.47,0.93
						c0.08,0.17,0.14,0.25,0.27,0.57c0.12,0.27,0.23,0.54,0.3,0.82c0.32,1.14,0.28,2.39-0.12,3.49c-0.39,1.11-1.11,2.09-2.07,2.78
						l-0.37,0.25c-0.13,0.08-0.26,0.15-0.39,0.21c-0.26,0.15-0.54,0.25-0.82,0.35c-0.28,0.1-0.57,0.15-0.86,0.22
						c-0.14,0.02-0.29,0.04-0.43,0.05l-0.22,0.02c-0.07,0-0.16,0.01-0.19,0.01l-2.09,0.07l-4.17,0.13l-8.35,0.26l-1.02,0.03
						c-0.12,0.02-0.22,0.02-0.32,0.06c-0.18,0.07-0.37,0.18-0.49,0.37c-0.12,0.18-0.21,0.39-0.2,0.6c0,0.05,0,0.1,0.01,0.15
						c0,0.05,0.02,0.1,0.03,0.15c0,0.06,0.05,0.1,0.06,0.16c0,0,0.05,0.11,0.09,0.18l0.12,0.23l3.76,7.45l1.88,3.72l0.94,1.86
						l0.47,0.93l0.18,0.35c0.02,0.04,0.03,0.07,0.07,0.15c0.11,0.27,0.24,0.53,0.32,0.82c0.36,1.12,0.36,2.38,0,3.5
						C918.15,654.37,917.46,655.38,916.53,656.1z"
                            />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M979.04,600.66c-2.05,0-4.07-0.55-5.88-1.62c-5.49-3.29-7.28-10.38-4.04-15.84c3.24-5.46,10.32-7.28,15.79-4.07
					c2.7,1.6,4.58,4.12,5.35,7.11c0.76,2.99,0.31,6.1-1.26,8.76l0,0c-1.58,2.65-4.09,4.54-7.08,5.3
					C980.96,600.54,980,600.66,979.04,600.66z M979.07,579.18c-3.4,0-6.7,1.74-8.55,4.85c-2.78,4.69-1.24,10.78,3.43,13.58
					c2.32,1.38,4.99,1.76,7.56,1.11c2.57-0.65,4.73-2.27,6.08-4.55l0,0c1.35-2.28,1.74-4.95,1.08-7.52
					c-0.66-2.57-2.27-4.73-4.55-6.08C982.54,579.62,980.79,579.18,979.07,579.18z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M973.12,599.01l-46.79-27.78c-2.65-1.58-4.54-4.09-5.3-7.08c-0.76-2.99-0.32-6.1,1.26-8.75
					c1.58-2.66,4.09-4.54,7.08-5.3c2.99-0.76,6.1-0.31,8.76,1.26l46.79,27.78l-0.83,1.4c-4.7-2.76-10.78-1.19-13.56,3.49
					c-2.78,4.69-1.24,10.78,3.43,13.58L973.12,599.01z M932.24,551.36c-0.82,0-1.65,0.1-2.47,0.31c-2.57,0.65-4.73,2.27-6.08,4.55
					c-1.35,2.28-1.74,4.95-1.08,7.52c0.66,2.57,2.27,4.73,4.55,6.08l41.76,24.79c-1.87-3.46-1.94-7.8,0.21-11.42
					c2.15-3.62,5.99-5.64,9.93-5.65l-41.76-24.79C935.74,551.83,934,551.36,932.24,551.36z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M973.11,599c-5.44-3.26-7.23-10.35-3.99-15.81c3.24-5.46,10.32-7.28,15.79-4.07l-0.83,1.4
					c-4.7-2.76-10.78-1.19-13.56,3.49c-2.78,4.69-1.24,10.78,3.43,13.58L973.11,599z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="937.78"
                            y="582.16"
                            transform="matrix(0.8599 0.5105 -0.5105 0.8599 432.653 -399.8295)"
                            width="13.77"
                            height="12.12"
                        />
                    </g>
                    <g>
                        <path
                            className="st0"
                            d="M947.78,598.06l-13.24-7.86l7.02-11.82l13.24,7.86L947.78,598.06z M936.77,589.63l10.44,6.2l5.36-9.02l-10.44-6.2
					L936.77,589.63z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M591.31,631.23c-47.23-24.74-57.82-87.48-59.47-99.81l1.62-0.22c1.62,12.19,12.09,74.21,58.61,98.58L591.31,631.23z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M532.89,532.33l-0.43-0.2c-0.13-0.06-13.34-6.55-4.37-28.18l0.51-1.23l0.86,1.02c0.4,0.47,9.69,11.53,8.51,20.73
					c-0.4,3.14-1.99,5.7-4.7,7.59L532.89,532.33z M529.08,505.88c-6.62,17.21,1.63,23.36,3.66,24.56c2.08-1.59,3.29-3.66,3.62-6.17
					C537.25,517.35,531.35,508.84,529.08,505.88z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M538.01,542.9c-1.2,0-2.42-0.2-3.67-0.61l-0.45-0.15l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.41l1.29-0.33l-0.29,1.3
					c-0.14,0.6-3.41,14.67-11.48,19.25C541.57,542.43,539.83,542.9,538.01,542.9z M535.32,540.88c2.52,0.71,4.91,0.43,7.11-0.81
					c6.07-3.44,9.33-13.27,10.34-16.86C535.03,528.25,535.07,538.54,535.32,540.88z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M541.94,558.29c-1.2,0-2.42-0.2-3.67-0.61l-0.45-0.15l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.41l1.29-0.33l-0.29,1.3
					c-0.14,0.6-3.41,14.67-11.48,19.25C545.51,557.82,543.76,558.29,541.94,558.29z M539.26,556.27c2.52,0.71,4.91,0.43,7.11-0.81
					c6.07-3.44,9.33-13.27,10.34-16.86C538.96,543.64,539,553.93,539.26,556.27z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M547.74,574.77c-1.2,0-2.42-0.2-3.67-0.61l-0.45-0.15l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.41l1.29-0.33l-0.29,1.3
					c-0.14,0.6-3.41,14.67-11.48,19.25C551.3,574.3,549.55,574.77,547.74,574.77z M545.05,572.75c2.52,0.71,4.91,0.43,7.11-0.81
					c6.07-3.44,9.33-13.27,10.34-16.86C544.75,560.12,544.79,570.41,545.05,572.75z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M555.47,590.99c-1.2,0-2.42-0.2-3.67-0.61l-0.45-0.15l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.4l1.29-0.33l-0.29,1.3
					c-0.14,0.6-3.41,14.67-11.48,19.24C559.04,590.52,557.29,590.99,555.47,590.99z M552.79,588.97c2.52,0.71,4.91,0.44,7.11-0.81
					c6.07-3.43,9.33-13.26,10.34-16.85C552.49,576.34,552.53,586.63,552.79,588.97z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M564.79,605.66c-1.2,0-2.42-0.2-3.67-0.61l-0.45-0.15l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.4l1.29-0.33l-0.29,1.3
					c-0.14,0.6-3.41,14.67-11.48,19.24C568.35,605.19,566.6,605.66,564.79,605.66z M562.11,603.65c2.52,0.7,4.9,0.43,7.1-0.81
					c6.07-3.44,9.33-13.26,10.34-16.85C561.98,590.98,561.89,601.34,562.11,603.65z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M527.66,549.06c-0.12,0-0.24,0-0.37-0.01c-9.27-0.28-18.73-11.21-19.12-11.68l-0.87-1.01l1.3-0.31
					c22.78-5.44,27.09,8.63,27.13,8.77l0.13,0.45l-0.33,0.34C533.32,547.9,530.67,549.06,527.66,549.06z M510.34,537.33
					c2.57,2.7,10.04,9.88,17.01,10.09c2.54,0.08,4.77-0.8,6.67-2.59C533.14,542.64,528.39,533.52,510.34,537.33z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M533.32,568.36c-0.12,0-0.24,0-0.37-0.01c-9.27-0.28-18.73-11.21-19.12-11.68l-0.87-1.01l1.3-0.31
					c22.78-5.44,27.09,8.62,27.13,8.77l0.13,0.45l-0.33,0.34C538.99,567.2,536.34,568.36,533.32,568.36z M516.01,556.63
					c2.57,2.7,10.04,9.88,17.01,10.09c2.54,0.07,4.77-0.8,6.67-2.59C538.81,561.94,534.05,552.82,516.01,556.63z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M540.25,585.71c-0.12,0-0.24,0-0.37-0.01c-9.27-0.28-18.73-11.21-19.12-11.68l-0.87-1.01l1.3-0.31
					c22.78-5.44,27.09,8.62,27.13,8.77l0.13,0.45l-0.33,0.34C545.91,584.55,543.26,585.71,540.25,585.71z M522.93,573.99
					c2.57,2.7,10.04,9.88,17.01,10.09c2.52,0.07,4.77-0.8,6.66-2.59C545.73,579.29,540.97,570.17,522.93,573.99z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M549.31,604.3c-8.92,0-18.77-8.75-19.2-9.14l-0.99-0.89l1.25-0.48c21.87-8.36,27.99,5.01,28.05,5.15l0.19,0.43
					l-0.28,0.38c-1.97,2.66-4.57,4.17-7.72,4.49C550.16,604.28,549.74,604.3,549.31,604.3z M532.25,594.83
					c2.9,2.34,11.24,8.49,18.18,7.79c2.52-0.25,4.62-1.41,6.27-3.44C555.55,597.11,549.65,588.69,532.25,594.83z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M557.09,620.87c-7.57,0-15.58-3.74-16.01-3.94l-1.21-0.57l1.06-0.81c18.57-14.27,28.25-3.19,28.35-3.08l0.3,0.36
					l-0.16,0.44c-1.13,3.11-3.19,5.3-6.12,6.51C561.4,620.56,559.26,620.87,557.09,620.87z M543.04,616
					c3.45,1.42,13.2,4.92,19.65,2.27c2.34-0.96,4.03-2.67,5.02-5.09C566.03,611.53,557.96,605.15,543.04,616z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M901.46,953.01c-1.53-6.09-3.51-12.35-5.89-18.6c-5.16-13.58-4.97-25.87,0.57-36.53c11-21.18,39.75-28.72,40.04-28.79
					l0.4,1.58c-0.28,0.07-28.34,7.44-39,27.97c-5.31,10.23-5.47,22.07-0.48,35.19c2.4,6.31,4.4,12.63,5.94,18.78L901.46,953.01z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M942.9,873.37c-2.86,0-5.41-1.07-7.61-3.19l-0.34-0.33l0.12-0.46c0.04-0.14,3.89-14.34,26.83-9.65l1.31,0.27l-0.83,1.04
					c-0.38,0.48-9.48,11.71-18.73,12.3C943.4,873.36,943.15,873.37,942.9,873.37z M936.8,869.35c1.96,1.74,4.22,2.54,6.75,2.37
					c6.96-0.44,14.19-7.86,16.67-10.64C942.05,857.85,937.59,867.13,936.8,869.35z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M924.46,874.85l-0.32-0.35c-0.1-0.11-9.83-11.14,6.71-27.71l0.94-0.94l0.41,1.27c0.19,0.58,4.56,14.36-0.05,22.41
					c-1.57,2.75-4.01,4.51-7.24,5.23L924.46,874.85z M931.04,848.94c-12.69,13.39-7.4,22.21-5.99,24.1c2.53-0.68,4.44-2.13,5.7-4.32
					C934.21,862.66,932,852.54,931.04,848.94z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M909.13,883.8l-0.36-0.31c-0.11-0.1-11.03-9.96,3.54-28.3l0.83-1.04l0.55,1.21c0.25,0.56,6.15,13.75,2.49,22.27
					c-1.25,2.91-3.47,4.93-6.61,6.01L909.13,883.8z M912.74,857.32c-10.98,14.6-4.78,22.91-3.22,24.62
					c2.43-0.96,4.17-2.62,5.17-4.94C917.44,870.58,914.1,860.79,912.74,857.32z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M899.17,895.44l-0.46-0.12c-0.14-0.04-14.29-4.09-9.27-26.97l0.28-1.3l1.03,0.85c0.47,0.39,11.58,9.64,12.03,18.9
					c0.16,3.16-0.95,5.96-3.29,8.31L899.17,895.44z M890.75,870.06c-3.48,18.11,5.73,22.7,7.94,23.53c1.76-1.93,2.59-4.19,2.47-6.71
					C900.82,879.92,893.5,872.58,890.75,870.06z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M892.38,905.33c-4.26,0-13.93-2.03-19.08-19.28l-0.38-1.28l1.31,0.24c0.6,0.11,14.8,2.82,19.69,10.7
					c1.67,2.69,2.06,5.67,1.15,8.86l-0.13,0.45l-0.46,0.11C894.45,905.15,893.65,905.33,892.38,905.33z M875.27,886.92
					c5.74,17.53,16.02,17.08,18.35,16.73c0.61-2.54,0.24-4.92-1.09-7.07C888.85,890.65,878.9,887.79,875.27,886.92z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M887.92,922.52c-4.94,0-12.63-2.44-19.68-14.88l-0.66-1.16l1.33-0.06c0.61-0.03,15.05-0.56,21.58,6.03
					c2.23,2.25,3.27,5.07,3.1,8.38l-0.02,0.47l-0.42,0.21C893.1,921.53,891.06,922.52,887.92,922.52z M870.35,908.04
					c9.51,15.8,19.43,13.06,21.63,12.2c0.02-2.61-0.87-4.85-2.65-6.64C884.43,908.65,874.08,908.08,870.35,908.04z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M933.95,888.4c-4.18,0-8.84-0.66-12.23-2.98c-2.61-1.79-4.17-4.36-4.62-7.64l-0.06-0.47l0.37-0.29
					c0.12-0.09,11.9-8.89,27.08,8.94l0.86,1.02l-1.3,0.31C943.72,887.36,939.21,888.4,933.95,888.4z M918.8,878.05
					c0.47,2.57,1.76,4.59,3.85,6.02c5.76,3.94,16.01,2.56,19.68,1.89C930.13,872.37,920.78,876.85,918.8,878.05z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M914.74,897.6c-2.26,0-4.48-0.32-6.46-1.16c-2.92-1.24-4.95-3.45-6.05-6.57l-0.16-0.44l0.31-0.36
					c0.1-0.11,9.9-11.08,28.31,3.39l1.05,0.82l-1.21,0.56C930.11,894.03,922.24,897.6,914.74,897.6z M903.95,889.79
					c0.97,2.43,2.64,4.16,4.97,5.15c6.42,2.72,16.2-0.67,19.67-2.05C913.79,881.87,905.65,888.16,903.95,889.79z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M905.33,915.99c-2.26,0-4.48-0.32-6.46-1.16c-2.92-1.24-4.95-3.45-6.05-6.57l-0.16-0.44l0.31-0.36
					c0.1-0.11,9.9-11.08,28.31,3.39l1.05,0.82l-1.21,0.56C920.7,912.42,912.82,915.99,905.33,915.99z M894.54,908.18
					c0.97,2.43,2.64,4.16,4.97,5.15c6.42,2.72,16.2-0.67,19.67-2.05C904.38,900.26,896.24,906.55,894.54,908.18z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M901.75,933.2c-2.75,0-5.24-1.01-7.41-3.02l-0.35-0.32l0.1-0.46c0.03-0.14,3.56-14.43,26.6-10.28l1.31,0.24l-0.81,1.06
					c-0.37,0.49-9.2,11.93-18.44,12.73C902.42,933.19,902.08,933.2,901.75,933.2z M895.82,929.33c1.99,1.69,4.28,2.43,6.79,2.21
					c6.94-0.6,14-8.19,16.41-11.03C900.97,917.75,896.58,927.14,895.82,929.33z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M654.34,908.39c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C659.76,905.96,657.33,908.39,654.34,908.39z M654.34,899.17c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79C658.13,900.87,656.43,899.17,654.34,899.17z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M637.76,961.22c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C643.18,958.79,640.75,961.22,637.76,961.22z M637.76,952c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
				s3.79-1.7,3.79-3.79C641.55,953.7,639.85,952,637.76,952z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M850.13,864.7c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42S853.12,864.7,850.13,864.7z
				 M850.13,855.49c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79c2.09,0,3.79-1.7,3.79-3.79S852.22,855.49,850.13,855.49z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M954.72,802.84c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C960.14,800.41,957.71,802.84,954.72,802.84z M954.72,793.62c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79C958.51,795.33,956.81,793.62,954.72,793.62z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M757.65,729.71c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C763.08,727.28,760.64,729.71,757.65,729.71z M757.65,720.49c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79C761.45,722.2,759.75,720.49,757.65,720.49z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M702.17,613.43c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				S705.16,613.43,702.17,613.43z M702.17,604.21c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79c2.09,0,3.79-1.7,3.79-3.79
				C705.96,605.91,704.26,604.21,702.17,604.21z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M810.87,530.95c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				S813.86,530.95,810.87,530.95z M810.87,521.73c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79c2.09,0,3.79-1.7,3.79-3.79
				S812.96,521.73,810.87,521.73z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M630.74,544.55c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C636.16,542.12,633.73,544.55,630.74,544.55z M630.74,535.33c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79C634.53,537.04,632.83,535.33,630.74,535.33z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M952.81,962.92c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42S955.8,962.92,952.81,962.92
				z M952.81,953.7c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79s3.79-1.7,3.79-3.79S954.9,953.7,952.81,953.7z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M844.39,965.47c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				S847.38,965.47,844.39,965.47z M844.39,956.25c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79c2.09,0,3.79-1.7,3.79-3.79
				C848.18,957.95,846.48,956.25,844.39,956.25z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M620.24,953.41l-6.88,3.15l-0.3-0.92c-2.45-5.35-8.79-7.71-14.13-5.26s-7.71,8.78-5.26,14.13l0.02,0.05l0.42,0.77
				l-0.78,0.39l-44.17,20.19l-18.21-39.83l0.82-0.3c0.11-0.04,0.22-0.09,0.33-0.14c5.35-2.45,7.71-8.78,5.26-14.13
				c-2.45-5.35-8.78-7.71-14.13-5.26c-0.11,0.05-0.22,0.1-0.32,0.16l-0.77,0.43l-5.48-11.99l12.29-5.62l0.35,0.7
				c2.58,5.15,8.71,7.3,13.96,4.91s7.62-8.44,5.42-13.77l-0.3-0.73l39.36-18l3.15,6.89l-0.74,0.34c-5.36,2.45-7.72,8.79-5.28,14.14
				c2.45,5.35,8.78,7.71,14.13,5.26l0.74-0.34l0.35,0.73L620.24,953.41z M614.25,954.36l3.84-1.75l-19.13-41.85
				c-6.01,2.29-12.85-0.46-15.56-6.38c-2.71-5.92-0.3-12.9,5.35-15.94l-1.81-3.95l-36.47,16.67c2.04,5.95-0.72,12.48-6.53,15.13
				s-12.55,0.47-15.72-4.96l-9.4,4.3l4.1,8.96c6.09-2.56,13.17,0.18,15.93,6.23c2.76,6.05,0.2,13.19-5.72,16.12l16.83,36.81
				l41.95-19.18c-2.37-6.03,0.37-12.95,6.33-15.68C604.21,946.16,611.24,948.62,614.25,954.36z"
                    />
                </g>
                <g>
                    <path
                        className="st0"
                        d="M568.87,947.3c-7.24,3.31-15.82,0.11-19.13-7.13c-3.31-7.24-0.11-15.82,7.13-19.13c7.24-3.31,15.82-0.11,19.13,7.13
				C579.31,935.41,576.11,943.99,568.87,947.3z M557.54,922.52c-6.42,2.94-9.26,10.55-6.32,16.97c2.94,6.42,10.55,9.26,16.97,6.32
				c6.42-2.94,9.26-10.55,6.32-16.97C571.58,922.42,563.97,919.59,557.54,922.52z"
                    />
                </g>
                <g>
                    <path
                        className="st0"
                        d="M566.21,968.76c-2.97,1.36-6.48,0.05-7.84-2.92c-1.36-2.97-0.05-6.49,2.92-7.84s6.49-0.05,7.84,2.92
				C570.49,963.88,569.18,967.4,566.21,968.76z M561.97,959.47c-2.15,0.98-3.1,3.53-2.12,5.68c0.98,2.15,3.53,3.1,5.68,2.12
				c2.15-0.98,3.1-3.53,2.12-5.68C566.67,959.44,564.12,958.49,561.97,959.47z"
                    />
                </g>
                <g>
                    <path
                        className="st0"
                        d="M593.52,935.08c-2.97,1.36-6.48,0.05-7.84-2.92c-1.36-2.97-0.05-6.49,2.92-7.84s6.48-0.05,7.84,2.92
				C597.79,930.21,596.48,933.72,593.52,935.08z M589.27,925.8c-2.15,0.98-3.1,3.53-2.12,5.68c0.98,2.15,3.53,3.1,5.68,2.12
				c2.15-0.98,3.1-3.53,2.12-5.68C593.97,925.77,591.42,924.82,589.27,925.8z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <circle className="st0" cx="1299.67" cy="815.13" r="4.61" />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1306.44,794.68c0.17,0.52,1.79,5.47-1.01,8.07c-2.6,2.41-7.32,1.2-9.79-0.86c-1.52-1.26-1.27-2.08-2.88-4.47
					c-3.59-5.31-7.5-5.22-7.78-7.78c-0.44-3.98,8.47-9.09,10.66-7.35c1.47,1.17-0.79,4.87,1.15,6.63c1.55,1.4,3.66-0.33,6.34,1.15
					C1305.33,791.3,1306.12,793.72,1306.44,794.68z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1289.87,811.1c0.13,1.37-0.3,2.5-1.15,4.75c-1.22,3.2-1.83,4.8-3.02,5.04c-2.15,0.43-5.53-3.7-4.75-6.19
					c0.54-1.74,2.72-1.42,3.89-3.6c1.42-2.65-0.53-5.46,0.29-5.91C1286.02,804.71,1289.53,807.54,1289.87,811.1z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1371.35,796.57c-0.7,0-1.4-0.07-2.1-0.22c-2.68-0.55-4.98-2.12-6.49-4.41l-77.85-118.48
					c-0.04-0.06-0.08-0.12-0.11-0.18c-6-11.09-5.25-24.42,1.96-34.8c0.25-0.36,0.51-0.71,0.77-1.06c0.18-0.24,0.19-0.56,0.03-0.81
					l-36.2-55.09c-0.34-0.52-0.46-1.14-0.34-1.76c0.13-0.61,0.48-1.14,1-1.48l13.98-9.19c0.52-0.34,1.15-0.46,1.76-0.34
					c0.61,0.13,1.14,0.48,1.48,1l36.2,55.09c0.16,0.25,0.46,0.37,0.75,0.3c0.42-0.1,0.85-0.2,1.28-0.28
					c12.38-2.51,24.92,2.09,32.72,12.01c0.05,0.06,0.09,0.12,0.13,0.18l77.85,118.47c3.1,4.72,1.79,11.09-2.94,14.19l-38.28,25.16
					C1375.26,795.99,1373.32,796.57,1371.35,796.57z M1267.29,570.35c-0.14,0-0.27,0.04-0.39,0.12l-13.98,9.19
					c-0.16,0.1-0.27,0.26-0.3,0.45c-0.04,0.18,0,0.37,0.1,0.53l36.2,55.09c0.54,0.82,0.51,1.9-0.09,2.69
					c-0.25,0.33-0.49,0.67-0.73,1.01c-6.86,9.87-7.57,22.54-1.87,33.09c0.01,0.02,0.02,0.04,0.04,0.06l77.86,118.48
					c1.26,1.92,3.2,3.24,5.45,3.71c2.25,0.47,4.55,0.03,6.48-1.24l38.28-25.16c1.92-1.26,3.24-3.2,3.7-5.45
					c0.47-2.25,0.03-4.55-1.24-6.48l-77.86-118.48c-0.01-0.02-0.03-0.04-0.04-0.06c-7.42-9.42-19.34-13.8-31.11-11.42
					c-0.41,0.08-0.81,0.17-1.22,0.27c-0.95,0.23-1.96-0.16-2.5-0.99l-36.2-55.09c-0.1-0.16-0.26-0.26-0.45-0.3
					C1267.38,570.36,1267.34,570.35,1267.29,570.35z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1367.76,791.13c-1.03,0-2.07-0.16-3.09-0.48l0.49-1.55c2.48,0.78,5.15,0.41,7.32-1.02l15.3-10.05l0.9,1.36l-15.3,10.05
					C1371.68,790.55,1369.73,791.13,1367.76,791.13z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1365.21,787.24c-0.7,0-1.4-0.07-2.1-0.22l0.33-1.6c2.26,0.47,4.55,0.03,6.48-1.24l3.89-2.56l0.89,1.36l-3.89,2.56
					C1369.13,786.66,1367.19,787.24,1365.21,787.24z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1333.6,643.48c-5.36-6.84-14.34-10.15-22.87-8.42l-0.32-1.6c9.27-1.88,18.65,1.58,24.48,9.01L1333.6,643.48z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1247.44,582c-0.27,0-0.54-0.03-0.81-0.08c-1.03-0.21-1.92-0.82-2.5-1.7l-4.89-7.45c-1.2-1.82-0.69-4.27,1.13-5.47
					l20.18-13.26c0.88-0.58,1.94-0.78,2.97-0.57c1.03,0.21,1.92,0.82,2.5,1.7l4.89,7.45c1.2,1.82,0.69,4.27-1.13,5.47l-20.18,13.26
					C1248.94,581.78,1248.2,582,1247.44,582z M1262.71,555.02c-0.45,0-0.89,0.13-1.27,0.38l-20.18,13.26
					c-1.07,0.7-1.37,2.14-0.66,3.21l4.89,7.45c0.34,0.52,0.86,0.87,1.47,1c0.61,0.13,1.23,0.01,1.74-0.33l20.18-13.26
					c1.07-0.7,1.37-2.14,0.66-3.21l-4.89-7.45c-0.34-0.52-0.86-0.87-1.47-1C1263.03,555.04,1262.87,555.02,1262.71,555.02z"
                        />
                    </g>
                </g>
                <path
                    className="st0"
                    d="M1315.03,716.34l29.26,44.53c13.36-5.83,33.07-16.53,52.58-34.78l-37.36-56.85c-19.48,18.17-39.15,28.9-52.58,34.78
			l4.04,6.15c5.98-2.93,12.02-6.19,17.97-9.72l0.83,1.4c-5.93,3.51-11.94,6.76-17.9,9.69l2.25,3.42c5.97-2.92,12.02-6.19,17.97-9.72
			c0.1-0.06,10.3-6.16,12.26-7.81l1.05,1.25c-2.06,1.73-12.05,7.71-12.47,7.96C1327,710.17,1320.98,713.42,1315.03,716.34z"
                />
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="1255.94"
                            y="572.14"
                            transform="matrix(0.8357 -0.5492 0.5492 0.8357 -112.7884 791.0941)"
                            width="19.74"
                            height="23.84"
                        />
                    </g>
                    <g>
                        <path
                            className="st0"
                            d="M1263.87,600.57l-13.99-21.29l17.86-11.74l13.99,21.29L1263.87,600.57z M1252.14,579.75l12.2,18.57l15.14-9.95
					l-12.2-18.57L1252.14,579.75z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1103.73,749.58c-1.5-0.45-3.08-0.69-4.73-0.69c-9.07,0-16.42,7.35-16.42,16.42c0,2.49,0.55,4.85,1.55,6.96l-0.92,1.06
					l-18.05,3.26l10.56-16.9c0,0-26.12,2.3-26.69,2.3c-0.58,0,13.06-15.75,13.06-15.75l-19.39-21.32l25.16,6.34v-22.66l20.16,20.36
					l9.03-17.48L1103.73,749.58z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1115.13,852.26c-9.5,0-17.24-7.73-17.24-17.24c0-2.45,0.5-4.81,1.49-7.02l0.24-0.53l0.58,0.05
						c0.45,0.04,0.92,0.06,1.39,0.06c6.15,0,11.75-3.63,14.26-9.25l0.24-0.53l0.58,0.05c8.81,0.77,15.7,8.31,15.7,17.17
						C1132.37,844.53,1124.64,852.26,1115.13,852.26z M1100.66,829.19c-0.75,1.85-1.13,3.81-1.13,5.84c0,8.6,7,15.61,15.61,15.61
						c8.6,0,15.61-7,15.61-15.61c0-7.83-5.95-14.52-13.65-15.48c-2.88,5.89-8.9,9.67-15.49,9.67
						C1101.28,829.21,1100.97,829.2,1100.66,829.19z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1100.06,829.14c-8.81-0.77-15.7-8.31-15.7-17.17c0-9.5,7.73-17.24,17.24-17.24c9.5,0,17.24,7.73,17.24,17.24
						c0,2.45-0.5,4.81-1.49,7.02l-1.49-0.66c0.9-2.01,1.35-4.15,1.35-6.36c0-8.6-7-15.61-15.61-15.61c-8.6,0-15.61,7-15.61,15.61
						c0,8.02,6.24,14.84,14.22,15.54L1100.06,829.14z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1130.98,831.23h-0.08v-1.63h0.08c8.6,0,15.61-7,15.61-15.61c0-3.75-1.35-7.38-3.81-10.22
						c-2.97-3.42-7.27-5.39-11.8-5.39c-0.19,0-0.38,0-0.57,0.01l-0.06-1.63c0.21-0.01,0.42-0.01,0.63-0.01c5,0,9.75,2.17,13.03,5.95
						c2.71,3.13,4.21,7.14,4.21,11.29C1148.22,823.5,1140.48,831.23,1130.98,831.23z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1117.51,808.31l-0.22-1.61c7.67-1.06,13.45-7.7,13.45-15.46c0-8.6-7-15.61-15.61-15.61c-8.6,0-15.61,7-15.61,15.61
						c0,1.46,0.2,2.89,0.59,4.25l-1.57,0.45c-0.43-1.51-0.65-3.09-0.65-4.7c0-9.5,7.73-17.24,17.24-17.24s17.24,7.73,17.24,17.24
						C1132.37,799.8,1125.98,807.14,1117.51,808.31z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1086.68,805.6c-8.19-1.49-14.13-8.62-14.13-16.95c0-9.5,7.73-17.24,17.23-17.24c5.82,0,11.21,2.91,14.41,7.78
						l-1.36,0.9c-2.9-4.41-7.78-7.05-13.05-7.05c-8.6,0-15.6,7-15.6,15.61c0,7.55,5.38,14,12.79,15.35L1086.68,805.6z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1143.48,804.05l-0.17-1.62c7.94-0.84,13.94-7.52,13.94-15.52c0-8.6-7-15.61-15.61-15.61c-6.03,0-11.41,3.37-14.04,8.8
						l-1.47-0.71c2.87-5.9,8.95-9.71,15.51-9.71c9.5,0,17.24,7.73,17.24,17.24C1158.87,795.75,1152.25,803.12,1143.48,804.05z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1140.76,770.98l-1.41-0.81c1.34-2.34,2.05-5.02,2.05-7.74c0-8.6-7-15.61-15.61-15.61c-5.7,0-10.94,3.1-13.69,8.1
						l-1.43-0.78c3.03-5.52,8.82-8.95,15.11-8.95c9.5,0,17.24,7.73,17.24,17.24C1143.03,765.43,1142.25,768.39,1140.76,770.98z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1112.86,775.56l-1.31-0.97c0.41-0.56,0.8-1.16,1.14-1.78c1.25-2.29,1.92-4.88,1.92-7.5c0-3.77-1.36-7.4-3.83-10.24
						c-1.94-2.23-4.46-3.86-7.28-4.7c-1.44-0.44-2.96-0.66-4.5-0.66c-8.6,0-15.61,7-15.61,15.61c0,2.32,0.49,4.54,1.47,6.61
						c0.12,0.27,0.25,0.52,0.38,0.77l-1.43,0.78c-0.15-0.28-0.3-0.57-0.43-0.86c-1.07-2.28-1.62-4.74-1.62-7.3
						c0-9.5,7.73-17.24,17.24-17.24c1.7,0,3.37,0.24,4.97,0.73c3.11,0.93,5.89,2.73,8.04,5.2c2.73,3.14,4.23,7.15,4.23,11.31
						c0,2.89-0.73,5.76-2.12,8.29C1113.74,774.28,1113.32,774.94,1112.86,775.56z"
                            />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1112.69,752.49c-2.1-4.93-2.4-8.9-2.28-11.64c0.22-5.05,1.91-6.82,2.86-14.58c0.43-3.53,0.71-5.81,0.29-8.93
					c-0.39-2.91-1.04-7.73-4.9-11.81c-4.24-4.49-9.68-5.35-11.63-5.51c-3.92-0.32-7.09,0.32-7.09,0.32
					c21.03,0.58,23.33,19.3,19.3,30.82c-4.03,11.52,1.15,22.76,1.15,22.76l1.01,0.6l1.59-2.19"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1111.68,726.28c0,0,9.36-3.02,8.79-12.96c-0.58-9.94-3.17-13.25-3.17-13.25s3.89,3.6,4.61,7.06s2.59,11.52-2.45,15.99
					c-5.04,4.47-7.49,6.34-8.21,9.22L1111.68,726.28z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="1087.27" y="819.18" width="15.15" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="1090.45" y="823.21" width="11.97" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="1084.94" y="810.92" width="17.48" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="1085.62" y="814.95" width="16.8" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="1087.91" y="802.09" width="14.5" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="1085.96" y="806.12" width="16.45" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <rect className="st0" x="1075.55" y="795.56" width="12.22" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="1078.73" y="799.59" width="5.44" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="1073.22" y="787.3" width="17.48" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="1073.9" y="791.33" width="16.8" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="1076.2" y="778.47" width="7.97" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="1074.25" y="782.5" width="13.38" height="1.63" />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <rect className="st0" x="1081.17" y="766.9" width="12.22" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="1078.84" y="758.64" width="17.48" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="1079.52" y="762.67" width="16.8" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="1079.87" y="753.84" width="13.38" height="1.63" />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="1098.72" y="836.8" width="15.99" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="1099.72" y="840.83" width="11.52" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <polygon
                            className="st0"
                            points="1174.22,719.88 1158.64,686.42 1147.23,710.4 1133.38,663.27 1134.95,662.81 1147.59,705.85 1158.66,682.59 
					1174.19,715.96 1185.2,691.45 1215.97,692.29 1197.86,669.72 1217.85,651.89 1176.9,656.37 1186.9,634.44 1136.77,660.04 
					1136.03,658.59 1190.32,630.86 1179.57,654.44 1222.74,649.71 1200.1,669.9 1219.44,694.01 1186.25,693.11 				"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1136.38,659.34l-2.8,1.26l0.58,2.44c-1.7-1.27-4.33-2.9-8.36-4.8c-9.79-4.61-5.57-14.98-5.57-14.98
					s0.24,2.64,1.08,5.45c1.18,3.95,4.07,5.97,4.68,6.46c1.85,1.47,3.54,2.08,5.76,2.88c1.73,0.62,3.31,1.01,4.64,1.26
					L1136.38,659.34z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1079.27,680.14c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
					S1082.26,680.14,1079.27,680.14z M1079.27,670.92c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
					c2.09,0,3.79-1.7,3.79-3.79C1083.07,672.62,1081.36,670.92,1079.27,670.92z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1038.37,797.96c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
					S1041.36,797.96,1038.37,797.96z M1038.37,788.74c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
					c2.09,0,3.79-1.7,3.79-3.79C1042.16,790.44,1040.46,788.74,1038.37,788.74z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1164.54,766.27c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
					S1167.53,766.27,1164.54,766.27z M1164.54,757.05c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79c2.09,0,3.79-1.7,3.79-3.79
					S1166.63,757.05,1164.54,757.05z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1162.24,830.51c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
					S1165.23,830.51,1162.24,830.51z M1162.24,821.29c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79c2.09,0,3.79-1.7,3.79-3.79
					S1164.33,821.29,1162.24,821.29z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <polygon
                            className="st0"
                            points="1217.41,693.91 1139.05,663.37 1133.32,661.36 1133.85,659.83 1139.62,661.84 1218,692.39 				"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <polygon
                            className="st0"
                            points="1170.21,687.64 1167.82,673.51 1180.79,667.5 1181.48,668.98 1169.64,674.47 1171.82,687.37 				"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <polygon
                            className="st0"
                            points="1149.93,679.85 1148.3,679.68 1149.66,666.68 1157.95,659.26 1159.04,660.47 1151.22,667.47 				"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1300.62,832.3l-3.25,13.48c-0.08,0.38-0.16,0.76-0.25,1.14c-3.67,15.22-18.99,24.59-34.21,20.92
					c-15.23-3.67-24.59-18.99-20.92-34.22c0.1-0.4,0.2-0.8,0.32-1.2l3.23-13.4c0,0,8.64,15.52,20.15,15.33
					C1277.18,834.16,1285.43,818.57,1300.62,832.3z"
                        />
                    </g>
                    <g>
                        <path
                            className="st0"
                            d="M1269.55,869.45c-2.26,0-4.55-0.26-6.84-0.82c-7.58-1.83-13.99-6.49-18.05-13.14c-4.07-6.65-5.3-14.48-3.47-22.06
					c0.1-0.42,0.21-0.83,0.33-1.23l3.71-15.4l1.02,1.82c0.08,0.15,8.44,14.91,19.22,14.91c0.06,0,0.13,0,0.19,0
					c3.74-0.06,7.12-1.83,10.69-3.71c7.07-3.71,14.39-7.55,24.81,1.88l0.36,0.32l-3.36,13.95c-0.07,0.35-0.15,0.75-0.25,1.14
					C1294.7,860.46,1282.72,869.45,1269.55,869.45z M1245.86,821.13l-2.77,11.49c-0.12,0.42-0.22,0.81-0.32,1.2
					c-1.72,7.15-0.56,14.55,3.28,20.82c3.84,6.28,9.89,10.68,17.04,12.41c14.76,3.56,29.67-5.56,33.23-20.32
					c0.09-0.37,0.17-0.74,0.24-1.11l3.14-13.03c-9.38-8.25-15.8-4.88-22.6-1.32c-3.59,1.88-7.3,3.83-11.42,3.9
					C1256.06,835.32,1248.54,825.25,1245.86,821.13z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1264.26,824.95c-11.6,0-16.45-14.38-16.5-14.53l-0.07-0.22l6.6-27.39l1.58,0.38l-6.49,26.93
					c0.65,1.78,5.59,14.19,16.17,13.13l0.16,1.62C1265.22,824.92,1264.73,824.95,1264.26,824.95z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1264.82,808.39c-0.1-0.01-10.25-0.71-13.69-12l1.56-0.47c3.09,10.17,11.85,10.82,12.22,10.84L1264.82,808.39z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1249.58,950.65l-1.62-0.14c2.67-30.48,10.63-55.61,15.14-67.87c0.43-1.17,1.59-1.93,2.85-1.84
						c17.88,1.31,33.91-10.41,38.12-27.87c0.12-0.49,0.23-0.98,0.32-1.47l16.13-66.92c0.07-0.31,0.02-0.63-0.14-0.9
						c-0.17-0.27-0.43-0.46-0.74-0.54l-68.64-16.55c-0.31-0.07-0.63-0.02-0.9,0.14c-0.27,0.17-0.46,0.43-0.54,0.74l-16.1,66.79
						c-0.15,0.53-0.29,1.03-0.42,1.58c-4.21,17.45,4.72,35.19,21.23,42.18c1.15,0.49,1.85,1.7,1.7,2.94
						c-1.58,12.97-5.94,38.97-17.45,67.32l-1.51-0.61c11.44-28.18,15.78-54.01,17.34-66.9c0.06-0.53-0.22-1.03-0.72-1.24
						c-17.25-7.3-26.57-25.83-22.17-44.06c0.14-0.57,0.28-1.1,0.43-1.62l16.09-66.75c0.18-0.73,0.63-1.35,1.27-1.74
						c0.64-0.39,1.4-0.51,2.13-0.34l68.64,16.55c0.73,0.18,1.35,0.63,1.74,1.27c0.39,0.64,0.51,1.4,0.34,2.13l-16.13,66.88
						c-0.09,0.48-0.2,0.99-0.33,1.5c-4.4,18.23-21.15,30.48-39.82,29.11c-0.53-0.04-1.02,0.28-1.2,0.77
						C1260.15,895.39,1252.24,920.36,1249.58,950.65z"
                            />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1268.92,968.26c-8.69,0-18.95-1.34-29.48-3.88c-12.57-3.03-24.03-7.41-32.27-12.33c-8.5-5.08-12.62-10.23-11.59-14.49
					c1.96-8.14,22.46-10.17,47.68-4.7l-0.34,1.59c-23.53-5.1-44.06-3.53-45.75,3.49c-0.83,3.46,3.12,8.1,10.84,12.71
					c8.1,4.84,19.4,9.15,31.81,12.15c12.41,2.99,24.44,4.3,33.85,3.69c8.98-0.59,14.61-2.91,15.44-6.37
					c1.68-6.96-15.71-17.67-38.76-23.86l0.42-1.57c11.65,3.13,22.14,7.41,29.52,12.06c7.79,4.9,11.39,9.66,10.4,13.76
					c-1.03,4.27-7.04,6.97-16.92,7.62C1272.22,968.21,1270.6,968.26,1268.92,968.26z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="1211.51"
                            y="925.17"
                            transform="matrix(0.2344 -0.9721 0.9721 0.2344 12.8086 1899.4509)"
                            width="1.63"
                            height="32.84"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="1207.37"
                            y="936.07"
                            transform="matrix(0.2344 -0.9721 0.9721 0.2344 5.1297 1898.9612)"
                            width="1.63"
                            height="20.31"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="1211"
                            y="925.32"
                            transform="matrix(0.2344 -0.9721 0.9721 0.2344 19.1699 1893.6486)"
                            width="1.63"
                            height="18.68"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1416.53,656.1c-0.93,0.73-2.11,1.15-3.27,1.23l-0.57,0.05c-0.17,0.02-0.35,0.03-0.52,0.04c-0.35,0.02-0.7,0.02-1.05,0
						l-4.19-0.39l-2.09-0.22l-0.26-0.03c-0.08-0.01-0.2-0.02-0.22-0.03c-0.1-0.02-0.2-0.03-0.3-0.03c-0.21-0.01-0.42,0.02-0.63,0.08
						c-0.21,0.06-0.42,0.16-0.62,0.29c-0.1,0.07-0.19,0.14-0.28,0.22l-0.13,0.14l-0.17,0.2l-1.34,1.63
						c-0.91,1.08-1.8,2.17-2.73,3.23c0.49-1.32,1.02-2.63,1.53-3.94l0.79-1.96l0.1-0.25c0.04-0.09,0.1-0.2,0.15-0.3
						c0.11-0.2,0.24-0.4,0.38-0.58c0.28-0.38,0.63-0.71,1.03-0.99c0.39-0.28,0.84-0.5,1.31-0.65c0.24-0.07,0.48-0.13,0.73-0.17
						l0.55-0.09l2.08-0.35l4.15-0.66c0.35-0.05,0.69-0.07,1.04-0.07c0.17,0,0.35,0,0.52,0l0.26,0.01l0.13,0l0.06,0l0.02-0.01
						c0.23,0.01,0.41-0.07,0.58-0.19c0.16-0.13,0.29-0.32,0.36-0.53c0.06-0.21,0.05-0.41,0-0.61c0-0.05-0.05-0.1-0.06-0.16
						c0,0-0.02-0.05-0.04-0.08l-0.06-0.12l-0.12-0.24l-0.47-0.93l-0.94-1.86l-1.88-3.72l-3.76-7.45l-0.12-0.23
						c-0.04-0.08-0.07-0.13-0.14-0.29c-0.11-0.27-0.24-0.53-0.31-0.82c-0.09-0.28-0.16-0.57-0.19-0.87
						c-0.05-0.29-0.06-0.59-0.06-0.89c0.01-1.19,0.41-2.35,1.08-3.3c0.67-0.96,1.66-1.71,2.78-2.11c0.56-0.21,1.14-0.31,1.72-0.34
						l1.07-0.04l8.34-0.27l4.17-0.13l2.08-0.06c0.06,0,0.05,0,0.07-0.01l0.04-0.01l0.08,0c0.05-0.03,0.11-0.02,0.15-0.04
						c0.04-0.02,0.1-0.03,0.14-0.06c0.02-0.01,0.05-0.02,0.07-0.03c0.02-0.02,0.04-0.04,0.06-0.05c0.17-0.11,0.31-0.31,0.38-0.51
						c0.08-0.21,0.08-0.41,0.02-0.61c-0.01-0.05-0.03-0.1-0.06-0.15c0.01-0.01-0.11-0.23-0.18-0.37l-0.47-0.93l-0.94-1.86
						l-3.76-7.45l-1.88-3.72l-0.24-0.47l-0.06-0.12l-0.03-0.06l-0.04-0.1l-0.17-0.4c-0.12-0.26-0.18-0.56-0.26-0.84
						c-0.06-0.29-0.1-0.59-0.13-0.88c-0.07-1.19,0.22-2.38,0.82-3.39c0.61-1,1.52-1.83,2.6-2.32c0.55-0.23,1.12-0.4,1.7-0.47
						l0.44-0.04c0.08-0.01,0.12-0.01,0.16-0.01l0.13,0l0.52-0.02l4.17-0.13l1.04-0.03l0.52-0.02l0.12,0l0.04,0
						c0.03,0,0.05-0.01,0.08-0.01c0.21-0.03,0.41-0.13,0.56-0.27c0.04-0.03,0.07-0.08,0.11-0.12l0.05-0.07
						c0.02-0.03,0.01-0.01,0.07-0.11l3.73-6.28c0.35-0.6,0.72-1.19,1.05-1.8c0.17-0.3,0.32-0.62,0.49-0.92
						c0.18-0.3,0.37-0.59,0.58-0.87c0.69-0.88,1.38-1.77,2.07-2.65c0.11-0.15,0.23-0.29,0.34-0.44c0.12-0.15,0.23-0.29,0.35-0.44
						l3.13,1.86c-0.07,0.17-0.14,0.34-0.22,0.51c-0.07,0.17-0.14,0.34-0.22,0.51c-0.44,1.03-0.89,2.06-1.34,3.09
						c-0.15,0.32-0.3,0.63-0.49,0.93c-0.18,0.3-0.38,0.58-0.57,0.87c-0.37,0.59-0.72,1.19-1.07,1.79l-3.73,6.28
						c-0.03,0.05-0.13,0.22-0.21,0.33c-0.08,0.12-0.17,0.24-0.26,0.36c-0.18,0.23-0.38,0.45-0.6,0.65
						c-0.86,0.81-1.97,1.34-3.13,1.52c-0.15,0.02-0.29,0.04-0.44,0.05l-0.22,0.02l-0.14,0.01l-0.52,0.02l-1.04,0.03l-4.17,0.13
						l-0.52,0.02l-0.13,0c-0.04,0-0.09,0-0.09,0.01c-0.03,0-0.05,0.01-0.08,0.01c-0.11,0-0.21,0.04-0.31,0.08
						c-0.18,0.09-0.34,0.23-0.46,0.42c-0.11,0.19-0.16,0.41-0.15,0.61c0.01,0.05,0.01,0.1,0.02,0.15c0.03,0.05,0.02,0.1,0.05,0.15
						c0.01,0.02,0.02,0.05,0.03,0.08l0.01,0.02l0.03,0.05l0.06,0.12l0.23,0.47l1.88,3.73l3.76,7.45l0.94,1.86l0.47,0.93
						c0.08,0.17,0.14,0.25,0.27,0.57c0.12,0.27,0.23,0.54,0.3,0.82c0.32,1.14,0.28,2.39-0.12,3.49c-0.39,1.11-1.11,2.09-2.07,2.78
						l-0.37,0.25c-0.13,0.08-0.26,0.15-0.39,0.21c-0.26,0.15-0.54,0.25-0.82,0.35c-0.28,0.1-0.57,0.15-0.86,0.22
						c-0.14,0.02-0.29,0.04-0.43,0.05l-0.22,0.02c-0.07,0-0.16,0.01-0.19,0.01l-2.09,0.07l-4.17,0.13l-8.35,0.26l-1.02,0.03
						c-0.12,0.02-0.22,0.02-0.32,0.06c-0.18,0.07-0.37,0.18-0.49,0.37c-0.12,0.18-0.21,0.39-0.2,0.6c0,0.05,0,0.1,0.01,0.15
						c0,0.05,0.02,0.1,0.03,0.15c0,0.06,0.05,0.1,0.06,0.16c0,0,0.05,0.11,0.09,0.18l0.12,0.23l3.76,7.45l1.88,3.72l0.94,1.86
						l0.47,0.93l0.18,0.35c0.02,0.04,0.03,0.07,0.07,0.15c0.11,0.27,0.24,0.53,0.32,0.82c0.36,1.12,0.36,2.38,0,3.5
						C1418.15,654.37,1417.46,655.38,1416.53,656.1z"
                            />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1479.04,600.66c-2.05,0-4.07-0.55-5.88-1.62c-5.49-3.29-7.28-10.38-4.04-15.84c3.24-5.46,10.32-7.28,15.79-4.07
					c2.7,1.6,4.58,4.12,5.35,7.11c0.76,2.99,0.31,6.1-1.26,8.76l0,0c-1.58,2.65-4.09,4.54-7.08,5.3
					C1480.96,600.54,1480,600.66,1479.04,600.66z M1479.07,579.18c-3.4,0-6.7,1.74-8.55,4.85c-2.78,4.69-1.24,10.78,3.43,13.58
					c2.32,1.38,4.99,1.76,7.56,1.11c2.57-0.65,4.73-2.27,6.08-4.55l0,0c1.35-2.28,1.74-4.95,1.08-7.52
					c-0.66-2.57-2.27-4.73-4.55-6.08C1482.54,579.62,1480.79,579.18,1479.07,579.18z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1473.12,599.01l-46.79-27.78c-2.65-1.58-4.54-4.09-5.3-7.08c-0.76-2.99-0.32-6.1,1.26-8.75
					c1.58-2.66,4.09-4.54,7.08-5.3c2.99-0.76,6.1-0.31,8.76,1.26l46.79,27.78l-0.83,1.4c-4.7-2.76-10.78-1.19-13.56,3.49
					c-2.78,4.69-1.24,10.78,3.43,13.58L1473.12,599.01z M1432.24,551.36c-0.82,0-1.65,0.1-2.47,0.31c-2.57,0.65-4.73,2.27-6.08,4.55
					c-1.35,2.28-1.74,4.95-1.08,7.52c0.66,2.57,2.27,4.73,4.55,6.08l41.76,24.79c-1.87-3.46-1.94-7.8,0.21-11.42
					c2.15-3.62,5.99-5.64,9.93-5.65l-41.76-24.79C1435.74,551.83,1434,551.36,1432.24,551.36z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1473.11,599c-5.44-3.26-7.23-10.35-3.99-15.81c3.24-5.46,10.32-7.28,15.79-4.07l-0.83,1.4
					c-4.7-2.76-10.78-1.19-13.56,3.49c-2.78,4.69-1.24,10.78,3.43,13.58L1473.11,599z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="1437.78"
                            y="582.16"
                            transform="matrix(0.8599 0.5105 -0.5105 0.8599 502.7142 -655.0796)"
                            width="13.77"
                            height="12.12"
                        />
                    </g>
                    <g>
                        <path
                            className="st0"
                            d="M1447.78,598.06l-13.24-7.86l7.02-11.82l13.24,7.86L1447.78,598.06z M1436.77,589.63l10.44,6.2l5.36-9.02l-10.44-6.2
					L1436.77,589.63z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1091.31,631.23c-47.23-24.74-57.82-87.48-59.47-99.81l1.62-0.22c1.62,12.19,12.09,74.21,58.61,98.58L1091.31,631.23z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1032.89,532.33l-0.43-0.2c-0.13-0.06-13.34-6.55-4.37-28.18l0.51-1.23l0.86,1.02c0.4,0.47,9.69,11.53,8.51,20.73
					c-0.4,3.14-1.99,5.7-4.7,7.59L1032.89,532.33z M1029.08,505.88c-6.62,17.21,1.63,23.36,3.66,24.56
					c2.08-1.59,3.29-3.66,3.62-6.17C1037.25,517.35,1031.35,508.84,1029.08,505.88z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1038.01,542.9c-1.2,0-2.42-0.2-3.67-0.61l-0.45-0.15l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.41l1.29-0.33l-0.29,1.3
					c-0.14,0.6-3.41,14.67-11.48,19.25C1041.57,542.43,1039.83,542.9,1038.01,542.9z M1035.32,540.88c2.52,0.71,4.91,0.43,7.11-0.81
					c6.07-3.44,9.33-13.27,10.34-16.86C1035.03,528.25,1035.07,538.54,1035.32,540.88z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1041.94,558.29c-1.2,0-2.42-0.2-3.67-0.61l-0.45-0.15l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.41l1.29-0.33l-0.29,1.3
					c-0.14,0.6-3.41,14.67-11.48,19.25C1045.51,557.82,1043.76,558.29,1041.94,558.29z M1039.26,556.27
					c2.52,0.71,4.91,0.43,7.11-0.81c6.07-3.44,9.33-13.27,10.34-16.86C1038.96,543.64,1039,553.93,1039.26,556.27z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1047.74,574.77c-1.2,0-2.42-0.2-3.67-0.61l-0.45-0.15l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.41l1.29-0.33l-0.3,1.3
					c-0.14,0.6-3.41,14.67-11.48,19.25C1051.3,574.3,1049.55,574.77,1047.74,574.77z M1045.05,572.75c2.52,0.71,4.91,0.43,7.11-0.81
					c6.07-3.44,9.33-13.27,10.34-16.86C1044.75,560.12,1044.79,570.41,1045.05,572.75z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1055.47,590.99c-1.2,0-2.42-0.2-3.67-0.61l-0.45-0.15l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.4l1.29-0.33l-0.29,1.3
					c-0.14,0.6-3.41,14.67-11.48,19.24C1059.04,590.52,1057.29,590.99,1055.47,590.99z M1052.79,588.97
					c2.52,0.71,4.91,0.44,7.11-0.81c6.07-3.43,9.33-13.26,10.34-16.85C1052.49,576.34,1052.53,586.63,1052.79,588.97z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1064.79,605.66c-1.2,0-2.42-0.2-3.67-0.61l-0.45-0.15l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.4l1.29-0.33l-0.29,1.3
					c-0.14,0.6-3.41,14.67-11.48,19.24C1068.35,605.19,1066.6,605.66,1064.79,605.66z M1062.11,603.65c2.52,0.7,4.9,0.43,7.1-0.81
					c6.07-3.44,9.33-13.26,10.34-16.85C1061.98,590.98,1061.89,601.34,1062.11,603.65z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1027.66,549.06c-0.12,0-0.24,0-0.37-0.01c-9.27-0.28-18.73-11.21-19.12-11.68l-0.87-1.01l1.3-0.31
					c22.78-5.44,27.09,8.63,27.13,8.77l0.13,0.45l-0.33,0.34C1033.32,547.9,1030.67,549.06,1027.66,549.06z M1010.34,537.33
					c2.57,2.7,10.04,9.88,17.01,10.09c2.54,0.08,4.77-0.8,6.67-2.59C1033.14,542.64,1028.39,533.52,1010.34,537.33z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1033.32,568.36c-0.12,0-0.24,0-0.37-0.01c-9.27-0.28-18.73-11.21-19.12-11.68l-0.87-1.01l1.3-0.31
					c22.78-5.44,27.09,8.62,27.13,8.77l0.13,0.45l-0.33,0.34C1038.99,567.2,1036.34,568.36,1033.32,568.36z M1016.01,556.63
					c2.57,2.7,10.04,9.88,17.01,10.09c2.54,0.07,4.77-0.8,6.67-2.59C1038.81,561.94,1034.05,552.82,1016.01,556.63z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1040.25,585.71c-0.12,0-0.24,0-0.37-0.01c-9.27-0.28-18.73-11.21-19.12-11.68l-0.87-1.01l1.3-0.31
					c22.78-5.44,27.09,8.62,27.13,8.77l0.13,0.45l-0.33,0.34C1045.91,584.55,1043.26,585.71,1040.25,585.71z M1022.93,573.99
					c2.57,2.7,10.04,9.88,17.01,10.09c2.52,0.07,4.77-0.8,6.66-2.59C1045.73,579.29,1040.97,570.17,1022.93,573.99z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1049.31,604.3c-8.92,0-18.77-8.75-19.2-9.14l-0.99-0.89l1.25-0.48c21.87-8.36,27.99,5.01,28.05,5.15l0.19,0.43
					l-0.28,0.38c-1.97,2.66-4.57,4.17-7.72,4.49C1050.16,604.28,1049.74,604.3,1049.31,604.3z M1032.25,594.83
					c2.9,2.34,11.24,8.49,18.18,7.79c2.52-0.25,4.62-1.41,6.27-3.44C1055.55,597.11,1049.65,588.69,1032.25,594.83z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1057.09,620.87c-7.57,0-15.58-3.74-16.01-3.94l-1.21-0.57l1.06-0.81c18.57-14.27,28.25-3.19,28.35-3.08l0.3,0.36
					l-0.16,0.44c-1.13,3.11-3.19,5.3-6.12,6.51C1061.4,620.56,1059.26,620.87,1057.09,620.87z M1043.04,616
					c3.45,1.42,13.2,4.92,19.65,2.27c2.34-0.96,4.03-2.67,5.02-5.09C1066.03,611.53,1057.96,605.15,1043.04,616z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1401.46,953.01c-1.53-6.09-3.51-12.35-5.89-18.6c-5.16-13.58-4.97-25.87,0.57-36.53c11-21.18,39.75-28.72,40.04-28.79
					l0.4,1.58c-0.28,0.07-28.34,7.44-39,27.97c-5.31,10.23-5.47,22.07-0.48,35.19c2.4,6.31,4.4,12.63,5.94,18.78L1401.46,953.01z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1442.9,873.37c-2.86,0-5.41-1.07-7.61-3.19l-0.34-0.33l0.12-0.46c0.04-0.14,3.89-14.34,26.83-9.65l1.31,0.27
					l-0.83,1.04c-0.38,0.48-9.48,11.71-18.73,12.3C1443.4,873.36,1443.15,873.37,1442.9,873.37z M1436.8,869.35
					c1.96,1.74,4.22,2.54,6.75,2.37c6.96-0.44,14.19-7.86,16.67-10.64C1442.05,857.85,1437.59,867.13,1436.8,869.35z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1424.46,874.85l-0.32-0.35c-0.1-0.11-9.83-11.14,6.71-27.71l0.94-0.94l0.41,1.27c0.19,0.58,4.56,14.36-0.05,22.41
					c-1.57,2.75-4.01,4.51-7.24,5.23L1424.46,874.85z M1431.04,848.94c-12.69,13.39-7.4,22.21-5.99,24.1
					c2.53-0.68,4.44-2.13,5.7-4.32C1434.21,862.66,1432,852.54,1431.04,848.94z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1409.13,883.8l-0.36-0.31c-0.11-0.1-11.03-9.96,3.54-28.3l0.83-1.04l0.55,1.21c0.25,0.56,6.15,13.75,2.48,22.27
					c-1.25,2.91-3.47,4.93-6.61,6.01L1409.13,883.8z M1412.74,857.32c-10.98,14.6-4.78,22.91-3.22,24.62
					c2.43-0.96,4.17-2.62,5.17-4.94C1417.44,870.58,1414.1,860.79,1412.74,857.32z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1399.17,895.44l-0.46-0.12c-0.14-0.04-14.29-4.09-9.27-26.97l0.28-1.3l1.03,0.85c0.47,0.39,11.58,9.64,12.03,18.9
					c0.16,3.16-0.95,5.96-3.29,8.31L1399.17,895.44z M1390.75,870.06c-3.48,18.11,5.73,22.7,7.94,23.53
					c1.76-1.93,2.59-4.19,2.47-6.71C1400.82,879.92,1393.5,872.58,1390.75,870.06z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1392.38,905.33c-4.26,0-13.93-2.03-19.08-19.28l-0.38-1.28l1.31,0.24c0.6,0.11,14.8,2.82,19.69,10.7
					c1.67,2.69,2.06,5.67,1.15,8.86l-0.13,0.45l-0.46,0.11C1394.45,905.15,1393.65,905.33,1392.38,905.33z M1375.27,886.92
					c5.74,17.53,16.02,17.08,18.35,16.73c0.61-2.54,0.24-4.92-1.09-7.07C1388.85,890.65,1378.9,887.79,1375.27,886.92z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1387.92,922.52c-4.94,0-12.63-2.44-19.68-14.88l-0.66-1.16l1.33-0.06c0.61-0.03,15.05-0.56,21.58,6.03
					c2.23,2.25,3.27,5.07,3.1,8.38l-0.02,0.47l-0.42,0.21C1393.1,921.53,1391.06,922.52,1387.92,922.52z M1370.35,908.04
					c9.51,15.8,19.43,13.06,21.63,12.2c0.02-2.61-0.87-4.85-2.65-6.64C1384.43,908.65,1374.08,908.08,1370.35,908.04z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1433.95,888.4c-4.18,0-8.84-0.66-12.23-2.98c-2.61-1.79-4.17-4.36-4.62-7.64l-0.06-0.47l0.37-0.29
					c0.12-0.09,11.9-8.89,27.08,8.94l0.86,1.02l-1.3,0.31C1443.72,887.36,1439.21,888.4,1433.95,888.4z M1418.8,878.05
					c0.47,2.57,1.76,4.59,3.85,6.02c5.76,3.94,16.01,2.56,19.68,1.89C1430.13,872.37,1420.78,876.85,1418.8,878.05z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1414.74,897.6c-2.26,0-4.48-0.32-6.46-1.16c-2.92-1.24-4.95-3.45-6.05-6.57l-0.16-0.44l0.31-0.36
					c0.1-0.11,9.9-11.08,28.31,3.39l1.05,0.82l-1.21,0.56C1430.11,894.03,1422.24,897.6,1414.74,897.6z M1403.95,889.79
					c0.97,2.43,2.64,4.16,4.97,5.15c6.42,2.72,16.2-0.67,19.67-2.05C1413.79,881.87,1405.65,888.16,1403.95,889.79z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1405.33,915.99c-2.26,0-4.48-0.32-6.46-1.16c-2.92-1.24-4.95-3.45-6.05-6.57l-0.16-0.44l0.31-0.36
					c0.1-0.11,9.9-11.08,28.31,3.39l1.05,0.82l-1.21,0.56C1420.7,912.42,1412.82,915.99,1405.33,915.99z M1394.54,908.18
					c0.97,2.43,2.64,4.16,4.97,5.15c6.42,2.72,16.2-0.67,19.67-2.05C1404.38,900.26,1396.24,906.55,1394.54,908.18z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1401.75,933.2c-2.75,0-5.24-1.01-7.41-3.02l-0.35-0.32l0.1-0.46c0.03-0.14,3.56-14.43,26.6-10.28l1.31,0.24l-0.81,1.06
					c-0.37,0.49-9.2,11.93-18.44,12.73C1402.42,933.19,1402.08,933.2,1401.75,933.2z M1395.82,929.33c1.99,1.69,4.28,2.43,6.79,2.21
					c6.94-0.6,14-8.19,16.41-11.03C1400.97,917.75,1396.58,927.14,1395.82,929.33z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1154.34,908.39c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C1159.76,905.96,1157.33,908.39,1154.34,908.39z M1154.34,899.17c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79C1158.13,900.87,1156.43,899.17,1154.34,899.17z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1137.76,961.22c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42s5.42,2.43,5.42,5.42
				C1143.18,958.79,1140.75,961.22,1137.76,961.22z M1137.76,952c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79C1141.55,953.7,1139.85,952,1137.76,952z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1350.13,864.7c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42s5.42,2.43,5.42,5.42S1353.12,864.7,1350.13,864.7z
				 M1350.13,855.49c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79s3.79-1.7,3.79-3.79S1352.22,855.49,1350.13,855.49z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1454.72,802.84c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C1460.14,800.41,1457.71,802.84,1454.72,802.84z M1454.72,793.62c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79C1458.51,795.33,1456.81,793.62,1454.72,793.62z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1257.65,729.71c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C1263.08,727.28,1260.64,729.71,1257.65,729.71z M1257.65,720.49c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79C1261.45,722.2,1259.75,720.49,1257.65,720.49z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1202.17,613.43c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				S1205.16,613.43,1202.17,613.43z M1202.17,604.21c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79C1205.96,605.91,1204.26,604.21,1202.17,604.21z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1310.87,530.95c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42s5.42,2.43,5.42,5.42S1313.86,530.95,1310.87,530.95z
				 M1310.87,521.73c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79s3.79-1.7,3.79-3.79S1312.96,521.73,1310.87,521.73z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1130.74,544.55c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C1136.16,542.12,1133.73,544.55,1130.74,544.55z M1130.74,535.33c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79C1134.53,537.04,1132.83,535.33,1130.74,535.33z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1452.81,962.92c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42s5.42,2.43,5.42,5.42S1455.8,962.92,1452.81,962.92z
				 M1452.81,953.7c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79c2.09,0,3.79-1.7,3.79-3.79S1454.9,953.7,1452.81,953.7z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1344.39,965.47c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				S1347.38,965.47,1344.39,965.47z M1344.39,956.25c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79C1348.18,957.95,1346.48,956.25,1344.39,956.25z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1120.24,953.41l-6.88,3.15l-0.3-0.92c-2.44-5.35-8.79-7.71-14.13-5.26c-5.35,2.45-7.71,8.78-5.26,14.13l0.02,0.05
				l0.42,0.77l-0.78,0.39l-44.17,20.19l-18.21-39.83l0.82-0.3c0.11-0.04,0.22-0.09,0.33-0.14c5.35-2.45,7.71-8.78,5.26-14.13
				c-2.44-5.35-8.78-7.71-14.13-5.26c-0.11,0.05-0.22,0.1-0.32,0.16l-0.77,0.43l-5.48-11.99l12.29-5.62l0.35,0.7
				c2.58,5.15,8.71,7.3,13.96,4.91s7.62-8.44,5.42-13.77l-0.3-0.73l39.36-18l3.15,6.89l-0.74,0.34c-5.36,2.45-7.72,8.79-5.28,14.14
				c2.44,5.35,8.78,7.71,14.13,5.26l0.74-0.34l0.35,0.73L1120.24,953.41z M1114.25,954.36l3.84-1.75l-19.13-41.85
				c-6.01,2.29-12.85-0.46-15.56-6.38c-2.71-5.92-0.3-12.9,5.35-15.94l-1.81-3.95l-36.47,16.67c2.04,5.95-0.72,12.48-6.53,15.13
				s-12.55,0.47-15.72-4.96l-9.4,4.3l4.1,8.96c6.09-2.56,13.17,0.18,15.93,6.23c2.76,6.05,0.2,13.19-5.72,16.12l16.83,36.81
				l41.95-19.18c-2.37-6.03,0.37-12.95,6.33-15.68C1104.21,946.16,1111.24,948.62,1114.25,954.36z"
                    />
                </g>
                <g>
                    <path
                        className="st0"
                        d="M1068.87,947.3c-7.24,3.31-15.82,0.11-19.13-7.13c-3.31-7.24-0.11-15.82,7.13-19.13c7.24-3.31,15.82-0.11,19.13,7.13
				C1079.31,935.41,1076.11,943.99,1068.87,947.3z M1057.54,922.52c-6.42,2.94-9.26,10.55-6.32,16.97s10.55,9.26,16.97,6.32
				c6.42-2.94,9.26-10.55,6.32-16.97S1063.97,919.59,1057.54,922.52z"
                    />
                </g>
                <g>
                    <path
                        className="st0"
                        d="M1066.21,968.76c-2.97,1.36-6.48,0.05-7.84-2.92c-1.36-2.97-0.05-6.49,2.92-7.84s6.49-0.05,7.84,2.92
				C1070.49,963.88,1069.18,967.4,1066.21,968.76z M1061.97,959.47c-2.15,0.98-3.1,3.53-2.12,5.68c0.98,2.15,3.53,3.1,5.68,2.12
				c2.15-0.98,3.1-3.53,2.12-5.68C1066.67,959.44,1064.12,958.49,1061.97,959.47z"
                    />
                </g>
                <g>
                    <path
                        className="st0"
                        d="M1093.52,935.08c-2.97,1.36-6.49,0.05-7.84-2.92c-1.36-2.97-0.05-6.49,2.92-7.84s6.48-0.05,7.84,2.92
				C1097.79,930.21,1096.48,933.72,1093.52,935.08z M1089.27,925.8c-2.15,0.98-3.1,3.53-2.12,5.68c0.98,2.15,3.53,3.1,5.68,2.12
				c2.15-0.98,3.1-3.53,2.12-5.68C1093.97,925.77,1091.42,924.82,1089.27,925.8z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <circle className="st0" cx="1799.67" cy="815.13" r="4.61" />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1806.44,794.68c0.17,0.52,1.79,5.47-1.01,8.07c-2.6,2.41-7.32,1.2-9.79-0.86c-1.51-1.26-1.27-2.08-2.88-4.47
					c-3.59-5.31-7.5-5.22-7.78-7.78c-0.44-3.98,8.47-9.09,10.66-7.35c1.47,1.17-0.79,4.87,1.15,6.63c1.55,1.4,3.66-0.33,6.34,1.15
					C1805.33,791.3,1806.12,793.72,1806.44,794.68z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1789.87,811.1c0.13,1.37-0.3,2.5-1.15,4.75c-1.22,3.2-1.83,4.8-3.02,5.04c-2.15,0.43-5.53-3.7-4.75-6.19
					c0.54-1.74,2.72-1.42,3.89-3.6c1.42-2.65-0.53-5.46,0.29-5.91C1786.02,804.71,1789.53,807.54,1789.87,811.1z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1871.35,796.57c-0.7,0-1.4-0.07-2.1-0.22c-2.68-0.55-4.98-2.12-6.49-4.41l-77.85-118.48
					c-0.04-0.06-0.08-0.12-0.11-0.18c-6-11.09-5.25-24.42,1.96-34.8c0.25-0.36,0.51-0.71,0.77-1.06c0.18-0.24,0.19-0.56,0.03-0.81
					l-36.2-55.09c-0.34-0.52-0.46-1.14-0.34-1.76c0.13-0.61,0.48-1.14,1-1.48l13.98-9.19c0.52-0.34,1.15-0.46,1.76-0.34
					c0.61,0.13,1.14,0.48,1.48,1l36.2,55.09c0.16,0.25,0.46,0.37,0.75,0.3c0.42-0.1,0.85-0.2,1.28-0.28
					c12.38-2.51,24.92,2.09,32.72,12.01c0.05,0.06,0.09,0.12,0.13,0.18l77.85,118.47c3.1,4.72,1.79,11.09-2.94,14.19l-38.28,25.16
					C1875.26,795.99,1873.32,796.57,1871.35,796.57z M1767.29,570.35c-0.14,0-0.27,0.04-0.39,0.12l-13.98,9.19
					c-0.16,0.1-0.27,0.26-0.3,0.45c-0.04,0.18,0,0.37,0.1,0.53l36.2,55.09c0.54,0.82,0.51,1.9-0.09,2.69
					c-0.25,0.33-0.49,0.67-0.73,1.01c-6.86,9.87-7.57,22.54-1.87,33.09c0.01,0.02,0.02,0.04,0.04,0.06l77.86,118.48
					c1.26,1.92,3.2,3.24,5.45,3.71c2.25,0.47,4.55,0.03,6.48-1.24l38.28-25.16c1.92-1.26,3.24-3.2,3.7-5.45
					c0.47-2.25,0.03-4.55-1.24-6.48l-77.86-118.48c-0.01-0.02-0.03-0.04-0.04-0.06c-7.42-9.42-19.34-13.8-31.11-11.42
					c-0.41,0.08-0.81,0.17-1.22,0.27c-0.95,0.23-1.96-0.16-2.5-0.99l-36.2-55.09c-0.1-0.16-0.26-0.26-0.45-0.3
					C1767.38,570.36,1767.34,570.35,1767.29,570.35z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1867.76,791.13c-1.03,0-2.07-0.16-3.09-0.48l0.49-1.55c2.48,0.78,5.15,0.41,7.32-1.02l15.3-10.05l0.9,1.36l-15.3,10.05
					C1871.68,790.55,1869.73,791.13,1867.76,791.13z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1865.21,787.24c-0.7,0-1.4-0.07-2.1-0.22l0.33-1.6c2.26,0.47,4.55,0.03,6.48-1.24l3.89-2.56l0.89,1.36l-3.89,2.56
					C1869.13,786.66,1867.19,787.24,1865.21,787.24z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1833.6,643.48c-5.36-6.84-14.34-10.15-22.87-8.42l-0.32-1.6c9.27-1.88,18.65,1.58,24.48,9.01L1833.6,643.48z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1747.44,582c-0.27,0-0.54-0.03-0.81-0.08c-1.03-0.21-1.92-0.82-2.5-1.7l-4.89-7.45c-1.2-1.82-0.69-4.27,1.13-5.47
					l20.18-13.26c0.88-0.58,1.94-0.78,2.97-0.57c1.03,0.21,1.92,0.82,2.5,1.7l4.89,7.45c1.2,1.82,0.69,4.27-1.13,5.47l-20.18,13.26
					C1748.94,581.78,1748.2,582,1747.44,582z M1762.71,555.02c-0.45,0-0.89,0.13-1.27,0.38l-20.18,13.26
					c-1.07,0.7-1.37,2.14-0.66,3.21l4.89,7.45c0.34,0.52,0.86,0.87,1.47,1c0.61,0.13,1.23,0.01,1.74-0.33l20.18-13.26
					c1.07-0.7,1.37-2.14,0.66-3.21l-4.89-7.45c-0.34-0.52-0.86-0.87-1.47-1C1763.03,555.04,1762.87,555.02,1762.71,555.02z"
                        />
                    </g>
                </g>
                <path
                    className="st0"
                    d="M1815.03,716.34l29.26,44.53c13.36-5.83,33.07-16.53,52.58-34.78l-37.36-56.85c-19.48,18.17-39.15,28.9-52.58,34.78
			l4.04,6.15c5.98-2.93,12.02-6.19,17.97-9.72l0.83,1.4c-5.93,3.51-11.94,6.76-17.9,9.69l2.25,3.42c5.97-2.92,12.02-6.19,17.97-9.72
			c0.1-0.06,10.3-6.16,12.26-7.81l1.05,1.25c-2.06,1.73-12.05,7.71-12.47,7.96C1827,710.17,1820.98,713.42,1815.03,716.34z"
                />
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="1755.94"
                            y="572.14"
                            transform="matrix(0.8357 -0.5492 0.5492 0.8357 -30.6449 1065.6772)"
                            width="19.74"
                            height="23.84"
                        />
                    </g>
                    <g>
                        <path
                            className="st0"
                            d="M1763.87,600.57l-13.99-21.29l17.86-11.74l13.99,21.29L1763.87,600.57z M1752.14,579.75l12.2,18.57l15.14-9.95
					l-12.2-18.57L1752.14,579.75z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1603.73,749.58c-1.5-0.45-3.08-0.69-4.73-0.69c-9.07,0-16.42,7.35-16.42,16.42c0,2.49,0.55,4.85,1.55,6.96l-0.92,1.06
					l-18.05,3.26l10.56-16.9c0,0-26.12,2.3-26.69,2.3c-0.58,0,13.06-15.75,13.06-15.75l-19.39-21.32l25.16,6.34v-22.66l20.16,20.36
					l9.03-17.48L1603.73,749.58z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1615.13,852.26c-9.5,0-17.24-7.73-17.24-17.24c0-2.45,0.5-4.81,1.49-7.02l0.24-0.53l0.58,0.05
						c0.45,0.04,0.92,0.06,1.39,0.06c6.15,0,11.75-3.63,14.26-9.25l0.24-0.53l0.58,0.05c8.81,0.77,15.7,8.31,15.7,17.17
						C1632.37,844.53,1624.64,852.26,1615.13,852.26z M1600.66,829.19c-0.75,1.85-1.13,3.81-1.13,5.84c0,8.6,7,15.61,15.61,15.61
						s15.61-7,15.61-15.61c0-7.83-5.95-14.52-13.65-15.48c-2.88,5.89-8.9,9.67-15.49,9.67
						C1601.28,829.21,1600.97,829.2,1600.66,829.19z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1600.06,829.14c-8.81-0.77-15.7-8.31-15.7-17.17c0-9.5,7.73-17.24,17.24-17.24s17.24,7.73,17.24,17.24
						c0,2.45-0.5,4.81-1.49,7.02l-1.49-0.66c0.9-2.01,1.35-4.15,1.35-6.36c0-8.6-7-15.61-15.61-15.61s-15.61,7-15.61,15.61
						c0,8.02,6.24,14.84,14.22,15.54L1600.06,829.14z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1630.98,831.23h-0.08v-1.63h0.08c8.6,0,15.61-7,15.61-15.61c0-3.75-1.35-7.38-3.81-10.22
						c-2.97-3.42-7.27-5.39-11.8-5.39c-0.19,0-0.38,0-0.57,0.01l-0.06-1.63c0.21-0.01,0.42-0.01,0.63-0.01c5,0,9.75,2.17,13.03,5.95
						c2.71,3.13,4.21,7.14,4.21,11.29C1648.22,823.5,1640.48,831.23,1630.98,831.23z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1617.51,808.31l-0.22-1.61c7.67-1.06,13.45-7.7,13.45-15.46c0-8.6-7-15.61-15.61-15.61s-15.61,7-15.61,15.61
						c0,1.46,0.2,2.89,0.59,4.25l-1.57,0.45c-0.43-1.51-0.65-3.09-0.65-4.7c0-9.5,7.73-17.24,17.24-17.24s17.24,7.73,17.24,17.24
						C1632.37,799.8,1625.98,807.14,1617.51,808.31z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1586.68,805.6c-8.19-1.49-14.13-8.62-14.13-16.95c0-9.5,7.73-17.24,17.23-17.24c5.82,0,11.21,2.91,14.41,7.78
						l-1.36,0.9c-2.9-4.41-7.78-7.05-13.05-7.05c-8.6,0-15.6,7-15.6,15.61c0,7.55,5.38,14,12.79,15.35L1586.68,805.6z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1643.48,804.05l-0.17-1.62c7.94-0.84,13.94-7.52,13.94-15.52c0-8.6-7-15.61-15.61-15.61c-6.03,0-11.41,3.37-14.04,8.8
						l-1.47-0.71c2.87-5.9,8.95-9.71,15.51-9.71c9.5,0,17.24,7.73,17.24,17.24C1658.87,795.75,1652.25,803.12,1643.48,804.05z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1640.76,770.98l-1.41-0.81c1.34-2.34,2.05-5.02,2.05-7.74c0-8.6-7-15.61-15.61-15.61c-5.7,0-10.94,3.1-13.69,8.1
						l-1.43-0.78c3.03-5.52,8.82-8.95,15.11-8.95c9.5,0,17.24,7.73,17.24,17.24C1643.03,765.43,1642.25,768.39,1640.76,770.98z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1612.86,775.56l-1.31-0.97c0.41-0.56,0.8-1.16,1.14-1.78c1.25-2.29,1.92-4.88,1.92-7.5c0-3.77-1.36-7.4-3.83-10.24
						c-1.94-2.23-4.46-3.86-7.28-4.7c-1.44-0.44-2.96-0.66-4.5-0.66c-8.6,0-15.61,7-15.61,15.61c0,2.32,0.49,4.54,1.47,6.61
						c0.12,0.27,0.25,0.52,0.38,0.77l-1.43,0.78c-0.15-0.28-0.3-0.57-0.43-0.86c-1.07-2.28-1.62-4.74-1.62-7.3
						c0-9.5,7.73-17.24,17.24-17.24c1.7,0,3.37,0.24,4.97,0.73c3.11,0.93,5.89,2.73,8.04,5.2c2.73,3.14,4.23,7.15,4.23,11.31
						c0,2.89-0.73,5.76-2.12,8.29C1613.74,774.28,1613.32,774.94,1612.86,775.56z"
                            />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1612.69,752.49c-2.1-4.93-2.4-8.9-2.28-11.64c0.22-5.05,1.91-6.82,2.86-14.58c0.43-3.53,0.71-5.81,0.29-8.93
					c-0.39-2.91-1.04-7.73-4.9-11.81c-4.24-4.49-9.68-5.35-11.63-5.51c-3.93-0.32-7.09,0.32-7.09,0.32
					c21.03,0.58,23.33,19.3,19.3,30.82c-4.03,11.52,1.15,22.76,1.15,22.76l1.01,0.6l1.59-2.19"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1611.68,726.28c0,0,9.36-3.02,8.79-12.96s-3.17-13.25-3.17-13.25s3.89,3.6,4.61,7.06c0.72,3.46,2.59,11.52-2.45,15.99
					c-5.04,4.47-7.49,6.34-8.21,9.22L1611.68,726.28z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="1587.27" y="819.18" width="15.15" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="1590.45" y="823.21" width="11.97" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="1584.94" y="810.92" width="17.48" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="1585.62" y="814.95" width="16.8" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="1587.91" y="802.09" width="14.5" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="1585.96" y="806.12" width="16.45" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <rect className="st0" x="1575.55" y="795.56" width="12.22" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="1578.73" y="799.59" width="5.44" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="1573.22" y="787.3" width="17.48" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="1573.9" y="791.33" width="16.8" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="1576.2" y="778.47" width="7.97" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="1574.25" y="782.5" width="13.38" height="1.63" />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <rect className="st0" x="1581.17" y="766.9" width="12.22" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="1578.84" y="758.64" width="17.48" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="1579.52" y="762.67" width="16.8" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="1579.87" y="753.84" width="13.38" height="1.63" />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="1598.72" y="836.8" width="15.99" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="1599.72" y="840.83" width="11.52" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <polygon
                            className="st0"
                            points="1674.22,719.88 1658.64,686.42 1647.23,710.4 1633.38,663.27 1634.95,662.81 1647.59,705.85 1658.66,682.59 
					1674.19,715.96 1685.2,691.45 1715.97,692.29 1697.86,669.72 1717.85,651.89 1676.9,656.37 1686.9,634.44 1636.77,660.04 
					1636.03,658.59 1690.32,630.86 1679.57,654.44 1722.74,649.71 1700.1,669.9 1719.44,694.01 1686.25,693.11 				"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1636.38,659.34l-2.8,1.26l0.58,2.44c-1.7-1.27-4.33-2.9-8.36-4.8c-9.79-4.61-5.57-14.98-5.57-14.98
					s0.24,2.64,1.08,5.45c1.18,3.95,4.07,5.97,4.68,6.46c1.85,1.47,3.54,2.08,5.76,2.88c1.73,0.62,3.31,1.01,4.64,1.26
					L1636.38,659.34z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1579.27,680.14c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
					S1582.26,680.14,1579.27,680.14z M1579.27,670.92c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
					c2.09,0,3.79-1.7,3.79-3.79C1583.07,672.62,1581.36,670.92,1579.27,670.92z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1538.37,797.96c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
					S1541.36,797.96,1538.37,797.96z M1538.37,788.74c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
					c2.09,0,3.79-1.7,3.79-3.79C1542.16,790.44,1540.46,788.74,1538.37,788.74z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1664.54,766.27c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42s5.42,2.43,5.42,5.42S1667.53,766.27,1664.54,766.27z
					 M1664.54,757.05c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79c2.09,0,3.79-1.7,3.79-3.79S1666.63,757.05,1664.54,757.05z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1662.24,830.51c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
					S1665.23,830.51,1662.24,830.51z M1662.24,821.29c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79c2.09,0,3.79-1.7,3.79-3.79
					S1664.33,821.29,1662.24,821.29z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <polygon
                            className="st0"
                            points="1717.41,693.91 1639.05,663.37 1633.32,661.36 1633.85,659.83 1639.61,661.84 1718,692.39 				"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <polygon
                            className="st0"
                            points="1670.21,687.64 1667.82,673.51 1680.79,667.5 1681.48,668.98 1669.64,674.47 1671.82,687.37 				"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <polygon
                            className="st0"
                            points="1649.93,679.85 1648.3,679.68 1649.66,666.68 1657.95,659.26 1659.04,660.47 1651.22,667.47 				"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1800.62,832.3l-3.25,13.48c-0.08,0.38-0.16,0.76-0.25,1.14c-3.67,15.22-18.99,24.59-34.21,20.92
					c-15.23-3.67-24.59-18.99-20.92-34.22c0.1-0.4,0.2-0.8,0.32-1.2l3.23-13.4c0,0,8.64,15.52,20.14,15.33
					C1777.18,834.16,1785.43,818.57,1800.62,832.3z"
                        />
                    </g>
                    <g>
                        <path
                            className="st0"
                            d="M1769.55,869.45c-2.26,0-4.55-0.26-6.84-0.82c-7.58-1.83-13.99-6.49-18.05-13.14c-4.07-6.65-5.3-14.48-3.47-22.06
					c0.1-0.42,0.21-0.83,0.33-1.23l3.71-15.4l1.02,1.82c0.08,0.15,8.44,14.91,19.22,14.91c0.06,0,0.13,0,0.19,0
					c3.74-0.06,7.12-1.83,10.69-3.71c7.07-3.71,14.39-7.55,24.81,1.88l0.36,0.32l-3.36,13.95c-0.07,0.35-0.15,0.75-0.25,1.14
					C1794.7,860.46,1782.72,869.45,1769.55,869.45z M1745.86,821.13l-2.77,11.49c-0.12,0.42-0.22,0.81-0.32,1.2
					c-1.72,7.15-0.56,14.55,3.28,20.82c3.84,6.28,9.89,10.68,17.04,12.41c14.76,3.56,29.67-5.56,33.23-20.32
					c0.09-0.37,0.17-0.74,0.24-1.11l3.14-13.03c-9.38-8.25-15.8-4.88-22.6-1.32c-3.59,1.88-7.3,3.83-11.42,3.9
					C1756.06,835.32,1748.54,825.25,1745.86,821.13z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1764.26,824.95c-11.6,0-16.45-14.38-16.5-14.53l-0.07-0.22l6.6-27.39l1.58,0.38l-6.49,26.93
					c0.65,1.78,5.59,14.19,16.17,13.13l0.16,1.62C1765.22,824.92,1764.73,824.95,1764.26,824.95z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1764.82,808.39c-0.1-0.01-10.25-0.71-13.69-12l1.56-0.47c3.09,10.17,11.85,10.82,12.22,10.84L1764.82,808.39z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1749.58,950.65l-1.62-0.14c2.67-30.48,10.63-55.61,15.14-67.87c0.43-1.17,1.59-1.93,2.85-1.84
						c17.88,1.31,33.91-10.41,38.12-27.87c0.12-0.49,0.23-0.98,0.32-1.47l16.13-66.92c0.07-0.31,0.02-0.63-0.14-0.9
						c-0.17-0.27-0.43-0.46-0.74-0.54l-68.64-16.55c-0.31-0.07-0.63-0.02-0.9,0.14c-0.27,0.17-0.46,0.43-0.54,0.74l-16.1,66.79
						c-0.15,0.53-0.29,1.03-0.42,1.58c-4.21,17.45,4.72,35.19,21.23,42.18c1.15,0.49,1.85,1.7,1.7,2.94
						c-1.58,12.97-5.94,38.97-17.45,67.32l-1.51-0.61c11.44-28.18,15.78-54.01,17.34-66.9c0.06-0.53-0.22-1.03-0.72-1.24
						c-17.25-7.3-26.57-25.83-22.17-44.06c0.14-0.57,0.28-1.1,0.43-1.62l16.09-66.75c0.18-0.73,0.63-1.35,1.27-1.74
						c0.64-0.39,1.4-0.51,2.13-0.34l68.64,16.55c0.73,0.18,1.35,0.63,1.74,1.27c0.39,0.64,0.51,1.4,0.34,2.13l-16.13,66.88
						c-0.09,0.48-0.2,0.99-0.33,1.5c-4.4,18.23-21.15,30.48-39.82,29.11c-0.53-0.04-1.02,0.28-1.2,0.77
						C1760.15,895.39,1752.24,920.36,1749.58,950.65z"
                            />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1768.92,968.26c-8.69,0-18.95-1.34-29.48-3.88c-12.57-3.03-24.03-7.41-32.27-12.33c-8.5-5.08-12.62-10.23-11.59-14.49
					c1.96-8.14,22.46-10.17,47.68-4.7l-0.34,1.59c-23.53-5.1-44.06-3.53-45.75,3.49c-0.84,3.46,3.12,8.1,10.84,12.71
					c8.1,4.84,19.4,9.15,31.81,12.15c12.41,2.99,24.44,4.3,33.85,3.69c8.98-0.59,14.61-2.91,15.44-6.37
					c1.68-6.96-15.71-17.67-38.76-23.86l0.42-1.57c11.65,3.13,22.14,7.41,29.52,12.06c7.79,4.9,11.39,9.66,10.4,13.76
					c-1.03,4.27-7.04,6.97-16.92,7.62C1772.22,968.21,1770.6,968.26,1768.92,968.26z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="1711.51"
                            y="925.17"
                            transform="matrix(0.2344 -0.9721 0.9721 0.2344 395.6141 2385.5225)"
                            width="1.63"
                            height="32.84"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="1707.37"
                            y="936.07"
                            transform="matrix(0.2344 -0.9721 0.9721 0.2344 387.9341 2385.0322)"
                            width="1.63"
                            height="20.31"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="1711"
                            y="925.32"
                            transform="matrix(0.2344 -0.9721 0.9721 0.2344 401.9801 2379.7212)"
                            width="1.63"
                            height="18.68"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1916.53,656.1c-0.93,0.73-2.11,1.15-3.27,1.23l-0.57,0.05c-0.17,0.02-0.35,0.03-0.52,0.04c-0.35,0.02-0.7,0.02-1.05,0
						l-4.19-0.39l-2.09-0.22l-0.26-0.03c-0.08-0.01-0.2-0.02-0.22-0.03c-0.1-0.02-0.2-0.03-0.3-0.03c-0.21-0.01-0.42,0.02-0.63,0.08
						c-0.21,0.06-0.42,0.16-0.62,0.29c-0.1,0.07-0.19,0.14-0.28,0.22l-0.13,0.14l-0.17,0.2l-1.34,1.63
						c-0.91,1.08-1.8,2.17-2.73,3.23c0.49-1.32,1.02-2.63,1.53-3.94l0.79-1.96l0.1-0.25c0.04-0.09,0.1-0.2,0.15-0.3
						c0.11-0.2,0.24-0.4,0.38-0.58c0.28-0.38,0.63-0.71,1.03-0.99c0.39-0.28,0.84-0.5,1.31-0.65c0.24-0.07,0.48-0.13,0.73-0.17
						l0.55-0.09l2.08-0.35l4.15-0.66c0.35-0.05,0.69-0.07,1.04-0.07c0.17,0,0.35,0,0.52,0l0.26,0.01l0.13,0l0.06,0l0.02-0.01
						c0.23,0.01,0.41-0.07,0.58-0.19c0.16-0.13,0.29-0.32,0.36-0.53c0.06-0.21,0.05-0.41,0-0.61c0-0.05-0.05-0.1-0.06-0.16
						c0,0-0.02-0.05-0.04-0.08l-0.06-0.12l-0.12-0.24l-0.47-0.93l-0.94-1.86l-1.88-3.72l-3.76-7.45l-0.12-0.23
						c-0.04-0.08-0.07-0.13-0.14-0.29c-0.11-0.27-0.24-0.53-0.31-0.82c-0.09-0.28-0.16-0.57-0.19-0.87
						c-0.05-0.29-0.06-0.59-0.06-0.89c0.01-1.19,0.41-2.35,1.08-3.3c0.67-0.96,1.66-1.71,2.78-2.11c0.56-0.21,1.14-0.31,1.72-0.34
						l1.07-0.04l8.34-0.27l4.17-0.13l2.08-0.06c0.06,0,0.05,0,0.07-0.01l0.04-0.01l0.08,0c0.05-0.03,0.11-0.02,0.15-0.04
						c0.04-0.02,0.1-0.03,0.14-0.06c0.02-0.01,0.05-0.02,0.07-0.03c0.02-0.02,0.04-0.04,0.06-0.05c0.17-0.11,0.31-0.31,0.38-0.51
						c0.08-0.21,0.08-0.41,0.02-0.61c-0.01-0.05-0.03-0.1-0.06-0.15c0.01-0.01-0.11-0.23-0.18-0.37l-0.47-0.93l-0.94-1.86
						l-3.76-7.45l-1.88-3.72l-0.24-0.47l-0.06-0.12l-0.03-0.06l-0.04-0.1l-0.17-0.4c-0.12-0.26-0.18-0.56-0.26-0.84
						c-0.06-0.29-0.1-0.59-0.13-0.88c-0.07-1.19,0.22-2.38,0.82-3.39c0.61-1,1.52-1.83,2.6-2.32c0.55-0.23,1.12-0.4,1.7-0.47
						l0.44-0.04c0.08-0.01,0.12-0.01,0.16-0.01l0.13,0l0.52-0.02l4.17-0.13l1.04-0.03l0.52-0.02l0.12,0l0.04,0
						c0.03,0,0.05-0.01,0.08-0.01c0.21-0.03,0.41-0.13,0.56-0.27c0.04-0.03,0.07-0.08,0.11-0.12l0.05-0.07
						c0.02-0.03,0.01-0.01,0.07-0.11l3.73-6.28c0.35-0.6,0.72-1.19,1.05-1.8c0.17-0.3,0.32-0.62,0.49-0.92
						c0.18-0.3,0.37-0.59,0.58-0.87c0.69-0.88,1.38-1.77,2.07-2.65c0.11-0.15,0.23-0.29,0.34-0.44c0.12-0.15,0.23-0.29,0.35-0.44
						l3.13,1.86c-0.07,0.17-0.14,0.34-0.22,0.51c-0.07,0.17-0.14,0.34-0.22,0.51c-0.44,1.03-0.89,2.06-1.34,3.09
						c-0.15,0.32-0.3,0.63-0.49,0.93c-0.18,0.3-0.38,0.58-0.57,0.87c-0.37,0.59-0.72,1.19-1.07,1.79l-3.73,6.28
						c-0.03,0.05-0.13,0.22-0.21,0.33c-0.08,0.12-0.17,0.24-0.26,0.36c-0.18,0.23-0.38,0.45-0.6,0.65
						c-0.86,0.81-1.97,1.34-3.13,1.52c-0.15,0.02-0.29,0.04-0.44,0.05l-0.22,0.02l-0.14,0.01l-0.52,0.02l-1.04,0.03l-4.17,0.13
						l-0.52,0.02l-0.13,0c-0.04,0-0.09,0-0.09,0.01c-0.03,0-0.05,0.01-0.08,0.01c-0.11,0-0.21,0.04-0.31,0.08
						c-0.18,0.09-0.34,0.23-0.46,0.42c-0.11,0.19-0.16,0.41-0.15,0.61c0.01,0.05,0.01,0.1,0.02,0.15c0.03,0.05,0.02,0.1,0.05,0.15
						c0.01,0.02,0.02,0.05,0.03,0.08l0.01,0.02l0.03,0.05l0.06,0.12l0.23,0.47l1.88,3.73l3.76,7.45l0.94,1.86l0.47,0.93
						c0.08,0.17,0.14,0.25,0.27,0.57c0.12,0.27,0.23,0.54,0.3,0.82c0.32,1.14,0.28,2.39-0.12,3.49c-0.39,1.11-1.11,2.09-2.07,2.78
						l-0.37,0.25c-0.13,0.08-0.26,0.15-0.39,0.21c-0.26,0.15-0.54,0.25-0.82,0.35s-0.57,0.15-0.86,0.22
						c-0.14,0.02-0.29,0.04-0.43,0.05l-0.22,0.02c-0.07,0-0.16,0.01-0.19,0.01l-2.09,0.07l-4.17,0.13l-8.35,0.26l-1.02,0.03
						c-0.12,0.02-0.22,0.02-0.32,0.06c-0.18,0.07-0.37,0.18-0.49,0.37c-0.12,0.18-0.21,0.39-0.2,0.6c0,0.05,0,0.1,0.01,0.15
						c0,0.05,0.02,0.1,0.03,0.15c0,0.06,0.05,0.1,0.06,0.16c0,0,0.05,0.11,0.09,0.18l0.12,0.23l3.76,7.45l1.88,3.72l0.94,1.86
						l0.47,0.93l0.18,0.35c0.02,0.04,0.03,0.07,0.07,0.15c0.11,0.27,0.24,0.53,0.32,0.82c0.36,1.12,0.36,2.38,0,3.5
						C1918.15,654.37,1917.46,655.38,1916.53,656.1z"
                            />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1979.04,600.66c-2.05,0-4.07-0.55-5.88-1.62c-5.49-3.29-7.28-10.38-4.04-15.84c3.24-5.46,10.32-7.28,15.79-4.07
					c2.7,1.6,4.58,4.12,5.35,7.11c0.76,2.99,0.31,6.1-1.26,8.76l0,0c-1.58,2.65-4.09,4.54-7.08,5.3
					C1980.96,600.54,1980,600.66,1979.04,600.66z M1979.07,579.18c-3.4,0-6.7,1.74-8.55,4.85c-2.78,4.69-1.24,10.78,3.43,13.58
					c2.32,1.38,4.99,1.76,7.56,1.11c2.57-0.65,4.73-2.27,6.08-4.55l0,0c1.35-2.28,1.74-4.95,1.08-7.52
					c-0.66-2.57-2.27-4.73-4.55-6.08C1982.54,579.62,1980.79,579.18,1979.07,579.18z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1973.12,599.01l-46.79-27.78c-2.65-1.58-4.54-4.09-5.3-7.08c-0.76-2.99-0.32-6.1,1.26-8.75
					c1.58-2.66,4.09-4.54,7.08-5.3c2.99-0.76,6.1-0.31,8.76,1.26l46.79,27.78l-0.83,1.4c-4.7-2.76-10.78-1.19-13.56,3.49
					c-2.78,4.69-1.24,10.78,3.43,13.58L1973.12,599.01z M1932.24,551.36c-0.82,0-1.65,0.1-2.47,0.31c-2.57,0.65-4.73,2.27-6.08,4.55
					c-1.35,2.28-1.74,4.95-1.08,7.52c0.66,2.57,2.27,4.73,4.55,6.08l41.76,24.79c-1.87-3.46-1.94-7.8,0.21-11.42
					c2.15-3.62,5.99-5.64,9.93-5.65l-41.76-24.79C1935.74,551.83,1934,551.36,1932.24,551.36z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1973.11,599c-5.44-3.26-7.23-10.35-3.99-15.81c3.24-5.46,10.32-7.28,15.79-4.07l-0.83,1.4
					c-4.7-2.76-10.78-1.19-13.56,3.49c-2.78,4.69-1.24,10.78,3.43,13.58L1973.11,599z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="1937.78"
                            y="582.16"
                            transform="matrix(0.8599 0.5105 -0.5105 0.8599 572.7755 -910.3297)"
                            width="13.77"
                            height="12.12"
                        />
                    </g>
                    <g>
                        <path
                            className="st0"
                            d="M1947.78,598.06l-13.24-7.86l7.02-11.82l13.24,7.86L1947.78,598.06z M1936.77,589.63l10.44,6.2l5.36-9.02l-10.44-6.2
					L1936.77,589.63z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1591.31,631.23c-47.23-24.74-57.82-87.48-59.47-99.81l1.62-0.22c1.62,12.19,12.09,74.21,58.61,98.58L1591.31,631.23z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1532.89,532.33l-0.43-0.2c-0.13-0.06-13.34-6.55-4.37-28.18l0.51-1.23l0.86,1.02c0.4,0.47,9.69,11.53,8.51,20.73
					c-0.4,3.14-1.99,5.7-4.7,7.59L1532.89,532.33z M1529.08,505.88c-6.62,17.21,1.63,23.36,3.66,24.56
					c2.08-1.59,3.29-3.66,3.62-6.17C1537.25,517.35,1531.35,508.84,1529.08,505.88z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1538.01,542.9c-1.2,0-2.42-0.2-3.67-0.61l-0.45-0.15l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.41l1.29-0.33l-0.29,1.3
					c-0.14,0.6-3.41,14.67-11.48,19.25C1541.57,542.43,1539.83,542.9,1538.01,542.9z M1535.32,540.88c2.52,0.71,4.91,0.43,7.11-0.81
					c6.07-3.44,9.33-13.27,10.34-16.86C1535.03,528.25,1535.07,538.54,1535.32,540.88z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1541.94,558.29c-1.2,0-2.42-0.2-3.67-0.61l-0.45-0.15l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.41l1.29-0.33l-0.29,1.3
					c-0.14,0.6-3.41,14.67-11.48,19.25C1545.51,557.82,1543.76,558.29,1541.94,558.29z M1539.26,556.27
					c2.52,0.71,4.91,0.43,7.11-0.81c6.07-3.44,9.33-13.27,10.34-16.86C1538.96,543.64,1539,553.93,1539.26,556.27z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1547.74,574.77c-1.2,0-2.42-0.2-3.67-0.61l-0.45-0.15l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.41l1.29-0.33l-0.29,1.3
					c-0.14,0.6-3.41,14.67-11.48,19.25C1551.3,574.3,1549.55,574.77,1547.74,574.77z M1545.05,572.75c2.52,0.71,4.91,0.43,7.11-0.81
					c6.07-3.44,9.33-13.27,10.34-16.86C1544.75,560.12,1544.79,570.41,1545.05,572.75z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1555.47,590.99c-1.2,0-2.42-0.2-3.67-0.61l-0.45-0.15l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.4l1.29-0.33l-0.29,1.3
					c-0.14,0.6-3.41,14.67-11.48,19.24C1559.04,590.52,1557.29,590.99,1555.47,590.99z M1552.79,588.97
					c2.52,0.71,4.91,0.44,7.11-0.81c6.07-3.43,9.33-13.26,10.34-16.85C1552.49,576.34,1552.53,586.63,1552.79,588.97z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1564.79,605.66c-1.2,0-2.42-0.2-3.67-0.61l-0.45-0.15l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.4l1.29-0.33l-0.3,1.3
					c-0.14,0.6-3.41,14.67-11.48,19.24C1568.35,605.19,1566.6,605.66,1564.79,605.66z M1562.11,603.65c2.52,0.7,4.9,0.43,7.1-0.81
					c6.07-3.44,9.33-13.26,10.34-16.85C1561.98,590.98,1561.89,601.34,1562.11,603.65z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1527.66,549.06c-0.12,0-0.24,0-0.37-0.01c-9.27-0.28-18.73-11.21-19.12-11.68l-0.87-1.01l1.3-0.31
					c22.78-5.44,27.09,8.63,27.13,8.77l0.13,0.45l-0.33,0.34C1533.32,547.9,1530.67,549.06,1527.66,549.06z M1510.34,537.33
					c2.57,2.7,10.04,9.88,17.01,10.09c2.54,0.08,4.77-0.8,6.67-2.59C1533.14,542.64,1528.39,533.52,1510.34,537.33z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1533.32,568.36c-0.12,0-0.24,0-0.37-0.01c-9.27-0.28-18.73-11.21-19.12-11.68l-0.87-1.01l1.3-0.31
					c22.78-5.44,27.09,8.62,27.13,8.77l0.13,0.45l-0.33,0.34C1538.99,567.2,1536.34,568.36,1533.32,568.36z M1516.01,556.63
					c2.57,2.7,10.04,9.88,17.01,10.09c2.54,0.07,4.77-0.8,6.67-2.59C1538.81,561.94,1534.05,552.82,1516.01,556.63z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1540.25,585.71c-0.12,0-0.24,0-0.36-0.01c-9.27-0.28-18.73-11.21-19.12-11.68l-0.87-1.01l1.3-0.31
					c22.78-5.44,27.09,8.62,27.13,8.77l0.13,0.45l-0.33,0.34C1545.91,584.55,1543.26,585.71,1540.25,585.71z M1522.93,573.99
					c2.57,2.7,10.04,9.88,17.01,10.09c2.52,0.07,4.77-0.8,6.66-2.59C1545.73,579.29,1540.97,570.17,1522.93,573.99z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1549.31,604.3c-8.92,0-18.77-8.75-19.2-9.14l-0.99-0.89l1.25-0.48c21.87-8.36,27.99,5.01,28.05,5.15l0.19,0.43
					l-0.28,0.38c-1.97,2.66-4.57,4.17-7.72,4.49C1550.16,604.28,1549.74,604.3,1549.31,604.3z M1532.25,594.83
					c2.9,2.34,11.24,8.49,18.18,7.79c2.52-0.25,4.62-1.41,6.27-3.44C1555.55,597.11,1549.65,588.69,1532.25,594.83z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1557.09,620.87c-7.57,0-15.58-3.74-16.01-3.94l-1.21-0.57l1.06-0.81c18.57-14.27,28.25-3.19,28.35-3.08l0.3,0.36
					l-0.16,0.44c-1.13,3.11-3.19,5.3-6.12,6.51C1561.4,620.56,1559.26,620.87,1557.09,620.87z M1543.04,616
					c3.45,1.42,13.2,4.92,19.65,2.27c2.34-0.96,4.03-2.67,5.02-5.09C1566.03,611.53,1557.96,605.15,1543.04,616z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1901.45,953.01c-1.53-6.09-3.51-12.35-5.89-18.6c-5.16-13.58-4.97-25.87,0.56-36.53c11-21.18,39.75-28.72,40.04-28.79
					l0.4,1.58c-0.28,0.07-28.34,7.44-39,27.97c-5.31,10.23-5.47,22.07-0.48,35.19c2.4,6.31,4.4,12.63,5.94,18.78L1901.45,953.01z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1942.9,873.37c-2.86,0-5.41-1.07-7.61-3.19l-0.34-0.33l0.12-0.46c0.04-0.14,3.89-14.34,26.83-9.65l1.31,0.27
					l-0.83,1.04c-0.38,0.48-9.48,11.71-18.73,12.3C1943.4,873.36,1943.15,873.37,1942.9,873.37z M1936.8,869.35
					c1.96,1.74,4.22,2.54,6.75,2.37c6.96-0.44,14.19-7.86,16.67-10.64C1942.05,857.85,1937.59,867.13,1936.8,869.35z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1924.46,874.85l-0.32-0.35c-0.1-0.11-9.83-11.14,6.71-27.71l0.94-0.94l0.41,1.27c0.19,0.58,4.56,14.36-0.05,22.41
					c-1.57,2.75-4.01,4.51-7.24,5.23L1924.46,874.85z M1931.04,848.94c-12.69,13.39-7.4,22.21-5.99,24.1
					c2.53-0.68,4.44-2.13,5.7-4.32C1934.21,862.66,1932,852.54,1931.04,848.94z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1909.13,883.8l-0.36-0.31c-0.11-0.1-11.03-9.96,3.54-28.3l0.83-1.04l0.55,1.21c0.25,0.56,6.15,13.75,2.48,22.27
					c-1.25,2.91-3.47,4.93-6.61,6.01L1909.13,883.8z M1912.74,857.32c-10.98,14.6-4.78,22.91-3.22,24.62
					c2.43-0.96,4.17-2.62,5.17-4.94C1917.44,870.58,1914.1,860.79,1912.74,857.32z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1899.17,895.44l-0.46-0.12c-0.14-0.04-14.29-4.09-9.27-26.97l0.28-1.3l1.03,0.85c0.47,0.39,11.58,9.64,12.03,18.9
					c0.16,3.16-0.95,5.96-3.29,8.31L1899.17,895.44z M1890.75,870.06c-3.48,18.11,5.73,22.7,7.94,23.53
					c1.76-1.93,2.59-4.19,2.47-6.71C1900.82,879.92,1893.5,872.58,1890.75,870.06z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1892.38,905.33c-4.26,0-13.93-2.03-19.08-19.28l-0.38-1.28l1.31,0.24c0.6,0.11,14.8,2.82,19.69,10.7
					c1.67,2.69,2.06,5.67,1.15,8.86l-0.13,0.45l-0.46,0.11C1894.44,905.15,1893.65,905.33,1892.38,905.33z M1875.27,886.92
					c5.74,17.53,16.02,17.08,18.35,16.73c0.61-2.54,0.24-4.92-1.09-7.07C1888.85,890.65,1878.9,887.79,1875.27,886.92z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1887.92,922.52c-4.94,0-12.63-2.44-19.68-14.88l-0.66-1.16l1.33-0.06c0.61-0.03,15.05-0.56,21.58,6.03
					c2.23,2.25,3.27,5.07,3.1,8.38l-0.02,0.47l-0.42,0.21C1893.1,921.53,1891.06,922.52,1887.92,922.52z M1870.35,908.04
					c9.51,15.8,19.43,13.06,21.63,12.2c0.02-2.61-0.87-4.85-2.65-6.64C1884.43,908.65,1874.08,908.08,1870.35,908.04z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1933.95,888.4c-4.18,0-8.84-0.66-12.23-2.98c-2.61-1.79-4.17-4.36-4.62-7.64l-0.06-0.47l0.37-0.29
					c0.12-0.09,11.9-8.89,27.08,8.94l0.86,1.02l-1.3,0.31C1943.72,887.36,1939.21,888.4,1933.95,888.4z M1918.8,878.05
					c0.47,2.57,1.76,4.59,3.85,6.02c5.76,3.94,16.01,2.56,19.68,1.89C1930.13,872.37,1920.78,876.85,1918.8,878.05z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1914.74,897.6c-2.26,0-4.48-0.32-6.46-1.16c-2.92-1.24-4.95-3.45-6.05-6.57l-0.16-0.44l0.31-0.36
					c0.1-0.11,9.9-11.08,28.31,3.39l1.05,0.82l-1.21,0.56C1930.11,894.03,1922.24,897.6,1914.74,897.6z M1903.95,889.79
					c0.97,2.43,2.64,4.16,4.97,5.15c6.42,2.72,16.2-0.67,19.67-2.05C1913.79,881.87,1905.65,888.16,1903.95,889.79z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1905.33,915.99c-2.26,0-4.48-0.32-6.46-1.16c-2.92-1.24-4.95-3.45-6.05-6.57l-0.16-0.44l0.31-0.36
					c0.1-0.11,9.9-11.08,28.31,3.39l1.05,0.82l-1.21,0.56C1920.7,912.42,1912.82,915.99,1905.33,915.99z M1894.54,908.18
					c0.97,2.43,2.64,4.16,4.97,5.15c6.42,2.72,16.2-0.67,19.67-2.05C1904.38,900.26,1896.24,906.55,1894.54,908.18z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1901.75,933.2c-2.75,0-5.24-1.01-7.41-3.02l-0.35-0.32l0.1-0.46c0.03-0.14,3.56-14.43,26.6-10.28l1.31,0.24l-0.81,1.06
					c-0.37,0.49-9.2,11.93-18.44,12.73C1902.42,933.19,1902.08,933.2,1901.75,933.2z M1895.82,929.33c1.99,1.69,4.28,2.43,6.79,2.21
					c6.94-0.6,14-8.19,16.41-11.03C1900.97,917.75,1896.58,927.14,1895.82,929.33z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1654.34,908.39c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C1659.76,905.96,1657.33,908.39,1654.34,908.39z M1654.34,899.17c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79C1658.13,900.87,1656.43,899.17,1654.34,899.17z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1637.76,961.22c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C1643.18,958.79,1640.75,961.22,1637.76,961.22z M1637.76,952c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79C1641.55,953.7,1639.85,952,1637.76,952z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1850.13,864.7c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				S1853.12,864.7,1850.13,864.7z M1850.13,855.49c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79c2.09,0,3.79-1.7,3.79-3.79
				S1852.22,855.49,1850.13,855.49z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1954.72,802.84c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C1960.14,800.41,1957.71,802.84,1954.72,802.84z M1954.72,793.62c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79C1958.51,795.33,1956.81,793.62,1954.72,793.62z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1757.65,729.71c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C1763.08,727.28,1760.64,729.71,1757.65,729.71z M1757.65,720.49c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79C1761.45,722.2,1759.75,720.49,1757.65,720.49z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1702.17,613.43c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				S1705.16,613.43,1702.17,613.43z M1702.17,604.21c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79C1705.96,605.91,1704.26,604.21,1702.17,604.21z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1810.87,530.95c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42s5.42,2.43,5.42,5.42S1813.86,530.95,1810.87,530.95z
				 M1810.87,521.73c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79c2.09,0,3.79-1.7,3.79-3.79S1812.96,521.73,1810.87,521.73z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1630.74,544.55c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C1636.16,542.12,1633.73,544.55,1630.74,544.55z M1630.74,535.33c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79C1634.53,537.04,1632.83,535.33,1630.74,535.33z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1952.81,962.92c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				S1955.8,962.92,1952.81,962.92z M1952.81,953.7c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79c2.09,0,3.79-1.7,3.79-3.79
				S1954.9,953.7,1952.81,953.7z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1844.39,965.47c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				S1847.38,965.47,1844.39,965.47z M1844.39,956.25c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79C1848.18,957.95,1846.48,956.25,1844.39,956.25z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1620.24,953.41l-6.88,3.15l-0.3-0.92c-2.45-5.35-8.79-7.71-14.13-5.26s-7.71,8.78-5.26,14.13l0.02,0.05l0.42,0.77
				l-0.78,0.39l-44.17,20.19l-18.21-39.83l0.82-0.3c0.11-0.04,0.22-0.09,0.33-0.14c5.35-2.45,7.71-8.78,5.26-14.13
				c-2.45-5.35-8.78-7.71-14.13-5.26c-0.11,0.05-0.22,0.1-0.32,0.16l-0.77,0.43l-5.48-11.99l12.29-5.62l0.35,0.7
				c2.58,5.15,8.71,7.3,13.96,4.91s7.62-8.44,5.42-13.77l-0.3-0.73l39.36-18l3.15,6.89l-0.74,0.34c-5.36,2.45-7.72,8.79-5.28,14.14
				c2.45,5.35,8.78,7.71,14.13,5.26l0.74-0.34l0.35,0.73L1620.24,953.41z M1614.25,954.36l3.84-1.75l-19.13-41.85
				c-6.01,2.29-12.85-0.46-15.56-6.38c-2.71-5.92-0.3-12.9,5.35-15.94l-1.81-3.95l-36.47,16.67c2.04,5.95-0.72,12.48-6.53,15.13
				s-12.55,0.47-15.72-4.96l-9.4,4.3l4.1,8.96c6.09-2.56,13.17,0.18,15.93,6.23c2.76,6.05,0.2,13.19-5.72,16.12l16.83,36.81
				l41.95-19.18c-2.37-6.03,0.37-12.95,6.33-15.68C1604.21,946.16,1611.24,948.62,1614.25,954.36z"
                    />
                </g>
                <g>
                    <path
                        className="st0"
                        d="M1568.87,947.3c-7.24,3.31-15.82,0.11-19.13-7.13c-3.31-7.24-0.11-15.82,7.13-19.13c7.24-3.31,15.82-0.11,19.13,7.13
				C1579.31,935.41,1576.11,943.99,1568.87,947.3z M1557.54,922.52c-6.42,2.94-9.26,10.55-6.32,16.97
				c2.94,6.42,10.55,9.26,16.97,6.32s9.26-10.55,6.32-16.97S1563.97,919.59,1557.54,922.52z"
                    />
                </g>
                <g>
                    <path
                        className="st0"
                        d="M1566.21,968.76c-2.97,1.36-6.48,0.05-7.84-2.92c-1.36-2.97-0.05-6.49,2.92-7.84s6.48-0.05,7.84,2.92
				C1570.49,963.88,1569.18,967.4,1566.21,968.76z M1561.97,959.47c-2.15,0.98-3.1,3.53-2.12,5.68c0.98,2.15,3.53,3.1,5.68,2.12
				c2.15-0.98,3.1-3.53,2.12-5.68C1566.67,959.44,1564.12,958.49,1561.97,959.47z"
                    />
                </g>
                <g>
                    <path
                        className="st0"
                        d="M1593.52,935.08c-2.97,1.36-6.48,0.05-7.84-2.92c-1.36-2.97-0.05-6.49,2.92-7.84s6.48-0.05,7.84,2.92
				C1597.79,930.21,1596.48,933.72,1593.52,935.08z M1589.27,925.8c-2.15,0.98-3.1,3.53-2.12,5.68c0.98,2.15,3.53,3.1,5.68,2.12
				c2.15-0.98,3.1-3.53,2.12-5.68C1593.97,925.77,1591.42,924.82,1589.27,925.8z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <circle className="st0" cx="299.67" cy="1315.13" r="4.61" />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M306.44,1294.68c0.17,0.52,1.79,5.47-1.01,8.07c-2.6,2.41-7.32,1.2-9.79-0.86c-1.51-1.26-1.27-2.08-2.88-4.47
					c-3.59-5.31-7.5-5.22-7.78-7.78c-0.44-3.98,8.47-9.09,10.66-7.35c1.47,1.17-0.79,4.87,1.15,6.63c1.55,1.4,3.66-0.33,6.34,1.15
					C305.33,1291.3,306.12,1293.72,306.44,1294.68z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M289.87,1311.1c0.13,1.37-0.3,2.5-1.15,4.75c-1.22,3.2-1.83,4.8-3.02,5.04c-2.15,0.43-5.53-3.7-4.75-6.19
					c0.54-1.74,2.72-1.42,3.89-3.6c1.42-2.65-0.53-5.46,0.29-5.91C286.02,1304.71,289.53,1307.54,289.87,1311.1z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M371.35,1296.57c-0.7,0-1.4-0.07-2.1-0.22c-2.68-0.55-4.98-2.12-6.49-4.41l-77.85-118.48
					c-0.04-0.06-0.08-0.12-0.11-0.18c-6-11.09-5.25-24.42,1.96-34.8c0.25-0.36,0.51-0.71,0.77-1.06c0.18-0.24,0.19-0.56,0.03-0.81
					l-36.2-55.09c-0.34-0.52-0.46-1.14-0.34-1.76c0.13-0.61,0.48-1.14,1-1.48l13.98-9.19c0.52-0.34,1.15-0.46,1.76-0.34
					c0.61,0.13,1.14,0.48,1.48,1l36.2,55.09c0.16,0.25,0.46,0.37,0.75,0.3c0.42-0.1,0.85-0.2,1.28-0.28
					c12.38-2.51,24.92,2.09,32.72,12.01c0.05,0.06,0.09,0.12,0.13,0.18l77.85,118.47c3.1,4.72,1.79,11.09-2.94,14.19l-38.28,25.16
					C375.26,1295.99,373.32,1296.57,371.35,1296.57z M267.29,1070.35c-0.14,0-0.27,0.04-0.39,0.12l-13.98,9.19
					c-0.16,0.1-0.27,0.26-0.3,0.45c-0.04,0.18,0,0.37,0.1,0.53l36.2,55.09c0.54,0.82,0.51,1.9-0.09,2.69
					c-0.25,0.33-0.49,0.67-0.73,1.01c-6.86,9.87-7.57,22.55-1.87,33.09c0.01,0.02,0.02,0.04,0.04,0.06l77.86,118.48
					c1.26,1.92,3.2,3.24,5.45,3.71c2.25,0.47,4.55,0.03,6.48-1.24l38.28-25.16c1.92-1.26,3.24-3.2,3.7-5.45
					c0.47-2.25,0.03-4.55-1.24-6.48l-77.86-118.48c-0.01-0.02-0.03-0.04-0.04-0.06c-7.42-9.42-19.34-13.8-31.11-11.42
					c-0.41,0.08-0.81,0.17-1.22,0.27c-0.95,0.23-1.96-0.16-2.5-0.99l-36.2-55.09c-0.1-0.16-0.26-0.27-0.45-0.3
					C267.38,1070.36,267.34,1070.35,267.29,1070.35z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M367.76,1291.13c-1.03,0-2.07-0.16-3.09-0.48l0.49-1.56c2.48,0.78,5.15,0.41,7.32-1.02l15.3-10.05l0.9,1.36l-15.3,10.05
					C371.68,1290.55,369.73,1291.13,367.76,1291.13z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M365.21,1287.24c-0.7,0-1.4-0.07-2.1-0.22l0.33-1.6c2.26,0.47,4.55,0.03,6.48-1.24l3.89-2.56l0.89,1.36l-3.89,2.56
					C369.13,1286.66,367.19,1287.24,365.21,1287.24z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M333.6,1143.48c-5.36-6.84-14.34-10.15-22.87-8.42l-0.32-1.6c9.27-1.88,18.65,1.58,24.48,9.01L333.6,1143.48z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M247.44,1082c-0.27,0-0.54-0.03-0.81-0.08c-1.03-0.21-1.92-0.82-2.5-1.7l-4.89-7.45c-1.2-1.82-0.69-4.27,1.13-5.47
					l20.18-13.26c0.88-0.58,1.94-0.78,2.97-0.57c1.03,0.21,1.92,0.82,2.5,1.7l4.89,7.45c1.2,1.82,0.69,4.27-1.13,5.47l-20.18,13.26
					C248.95,1081.78,248.2,1082,247.44,1082z M262.71,1055.02c-0.45,0-0.89,0.13-1.27,0.38l-20.18,13.26
					c-1.07,0.7-1.37,2.14-0.66,3.21l4.89,7.45c0.34,0.52,0.86,0.87,1.47,1c0.61,0.13,1.23,0.01,1.74-0.33l20.18-13.26
					c1.07-0.7,1.37-2.14,0.66-3.21l-4.89-7.45c-0.34-0.52-0.86-0.87-1.47-1C263.03,1055.04,262.87,1055.02,262.71,1055.02z"
                        />
                    </g>
                </g>
                <path
                    className="st0"
                    d="M315.03,1216.34l29.26,44.53c13.36-5.83,33.07-16.53,52.58-34.78l-37.36-56.85c-19.48,18.17-39.15,28.9-52.58,34.78
			l4.04,6.15c5.98-2.93,12.02-6.19,17.97-9.72l0.83,1.4c-5.93,3.51-11.94,6.76-17.9,9.69l2.25,3.42c5.97-2.92,12.02-6.19,17.97-9.72
			c0.1-0.06,10.3-6.16,12.26-7.81l1.05,1.25c-2.06,1.73-12.05,7.71-12.47,7.96C327,1210.17,320.98,1213.42,315.03,1216.34z"
                />
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="255.94"
                            y="1072.14"
                            transform="matrix(0.8357 -0.5492 0.5492 0.8357 -551.6586 324.0711)"
                            width="19.74"
                            height="23.84"
                        />
                    </g>
                    <g>
                        <path
                            className="st0"
                            d="M263.87,1100.57l-13.99-21.29l17.86-11.74l13.99,21.29L263.87,1100.57z M252.14,1079.75l12.2,18.57l15.14-9.95
					l-12.2-18.57L252.14,1079.75z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M103.74,1249.58c-1.5-0.45-3.08-0.69-4.73-0.69c-9.07,0-16.42,7.35-16.42,16.42c0,2.49,0.55,4.85,1.55,6.96l-0.92,1.06
					l-18.05,3.27l10.56-16.9c0,0-26.12,2.3-26.69,2.3c-0.58,0,13.06-15.75,13.06-15.75l-19.39-21.32l25.16,6.34v-22.66l20.16,20.36
					l9.03-17.48L103.74,1249.58z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M115.13,1352.26c-9.5,0-17.24-7.73-17.24-17.24c0-2.45,0.5-4.81,1.49-7.02l0.24-0.53l0.58,0.05
						c0.45,0.04,0.92,0.06,1.39,0.06c6.15,0,11.75-3.63,14.26-9.25l0.24-0.53l0.58,0.05c8.81,0.77,15.7,8.31,15.7,17.17
						C132.37,1344.53,124.64,1352.26,115.13,1352.26z M100.66,1329.19c-0.75,1.85-1.13,3.81-1.13,5.84c0,8.6,7,15.61,15.61,15.61
						s15.61-7,15.61-15.61c0-7.83-5.95-14.52-13.65-15.48c-2.88,5.89-8.9,9.67-15.49,9.67
						C101.28,1329.21,100.97,1329.2,100.66,1329.19z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M100.06,1329.14c-8.81-0.77-15.7-8.31-15.7-17.17c0-9.5,7.73-17.24,17.24-17.24s17.24,7.73,17.24,17.24
						c0,2.45-0.5,4.81-1.49,7.02l-1.49-0.66c0.9-2.01,1.35-4.15,1.35-6.36c0-8.6-7-15.61-15.61-15.61s-15.61,7-15.61,15.61
						c0,8.02,6.24,14.84,14.22,15.54L100.06,1329.14z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M130.98,1331.23h-0.08v-1.63h0.08c8.6,0,15.61-7,15.61-15.61c0-3.75-1.35-7.38-3.81-10.22
						c-2.97-3.42-7.27-5.39-11.8-5.39c-0.19,0-0.38,0-0.57,0.01l-0.06-1.63c0.21-0.01,0.42-0.01,0.63-0.01c5,0,9.75,2.17,13.03,5.95
						c2.71,3.13,4.21,7.14,4.21,11.29C148.22,1323.5,140.48,1331.23,130.98,1331.23z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M117.51,1308.31l-0.22-1.61c7.67-1.06,13.45-7.7,13.45-15.46c0-8.6-7-15.61-15.61-15.61s-15.61,7-15.61,15.61
						c0,1.46,0.2,2.89,0.59,4.25l-1.57,0.45c-0.43-1.51-0.65-3.09-0.65-4.7c0-9.5,7.73-17.24,17.24-17.24s17.24,7.73,17.24,17.24
						C132.37,1299.8,125.98,1307.14,117.51,1308.31z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M86.68,1305.6c-8.19-1.49-14.13-8.62-14.13-16.95c0-9.5,7.73-17.24,17.23-17.24c5.82,0,11.21,2.91,14.41,7.78
						l-1.36,0.9c-2.9-4.41-7.78-7.05-13.05-7.05c-8.6,0-15.6,7-15.6,15.61c0,7.55,5.38,14,12.79,15.35L86.68,1305.6z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M143.48,1304.05l-0.17-1.62c7.94-0.84,13.94-7.52,13.94-15.52c0-8.6-7-15.61-15.61-15.61
						c-6.03,0-11.41,3.37-14.04,8.79l-1.47-0.71c2.87-5.9,8.95-9.71,15.51-9.71c9.5,0,17.24,7.73,17.24,17.24
						C158.87,1295.75,152.25,1303.12,143.48,1304.05z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M140.76,1270.98l-1.41-0.81c1.34-2.34,2.05-5.02,2.05-7.74c0-8.6-7-15.61-15.61-15.61c-5.7,0-10.94,3.1-13.69,8.1
						l-1.43-0.78c3.03-5.52,8.82-8.95,15.11-8.95c9.5,0,17.24,7.73,17.24,17.24C143.03,1265.43,142.25,1268.39,140.76,1270.98z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M112.86,1275.56l-1.31-0.97c0.41-0.56,0.8-1.16,1.14-1.78c1.25-2.29,1.92-4.88,1.92-7.5c0-3.77-1.36-7.4-3.83-10.24
						c-1.94-2.23-4.46-3.86-7.28-4.7c-1.44-0.44-2.96-0.66-4.5-0.66c-8.6,0-15.61,7-15.61,15.61c0,2.32,0.49,4.54,1.47,6.61
						c0.12,0.27,0.25,0.52,0.38,0.77l-1.43,0.78c-0.15-0.28-0.3-0.57-0.43-0.86c-1.07-2.28-1.62-4.74-1.62-7.3
						c0-9.5,7.73-17.24,17.24-17.24c1.7,0,3.37,0.24,4.97,0.73c3.11,0.93,5.89,2.73,8.04,5.2c2.73,3.14,4.23,7.15,4.23,11.31
						c0,2.89-0.73,5.76-2.12,8.29C113.74,1274.28,113.32,1274.94,112.86,1275.56z"
                            />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M112.69,1252.49c-2.1-4.93-2.4-8.9-2.28-11.64c0.22-5.05,1.91-6.82,2.86-14.58c0.43-3.53,0.71-5.81,0.29-8.93
					c-0.39-2.91-1.04-7.73-4.9-11.81c-4.24-4.49-9.68-5.35-11.63-5.51c-3.92-0.32-7.09,0.32-7.09,0.32
					c21.03,0.58,23.33,19.3,19.3,30.82c-4.03,11.52,1.15,22.76,1.15,22.76l1.01,0.6l1.59-2.19"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M111.68,1226.28c0,0,9.36-3.02,8.79-12.96c-0.58-9.94-3.17-13.25-3.17-13.25s3.89,3.6,4.61,7.06
					c0.72,3.46,2.59,11.52-2.45,15.99c-5.04,4.47-7.49,6.34-8.21,9.22L111.68,1226.28z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="87.27" y="1319.18" width="15.15" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="90.45" y="1323.21" width="11.97" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="84.94" y="1310.92" width="17.48" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="85.62" y="1314.95" width="16.8" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="87.91" y="1302.09" width="14.5" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="85.96" y="1306.12" width="16.45" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <rect className="st0" x="75.55" y="1295.56" width="12.22" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="78.73" y="1299.59" width="5.44" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="73.22" y="1287.3" width="17.48" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="73.9" y="1291.33" width="16.8" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="76.2" y="1278.47" width="7.97" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="74.25" y="1282.5" width="13.38" height="1.63" />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <rect className="st0" x="81.17" y="1266.9" width="12.22" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="78.84" y="1258.64" width="17.48" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="79.52" y="1262.67" width="16.8" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="79.87" y="1253.84" width="13.38" height="1.63" />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="98.72" y="1336.8" width="15.99" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="99.72" y="1340.83" width="11.52" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <polygon
                            className="st0"
                            points="174.22,1219.88 158.64,1186.42 147.23,1210.4 133.39,1163.27 134.95,1162.81 147.59,1205.85 158.66,1182.59 
					174.19,1215.96 185.2,1191.45 215.97,1192.29 197.86,1169.72 217.85,1151.89 176.9,1156.37 186.9,1134.44 136.77,1160.04 
					136.03,1158.59 190.32,1130.86 179.57,1154.44 222.74,1149.71 200.1,1169.9 219.44,1194.01 186.25,1193.11 				"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M136.38,1159.34l-2.8,1.26l0.58,2.44c-1.7-1.27-4.33-2.9-8.36-4.8c-9.79-4.61-5.57-14.98-5.57-14.98
					s0.24,2.64,1.08,5.45c1.18,3.95,4.07,5.97,4.68,6.46c1.85,1.47,3.54,2.08,5.76,2.88c1.73,0.62,3.31,1.01,4.64,1.26
					L136.38,1159.34z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M79.27,1180.14c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
					C84.7,1177.7,82.26,1180.14,79.27,1180.14z M79.27,1170.92c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79
					c2.09,0,3.79-1.7,3.79-3.79S81.36,1170.92,79.27,1170.92z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M38.37,1297.96c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
					C43.79,1295.52,41.36,1297.96,38.37,1297.96z M38.37,1288.74c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79
					c2.09,0,3.79-1.7,3.79-3.79S40.46,1288.74,38.37,1288.74z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M164.54,1266.27c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42s5.42,2.43,5.42,5.42S167.53,1266.27,164.54,1266.27z
					 M164.54,1257.05c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79s3.79-1.7,3.79-3.79S166.63,1257.05,164.54,1257.05z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M162.24,1330.51c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
					S165.23,1330.51,162.24,1330.51z M162.24,1321.29c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79c2.09,0,3.79-1.7,3.79-3.79
					S164.33,1321.29,162.24,1321.29z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <polygon
                            className="st0"
                            points="217.41,1193.91 139.05,1163.37 133.32,1161.36 133.86,1159.83 139.62,1161.84 218,1192.39 				"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <polygon
                            className="st0"
                            points="170.21,1187.64 167.82,1173.51 180.79,1167.5 181.48,1168.98 169.64,1174.47 171.82,1187.37 				"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <polygon
                            className="st0"
                            points="149.93,1179.85 148.3,1179.68 149.66,1166.68 157.95,1159.26 159.04,1160.47 151.22,1167.47 				"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M300.62,1332.3l-3.25,13.48c-0.08,0.38-0.16,0.76-0.25,1.14c-3.67,15.22-18.99,24.59-34.21,20.92
					c-15.23-3.67-24.59-18.99-20.92-34.22c0.1-0.4,0.2-0.8,0.32-1.2l3.23-13.4c0,0,8.64,15.52,20.14,15.33
					S285.43,1318.57,300.62,1332.3z"
                        />
                    </g>
                    <g>
                        <path
                            className="st0"
                            d="M269.55,1369.45c-2.26,0-4.55-0.26-6.84-0.82c-7.58-1.83-13.99-6.49-18.05-13.14c-4.07-6.65-5.3-14.48-3.47-22.06
					c0.1-0.42,0.21-0.83,0.33-1.23l3.71-15.4l1.02,1.82c0.08,0.15,8.44,14.91,19.22,14.91c0.06,0,0.13,0,0.19,0
					c3.74-0.06,7.12-1.83,10.69-3.71c7.07-3.71,14.39-7.55,24.81,1.88l0.36,0.32l-3.36,13.95c-0.07,0.35-0.15,0.75-0.25,1.14
					C294.7,1360.46,282.72,1369.45,269.55,1369.45z M245.86,1321.13l-2.77,11.49c-0.12,0.42-0.22,0.81-0.32,1.2
					c-1.72,7.15-0.56,14.55,3.28,20.82c3.84,6.28,9.89,10.68,17.04,12.41c14.76,3.56,29.67-5.56,33.23-20.32
					c0.09-0.37,0.17-0.74,0.24-1.11l3.14-13.03c-9.38-8.25-15.8-4.88-22.6-1.32c-3.59,1.88-7.3,3.83-11.42,3.9
					C256.06,1335.32,248.54,1325.25,245.86,1321.13z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M264.26,1324.95c-11.6,0-16.45-14.38-16.5-14.53l-0.07-0.22l6.6-27.39l1.58,0.38l-6.49,26.93
					c0.65,1.78,5.59,14.19,16.17,13.13l0.16,1.62C265.22,1324.92,264.73,1324.95,264.26,1324.95z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M264.82,1308.39c-0.1-0.01-10.25-0.71-13.69-12l1.56-0.47c3.09,10.17,11.85,10.82,12.22,10.84L264.82,1308.39z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M249.58,1450.65l-1.62-0.14c2.67-30.48,10.63-55.61,15.14-67.87c0.43-1.17,1.59-1.93,2.85-1.84
						c17.88,1.31,33.91-10.41,38.12-27.87c0.12-0.49,0.23-0.98,0.32-1.47l16.13-66.92c0.07-0.31,0.02-0.63-0.14-0.9
						c-0.17-0.27-0.43-0.46-0.74-0.54l-68.64-16.55c-0.31-0.07-0.63-0.02-0.9,0.14c-0.27,0.17-0.46,0.43-0.54,0.74l-16.1,66.79
						c-0.15,0.53-0.29,1.03-0.42,1.58c-4.21,17.45,4.72,35.19,21.23,42.18c1.15,0.49,1.85,1.7,1.7,2.94
						c-1.58,12.97-5.94,38.97-17.45,67.32l-1.51-0.61c11.44-28.18,15.78-54.01,17.34-66.9c0.06-0.53-0.22-1.03-0.72-1.24
						c-17.25-7.3-26.57-25.83-22.17-44.06c0.14-0.57,0.28-1.1,0.43-1.62l16.09-66.75c0.18-0.73,0.63-1.35,1.27-1.74
						c0.64-0.39,1.4-0.51,2.13-0.34l68.64,16.55c0.73,0.18,1.35,0.63,1.74,1.27c0.39,0.64,0.51,1.4,0.34,2.13l-16.13,66.88
						c-0.09,0.48-0.2,0.99-0.33,1.5c-4.4,18.23-21.15,30.48-39.82,29.11c-0.53-0.04-1.02,0.28-1.2,0.77
						C260.15,1395.39,252.24,1420.36,249.58,1450.65z"
                            />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M268.92,1468.26c-8.69,0-18.95-1.34-29.48-3.88c-12.57-3.03-24.03-7.41-32.27-12.33c-8.5-5.08-12.62-10.23-11.59-14.49
					c1.96-8.14,22.46-10.17,47.68-4.7l-0.34,1.59c-23.53-5.1-44.06-3.53-45.75,3.49c-0.84,3.46,3.12,8.1,10.84,12.71
					c8.1,4.84,19.4,9.15,31.81,12.15c12.41,2.99,24.44,4.3,33.85,3.69c8.98-0.59,14.61-2.91,15.44-6.37
					c1.68-6.96-15.71-17.67-38.76-23.86l0.42-1.57c11.65,3.13,22.14,7.41,29.52,12.06c7.79,4.9,11.39,9.66,10.4,13.76
					c-1.03,4.27-7.04,6.97-16.92,7.62C272.22,1468.21,270.6,1468.26,268.92,1468.26z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="211.51"
                            y="1425.17"
                            transform="matrix(0.2344 -0.9721 0.9721 0.2344 -1238.8738 1310.1136)"
                            width="1.63"
                            height="32.84"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="207.37"
                            y="1436.07"
                            transform="matrix(0.2344 -0.9721 0.9721 0.2344 -1246.5502 1309.6233)"
                            width="1.63"
                            height="20.31"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="211"
                            y="1425.32"
                            transform="matrix(0.2344 -0.9721 0.9721 0.2344 -1232.5231 1304.3137)"
                            width="1.63"
                            height="18.68"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M416.53,1156.1c-0.93,0.73-2.11,1.15-3.27,1.23l-0.57,0.05c-0.17,0.02-0.35,0.03-0.52,0.04c-0.35,0.02-0.7,0.02-1.05,0
						l-4.19-0.39l-2.09-0.22l-0.26-0.03c-0.08-0.01-0.2-0.02-0.22-0.03c-0.1-0.02-0.2-0.03-0.3-0.03c-0.21-0.01-0.42,0.02-0.63,0.08
						c-0.21,0.06-0.42,0.16-0.62,0.29c-0.1,0.07-0.19,0.14-0.28,0.22l-0.13,0.14l-0.17,0.2l-1.34,1.63
						c-0.91,1.08-1.8,2.17-2.73,3.23c0.49-1.32,1.02-2.63,1.53-3.94l0.79-1.96l0.1-0.25c0.04-0.09,0.1-0.2,0.15-0.3
						c0.11-0.2,0.24-0.4,0.38-0.58c0.28-0.38,0.63-0.71,1.03-0.99c0.39-0.28,0.84-0.5,1.31-0.65c0.24-0.07,0.48-0.13,0.73-0.17
						l0.55-0.09l2.08-0.35l4.15-0.66c0.35-0.05,0.69-0.07,1.04-0.07c0.17,0,0.35,0,0.52,0l0.26,0.01l0.13,0l0.06,0l0.02-0.01
						c0.23,0.01,0.41-0.07,0.58-0.19c0.16-0.13,0.29-0.32,0.36-0.53c0.06-0.21,0.05-0.41,0-0.61c0-0.05-0.05-0.1-0.06-0.16
						c0,0-0.02-0.05-0.04-0.08l-0.06-0.12l-0.12-0.24l-0.47-0.93l-0.94-1.86l-1.88-3.72l-3.76-7.45l-0.12-0.23
						c-0.04-0.08-0.07-0.13-0.14-0.29c-0.11-0.27-0.24-0.53-0.31-0.82c-0.09-0.28-0.16-0.57-0.19-0.87
						c-0.05-0.29-0.06-0.59-0.06-0.89c0.01-1.19,0.41-2.35,1.08-3.3c0.67-0.96,1.66-1.71,2.78-2.11c0.56-0.21,1.14-0.31,1.72-0.34
						l1.07-0.04l8.34-0.27l4.17-0.13l2.08-0.06c0.06,0,0.05,0,0.07-0.01l0.04-0.01l0.08,0c0.05-0.03,0.11-0.02,0.15-0.04
						c0.04-0.02,0.1-0.03,0.14-0.06c0.02-0.01,0.05-0.02,0.07-0.03c0.02-0.02,0.04-0.04,0.06-0.05c0.17-0.11,0.31-0.31,0.38-0.51
						c0.08-0.21,0.08-0.41,0.02-0.61c-0.01-0.05-0.03-0.1-0.06-0.15c0.01-0.01-0.11-0.23-0.18-0.37l-0.47-0.93l-0.94-1.86
						l-3.76-7.45l-1.88-3.72l-0.24-0.47l-0.06-0.12l-0.03-0.06l-0.04-0.1l-0.17-0.4c-0.12-0.26-0.18-0.56-0.26-0.84
						c-0.06-0.29-0.1-0.59-0.13-0.88c-0.07-1.19,0.22-2.38,0.82-3.39c0.61-1,1.52-1.83,2.6-2.32c0.55-0.23,1.12-0.4,1.7-0.47
						l0.44-0.04c0.08-0.01,0.12-0.01,0.16-0.01l0.13,0l0.52-0.02l4.17-0.13l1.04-0.03l0.52-0.02l0.12,0l0.04,0
						c0.03,0,0.05-0.01,0.08-0.01c0.21-0.03,0.41-0.13,0.56-0.27c0.04-0.03,0.07-0.08,0.11-0.12l0.05-0.07
						c0.02-0.03,0.01-0.01,0.07-0.11l3.73-6.28c0.35-0.6,0.72-1.19,1.05-1.8c0.17-0.3,0.32-0.62,0.49-0.92
						c0.18-0.3,0.37-0.59,0.58-0.87c0.69-0.88,1.38-1.77,2.07-2.65c0.11-0.15,0.23-0.29,0.34-0.44c0.12-0.15,0.23-0.29,0.35-0.44
						l3.13,1.86c-0.07,0.17-0.14,0.34-0.22,0.51c-0.07,0.17-0.14,0.34-0.22,0.51c-0.44,1.03-0.89,2.06-1.34,3.09
						c-0.15,0.32-0.3,0.63-0.49,0.93c-0.18,0.3-0.38,0.58-0.57,0.87c-0.37,0.59-0.72,1.19-1.07,1.79l-3.73,6.28
						c-0.03,0.05-0.13,0.22-0.21,0.33c-0.08,0.12-0.17,0.24-0.26,0.36c-0.18,0.23-0.38,0.45-0.6,0.65
						c-0.86,0.81-1.97,1.34-3.13,1.52c-0.15,0.02-0.29,0.04-0.44,0.05l-0.22,0.02l-0.14,0.01l-0.52,0.02l-1.04,0.03l-4.17,0.13
						l-0.52,0.02l-0.13,0c-0.04,0-0.09,0-0.09,0.01c-0.03,0-0.05,0.01-0.08,0.01c-0.11,0-0.21,0.04-0.31,0.08
						c-0.18,0.09-0.34,0.23-0.46,0.42c-0.11,0.19-0.16,0.41-0.15,0.61c0.01,0.05,0.01,0.1,0.02,0.15c0.03,0.05,0.02,0.1,0.05,0.15
						c0.01,0.02,0.02,0.05,0.03,0.08l0.01,0.02l0.03,0.05l0.06,0.12l0.23,0.47l1.88,3.73l3.76,7.45l0.94,1.86l0.47,0.93
						c0.08,0.17,0.14,0.25,0.27,0.57c0.12,0.27,0.23,0.54,0.3,0.82c0.32,1.14,0.28,2.39-0.12,3.49c-0.39,1.11-1.11,2.09-2.07,2.78
						l-0.37,0.25c-0.13,0.08-0.26,0.15-0.39,0.21c-0.26,0.15-0.54,0.25-0.82,0.35c-0.28,0.1-0.57,0.15-0.86,0.22
						c-0.14,0.02-0.29,0.04-0.43,0.05l-0.22,0.02c-0.07,0-0.16,0.01-0.19,0.01l-2.09,0.07l-4.17,0.13l-8.35,0.26l-1.02,0.03
						c-0.12,0.02-0.22,0.02-0.32,0.06c-0.18,0.07-0.37,0.18-0.49,0.37c-0.12,0.18-0.21,0.39-0.2,0.6c0,0.05,0,0.1,0.01,0.15
						c0,0.05,0.02,0.1,0.03,0.15c0,0.06,0.05,0.1,0.06,0.16c0,0,0.05,0.11,0.09,0.18l0.12,0.23l3.76,7.45l1.88,3.72l0.94,1.86
						l0.47,0.93l0.18,0.35c0.02,0.04,0.03,0.07,0.07,0.15c0.11,0.27,0.24,0.53,0.32,0.82c0.36,1.12,0.36,2.38,0,3.5
						C418.15,1154.37,417.46,1155.38,416.53,1156.1z"
                            />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M479.04,1100.66c-2.05,0-4.07-0.55-5.88-1.62c-5.49-3.29-7.28-10.38-4.04-15.84c3.24-5.46,10.32-7.28,15.79-4.07
					c2.7,1.6,4.58,4.12,5.35,7.11c0.76,2.99,0.31,6.1-1.26,8.76l0,0c-1.58,2.65-4.09,4.54-7.08,5.3
					C480.96,1100.54,480,1100.66,479.04,1100.66z M479.07,1079.18c-3.4,0-6.7,1.74-8.55,4.85c-2.78,4.69-1.24,10.78,3.43,13.58
					c2.32,1.38,4.99,1.76,7.56,1.11c2.57-0.65,4.73-2.27,6.08-4.55l0,0c1.35-2.28,1.74-4.95,1.08-7.52
					c-0.66-2.57-2.27-4.73-4.55-6.08C482.54,1079.62,480.79,1079.18,479.07,1079.18z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M473.12,1099.01l-46.79-27.78c-2.65-1.58-4.54-4.09-5.3-7.08c-0.76-2.99-0.32-6.1,1.26-8.75
					c1.58-2.66,4.09-4.54,7.08-5.3c2.99-0.76,6.1-0.31,8.76,1.26l46.79,27.78l-0.83,1.4c-4.7-2.76-10.78-1.19-13.56,3.49
					c-2.78,4.69-1.24,10.78,3.43,13.58L473.12,1099.01z M432.24,1051.36c-0.82,0-1.65,0.1-2.47,0.31c-2.57,0.65-4.73,2.27-6.08,4.55
					c-1.35,2.28-1.74,4.95-1.08,7.52c0.66,2.57,2.27,4.73,4.55,6.08l41.76,24.79c-1.87-3.46-1.94-7.8,0.21-11.42
					c2.15-3.62,5.99-5.64,9.93-5.65l-41.76-24.79C435.74,1051.83,434,1051.36,432.24,1051.36z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M473.11,1099c-5.44-3.26-7.23-10.35-3.99-15.81c3.24-5.46,10.32-7.28,15.79-4.07l-0.83,1.4
					c-4.7-2.76-10.78-1.19-13.56,3.49c-2.78,4.69-1.24,10.78,3.43,13.58L473.11,1099z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="437.78"
                            y="1082.16"
                            transform="matrix(0.8599 0.5105 -0.5105 0.8599 617.8419 -74.5182)"
                            width="13.77"
                            height="12.12"
                        />
                    </g>
                    <g>
                        <path
                            className="st0"
                            d="M447.78,1098.06l-13.24-7.86l7.02-11.82l13.24,7.86L447.78,1098.06z M436.77,1089.63l10.44,6.2l5.36-9.02l-10.44-6.2
					L436.77,1089.63z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M91.31,1131.23c-47.23-24.74-57.82-87.48-59.47-99.81l1.62-0.22c1.62,12.19,12.09,74.21,58.61,98.58L91.31,1131.23z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M32.89,1032.33l-0.43-0.2c-0.13-0.06-13.34-6.55-4.37-28.18l0.51-1.23l0.86,1.02c0.4,0.47,9.69,11.53,8.51,20.73
					c-0.4,3.14-1.99,5.7-4.7,7.59L32.89,1032.33z M29.08,1005.88c-6.62,17.21,1.63,23.36,3.66,24.56c2.08-1.59,3.29-3.66,3.62-6.17
					C37.25,1017.35,31.35,1008.84,29.08,1005.88z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M38.01,1042.9c-1.2,0-2.42-0.2-3.67-0.61l-0.45-0.15l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.41l1.29-0.33l-0.29,1.3
					c-0.14,0.6-3.41,14.67-11.48,19.25C41.57,1042.43,39.83,1042.9,38.01,1042.9z M35.32,1040.88c2.52,0.71,4.91,0.43,7.11-0.81
					c6.07-3.44,9.33-13.27,10.34-16.86C35.03,1028.25,35.07,1038.54,35.32,1040.88z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M41.94,1058.29c-1.2,0-2.42-0.2-3.67-0.61l-0.45-0.15l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.41l1.29-0.33l-0.29,1.3
					c-0.14,0.6-3.41,14.67-11.48,19.25C45.51,1057.82,43.76,1058.29,41.94,1058.29z M39.26,1056.27c2.52,0.71,4.91,0.43,7.11-0.81
					c6.07-3.44,9.33-13.27,10.34-16.86C38.96,1043.64,39,1053.93,39.26,1056.27z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M47.74,1074.77c-1.2,0-2.42-0.2-3.67-0.61l-0.45-0.15l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.41l1.29-0.33l-0.29,1.3
					c-0.14,0.6-3.41,14.67-11.48,19.25C51.3,1074.3,49.55,1074.77,47.74,1074.77z M45.05,1072.75c2.52,0.71,4.91,0.43,7.11-0.81
					c6.07-3.44,9.33-13.27,10.34-16.86C44.75,1060.12,44.79,1070.41,45.05,1072.75z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M55.47,1090.99c-1.2,0-2.42-0.2-3.67-0.61l-0.45-0.15l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.4l1.29-0.33l-0.29,1.3
					c-0.14,0.6-3.41,14.67-11.48,19.24C59.04,1090.52,57.29,1090.99,55.47,1090.99z M52.79,1088.97c2.52,0.71,4.91,0.43,7.11-0.81
					c6.07-3.43,9.33-13.26,10.34-16.85C52.49,1076.34,52.53,1086.63,52.79,1088.97z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M64.79,1105.66c-1.2,0-2.42-0.2-3.67-0.61l-0.45-0.15l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.4l1.29-0.33l-0.29,1.3
					c-0.14,0.6-3.41,14.67-11.48,19.24C68.35,1105.19,66.6,1105.66,64.79,1105.66z M62.11,1103.65c2.52,0.7,4.9,0.43,7.1-0.81
					c6.07-3.44,9.33-13.26,10.34-16.85C61.98,1090.98,61.89,1101.34,62.11,1103.65z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M27.66,1049.06c-0.12,0-0.24,0-0.37-0.01c-9.27-0.28-18.73-11.21-19.12-11.68l-0.87-1.01l1.3-0.31
					c22.78-5.44,27.09,8.63,27.13,8.77l0.13,0.45l-0.33,0.34C33.32,1047.9,30.67,1049.06,27.66,1049.06z M10.34,1037.33
					c2.57,2.7,10.04,9.88,17.01,10.09c2.54,0.08,4.77-0.8,6.67-2.59C33.14,1042.64,28.39,1033.52,10.34,1037.33z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M33.32,1068.36c-0.12,0-0.24,0-0.37-0.01c-9.27-0.28-18.73-11.21-19.12-11.68l-0.87-1.01l1.3-0.31
					c22.78-5.44,27.09,8.62,27.13,8.77l0.13,0.45l-0.33,0.34C38.99,1067.2,36.34,1068.36,33.32,1068.36z M16.01,1056.63
					c2.57,2.7,10.04,9.88,17.01,10.09c2.54,0.07,4.77-0.8,6.67-2.59C38.81,1061.94,34.05,1052.82,16.01,1056.63z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M40.25,1085.71c-0.12,0-0.24,0-0.37-0.01c-9.27-0.28-18.73-11.21-19.12-11.68l-0.87-1.01l1.3-0.31
					c22.78-5.44,27.09,8.62,27.13,8.77l0.13,0.45l-0.33,0.34C45.91,1084.55,43.26,1085.71,40.25,1085.71z M22.93,1073.99
					c2.57,2.7,10.04,9.88,17.01,10.09c2.52,0.07,4.77-0.8,6.66-2.59C45.73,1079.29,40.97,1070.17,22.93,1073.99z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M49.31,1104.3c-8.92,0-18.77-8.75-19.2-9.14l-0.99-0.89l1.25-0.48c21.87-8.36,27.99,5.01,28.05,5.15l0.19,0.43
					l-0.28,0.38c-1.97,2.66-4.57,4.17-7.72,4.49C50.16,1104.28,49.74,1104.3,49.31,1104.3z M32.25,1094.83
					c2.9,2.34,11.24,8.49,18.18,7.79c2.52-0.25,4.62-1.41,6.27-3.44C55.55,1097.11,49.65,1088.69,32.25,1094.83z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M57.09,1120.87c-7.57,0-15.58-3.74-16.01-3.94l-1.21-0.57l1.06-0.81c18.57-14.27,28.25-3.19,28.35-3.08l0.3,0.36
					l-0.16,0.44c-1.13,3.11-3.19,5.3-6.12,6.51C61.4,1120.56,59.26,1120.87,57.09,1120.87z M43.04,1116
					c3.45,1.42,13.2,4.92,19.65,2.27c2.34-0.96,4.03-2.67,5.02-5.09C66.03,1111.53,57.96,1105.15,43.04,1116z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M401.46,1453.01c-1.53-6.09-3.51-12.35-5.89-18.6c-5.16-13.58-4.97-25.87,0.57-36.53c11-21.18,39.75-28.72,40.04-28.79
					l0.4,1.58c-0.28,0.07-28.34,7.44-39,27.97c-5.31,10.23-5.47,22.07-0.48,35.19c2.4,6.31,4.4,12.63,5.94,18.78L401.46,1453.01z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M442.9,1373.37c-2.86,0-5.41-1.07-7.61-3.19l-0.34-0.33l0.12-0.46c0.04-0.14,3.89-14.34,26.83-9.65l1.31,0.27
					l-0.83,1.04c-0.38,0.48-9.48,11.71-18.73,12.3C443.4,1373.36,443.15,1373.37,442.9,1373.37z M436.8,1369.35
					c1.96,1.74,4.22,2.54,6.75,2.38c6.96-0.44,14.19-7.86,16.67-10.64C442.05,1357.85,437.59,1367.13,436.8,1369.35z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M424.46,1374.85l-0.32-0.35c-0.1-0.11-9.83-11.14,6.71-27.71l0.94-0.94l0.41,1.27c0.19,0.58,4.56,14.36-0.05,22.41
					c-1.57,2.75-4.01,4.51-7.24,5.23L424.46,1374.85z M431.04,1348.94c-12.69,13.39-7.4,22.21-5.99,24.1
					c2.53-0.68,4.44-2.13,5.7-4.32C434.21,1362.66,432,1352.54,431.04,1348.94z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M409.13,1383.8l-0.36-0.31c-0.11-0.1-11.03-9.96,3.54-28.29l0.83-1.04l0.55,1.21c0.25,0.56,6.15,13.75,2.49,22.27
					c-1.25,2.91-3.47,4.93-6.61,6.01L409.13,1383.8z M412.74,1357.32c-10.98,14.6-4.78,22.91-3.22,24.62
					c2.43-0.96,4.17-2.62,5.17-4.94C417.44,1370.58,414.1,1360.79,412.74,1357.32z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M399.17,1395.44l-0.46-0.12c-0.14-0.04-14.29-4.09-9.27-26.97l0.28-1.3l1.03,0.85c0.47,0.39,11.58,9.64,12.03,18.9
					c0.16,3.16-0.95,5.96-3.29,8.31L399.17,1395.44z M390.75,1370.06c-3.48,18.11,5.73,22.7,7.94,23.53
					c1.76-1.93,2.59-4.19,2.47-6.71C400.82,1379.92,393.5,1372.58,390.75,1370.06z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M392.38,1405.33c-4.26,0-13.93-2.03-19.08-19.28l-0.38-1.28l1.31,0.24c0.6,0.11,14.8,2.82,19.69,10.7
					c1.67,2.69,2.06,5.67,1.15,8.86l-0.13,0.45l-0.46,0.11C394.45,1405.15,393.65,1405.33,392.38,1405.33z M375.27,1386.92
					c5.74,17.53,16.02,17.08,18.35,16.73c0.61-2.54,0.24-4.92-1.09-7.07C388.85,1390.65,378.9,1387.79,375.27,1386.92z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M387.92,1422.52c-4.94,0-12.63-2.44-19.68-14.88l-0.66-1.16l1.33-0.06c0.61-0.03,15.05-0.56,21.58,6.03
					c2.23,2.25,3.27,5.07,3.1,8.38l-0.02,0.47l-0.42,0.21C393.1,1421.53,391.06,1422.52,387.92,1422.52z M370.35,1408.04
					c9.51,15.8,19.43,13.06,21.63,12.2c0.02-2.61-0.87-4.85-2.65-6.64C384.43,1408.65,374.08,1408.08,370.35,1408.04z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M433.95,1388.4c-4.18,0-8.84-0.66-12.23-2.98c-2.61-1.79-4.17-4.36-4.62-7.64l-0.06-0.47l0.37-0.29
					c0.12-0.09,11.9-8.89,27.08,8.94l0.86,1.02l-1.3,0.31C443.72,1387.36,439.21,1388.4,433.95,1388.4z M418.8,1378.05
					c0.47,2.57,1.76,4.59,3.85,6.02c5.76,3.94,16.01,2.56,19.68,1.89C430.13,1372.37,420.78,1376.85,418.8,1378.05z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M414.74,1397.6c-2.26,0-4.48-0.32-6.46-1.16c-2.92-1.24-4.95-3.45-6.05-6.57l-0.16-0.44l0.31-0.36
					c0.1-0.11,9.9-11.08,28.31,3.39l1.05,0.82l-1.21,0.56C430.11,1394.03,422.24,1397.6,414.74,1397.6z M403.95,1389.79
					c0.97,2.43,2.64,4.16,4.97,5.15c6.42,2.72,16.2-0.67,19.67-2.05C413.79,1381.87,405.65,1388.16,403.95,1389.79z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M405.33,1415.99c-2.26,0-4.48-0.32-6.46-1.16c-2.92-1.24-4.95-3.45-6.05-6.57l-0.16-0.44l0.31-0.36
					c0.1-0.11,9.9-11.08,28.31,3.39l1.05,0.82l-1.21,0.56C420.7,1412.42,412.82,1415.99,405.33,1415.99z M394.54,1408.18
					c0.97,2.43,2.64,4.16,4.97,5.15c6.42,2.72,16.2-0.67,19.67-2.05C404.38,1400.26,396.24,1406.55,394.54,1408.18z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M401.75,1433.2c-2.75,0-5.24-1.01-7.41-3.02l-0.35-0.32l0.1-0.46c0.03-0.14,3.56-14.43,26.6-10.28l1.31,0.24l-0.81,1.06
					c-0.37,0.49-9.2,11.93-18.44,12.73C402.42,1433.19,402.08,1433.2,401.75,1433.2z M395.82,1429.33c1.99,1.69,4.28,2.43,6.79,2.21
					c6.94-0.6,14-8.19,16.41-11.03C400.97,1417.75,396.58,1427.14,395.82,1429.33z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M154.34,1408.39c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C159.76,1405.96,157.33,1408.39,154.34,1408.39z M154.34,1399.17c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79S156.43,1399.17,154.34,1399.17z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M137.76,1461.22c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C143.18,1458.79,140.75,1461.22,137.76,1461.22z M137.76,1452c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79C141.55,1453.7,139.85,1452,137.76,1452z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M350.13,1364.7c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42s5.42,2.43,5.42,5.42
				C355.55,1362.27,353.12,1364.7,350.13,1364.7z M350.13,1355.49c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79S352.22,1355.49,350.13,1355.49z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M454.72,1302.84c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C460.14,1300.41,457.71,1302.84,454.72,1302.84z M454.72,1293.62c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79C458.51,1295.33,456.81,1293.62,454.72,1293.62z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M257.65,1229.71c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C263.08,1227.28,260.64,1229.71,257.65,1229.71z M257.65,1220.49c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79S259.75,1220.49,257.65,1220.49z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M202.17,1113.43c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C207.59,1111,205.16,1113.43,202.17,1113.43z M202.17,1104.21c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79S204.26,1104.21,202.17,1104.21z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M310.87,1030.95c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42s5.42,2.43,5.42,5.42
				C316.29,1028.51,313.86,1030.95,310.87,1030.95z M310.87,1021.73c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79S312.96,1021.73,310.87,1021.73z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M130.74,1044.55c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				S133.73,1044.55,130.74,1044.55z M130.74,1035.33c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79c2.09,0,3.79-1.7,3.79-3.79
				S132.83,1035.33,130.74,1035.33z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M452.81,1462.92c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42s5.42,2.43,5.42,5.42S455.8,1462.92,452.81,1462.92z
				 M452.81,1453.7c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79c2.09,0,3.79-1.7,3.79-3.79S454.9,1453.7,452.81,1453.7z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M344.39,1465.47c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C349.81,1463.04,347.38,1465.47,344.39,1465.47z M344.39,1456.25c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79C348.18,1457.95,346.48,1456.25,344.39,1456.25z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M120.24,1453.41l-6.88,3.15l-0.3-0.92c-2.45-5.35-8.79-7.71-14.13-5.26c-5.35,2.45-7.71,8.78-5.26,14.13l0.02,0.05
				l0.42,0.77l-0.78,0.39l-44.17,20.19l-18.21-39.83l0.82-0.3c0.11-0.04,0.22-0.09,0.33-0.14c5.35-2.45,7.71-8.78,5.26-14.13
				c-2.45-5.35-8.78-7.71-14.13-5.26c-0.11,0.05-0.22,0.1-0.32,0.16l-0.77,0.43l-5.48-11.99l12.29-5.62l0.35,0.7
				c2.58,5.15,8.71,7.3,13.96,4.91s7.62-8.44,5.42-13.77l-0.3-0.73l39.36-18l3.15,6.89l-0.74,0.34c-5.36,2.45-7.72,8.79-5.28,14.14
				s8.78,7.71,14.13,5.26l0.74-0.34l0.35,0.73L120.24,1453.41z M114.25,1454.36l3.84-1.75l-19.13-41.85
				c-6.01,2.29-12.85-0.46-15.56-6.38c-2.71-5.92-0.3-12.9,5.35-15.94l-1.81-3.95l-36.47,16.67c2.04,5.95-0.72,12.48-6.53,15.13
				s-12.55,0.47-15.72-4.96l-9.4,4.3l4.1,8.96c6.09-2.56,13.17,0.18,15.93,6.23c2.76,6.05,0.2,13.19-5.72,16.12l16.83,36.81
				l41.95-19.18c-2.37-6.03,0.37-12.95,6.33-15.68C104.21,1446.16,111.24,1448.62,114.25,1454.36z"
                    />
                </g>
                <g>
                    <path
                        className="st0"
                        d="M68.87,1447.3c-7.24,3.31-15.82,0.11-19.13-7.13s-0.11-15.82,7.13-19.13s15.82-0.11,19.13,7.13
				C79.31,1435.41,76.11,1443.99,68.87,1447.3z M57.54,1422.52c-6.42,2.94-9.26,10.55-6.32,16.97c2.94,6.42,10.55,9.26,16.97,6.32
				c6.42-2.94,9.26-10.55,6.32-16.97C71.58,1422.42,63.97,1419.59,57.54,1422.52z"
                    />
                </g>
                <g>
                    <path
                        className="st0"
                        d="M66.21,1468.76c-2.97,1.36-6.49,0.05-7.84-2.92s-0.05-6.48,2.92-7.84c2.97-1.36,6.49-0.05,7.84,2.92
				C70.49,1463.88,69.18,1467.4,66.21,1468.76z M61.97,1459.47c-2.15,0.98-3.1,3.53-2.12,5.68c0.98,2.15,3.53,3.1,5.68,2.12
				c2.15-0.98,3.1-3.53,2.12-5.68S64.12,1458.49,61.97,1459.47z"
                    />
                </g>
                <g>
                    <path
                        className="st0"
                        d="M93.52,1435.08c-2.97,1.36-6.49,0.05-7.84-2.92c-1.36-2.97-0.05-6.48,2.92-7.84c2.97-1.36,6.49-0.05,7.84,2.92
				C97.79,1430.21,96.48,1433.72,93.52,1435.08z M89.27,1425.8c-2.15,0.98-3.1,3.53-2.12,5.68s3.53,3.1,5.68,2.12
				c2.15-0.98,3.1-3.53,2.12-5.68C93.97,1425.77,91.42,1424.82,89.27,1425.8z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <circle className="st0" cx="799.67" cy="1315.13" r="4.61" />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M806.44,1294.68c0.17,0.52,1.79,5.47-1.01,8.07c-2.6,2.41-7.32,1.2-9.79-0.86c-1.52-1.26-1.27-2.08-2.88-4.47
					c-3.59-5.31-7.5-5.22-7.78-7.78c-0.44-3.98,8.47-9.09,10.66-7.35c1.47,1.17-0.79,4.87,1.15,6.63c1.55,1.4,3.66-0.33,6.34,1.15
					C805.33,1291.3,806.12,1293.72,806.44,1294.68z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M789.87,1311.1c0.13,1.37-0.3,2.5-1.15,4.75c-1.22,3.2-1.83,4.8-3.02,5.04c-2.15,0.43-5.53-3.7-4.75-6.19
					c0.54-1.74,2.72-1.42,3.89-3.6c1.42-2.65-0.53-5.46,0.29-5.91C786.02,1304.71,789.53,1307.54,789.87,1311.1z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M871.35,1296.57c-0.7,0-1.4-0.07-2.1-0.22c-2.68-0.55-4.98-2.12-6.49-4.41l-77.85-118.48
					c-0.04-0.06-0.08-0.12-0.11-0.18c-6-11.09-5.25-24.42,1.96-34.8c0.25-0.36,0.51-0.71,0.77-1.06c0.18-0.24,0.19-0.56,0.03-0.81
					l-36.2-55.09c-0.34-0.52-0.46-1.14-0.34-1.76c0.13-0.61,0.48-1.14,1-1.48l13.98-9.19c0.52-0.34,1.15-0.46,1.76-0.34
					c0.61,0.13,1.14,0.48,1.48,1l36.2,55.09c0.16,0.25,0.46,0.37,0.75,0.3c0.42-0.1,0.85-0.2,1.28-0.28
					c12.38-2.51,24.92,2.09,32.72,12.01c0.05,0.06,0.09,0.12,0.13,0.18l77.85,118.47c3.1,4.72,1.79,11.09-2.94,14.19l-38.28,25.16
					C875.26,1295.99,873.32,1296.57,871.35,1296.57z M767.29,1070.35c-0.14,0-0.27,0.04-0.39,0.12l-13.98,9.19
					c-0.16,0.1-0.27,0.26-0.3,0.45c-0.04,0.18,0,0.37,0.1,0.53l36.2,55.09c0.54,0.82,0.51,1.9-0.09,2.69
					c-0.25,0.33-0.49,0.67-0.73,1.01c-6.86,9.87-7.57,22.55-1.87,33.09c0.01,0.02,0.02,0.04,0.04,0.06l77.86,118.48
					c1.26,1.92,3.2,3.24,5.45,3.71c2.25,0.47,4.55,0.03,6.48-1.24l38.28-25.16c1.92-1.26,3.24-3.2,3.7-5.45
					c0.47-2.25,0.03-4.55-1.24-6.48l-77.86-118.48c-0.01-0.02-0.03-0.04-0.04-0.06c-7.42-9.42-19.34-13.8-31.11-11.42
					c-0.41,0.08-0.81,0.17-1.22,0.27c-0.95,0.23-1.96-0.16-2.5-0.99l-36.2-55.09c-0.1-0.16-0.26-0.27-0.45-0.3
					C767.38,1070.36,767.34,1070.35,767.29,1070.35z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M867.76,1291.13c-1.03,0-2.07-0.16-3.09-0.48l0.49-1.56c2.48,0.78,5.15,0.41,7.32-1.02l15.3-10.05l0.9,1.36l-15.3,10.05
					C871.68,1290.55,869.73,1291.13,867.76,1291.13z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M865.21,1287.24c-0.7,0-1.4-0.07-2.1-0.22l0.33-1.6c2.26,0.47,4.55,0.03,6.48-1.24l3.89-2.56l0.89,1.36l-3.89,2.56
					C869.13,1286.66,867.19,1287.24,865.21,1287.24z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M833.6,1143.48c-5.36-6.84-14.34-10.15-22.87-8.42l-0.32-1.6c9.27-1.88,18.65,1.58,24.48,9.01L833.6,1143.48z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M747.44,1082c-0.27,0-0.54-0.03-0.81-0.08c-1.03-0.21-1.92-0.82-2.5-1.7l-4.89-7.45c-1.2-1.82-0.69-4.27,1.13-5.47
					l20.18-13.26c0.88-0.58,1.94-0.78,2.97-0.57c1.03,0.21,1.92,0.82,2.5,1.7l4.89,7.45c1.2,1.82,0.69,4.27-1.13,5.47l-20.18,13.26
					C748.95,1081.78,748.2,1082,747.44,1082z M762.71,1055.02c-0.45,0-0.89,0.13-1.27,0.38l-20.18,13.26
					c-1.07,0.7-1.37,2.14-0.66,3.21l4.89,7.45c0.34,0.52,0.86,0.87,1.47,1c0.61,0.13,1.23,0.01,1.74-0.33l20.18-13.26
					c1.07-0.7,1.37-2.14,0.66-3.21l-4.89-7.45c-0.34-0.52-0.86-0.87-1.47-1C763.03,1055.04,762.87,1055.02,762.71,1055.02z"
                        />
                    </g>
                </g>
                <path
                    className="st0"
                    d="M815.03,1216.34l29.26,44.53c13.36-5.83,33.07-16.53,52.58-34.78l-37.36-56.85c-19.48,18.17-39.15,28.9-52.58,34.78
			l4.04,6.15c5.98-2.93,12.02-6.19,17.97-9.72l0.83,1.4c-5.93,3.51-11.94,6.76-17.9,9.69l2.25,3.42c5.97-2.92,12.02-6.19,17.97-9.72
			c0.1-0.06,10.3-6.16,12.26-7.81l1.05,1.25c-2.06,1.73-12.05,7.71-12.47,7.96C827,1210.17,820.98,1213.42,815.03,1216.34z"
                />
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="755.94"
                            y="1072.14"
                            transform="matrix(0.8357 -0.5492 0.5492 0.8357 -469.5151 598.6543)"
                            width="19.74"
                            height="23.84"
                        />
                    </g>
                    <g>
                        <path
                            className="st0"
                            d="M763.87,1100.57l-13.99-21.29l17.86-11.74l13.99,21.29L763.87,1100.57z M752.14,1079.75l12.2,18.57l15.14-9.95
					l-12.2-18.57L752.14,1079.75z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M603.74,1249.58c-1.5-0.45-3.08-0.69-4.73-0.69c-9.07,0-16.42,7.35-16.42,16.42c0,2.49,0.55,4.85,1.55,6.96l-0.92,1.06
					l-18.05,3.27l10.56-16.9c0,0-26.12,2.3-26.69,2.3c-0.58,0,13.06-15.75,13.06-15.75l-19.39-21.32l25.16,6.34v-22.66l20.16,20.36
					l9.03-17.48L603.74,1249.58z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M615.13,1352.26c-9.5,0-17.24-7.73-17.24-17.24c0-2.45,0.5-4.81,1.49-7.02l0.24-0.53l0.58,0.05
						c0.45,0.04,0.92,0.06,1.39,0.06c6.15,0,11.75-3.63,14.26-9.25l0.24-0.53l0.58,0.05c8.81,0.77,15.7,8.31,15.7,17.17
						C632.37,1344.53,624.64,1352.26,615.13,1352.26z M600.66,1329.19c-0.75,1.85-1.13,3.81-1.13,5.84c0,8.6,7,15.61,15.61,15.61
						c8.6,0,15.61-7,15.61-15.61c0-7.83-5.95-14.52-13.65-15.48c-2.88,5.89-8.9,9.67-15.49,9.67
						C601.28,1329.21,600.97,1329.2,600.66,1329.19z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M600.06,1329.14c-8.81-0.77-15.7-8.31-15.7-17.17c0-9.5,7.73-17.24,17.24-17.24s17.24,7.73,17.24,17.24
						c0,2.45-0.5,4.81-1.49,7.02l-1.49-0.66c0.9-2.01,1.35-4.15,1.35-6.36c0-8.6-7-15.61-15.61-15.61c-8.6,0-15.61,7-15.61,15.61
						c0,8.02,6.24,14.84,14.22,15.54L600.06,1329.14z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M630.98,1331.23h-0.08v-1.63h0.08c8.6,0,15.61-7,15.61-15.61c0-3.75-1.35-7.38-3.81-10.22
						c-2.97-3.42-7.27-5.39-11.8-5.39c-0.19,0-0.38,0-0.57,0.01l-0.06-1.63c0.21-0.01,0.42-0.01,0.63-0.01c5,0,9.75,2.17,13.03,5.95
						c2.71,3.13,4.21,7.14,4.21,11.29C648.22,1323.5,640.48,1331.23,630.98,1331.23z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M617.51,1308.31l-0.22-1.61c7.67-1.06,13.45-7.7,13.45-15.46c0-8.6-7-15.61-15.61-15.61c-8.6,0-15.61,7-15.61,15.61
						c0,1.46,0.2,2.89,0.59,4.25l-1.57,0.45c-0.43-1.51-0.65-3.09-0.65-4.7c0-9.5,7.73-17.24,17.24-17.24
						c9.5,0,17.24,7.73,17.24,17.24C632.37,1299.8,625.98,1307.14,617.51,1308.31z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M586.68,1305.6c-8.19-1.49-14.13-8.62-14.13-16.95c0-9.5,7.73-17.24,17.23-17.24c5.82,0,11.21,2.91,14.41,7.78
						l-1.36,0.9c-2.9-4.41-7.78-7.05-13.05-7.05c-8.6,0-15.6,7-15.6,15.61c0,7.55,5.38,14,12.79,15.35L586.68,1305.6z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M643.48,1304.05l-0.17-1.62c7.94-0.84,13.94-7.52,13.94-15.52c0-8.6-7-15.61-15.61-15.61
						c-6.03,0-11.41,3.37-14.04,8.79l-1.47-0.71c2.87-5.9,8.95-9.71,15.51-9.71c9.5,0,17.24,7.73,17.24,17.24
						C658.87,1295.75,652.25,1303.12,643.48,1304.05z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M640.76,1270.98l-1.41-0.81c1.34-2.34,2.05-5.02,2.05-7.74c0-8.6-7-15.61-15.61-15.61c-5.7,0-10.94,3.1-13.69,8.1
						l-1.43-0.78c3.03-5.52,8.82-8.95,15.11-8.95c9.5,0,17.24,7.73,17.24,17.24C643.03,1265.43,642.25,1268.39,640.76,1270.98z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M612.86,1275.56l-1.31-0.97c0.41-0.56,0.8-1.16,1.14-1.78c1.25-2.29,1.92-4.88,1.92-7.5c0-3.77-1.36-7.4-3.83-10.24
						c-1.94-2.23-4.46-3.86-7.28-4.7c-1.44-0.44-2.96-0.66-4.5-0.66c-8.6,0-15.61,7-15.61,15.61c0,2.32,0.49,4.54,1.47,6.61
						c0.12,0.27,0.25,0.52,0.38,0.77l-1.43,0.78c-0.15-0.28-0.3-0.57-0.43-0.86c-1.07-2.28-1.62-4.74-1.62-7.3
						c0-9.5,7.73-17.24,17.24-17.24c1.7,0,3.37,0.24,4.97,0.73c3.11,0.93,5.89,2.73,8.04,5.2c2.73,3.14,4.23,7.15,4.23,11.31
						c0,2.89-0.73,5.76-2.12,8.29C613.74,1274.28,613.32,1274.94,612.86,1275.56z"
                            />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M612.69,1252.49c-2.1-4.93-2.4-8.9-2.28-11.64c0.22-5.05,1.91-6.82,2.86-14.58c0.43-3.53,0.71-5.81,0.29-8.93
					c-0.39-2.91-1.04-7.73-4.9-11.81c-4.24-4.49-9.68-5.35-11.63-5.51c-3.92-0.32-7.09,0.32-7.09,0.32
					c21.03,0.58,23.33,19.3,19.3,30.82c-4.03,11.52,1.15,22.76,1.15,22.76l1.01,0.6l1.59-2.19"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M611.68,1226.28c0,0,9.36-3.02,8.79-12.96c-0.58-9.94-3.17-13.25-3.17-13.25s3.89,3.6,4.61,7.06
					c0.72,3.46,2.59,11.52-2.45,15.99c-5.04,4.47-7.49,6.34-8.21,9.22L611.68,1226.28z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="587.27" y="1319.18" width="15.15" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="590.45" y="1323.21" width="11.97" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="584.94" y="1310.92" width="17.48" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="585.62" y="1314.95" width="16.8" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="587.91" y="1302.09" width="14.5" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="585.96" y="1306.12" width="16.45" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <rect className="st0" x="575.55" y="1295.56" width="12.22" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="578.73" y="1299.59" width="5.44" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="573.22" y="1287.3" width="17.48" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="573.9" y="1291.33" width="16.8" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="576.2" y="1278.47" width="7.97" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="574.25" y="1282.5" width="13.38" height="1.63" />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <rect className="st0" x="581.17" y="1266.9" width="12.22" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="578.84" y="1258.64" width="17.48" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="579.52" y="1262.67" width="16.8" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="579.87" y="1253.84" width="13.38" height="1.63" />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="598.72" y="1336.8" width="15.99" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="599.72" y="1340.83" width="11.52" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <polygon
                            className="st0"
                            points="674.22,1219.88 658.64,1186.42 647.23,1210.4 633.39,1163.27 634.95,1162.81 647.59,1205.85 658.66,1182.59 
					674.19,1215.96 685.2,1191.45 715.97,1192.29 697.86,1169.72 717.85,1151.89 676.9,1156.37 686.9,1134.44 636.77,1160.04 
					636.03,1158.59 690.32,1130.86 679.57,1154.44 722.74,1149.71 700.1,1169.9 719.44,1194.01 686.25,1193.11 				"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M636.38,1159.34l-2.8,1.26l0.58,2.44c-1.7-1.27-4.33-2.9-8.36-4.8c-9.79-4.61-5.57-14.98-5.57-14.98
					s0.24,2.64,1.08,5.45c1.18,3.95,4.07,5.97,4.68,6.46c1.85,1.47,3.54,2.08,5.76,2.88c1.73,0.62,3.31,1.01,4.64,1.26
					L636.38,1159.34z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M579.27,1180.14c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
					C584.7,1177.7,582.26,1180.14,579.27,1180.14z M579.27,1170.92c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79
					c2.09,0,3.79-1.7,3.79-3.79S581.36,1170.92,579.27,1170.92z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M538.37,1297.96c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
					C543.79,1295.52,541.36,1297.96,538.37,1297.96z M538.37,1288.74c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79
					c2.09,0,3.79-1.7,3.79-3.79S540.46,1288.74,538.37,1288.74z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M664.54,1266.27c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
					S667.53,1266.27,664.54,1266.27z M664.54,1257.05c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79c2.09,0,3.79-1.7,3.79-3.79
					S666.63,1257.05,664.54,1257.05z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M662.24,1330.51c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
					S665.23,1330.51,662.24,1330.51z M662.24,1321.29c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79c2.09,0,3.79-1.7,3.79-3.79
					S664.33,1321.29,662.24,1321.29z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <polygon
                            className="st0"
                            points="717.41,1193.91 639.05,1163.37 633.32,1161.36 633.86,1159.83 639.62,1161.84 718,1192.39 				"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <polygon
                            className="st0"
                            points="670.21,1187.64 667.82,1173.51 680.79,1167.5 681.48,1168.98 669.64,1174.47 671.82,1187.37 				"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <polygon
                            className="st0"
                            points="649.93,1179.85 648.3,1179.68 649.66,1166.68 657.95,1159.26 659.04,1160.47 651.22,1167.47 				"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M800.62,1332.3l-3.25,13.48c-0.08,0.38-0.16,0.76-0.25,1.14c-3.67,15.22-18.99,24.59-34.21,20.92
					c-15.23-3.67-24.59-18.99-20.92-34.22c0.1-0.4,0.2-0.8,0.32-1.2l3.23-13.4c0,0,8.64,15.52,20.15,15.33
					C777.18,1334.16,785.43,1318.57,800.62,1332.3z"
                        />
                    </g>
                    <g>
                        <path
                            className="st0"
                            d="M769.55,1369.45c-2.26,0-4.55-0.26-6.84-0.82c-7.58-1.83-13.99-6.49-18.05-13.14c-4.07-6.65-5.3-14.48-3.47-22.06
					c0.1-0.42,0.21-0.83,0.33-1.23l3.71-15.4l1.02,1.82c0.08,0.15,8.44,14.91,19.22,14.91c0.06,0,0.13,0,0.19,0
					c3.74-0.06,7.12-1.83,10.69-3.71c7.07-3.71,14.39-7.55,24.81,1.88l0.36,0.32l-3.36,13.95c-0.07,0.35-0.15,0.75-0.25,1.14
					C794.7,1360.46,782.72,1369.45,769.55,1369.45z M745.86,1321.13l-2.77,11.49c-0.12,0.42-0.22,0.81-0.32,1.2
					c-1.72,7.15-0.56,14.55,3.28,20.82c3.84,6.28,9.89,10.68,17.04,12.41c14.76,3.56,29.67-5.56,33.23-20.32
					c0.09-0.37,0.17-0.74,0.24-1.11l3.14-13.03c-9.38-8.25-15.8-4.88-22.6-1.32c-3.59,1.88-7.3,3.83-11.42,3.9
					C756.06,1335.32,748.54,1325.25,745.86,1321.13z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M764.26,1324.95c-11.6,0-16.45-14.38-16.5-14.53l-0.07-0.22l6.6-27.39l1.58,0.38l-6.49,26.93
					c0.65,1.78,5.59,14.19,16.17,13.13l0.16,1.62C765.22,1324.92,764.73,1324.95,764.26,1324.95z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M764.82,1308.39c-0.1-0.01-10.25-0.71-13.69-12l1.56-0.47c3.09,10.17,11.85,10.82,12.22,10.84L764.82,1308.39z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M749.58,1450.65l-1.62-0.14c2.67-30.48,10.63-55.61,15.14-67.87c0.43-1.17,1.59-1.93,2.85-1.84
						c17.88,1.31,33.91-10.41,38.12-27.87c0.12-0.49,0.23-0.98,0.32-1.47l16.13-66.92c0.07-0.31,0.02-0.63-0.14-0.9
						c-0.17-0.27-0.43-0.46-0.74-0.54l-68.64-16.55c-0.31-0.07-0.63-0.02-0.9,0.14c-0.27,0.17-0.46,0.43-0.54,0.74l-16.1,66.79
						c-0.15,0.53-0.29,1.03-0.42,1.58c-4.21,17.45,4.72,35.19,21.23,42.18c1.15,0.49,1.85,1.7,1.7,2.94
						c-1.58,12.97-5.94,38.97-17.45,67.32l-1.51-0.61c11.44-28.18,15.78-54.01,17.34-66.9c0.06-0.53-0.22-1.03-0.72-1.24
						c-17.25-7.3-26.57-25.83-22.17-44.06c0.14-0.57,0.28-1.1,0.43-1.62l16.09-66.75c0.18-0.73,0.63-1.35,1.27-1.74
						c0.64-0.39,1.4-0.51,2.13-0.34l68.64,16.55c0.73,0.18,1.35,0.63,1.74,1.27c0.39,0.64,0.51,1.4,0.34,2.13l-16.13,66.88
						c-0.09,0.48-0.2,0.99-0.33,1.5c-4.4,18.23-21.15,30.48-39.82,29.11c-0.53-0.04-1.02,0.28-1.2,0.77
						C760.15,1395.39,752.24,1420.36,749.58,1450.65z"
                            />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M768.92,1468.26c-8.69,0-18.95-1.34-29.48-3.88c-12.57-3.03-24.03-7.41-32.27-12.33c-8.5-5.08-12.62-10.23-11.59-14.49
					c1.96-8.14,22.46-10.17,47.68-4.7l-0.34,1.59c-23.53-5.1-44.06-3.53-45.75,3.49c-0.84,3.46,3.12,8.1,10.84,12.71
					c8.1,4.84,19.4,9.15,31.81,12.15c12.41,2.99,24.44,4.3,33.85,3.69c8.98-0.59,14.61-2.91,15.44-6.37
					c1.68-6.96-15.71-17.67-38.76-23.86l0.42-1.57c11.65,3.13,22.14,7.41,29.52,12.06c7.79,4.9,11.39,9.66,10.4,13.76
					c-1.03,4.27-7.04,6.97-16.92,7.62C772.22,1468.21,770.6,1468.26,768.92,1468.26z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="711.51"
                            y="1425.17"
                            transform="matrix(0.2344 -0.9721 0.9721 0.2344 -856.0683 1796.1852)"
                            width="1.63"
                            height="32.84"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="707.37"
                            y="1436.07"
                            transform="matrix(0.2344 -0.9721 0.9721 0.2344 -863.7458 1795.6945)"
                            width="1.63"
                            height="20.31"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="711"
                            y="1425.32"
                            transform="matrix(0.2344 -0.9721 0.9721 0.2344 -849.7128 1790.3862)"
                            width="1.63"
                            height="18.68"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M916.53,1156.1c-0.93,0.73-2.11,1.15-3.27,1.23l-0.57,0.05c-0.17,0.02-0.35,0.03-0.52,0.04c-0.35,0.02-0.7,0.02-1.05,0
						l-4.19-0.39l-2.09-0.22l-0.26-0.03c-0.08-0.01-0.2-0.02-0.22-0.03c-0.1-0.02-0.2-0.03-0.3-0.03c-0.21-0.01-0.42,0.02-0.63,0.08
						c-0.21,0.06-0.42,0.16-0.62,0.29c-0.1,0.07-0.19,0.14-0.28,0.22l-0.13,0.14l-0.17,0.2l-1.34,1.63
						c-0.91,1.08-1.8,2.17-2.73,3.23c0.49-1.32,1.02-2.63,1.53-3.94l0.79-1.96l0.1-0.25c0.04-0.09,0.1-0.2,0.15-0.3
						c0.11-0.2,0.24-0.4,0.38-0.58c0.28-0.38,0.63-0.71,1.03-0.99c0.39-0.28,0.84-0.5,1.31-0.65c0.24-0.07,0.48-0.13,0.73-0.17
						l0.55-0.09l2.08-0.35l4.15-0.66c0.35-0.05,0.69-0.07,1.04-0.07c0.17,0,0.35,0,0.52,0l0.26,0.01l0.13,0l0.06,0l0.02-0.01
						c0.23,0.01,0.41-0.07,0.58-0.19c0.16-0.13,0.29-0.32,0.36-0.53c0.06-0.21,0.05-0.41,0-0.61c0-0.05-0.05-0.1-0.06-0.16
						c0,0-0.02-0.05-0.04-0.08l-0.06-0.12l-0.12-0.24l-0.47-0.93l-0.94-1.86l-1.88-3.72l-3.76-7.45l-0.12-0.23
						c-0.04-0.08-0.07-0.13-0.14-0.29c-0.11-0.27-0.24-0.53-0.31-0.82c-0.09-0.28-0.16-0.57-0.19-0.87
						c-0.05-0.29-0.06-0.59-0.06-0.89c0.01-1.19,0.41-2.35,1.08-3.3c0.67-0.96,1.66-1.71,2.78-2.11c0.56-0.21,1.14-0.31,1.72-0.34
						l1.07-0.04l8.34-0.27l4.17-0.13l2.08-0.06c0.06,0,0.05,0,0.07-0.01l0.04-0.01l0.08,0c0.05-0.03,0.11-0.02,0.15-0.04
						s0.1-0.03,0.14-0.06c0.02-0.01,0.05-0.02,0.07-0.03c0.02-0.02,0.04-0.04,0.06-0.05c0.17-0.11,0.31-0.31,0.38-0.51
						c0.08-0.21,0.08-0.41,0.02-0.61c-0.01-0.05-0.03-0.1-0.06-0.15c0.01-0.01-0.11-0.23-0.18-0.37l-0.47-0.93l-0.94-1.86
						l-3.76-7.45l-1.88-3.72l-0.24-0.47l-0.06-0.12l-0.03-0.06l-0.04-0.1l-0.17-0.4c-0.12-0.26-0.18-0.56-0.26-0.84
						c-0.06-0.29-0.1-0.59-0.13-0.88c-0.07-1.19,0.22-2.38,0.82-3.39c0.61-1,1.52-1.83,2.6-2.32c0.55-0.23,1.12-0.4,1.7-0.47
						l0.44-0.04c0.08-0.01,0.12-0.01,0.16-0.01l0.13,0l0.52-0.02l4.17-0.13l1.04-0.03l0.52-0.02l0.12,0l0.04,0
						c0.03,0,0.05-0.01,0.08-0.01c0.21-0.03,0.41-0.13,0.56-0.27c0.04-0.03,0.07-0.08,0.11-0.12l0.05-0.07
						c0.02-0.03,0.01-0.01,0.07-0.11l3.73-6.28c0.35-0.6,0.72-1.19,1.05-1.8c0.17-0.3,0.32-0.62,0.49-0.92
						c0.18-0.3,0.37-0.59,0.58-0.87c0.69-0.88,1.38-1.77,2.07-2.65c0.11-0.15,0.23-0.29,0.34-0.44c0.12-0.15,0.23-0.29,0.35-0.44
						l3.13,1.86c-0.07,0.17-0.14,0.34-0.22,0.51c-0.07,0.17-0.14,0.34-0.22,0.51c-0.44,1.03-0.89,2.06-1.34,3.09
						c-0.15,0.32-0.3,0.63-0.49,0.93c-0.18,0.3-0.38,0.58-0.57,0.87c-0.37,0.59-0.72,1.19-1.07,1.79l-3.73,6.28
						c-0.03,0.05-0.13,0.22-0.21,0.33c-0.08,0.12-0.17,0.24-0.26,0.36c-0.18,0.23-0.38,0.45-0.6,0.65
						c-0.86,0.81-1.97,1.34-3.13,1.52c-0.15,0.02-0.29,0.04-0.44,0.05l-0.22,0.02l-0.14,0.01l-0.52,0.02l-1.04,0.03l-4.17,0.13
						l-0.52,0.02l-0.13,0c-0.04,0-0.09,0-0.09,0.01c-0.03,0-0.05,0.01-0.08,0.01c-0.11,0-0.21,0.04-0.31,0.08
						c-0.18,0.09-0.34,0.23-0.46,0.42c-0.11,0.19-0.16,0.41-0.15,0.61c0.01,0.05,0.01,0.1,0.02,0.15c0.03,0.05,0.02,0.1,0.05,0.15
						c0.01,0.02,0.02,0.05,0.03,0.08l0.01,0.02l0.03,0.05l0.06,0.12l0.23,0.47l1.88,3.73l3.76,7.45l0.94,1.86l0.47,0.93
						c0.08,0.17,0.14,0.25,0.27,0.57c0.12,0.27,0.23,0.54,0.3,0.82c0.32,1.14,0.28,2.39-0.12,3.49c-0.39,1.11-1.11,2.09-2.07,2.78
						l-0.37,0.25c-0.13,0.08-0.26,0.15-0.39,0.21c-0.26,0.15-0.54,0.25-0.82,0.35c-0.28,0.1-0.57,0.15-0.86,0.22
						c-0.14,0.02-0.29,0.04-0.43,0.05l-0.22,0.02c-0.07,0-0.16,0.01-0.19,0.01l-2.09,0.07l-4.17,0.13l-8.35,0.26l-1.02,0.03
						c-0.12,0.02-0.22,0.02-0.32,0.06c-0.18,0.07-0.37,0.18-0.49,0.37c-0.12,0.18-0.21,0.39-0.2,0.6c0,0.05,0,0.1,0.01,0.15
						c0,0.05,0.02,0.1,0.03,0.15c0,0.06,0.05,0.1,0.06,0.16c0,0,0.05,0.11,0.09,0.18l0.12,0.23l3.76,7.45l1.88,3.72l0.94,1.86
						l0.47,0.93l0.18,0.35c0.02,0.04,0.03,0.07,0.07,0.15c0.11,0.27,0.24,0.53,0.32,0.82c0.36,1.12,0.36,2.38,0,3.5
						C918.15,1154.37,917.46,1155.38,916.53,1156.1z"
                            />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M979.04,1100.66c-2.05,0-4.07-0.55-5.88-1.62c-5.49-3.29-7.28-10.38-4.04-15.84c3.24-5.46,10.32-7.28,15.79-4.07
					c2.7,1.6,4.58,4.12,5.35,7.11c0.76,2.99,0.31,6.1-1.26,8.76l0,0c-1.58,2.65-4.09,4.54-7.08,5.3
					C980.96,1100.54,980,1100.66,979.04,1100.66z M979.07,1079.18c-3.4,0-6.7,1.74-8.55,4.85c-2.78,4.69-1.24,10.78,3.43,13.58
					c2.32,1.38,4.99,1.76,7.56,1.11c2.57-0.65,4.73-2.27,6.08-4.55l0,0c1.35-2.28,1.74-4.95,1.08-7.52
					c-0.66-2.57-2.27-4.73-4.55-6.08C982.54,1079.62,980.79,1079.18,979.07,1079.18z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M973.12,1099.01l-46.79-27.78c-2.65-1.58-4.54-4.09-5.3-7.08c-0.76-2.99-0.32-6.1,1.26-8.75
					c1.58-2.66,4.09-4.54,7.08-5.3c2.99-0.76,6.1-0.31,8.76,1.26l46.79,27.78l-0.83,1.4c-4.7-2.76-10.78-1.19-13.56,3.49
					c-2.78,4.69-1.24,10.78,3.43,13.58L973.12,1099.01z M932.24,1051.36c-0.82,0-1.65,0.1-2.47,0.31c-2.57,0.65-4.73,2.27-6.08,4.55
					c-1.35,2.28-1.74,4.95-1.08,7.52c0.66,2.57,2.27,4.73,4.55,6.08l41.76,24.79c-1.87-3.46-1.94-7.8,0.21-11.42
					c2.15-3.62,5.99-5.64,9.93-5.65l-41.76-24.79C935.74,1051.83,934,1051.36,932.24,1051.36z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M973.11,1099c-5.44-3.26-7.23-10.35-3.99-15.81c3.24-5.46,10.32-7.28,15.79-4.07l-0.83,1.4
					c-4.7-2.76-10.78-1.19-13.56,3.49c-2.78,4.69-1.24,10.78,3.43,13.58L973.11,1099z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="937.78"
                            y="1082.16"
                            transform="matrix(0.8599 0.5105 -0.5105 0.8599 687.9031 -329.7683)"
                            width="13.77"
                            height="12.12"
                        />
                    </g>
                    <g>
                        <path
                            className="st0"
                            d="M947.78,1098.06l-13.24-7.86l7.02-11.82l13.24,7.86L947.78,1098.06z M936.77,1089.63l10.44,6.2l5.36-9.02l-10.44-6.2
					L936.77,1089.63z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M591.31,1131.23c-47.23-24.74-57.82-87.48-59.47-99.81l1.62-0.22c1.62,12.19,12.09,74.21,58.61,98.58L591.31,1131.23z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M532.89,1032.33l-0.43-0.2c-0.13-0.06-13.34-6.55-4.37-28.18l0.51-1.23l0.86,1.02c0.4,0.47,9.69,11.53,8.51,20.73
					c-0.4,3.14-1.99,5.7-4.7,7.59L532.89,1032.33z M529.08,1005.88c-6.62,17.21,1.63,23.36,3.66,24.56
					c2.08-1.59,3.29-3.66,3.62-6.17C537.25,1017.35,531.35,1008.84,529.08,1005.88z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M538.01,1042.9c-1.2,0-2.42-0.2-3.67-0.61l-0.45-0.15l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.41l1.29-0.33l-0.29,1.3
					c-0.14,0.6-3.41,14.67-11.48,19.25C541.57,1042.43,539.83,1042.9,538.01,1042.9z M535.32,1040.88c2.52,0.71,4.91,0.43,7.11-0.81
					c6.07-3.44,9.33-13.27,10.34-16.86C535.03,1028.25,535.07,1038.54,535.32,1040.88z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M541.94,1058.29c-1.2,0-2.42-0.2-3.67-0.61l-0.45-0.15l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.41l1.29-0.33l-0.29,1.3
					c-0.14,0.6-3.41,14.67-11.48,19.25C545.51,1057.82,543.76,1058.29,541.94,1058.29z M539.26,1056.27
					c2.52,0.71,4.91,0.43,7.11-0.81c6.07-3.44,9.33-13.27,10.34-16.86C538.96,1043.64,539,1053.93,539.26,1056.27z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M547.74,1074.77c-1.2,0-2.42-0.2-3.67-0.61l-0.45-0.15l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.41l1.29-0.33l-0.29,1.3
					c-0.14,0.6-3.41,14.67-11.48,19.25C551.3,1074.3,549.55,1074.77,547.74,1074.77z M545.05,1072.75c2.52,0.71,4.91,0.43,7.11-0.81
					c6.07-3.44,9.33-13.27,10.34-16.86C544.75,1060.12,544.79,1070.41,545.05,1072.75z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M555.47,1090.99c-1.2,0-2.42-0.2-3.67-0.61l-0.45-0.15l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.4l1.29-0.33l-0.29,1.3
					c-0.14,0.6-3.41,14.67-11.48,19.24C559.04,1090.52,557.29,1090.99,555.47,1090.99z M552.79,1088.97
					c2.52,0.71,4.91,0.43,7.11-0.81c6.07-3.43,9.33-13.26,10.34-16.85C552.49,1076.34,552.53,1086.63,552.79,1088.97z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M564.79,1105.66c-1.2,0-2.42-0.2-3.67-0.61l-0.45-0.15l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.4l1.29-0.33l-0.29,1.3
					c-0.14,0.6-3.41,14.67-11.48,19.24C568.35,1105.19,566.6,1105.66,564.79,1105.66z M562.11,1103.65c2.52,0.7,4.9,0.43,7.1-0.81
					c6.07-3.44,9.33-13.26,10.34-16.85C561.98,1090.98,561.89,1101.34,562.11,1103.65z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M527.66,1049.06c-0.12,0-0.24,0-0.37-0.01c-9.27-0.28-18.73-11.21-19.12-11.68l-0.87-1.01l1.3-0.31
					c22.78-5.44,27.09,8.63,27.13,8.77l0.13,0.45l-0.33,0.34C533.32,1047.9,530.67,1049.06,527.66,1049.06z M510.34,1037.33
					c2.57,2.7,10.04,9.88,17.01,10.09c2.54,0.08,4.77-0.8,6.67-2.59C533.14,1042.64,528.39,1033.52,510.34,1037.33z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M533.32,1068.36c-0.12,0-0.24,0-0.37-0.01c-9.27-0.28-18.73-11.21-19.12-11.68l-0.87-1.01l1.3-0.31
					c22.78-5.44,27.09,8.62,27.13,8.77l0.13,0.45l-0.33,0.34C538.99,1067.2,536.34,1068.36,533.32,1068.36z M516.01,1056.63
					c2.57,2.7,10.04,9.88,17.01,10.09c2.54,0.07,4.77-0.8,6.67-2.59C538.81,1061.94,534.05,1052.82,516.01,1056.63z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M540.25,1085.71c-0.12,0-0.24,0-0.36-0.01c-9.27-0.28-18.73-11.21-19.12-11.68l-0.87-1.01l1.3-0.31
					c22.78-5.44,27.09,8.62,27.13,8.77l0.13,0.45l-0.33,0.34C545.91,1084.55,543.26,1085.71,540.25,1085.71z M522.93,1073.99
					c2.57,2.7,10.04,9.88,17.01,10.09c2.52,0.07,4.77-0.8,6.66-2.59C545.73,1079.29,540.97,1070.17,522.93,1073.99z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M549.31,1104.3c-8.92,0-18.77-8.75-19.2-9.14l-0.99-0.89l1.25-0.48c21.87-8.36,27.99,5.01,28.05,5.15l0.19,0.43
					l-0.28,0.38c-1.97,2.66-4.57,4.17-7.72,4.49C550.16,1104.28,549.74,1104.3,549.31,1104.3z M532.25,1094.83
					c2.9,2.34,11.24,8.49,18.18,7.79c2.52-0.25,4.62-1.41,6.27-3.44C555.55,1097.11,549.65,1088.69,532.25,1094.83z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M557.09,1120.87c-7.57,0-15.58-3.74-16.01-3.94l-1.21-0.57l1.06-0.81c18.57-14.27,28.25-3.19,28.35-3.08l0.3,0.36
					l-0.16,0.44c-1.13,3.11-3.19,5.3-6.12,6.51C561.4,1120.56,559.26,1120.87,557.09,1120.87z M543.04,1116
					c3.45,1.42,13.2,4.92,19.65,2.27c2.34-0.96,4.03-2.67,5.02-5.09C566.03,1111.53,557.96,1105.15,543.04,1116z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M901.46,1453.01c-1.53-6.09-3.51-12.35-5.89-18.6c-5.16-13.58-4.97-25.87,0.57-36.53c11-21.18,39.75-28.72,40.04-28.79
					l0.4,1.58c-0.28,0.07-28.34,7.44-39,27.97c-5.31,10.23-5.47,22.07-0.48,35.19c2.4,6.31,4.4,12.63,5.94,18.78L901.46,1453.01z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M942.9,1373.37c-2.86,0-5.41-1.07-7.61-3.19l-0.34-0.33l0.12-0.46c0.04-0.14,3.89-14.34,26.83-9.65l1.31,0.27
					l-0.83,1.04c-0.38,0.48-9.48,11.71-18.73,12.3C943.4,1373.36,943.15,1373.37,942.9,1373.37z M936.8,1369.35
					c1.96,1.74,4.22,2.54,6.75,2.38c6.96-0.44,14.19-7.86,16.67-10.64C942.05,1357.85,937.59,1367.13,936.8,1369.35z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M924.46,1374.85l-0.32-0.35c-0.1-0.11-9.83-11.14,6.71-27.71l0.94-0.94l0.41,1.27c0.19,0.58,4.56,14.36-0.05,22.41
					c-1.57,2.75-4.01,4.51-7.24,5.23L924.46,1374.85z M931.04,1348.94c-12.69,13.39-7.4,22.21-5.99,24.1
					c2.53-0.68,4.44-2.13,5.7-4.32C934.21,1362.66,932,1352.54,931.04,1348.94z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M909.13,1383.8l-0.36-0.31c-0.11-0.1-11.03-9.96,3.54-28.29l0.83-1.04l0.55,1.21c0.25,0.56,6.15,13.75,2.49,22.27
					c-1.25,2.91-3.47,4.93-6.61,6.01L909.13,1383.8z M912.74,1357.32c-10.98,14.6-4.78,22.91-3.22,24.62
					c2.43-0.96,4.17-2.62,5.17-4.94C917.44,1370.58,914.1,1360.79,912.74,1357.32z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M899.17,1395.44l-0.46-0.12c-0.14-0.04-14.29-4.09-9.27-26.97l0.28-1.3l1.03,0.85c0.47,0.39,11.58,9.64,12.03,18.9
					c0.16,3.16-0.95,5.96-3.29,8.31L899.17,1395.44z M890.75,1370.06c-3.48,18.11,5.73,22.7,7.94,23.53
					c1.76-1.93,2.59-4.19,2.47-6.71C900.82,1379.92,893.5,1372.58,890.75,1370.06z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M892.38,1405.33c-4.26,0-13.93-2.03-19.08-19.28l-0.38-1.28l1.31,0.24c0.6,0.11,14.8,2.82,19.69,10.7
					c1.67,2.69,2.06,5.67,1.15,8.86l-0.13,0.45l-0.46,0.11C894.45,1405.15,893.65,1405.33,892.38,1405.33z M875.27,1386.92
					c5.74,17.53,16.02,17.08,18.35,16.73c0.61-2.54,0.24-4.92-1.09-7.07C888.85,1390.65,878.9,1387.79,875.27,1386.92z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M887.92,1422.52c-4.94,0-12.63-2.44-19.68-14.88l-0.66-1.16l1.33-0.06c0.61-0.03,15.05-0.56,21.58,6.03
					c2.23,2.25,3.27,5.07,3.1,8.38l-0.02,0.47l-0.42,0.21C893.1,1421.53,891.06,1422.52,887.92,1422.52z M870.35,1408.04
					c9.51,15.8,19.43,13.06,21.63,12.2c0.02-2.61-0.87-4.85-2.65-6.64C884.43,1408.65,874.08,1408.08,870.35,1408.04z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M933.95,1388.4c-4.18,0-8.84-0.66-12.23-2.98c-2.61-1.79-4.17-4.36-4.62-7.64l-0.06-0.47l0.37-0.29
					c0.12-0.09,11.9-8.89,27.08,8.94l0.86,1.02l-1.3,0.31C943.72,1387.36,939.21,1388.4,933.95,1388.4z M918.8,1378.05
					c0.47,2.57,1.76,4.59,3.85,6.02c5.76,3.94,16.01,2.56,19.68,1.89C930.13,1372.37,920.78,1376.85,918.8,1378.05z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M914.74,1397.6c-2.26,0-4.48-0.32-6.46-1.16c-2.92-1.24-4.95-3.45-6.05-6.57l-0.16-0.44l0.31-0.36
					c0.1-0.11,9.9-11.08,28.31,3.39l1.05,0.82l-1.21,0.56C930.11,1394.03,922.24,1397.6,914.74,1397.6z M903.95,1389.79
					c0.97,2.43,2.64,4.16,4.97,5.15c6.42,2.72,16.2-0.67,19.67-2.05C913.79,1381.87,905.65,1388.16,903.95,1389.79z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M905.33,1415.99c-2.26,0-4.48-0.32-6.46-1.16c-2.92-1.24-4.95-3.45-6.05-6.57l-0.16-0.44l0.31-0.36
					c0.1-0.11,9.9-11.08,28.31,3.39l1.05,0.82l-1.21,0.56C920.7,1412.42,912.82,1415.99,905.33,1415.99z M894.54,1408.18
					c0.97,2.43,2.64,4.16,4.97,5.15c6.42,2.72,16.2-0.67,19.67-2.05C904.38,1400.26,896.24,1406.55,894.54,1408.18z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M901.75,1433.2c-2.75,0-5.24-1.01-7.41-3.02l-0.35-0.32l0.1-0.46c0.03-0.14,3.56-14.43,26.6-10.28l1.31,0.24l-0.81,1.06
					c-0.37,0.49-9.2,11.93-18.44,12.73C902.42,1433.19,902.08,1433.2,901.75,1433.2z M895.82,1429.33c1.99,1.69,4.28,2.43,6.79,2.21
					c6.94-0.6,14-8.19,16.41-11.03C900.97,1417.75,896.58,1427.14,895.82,1429.33z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M654.34,1408.39c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C659.76,1405.96,657.33,1408.39,654.34,1408.39z M654.34,1399.17c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79S656.43,1399.17,654.34,1399.17z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M637.76,1461.22c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C643.18,1458.79,640.75,1461.22,637.76,1461.22z M637.76,1452c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
				s3.79-1.7,3.79-3.79C641.55,1453.7,639.85,1452,637.76,1452z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M850.13,1364.7c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C855.55,1362.27,853.12,1364.7,850.13,1364.7z M850.13,1355.49c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79S852.22,1355.49,850.13,1355.49z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M954.72,1302.84c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C960.14,1300.41,957.71,1302.84,954.72,1302.84z M954.72,1293.62c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79C958.51,1295.33,956.81,1293.62,954.72,1293.62z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M757.65,1229.71c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C763.08,1227.28,760.64,1229.71,757.65,1229.71z M757.65,1220.49c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79S759.75,1220.49,757.65,1220.49z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M702.17,1113.43c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C707.59,1111,705.16,1113.43,702.17,1113.43z M702.17,1104.21c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79S704.26,1104.21,702.17,1104.21z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M810.87,1030.95c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C816.29,1028.51,813.86,1030.95,810.87,1030.95z M810.87,1021.73c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79S812.96,1021.73,810.87,1021.73z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M630.74,1044.55c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				S633.73,1044.55,630.74,1044.55z M630.74,1035.33c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79c2.09,0,3.79-1.7,3.79-3.79
				S632.83,1035.33,630.74,1035.33z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M952.81,1462.92c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				S955.8,1462.92,952.81,1462.92z M952.81,1453.7c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79s3.79-1.7,3.79-3.79
				S954.9,1453.7,952.81,1453.7z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M844.39,1465.47c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C849.81,1463.04,847.38,1465.47,844.39,1465.47z M844.39,1456.25c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79C848.18,1457.95,846.48,1456.25,844.39,1456.25z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M620.24,1453.41l-6.88,3.15l-0.3-0.92c-2.45-5.35-8.79-7.71-14.13-5.26c-5.35,2.45-7.71,8.78-5.26,14.13l0.02,0.05
				l0.42,0.77l-0.78,0.39l-44.17,20.19l-18.21-39.83l0.82-0.3c0.11-0.04,0.22-0.09,0.33-0.14c5.35-2.45,7.71-8.78,5.26-14.13
				c-2.45-5.35-8.78-7.71-14.13-5.26c-0.11,0.05-0.22,0.1-0.32,0.16l-0.77,0.43l-5.48-11.99l12.29-5.62l0.35,0.7
				c2.58,5.15,8.71,7.3,13.96,4.91s7.62-8.44,5.42-13.77l-0.3-0.73l39.36-18l3.15,6.89l-0.74,0.34c-5.36,2.45-7.72,8.79-5.28,14.14
				s8.78,7.71,14.13,5.26l0.74-0.34l0.35,0.73L620.24,1453.41z M614.25,1454.36l3.84-1.75l-19.13-41.85
				c-6.01,2.29-12.85-0.46-15.56-6.38c-2.71-5.92-0.3-12.9,5.35-15.94l-1.81-3.95l-36.47,16.67c2.04,5.95-0.72,12.48-6.53,15.13
				c-5.8,2.65-12.55,0.47-15.72-4.96l-9.4,4.3l4.1,8.96c6.09-2.56,13.17,0.18,15.93,6.23c2.76,6.05,0.2,13.19-5.72,16.12
				l16.83,36.81l41.95-19.18c-2.37-6.03,0.37-12.95,6.33-15.68C604.21,1446.16,611.24,1448.62,614.25,1454.36z"
                    />
                </g>
                <g>
                    <path
                        className="st0"
                        d="M568.87,1447.3c-7.24,3.31-15.82,0.11-19.13-7.13c-3.31-7.24-0.11-15.82,7.13-19.13c7.24-3.31,15.82-0.11,19.13,7.13
				C579.31,1435.41,576.11,1443.99,568.87,1447.3z M557.54,1422.52c-6.42,2.94-9.26,10.55-6.32,16.97
				c2.94,6.42,10.55,9.26,16.97,6.32s9.26-10.55,6.32-16.97C571.58,1422.42,563.97,1419.59,557.54,1422.52z"
                    />
                </g>
                <g>
                    <path
                        className="st0"
                        d="M566.21,1468.76c-2.97,1.36-6.49,0.05-7.84-2.92s-0.05-6.48,2.92-7.84c2.97-1.36,6.48-0.05,7.84,2.92
				S569.18,1467.4,566.21,1468.76z M561.97,1459.47c-2.15,0.98-3.1,3.53-2.12,5.68c0.98,2.15,3.53,3.1,5.68,2.12
				c2.15-0.98,3.1-3.53,2.12-5.68C566.67,1459.44,564.12,1458.49,561.97,1459.47z"
                    />
                </g>
                <g>
                    <path
                        className="st0"
                        d="M593.52,1435.08c-2.97,1.36-6.49,0.05-7.84-2.92s-0.05-6.48,2.92-7.84c2.97-1.36,6.48-0.05,7.84,2.92
				S596.48,1433.72,593.52,1435.08z M589.27,1425.8c-2.15,0.98-3.1,3.53-2.12,5.68c0.98,2.15,3.53,3.1,5.68,2.12
				c2.15-0.98,3.1-3.53,2.12-5.68C593.97,1425.77,591.42,1424.82,589.27,1425.8z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <circle className="st0" cx="1299.67" cy="1315.13" r="4.61" />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1306.44,1294.68c0.17,0.52,1.79,5.47-1.01,8.07c-2.6,2.41-7.32,1.2-9.79-0.86c-1.51-1.26-1.27-2.08-2.88-4.47
					c-3.59-5.31-7.5-5.22-7.78-7.78c-0.44-3.98,8.47-9.09,10.66-7.35c1.47,1.17-0.79,4.87,1.15,6.63c1.55,1.4,3.66-0.33,6.34,1.15
					C1305.33,1291.3,1306.12,1293.72,1306.44,1294.68z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1289.87,1311.1c0.13,1.37-0.3,2.5-1.15,4.75c-1.22,3.2-1.83,4.8-3.02,5.04c-2.15,0.43-5.53-3.7-4.75-6.19
					c0.54-1.74,2.72-1.42,3.89-3.6c1.42-2.65-0.53-5.46,0.29-5.91C1286.02,1304.71,1289.53,1307.54,1289.87,1311.1z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1371.35,1296.57c-0.7,0-1.4-0.07-2.1-0.22c-2.68-0.55-4.98-2.12-6.49-4.41l-77.85-118.48
					c-0.04-0.06-0.08-0.12-0.11-0.18c-6-11.09-5.25-24.42,1.96-34.8c0.25-0.36,0.51-0.71,0.77-1.06c0.18-0.24,0.19-0.56,0.03-0.81
					l-36.2-55.09c-0.34-0.52-0.46-1.14-0.34-1.76c0.13-0.61,0.48-1.14,1-1.48l13.98-9.19c0.52-0.34,1.15-0.46,1.76-0.34
					c0.61,0.13,1.14,0.48,1.48,1l36.2,55.09c0.16,0.25,0.46,0.37,0.75,0.3c0.42-0.1,0.85-0.2,1.28-0.28
					c12.38-2.51,24.92,2.09,32.72,12.01c0.05,0.06,0.09,0.12,0.13,0.18l77.85,118.47c3.1,4.72,1.79,11.09-2.94,14.19l-38.28,25.16
					C1375.26,1295.99,1373.32,1296.57,1371.35,1296.57z M1267.29,1070.35c-0.14,0-0.27,0.04-0.39,0.12l-13.98,9.19
					c-0.16,0.1-0.27,0.26-0.3,0.45s0,0.37,0.1,0.53l36.2,55.09c0.54,0.82,0.51,1.9-0.09,2.69c-0.25,0.33-0.49,0.67-0.73,1.01
					c-6.86,9.87-7.57,22.55-1.87,33.09c0.01,0.02,0.02,0.04,0.04,0.06l77.86,118.48c1.26,1.92,3.2,3.24,5.45,3.71
					c2.25,0.47,4.55,0.03,6.48-1.24l38.28-25.16c1.92-1.26,3.24-3.2,3.7-5.45c0.47-2.25,0.03-4.55-1.24-6.48l-77.86-118.48
					c-0.01-0.02-0.03-0.04-0.04-0.06c-7.42-9.42-19.34-13.8-31.11-11.42c-0.41,0.08-0.81,0.17-1.22,0.27
					c-0.95,0.23-1.96-0.16-2.5-0.99l-36.2-55.09c-0.1-0.16-0.26-0.27-0.45-0.3C1267.38,1070.36,1267.34,1070.35,1267.29,1070.35z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1367.76,1291.13c-1.03,0-2.07-0.16-3.09-0.48l0.49-1.56c2.48,0.78,5.15,0.41,7.32-1.02l15.3-10.05l0.9,1.36
					l-15.3,10.05C1371.68,1290.55,1369.73,1291.13,1367.76,1291.13z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1365.21,1287.24c-0.7,0-1.4-0.07-2.1-0.22l0.33-1.6c2.26,0.47,4.55,0.03,6.48-1.24l3.89-2.56l0.89,1.36l-3.89,2.56
					C1369.13,1286.66,1367.19,1287.24,1365.21,1287.24z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1333.6,1143.48c-5.36-6.84-14.34-10.15-22.87-8.42l-0.32-1.6c9.27-1.88,18.65,1.58,24.48,9.01L1333.6,1143.48z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1247.44,1082c-0.27,0-0.54-0.03-0.81-0.08c-1.03-0.21-1.92-0.82-2.5-1.7l-4.89-7.45c-1.2-1.82-0.69-4.27,1.13-5.47
					l20.18-13.26c0.88-0.58,1.94-0.78,2.97-0.57c1.03,0.21,1.92,0.82,2.5,1.7l4.89,7.45c1.2,1.82,0.69,4.27-1.13,5.47l-20.18,13.26
					C1248.95,1081.78,1248.2,1082,1247.44,1082z M1262.71,1055.02c-0.45,0-0.89,0.13-1.27,0.38l-20.18,13.26
					c-1.07,0.7-1.37,2.14-0.66,3.21l4.89,7.45c0.34,0.52,0.86,0.87,1.47,1c0.61,0.13,1.23,0.01,1.74-0.33l20.18-13.26
					c1.07-0.7,1.37-2.14,0.66-3.21l-4.89-7.45c-0.34-0.52-0.86-0.87-1.47-1C1263.03,1055.04,1262.87,1055.02,1262.71,1055.02z"
                        />
                    </g>
                </g>
                <path
                    className="st0"
                    d="M1315.03,1216.34l29.26,44.53c13.36-5.83,33.07-16.53,52.58-34.78l-37.36-56.85c-19.48,18.17-39.15,28.9-52.58,34.78
			l4.04,6.15c5.98-2.93,12.02-6.19,17.97-9.72l0.83,1.4c-5.93,3.51-11.94,6.76-17.9,9.69l2.25,3.42c5.97-2.92,12.02-6.19,17.97-9.72
			c0.1-0.06,10.3-6.16,12.26-7.81l1.05,1.25c-2.06,1.73-12.05,7.71-12.47,7.96C1327,1210.17,1320.98,1213.42,1315.03,1216.34z"
                />
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="1255.94"
                            y="1072.14"
                            transform="matrix(0.8357 -0.5492 0.5492 0.8357 -387.3716 873.2375)"
                            width="19.74"
                            height="23.84"
                        />
                    </g>
                    <g>
                        <path
                            className="st0"
                            d="M1263.87,1100.57l-13.99-21.29l17.86-11.74l13.99,21.29L1263.87,1100.57z M1252.14,1079.75l12.2,18.57l15.14-9.95
					l-12.2-18.57L1252.14,1079.75z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1103.74,1249.58c-1.5-0.45-3.08-0.69-4.73-0.69c-9.07,0-16.42,7.35-16.42,16.42c0,2.49,0.55,4.85,1.55,6.96l-0.92,1.06
					l-18.05,3.27l10.56-16.9c0,0-26.12,2.3-26.69,2.3c-0.58,0,13.06-15.75,13.06-15.75l-19.39-21.32l25.16,6.34v-22.66l20.16,20.36
					l9.03-17.48L1103.74,1249.58z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1115.13,1352.26c-9.5,0-17.24-7.73-17.24-17.24c0-2.45,0.5-4.81,1.49-7.02l0.24-0.53l0.58,0.05
						c0.45,0.04,0.92,0.06,1.39,0.06c6.15,0,11.75-3.63,14.26-9.25l0.24-0.53l0.58,0.05c8.81,0.77,15.7,8.31,15.7,17.17
						C1132.37,1344.53,1124.64,1352.26,1115.13,1352.26z M1100.66,1329.19c-0.75,1.85-1.13,3.81-1.13,5.84
						c0,8.6,7,15.61,15.61,15.61s15.61-7,15.61-15.61c0-7.83-5.95-14.52-13.65-15.48c-2.88,5.89-8.9,9.67-15.49,9.67
						C1101.28,1329.21,1100.97,1329.2,1100.66,1329.19z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1100.06,1329.14c-8.81-0.77-15.7-8.31-15.7-17.17c0-9.5,7.73-17.24,17.24-17.24s17.24,7.73,17.24,17.24
						c0,2.45-0.5,4.81-1.49,7.02l-1.49-0.66c0.9-2.01,1.35-4.15,1.35-6.36c0-8.6-7-15.61-15.61-15.61s-15.61,7-15.61,15.61
						c0,8.02,6.24,14.84,14.22,15.54L1100.06,1329.14z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1130.98,1331.23h-0.08v-1.63h0.08c8.6,0,15.61-7,15.61-15.61c0-3.75-1.35-7.38-3.81-10.22
						c-2.97-3.42-7.27-5.39-11.8-5.39c-0.19,0-0.38,0-0.57,0.01l-0.06-1.63c0.21-0.01,0.42-0.01,0.63-0.01c5,0,9.75,2.17,13.03,5.95
						c2.71,3.13,4.21,7.14,4.21,11.29C1148.22,1323.5,1140.48,1331.23,1130.98,1331.23z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1117.51,1308.31l-0.22-1.61c7.67-1.06,13.45-7.7,13.45-15.46c0-8.6-7-15.61-15.61-15.61s-15.61,7-15.61,15.61
						c0,1.46,0.2,2.89,0.59,4.25l-1.57,0.45c-0.43-1.51-0.65-3.09-0.65-4.7c0-9.5,7.73-17.24,17.24-17.24s17.24,7.73,17.24,17.24
						C1132.37,1299.8,1125.98,1307.14,1117.51,1308.31z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1086.68,1305.6c-8.19-1.49-14.13-8.62-14.13-16.95c0-9.5,7.73-17.24,17.23-17.24c5.82,0,11.21,2.91,14.41,7.78
						l-1.36,0.9c-2.9-4.41-7.78-7.05-13.05-7.05c-8.6,0-15.6,7-15.6,15.61c0,7.55,5.38,14,12.79,15.35L1086.68,1305.6z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1143.48,1304.05l-0.17-1.62c7.94-0.84,13.94-7.52,13.94-15.52c0-8.6-7-15.61-15.61-15.61
						c-6.03,0-11.41,3.37-14.04,8.79l-1.47-0.71c2.87-5.9,8.95-9.71,15.51-9.71c9.5,0,17.24,7.73,17.24,17.24
						C1158.87,1295.75,1152.25,1303.12,1143.48,1304.05z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1140.76,1270.98l-1.41-0.81c1.34-2.34,2.05-5.02,2.05-7.74c0-8.6-7-15.61-15.61-15.61c-5.7,0-10.94,3.1-13.69,8.1
						l-1.43-0.78c3.03-5.52,8.82-8.95,15.11-8.95c9.5,0,17.24,7.73,17.24,17.24C1143.03,1265.43,1142.25,1268.39,1140.76,1270.98z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1112.86,1275.56l-1.31-0.97c0.41-0.56,0.8-1.16,1.14-1.78c1.25-2.29,1.92-4.88,1.92-7.5c0-3.77-1.36-7.4-3.83-10.24
						c-1.94-2.23-4.46-3.86-7.28-4.7c-1.44-0.44-2.96-0.66-4.5-0.66c-8.6,0-15.61,7-15.61,15.61c0,2.32,0.49,4.54,1.47,6.61
						c0.12,0.27,0.25,0.52,0.38,0.77l-1.43,0.78c-0.15-0.28-0.3-0.57-0.43-0.86c-1.07-2.28-1.62-4.74-1.62-7.3
						c0-9.5,7.73-17.24,17.24-17.24c1.7,0,3.37,0.24,4.97,0.73c3.11,0.93,5.89,2.73,8.04,5.2c2.73,3.14,4.23,7.15,4.23,11.31
						c0,2.89-0.73,5.76-2.12,8.29C1113.74,1274.28,1113.32,1274.94,1112.86,1275.56z"
                            />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1112.69,1252.49c-2.1-4.93-2.4-8.9-2.28-11.64c0.22-5.05,1.91-6.82,2.86-14.58c0.43-3.53,0.71-5.81,0.29-8.93
					c-0.39-2.91-1.04-7.73-4.9-11.81c-4.24-4.49-9.68-5.35-11.63-5.51c-3.93-0.32-7.09,0.32-7.09,0.32
					c21.03,0.58,23.33,19.3,19.3,30.82s1.15,22.76,1.15,22.76l1.01,0.6l1.59-2.19"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1111.68,1226.28c0,0,9.36-3.02,8.79-12.96c-0.58-9.94-3.17-13.25-3.17-13.25s3.89,3.6,4.61,7.06
					c0.72,3.46,2.59,11.52-2.45,15.99c-5.04,4.47-7.49,6.34-8.21,9.22L1111.68,1226.28z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="1087.27" y="1319.18" width="15.15" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="1090.45" y="1323.21" width="11.97" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="1084.94" y="1310.92" width="17.48" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="1085.62" y="1314.95" width="16.8" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="1087.91" y="1302.09" width="14.5" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="1085.96" y="1306.12" width="16.45" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <rect className="st0" x="1075.55" y="1295.56" width="12.22" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="1078.73" y="1299.59" width="5.44" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="1073.22" y="1287.3" width="17.48" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="1073.9" y="1291.33" width="16.8" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="1076.2" y="1278.47" width="7.97" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="1074.25" y="1282.5" width="13.38" height="1.63" />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <rect className="st0" x="1081.17" y="1266.9" width="12.22" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="1078.84" y="1258.64" width="17.48" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="1079.52" y="1262.67" width="16.8" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="1079.87" y="1253.84" width="13.38" height="1.63" />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="1098.72" y="1336.8" width="15.99" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="1099.72" y="1340.83" width="11.52" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <polygon
                            className="st0"
                            points="1174.22,1219.88 1158.64,1186.42 1147.23,1210.4 1133.39,1163.27 1134.95,1162.81 1147.59,1205.85 
					1158.66,1182.59 1174.19,1215.96 1185.2,1191.45 1215.97,1192.29 1197.86,1169.72 1217.85,1151.89 1176.9,1156.37 
					1186.9,1134.44 1136.77,1160.04 1136.03,1158.59 1190.32,1130.86 1179.57,1154.44 1222.74,1149.71 1200.1,1169.9 
					1219.44,1194.01 1186.25,1193.11 				"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1136.38,1159.34l-2.8,1.26l0.58,2.44c-1.7-1.27-4.33-2.9-8.36-4.8c-9.79-4.61-5.57-14.98-5.57-14.98
					s0.24,2.64,1.08,5.45c1.18,3.95,4.07,5.97,4.68,6.46c1.85,1.47,3.54,2.08,5.76,2.88c1.73,0.62,3.31,1.01,4.64,1.26
					L1136.38,1159.34z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1079.27,1180.14c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
					C1084.7,1177.7,1082.26,1180.14,1079.27,1180.14z M1079.27,1170.92c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79
					c2.09,0,3.79-1.7,3.79-3.79S1081.36,1170.92,1079.27,1170.92z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1038.37,1297.96c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
					C1043.79,1295.52,1041.36,1297.96,1038.37,1297.96z M1038.37,1288.74c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79
					c2.09,0,3.79-1.7,3.79-3.79S1040.46,1288.74,1038.37,1288.74z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1164.54,1266.27c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42s5.42,2.43,5.42,5.42
					S1167.53,1266.27,1164.54,1266.27z M1164.54,1257.05c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79c2.09,0,3.79-1.7,3.79-3.79
					S1166.63,1257.05,1164.54,1257.05z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1162.24,1330.51c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
					S1165.23,1330.51,1162.24,1330.51z M1162.24,1321.29c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79c2.09,0,3.79-1.7,3.79-3.79
					S1164.33,1321.29,1162.24,1321.29z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <polygon
                            className="st0"
                            points="1217.41,1193.91 1139.05,1163.37 1133.32,1161.36 1133.86,1159.83 1139.62,1161.84 1218,1192.39 				"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <polygon
                            className="st0"
                            points="1170.21,1187.64 1167.82,1173.51 1180.79,1167.5 1181.48,1168.98 1169.64,1174.47 1171.82,1187.37 				"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <polygon
                            className="st0"
                            points="1149.93,1179.85 1148.3,1179.68 1149.66,1166.68 1157.95,1159.26 1159.04,1160.47 1151.22,1167.47 				"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1300.62,1332.3l-3.25,13.48c-0.08,0.38-0.16,0.76-0.25,1.14c-3.67,15.22-18.99,24.59-34.21,20.92
					c-15.23-3.67-24.59-18.99-20.92-34.22c0.1-0.4,0.2-0.8,0.32-1.2l3.23-13.4c0,0,8.64,15.52,20.14,15.33
					S1285.43,1318.57,1300.62,1332.3z"
                        />
                    </g>
                    <g>
                        <path
                            className="st0"
                            d="M1269.55,1369.45c-2.26,0-4.55-0.26-6.84-0.82c-7.58-1.83-13.99-6.49-18.05-13.14c-4.07-6.65-5.3-14.48-3.47-22.06
					c0.1-0.42,0.21-0.83,0.33-1.23l3.71-15.4l1.02,1.82c0.08,0.15,8.44,14.91,19.22,14.91c0.06,0,0.13,0,0.19,0
					c3.74-0.06,7.12-1.83,10.69-3.71c7.07-3.71,14.39-7.55,24.81,1.88l0.36,0.32l-3.36,13.95c-0.07,0.35-0.15,0.75-0.25,1.14
					C1294.7,1360.46,1282.72,1369.45,1269.55,1369.45z M1245.86,1321.13l-2.77,11.49c-0.12,0.42-0.22,0.81-0.32,1.2
					c-1.72,7.15-0.56,14.55,3.28,20.82c3.84,6.28,9.89,10.68,17.04,12.41c14.76,3.56,29.67-5.56,33.23-20.32
					c0.09-0.37,0.17-0.74,0.24-1.11l3.14-13.03c-9.38-8.25-15.8-4.88-22.6-1.32c-3.59,1.88-7.3,3.83-11.42,3.9
					C1256.06,1335.32,1248.54,1325.25,1245.86,1321.13z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1264.26,1324.95c-11.6,0-16.45-14.38-16.5-14.53l-0.07-0.22l6.6-27.39l1.58,0.38l-6.49,26.93
					c0.65,1.78,5.59,14.19,16.17,13.13l0.16,1.62C1265.22,1324.92,1264.73,1324.95,1264.26,1324.95z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1264.82,1308.39c-0.1-0.01-10.25-0.71-13.69-12l1.56-0.47c3.09,10.17,11.85,10.82,12.22,10.84L1264.82,1308.39z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1249.58,1450.65l-1.62-0.14c2.67-30.48,10.63-55.61,15.14-67.87c0.43-1.17,1.59-1.93,2.85-1.84
						c17.88,1.31,33.91-10.41,38.12-27.87c0.12-0.49,0.23-0.98,0.32-1.47l16.13-66.92c0.07-0.31,0.02-0.63-0.14-0.9
						c-0.17-0.27-0.43-0.46-0.74-0.54l-68.64-16.55c-0.31-0.07-0.63-0.02-0.9,0.14c-0.27,0.17-0.46,0.43-0.54,0.74l-16.1,66.79
						c-0.15,0.53-0.29,1.03-0.42,1.58c-4.21,17.45,4.72,35.19,21.23,42.18c1.15,0.49,1.85,1.7,1.7,2.94
						c-1.58,12.97-5.94,38.97-17.45,67.32l-1.51-0.61c11.44-28.18,15.78-54.01,17.34-66.9c0.06-0.53-0.22-1.03-0.72-1.24
						c-17.25-7.3-26.57-25.83-22.17-44.06c0.14-0.57,0.28-1.1,0.43-1.62l16.09-66.75c0.18-0.73,0.63-1.35,1.27-1.74
						c0.64-0.39,1.4-0.51,2.13-0.34l68.64,16.55c0.73,0.18,1.35,0.63,1.74,1.27c0.39,0.64,0.51,1.4,0.34,2.13l-16.13,66.88
						c-0.09,0.48-0.2,0.99-0.33,1.5c-4.4,18.23-21.15,30.48-39.82,29.11c-0.53-0.04-1.02,0.28-1.2,0.77
						C1260.15,1395.39,1252.24,1420.36,1249.58,1450.65z"
                            />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1268.92,1468.26c-8.69,0-18.95-1.34-29.48-3.88c-12.57-3.03-24.03-7.41-32.27-12.33c-8.5-5.08-12.62-10.23-11.59-14.49
					c1.96-8.14,22.46-10.17,47.68-4.7l-0.34,1.59c-23.53-5.1-44.06-3.53-45.75,3.49c-0.84,3.46,3.12,8.1,10.84,12.71
					c8.1,4.84,19.4,9.15,31.81,12.15c12.41,2.99,24.44,4.3,33.85,3.69c8.98-0.59,14.61-2.91,15.44-6.37
					c1.68-6.96-15.71-17.67-38.76-23.86l0.42-1.57c11.65,3.13,22.14,7.41,29.52,12.06c7.79,4.9,11.39,9.66,10.4,13.76
					c-1.03,4.27-7.04,6.97-16.92,7.62C1272.22,1468.21,1270.6,1468.26,1268.92,1468.26z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="1211.51"
                            y="1425.17"
                            transform="matrix(0.2344 -0.9721 0.9721 0.2344 -473.2627 2282.2566)"
                            width="1.63"
                            height="32.84"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="1207.37"
                            y="1436.07"
                            transform="matrix(0.2344 -0.9721 0.9721 0.2344 -480.9414 2281.7656)"
                            width="1.63"
                            height="20.31"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="1211"
                            y="1425.32"
                            transform="matrix(0.2344 -0.9721 0.9721 0.2344 -466.9026 2276.459)"
                            width="1.63"
                            height="18.68"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1416.53,1156.1c-0.93,0.73-2.11,1.15-3.27,1.23l-0.57,0.05c-0.17,0.02-0.35,0.03-0.52,0.04
						c-0.35,0.02-0.7,0.02-1.05,0l-4.19-0.39l-2.09-0.22l-0.26-0.03c-0.08-0.01-0.2-0.02-0.22-0.03c-0.1-0.02-0.2-0.03-0.3-0.03
						c-0.21-0.01-0.42,0.02-0.63,0.08c-0.21,0.06-0.42,0.16-0.62,0.29c-0.1,0.07-0.19,0.14-0.28,0.22l-0.13,0.14l-0.17,0.2
						l-1.34,1.63c-0.91,1.08-1.8,2.17-2.73,3.23c0.49-1.32,1.02-2.63,1.53-3.94l0.79-1.96l0.1-0.25c0.04-0.09,0.1-0.2,0.15-0.3
						c0.11-0.2,0.24-0.4,0.38-0.58c0.28-0.38,0.63-0.71,1.03-0.99c0.39-0.28,0.84-0.5,1.31-0.65c0.24-0.07,0.48-0.13,0.73-0.17
						l0.55-0.09l2.08-0.35l4.15-0.66c0.35-0.05,0.69-0.07,1.04-0.07c0.17,0,0.35,0,0.52,0l0.26,0.01l0.13,0l0.06,0l0.02-0.01
						c0.23,0.01,0.41-0.07,0.58-0.19c0.16-0.13,0.29-0.32,0.36-0.53c0.06-0.21,0.05-0.41,0-0.61c0-0.05-0.05-0.1-0.06-0.16
						c0,0-0.02-0.05-0.04-0.08l-0.06-0.12l-0.12-0.24l-0.47-0.93l-0.94-1.86l-1.88-3.72l-3.76-7.45l-0.12-0.23
						c-0.04-0.08-0.07-0.13-0.14-0.29c-0.11-0.27-0.24-0.53-0.31-0.82c-0.09-0.28-0.16-0.57-0.19-0.87
						c-0.05-0.29-0.06-0.59-0.06-0.89c0.01-1.19,0.41-2.35,1.08-3.3c0.67-0.96,1.67-1.71,2.78-2.11c0.56-0.21,1.14-0.31,1.72-0.34
						l1.07-0.04l8.34-0.27l4.17-0.13l2.08-0.06c0.06,0,0.05,0,0.07-0.01l0.04-0.01l0.08,0c0.05-0.03,0.11-0.02,0.15-0.04
						c0.04-0.02,0.1-0.03,0.14-0.06c0.02-0.01,0.05-0.02,0.07-0.03c0.02-0.02,0.04-0.04,0.06-0.05c0.17-0.11,0.31-0.31,0.38-0.51
						c0.08-0.21,0.08-0.41,0.02-0.61c-0.01-0.05-0.03-0.1-0.06-0.15c0.01-0.01-0.11-0.23-0.18-0.37l-0.47-0.93l-0.94-1.86
						l-3.76-7.45l-1.88-3.72l-0.24-0.47l-0.06-0.12l-0.03-0.06l-0.04-0.1l-0.17-0.4c-0.12-0.26-0.18-0.56-0.26-0.84
						c-0.06-0.29-0.1-0.59-0.13-0.88c-0.07-1.19,0.22-2.38,0.82-3.39c0.61-1,1.52-1.83,2.6-2.32c0.55-0.23,1.12-0.4,1.7-0.47
						l0.44-0.04c0.08-0.01,0.12-0.01,0.16-0.01l0.13,0l0.52-0.02l4.17-0.13l1.04-0.03l0.52-0.02l0.12,0l0.04,0
						c0.03,0,0.05-0.01,0.08-0.01c0.21-0.03,0.41-0.13,0.56-0.27c0.04-0.03,0.07-0.08,0.11-0.12l0.05-0.07
						c0.02-0.03,0.01-0.01,0.07-0.11l3.73-6.28c0.35-0.6,0.72-1.19,1.05-1.8c0.17-0.3,0.32-0.62,0.49-0.92
						c0.18-0.3,0.37-0.59,0.58-0.87c0.69-0.88,1.38-1.77,2.07-2.65c0.11-0.15,0.23-0.29,0.34-0.44c0.12-0.15,0.23-0.29,0.35-0.44
						l3.13,1.86c-0.07,0.17-0.14,0.34-0.22,0.51c-0.07,0.17-0.14,0.34-0.22,0.51c-0.44,1.03-0.89,2.06-1.34,3.09
						c-0.15,0.32-0.3,0.63-0.49,0.93c-0.18,0.3-0.38,0.58-0.57,0.87c-0.37,0.59-0.72,1.19-1.07,1.79l-3.73,6.28
						c-0.03,0.05-0.13,0.22-0.21,0.33c-0.08,0.12-0.17,0.24-0.26,0.36c-0.18,0.23-0.38,0.45-0.6,0.65
						c-0.86,0.81-1.97,1.34-3.13,1.52c-0.15,0.02-0.29,0.04-0.44,0.05l-0.22,0.02l-0.14,0.01l-0.52,0.02l-1.04,0.03l-4.17,0.13
						l-0.52,0.02l-0.13,0c-0.04,0-0.09,0-0.09,0.01c-0.03,0-0.05,0.01-0.08,0.01c-0.11,0-0.21,0.04-0.31,0.08
						c-0.18,0.09-0.34,0.23-0.46,0.42c-0.11,0.19-0.16,0.41-0.15,0.61c0.01,0.05,0.01,0.1,0.02,0.15c0.03,0.05,0.02,0.1,0.05,0.15
						c0.01,0.02,0.02,0.05,0.03,0.08l0.01,0.02l0.03,0.05l0.06,0.12l0.23,0.47l1.88,3.73l3.76,7.45l0.94,1.86l0.47,0.93
						c0.08,0.17,0.14,0.25,0.27,0.57c0.12,0.27,0.23,0.54,0.3,0.82c0.32,1.14,0.28,2.39-0.12,3.49c-0.39,1.11-1.11,2.09-2.07,2.78
						l-0.37,0.25c-0.13,0.08-0.26,0.15-0.39,0.21c-0.26,0.15-0.54,0.25-0.82,0.35c-0.28,0.1-0.57,0.15-0.86,0.22
						c-0.14,0.02-0.29,0.04-0.43,0.05l-0.22,0.02c-0.07,0-0.16,0.01-0.19,0.01l-2.09,0.07l-4.17,0.13l-8.35,0.26l-1.02,0.03
						c-0.12,0.02-0.22,0.02-0.32,0.06c-0.18,0.07-0.37,0.18-0.49,0.37c-0.12,0.18-0.21,0.39-0.2,0.6c0,0.05,0,0.1,0.01,0.15
						c0,0.05,0.02,0.1,0.03,0.15c0,0.06,0.05,0.1,0.06,0.16c0,0,0.05,0.11,0.09,0.18l0.12,0.23l3.76,7.45l1.88,3.72l0.94,1.86
						l0.47,0.93l0.18,0.35c0.02,0.04,0.03,0.07,0.07,0.15c0.11,0.27,0.24,0.53,0.32,0.82c0.36,1.12,0.36,2.38,0,3.5
						C1418.15,1154.37,1417.46,1155.38,1416.53,1156.1z"
                            />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1479.04,1100.66c-2.05,0-4.07-0.55-5.88-1.62c-5.49-3.29-7.28-10.38-4.04-15.84c3.24-5.46,10.32-7.28,15.79-4.07
					c2.7,1.6,4.58,4.12,5.35,7.11c0.76,2.99,0.31,6.1-1.26,8.76l0,0c-1.58,2.65-4.09,4.54-7.08,5.3
					C1480.96,1100.54,1480,1100.66,1479.04,1100.66z M1479.07,1079.18c-3.4,0-6.7,1.74-8.55,4.85c-2.78,4.69-1.24,10.78,3.43,13.58
					c2.32,1.38,4.99,1.76,7.56,1.11c2.57-0.65,4.73-2.27,6.08-4.55l0,0c1.35-2.28,1.74-4.95,1.08-7.52
					c-0.66-2.57-2.27-4.73-4.55-6.08C1482.54,1079.62,1480.79,1079.18,1479.07,1079.18z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1473.12,1099.01l-46.79-27.78c-2.65-1.58-4.54-4.09-5.3-7.08c-0.76-2.99-0.32-6.1,1.26-8.75
					c1.58-2.66,4.09-4.54,7.08-5.3c2.99-0.76,6.1-0.31,8.76,1.26l46.79,27.78l-0.83,1.4c-4.7-2.76-10.78-1.19-13.56,3.49
					c-2.78,4.69-1.24,10.78,3.43,13.58L1473.12,1099.01z M1432.24,1051.36c-0.82,0-1.65,0.1-2.47,0.31
					c-2.57,0.65-4.73,2.27-6.08,4.55c-1.35,2.28-1.74,4.95-1.08,7.52c0.66,2.57,2.27,4.73,4.55,6.08l41.76,24.79
					c-1.87-3.46-1.94-7.8,0.21-11.42c2.15-3.62,5.99-5.64,9.93-5.65l-41.76-24.79C1435.74,1051.83,1434,1051.36,1432.24,1051.36z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1473.11,1099c-5.44-3.26-7.23-10.35-3.99-15.81c3.24-5.46,10.32-7.28,15.79-4.07l-0.83,1.4
					c-4.7-2.76-10.78-1.19-13.56,3.49c-2.78,4.69-1.24,10.78,3.43,13.58L1473.11,1099z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="1437.78"
                            y="1082.16"
                            transform="matrix(0.8599 0.5105 -0.5105 0.8599 757.9643 -585.0184)"
                            width="13.77"
                            height="12.12"
                        />
                    </g>
                    <g>
                        <path
                            className="st0"
                            d="M1447.78,1098.06l-13.24-7.86l7.02-11.82l13.24,7.86L1447.78,1098.06z M1436.77,1089.63l10.44,6.2l5.36-9.02l-10.44-6.2
					L1436.77,1089.63z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1091.31,1131.23c-47.23-24.74-57.82-87.48-59.47-99.81l1.62-0.22c1.62,12.19,12.09,74.21,58.61,98.58L1091.31,1131.23z
					"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1032.89,1032.33l-0.43-0.2c-0.13-0.06-13.34-6.55-4.37-28.18l0.51-1.23l0.86,1.02c0.4,0.47,9.69,11.53,8.51,20.73
					c-0.4,3.14-1.99,5.7-4.7,7.59L1032.89,1032.33z M1029.08,1005.88c-6.62,17.21,1.63,23.36,3.66,24.56
					c2.08-1.59,3.29-3.66,3.62-6.17C1037.25,1017.35,1031.35,1008.84,1029.08,1005.88z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1038.01,1042.9c-1.2,0-2.42-0.2-3.67-0.61l-0.45-0.15l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.41l1.29-0.33l-0.3,1.3
					c-0.14,0.6-3.41,14.67-11.47,19.25C1041.57,1042.43,1039.83,1042.9,1038.01,1042.9z M1035.32,1040.88
					c2.52,0.71,4.91,0.43,7.11-0.81c6.07-3.44,9.33-13.27,10.34-16.86C1035.03,1028.25,1035.07,1038.54,1035.32,1040.88z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1041.94,1058.29c-1.2,0-2.42-0.2-3.67-0.61l-0.45-0.15l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.41l1.29-0.33l-0.3,1.3
					c-0.14,0.6-3.41,14.67-11.47,19.25C1045.51,1057.82,1043.76,1058.29,1041.94,1058.29z M1039.26,1056.27
					c2.52,0.71,4.91,0.43,7.11-0.81c6.07-3.44,9.33-13.27,10.34-16.86C1038.96,1043.64,1039,1053.93,1039.26,1056.27z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1047.74,1074.77c-1.2,0-2.42-0.2-3.67-0.61l-0.45-0.15l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.41l1.29-0.33
					l-0.29,1.3c-0.14,0.6-3.41,14.67-11.48,19.25C1051.3,1074.3,1049.55,1074.77,1047.74,1074.77z M1045.05,1072.75
					c2.52,0.71,4.91,0.43,7.11-0.81c6.07-3.44,9.33-13.27,10.34-16.86C1044.75,1060.12,1044.79,1070.41,1045.05,1072.75z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1055.47,1090.99c-1.2,0-2.42-0.2-3.67-0.61l-0.45-0.15l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.4l1.29-0.33l-0.3,1.3
					c-0.14,0.6-3.41,14.67-11.48,19.24C1059.04,1090.52,1057.29,1090.99,1055.47,1090.99z M1052.79,1088.97
					c2.52,0.71,4.91,0.43,7.11-0.81c6.07-3.43,9.33-13.26,10.34-16.85C1052.49,1076.34,1052.53,1086.63,1052.79,1088.97z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1064.79,1105.66c-1.2,0-2.42-0.2-3.67-0.61l-0.45-0.15l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.4l1.29-0.33l-0.3,1.3
					c-0.14,0.6-3.41,14.67-11.48,19.24C1068.35,1105.19,1066.6,1105.66,1064.79,1105.66z M1062.11,1103.65
					c2.52,0.7,4.9,0.43,7.1-0.81c6.07-3.44,9.33-13.26,10.34-16.85C1061.98,1090.98,1061.89,1101.34,1062.11,1103.65z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1027.66,1049.06c-0.12,0-0.24,0-0.37-0.01c-9.27-0.28-18.73-11.21-19.12-11.68l-0.87-1.01l1.3-0.31
					c22.78-5.44,27.09,8.63,27.13,8.77l0.13,0.45l-0.33,0.34C1033.32,1047.9,1030.67,1049.06,1027.66,1049.06z M1010.34,1037.33
					c2.57,2.7,10.04,9.88,17.01,10.09c2.54,0.08,4.77-0.8,6.67-2.59C1033.14,1042.64,1028.39,1033.52,1010.34,1037.33z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1033.32,1068.36c-0.12,0-0.24,0-0.37-0.01c-9.27-0.28-18.73-11.21-19.12-11.68l-0.87-1.01l1.3-0.31
					c22.78-5.44,27.09,8.62,27.13,8.77l0.13,0.45l-0.33,0.34C1038.99,1067.2,1036.34,1068.36,1033.32,1068.36z M1016.01,1056.63
					c2.57,2.7,10.04,9.88,17.01,10.09c2.54,0.07,4.77-0.8,6.67-2.59C1038.81,1061.94,1034.05,1052.82,1016.01,1056.63z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1040.25,1085.71c-0.12,0-0.24,0-0.36-0.01c-9.27-0.28-18.73-11.21-19.12-11.68l-0.87-1.01l1.3-0.31
					c22.78-5.44,27.09,8.62,27.13,8.77l0.13,0.45l-0.33,0.34C1045.91,1084.55,1043.26,1085.71,1040.25,1085.71z M1022.93,1073.99
					c2.57,2.7,10.04,9.88,17.01,10.09c2.52,0.07,4.77-0.8,6.66-2.59C1045.73,1079.29,1040.97,1070.17,1022.93,1073.99z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1049.31,1104.3c-8.92,0-18.77-8.75-19.2-9.14l-0.99-0.89l1.25-0.48c21.87-8.36,27.99,5.01,28.05,5.15l0.19,0.43
					l-0.28,0.38c-1.97,2.66-4.57,4.17-7.72,4.49C1050.16,1104.28,1049.74,1104.3,1049.31,1104.3z M1032.25,1094.83
					c2.9,2.34,11.24,8.49,18.18,7.79c2.52-0.25,4.62-1.41,6.27-3.44C1055.55,1097.11,1049.65,1088.69,1032.25,1094.83z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1057.09,1120.87c-7.57,0-15.58-3.74-16.01-3.94l-1.21-0.57l1.06-0.81c18.57-14.27,28.25-3.19,28.35-3.08l0.3,0.36
					l-0.16,0.44c-1.13,3.11-3.19,5.3-6.12,6.51C1061.4,1120.56,1059.26,1120.87,1057.09,1120.87z M1043.04,1116
					c3.45,1.42,13.2,4.92,19.65,2.27c2.34-0.96,4.03-2.67,5.02-5.09C1066.03,1111.53,1057.96,1105.15,1043.04,1116z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1401.46,1453.01c-1.53-6.09-3.51-12.35-5.89-18.6c-5.16-13.58-4.97-25.87,0.56-36.53c11-21.18,39.75-28.72,40.04-28.79
					l0.4,1.58c-0.28,0.07-28.34,7.44-39,27.97c-5.31,10.23-5.47,22.07-0.48,35.19c2.4,6.31,4.4,12.63,5.94,18.78L1401.46,1453.01z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1442.9,1373.37c-2.86,0-5.41-1.07-7.61-3.19l-0.34-0.33l0.12-0.46c0.04-0.14,3.89-14.34,26.83-9.65l1.31,0.27
					l-0.83,1.04c-0.38,0.48-9.48,11.71-18.73,12.3C1443.4,1373.36,1443.15,1373.37,1442.9,1373.37z M1436.8,1369.35
					c1.96,1.74,4.22,2.54,6.75,2.38c6.96-0.44,14.19-7.86,16.67-10.64C1442.05,1357.85,1437.59,1367.13,1436.8,1369.35z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1424.46,1374.85l-0.32-0.35c-0.1-0.11-9.83-11.14,6.71-27.71l0.94-0.94l0.41,1.27c0.19,0.58,4.56,14.36-0.05,22.41
					c-1.57,2.75-4.01,4.51-7.24,5.23L1424.46,1374.85z M1431.04,1348.94c-12.69,13.39-7.4,22.21-5.99,24.1
					c2.53-0.68,4.44-2.13,5.7-4.32C1434.21,1362.66,1432,1352.54,1431.04,1348.94z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1409.13,1383.8l-0.36-0.31c-0.11-0.1-11.03-9.96,3.54-28.29l0.83-1.04l0.55,1.21c0.25,0.56,6.15,13.75,2.48,22.27
					c-1.25,2.91-3.47,4.93-6.61,6.01L1409.13,1383.8z M1412.74,1357.32c-10.98,14.6-4.78,22.91-3.22,24.62
					c2.43-0.96,4.17-2.62,5.17-4.94C1417.44,1370.58,1414.1,1360.79,1412.74,1357.32z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1399.17,1395.44l-0.46-0.12c-0.14-0.04-14.29-4.09-9.27-26.97l0.28-1.3l1.03,0.85c0.47,0.39,11.58,9.64,12.03,18.9
					c0.16,3.16-0.95,5.96-3.29,8.31L1399.17,1395.44z M1390.75,1370.06c-3.48,18.11,5.73,22.7,7.94,23.53
					c1.76-1.93,2.59-4.19,2.47-6.71C1400.82,1379.92,1393.5,1372.58,1390.75,1370.06z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1392.38,1405.33c-4.26,0-13.93-2.03-19.08-19.28l-0.38-1.28l1.31,0.24c0.6,0.11,14.8,2.82,19.69,10.7
					c1.67,2.69,2.06,5.67,1.15,8.86l-0.13,0.45l-0.46,0.11C1394.45,1405.15,1393.65,1405.33,1392.38,1405.33z M1375.27,1386.92
					c5.74,17.53,16.02,17.08,18.35,16.73c0.61-2.54,0.24-4.92-1.09-7.07C1388.85,1390.65,1378.9,1387.79,1375.27,1386.92z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1387.92,1422.52c-4.94,0-12.63-2.44-19.68-14.88l-0.66-1.16l1.33-0.06c0.61-0.03,15.05-0.56,21.58,6.03
					c2.23,2.25,3.27,5.07,3.1,8.38l-0.02,0.47l-0.42,0.21C1393.1,1421.53,1391.06,1422.52,1387.92,1422.52z M1370.35,1408.04
					c9.51,15.8,19.43,13.06,21.63,12.2c0.02-2.61-0.87-4.85-2.65-6.64C1384.43,1408.65,1374.08,1408.08,1370.35,1408.04z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1433.95,1388.4c-4.18,0-8.84-0.66-12.23-2.98c-2.61-1.79-4.17-4.36-4.62-7.64l-0.06-0.47l0.37-0.29
					c0.12-0.09,11.9-8.89,27.08,8.94l0.86,1.02l-1.3,0.31C1443.72,1387.36,1439.21,1388.4,1433.95,1388.4z M1418.8,1378.05
					c0.47,2.57,1.76,4.59,3.85,6.02c5.76,3.94,16.01,2.56,19.68,1.89C1430.13,1372.37,1420.78,1376.85,1418.8,1378.05z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1414.74,1397.6c-2.26,0-4.48-0.32-6.46-1.16c-2.92-1.24-4.95-3.45-6.05-6.57l-0.16-0.44l0.31-0.36
					c0.1-0.11,9.9-11.08,28.31,3.39l1.05,0.82l-1.21,0.56C1430.11,1394.03,1422.24,1397.6,1414.74,1397.6z M1403.95,1389.79
					c0.97,2.43,2.64,4.16,4.97,5.15c6.42,2.72,16.2-0.67,19.67-2.05C1413.79,1381.87,1405.65,1388.16,1403.95,1389.79z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1405.33,1415.99c-2.26,0-4.48-0.32-6.46-1.16c-2.92-1.24-4.95-3.45-6.05-6.57l-0.16-0.44l0.31-0.36
					c0.1-0.11,9.9-11.08,28.31,3.39l1.05,0.82l-1.21,0.56C1420.7,1412.42,1412.82,1415.99,1405.33,1415.99z M1394.54,1408.18
					c0.97,2.43,2.64,4.16,4.97,5.15c6.42,2.72,16.2-0.67,19.67-2.05C1404.38,1400.26,1396.24,1406.55,1394.54,1408.18z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1401.75,1433.2c-2.75,0-5.24-1.01-7.41-3.02l-0.35-0.32l0.1-0.46c0.03-0.14,3.56-14.43,26.6-10.28l1.31,0.24
					l-0.81,1.06c-0.37,0.49-9.2,11.93-18.44,12.73C1402.42,1433.19,1402.08,1433.2,1401.75,1433.2z M1395.82,1429.33
					c1.99,1.69,4.28,2.43,6.79,2.21c6.94-0.6,14-8.19,16.41-11.03C1400.97,1417.75,1396.58,1427.14,1395.82,1429.33z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1154.34,1408.39c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C1159.76,1405.96,1157.33,1408.39,1154.34,1408.39z M1154.34,1399.17c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79S1156.43,1399.17,1154.34,1399.17z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1137.76,1461.22c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C1143.18,1458.79,1140.75,1461.22,1137.76,1461.22z M1137.76,1452c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79C1141.55,1453.7,1139.85,1452,1137.76,1452z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1350.13,1364.7c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C1355.55,1362.27,1353.12,1364.7,1350.13,1364.7z M1350.13,1355.49c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79S1352.22,1355.49,1350.13,1355.49z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1454.72,1302.84c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C1460.14,1300.41,1457.71,1302.84,1454.72,1302.84z M1454.72,1293.62c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79C1458.51,1295.33,1456.81,1293.62,1454.72,1293.62z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1257.65,1229.71c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C1263.08,1227.28,1260.64,1229.71,1257.65,1229.71z M1257.65,1220.49c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79S1259.75,1220.49,1257.65,1220.49z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1202.17,1113.43c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C1207.59,1111,1205.16,1113.43,1202.17,1113.43z M1202.17,1104.21c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79S1204.26,1104.21,1202.17,1104.21z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1310.87,1030.95c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42s5.42,2.43,5.42,5.42
				C1316.29,1028.51,1313.86,1030.95,1310.87,1030.95z M1310.87,1021.73c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79S1312.96,1021.73,1310.87,1021.73z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1130.74,1044.55c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				S1133.73,1044.55,1130.74,1044.55z M1130.74,1035.33c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79c2.09,0,3.79-1.7,3.79-3.79
				S1132.83,1035.33,1130.74,1035.33z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1452.81,1462.92c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				S1455.8,1462.92,1452.81,1462.92z M1452.81,1453.7c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79c2.09,0,3.79-1.7,3.79-3.79
				S1454.9,1453.7,1452.81,1453.7z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1344.39,1465.47c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C1349.81,1463.04,1347.38,1465.47,1344.39,1465.47z M1344.39,1456.25c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79C1348.18,1457.95,1346.48,1456.25,1344.39,1456.25z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1120.24,1453.41l-6.88,3.15l-0.3-0.92c-2.45-5.35-8.79-7.71-14.13-5.26c-5.35,2.45-7.71,8.78-5.26,14.13l0.02,0.05
				l0.42,0.77l-0.78,0.39l-44.17,20.19l-18.21-39.83l0.82-0.3c0.11-0.04,0.22-0.09,0.33-0.14c5.35-2.45,7.71-8.78,5.26-14.13
				c-2.45-5.35-8.78-7.71-14.13-5.26c-0.11,0.05-0.22,0.1-0.32,0.16l-0.77,0.43l-5.48-11.99l12.29-5.62l0.35,0.7
				c2.58,5.15,8.71,7.3,13.96,4.91s7.62-8.44,5.42-13.77l-0.3-0.73l39.36-18l3.15,6.89l-0.74,0.34c-5.36,2.45-7.72,8.79-5.28,14.14
				c2.45,5.35,8.78,7.71,14.13,5.26l0.74-0.34l0.35,0.73L1120.24,1453.41z M1114.25,1454.36l3.84-1.75l-19.13-41.85
				c-6.01,2.29-12.85-0.46-15.56-6.38c-2.71-5.92-0.3-12.9,5.35-15.94l-1.81-3.95l-36.47,16.67c2.04,5.95-0.72,12.48-6.53,15.13
				s-12.55,0.47-15.72-4.96l-9.4,4.3l4.1,8.96c6.09-2.56,13.17,0.18,15.93,6.23c2.76,6.05,0.2,13.19-5.72,16.12l16.83,36.81
				l41.95-19.18c-2.37-6.03,0.37-12.95,6.33-15.68C1104.21,1446.16,1111.24,1448.62,1114.25,1454.36z"
                    />
                </g>
                <g>
                    <path
                        className="st0"
                        d="M1068.87,1447.3c-7.24,3.31-15.82,0.11-19.13-7.13s-0.11-15.82,7.13-19.13s15.82-0.11,19.13,7.13
				S1076.11,1443.99,1068.87,1447.3z M1057.54,1422.52c-6.42,2.94-9.26,10.55-6.32,16.97c2.94,6.42,10.55,9.26,16.97,6.32
				s9.26-10.55,6.32-16.97C1071.58,1422.42,1063.97,1419.59,1057.54,1422.52z"
                    />
                </g>
                <g>
                    <path
                        className="st0"
                        d="M1066.21,1468.76c-2.97,1.36-6.48,0.05-7.84-2.92c-1.36-2.97-0.05-6.48,2.92-7.84c2.97-1.36,6.48-0.05,7.84,2.92
				C1070.49,1463.88,1069.18,1467.4,1066.21,1468.76z M1061.97,1459.47c-2.15,0.98-3.1,3.53-2.12,5.68c0.98,2.15,3.53,3.1,5.68,2.12
				c2.15-0.98,3.1-3.53,2.12-5.68S1064.12,1458.49,1061.97,1459.47z"
                    />
                </g>
                <g>
                    <path
                        className="st0"
                        d="M1093.52,1435.08c-2.97,1.36-6.48,0.05-7.84-2.92c-1.36-2.97-0.05-6.48,2.92-7.84c2.97-1.36,6.48-0.05,7.84,2.92
				C1097.79,1430.21,1096.48,1433.72,1093.52,1435.08z M1089.27,1425.8c-2.15,0.98-3.1,3.53-2.12,5.68c0.98,2.15,3.53,3.1,5.68,2.12
				c2.15-0.98,3.1-3.53,2.12-5.68C1093.97,1425.77,1091.42,1424.82,1089.27,1425.8z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <circle className="st0" cx="1799.67" cy="1315.13" r="4.61" />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1806.44,1294.68c0.17,0.52,1.79,5.47-1.01,8.07c-2.6,2.41-7.32,1.2-9.79-0.86c-1.51-1.26-1.27-2.08-2.88-4.47
					c-3.59-5.31-7.5-5.22-7.78-7.78c-0.44-3.98,8.47-9.09,10.66-7.35c1.47,1.17-0.79,4.87,1.15,6.63c1.55,1.4,3.66-0.33,6.34,1.15
					C1805.33,1291.3,1806.12,1293.72,1806.44,1294.68z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1789.87,1311.1c0.13,1.37-0.3,2.5-1.15,4.75c-1.22,3.2-1.83,4.8-3.02,5.04c-2.15,0.43-5.53-3.7-4.75-6.19
					c0.54-1.74,2.72-1.42,3.89-3.6c1.42-2.65-0.53-5.46,0.29-5.91C1786.02,1304.71,1789.53,1307.54,1789.87,1311.1z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1871.35,1296.57c-0.7,0-1.4-0.07-2.1-0.22c-2.68-0.55-4.98-2.12-6.49-4.41l-77.85-118.48
					c-0.04-0.06-0.08-0.12-0.11-0.18c-6-11.09-5.25-24.42,1.96-34.8c0.25-0.36,0.51-0.71,0.77-1.06c0.18-0.24,0.19-0.56,0.03-0.81
					l-36.2-55.09c-0.34-0.52-0.46-1.14-0.34-1.76c0.13-0.61,0.48-1.14,1-1.48l13.98-9.19c0.52-0.34,1.15-0.46,1.76-0.34
					c0.61,0.13,1.14,0.48,1.48,1l36.2,55.09c0.16,0.25,0.46,0.37,0.75,0.3c0.42-0.1,0.85-0.2,1.28-0.28
					c12.38-2.51,24.92,2.09,32.72,12.01c0.05,0.06,0.09,0.12,0.13,0.18l77.85,118.47c3.1,4.72,1.79,11.09-2.94,14.19l-38.28,25.16
					C1875.26,1295.99,1873.32,1296.57,1871.35,1296.57z M1767.29,1070.35c-0.14,0-0.27,0.04-0.39,0.12l-13.98,9.19
					c-0.16,0.1-0.27,0.26-0.3,0.45s0,0.37,0.1,0.53l36.2,55.09c0.54,0.82,0.51,1.9-0.09,2.69c-0.25,0.33-0.49,0.67-0.73,1.01
					c-6.86,9.87-7.57,22.55-1.87,33.09c0.01,0.02,0.02,0.04,0.04,0.06l77.86,118.48c1.26,1.92,3.2,3.24,5.45,3.71
					c2.25,0.47,4.55,0.03,6.48-1.24l38.28-25.16c1.92-1.26,3.24-3.2,3.7-5.45c0.47-2.25,0.03-4.55-1.24-6.48l-77.86-118.48
					c-0.01-0.02-0.03-0.04-0.04-0.06c-7.42-9.42-19.34-13.8-31.11-11.42c-0.41,0.08-0.81,0.17-1.22,0.27
					c-0.95,0.23-1.96-0.16-2.5-0.99l-36.2-55.09c-0.1-0.16-0.26-0.27-0.45-0.3C1767.38,1070.36,1767.34,1070.35,1767.29,1070.35z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1867.76,1291.13c-1.03,0-2.07-0.16-3.09-0.48l0.49-1.56c2.48,0.78,5.15,0.41,7.32-1.02l15.3-10.05l0.9,1.36
					l-15.3,10.05C1871.68,1290.55,1869.73,1291.13,1867.76,1291.13z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1865.21,1287.24c-0.7,0-1.4-0.07-2.1-0.22l0.33-1.6c2.26,0.47,4.55,0.03,6.48-1.24l3.89-2.56l0.89,1.36l-3.89,2.56
					C1869.13,1286.66,1867.19,1287.24,1865.21,1287.24z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1833.6,1143.48c-5.36-6.84-14.34-10.15-22.87-8.42l-0.32-1.6c9.27-1.88,18.65,1.58,24.48,9.01L1833.6,1143.48z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1747.44,1082c-0.27,0-0.54-0.03-0.81-0.08c-1.03-0.21-1.92-0.82-2.5-1.7l-4.89-7.45c-1.2-1.82-0.69-4.27,1.13-5.47
					l20.18-13.26c0.88-0.58,1.94-0.78,2.97-0.57c1.03,0.21,1.92,0.82,2.5,1.7l4.89,7.45c1.2,1.82,0.69,4.27-1.13,5.47l-20.18,13.26
					C1748.95,1081.78,1748.2,1082,1747.44,1082z M1762.71,1055.02c-0.45,0-0.89,0.13-1.27,0.38l-20.18,13.26
					c-1.07,0.7-1.37,2.14-0.66,3.21l4.89,7.45c0.34,0.52,0.86,0.87,1.47,1c0.61,0.13,1.23,0.01,1.74-0.33l20.18-13.26
					c1.07-0.7,1.37-2.14,0.66-3.21l-4.89-7.45c-0.34-0.52-0.86-0.87-1.47-1C1763.03,1055.04,1762.87,1055.02,1762.71,1055.02z"
                        />
                    </g>
                </g>
                <path
                    className="st0"
                    d="M1815.03,1216.34l29.26,44.53c13.36-5.83,33.07-16.53,52.58-34.78l-37.36-56.85c-19.48,18.17-39.15,28.9-52.58,34.78
			l4.04,6.15c5.98-2.93,12.02-6.19,17.97-9.72l0.83,1.4c-5.93,3.51-11.94,6.76-17.9,9.69l2.25,3.42c5.97-2.92,12.02-6.19,17.97-9.72
			c0.1-0.06,10.3-6.16,12.26-7.81l1.05,1.25c-2.06,1.73-12.05,7.71-12.47,7.96C1827,1210.17,1820.98,1213.42,1815.03,1216.34z"
                />
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="1755.94"
                            y="1072.14"
                            transform="matrix(0.8357 -0.5492 0.5492 0.8357 -305.2281 1147.8208)"
                            width="19.74"
                            height="23.84"
                        />
                    </g>
                    <g>
                        <path
                            className="st0"
                            d="M1763.87,1100.57l-13.99-21.29l17.86-11.74l13.99,21.29L1763.87,1100.57z M1752.14,1079.75l12.2,18.57l15.14-9.95
					l-12.2-18.57L1752.14,1079.75z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1603.74,1249.58c-1.5-0.45-3.08-0.69-4.73-0.69c-9.07,0-16.42,7.35-16.42,16.42c0,2.49,0.55,4.85,1.55,6.96l-0.92,1.06
					l-18.05,3.27l10.56-16.9c0,0-26.12,2.3-26.69,2.3c-0.58,0,13.06-15.75,13.06-15.75l-19.39-21.32l25.16,6.34v-22.66l20.16,20.36
					l9.03-17.48L1603.74,1249.58z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1615.13,1352.26c-9.5,0-17.24-7.73-17.24-17.24c0-2.45,0.5-4.81,1.49-7.02l0.24-0.53l0.58,0.05
						c0.45,0.04,0.92,0.06,1.39,0.06c6.15,0,11.75-3.63,14.26-9.25l0.24-0.53l0.58,0.05c8.81,0.77,15.7,8.31,15.7,17.17
						C1632.37,1344.53,1624.64,1352.26,1615.13,1352.26z M1600.66,1329.19c-0.75,1.85-1.13,3.81-1.13,5.84
						c0,8.6,7,15.61,15.61,15.61s15.61-7,15.61-15.61c0-7.83-5.95-14.52-13.65-15.48c-2.88,5.89-8.9,9.67-15.49,9.67
						C1601.28,1329.21,1600.97,1329.2,1600.66,1329.19z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1600.06,1329.14c-8.81-0.77-15.7-8.31-15.7-17.17c0-9.5,7.73-17.24,17.24-17.24s17.24,7.73,17.24,17.24
						c0,2.45-0.5,4.81-1.49,7.02l-1.49-0.66c0.9-2.01,1.35-4.15,1.35-6.36c0-8.6-7-15.61-15.61-15.61s-15.61,7-15.61,15.61
						c0,8.02,6.24,14.84,14.22,15.54L1600.06,1329.14z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1630.98,1331.23h-0.08v-1.63h0.08c8.6,0,15.61-7,15.61-15.61c0-3.75-1.35-7.38-3.81-10.22
						c-2.97-3.42-7.27-5.39-11.8-5.39c-0.19,0-0.38,0-0.57,0.01l-0.06-1.63c0.21-0.01,0.42-0.01,0.63-0.01c5,0,9.75,2.17,13.03,5.95
						c2.71,3.13,4.21,7.14,4.21,11.29C1648.22,1323.5,1640.48,1331.23,1630.98,1331.23z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1617.51,1308.31l-0.22-1.61c7.67-1.06,13.45-7.7,13.45-15.46c0-8.6-7-15.61-15.61-15.61s-15.61,7-15.61,15.61
						c0,1.46,0.2,2.89,0.59,4.25l-1.57,0.45c-0.43-1.51-0.65-3.09-0.65-4.7c0-9.5,7.73-17.24,17.24-17.24s17.24,7.73,17.24,17.24
						C1632.37,1299.8,1625.98,1307.14,1617.51,1308.31z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1586.68,1305.6c-8.19-1.49-14.13-8.62-14.13-16.95c0-9.5,7.73-17.24,17.23-17.24c5.82,0,11.21,2.91,14.41,7.78
						l-1.36,0.9c-2.9-4.41-7.78-7.05-13.05-7.05c-8.6,0-15.6,7-15.6,15.61c0,7.55,5.38,14,12.79,15.35L1586.68,1305.6z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1643.48,1304.05l-0.17-1.62c7.94-0.84,13.94-7.52,13.94-15.52c0-8.6-7-15.61-15.61-15.61
						c-6.03,0-11.41,3.37-14.04,8.79l-1.47-0.71c2.87-5.9,8.95-9.71,15.51-9.71c9.5,0,17.24,7.73,17.24,17.24
						C1658.87,1295.75,1652.25,1303.12,1643.48,1304.05z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1640.76,1270.98l-1.41-0.81c1.34-2.34,2.05-5.02,2.05-7.74c0-8.6-7-15.61-15.61-15.61c-5.7,0-10.94,3.1-13.69,8.1
						l-1.43-0.78c3.03-5.52,8.82-8.95,15.11-8.95c9.5,0,17.24,7.73,17.24,17.24C1643.03,1265.43,1642.25,1268.39,1640.76,1270.98z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1612.86,1275.56l-1.31-0.97c0.41-0.56,0.8-1.16,1.14-1.78c1.25-2.29,1.92-4.88,1.92-7.5c0-3.77-1.36-7.4-3.83-10.24
						c-1.94-2.23-4.46-3.86-7.28-4.7c-1.44-0.44-2.96-0.66-4.5-0.66c-8.6,0-15.61,7-15.61,15.61c0,2.32,0.49,4.54,1.47,6.61
						c0.12,0.27,0.25,0.52,0.38,0.77l-1.43,0.78c-0.15-0.28-0.3-0.57-0.43-0.86c-1.07-2.28-1.62-4.74-1.62-7.3
						c0-9.5,7.73-17.24,17.24-17.24c1.7,0,3.37,0.24,4.97,0.73c3.11,0.93,5.89,2.73,8.04,5.2c2.73,3.14,4.23,7.15,4.23,11.31
						c0,2.89-0.73,5.76-2.12,8.29C1613.74,1274.28,1613.32,1274.94,1612.86,1275.56z"
                            />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1612.69,1252.49c-2.1-4.93-2.4-8.9-2.28-11.64c0.22-5.05,1.91-6.82,2.86-14.58c0.43-3.53,0.71-5.81,0.29-8.93
					c-0.39-2.91-1.04-7.73-4.9-11.81c-4.24-4.49-9.68-5.35-11.63-5.51c-3.93-0.32-7.09,0.32-7.09,0.32
					c21.03,0.58,23.33,19.3,19.3,30.82s1.15,22.76,1.15,22.76l1.01,0.6l1.59-2.19"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1611.68,1226.28c0,0,9.36-3.02,8.79-12.96c-0.58-9.94-3.17-13.25-3.17-13.25s3.89,3.6,4.61,7.06
					c0.72,3.46,2.59,11.52-2.45,15.99c-5.04,4.47-7.49,6.34-8.21,9.22L1611.68,1226.28z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="1587.27" y="1319.18" width="15.15" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="1590.45" y="1323.21" width="11.97" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="1584.94" y="1310.92" width="17.48" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="1585.62" y="1314.95" width="16.8" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="1587.91" y="1302.09" width="14.5" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="1585.96" y="1306.12" width="16.45" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <rect className="st0" x="1575.55" y="1295.56" width="12.22" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="1578.73" y="1299.59" width="5.44" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="1573.22" y="1287.3" width="17.48" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="1573.9" y="1291.33" width="16.8" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="1576.2" y="1278.47" width="7.97" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="1574.25" y="1282.5" width="13.38" height="1.63" />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <rect className="st0" x="1581.17" y="1266.9" width="12.22" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="1578.84" y="1258.64" width="17.48" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="1579.52" y="1262.67" width="16.8" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="1579.87" y="1253.84" width="13.38" height="1.63" />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="1598.72" y="1336.8" width="15.99" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="1599.72" y="1340.83" width="11.52" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <polygon
                            className="st0"
                            points="1674.22,1219.88 1658.64,1186.42 1647.23,1210.4 1633.39,1163.27 1634.95,1162.81 1647.59,1205.85 
					1658.66,1182.59 1674.19,1215.96 1685.2,1191.45 1715.97,1192.29 1697.86,1169.72 1717.85,1151.89 1676.9,1156.37 
					1686.9,1134.44 1636.77,1160.04 1636.03,1158.59 1690.32,1130.86 1679.57,1154.44 1722.74,1149.71 1700.1,1169.9 
					1719.44,1194.01 1686.25,1193.11 				"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1636.38,1159.34l-2.8,1.26l0.58,2.44c-1.7-1.27-4.33-2.9-8.36-4.8c-9.79-4.61-5.57-14.98-5.57-14.98
					s0.24,2.64,1.08,5.45c1.18,3.95,4.07,5.97,4.68,6.46c1.85,1.47,3.54,2.08,5.76,2.88c1.73,0.62,3.31,1.01,4.64,1.26
					L1636.38,1159.34z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1579.27,1180.14c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
					C1584.7,1177.7,1582.26,1180.14,1579.27,1180.14z M1579.27,1170.92c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79
					c2.09,0,3.79-1.7,3.79-3.79S1581.36,1170.92,1579.27,1170.92z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1538.37,1297.96c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
					C1543.79,1295.52,1541.36,1297.96,1538.37,1297.96z M1538.37,1288.74c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79
					c2.09,0,3.79-1.7,3.79-3.79S1540.46,1288.74,1538.37,1288.74z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1664.54,1266.27c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42s5.42,2.43,5.42,5.42
					S1667.53,1266.27,1664.54,1266.27z M1664.54,1257.05c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79c2.09,0,3.79-1.7,3.79-3.79
					S1666.63,1257.05,1664.54,1257.05z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1662.24,1330.51c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
					S1665.23,1330.51,1662.24,1330.51z M1662.24,1321.29c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79c2.09,0,3.79-1.7,3.79-3.79
					S1664.33,1321.29,1662.24,1321.29z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <polygon
                            className="st0"
                            points="1717.41,1193.91 1639.05,1163.37 1633.32,1161.36 1633.86,1159.83 1639.62,1161.84 1718,1192.39 				"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <polygon
                            className="st0"
                            points="1670.21,1187.64 1667.82,1173.51 1680.79,1167.5 1681.48,1168.98 1669.64,1174.47 1671.82,1187.37 				"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <polygon
                            className="st0"
                            points="1649.93,1179.85 1648.3,1179.68 1649.66,1166.68 1657.95,1159.26 1659.04,1160.47 1651.22,1167.47 				"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1800.62,1332.3l-3.25,13.48c-0.08,0.38-0.16,0.76-0.25,1.14c-3.67,15.22-18.99,24.59-34.21,20.92
					c-15.23-3.67-24.59-18.99-20.92-34.22c0.1-0.4,0.2-0.8,0.32-1.2l3.23-13.4c0,0,8.64,15.52,20.14,15.33
					S1785.43,1318.57,1800.62,1332.3z"
                        />
                    </g>
                    <g>
                        <path
                            className="st0"
                            d="M1769.55,1369.45c-2.26,0-4.55-0.26-6.84-0.82c-7.58-1.83-13.99-6.49-18.05-13.14c-4.07-6.65-5.3-14.48-3.47-22.06
					c0.1-0.42,0.21-0.83,0.33-1.23l3.71-15.4l1.02,1.82c0.08,0.15,8.44,14.91,19.22,14.91c0.06,0,0.13,0,0.19,0
					c3.74-0.06,7.12-1.83,10.69-3.71c7.07-3.71,14.39-7.55,24.81,1.88l0.36,0.32l-3.36,13.95c-0.07,0.35-0.15,0.75-0.25,1.14
					C1794.7,1360.46,1782.72,1369.45,1769.55,1369.45z M1745.86,1321.13l-2.77,11.49c-0.12,0.42-0.22,0.81-0.32,1.2
					c-1.72,7.15-0.56,14.55,3.28,20.82c3.84,6.28,9.89,10.68,17.04,12.41c14.76,3.56,29.67-5.56,33.23-20.32
					c0.09-0.37,0.17-0.74,0.24-1.11l3.14-13.03c-9.38-8.25-15.8-4.88-22.6-1.32c-3.59,1.88-7.3,3.83-11.42,3.9
					C1756.06,1335.32,1748.54,1325.25,1745.86,1321.13z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1764.26,1324.95c-11.6,0-16.45-14.38-16.5-14.53l-0.07-0.22l6.6-27.39l1.58,0.38l-6.49,26.93
					c0.65,1.78,5.59,14.19,16.17,13.13l0.16,1.62C1765.22,1324.92,1764.73,1324.95,1764.26,1324.95z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1764.82,1308.39c-0.1-0.01-10.25-0.71-13.69-12l1.56-0.47c3.09,10.17,11.85,10.82,12.22,10.84L1764.82,1308.39z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1749.58,1450.65l-1.62-0.14c2.67-30.48,10.63-55.61,15.14-67.87c0.43-1.17,1.59-1.93,2.85-1.84
						c17.88,1.31,33.91-10.41,38.12-27.87c0.12-0.49,0.23-0.98,0.32-1.47l16.13-66.92c0.07-0.31,0.02-0.63-0.14-0.9
						c-0.17-0.27-0.43-0.46-0.74-0.54l-68.64-16.55c-0.31-0.07-0.63-0.02-0.9,0.14c-0.27,0.17-0.46,0.43-0.54,0.74l-16.1,66.79
						c-0.15,0.53-0.29,1.03-0.42,1.58c-4.21,17.45,4.72,35.19,21.23,42.18c1.15,0.49,1.85,1.7,1.7,2.94
						c-1.58,12.97-5.94,38.97-17.45,67.32l-1.51-0.61c11.44-28.18,15.78-54.01,17.34-66.9c0.06-0.53-0.22-1.03-0.72-1.24
						c-17.25-7.3-26.57-25.83-22.17-44.06c0.14-0.57,0.28-1.1,0.43-1.62l16.09-66.75c0.18-0.73,0.63-1.35,1.27-1.74
						c0.64-0.39,1.4-0.51,2.13-0.34l68.64,16.55c0.73,0.18,1.35,0.63,1.74,1.27c0.39,0.64,0.51,1.4,0.34,2.13l-16.13,66.88
						c-0.09,0.48-0.2,0.99-0.33,1.5c-4.4,18.23-21.15,30.48-39.82,29.11c-0.53-0.04-1.02,0.28-1.2,0.77
						C1760.15,1395.39,1752.24,1420.36,1749.58,1450.65z"
                            />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1768.92,1468.26c-8.69,0-18.95-1.34-29.48-3.88c-12.57-3.03-24.03-7.41-32.27-12.33c-8.5-5.08-12.62-10.23-11.59-14.49
					c1.96-8.14,22.46-10.17,47.68-4.7l-0.34,1.59c-23.53-5.1-44.06-3.53-45.75,3.49c-0.84,3.46,3.12,8.1,10.84,12.71
					c8.1,4.84,19.4,9.15,31.81,12.15c12.41,2.99,24.44,4.3,33.85,3.69c8.98-0.59,14.61-2.91,15.44-6.37
					c1.68-6.96-15.71-17.67-38.76-23.86l0.42-1.57c11.65,3.13,22.14,7.41,29.52,12.06c7.79,4.9,11.39,9.66,10.4,13.76
					c-1.03,4.27-7.04,6.97-16.92,7.62C1772.22,1468.21,1770.6,1468.26,1768.92,1468.26z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="1711.51"
                            y="1425.17"
                            transform="matrix(0.2344 -0.9721 0.9721 0.2344 -90.4572 2768.3281)"
                            width="1.63"
                            height="32.84"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="1707.37"
                            y="1436.07"
                            transform="matrix(0.2344 -0.9721 0.9721 0.2344 -98.137 2767.8369)"
                            width="1.63"
                            height="20.31"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="1711"
                            y="1425.32"
                            transform="matrix(0.2344 -0.9721 0.9721 0.2344 -84.0924 2762.5315)"
                            width="1.63"
                            height="18.68"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1916.53,1156.1c-0.93,0.73-2.11,1.15-3.27,1.23l-0.57,0.05c-0.17,0.02-0.35,0.03-0.52,0.04
						c-0.35,0.02-0.7,0.02-1.05,0l-4.19-0.39l-2.09-0.22l-0.26-0.03c-0.08-0.01-0.2-0.02-0.22-0.03c-0.1-0.02-0.2-0.03-0.3-0.03
						c-0.21-0.01-0.42,0.02-0.63,0.08c-0.21,0.06-0.42,0.16-0.62,0.29c-0.1,0.07-0.19,0.14-0.28,0.22l-0.13,0.14l-0.17,0.2
						l-1.34,1.63c-0.91,1.08-1.8,2.17-2.73,3.23c0.49-1.32,1.02-2.63,1.53-3.94l0.79-1.96l0.1-0.25c0.04-0.09,0.1-0.2,0.15-0.3
						c0.11-0.2,0.24-0.4,0.38-0.58c0.28-0.38,0.63-0.71,1.03-0.99c0.39-0.28,0.84-0.5,1.31-0.65c0.24-0.07,0.48-0.13,0.73-0.17
						l0.55-0.09l2.08-0.35l4.15-0.66c0.35-0.05,0.69-0.07,1.04-0.07c0.17,0,0.35,0,0.52,0l0.26,0.01l0.13,0l0.06,0l0.02-0.01
						c0.23,0.01,0.41-0.07,0.58-0.19c0.16-0.13,0.29-0.32,0.36-0.53c0.06-0.21,0.05-0.41,0-0.61c0-0.05-0.05-0.1-0.06-0.16
						c0,0-0.02-0.05-0.04-0.08l-0.06-0.12l-0.12-0.24l-0.47-0.93l-0.94-1.86l-1.88-3.72l-3.76-7.45l-0.12-0.23
						c-0.04-0.08-0.07-0.13-0.14-0.29c-0.11-0.27-0.24-0.53-0.31-0.82c-0.09-0.28-0.16-0.57-0.19-0.87
						c-0.05-0.29-0.06-0.59-0.06-0.89c0.01-1.19,0.41-2.35,1.08-3.3c0.67-0.96,1.67-1.71,2.78-2.11c0.56-0.21,1.14-0.31,1.72-0.34
						l1.07-0.04l8.34-0.27l4.17-0.13l2.08-0.06c0.06,0,0.05,0,0.07-0.01l0.04-0.01l0.08,0c0.05-0.03,0.11-0.02,0.15-0.04
						c0.04-0.02,0.1-0.03,0.14-0.06c0.02-0.01,0.05-0.02,0.07-0.03c0.02-0.02,0.04-0.04,0.06-0.05c0.17-0.11,0.31-0.31,0.38-0.51
						c0.08-0.21,0.08-0.41,0.02-0.61c-0.01-0.05-0.03-0.1-0.06-0.15c0.01-0.01-0.11-0.23-0.18-0.37l-0.47-0.93l-0.94-1.86
						l-3.76-7.45l-1.88-3.72l-0.24-0.47l-0.06-0.12l-0.03-0.06l-0.04-0.1l-0.17-0.4c-0.12-0.26-0.18-0.56-0.26-0.84
						c-0.06-0.29-0.1-0.59-0.13-0.88c-0.07-1.19,0.22-2.38,0.82-3.39c0.61-1,1.52-1.83,2.6-2.32c0.55-0.23,1.12-0.4,1.7-0.47
						l0.44-0.04c0.08-0.01,0.12-0.01,0.16-0.01l0.13,0l0.52-0.02l4.17-0.13l1.04-0.03l0.52-0.02l0.12,0l0.04,0
						c0.03,0,0.05-0.01,0.08-0.01c0.21-0.03,0.41-0.13,0.56-0.27c0.04-0.03,0.07-0.08,0.11-0.12l0.05-0.07
						c0.02-0.03,0.01-0.01,0.07-0.11l3.73-6.28c0.35-0.6,0.72-1.19,1.05-1.8c0.17-0.3,0.32-0.62,0.49-0.92
						c0.18-0.3,0.37-0.59,0.58-0.87c0.69-0.88,1.38-1.77,2.07-2.65c0.11-0.15,0.23-0.29,0.34-0.44c0.12-0.15,0.23-0.29,0.35-0.44
						l3.13,1.86c-0.07,0.17-0.14,0.34-0.22,0.51c-0.07,0.17-0.14,0.34-0.22,0.51c-0.44,1.03-0.89,2.06-1.34,3.09
						c-0.15,0.32-0.3,0.63-0.49,0.93c-0.18,0.3-0.38,0.58-0.57,0.87c-0.37,0.59-0.72,1.19-1.07,1.79l-3.73,6.28
						c-0.03,0.05-0.13,0.22-0.21,0.33c-0.08,0.12-0.17,0.24-0.26,0.36c-0.18,0.23-0.38,0.45-0.6,0.65
						c-0.86,0.81-1.97,1.34-3.13,1.52c-0.15,0.02-0.29,0.04-0.44,0.05l-0.22,0.02l-0.14,0.01l-0.52,0.02l-1.04,0.03l-4.17,0.13
						l-0.52,0.02l-0.13,0c-0.04,0-0.09,0-0.09,0.01c-0.03,0-0.05,0.01-0.08,0.01c-0.11,0-0.21,0.04-0.31,0.08
						c-0.18,0.09-0.34,0.23-0.46,0.42c-0.11,0.19-0.16,0.41-0.15,0.61c0.01,0.05,0.01,0.1,0.02,0.15c0.03,0.05,0.02,0.1,0.05,0.15
						c0.01,0.02,0.02,0.05,0.03,0.08l0.01,0.02l0.03,0.05l0.06,0.12l0.23,0.47l1.88,3.73l3.76,7.45l0.94,1.86l0.47,0.93
						c0.08,0.17,0.14,0.25,0.27,0.57c0.12,0.27,0.23,0.54,0.3,0.82c0.32,1.14,0.28,2.39-0.12,3.49c-0.39,1.11-1.11,2.09-2.07,2.78
						l-0.37,0.25c-0.13,0.08-0.26,0.15-0.39,0.21c-0.26,0.15-0.54,0.25-0.82,0.35c-0.28,0.1-0.57,0.15-0.86,0.22
						c-0.14,0.02-0.29,0.04-0.43,0.05l-0.22,0.02c-0.07,0-0.16,0.01-0.19,0.01l-2.09,0.07l-4.17,0.13l-8.35,0.26l-1.02,0.03
						c-0.12,0.02-0.22,0.02-0.32,0.06c-0.18,0.07-0.37,0.18-0.49,0.37c-0.12,0.18-0.21,0.39-0.2,0.6c0,0.05,0,0.1,0.01,0.15
						c0,0.05,0.02,0.1,0.03,0.15c0,0.06,0.05,0.1,0.06,0.16c0,0,0.05,0.11,0.09,0.18l0.12,0.23l3.76,7.45l1.88,3.72l0.94,1.86
						l0.47,0.93l0.18,0.35c0.02,0.04,0.03,0.07,0.07,0.15c0.11,0.27,0.24,0.53,0.32,0.82c0.36,1.12,0.36,2.38,0,3.5
						C1918.15,1154.37,1917.46,1155.38,1916.53,1156.1z"
                            />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1979.04,1100.66c-2.05,0-4.07-0.55-5.88-1.62c-5.49-3.29-7.28-10.38-4.04-15.84c3.24-5.46,10.32-7.28,15.79-4.07
					c2.7,1.6,4.58,4.12,5.35,7.11c0.76,2.99,0.31,6.1-1.26,8.76l0,0c-1.58,2.65-4.09,4.54-7.08,5.3
					C1980.96,1100.54,1980,1100.66,1979.04,1100.66z M1979.07,1079.18c-3.4,0-6.7,1.74-8.55,4.85c-2.78,4.69-1.24,10.78,3.43,13.58
					c2.32,1.38,4.99,1.76,7.56,1.11c2.57-0.65,4.73-2.27,6.08-4.55l0,0c1.35-2.28,1.74-4.95,1.08-7.52
					c-0.66-2.57-2.27-4.73-4.55-6.08C1982.54,1079.62,1980.79,1079.18,1979.07,1079.18z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1973.12,1099.01l-46.79-27.78c-2.65-1.58-4.54-4.09-5.3-7.08c-0.76-2.99-0.32-6.1,1.26-8.75
					c1.58-2.66,4.09-4.54,7.08-5.3c2.99-0.76,6.1-0.31,8.76,1.26l46.79,27.78l-0.83,1.4c-4.7-2.76-10.78-1.19-13.56,3.49
					c-2.78,4.69-1.24,10.78,3.43,13.58L1973.12,1099.01z M1932.24,1051.36c-0.82,0-1.65,0.1-2.47,0.31
					c-2.57,0.65-4.73,2.27-6.08,4.55c-1.35,2.28-1.74,4.95-1.08,7.52c0.66,2.57,2.27,4.73,4.55,6.08l41.76,24.79
					c-1.87-3.46-1.94-7.8,0.21-11.42c2.15-3.62,5.99-5.64,9.93-5.65l-41.76-24.79C1935.74,1051.83,1934,1051.36,1932.24,1051.36z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1973.11,1099c-5.44-3.26-7.23-10.35-3.99-15.81c3.24-5.46,10.32-7.28,15.79-4.07l-0.83,1.4
					c-4.7-2.76-10.78-1.19-13.56,3.49c-2.78,4.69-1.24,10.78,3.43,13.58L1973.11,1099z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="1937.78"
                            y="1082.16"
                            transform="matrix(0.8599 0.5105 -0.5105 0.8599 828.0255 -840.2685)"
                            width="13.77"
                            height="12.12"
                        />
                    </g>
                    <g>
                        <path
                            className="st0"
                            d="M1947.78,1098.06l-13.24-7.86l7.02-11.82l13.24,7.86L1947.78,1098.06z M1936.77,1089.63l10.44,6.2l5.36-9.02l-10.44-6.2
					L1936.77,1089.63z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1591.31,1131.23c-47.23-24.74-57.82-87.48-59.47-99.81l1.62-0.22c1.62,12.19,12.09,74.21,58.61,98.58L1591.31,1131.23z
					"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1532.89,1032.33l-0.43-0.2c-0.13-0.06-13.34-6.55-4.37-28.18l0.51-1.23l0.86,1.02c0.4,0.47,9.69,11.53,8.51,20.73
					c-0.4,3.14-1.99,5.7-4.7,7.59L1532.89,1032.33z M1529.08,1005.88c-6.62,17.21,1.63,23.36,3.66,24.56
					c2.08-1.59,3.29-3.66,3.62-6.17C1537.25,1017.35,1531.35,1008.84,1529.08,1005.88z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1538.01,1042.9c-1.2,0-2.42-0.2-3.67-0.61l-0.45-0.15l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.41l1.29-0.33l-0.3,1.3
					c-0.14,0.6-3.41,14.67-11.47,19.25C1541.57,1042.43,1539.83,1042.9,1538.01,1042.9z M1535.32,1040.88
					c2.52,0.71,4.91,0.43,7.11-0.81c6.07-3.44,9.33-13.27,10.34-16.86C1535.03,1028.25,1535.07,1038.54,1535.32,1040.88z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1541.94,1058.29c-1.2,0-2.42-0.2-3.67-0.61l-0.45-0.15l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.41l1.29-0.33l-0.3,1.3
					c-0.14,0.6-3.41,14.67-11.47,19.25C1545.51,1057.82,1543.76,1058.29,1541.94,1058.29z M1539.26,1056.27
					c2.52,0.71,4.91,0.43,7.11-0.81c6.07-3.44,9.33-13.27,10.34-16.86C1538.96,1043.64,1539,1053.93,1539.26,1056.27z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1547.74,1074.77c-1.2,0-2.42-0.2-3.67-0.61l-0.45-0.15l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.41l1.29-0.33
					l-0.29,1.3c-0.14,0.6-3.41,14.67-11.48,19.25C1551.3,1074.3,1549.55,1074.77,1547.74,1074.77z M1545.05,1072.75
					c2.52,0.71,4.91,0.43,7.11-0.81c6.07-3.44,9.33-13.27,10.34-16.86C1544.75,1060.12,1544.79,1070.41,1545.05,1072.75z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1555.47,1090.99c-1.2,0-2.42-0.2-3.67-0.61l-0.45-0.15l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.4l1.29-0.33l-0.3,1.3
					c-0.14,0.6-3.41,14.67-11.48,19.24C1559.04,1090.52,1557.29,1090.99,1555.47,1090.99z M1552.79,1088.97
					c2.52,0.71,4.91,0.43,7.11-0.81c6.07-3.43,9.33-13.26,10.34-16.85C1552.49,1076.34,1552.53,1086.63,1552.79,1088.97z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1564.79,1105.66c-1.2,0-2.42-0.2-3.67-0.61l-0.45-0.15l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.4l1.29-0.33l-0.3,1.3
					c-0.14,0.6-3.41,14.67-11.48,19.24C1568.35,1105.19,1566.6,1105.66,1564.79,1105.66z M1562.11,1103.65
					c2.52,0.7,4.9,0.43,7.1-0.81c6.07-3.44,9.33-13.26,10.34-16.85C1561.98,1090.98,1561.89,1101.34,1562.11,1103.65z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1527.66,1049.06c-0.12,0-0.24,0-0.37-0.01c-9.27-0.28-18.73-11.21-19.12-11.68l-0.87-1.01l1.3-0.31
					c22.78-5.44,27.09,8.63,27.13,8.77l0.13,0.45l-0.33,0.34C1533.32,1047.9,1530.67,1049.06,1527.66,1049.06z M1510.34,1037.33
					c2.57,2.7,10.04,9.88,17.01,10.09c2.54,0.08,4.77-0.8,6.67-2.59C1533.14,1042.64,1528.39,1033.52,1510.34,1037.33z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1533.32,1068.36c-0.12,0-0.24,0-0.37-0.01c-9.27-0.28-18.73-11.21-19.12-11.68l-0.87-1.01l1.3-0.31
					c22.78-5.44,27.09,8.62,27.13,8.77l0.13,0.45l-0.33,0.34C1538.99,1067.2,1536.34,1068.36,1533.32,1068.36z M1516.01,1056.63
					c2.57,2.7,10.04,9.88,17.01,10.09c2.54,0.07,4.77-0.8,6.67-2.59C1538.81,1061.94,1534.05,1052.82,1516.01,1056.63z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1540.25,1085.71c-0.12,0-0.24,0-0.36-0.01c-9.27-0.28-18.73-11.21-19.12-11.68l-0.87-1.01l1.3-0.31
					c22.78-5.44,27.09,8.62,27.13,8.77l0.13,0.45l-0.33,0.34C1545.91,1084.55,1543.26,1085.71,1540.25,1085.71z M1522.93,1073.99
					c2.57,2.7,10.04,9.88,17.01,10.09c2.52,0.07,4.77-0.8,6.66-2.59C1545.73,1079.29,1540.97,1070.17,1522.93,1073.99z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1549.31,1104.3c-8.92,0-18.77-8.75-19.2-9.14l-0.99-0.89l1.25-0.48c21.87-8.36,27.99,5.01,28.05,5.15l0.19,0.43
					l-0.28,0.38c-1.97,2.66-4.57,4.17-7.72,4.49C1550.16,1104.28,1549.74,1104.3,1549.31,1104.3z M1532.25,1094.83
					c2.9,2.34,11.24,8.49,18.18,7.79c2.52-0.25,4.62-1.41,6.27-3.44C1555.55,1097.11,1549.65,1088.69,1532.25,1094.83z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1557.09,1120.87c-7.57,0-15.58-3.74-16.01-3.94l-1.21-0.57l1.06-0.81c18.57-14.27,28.25-3.19,28.35-3.08l0.3,0.36
					l-0.16,0.44c-1.13,3.11-3.19,5.3-6.12,6.51C1561.4,1120.56,1559.26,1120.87,1557.09,1120.87z M1543.04,1116
					c3.45,1.42,13.2,4.92,19.65,2.27c2.34-0.96,4.03-2.67,5.02-5.09C1566.03,1111.53,1557.96,1105.15,1543.04,1116z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1901.46,1453.01c-1.53-6.09-3.51-12.35-5.89-18.6c-5.16-13.58-4.97-25.87,0.56-36.53c11-21.18,39.75-28.72,40.04-28.79
					l0.4,1.58c-0.28,0.07-28.34,7.44-39,27.97c-5.31,10.23-5.47,22.07-0.48,35.19c2.4,6.31,4.4,12.63,5.94,18.78L1901.46,1453.01z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1942.9,1373.37c-2.86,0-5.41-1.07-7.61-3.19l-0.34-0.33l0.12-0.46c0.04-0.14,3.89-14.34,26.83-9.65l1.31,0.27
					l-0.83,1.04c-0.38,0.48-9.48,11.71-18.73,12.3C1943.4,1373.36,1943.15,1373.37,1942.9,1373.37z M1936.8,1369.35
					c1.96,1.74,4.22,2.54,6.75,2.38c6.96-0.44,14.19-7.86,16.67-10.64C1942.05,1357.85,1937.59,1367.13,1936.8,1369.35z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1924.46,1374.85l-0.32-0.35c-0.1-0.11-9.83-11.14,6.71-27.71l0.94-0.94l0.41,1.27c0.19,0.58,4.56,14.36-0.05,22.41
					c-1.57,2.75-4.01,4.51-7.24,5.23L1924.46,1374.85z M1931.04,1348.94c-12.69,13.39-7.4,22.21-5.99,24.1
					c2.53-0.68,4.44-2.13,5.7-4.32C1934.21,1362.66,1932,1352.54,1931.04,1348.94z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1909.13,1383.8l-0.36-0.31c-0.11-0.1-11.03-9.96,3.54-28.29l0.83-1.04l0.55,1.21c0.25,0.56,6.15,13.75,2.48,22.27
					c-1.25,2.91-3.47,4.93-6.61,6.01L1909.13,1383.8z M1912.74,1357.32c-10.98,14.6-4.78,22.91-3.22,24.62
					c2.43-0.96,4.17-2.62,5.17-4.94C1917.44,1370.58,1914.1,1360.79,1912.74,1357.32z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1899.17,1395.44l-0.46-0.12c-0.14-0.04-14.29-4.09-9.27-26.97l0.28-1.3l1.03,0.85c0.47,0.39,11.58,9.64,12.03,18.9
					c0.16,3.16-0.95,5.96-3.29,8.31L1899.17,1395.44z M1890.75,1370.06c-3.48,18.11,5.73,22.7,7.94,23.53
					c1.76-1.93,2.59-4.19,2.47-6.71C1900.82,1379.92,1893.5,1372.58,1890.75,1370.06z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1892.38,1405.33c-4.26,0-13.93-2.03-19.08-19.28l-0.38-1.28l1.31,0.24c0.6,0.11,14.8,2.82,19.69,10.7
					c1.67,2.69,2.06,5.67,1.15,8.86l-0.13,0.45l-0.46,0.11C1894.45,1405.15,1893.65,1405.33,1892.38,1405.33z M1875.27,1386.92
					c5.74,17.53,16.02,17.08,18.35,16.73c0.61-2.54,0.24-4.92-1.09-7.07C1888.85,1390.65,1878.9,1387.79,1875.27,1386.92z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1887.92,1422.52c-4.94,0-12.63-2.44-19.68-14.88l-0.66-1.16l1.33-0.06c0.61-0.03,15.05-0.56,21.58,6.03
					c2.23,2.25,3.27,5.07,3.1,8.38l-0.02,0.47l-0.42,0.21C1893.1,1421.53,1891.06,1422.52,1887.92,1422.52z M1870.35,1408.04
					c9.51,15.8,19.43,13.06,21.63,12.2c0.02-2.61-0.87-4.85-2.65-6.64C1884.43,1408.65,1874.08,1408.08,1870.35,1408.04z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1933.95,1388.4c-4.18,0-8.84-0.66-12.23-2.98c-2.61-1.79-4.17-4.36-4.62-7.64l-0.06-0.47l0.37-0.29
					c0.12-0.09,11.9-8.89,27.08,8.94l0.86,1.02l-1.3,0.31C1943.72,1387.36,1939.21,1388.4,1933.95,1388.4z M1918.8,1378.05
					c0.47,2.57,1.76,4.59,3.85,6.02c5.76,3.94,16.01,2.56,19.68,1.89C1930.13,1372.37,1920.78,1376.85,1918.8,1378.05z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1914.74,1397.6c-2.26,0-4.48-0.32-6.46-1.16c-2.92-1.24-4.95-3.45-6.05-6.57l-0.16-0.44l0.31-0.36
					c0.1-0.11,9.9-11.08,28.31,3.39l1.05,0.82l-1.21,0.56C1930.11,1394.03,1922.24,1397.6,1914.74,1397.6z M1903.95,1389.79
					c0.97,2.43,2.64,4.16,4.97,5.15c6.42,2.72,16.2-0.67,19.67-2.05C1913.79,1381.87,1905.65,1388.16,1903.95,1389.79z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1905.33,1415.99c-2.26,0-4.48-0.32-6.46-1.16c-2.92-1.24-4.95-3.45-6.05-6.57l-0.16-0.44l0.31-0.36
					c0.1-0.11,9.9-11.08,28.31,3.39l1.05,0.82l-1.21,0.56C1920.7,1412.42,1912.82,1415.99,1905.33,1415.99z M1894.54,1408.18
					c0.97,2.43,2.64,4.16,4.97,5.15c6.42,2.72,16.2-0.67,19.67-2.05C1904.38,1400.26,1896.24,1406.55,1894.54,1408.18z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1901.75,1433.2c-2.75,0-5.24-1.01-7.41-3.02l-0.35-0.32l0.1-0.46c0.03-0.14,3.56-14.43,26.6-10.28l1.31,0.24
					l-0.81,1.06c-0.37,0.49-9.2,11.93-18.44,12.73C1902.42,1433.19,1902.08,1433.2,1901.75,1433.2z M1895.82,1429.33
					c1.99,1.69,4.28,2.43,6.79,2.21c6.94-0.6,14-8.19,16.41-11.03C1900.97,1417.75,1896.58,1427.14,1895.82,1429.33z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1654.34,1408.39c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C1659.76,1405.96,1657.33,1408.39,1654.34,1408.39z M1654.34,1399.17c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79S1656.43,1399.17,1654.34,1399.17z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1637.76,1461.22c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C1643.18,1458.79,1640.75,1461.22,1637.76,1461.22z M1637.76,1452c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79C1641.55,1453.7,1639.85,1452,1637.76,1452z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1850.13,1364.7c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C1855.55,1362.27,1853.12,1364.7,1850.13,1364.7z M1850.13,1355.49c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79S1852.22,1355.49,1850.13,1355.49z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1954.72,1302.84c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C1960.14,1300.41,1957.71,1302.84,1954.72,1302.84z M1954.72,1293.62c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79C1958.51,1295.33,1956.81,1293.62,1954.72,1293.62z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1757.65,1229.71c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C1763.08,1227.28,1760.64,1229.71,1757.65,1229.71z M1757.65,1220.49c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79S1759.75,1220.49,1757.65,1220.49z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1702.17,1113.43c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C1707.59,1111,1705.16,1113.43,1702.17,1113.43z M1702.17,1104.21c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79S1704.26,1104.21,1702.17,1104.21z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1810.87,1030.95c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42s5.42,2.43,5.42,5.42
				C1816.29,1028.51,1813.86,1030.95,1810.87,1030.95z M1810.87,1021.73c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79S1812.96,1021.73,1810.87,1021.73z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1630.74,1044.55c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				S1633.73,1044.55,1630.74,1044.55z M1630.74,1035.33c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79c2.09,0,3.79-1.7,3.79-3.79
				S1632.83,1035.33,1630.74,1035.33z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1952.81,1462.92c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				S1955.8,1462.92,1952.81,1462.92z M1952.81,1453.7c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79c2.09,0,3.79-1.7,3.79-3.79
				S1954.9,1453.7,1952.81,1453.7z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1844.39,1465.47c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C1849.81,1463.04,1847.38,1465.47,1844.39,1465.47z M1844.39,1456.25c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79C1848.18,1457.95,1846.48,1456.25,1844.39,1456.25z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1620.24,1453.41l-6.88,3.15l-0.3-0.92c-2.45-5.35-8.79-7.71-14.13-5.26c-5.35,2.45-7.71,8.78-5.26,14.13l0.02,0.05
				l0.42,0.77l-0.78,0.39l-44.17,20.19l-18.21-39.83l0.82-0.3c0.11-0.04,0.22-0.09,0.33-0.14c5.35-2.45,7.71-8.78,5.26-14.13
				c-2.45-5.35-8.78-7.71-14.13-5.26c-0.11,0.05-0.22,0.1-0.32,0.16l-0.77,0.43l-5.48-11.99l12.29-5.62l0.35,0.7
				c2.58,5.15,8.71,7.3,13.96,4.91s7.62-8.44,5.42-13.77l-0.3-0.73l39.36-18l3.15,6.89l-0.74,0.34c-5.36,2.45-7.72,8.79-5.28,14.14
				c2.45,5.35,8.78,7.71,14.13,5.26l0.74-0.34l0.35,0.73L1620.24,1453.41z M1614.25,1454.36l3.84-1.75l-19.13-41.85
				c-6.01,2.29-12.85-0.46-15.56-6.38c-2.71-5.92-0.3-12.9,5.35-15.94l-1.81-3.95l-36.47,16.67c2.04,5.95-0.72,12.48-6.53,15.13
				s-12.55,0.47-15.72-4.96l-9.4,4.3l4.1,8.96c6.09-2.56,13.17,0.18,15.93,6.23c2.76,6.05,0.2,13.19-5.72,16.12l16.83,36.81
				l41.95-19.18c-2.37-6.03,0.37-12.95,6.33-15.68C1604.21,1446.16,1611.24,1448.62,1614.25,1454.36z"
                    />
                </g>
                <g>
                    <path
                        className="st0"
                        d="M1568.87,1447.3c-7.24,3.31-15.82,0.11-19.13-7.13s-0.11-15.82,7.13-19.13s15.82-0.11,19.13,7.13
				S1576.11,1443.99,1568.87,1447.3z M1557.54,1422.52c-6.42,2.94-9.26,10.55-6.32,16.97c2.94,6.42,10.55,9.26,16.97,6.32
				s9.26-10.55,6.32-16.97C1571.58,1422.42,1563.97,1419.59,1557.54,1422.52z"
                    />
                </g>
                <g>
                    <path
                        className="st0"
                        d="M1566.21,1468.76c-2.97,1.36-6.48,0.05-7.84-2.92c-1.36-2.97-0.05-6.48,2.92-7.84c2.97-1.36,6.48-0.05,7.84,2.92
				C1570.49,1463.88,1569.18,1467.4,1566.21,1468.76z M1561.97,1459.47c-2.15,0.98-3.1,3.53-2.12,5.68c0.98,2.15,3.53,3.1,5.68,2.12
				c2.15-0.98,3.1-3.53,2.12-5.68S1564.12,1458.49,1561.97,1459.47z"
                    />
                </g>
                <g>
                    <path
                        className="st0"
                        d="M1593.52,1435.08c-2.97,1.36-6.48,0.05-7.84-2.92c-1.36-2.97-0.05-6.48,2.92-7.84c2.97-1.36,6.48-0.05,7.84,2.92
				C1597.79,1430.21,1596.48,1433.72,1593.52,1435.08z M1589.27,1425.8c-2.15,0.98-3.1,3.53-2.12,5.68c0.98,2.15,3.53,3.1,5.68,2.12
				c2.15-0.98,3.1-3.53,2.12-5.68C1593.97,1425.77,1591.42,1424.82,1589.27,1425.8z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <circle className="st0" cx="299.67" cy="1815.15" r="4.61" />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M306.44,1794.69c0.17,0.52,1.79,5.47-1.01,8.07c-2.6,2.41-7.32,1.2-9.79-0.86c-1.51-1.26-1.27-2.08-2.88-4.47
					c-3.59-5.31-7.5-5.22-7.78-7.78c-0.44-3.98,8.47-9.09,10.66-7.35c1.47,1.17-0.79,4.87,1.15,6.63c1.55,1.4,3.66-0.33,6.34,1.15
					C305.33,1791.31,306.12,1793.73,306.44,1794.69z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M289.87,1811.11c0.13,1.37-0.3,2.5-1.15,4.75c-1.22,3.2-1.83,4.8-3.02,5.04c-2.15,0.43-5.53-3.7-4.75-6.19
					c0.54-1.74,2.72-1.42,3.89-3.6c1.42-2.65-0.53-5.46,0.29-5.91C286.02,1804.72,289.53,1807.55,289.87,1811.11z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M371.35,1796.58c-0.7,0-1.4-0.07-2.1-0.22c-2.68-0.55-4.98-2.12-6.49-4.41l-77.85-118.48
					c-0.04-0.06-0.08-0.12-0.11-0.18c-6-11.09-5.25-24.42,1.96-34.8c0.25-0.36,0.51-0.71,0.77-1.06c0.18-0.24,0.19-0.56,0.03-0.81
					l-36.2-55.09c-0.34-0.52-0.46-1.14-0.34-1.76c0.13-0.61,0.48-1.14,1-1.48l13.98-9.19c0.52-0.34,1.15-0.46,1.76-0.34
					c0.61,0.13,1.14,0.48,1.48,1l36.2,55.09c0.16,0.25,0.46,0.37,0.75,0.3c0.42-0.1,0.85-0.2,1.28-0.28
					c12.38-2.51,24.92,2.09,32.72,12.01c0.05,0.06,0.09,0.12,0.13,0.18l77.85,118.47c3.1,4.72,1.79,11.09-2.94,14.19l-38.28,25.16
					C375.26,1796.01,373.32,1796.58,371.35,1796.58z M267.29,1570.36c-0.14,0-0.27,0.04-0.39,0.12l-13.98,9.19
					c-0.16,0.1-0.27,0.26-0.3,0.45c-0.04,0.18,0,0.37,0.1,0.53l36.2,55.09c0.54,0.82,0.51,1.9-0.09,2.69
					c-0.25,0.33-0.49,0.67-0.73,1.01c-6.86,9.87-7.57,22.55-1.87,33.09c0.01,0.02,0.02,0.04,0.04,0.06l77.86,118.48
					c1.26,1.92,3.2,3.24,5.45,3.71c2.25,0.47,4.55,0.03,6.48-1.24l38.28-25.16c1.92-1.26,3.24-3.2,3.7-5.45
					c0.47-2.25,0.03-4.55-1.24-6.48l-77.86-118.48c-0.01-0.02-0.03-0.04-0.04-0.06c-7.42-9.42-19.34-13.8-31.11-11.42
					c-0.41,0.08-0.81,0.17-1.22,0.27c-0.95,0.23-1.96-0.16-2.5-0.99l-36.2-55.09c-0.1-0.16-0.26-0.27-0.45-0.3
					C267.38,1570.37,267.34,1570.36,267.29,1570.36z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M367.76,1791.14c-1.03,0-2.07-0.16-3.09-0.48l0.49-1.56c2.48,0.78,5.15,0.41,7.32-1.02l15.3-10.05l0.9,1.36l-15.3,10.05
					C371.68,1790.56,369.73,1791.14,367.76,1791.14z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M365.21,1787.25c-0.7,0-1.4-0.07-2.1-0.22l0.33-1.6c2.26,0.47,4.55,0.03,6.48-1.24l3.89-2.56l0.89,1.36l-3.89,2.56
					C369.13,1786.67,367.19,1787.25,365.21,1787.25z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M333.6,1643.49c-5.36-6.84-14.34-10.15-22.87-8.42l-0.32-1.6c9.27-1.88,18.65,1.58,24.48,9.01L333.6,1643.49z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M247.44,1582.01c-0.27,0-0.54-0.03-0.81-0.08c-1.03-0.21-1.92-0.82-2.5-1.7l-4.89-7.45c-1.2-1.82-0.69-4.27,1.13-5.47
					l20.18-13.26c0.88-0.58,1.94-0.78,2.97-0.57c1.03,0.21,1.92,0.82,2.5,1.7l4.89,7.45c1.2,1.82,0.69,4.27-1.13,5.47l-20.18,13.26
					C248.95,1581.79,248.2,1582.01,247.44,1582.01z M262.71,1555.04c-0.45,0-0.89,0.13-1.27,0.38l-20.18,13.26
					c-1.07,0.7-1.37,2.14-0.66,3.21l4.89,7.45c0.34,0.52,0.86,0.87,1.47,1c0.61,0.13,1.23,0.01,1.74-0.33l20.18-13.26
					c1.07-0.7,1.37-2.14,0.66-3.21l-4.89-7.45c-0.34-0.52-0.86-0.87-1.47-1C263.03,1555.05,262.87,1555.04,262.71,1555.04z"
                        />
                    </g>
                </g>
                <path
                    className="st0"
                    d="M315.03,1716.36l29.26,44.53c13.36-5.83,33.07-16.53,52.58-34.78l-37.36-56.85c-19.48,18.17-39.15,28.9-52.58,34.78
			l4.04,6.15c5.98-2.93,12.02-6.19,17.97-9.72l0.83,1.4c-5.93,3.51-11.94,6.76-17.9,9.69l2.25,3.42c5.97-2.92,12.01-6.19,17.97-9.72
			c0.1-0.06,10.3-6.16,12.26-7.81l1.05,1.25c-2.06,1.73-12.05,7.71-12.47,7.96C327,1710.19,320.98,1713.44,315.03,1716.36z"
                />
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="255.94"
                            y="1572.15"
                            transform="matrix(0.8357 -0.5492 0.5492 0.8357 -826.2486 406.2166)"
                            width="19.74"
                            height="23.84"
                        />
                    </g>
                    <g>
                        <path
                            className="st0"
                            d="M263.87,1600.58l-13.99-21.29l17.86-11.74l13.99,21.29L263.87,1600.58z M252.14,1579.76l12.2,18.57l15.14-9.95
					l-12.2-18.57L252.14,1579.76z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M103.74,1749.59c-1.5-0.45-3.08-0.69-4.73-0.69c-9.07,0-16.42,7.35-16.42,16.42c0,2.49,0.55,4.85,1.55,6.96l-0.92,1.06
					l-18.05,3.26l10.56-16.9c0,0-26.12,2.3-26.69,2.3c-0.58,0,13.06-15.75,13.06-15.75l-19.39-21.32l25.16,6.34v-22.66l20.16,20.36
					l9.03-17.48L103.74,1749.59z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M115.13,1852.27c-9.5,0-17.24-7.73-17.24-17.24c0-2.45,0.5-4.81,1.49-7.02l0.24-0.53l0.58,0.05
						c0.45,0.04,0.92,0.06,1.39,0.06c6.15,0,11.75-3.63,14.26-9.25l0.24-0.53l0.58,0.05c8.81,0.77,15.7,8.31,15.7,17.17
						C132.37,1844.54,124.64,1852.27,115.13,1852.27z M100.66,1829.2c-0.75,1.85-1.13,3.81-1.13,5.84c0,8.6,7,15.61,15.61,15.61
						s15.61-7,15.61-15.61c0-7.83-5.95-14.52-13.65-15.48c-2.88,5.89-8.9,9.67-15.49,9.67
						C101.28,1829.23,100.97,1829.22,100.66,1829.2z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M100.06,1829.16c-8.81-0.77-15.7-8.31-15.7-17.17c0-9.5,7.73-17.24,17.24-17.24s17.24,7.73,17.24,17.24
						c0,2.45-0.5,4.81-1.49,7.02l-1.49-0.66c0.9-2.01,1.35-4.15,1.35-6.36c0-8.6-7-15.61-15.61-15.61s-15.61,7-15.61,15.61
						c0,8.02,6.24,14.84,14.22,15.54L100.06,1829.16z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M130.98,1831.24h-0.08v-1.63h0.08c8.6,0,15.61-7,15.61-15.61c0-3.75-1.35-7.38-3.81-10.22
						c-2.97-3.42-7.27-5.39-11.8-5.39c-0.19,0-0.38,0-0.57,0.01l-0.06-1.63c0.21-0.01,0.42-0.01,0.63-0.01c5,0,9.75,2.17,13.03,5.95
						c2.71,3.13,4.21,7.14,4.21,11.29C148.22,1823.51,140.48,1831.24,130.98,1831.24z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M117.51,1808.32l-0.22-1.61c7.67-1.06,13.45-7.7,13.45-15.46c0-8.6-7-15.61-15.61-15.61s-15.61,7-15.61,15.61
						c0,1.46,0.2,2.89,0.59,4.25l-1.57,0.45c-0.43-1.51-0.65-3.09-0.65-4.7c0-9.5,7.73-17.24,17.24-17.24s17.24,7.73,17.24,17.24
						C132.37,1799.81,125.98,1807.15,117.51,1808.32z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M86.68,1805.61c-8.19-1.49-14.13-8.62-14.13-16.95c0-9.5,7.73-17.24,17.23-17.24c5.82,0,11.21,2.91,14.41,7.78
						l-1.36,0.9c-2.9-4.41-7.78-7.05-13.05-7.05c-8.6,0-15.6,7-15.6,15.61c0,7.55,5.38,14,12.79,15.35L86.68,1805.61z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M143.48,1804.07l-0.17-1.62c7.94-0.84,13.94-7.52,13.94-15.52c0-8.6-7-15.61-15.61-15.61
						c-6.03,0-11.41,3.37-14.04,8.79l-1.47-0.71c2.87-5.9,8.95-9.71,15.51-9.71c9.5,0,17.24,7.73,17.24,17.24
						C158.87,1795.77,152.25,1803.13,143.48,1804.07z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M140.76,1770.99l-1.41-0.81c1.34-2.34,2.05-5.02,2.05-7.74c0-8.6-7-15.61-15.61-15.61c-5.7,0-10.94,3.1-13.69,8.1
						l-1.43-0.78c3.03-5.52,8.82-8.95,15.11-8.95c9.5,0,17.24,7.73,17.24,17.24C143.03,1765.44,142.25,1768.4,140.76,1770.99z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M112.86,1775.57l-1.31-0.97c0.41-0.56,0.8-1.16,1.14-1.78c1.25-2.29,1.92-4.88,1.92-7.5c0-3.77-1.36-7.4-3.83-10.24
						c-1.94-2.23-4.46-3.86-7.28-4.7c-1.44-0.44-2.96-0.66-4.5-0.66c-8.6,0-15.61,7-15.61,15.61c0,2.32,0.49,4.54,1.47,6.61
						c0.12,0.27,0.25,0.52,0.38,0.77l-1.43,0.78c-0.15-0.28-0.3-0.57-0.43-0.86c-1.07-2.28-1.62-4.74-1.62-7.3
						c0-9.5,7.73-17.24,17.24-17.24c1.7,0,3.37,0.24,4.97,0.73c3.11,0.93,5.89,2.73,8.04,5.2c2.73,3.14,4.23,7.15,4.23,11.31
						c0,2.89-0.73,5.76-2.12,8.29C113.74,1774.3,113.32,1774.96,112.86,1775.57z"
                            />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M112.69,1752.5c-2.1-4.93-2.4-8.9-2.28-11.64c0.22-5.05,1.91-6.82,2.86-14.58c0.43-3.53,0.71-5.81,0.29-8.93
					c-0.39-2.91-1.04-7.73-4.9-11.81c-4.24-4.49-9.68-5.35-11.63-5.51c-3.92-0.32-7.09,0.32-7.09,0.32
					c21.03,0.58,23.33,19.3,19.3,30.82c-4.03,11.52,1.15,22.76,1.15,22.76l1.01,0.6l1.59-2.19"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M111.68,1726.29c0,0,9.36-3.02,8.79-12.96s-3.17-13.25-3.17-13.25s3.89,3.6,4.61,7.06c0.72,3.46,2.59,11.52-2.45,15.99
					c-5.04,4.47-7.49,6.34-8.21,9.22L111.68,1726.29z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="87.27" y="1819.19" width="15.15" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="90.45" y="1823.23" width="11.97" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="84.94" y="1810.94" width="17.48" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="85.62" y="1814.97" width="16.8" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="87.91" y="1802.1" width="14.5" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="85.96" y="1806.13" width="16.45" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <rect className="st0" x="75.55" y="1795.57" width="12.22" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="78.73" y="1799.6" width="5.44" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="73.22" y="1787.31" width="17.48" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="73.9" y="1791.34" width="16.8" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="76.2" y="1778.48" width="7.97" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="74.25" y="1782.51" width="13.38" height="1.63" />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <rect className="st0" x="81.17" y="1766.91" width="12.22" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="78.84" y="1758.65" width="17.48" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="79.52" y="1762.68" width="16.8" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="79.87" y="1753.85" width="13.38" height="1.63" />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="98.72" y="1836.81" width="15.99" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="99.72" y="1840.85" width="11.52" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <polygon
                            className="st0"
                            points="174.22,1719.89 158.64,1686.43 147.23,1710.41 133.39,1663.28 134.95,1662.82 147.59,1705.86 158.66,1682.6 
					174.19,1715.97 185.2,1691.47 215.97,1692.3 197.86,1669.73 217.85,1651.9 176.9,1656.39 186.9,1634.45 136.77,1660.05 
					136.03,1658.6 190.32,1630.88 179.57,1654.45 222.74,1649.72 200.1,1669.92 219.44,1694.02 186.25,1693.12 				"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M136.38,1659.35l-2.8,1.26l0.58,2.44c-1.7-1.27-4.33-2.9-8.36-4.8c-9.79-4.61-5.57-14.98-5.57-14.98
					s0.24,2.64,1.08,5.45c1.18,3.95,4.07,5.97,4.68,6.46c1.85,1.47,3.54,2.08,5.76,2.88c1.73,0.62,3.31,1.01,4.64,1.26
					L136.38,1659.35z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M79.27,1680.15c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
					S82.26,1680.15,79.27,1680.15z M79.27,1670.93c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79c2.09,0,3.79-1.7,3.79-3.79
					S81.36,1670.93,79.27,1670.93z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M38.37,1797.97c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
					C43.79,1795.54,41.36,1797.97,38.37,1797.97z M38.37,1788.75c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
					c2.09,0,3.79-1.7,3.79-3.79C42.16,1790.45,40.46,1788.75,38.37,1788.75z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M164.54,1766.28c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
					S167.53,1766.28,164.54,1766.28z M164.54,1757.06c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79c2.09,0,3.79-1.7,3.79-3.79
					S166.63,1757.06,164.54,1757.06z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M162.24,1830.52c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
					S165.23,1830.52,162.24,1830.52z M162.24,1821.3c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79c2.09,0,3.79-1.7,3.79-3.79
					S164.33,1821.3,162.24,1821.3z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <polygon
                            className="st0"
                            points="217.41,1693.92 139.05,1663.38 133.32,1661.38 133.86,1659.84 139.62,1661.85 218,1692.4 				"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <polygon
                            className="st0"
                            points="170.21,1687.66 167.82,1673.52 180.79,1667.52 181.48,1668.99 169.64,1674.48 171.82,1687.38 				"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <polygon
                            className="st0"
                            points="149.93,1679.86 148.3,1679.69 149.66,1666.69 157.95,1659.27 159.04,1660.48 151.22,1667.49 				"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M300.62,1832.32l-3.25,13.48c-0.08,0.38-0.16,0.76-0.25,1.14c-3.67,15.22-18.99,24.59-34.21,20.92
					c-15.23-3.67-24.59-18.99-20.92-34.22c0.1-0.4,0.2-0.8,0.32-1.2l3.23-13.4c0,0,8.64,15.52,20.14,15.33
					C277.18,1834.17,285.43,1818.58,300.62,1832.32z"
                        />
                    </g>
                    <g>
                        <path
                            className="st0"
                            d="M269.55,1869.46c-2.26,0-4.55-0.26-6.84-0.82c-7.58-1.83-13.99-6.49-18.05-13.14c-4.07-6.65-5.3-14.48-3.47-22.06
					c0.1-0.42,0.21-0.83,0.33-1.23l3.71-15.4l1.02,1.82c0.08,0.15,8.44,14.91,19.22,14.91c0.06,0,0.13,0,0.19,0
					c3.74-0.06,7.12-1.83,10.69-3.71c7.07-3.71,14.39-7.55,24.81,1.88l0.36,0.32l-3.36,13.95c-0.07,0.35-0.15,0.75-0.25,1.14
					C294.7,1860.47,282.72,1869.46,269.55,1869.46z M245.86,1821.14l-2.77,11.49c-0.12,0.42-0.22,0.81-0.32,1.2
					c-1.72,7.15-0.56,14.55,3.28,20.82c3.84,6.28,9.89,10.68,17.04,12.41c14.76,3.56,29.67-5.56,33.23-20.32
					c0.09-0.37,0.17-0.74,0.24-1.11l3.14-13.03c-9.38-8.25-15.8-4.88-22.6-1.32c-3.59,1.88-7.3,3.83-11.42,3.9
					C256.06,1835.33,248.54,1825.27,245.86,1821.14z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M264.26,1824.96c-11.6,0-16.45-14.38-16.5-14.53l-0.07-0.22l6.6-27.39l1.58,0.38l-6.49,26.93
					c0.65,1.78,5.59,14.19,16.17,13.13l0.16,1.62C265.22,1824.94,264.73,1824.96,264.26,1824.96z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M264.82,1808.41c-0.1-0.01-10.25-0.71-13.69-12l1.56-0.47c3.09,10.17,11.85,10.82,12.22,10.84L264.82,1808.41z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M249.58,1950.66l-1.62-0.14c2.67-30.48,10.63-55.61,15.14-67.87c0.43-1.17,1.59-1.93,2.85-1.84
						c17.88,1.31,33.91-10.41,38.12-27.87c0.12-0.49,0.23-0.98,0.32-1.47l16.13-66.92c0.07-0.31,0.02-0.63-0.14-0.9
						c-0.17-0.27-0.43-0.46-0.74-0.54l-68.64-16.55c-0.31-0.07-0.63-0.02-0.9,0.14c-0.27,0.17-0.46,0.43-0.54,0.74l-16.1,66.79
						c-0.15,0.53-0.29,1.03-0.42,1.58c-4.21,17.45,4.72,35.19,21.23,42.18c1.15,0.49,1.85,1.7,1.7,2.94
						c-1.58,12.97-5.94,38.97-17.45,67.32l-1.51-0.61c11.44-28.18,15.78-54.01,17.34-66.9c0.06-0.53-0.22-1.03-0.72-1.24
						c-17.25-7.3-26.57-25.83-22.17-44.06c0.14-0.57,0.28-1.1,0.43-1.62l16.09-66.75c0.18-0.73,0.63-1.35,1.27-1.74
						c0.64-0.39,1.4-0.51,2.13-0.34l68.64,16.55c0.73,0.18,1.35,0.63,1.74,1.27c0.39,0.64,0.51,1.4,0.34,2.13l-16.13,66.88
						c-0.09,0.48-0.2,0.99-0.33,1.5c-4.4,18.23-21.15,30.48-39.82,29.11c-0.53-0.04-1.02,0.28-1.2,0.77
						C260.15,1895.4,252.24,1920.37,249.58,1950.66z"
                            />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M268.92,1968.27c-8.69,0-18.95-1.34-29.48-3.88c-12.57-3.03-24.03-7.41-32.27-12.33c-8.5-5.08-12.62-10.23-11.59-14.49
					c1.96-8.14,22.46-10.17,47.68-4.7l-0.34,1.59c-23.53-5.1-44.06-3.53-45.75,3.49c-0.84,3.46,3.12,8.1,10.84,12.71
					c8.1,4.84,19.4,9.15,31.81,12.15c12.41,2.99,24.44,4.3,33.85,3.69c8.98-0.59,14.61-2.91,15.44-6.37
					c1.68-6.96-15.71-17.67-38.76-23.86l0.42-1.57c11.65,3.13,22.14,7.41,29.52,12.06c7.79,4.9,11.39,9.66,10.4,13.76
					c-1.03,4.27-7.04,6.97-16.92,7.62C272.22,1968.22,270.6,1968.27,268.92,1968.27z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="211.51"
                            y="1925.19"
                            transform="matrix(0.2344 -0.9721 0.9721 0.2344 -1724.957 1692.9283)"
                            width="1.63"
                            height="32.84"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="207.37"
                            y="1936.08"
                            transform="matrix(0.2344 -0.9721 0.9721 0.2344 -1732.6331 1692.4369)"
                            width="1.63"
                            height="20.31"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="211"
                            y="1925.33"
                            transform="matrix(0.2344 -0.9721 0.9721 0.2344 -1718.6074 1687.1332)"
                            width="1.63"
                            height="18.68"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M416.53,1656.11c-0.93,0.73-2.11,1.15-3.27,1.23l-0.57,0.05c-0.17,0.02-0.35,0.03-0.52,0.04
						c-0.35,0.02-0.7,0.02-1.05,0l-4.19-0.39l-2.09-0.22l-0.26-0.03c-0.08-0.01-0.2-0.02-0.22-0.03c-0.1-0.02-0.2-0.03-0.3-0.03
						c-0.21-0.01-0.42,0.02-0.63,0.08c-0.21,0.06-0.42,0.16-0.62,0.29c-0.1,0.07-0.19,0.14-0.28,0.22l-0.13,0.14l-0.17,0.2
						l-1.34,1.63c-0.91,1.08-1.8,2.17-2.73,3.23c0.49-1.32,1.02-2.63,1.53-3.94l0.79-1.96l0.1-0.25c0.04-0.09,0.1-0.2,0.15-0.3
						c0.11-0.2,0.24-0.4,0.38-0.58c0.28-0.38,0.63-0.71,1.03-0.99c0.39-0.28,0.84-0.5,1.31-0.65c0.24-0.07,0.48-0.13,0.73-0.17
						l0.55-0.09l2.08-0.35l4.15-0.66c0.35-0.05,0.69-0.07,1.04-0.07c0.17,0,0.35,0,0.52,0l0.26,0.01l0.13,0l0.06,0l0.02-0.01
						c0.23,0.01,0.41-0.07,0.58-0.19c0.16-0.13,0.29-0.32,0.36-0.53c0.06-0.21,0.05-0.41,0-0.61c0-0.05-0.05-0.1-0.06-0.16
						c0,0-0.02-0.05-0.04-0.08l-0.06-0.12l-0.12-0.24l-0.47-0.93l-0.94-1.86l-1.88-3.72l-3.76-7.45l-0.12-0.23
						c-0.04-0.08-0.07-0.13-0.14-0.29c-0.11-0.27-0.24-0.53-0.31-0.82c-0.09-0.28-0.16-0.57-0.19-0.87
						c-0.05-0.29-0.06-0.59-0.06-0.89c0.01-1.19,0.41-2.35,1.08-3.3c0.67-0.96,1.66-1.71,2.78-2.11c0.56-0.21,1.14-0.31,1.72-0.34
						l1.07-0.04l8.34-0.27l4.17-0.13l2.08-0.06c0.06,0,0.05,0,0.07-0.01l0.04-0.01l0.08,0c0.05-0.03,0.11-0.02,0.15-0.04
						c0.04-0.02,0.1-0.03,0.14-0.06c0.02-0.01,0.05-0.02,0.07-0.03c0.02-0.02,0.04-0.04,0.06-0.05c0.17-0.11,0.31-0.31,0.38-0.51
						c0.08-0.21,0.08-0.41,0.02-0.61c-0.01-0.05-0.03-0.1-0.06-0.15c0.01-0.01-0.11-0.23-0.18-0.37l-0.47-0.93l-0.94-1.86
						l-3.76-7.45l-1.88-3.72l-0.24-0.47l-0.06-0.12l-0.03-0.06l-0.04-0.1l-0.17-0.4c-0.12-0.26-0.18-0.56-0.26-0.84
						c-0.06-0.29-0.1-0.59-0.13-0.88c-0.07-1.19,0.22-2.38,0.82-3.39c0.61-1,1.52-1.83,2.6-2.32c0.55-0.23,1.12-0.4,1.7-0.47
						l0.44-0.04c0.08-0.01,0.12-0.01,0.16-0.01l0.13,0l0.52-0.02l4.17-0.13l1.04-0.03l0.52-0.02l0.12,0l0.04,0
						c0.03,0,0.05-0.01,0.08-0.01c0.21-0.03,0.41-0.13,0.56-0.27c0.04-0.03,0.07-0.08,0.11-0.12l0.05-0.07
						c0.02-0.03,0.01-0.01,0.07-0.11l3.73-6.28c0.35-0.6,0.72-1.19,1.05-1.8c0.17-0.3,0.32-0.62,0.49-0.92
						c0.18-0.3,0.37-0.59,0.58-0.87c0.69-0.88,1.38-1.77,2.07-2.65c0.11-0.15,0.23-0.29,0.34-0.44c0.12-0.15,0.23-0.29,0.35-0.44
						l3.13,1.86c-0.07,0.17-0.14,0.34-0.22,0.51c-0.07,0.17-0.14,0.34-0.22,0.51c-0.44,1.03-0.89,2.06-1.34,3.09
						c-0.15,0.32-0.3,0.63-0.49,0.93c-0.18,0.3-0.38,0.58-0.57,0.87c-0.37,0.59-0.72,1.19-1.07,1.79l-3.73,6.28
						c-0.03,0.05-0.13,0.22-0.21,0.33c-0.08,0.12-0.17,0.24-0.26,0.36c-0.18,0.23-0.38,0.45-0.6,0.65
						c-0.86,0.81-1.97,1.34-3.13,1.52c-0.15,0.02-0.29,0.04-0.44,0.05l-0.22,0.02l-0.14,0.01l-0.52,0.02l-1.04,0.03l-4.17,0.13
						l-0.52,0.02l-0.13,0c-0.04,0-0.09,0-0.09,0.01c-0.03,0-0.05,0.01-0.08,0.01c-0.11,0-0.21,0.04-0.31,0.08
						c-0.18,0.09-0.34,0.23-0.46,0.42c-0.11,0.19-0.16,0.41-0.15,0.61c0.01,0.05,0.01,0.1,0.02,0.15c0.03,0.05,0.02,0.1,0.05,0.15
						c0.01,0.02,0.02,0.05,0.03,0.08l0.01,0.02l0.03,0.05l0.06,0.12l0.23,0.47l1.88,3.73l3.76,7.45l0.94,1.86l0.47,0.93
						c0.08,0.17,0.14,0.25,0.27,0.57c0.12,0.27,0.23,0.54,0.3,0.82c0.32,1.14,0.28,2.39-0.12,3.49c-0.39,1.11-1.11,2.09-2.07,2.78
						l-0.37,0.25c-0.13,0.08-0.26,0.15-0.39,0.21c-0.26,0.15-0.54,0.25-0.82,0.35c-0.28,0.1-0.57,0.15-0.86,0.22
						c-0.14,0.02-0.29,0.04-0.43,0.05l-0.22,0.02c-0.07,0-0.16,0.01-0.19,0.01l-2.09,0.07l-4.17,0.13l-8.35,0.26l-1.02,0.03
						c-0.12,0.02-0.22,0.02-0.32,0.06c-0.18,0.07-0.37,0.18-0.49,0.37c-0.12,0.18-0.21,0.39-0.2,0.6c0,0.05,0,0.1,0.01,0.15
						c0,0.05,0.02,0.1,0.03,0.15c0,0.06,0.05,0.1,0.06,0.16c0,0,0.05,0.11,0.09,0.18l0.12,0.23l3.76,7.45l1.88,3.72l0.94,1.86
						l0.47,0.93l0.18,0.35c0.02,0.04,0.03,0.07,0.07,0.15c0.11,0.27,0.24,0.53,0.32,0.82c0.36,1.12,0.36,2.38,0,3.5
						C418.15,1654.38,417.46,1655.39,416.53,1656.11z"
                            />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M479.04,1600.67c-2.05,0-4.07-0.55-5.88-1.62c-5.49-3.29-7.28-10.38-4.04-15.84c3.24-5.46,10.32-7.28,15.79-4.07
					c2.7,1.6,4.58,4.12,5.35,7.11c0.76,2.99,0.31,6.1-1.26,8.76l0,0c-1.58,2.65-4.09,4.54-7.08,5.3
					C480.96,1600.55,480,1600.67,479.04,1600.67z M479.07,1579.19c-3.4,0-6.7,1.74-8.55,4.85c-2.78,4.69-1.24,10.78,3.43,13.58
					c2.32,1.38,4.99,1.76,7.56,1.11c2.57-0.65,4.73-2.27,6.08-4.55l0,0c1.35-2.28,1.74-4.95,1.08-7.52
					c-0.66-2.57-2.27-4.73-4.55-6.08C482.54,1579.63,480.79,1579.19,479.07,1579.19z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M473.12,1599.02l-46.79-27.78c-2.65-1.58-4.54-4.09-5.3-7.08c-0.76-2.99-0.32-6.1,1.26-8.75
					c1.58-2.66,4.09-4.54,7.08-5.3c2.99-0.76,6.1-0.31,8.76,1.26l46.79,27.78l-0.83,1.4c-4.7-2.76-10.78-1.19-13.56,3.49
					c-2.78,4.69-1.24,10.78,3.43,13.58L473.12,1599.02z M432.24,1551.37c-0.82,0-1.65,0.1-2.47,0.31c-2.57,0.65-4.73,2.27-6.08,4.55
					c-1.35,2.28-1.74,4.95-1.08,7.52c0.66,2.57,2.27,4.73,4.55,6.08l41.76,24.79c-1.87-3.46-1.94-7.8,0.21-11.42
					c2.15-3.62,5.99-5.64,9.93-5.65l-41.76-24.79C435.74,1551.84,434,1551.37,432.24,1551.37z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M473.11,1599.02c-5.44-3.26-7.23-10.35-3.99-15.81c3.24-5.46,10.32-7.28,15.79-4.07l-0.83,1.4
					c-4.7-2.76-10.78-1.19-13.56,3.49c-2.78,4.69-1.24,10.78,3.43,13.58L473.11,1599.02z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="437.78"
                            y="1582.17"
                            transform="matrix(0.8599 0.5105 -0.5105 0.8599 873.0983 -4.4553)"
                            width="13.77"
                            height="12.12"
                        />
                    </g>
                    <g>
                        <path
                            className="st0"
                            d="M447.78,1598.07l-13.24-7.86l7.02-11.82l13.24,7.86L447.78,1598.07z M436.77,1589.64l10.44,6.2l5.36-9.02l-10.44-6.2
					L436.77,1589.64z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M91.31,1631.24c-47.23-24.74-57.82-87.48-59.47-99.81l1.62-0.22c1.62,12.19,12.09,74.21,58.61,98.58L91.31,1631.24z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M32.89,1532.35l-0.43-0.2c-0.13-0.06-13.34-6.55-4.37-28.18l0.51-1.23l0.86,1.02c0.4,0.47,9.69,11.53,8.51,20.73
					c-0.4,3.14-1.99,5.7-4.7,7.59L32.89,1532.35z M29.08,1505.89c-6.62,17.21,1.63,23.36,3.66,24.56c2.08-1.59,3.29-3.66,3.62-6.17
					C37.25,1517.36,31.35,1508.85,29.08,1505.89z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M38.01,1542.91c-1.2,0-2.42-0.2-3.67-0.61l-0.45-0.15l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.41l1.29-0.33l-0.29,1.3
					c-0.14,0.6-3.41,14.67-11.48,19.25C41.57,1542.44,39.83,1542.91,38.01,1542.91z M35.32,1540.89c2.52,0.71,4.91,0.43,7.11-0.81
					c6.07-3.44,9.33-13.27,10.34-16.86C35.03,1528.26,35.07,1538.55,35.32,1540.89z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M41.94,1558.3c-1.2,0-2.42-0.2-3.67-0.61l-0.45-0.15l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.41l1.29-0.33l-0.29,1.3
					c-0.14,0.6-3.41,14.67-11.48,19.25C45.51,1557.83,43.76,1558.3,41.94,1558.3z M39.26,1556.28c2.52,0.71,4.91,0.43,7.11-0.81
					c6.07-3.44,9.33-13.27,10.34-16.86C38.96,1543.65,39,1553.94,39.26,1556.28z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M47.74,1574.78c-1.2,0-2.42-0.2-3.67-0.61l-0.45-0.15l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.41l1.29-0.33l-0.29,1.3
					c-0.14,0.6-3.41,14.67-11.48,19.25C51.3,1574.31,49.55,1574.78,47.74,1574.78z M45.05,1572.76c2.52,0.71,4.91,0.43,7.11-0.81
					c6.07-3.44,9.33-13.27,10.34-16.86C44.75,1560.13,44.79,1570.42,45.05,1572.76z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M55.47,1591c-1.2,0-2.42-0.2-3.67-0.61l-0.45-0.15l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.4l1.29-0.33l-0.29,1.3
					c-0.14,0.6-3.41,14.67-11.48,19.24C59.04,1590.53,57.29,1591,55.47,1591z M52.79,1588.98c2.52,0.71,4.91,0.44,7.11-0.81
					c6.07-3.43,9.33-13.26,10.34-16.85C52.49,1576.35,52.53,1586.64,52.79,1588.98z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M64.79,1605.68c-1.2,0-2.42-0.2-3.67-0.61l-0.45-0.15l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.4l1.29-0.33l-0.29,1.3
					c-0.14,0.6-3.41,14.67-11.48,19.24C68.35,1605.2,66.6,1605.68,64.79,1605.68z M62.11,1603.66c2.52,0.7,4.9,0.43,7.1-0.81
					c6.07-3.44,9.33-13.26,10.34-16.85C61.98,1590.99,61.89,1601.35,62.11,1603.66z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M27.66,1549.07c-0.12,0-0.24,0-0.37-0.01c-9.27-0.28-18.73-11.21-19.12-11.68l-0.87-1.01l1.3-0.31
					c22.78-5.44,27.09,8.63,27.13,8.77l0.13,0.45l-0.33,0.34C33.32,1547.91,30.67,1549.07,27.66,1549.07z M10.34,1537.35
					c2.57,2.7,10.04,9.88,17.01,10.09c2.54,0.08,4.77-0.8,6.67-2.59C33.14,1542.65,28.39,1533.53,10.34,1537.35z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M33.32,1568.37c-0.12,0-0.24,0-0.37-0.01c-9.27-0.28-18.73-11.21-19.12-11.68l-0.87-1.01l1.3-0.31
					c22.78-5.44,27.09,8.62,27.13,8.77l0.13,0.45l-0.33,0.34C38.99,1567.21,36.34,1568.37,33.32,1568.37z M16.01,1556.65
					c2.57,2.7,10.04,9.88,17.01,10.09c2.54,0.07,4.77-0.8,6.67-2.59C38.81,1561.95,34.05,1552.83,16.01,1556.65z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M40.25,1585.72c-0.12,0-0.24,0-0.37-0.01c-9.27-0.28-18.73-11.21-19.12-11.68l-0.87-1.01l1.3-0.31
					c22.78-5.44,27.09,8.62,27.13,8.77l0.13,0.45l-0.33,0.34C45.91,1584.57,43.26,1585.72,40.25,1585.72z M22.93,1574
					c2.57,2.7,10.04,9.88,17.01,10.09c2.52,0.07,4.77-0.8,6.66-2.59C45.73,1579.3,40.97,1570.18,22.93,1574z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M49.31,1604.31c-8.92,0-18.77-8.75-19.2-9.14l-0.99-0.89l1.25-0.48c21.87-8.36,27.99,5.01,28.05,5.15l0.19,0.43
					l-0.28,0.38c-1.97,2.66-4.57,4.17-7.72,4.49C50.16,1604.29,49.74,1604.31,49.31,1604.31z M32.25,1594.84
					c2.9,2.35,11.24,8.49,18.18,7.79c2.52-0.25,4.62-1.41,6.27-3.44C55.55,1597.12,49.65,1588.7,32.25,1594.84z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M57.09,1620.88c-7.57,0-15.58-3.74-16.01-3.94l-1.21-0.57l1.06-0.81c18.57-14.27,28.25-3.19,28.35-3.08l0.3,0.36
					l-0.16,0.44c-1.13,3.11-3.19,5.3-6.12,6.51C61.4,1620.57,59.26,1620.88,57.09,1620.88z M43.04,1616.01
					c3.45,1.42,13.2,4.92,19.65,2.27c2.34-0.96,4.03-2.67,5.02-5.09C66.03,1611.54,57.96,1605.16,43.04,1616.01z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M401.46,1953.03c-1.53-6.09-3.51-12.35-5.89-18.6c-5.16-13.58-4.97-25.87,0.57-36.53c11-21.18,39.75-28.72,40.04-28.79
					l0.4,1.58c-0.28,0.07-28.34,7.44-39,27.97c-5.31,10.23-5.47,22.07-0.48,35.19c2.4,6.31,4.4,12.63,5.94,18.78L401.46,1953.03z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M442.9,1873.38c-2.86,0-5.41-1.07-7.61-3.19l-0.34-0.33l0.12-0.46c0.04-0.14,3.89-14.34,26.83-9.65l1.31,0.27
					l-0.83,1.04c-0.38,0.48-9.48,11.71-18.73,12.3C443.4,1873.37,443.15,1873.38,442.9,1873.38z M436.8,1869.36
					c1.96,1.74,4.22,2.54,6.75,2.38c6.96-0.44,14.19-7.86,16.67-10.64C442.05,1857.86,437.59,1867.14,436.8,1869.36z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M424.46,1874.86l-0.32-0.35c-0.1-0.11-9.83-11.14,6.71-27.71l0.94-0.94l0.41,1.27c0.19,0.58,4.56,14.36-0.05,22.41
					c-1.57,2.75-4.01,4.51-7.24,5.23L424.46,1874.86z M431.04,1848.95c-12.69,13.39-7.4,22.21-5.99,24.1
					c2.53-0.68,4.44-2.13,5.7-4.32C434.21,1862.67,432,1852.55,431.04,1848.95z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M409.13,1883.82l-0.36-0.31c-0.11-0.1-11.03-9.96,3.54-28.29l0.83-1.04l0.55,1.21c0.25,0.56,6.15,13.75,2.49,22.27
					c-1.25,2.91-3.47,4.93-6.61,6.01L409.13,1883.82z M412.74,1857.33c-10.98,14.6-4.78,22.91-3.22,24.62
					c2.43-0.96,4.17-2.62,5.17-4.94C417.44,1870.6,414.1,1860.8,412.74,1857.33z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M399.17,1895.45l-0.46-0.12c-0.14-0.04-14.29-4.09-9.27-26.97l0.28-1.3l1.03,0.85c0.47,0.39,11.58,9.64,12.03,18.9
					c0.16,3.16-0.95,5.96-3.29,8.31L399.17,1895.45z M390.75,1870.08c-3.48,18.11,5.73,22.7,7.94,23.53
					c1.76-1.93,2.59-4.19,2.47-6.71C400.82,1879.93,393.5,1872.6,390.75,1870.08z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M392.38,1905.34c-4.26,0-13.93-2.03-19.08-19.28l-0.38-1.28l1.31,0.24c0.6,0.11,14.8,2.82,19.69,10.7
					c1.67,2.69,2.06,5.67,1.15,8.86l-0.13,0.45l-0.46,0.11C394.45,1905.16,393.65,1905.34,392.38,1905.34z M375.27,1886.93
					c5.74,17.53,16.02,17.08,18.35,16.73c0.61-2.54,0.24-4.92-1.09-7.07C388.85,1890.66,378.9,1887.8,375.27,1886.93z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M387.92,1922.54c-4.94,0-12.63-2.44-19.68-14.88l-0.66-1.16l1.33-0.06c0.61-0.03,15.05-0.56,21.58,6.03
					c2.23,2.25,3.27,5.07,3.1,8.38l-0.02,0.47l-0.42,0.21C393.1,1921.55,391.06,1922.54,387.92,1922.54z M370.35,1908.05
					c9.51,15.8,19.43,13.06,21.63,12.2c0.02-2.61-0.87-4.85-2.65-6.64C384.43,1908.66,374.08,1908.09,370.35,1908.05z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M433.95,1888.41c-4.18,0-8.84-0.66-12.23-2.98c-2.61-1.79-4.17-4.36-4.62-7.64l-0.06-0.47l0.37-0.29
					c0.12-0.09,11.9-8.89,27.08,8.94l0.86,1.02l-1.3,0.31C443.72,1887.37,439.21,1888.41,433.95,1888.41z M418.8,1878.06
					c0.47,2.57,1.76,4.59,3.85,6.02c5.76,3.94,16.01,2.56,19.68,1.89C430.13,1872.39,420.78,1876.86,418.8,1878.06z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M414.74,1897.61c-2.26,0-4.48-0.32-6.46-1.16c-2.92-1.24-4.95-3.45-6.05-6.57l-0.16-0.44l0.31-0.36
					c0.1-0.11,9.9-11.08,28.31,3.39l1.05,0.82l-1.21,0.56C430.11,1894.04,422.24,1897.61,414.74,1897.61z M403.95,1889.8
					c0.97,2.43,2.64,4.16,4.97,5.15c6.42,2.72,16.2-0.67,19.67-2.05C413.79,1881.88,405.65,1888.17,403.95,1889.8z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M405.33,1916c-2.26,0-4.48-0.32-6.46-1.16c-2.92-1.24-4.95-3.45-6.05-6.57l-0.16-0.44l0.31-0.36
					c0.1-0.11,9.9-11.08,28.31,3.39l1.05,0.82l-1.21,0.56C420.7,1912.43,412.82,1916,405.33,1916z M394.54,1908.19
					c0.97,2.43,2.64,4.16,4.97,5.15c6.42,2.72,16.2-0.67,19.67-2.05C404.38,1900.27,396.24,1906.56,394.54,1908.19z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M401.75,1933.22c-2.75,0-5.24-1.01-7.41-3.02l-0.35-0.32l0.1-0.46c0.03-0.14,3.56-14.43,26.6-10.28l1.31,0.24
					l-0.81,1.06c-0.37,0.49-9.2,11.93-18.44,12.73C402.42,1933.2,402.08,1933.22,401.75,1933.22z M395.82,1929.34
					c1.99,1.69,4.28,2.43,6.79,2.21c6.94-0.6,14-8.19,16.41-11.03C400.97,1917.76,396.58,1927.15,395.82,1929.34z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M154.34,1908.4c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C159.76,1905.97,157.33,1908.4,154.34,1908.4z M154.34,1899.18c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79S156.43,1899.18,154.34,1899.18z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M137.76,1961.23c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42s5.42,2.43,5.42,5.42
				C143.18,1958.8,140.75,1961.23,137.76,1961.23z M137.76,1952.01c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79S139.85,1952.01,137.76,1952.01z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M350.13,1864.72c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42s5.42,2.43,5.42,5.42S353.12,1864.72,350.13,1864.72z
				 M350.13,1855.5c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79s3.79-1.7,3.79-3.79S352.22,1855.5,350.13,1855.5z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M454.72,1802.85c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C460.14,1800.42,457.71,1802.85,454.72,1802.85z M454.72,1793.64c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79S456.81,1793.64,454.72,1793.64z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M257.65,1729.73c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C263.08,1727.29,260.64,1729.73,257.65,1729.73z M257.65,1720.51c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79S259.75,1720.51,257.65,1720.51z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M202.17,1613.44c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				S205.16,1613.44,202.17,1613.44z M202.17,1604.22c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79c2.09,0,3.79-1.7,3.79-3.79
				S204.26,1604.22,202.17,1604.22z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M310.87,1530.96c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42s5.42,2.43,5.42,5.42S313.86,1530.96,310.87,1530.96z
				 M310.87,1521.74c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79c2.09,0,3.79-1.7,3.79-3.79S312.96,1521.74,310.87,1521.74z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M130.74,1544.56c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C136.16,1542.13,133.73,1544.56,130.74,1544.56z M130.74,1535.35c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79S132.83,1535.35,130.74,1535.35z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M452.81,1962.93c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42s5.42,2.43,5.42,5.42S455.8,1962.93,452.81,1962.93z
				 M452.81,1953.71c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79c2.09,0,3.79-1.7,3.79-3.79S454.9,1953.71,452.81,1953.71z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M344.39,1965.48c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C349.81,1963.05,347.38,1965.48,344.39,1965.48z M344.39,1956.26c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79C348.18,1957.97,346.48,1956.26,344.39,1956.26z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M120.24,1953.42l-6.88,3.15l-0.3-0.92c-2.45-5.35-8.79-7.71-14.13-5.26c-5.35,2.45-7.71,8.78-5.26,14.13l0.02,0.05
				l0.42,0.77l-0.78,0.39l-44.17,20.19l-18.21-39.83l0.82-0.3c0.11-0.04,0.22-0.09,0.33-0.14c5.35-2.45,7.71-8.78,5.26-14.13
				c-2.45-5.35-8.78-7.71-14.13-5.26c-0.11,0.05-0.22,0.1-0.32,0.16l-0.77,0.43l-5.48-11.99l12.29-5.62l0.35,0.7
				c2.58,5.15,8.71,7.3,13.96,4.91s7.62-8.44,5.42-13.77l-0.3-0.73l39.36-18l3.15,6.89l-0.74,0.34c-5.36,2.45-7.72,8.79-5.28,14.14
				c2.45,5.35,8.78,7.71,14.13,5.26l0.74-0.34l0.35,0.73L120.24,1953.42z M114.25,1954.37l3.84-1.75l-19.13-41.85
				c-6.01,2.29-12.85-0.46-15.56-6.38c-2.71-5.92-0.3-12.9,5.35-15.94l-1.81-3.95l-36.47,16.67c2.04,5.95-0.72,12.48-6.53,15.13
				s-12.55,0.47-15.72-4.96l-9.4,4.3l4.1,8.96c6.09-2.56,13.17,0.18,15.93,6.23c2.76,6.05,0.2,13.19-5.72,16.12l16.83,36.81
				l41.95-19.18c-2.37-6.03,0.37-12.95,6.33-15.68C104.21,1946.17,111.24,1948.63,114.25,1954.37z"
                    />
                </g>
                <g>
                    <path
                        className="st0"
                        d="M68.87,1947.31c-7.24,3.31-15.82,0.11-19.13-7.13s-0.11-15.82,7.13-19.13s15.82-0.11,19.13,7.13
				C79.31,1935.42,76.11,1944,68.87,1947.31z M57.54,1922.53c-6.42,2.94-9.26,10.55-6.32,16.97c2.94,6.42,10.55,9.26,16.97,6.32
				s9.26-10.55,6.32-16.97C71.58,1922.43,63.97,1919.6,57.54,1922.53z"
                    />
                </g>
                <g>
                    <path
                        className="st0"
                        d="M66.21,1968.77c-2.97,1.36-6.49,0.05-7.84-2.92s-0.05-6.48,2.92-7.84c2.97-1.36,6.49-0.05,7.84,2.92
				C70.49,1963.89,69.18,1967.41,66.21,1968.77z M61.97,1959.49c-2.15,0.98-3.1,3.53-2.12,5.68c0.98,2.15,3.53,3.1,5.68,2.12
				c2.15-0.98,3.1-3.53,2.12-5.68C66.67,1959.45,64.12,1958.5,61.97,1959.49z"
                    />
                </g>
                <g>
                    <path
                        className="st0"
                        d="M93.52,1935.09c-2.97,1.36-6.49,0.05-7.84-2.92c-1.36-2.97-0.05-6.48,2.92-7.84c2.97-1.36,6.49-0.05,7.84,2.92
				C97.79,1930.22,96.48,1933.74,93.52,1935.09z M89.27,1925.81c-2.15,0.98-3.1,3.53-2.12,5.68s3.53,3.1,5.68,2.12
				c2.15-0.98,3.1-3.53,2.12-5.68C93.97,1925.78,91.42,1924.83,89.27,1925.81z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <circle className="st0" cx="799.67" cy="1815.15" r="4.61" />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M806.44,1794.69c0.17,0.52,1.79,5.47-1.01,8.07c-2.6,2.41-7.32,1.2-9.79-0.86c-1.51-1.26-1.27-2.08-2.88-4.47
					c-3.59-5.31-7.5-5.22-7.78-7.78c-0.44-3.98,8.47-9.09,10.66-7.35c1.47,1.17-0.79,4.87,1.15,6.63c1.55,1.4,3.66-0.33,6.34,1.15
					C805.33,1791.31,806.12,1793.73,806.44,1794.69z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M789.87,1811.11c0.13,1.37-0.3,2.5-1.15,4.75c-1.22,3.2-1.83,4.8-3.02,5.04c-2.15,0.43-5.53-3.7-4.75-6.19
					c0.54-1.74,2.72-1.42,3.89-3.6c1.42-2.65-0.53-5.46,0.29-5.91C786.02,1804.72,789.53,1807.55,789.87,1811.11z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M871.35,1796.58c-0.7,0-1.4-0.07-2.1-0.22c-2.68-0.55-4.98-2.12-6.49-4.41l-77.85-118.48
					c-0.04-0.06-0.08-0.12-0.11-0.18c-6-11.09-5.25-24.42,1.96-34.8c0.25-0.36,0.51-0.71,0.77-1.06c0.18-0.24,0.19-0.56,0.03-0.81
					l-36.2-55.09c-0.34-0.52-0.46-1.14-0.34-1.76c0.13-0.61,0.48-1.14,1-1.48l13.98-9.19c0.52-0.34,1.15-0.46,1.76-0.34
					c0.61,0.13,1.14,0.48,1.48,1l36.2,55.09c0.16,0.25,0.46,0.37,0.75,0.3c0.42-0.1,0.85-0.2,1.28-0.28
					c12.38-2.51,24.92,2.09,32.72,12.01c0.05,0.06,0.09,0.12,0.13,0.18l77.85,118.47c3.1,4.72,1.79,11.09-2.94,14.19l-38.28,25.16
					C875.26,1796.01,873.32,1796.58,871.35,1796.58z M767.29,1570.36c-0.14,0-0.27,0.04-0.39,0.12l-13.98,9.19
					c-0.16,0.1-0.27,0.26-0.3,0.45c-0.04,0.18,0,0.37,0.1,0.53l36.2,55.09c0.54,0.82,0.51,1.9-0.09,2.69
					c-0.25,0.33-0.49,0.67-0.73,1.01c-6.86,9.87-7.57,22.55-1.87,33.09c0.01,0.02,0.02,0.04,0.04,0.06l77.86,118.48
					c1.26,1.92,3.2,3.24,5.45,3.71c2.25,0.47,4.55,0.03,6.48-1.24l38.28-25.16c1.92-1.26,3.24-3.2,3.7-5.45
					c0.47-2.25,0.03-4.55-1.24-6.48l-77.86-118.48c-0.01-0.02-0.03-0.04-0.04-0.06c-7.42-9.42-19.34-13.8-31.11-11.42
					c-0.41,0.08-0.81,0.17-1.22,0.27c-0.95,0.23-1.96-0.16-2.5-0.99l-36.2-55.09c-0.1-0.16-0.26-0.27-0.45-0.3
					C767.38,1570.37,767.34,1570.36,767.29,1570.36z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M867.76,1791.14c-1.03,0-2.07-0.16-3.09-0.48l0.49-1.56c2.48,0.78,5.15,0.41,7.32-1.02l15.3-10.05l0.9,1.36l-15.3,10.05
					C871.68,1790.56,869.73,1791.14,867.76,1791.14z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M865.21,1787.25c-0.7,0-1.4-0.07-2.1-0.22l0.33-1.6c2.26,0.47,4.55,0.03,6.48-1.24l3.89-2.56l0.89,1.36l-3.89,2.56
					C869.13,1786.67,867.19,1787.25,865.21,1787.25z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M833.6,1643.49c-5.36-6.84-14.34-10.15-22.87-8.42l-0.32-1.6c9.27-1.88,18.65,1.58,24.48,9.01L833.6,1643.49z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M747.44,1582.01c-0.27,0-0.54-0.03-0.81-0.08c-1.03-0.21-1.92-0.82-2.5-1.7l-4.89-7.45c-1.2-1.82-0.69-4.27,1.13-5.47
					l20.18-13.26c0.88-0.58,1.94-0.78,2.97-0.57c1.03,0.21,1.92,0.82,2.5,1.7l4.89,7.45c1.2,1.82,0.69,4.27-1.13,5.47l-20.18,13.26
					C748.95,1581.79,748.2,1582.01,747.44,1582.01z M762.71,1555.04c-0.45,0-0.89,0.13-1.27,0.38l-20.18,13.26
					c-1.07,0.7-1.37,2.14-0.66,3.21l4.89,7.45c0.34,0.52,0.86,0.87,1.47,1c0.61,0.13,1.23,0.01,1.74-0.33l20.18-13.26
					c1.07-0.7,1.37-2.14,0.66-3.21l-4.89-7.45c-0.34-0.52-0.86-0.87-1.47-1C763.03,1555.05,762.87,1555.04,762.71,1555.04z"
                        />
                    </g>
                </g>
                <path
                    className="st0"
                    d="M815.03,1716.36l29.26,44.53c13.36-5.83,33.07-16.53,52.58-34.78l-37.36-56.85c-19.48,18.17-39.15,28.9-52.58,34.78
			l4.04,6.15c5.98-2.93,12.02-6.19,17.97-9.72l0.83,1.4c-5.93,3.51-11.94,6.76-17.9,9.69l2.25,3.42c5.97-2.92,12.02-6.19,17.97-9.72
			c0.1-0.06,10.3-6.16,12.26-7.81l1.05,1.25c-2.06,1.73-12.05,7.71-12.47,7.96C827,1710.19,820.98,1713.44,815.03,1716.36z"
                />
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="755.94"
                            y="1572.15"
                            transform="matrix(0.8357 -0.5492 0.5492 0.8357 -744.105 680.7999)"
                            width="19.74"
                            height="23.84"
                        />
                    </g>
                    <g>
                        <path
                            className="st0"
                            d="M763.87,1600.58l-13.99-21.29l17.86-11.74l13.99,21.29L763.87,1600.58z M752.14,1579.76l12.2,18.57l15.14-9.95
					l-12.2-18.57L752.14,1579.76z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M603.74,1749.59c-1.5-0.45-3.08-0.69-4.73-0.69c-9.07,0-16.42,7.35-16.42,16.42c0,2.49,0.55,4.85,1.55,6.96l-0.92,1.06
					l-18.05,3.26l10.56-16.9c0,0-26.12,2.3-26.69,2.3c-0.58,0,13.06-15.75,13.06-15.75l-19.39-21.32l25.16,6.34v-22.66l20.16,20.36
					l9.03-17.48L603.74,1749.59z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M615.13,1852.27c-9.5,0-17.24-7.73-17.24-17.24c0-2.45,0.5-4.81,1.49-7.02l0.24-0.53l0.58,0.05
						c0.45,0.04,0.92,0.06,1.39,0.06c6.15,0,11.75-3.63,14.26-9.25l0.24-0.53l0.58,0.05c8.81,0.77,15.7,8.31,15.7,17.17
						C632.37,1844.54,624.64,1852.27,615.13,1852.27z M600.66,1829.2c-0.75,1.85-1.13,3.81-1.13,5.84c0,8.6,7,15.61,15.61,15.61
						c8.6,0,15.61-7,15.61-15.61c0-7.83-5.95-14.52-13.65-15.48c-2.88,5.89-8.9,9.67-15.49,9.67
						C601.28,1829.23,600.97,1829.22,600.66,1829.2z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M600.06,1829.16c-8.81-0.77-15.7-8.31-15.7-17.17c0-9.5,7.73-17.24,17.24-17.24c9.5,0,17.24,7.73,17.24,17.24
						c0,2.45-0.5,4.81-1.49,7.02l-1.49-0.66c0.9-2.01,1.35-4.15,1.35-6.36c0-8.6-7-15.61-15.61-15.61c-8.6,0-15.61,7-15.61,15.61
						c0,8.02,6.24,14.84,14.22,15.54L600.06,1829.16z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M630.98,1831.24h-0.08v-1.63h0.08c8.6,0,15.61-7,15.61-15.61c0-3.75-1.35-7.38-3.81-10.22
						c-2.97-3.42-7.27-5.39-11.8-5.39c-0.19,0-0.38,0-0.57,0.01l-0.06-1.63c0.21-0.01,0.42-0.01,0.63-0.01c5,0,9.75,2.17,13.03,5.95
						c2.71,3.13,4.21,7.14,4.21,11.29C648.22,1823.51,640.48,1831.24,630.98,1831.24z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M617.51,1808.32l-0.22-1.61c7.67-1.06,13.45-7.7,13.45-15.46c0-8.6-7-15.61-15.61-15.61c-8.6,0-15.61,7-15.61,15.61
						c0,1.46,0.2,2.89,0.59,4.25l-1.57,0.45c-0.43-1.51-0.65-3.09-0.65-4.7c0-9.5,7.73-17.24,17.24-17.24s17.24,7.73,17.24,17.24
						C632.37,1799.81,625.98,1807.15,617.51,1808.32z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M586.68,1805.61c-8.19-1.49-14.13-8.62-14.13-16.95c0-9.5,7.73-17.24,17.23-17.24c5.82,0,11.21,2.91,14.41,7.78
						l-1.36,0.9c-2.9-4.41-7.78-7.05-13.05-7.05c-8.6,0-15.6,7-15.6,15.61c0,7.55,5.38,14,12.79,15.35L586.68,1805.61z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M643.48,1804.07l-0.17-1.62c7.94-0.84,13.94-7.52,13.94-15.52c0-8.6-7-15.61-15.61-15.61
						c-6.03,0-11.41,3.37-14.04,8.79l-1.47-0.71c2.87-5.9,8.95-9.71,15.51-9.71c9.5,0,17.24,7.73,17.24,17.24
						C658.87,1795.77,652.25,1803.13,643.48,1804.07z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M640.76,1770.99l-1.41-0.81c1.34-2.34,2.05-5.02,2.05-7.74c0-8.6-7-15.61-15.61-15.61c-5.7,0-10.94,3.1-13.69,8.1
						l-1.43-0.78c3.03-5.52,8.82-8.95,15.11-8.95c9.5,0,17.24,7.73,17.24,17.24C643.03,1765.44,642.25,1768.4,640.76,1770.99z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M612.86,1775.57l-1.31-0.97c0.41-0.56,0.8-1.16,1.14-1.78c1.25-2.29,1.92-4.88,1.92-7.5c0-3.77-1.36-7.4-3.83-10.24
						c-1.94-2.23-4.46-3.86-7.28-4.7c-1.44-0.44-2.96-0.66-4.5-0.66c-8.6,0-15.61,7-15.61,15.61c0,2.32,0.49,4.54,1.47,6.61
						c0.12,0.27,0.25,0.52,0.38,0.77l-1.43,0.78c-0.15-0.28-0.3-0.57-0.43-0.86c-1.07-2.28-1.62-4.74-1.62-7.3
						c0-9.5,7.73-17.24,17.24-17.24c1.7,0,3.37,0.24,4.97,0.73c3.11,0.93,5.89,2.73,8.04,5.2c2.73,3.14,4.23,7.15,4.23,11.31
						c0,2.89-0.73,5.76-2.12,8.29C613.74,1774.3,613.32,1774.96,612.86,1775.57z"
                            />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M612.69,1752.5c-2.1-4.93-2.4-8.9-2.28-11.64c0.22-5.05,1.91-6.82,2.86-14.58c0.43-3.53,0.71-5.81,0.29-8.93
					c-0.39-2.91-1.04-7.73-4.9-11.81c-4.24-4.49-9.68-5.35-11.63-5.51c-3.92-0.32-7.09,0.32-7.09,0.32
					c21.03,0.58,23.33,19.3,19.3,30.82c-4.03,11.52,1.15,22.76,1.15,22.76l1.01,0.6l1.59-2.19"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M611.68,1726.29c0,0,9.36-3.02,8.79-12.96c-0.58-9.94-3.17-13.25-3.17-13.25s3.89,3.6,4.61,7.06
					c0.72,3.46,2.59,11.52-2.45,15.99c-5.04,4.47-7.49,6.34-8.21,9.22L611.68,1726.29z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="587.27" y="1819.19" width="15.15" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="590.45" y="1823.23" width="11.97" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="584.94" y="1810.94" width="17.48" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="585.62" y="1814.97" width="16.8" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="587.91" y="1802.1" width="14.5" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="585.96" y="1806.13" width="16.45" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <rect className="st0" x="575.55" y="1795.57" width="12.22" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="578.73" y="1799.6" width="5.44" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="573.22" y="1787.31" width="17.48" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="573.9" y="1791.34" width="16.8" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="576.2" y="1778.48" width="7.97" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="574.25" y="1782.51" width="13.38" height="1.63" />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <rect className="st0" x="581.17" y="1766.91" width="12.22" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="578.84" y="1758.65" width="17.48" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="579.52" y="1762.68" width="16.8" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="579.87" y="1753.85" width="13.38" height="1.63" />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="598.72" y="1836.81" width="15.99" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="599.72" y="1840.85" width="11.52" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <polygon
                            className="st0"
                            points="674.22,1719.89 658.64,1686.43 647.23,1710.41 633.39,1663.28 634.95,1662.82 647.59,1705.86 658.66,1682.6 
					674.19,1715.97 685.2,1691.47 715.97,1692.3 697.86,1669.73 717.85,1651.9 676.9,1656.39 686.9,1634.45 636.77,1660.05 
					636.03,1658.6 690.32,1630.88 679.57,1654.45 722.74,1649.72 700.1,1669.92 719.44,1694.02 686.25,1693.12 				"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M636.38,1659.35l-2.8,1.26l0.58,2.44c-1.7-1.27-4.33-2.9-8.36-4.8c-9.79-4.61-5.57-14.98-5.57-14.98
					s0.24,2.64,1.08,5.45c1.18,3.95,4.07,5.97,4.68,6.46c1.85,1.47,3.54,2.08,5.76,2.88c1.73,0.62,3.31,1.01,4.64,1.26
					L636.38,1659.35z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M579.27,1680.15c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
					S582.26,1680.15,579.27,1680.15z M579.27,1670.93c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79c2.09,0,3.79-1.7,3.79-3.79
					S581.36,1670.93,579.27,1670.93z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M538.37,1797.97c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
					C543.79,1795.54,541.36,1797.97,538.37,1797.97z M538.37,1788.75c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
					c2.09,0,3.79-1.7,3.79-3.79C542.16,1790.45,540.46,1788.75,538.37,1788.75z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M664.54,1766.28c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
					S667.53,1766.28,664.54,1766.28z M664.54,1757.06c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79c2.09,0,3.79-1.7,3.79-3.79
					S666.63,1757.06,664.54,1757.06z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M662.24,1830.52c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
					S665.23,1830.52,662.24,1830.52z M662.24,1821.3c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79c2.09,0,3.79-1.7,3.79-3.79
					S664.33,1821.3,662.24,1821.3z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <polygon
                            className="st0"
                            points="717.41,1693.92 639.05,1663.38 633.32,1661.38 633.86,1659.84 639.62,1661.85 718,1692.4 				"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <polygon
                            className="st0"
                            points="670.21,1687.66 667.82,1673.52 680.79,1667.52 681.48,1668.99 669.64,1674.48 671.82,1687.38 				"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <polygon
                            className="st0"
                            points="649.93,1679.86 648.3,1679.69 649.66,1666.69 657.95,1659.27 659.04,1660.48 651.22,1667.49 				"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M800.62,1832.32l-3.25,13.48c-0.08,0.38-0.16,0.76-0.25,1.14c-3.67,15.22-18.99,24.59-34.21,20.92
					c-15.23-3.67-24.59-18.99-20.92-34.22c0.1-0.4,0.2-0.8,0.32-1.2l3.23-13.4c0,0,8.64,15.52,20.15,15.33
					C777.18,1834.17,785.43,1818.58,800.62,1832.32z"
                        />
                    </g>
                    <g>
                        <path
                            className="st0"
                            d="M769.55,1869.46c-2.26,0-4.55-0.26-6.84-0.82c-7.58-1.83-13.99-6.49-18.05-13.14c-4.07-6.65-5.3-14.48-3.47-22.06
					c0.1-0.42,0.21-0.83,0.33-1.23l3.71-15.4l1.02,1.82c0.08,0.15,8.44,14.91,19.22,14.91c0.06,0,0.13,0,0.19,0
					c3.74-0.06,7.12-1.83,10.69-3.71c7.07-3.71,14.39-7.55,24.81,1.88l0.36,0.32l-3.36,13.95c-0.07,0.35-0.15,0.75-0.25,1.14
					C794.7,1860.47,782.72,1869.46,769.55,1869.46z M745.86,1821.14l-2.77,11.49c-0.12,0.42-0.22,0.81-0.32,1.2
					c-1.72,7.15-0.56,14.55,3.28,20.82c3.84,6.28,9.89,10.68,17.04,12.41c14.76,3.56,29.67-5.56,33.23-20.32
					c0.09-0.37,0.17-0.74,0.24-1.11l3.14-13.03c-9.38-8.25-15.8-4.88-22.6-1.32c-3.59,1.88-7.3,3.83-11.42,3.9
					C756.06,1835.33,748.54,1825.27,745.86,1821.14z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M764.26,1824.96c-11.6,0-16.45-14.38-16.5-14.53l-0.07-0.22l6.6-27.39l1.58,0.38l-6.49,26.93
					c0.65,1.78,5.59,14.19,16.17,13.13l0.16,1.62C765.22,1824.94,764.73,1824.96,764.26,1824.96z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M764.82,1808.41c-0.1-0.01-10.25-0.71-13.69-12l1.56-0.47c3.09,10.17,11.85,10.82,12.22,10.84L764.82,1808.41z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M749.58,1950.66l-1.62-0.14c2.67-30.48,10.63-55.61,15.14-67.87c0.43-1.17,1.59-1.93,2.85-1.84
						c17.88,1.31,33.91-10.41,38.12-27.87c0.12-0.49,0.23-0.98,0.32-1.47l16.13-66.92c0.07-0.31,0.02-0.63-0.14-0.9
						c-0.17-0.27-0.43-0.46-0.74-0.54l-68.64-16.55c-0.31-0.07-0.63-0.02-0.9,0.14c-0.27,0.17-0.46,0.43-0.54,0.74l-16.1,66.79
						c-0.15,0.53-0.29,1.03-0.42,1.58c-4.21,17.45,4.72,35.19,21.23,42.18c1.15,0.49,1.85,1.7,1.7,2.94
						c-1.58,12.97-5.94,38.97-17.45,67.32l-1.51-0.61c11.44-28.18,15.78-54.01,17.34-66.9c0.06-0.53-0.22-1.03-0.72-1.24
						c-17.25-7.3-26.57-25.83-22.17-44.06c0.14-0.57,0.28-1.1,0.43-1.62l16.09-66.75c0.18-0.73,0.63-1.35,1.27-1.74
						c0.64-0.39,1.4-0.51,2.13-0.34l68.64,16.55c0.73,0.18,1.35,0.63,1.74,1.27c0.39,0.64,0.51,1.4,0.34,2.13l-16.13,66.88
						c-0.09,0.48-0.2,0.99-0.33,1.5c-4.4,18.23-21.15,30.48-39.82,29.11c-0.53-0.04-1.02,0.28-1.2,0.77
						C760.15,1895.4,752.24,1920.37,749.58,1950.66z"
                            />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M768.92,1968.27c-8.69,0-18.95-1.34-29.48-3.88c-12.57-3.03-24.03-7.41-32.27-12.33c-8.5-5.08-12.62-10.23-11.59-14.49
					c1.96-8.14,22.46-10.17,47.68-4.7l-0.34,1.59c-23.53-5.1-44.06-3.53-45.75,3.49c-0.84,3.46,3.12,8.1,10.84,12.71
					c8.1,4.84,19.4,9.15,31.81,12.15c12.41,2.99,24.44,4.3,33.85,3.69c8.98-0.59,14.61-2.91,15.44-6.37
					c1.68-6.96-15.71-17.67-38.76-23.86l0.42-1.57c11.65,3.13,22.14,7.41,29.52,12.06c7.79,4.9,11.39,9.66,10.4,13.76
					c-1.03,4.27-7.04,6.97-16.92,7.62C772.22,1968.22,770.6,1968.27,768.92,1968.27z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="711.51"
                            y="1925.19"
                            transform="matrix(0.2344 -0.9721 0.9721 0.2344 -1342.1515 2178.9998)"
                            width="1.63"
                            height="32.84"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="707.37"
                            y="1936.08"
                            transform="matrix(0.2344 -0.9721 0.9721 0.2344 -1349.8287 2178.5081)"
                            width="1.63"
                            height="20.31"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="711"
                            y="1925.33"
                            transform="matrix(0.2344 -0.9721 0.9721 0.2344 -1335.7972 2173.2056)"
                            width="1.63"
                            height="18.68"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M916.53,1656.11c-0.93,0.73-2.11,1.15-3.27,1.23l-0.57,0.05c-0.17,0.02-0.35,0.03-0.52,0.04
						c-0.35,0.02-0.7,0.02-1.05,0l-4.19-0.39l-2.09-0.22l-0.26-0.03c-0.08-0.01-0.2-0.02-0.22-0.03c-0.1-0.02-0.2-0.03-0.3-0.03
						c-0.21-0.01-0.42,0.02-0.63,0.08c-0.21,0.06-0.42,0.16-0.62,0.29c-0.1,0.07-0.19,0.14-0.28,0.22l-0.13,0.14l-0.17,0.2
						l-1.34,1.63c-0.91,1.08-1.8,2.17-2.73,3.23c0.49-1.32,1.02-2.63,1.53-3.94l0.79-1.96l0.1-0.25c0.04-0.09,0.1-0.2,0.15-0.3
						c0.11-0.2,0.24-0.4,0.38-0.58c0.28-0.38,0.63-0.71,1.03-0.99c0.39-0.28,0.84-0.5,1.31-0.65c0.24-0.07,0.48-0.13,0.73-0.17
						l0.55-0.09l2.08-0.35l4.15-0.66c0.35-0.05,0.69-0.07,1.04-0.07c0.17,0,0.35,0,0.52,0l0.26,0.01l0.13,0l0.06,0l0.02-0.01
						c0.23,0.01,0.41-0.07,0.58-0.19c0.16-0.13,0.29-0.32,0.36-0.53c0.06-0.21,0.05-0.41,0-0.61c0-0.05-0.05-0.1-0.06-0.16
						c0,0-0.02-0.05-0.04-0.08l-0.06-0.12l-0.12-0.24l-0.47-0.93l-0.94-1.86l-1.88-3.72l-3.76-7.45l-0.12-0.23
						c-0.04-0.08-0.07-0.13-0.14-0.29c-0.11-0.27-0.24-0.53-0.31-0.82c-0.09-0.28-0.16-0.57-0.19-0.87
						c-0.05-0.29-0.06-0.59-0.06-0.89c0.01-1.19,0.41-2.35,1.08-3.3c0.67-0.96,1.66-1.71,2.78-2.11c0.56-0.21,1.14-0.31,1.72-0.34
						l1.07-0.04l8.34-0.27l4.17-0.13l2.08-0.06c0.06,0,0.05,0,0.07-0.01l0.04-0.01l0.08,0c0.05-0.03,0.11-0.02,0.15-0.04
						c0.04-0.02,0.1-0.03,0.14-0.06c0.02-0.01,0.05-0.02,0.07-0.03c0.02-0.02,0.04-0.04,0.06-0.05c0.17-0.11,0.31-0.31,0.38-0.51
						c0.08-0.21,0.08-0.41,0.02-0.61c-0.01-0.05-0.03-0.1-0.06-0.15c0.01-0.01-0.11-0.23-0.18-0.37l-0.47-0.93l-0.94-1.86
						l-3.76-7.45l-1.88-3.72l-0.24-0.47l-0.06-0.12l-0.03-0.06l-0.04-0.1l-0.17-0.4c-0.12-0.26-0.18-0.56-0.26-0.84
						c-0.06-0.29-0.1-0.59-0.13-0.88c-0.07-1.19,0.22-2.38,0.82-3.39c0.61-1,1.52-1.83,2.6-2.32c0.55-0.23,1.12-0.4,1.7-0.47
						l0.44-0.04c0.08-0.01,0.12-0.01,0.16-0.01l0.13,0l0.52-0.02l4.17-0.13l1.04-0.03l0.52-0.02l0.12,0l0.04,0
						c0.03,0,0.05-0.01,0.08-0.01c0.21-0.03,0.41-0.13,0.56-0.27c0.04-0.03,0.07-0.08,0.11-0.12l0.05-0.07
						c0.02-0.03,0.01-0.01,0.07-0.11l3.73-6.28c0.35-0.6,0.72-1.19,1.05-1.8c0.17-0.3,0.32-0.62,0.49-0.92
						c0.18-0.3,0.37-0.59,0.58-0.87c0.69-0.88,1.38-1.77,2.07-2.65c0.11-0.15,0.23-0.29,0.34-0.44c0.12-0.15,0.23-0.29,0.35-0.44
						l3.13,1.86c-0.07,0.17-0.14,0.34-0.22,0.51c-0.07,0.17-0.14,0.34-0.22,0.51c-0.44,1.03-0.89,2.06-1.34,3.09
						c-0.15,0.32-0.3,0.63-0.49,0.93c-0.18,0.3-0.38,0.58-0.57,0.87c-0.37,0.59-0.72,1.19-1.07,1.79l-3.73,6.28
						c-0.03,0.05-0.13,0.22-0.21,0.33c-0.08,0.12-0.17,0.24-0.26,0.36c-0.18,0.23-0.38,0.45-0.6,0.65
						c-0.86,0.81-1.97,1.34-3.13,1.52c-0.15,0.02-0.29,0.04-0.44,0.05l-0.22,0.02l-0.14,0.01l-0.52,0.02l-1.04,0.03l-4.17,0.13
						l-0.52,0.02l-0.13,0c-0.04,0-0.09,0-0.09,0.01c-0.03,0-0.05,0.01-0.08,0.01c-0.11,0-0.21,0.04-0.31,0.08
						c-0.18,0.09-0.34,0.23-0.46,0.42c-0.11,0.19-0.16,0.41-0.15,0.61c0.01,0.05,0.01,0.1,0.02,0.15c0.03,0.05,0.02,0.1,0.05,0.15
						c0.01,0.02,0.02,0.05,0.03,0.08l0.01,0.02l0.03,0.05l0.06,0.12l0.23,0.47l1.88,3.73l3.76,7.45l0.94,1.86l0.47,0.93
						c0.08,0.17,0.14,0.25,0.27,0.57c0.12,0.27,0.23,0.54,0.3,0.82c0.32,1.14,0.28,2.39-0.12,3.49c-0.39,1.11-1.11,2.09-2.07,2.78
						l-0.37,0.25c-0.13,0.08-0.26,0.15-0.39,0.21c-0.26,0.15-0.54,0.25-0.82,0.35c-0.28,0.1-0.57,0.15-0.86,0.22
						c-0.14,0.02-0.29,0.04-0.43,0.05l-0.22,0.02c-0.07,0-0.16,0.01-0.19,0.01l-2.09,0.07l-4.17,0.13l-8.35,0.26l-1.02,0.03
						c-0.12,0.02-0.22,0.02-0.32,0.06c-0.18,0.07-0.37,0.18-0.49,0.37c-0.12,0.18-0.21,0.39-0.2,0.6c0,0.05,0,0.1,0.01,0.15
						c0,0.05,0.02,0.1,0.03,0.15c0,0.06,0.05,0.1,0.06,0.16c0,0,0.05,0.11,0.09,0.18l0.12,0.23l3.76,7.45l1.88,3.72l0.94,1.86
						l0.47,0.93l0.18,0.35c0.02,0.04,0.03,0.07,0.07,0.15c0.11,0.27,0.24,0.53,0.32,0.82c0.36,1.12,0.36,2.38,0,3.5
						C918.15,1654.38,917.46,1655.39,916.53,1656.11z"
                            />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M979.04,1600.67c-2.05,0-4.07-0.55-5.88-1.62c-5.49-3.29-7.28-10.38-4.04-15.84c3.24-5.46,10.32-7.28,15.79-4.07
					c2.7,1.6,4.58,4.12,5.35,7.11c0.76,2.99,0.31,6.1-1.26,8.76l0,0c-1.58,2.65-4.09,4.54-7.08,5.3
					C980.96,1600.55,980,1600.67,979.04,1600.67z M979.07,1579.19c-3.4,0-6.7,1.74-8.55,4.85c-2.78,4.69-1.24,10.78,3.43,13.58
					c2.32,1.38,4.99,1.76,7.56,1.11c2.57-0.65,4.73-2.27,6.08-4.55l0,0c1.35-2.28,1.74-4.95,1.08-7.52
					c-0.66-2.57-2.27-4.73-4.55-6.08C982.54,1579.63,980.79,1579.19,979.07,1579.19z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M973.12,1599.02l-46.79-27.78c-2.65-1.58-4.54-4.09-5.3-7.08c-0.76-2.99-0.32-6.1,1.26-8.75
					c1.58-2.66,4.09-4.54,7.08-5.3c2.99-0.76,6.1-0.31,8.76,1.26l46.79,27.78l-0.83,1.4c-4.7-2.76-10.78-1.19-13.56,3.49
					c-2.78,4.69-1.24,10.78,3.43,13.58L973.12,1599.02z M932.24,1551.37c-0.82,0-1.65,0.1-2.47,0.31c-2.57,0.65-4.73,2.27-6.08,4.55
					c-1.35,2.28-1.74,4.95-1.08,7.52c0.66,2.57,2.27,4.73,4.55,6.08l41.76,24.79c-1.87-3.46-1.94-7.8,0.21-11.42
					c2.15-3.62,5.99-5.64,9.93-5.65l-41.76-24.79C935.74,1551.84,934,1551.37,932.24,1551.37z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M973.11,1599.02c-5.44-3.26-7.23-10.35-3.99-15.81c3.24-5.46,10.32-7.28,15.79-4.07l-0.83,1.4
					c-4.7-2.76-10.78-1.19-13.56,3.49c-2.78,4.69-1.24,10.78,3.43,13.58L973.11,1599.02z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="937.78"
                            y="1582.17"
                            transform="matrix(0.8599 0.5105 -0.5105 0.8599 943.1595 -259.7054)"
                            width="13.77"
                            height="12.12"
                        />
                    </g>
                    <g>
                        <path
                            className="st0"
                            d="M947.78,1598.07l-13.24-7.86l7.02-11.82l13.24,7.86L947.78,1598.07z M936.77,1589.64l10.44,6.2l5.36-9.02l-10.44-6.2
					L936.77,1589.64z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M591.31,1631.24c-47.23-24.74-57.82-87.48-59.47-99.81l1.62-0.22c1.62,12.19,12.09,74.21,58.61,98.58L591.31,1631.24z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M532.89,1532.35l-0.43-0.2c-0.13-0.06-13.34-6.55-4.37-28.18l0.51-1.23l0.86,1.02c0.4,0.47,9.69,11.53,8.51,20.73
					c-0.4,3.14-1.99,5.7-4.7,7.59L532.89,1532.35z M529.08,1505.89c-6.62,17.21,1.63,23.36,3.66,24.56
					c2.08-1.59,3.29-3.66,3.62-6.17C537.25,1517.36,531.35,1508.85,529.08,1505.89z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M538.01,1542.91c-1.2,0-2.42-0.2-3.67-0.61l-0.45-0.15l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.41l1.29-0.33l-0.29,1.3
					c-0.14,0.6-3.41,14.67-11.48,19.25C541.57,1542.44,539.83,1542.91,538.01,1542.91z M535.32,1540.89
					c2.52,0.71,4.91,0.43,7.11-0.81c6.07-3.44,9.33-13.27,10.34-16.86C535.03,1528.26,535.07,1538.55,535.32,1540.89z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M541.94,1558.3c-1.2,0-2.42-0.2-3.67-0.61l-0.45-0.15l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.41l1.29-0.33l-0.29,1.3
					c-0.14,0.6-3.41,14.67-11.48,19.25C545.51,1557.83,543.76,1558.3,541.94,1558.3z M539.26,1556.28c2.52,0.71,4.91,0.43,7.11-0.81
					c6.07-3.44,9.33-13.27,10.34-16.86C538.96,1543.65,539,1553.94,539.26,1556.28z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M547.74,1574.78c-1.2,0-2.42-0.2-3.67-0.61l-0.45-0.15l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.41l1.29-0.33l-0.29,1.3
					c-0.14,0.6-3.41,14.67-11.48,19.25C551.3,1574.31,549.55,1574.78,547.74,1574.78z M545.05,1572.76
					c2.52,0.71,4.91,0.43,7.11-0.81c6.07-3.44,9.33-13.27,10.34-16.86C544.75,1560.13,544.79,1570.42,545.05,1572.76z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M555.47,1591c-1.2,0-2.42-0.2-3.67-0.61l-0.45-0.15l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.4l1.29-0.33l-0.29,1.3
					c-0.14,0.6-3.41,14.67-11.48,19.24C559.04,1590.53,557.29,1591,555.47,1591z M552.79,1588.98c2.52,0.71,4.91,0.44,7.11-0.81
					c6.07-3.43,9.33-13.26,10.34-16.85C552.49,1576.35,552.53,1586.64,552.79,1588.98z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M564.79,1605.68c-1.2,0-2.42-0.2-3.67-0.61l-0.45-0.15l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.4l1.29-0.33l-0.29,1.3
					c-0.14,0.6-3.41,14.67-11.48,19.24C568.35,1605.2,566.6,1605.68,564.79,1605.68z M562.11,1603.66c2.52,0.7,4.9,0.43,7.1-0.81
					c6.07-3.44,9.33-13.26,10.34-16.85C561.98,1590.99,561.89,1601.35,562.11,1603.66z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M527.66,1549.07c-0.12,0-0.24,0-0.37-0.01c-9.27-0.28-18.73-11.21-19.12-11.68l-0.87-1.01l1.3-0.31
					c22.78-5.44,27.09,8.63,27.13,8.77l0.13,0.45l-0.33,0.34C533.32,1547.91,530.67,1549.07,527.66,1549.07z M510.34,1537.35
					c2.57,2.7,10.04,9.88,17.01,10.09c2.54,0.08,4.77-0.8,6.67-2.59C533.14,1542.65,528.39,1533.53,510.34,1537.35z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M533.32,1568.37c-0.12,0-0.24,0-0.37-0.01c-9.27-0.28-18.73-11.21-19.12-11.68l-0.87-1.01l1.3-0.31
					c22.78-5.44,27.09,8.62,27.13,8.77l0.13,0.45l-0.33,0.34C538.99,1567.21,536.34,1568.37,533.32,1568.37z M516.01,1556.65
					c2.57,2.7,10.04,9.88,17.01,10.09c2.54,0.07,4.77-0.8,6.67-2.59C538.81,1561.95,534.05,1552.83,516.01,1556.65z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M540.25,1585.72c-0.12,0-0.24,0-0.37-0.01c-9.27-0.28-18.73-11.21-19.12-11.68l-0.87-1.01l1.3-0.31
					c22.78-5.44,27.09,8.62,27.13,8.77l0.13,0.45l-0.33,0.34C545.91,1584.57,543.26,1585.72,540.25,1585.72z M522.93,1574
					c2.57,2.7,10.04,9.88,17.01,10.09c2.52,0.07,4.77-0.8,6.66-2.59C545.73,1579.3,540.97,1570.18,522.93,1574z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M549.31,1604.31c-8.92,0-18.77-8.75-19.2-9.14l-0.99-0.89l1.25-0.48c21.87-8.36,27.99,5.01,28.05,5.15l0.19,0.43
					l-0.28,0.38c-1.97,2.66-4.57,4.17-7.72,4.49C550.16,1604.29,549.74,1604.31,549.31,1604.31z M532.25,1594.84
					c2.9,2.35,11.24,8.49,18.18,7.79c2.52-0.25,4.62-1.41,6.27-3.44C555.55,1597.12,549.65,1588.7,532.25,1594.84z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M557.09,1620.88c-7.57,0-15.58-3.74-16.01-3.94l-1.21-0.57l1.06-0.81c18.57-14.27,28.25-3.19,28.35-3.08l0.3,0.36
					l-0.16,0.44c-1.13,3.11-3.19,5.3-6.12,6.51C561.4,1620.57,559.26,1620.88,557.09,1620.88z M543.04,1616.01
					c3.45,1.42,13.2,4.92,19.65,2.27c2.34-0.96,4.03-2.67,5.02-5.09C566.03,1611.54,557.96,1605.16,543.04,1616.01z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M901.46,1953.03c-1.53-6.09-3.51-12.35-5.89-18.6c-5.16-13.58-4.97-25.87,0.57-36.53c11-21.18,39.75-28.72,40.04-28.79
					l0.4,1.58c-0.28,0.07-28.34,7.44-39,27.97c-5.31,10.23-5.47,22.07-0.48,35.19c2.4,6.31,4.4,12.63,5.94,18.78L901.46,1953.03z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M942.9,1873.38c-2.86,0-5.41-1.07-7.61-3.19l-0.34-0.33l0.12-0.46c0.04-0.14,3.89-14.34,26.83-9.65l1.31,0.27
					l-0.83,1.04c-0.38,0.48-9.48,11.71-18.73,12.3C943.4,1873.37,943.15,1873.38,942.9,1873.38z M936.8,1869.36
					c1.96,1.74,4.22,2.54,6.75,2.38c6.96-0.44,14.19-7.86,16.67-10.64C942.05,1857.86,937.59,1867.14,936.8,1869.36z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M924.46,1874.86l-0.32-0.35c-0.1-0.11-9.83-11.14,6.71-27.71l0.94-0.94l0.41,1.27c0.19,0.58,4.56,14.36-0.05,22.41
					c-1.57,2.75-4.01,4.51-7.24,5.23L924.46,1874.86z M931.04,1848.95c-12.69,13.39-7.4,22.21-5.99,24.1
					c2.53-0.68,4.44-2.13,5.7-4.32C934.21,1862.67,932,1852.55,931.04,1848.95z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M909.13,1883.82l-0.36-0.31c-0.11-0.1-11.03-9.96,3.54-28.29l0.83-1.04l0.55,1.21c0.25,0.56,6.15,13.75,2.49,22.27
					c-1.25,2.91-3.47,4.93-6.61,6.01L909.13,1883.82z M912.74,1857.33c-10.98,14.6-4.78,22.91-3.22,24.62
					c2.43-0.96,4.17-2.62,5.17-4.94C917.44,1870.6,914.1,1860.8,912.74,1857.33z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M899.17,1895.45l-0.46-0.12c-0.14-0.04-14.29-4.09-9.27-26.97l0.28-1.3l1.03,0.85c0.47,0.39,11.58,9.64,12.03,18.9
					c0.16,3.16-0.95,5.96-3.29,8.31L899.17,1895.45z M890.75,1870.08c-3.48,18.11,5.73,22.7,7.94,23.53
					c1.76-1.93,2.59-4.19,2.47-6.71C900.82,1879.93,893.5,1872.6,890.75,1870.08z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M892.38,1905.34c-4.26,0-13.93-2.03-19.08-19.28l-0.38-1.28l1.31,0.24c0.6,0.11,14.8,2.82,19.69,10.7
					c1.67,2.69,2.06,5.67,1.15,8.86l-0.13,0.45l-0.46,0.11C894.45,1905.16,893.65,1905.34,892.38,1905.34z M875.27,1886.93
					c5.74,17.53,16.02,17.08,18.35,16.73c0.61-2.54,0.24-4.92-1.09-7.07C888.85,1890.66,878.9,1887.8,875.27,1886.93z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M887.92,1922.54c-4.94,0-12.63-2.44-19.68-14.88l-0.66-1.16l1.33-0.06c0.61-0.03,15.05-0.56,21.58,6.03
					c2.23,2.25,3.27,5.07,3.1,8.38l-0.02,0.47l-0.42,0.21C893.1,1921.55,891.06,1922.54,887.92,1922.54z M870.35,1908.05
					c9.51,15.8,19.43,13.06,21.63,12.2c0.02-2.61-0.87-4.85-2.65-6.64C884.43,1908.66,874.08,1908.09,870.35,1908.05z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M933.95,1888.41c-4.18,0-8.84-0.66-12.23-2.98c-2.61-1.79-4.17-4.36-4.62-7.64l-0.06-0.47l0.37-0.29
					c0.12-0.09,11.9-8.89,27.08,8.94l0.86,1.02l-1.3,0.31C943.72,1887.37,939.21,1888.41,933.95,1888.41z M918.8,1878.06
					c0.47,2.57,1.76,4.59,3.85,6.02c5.76,3.94,16.01,2.56,19.68,1.89C930.13,1872.39,920.78,1876.86,918.8,1878.06z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M914.74,1897.61c-2.26,0-4.48-0.32-6.46-1.16c-2.92-1.24-4.95-3.45-6.05-6.57l-0.16-0.44l0.31-0.36
					c0.1-0.11,9.9-11.08,28.31,3.39l1.05,0.82l-1.21,0.56C930.11,1894.04,922.24,1897.61,914.74,1897.61z M903.95,1889.8
					c0.97,2.43,2.64,4.16,4.97,5.15c6.42,2.72,16.2-0.67,19.67-2.05C913.79,1881.88,905.65,1888.17,903.95,1889.8z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M905.33,1916c-2.26,0-4.48-0.32-6.46-1.16c-2.92-1.24-4.95-3.45-6.05-6.57l-0.16-0.44l0.31-0.36
					c0.1-0.11,9.9-11.08,28.31,3.39l1.05,0.82l-1.21,0.56C920.7,1912.43,912.82,1916,905.33,1916z M894.54,1908.19
					c0.97,2.43,2.64,4.16,4.97,5.15c6.42,2.72,16.2-0.67,19.67-2.05C904.38,1900.27,896.24,1906.56,894.54,1908.19z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M901.75,1933.22c-2.75,0-5.24-1.01-7.41-3.02l-0.35-0.32l0.1-0.46c0.03-0.14,3.56-14.43,26.6-10.28l1.31,0.24
					l-0.81,1.06c-0.37,0.49-9.2,11.93-18.44,12.73C902.42,1933.2,902.08,1933.22,901.75,1933.22z M895.82,1929.34
					c1.99,1.69,4.28,2.43,6.79,2.21c6.94-0.6,14-8.19,16.41-11.03C900.97,1917.76,896.58,1927.15,895.82,1929.34z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M654.34,1908.4c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C659.76,1905.97,657.33,1908.4,654.34,1908.4z M654.34,1899.18c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79S656.43,1899.18,654.34,1899.18z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M637.76,1961.23c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C643.18,1958.8,640.75,1961.23,637.76,1961.23z M637.76,1952.01c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79
				s3.79-1.7,3.79-3.79S639.85,1952.01,637.76,1952.01z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M850.13,1864.72c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				S853.12,1864.72,850.13,1864.72z M850.13,1855.5c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79c2.09,0,3.79-1.7,3.79-3.79
				S852.22,1855.5,850.13,1855.5z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M954.72,1802.85c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C960.14,1800.42,957.71,1802.85,954.72,1802.85z M954.72,1793.64c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79S956.81,1793.64,954.72,1793.64z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M757.65,1729.73c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C763.08,1727.29,760.64,1729.73,757.65,1729.73z M757.65,1720.51c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79S759.75,1720.51,757.65,1720.51z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M702.17,1613.44c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				S705.16,1613.44,702.17,1613.44z M702.17,1604.22c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79c2.09,0,3.79-1.7,3.79-3.79
				S704.26,1604.22,702.17,1604.22z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M810.87,1530.96c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				S813.86,1530.96,810.87,1530.96z M810.87,1521.74c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79c2.09,0,3.79-1.7,3.79-3.79
				S812.96,1521.74,810.87,1521.74z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M630.74,1544.56c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C636.16,1542.13,633.73,1544.56,630.74,1544.56z M630.74,1535.35c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79S632.83,1535.35,630.74,1535.35z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M952.81,1962.93c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				S955.8,1962.93,952.81,1962.93z M952.81,1953.71c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79s3.79-1.7,3.79-3.79
				S954.9,1953.71,952.81,1953.71z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M844.39,1965.48c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C849.81,1963.05,847.38,1965.48,844.39,1965.48z M844.39,1956.26c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79C848.18,1957.97,846.48,1956.26,844.39,1956.26z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M620.24,1953.42l-6.88,3.15l-0.3-0.92c-2.45-5.35-8.79-7.71-14.13-5.26c-5.35,2.45-7.71,8.78-5.26,14.13l0.02,0.05
				l0.42,0.77l-0.78,0.39l-44.17,20.19l-18.21-39.83l0.82-0.3c0.11-0.04,0.22-0.09,0.33-0.14c5.35-2.45,7.71-8.78,5.26-14.13
				c-2.45-5.35-8.78-7.71-14.13-5.26c-0.11,0.05-0.22,0.1-0.32,0.16l-0.77,0.43l-5.48-11.99l12.29-5.62l0.35,0.7
				c2.58,5.15,8.71,7.3,13.96,4.91s7.62-8.44,5.42-13.77l-0.3-0.73l39.36-18l3.15,6.89l-0.74,0.34c-5.36,2.45-7.72,8.79-5.28,14.14
				s8.78,7.71,14.13,5.26l0.74-0.34l0.35,0.73L620.24,1953.42z M614.25,1954.37l3.84-1.75l-19.13-41.85
				c-6.01,2.29-12.85-0.46-15.56-6.38c-2.71-5.92-0.3-12.9,5.35-15.94l-1.81-3.95l-36.47,16.67c2.04,5.95-0.72,12.48-6.53,15.13
				s-12.55,0.47-15.72-4.96l-9.4,4.3l4.1,8.96c6.09-2.56,13.17,0.18,15.93,6.23c2.76,6.05,0.2,13.19-5.72,16.12l16.83,36.81
				l41.95-19.18c-2.37-6.03,0.37-12.95,6.33-15.68C604.21,1946.17,611.24,1948.63,614.25,1954.37z"
                    />
                </g>
                <g>
                    <path
                        className="st0"
                        d="M568.87,1947.31c-7.24,3.31-15.82,0.11-19.13-7.13s-0.11-15.82,7.13-19.13c7.24-3.31,15.82-0.11,19.13,7.13
				S576.11,1944,568.87,1947.31z M557.54,1922.53c-6.42,2.94-9.26,10.55-6.32,16.97c2.94,6.42,10.55,9.26,16.97,6.32
				c6.42-2.94,9.26-10.55,6.32-16.97C571.58,1922.43,563.97,1919.6,557.54,1922.53z"
                    />
                </g>
                <g>
                    <path
                        className="st0"
                        d="M566.21,1968.77c-2.97,1.36-6.48,0.05-7.84-2.92s-0.05-6.48,2.92-7.84c2.97-1.36,6.49-0.05,7.84,2.92
				C570.49,1963.89,569.18,1967.41,566.21,1968.77z M561.97,1959.49c-2.15,0.98-3.1,3.53-2.12,5.68c0.98,2.15,3.53,3.1,5.68,2.12
				c2.15-0.98,3.1-3.53,2.12-5.68C566.67,1959.45,564.12,1958.5,561.97,1959.49z"
                    />
                </g>
                <g>
                    <path
                        className="st0"
                        d="M593.52,1935.09c-2.97,1.36-6.48,0.05-7.84-2.92s-0.05-6.48,2.92-7.84c2.97-1.36,6.48-0.05,7.84,2.92
				S596.48,1933.74,593.52,1935.09z M589.27,1925.81c-2.15,0.98-3.1,3.53-2.12,5.68s3.53,3.1,5.68,2.12
				c2.15-0.98,3.1-3.53,2.12-5.68S591.42,1924.83,589.27,1925.81z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <circle className="st0" cx="1299.67" cy="1815.15" r="4.61" />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1306.44,1794.69c0.17,0.52,1.79,5.47-1.01,8.07c-2.6,2.41-7.32,1.2-9.79-0.86c-1.52-1.26-1.27-2.08-2.88-4.47
					c-3.59-5.31-7.5-5.22-7.78-7.78c-0.44-3.98,8.47-9.09,10.66-7.35c1.47,1.17-0.79,4.87,1.15,6.63c1.55,1.4,3.66-0.33,6.34,1.15
					C1305.33,1791.31,1306.12,1793.73,1306.44,1794.69z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1289.87,1811.11c0.13,1.37-0.3,2.5-1.15,4.75c-1.22,3.2-1.83,4.8-3.02,5.04c-2.15,0.43-5.53-3.7-4.75-6.19
					c0.54-1.74,2.72-1.42,3.89-3.6c1.42-2.65-0.53-5.46,0.29-5.91C1286.02,1804.72,1289.53,1807.55,1289.87,1811.11z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1371.35,1796.58c-0.7,0-1.4-0.07-2.1-0.22c-2.68-0.55-4.98-2.12-6.49-4.41l-77.85-118.48
					c-0.04-0.06-0.08-0.12-0.11-0.18c-6-11.09-5.25-24.42,1.96-34.8c0.25-0.36,0.51-0.71,0.77-1.06c0.18-0.24,0.19-0.56,0.03-0.81
					l-36.2-55.09c-0.34-0.52-0.46-1.14-0.34-1.76c0.13-0.61,0.48-1.14,1-1.48l13.98-9.19c0.52-0.34,1.15-0.46,1.76-0.34
					c0.61,0.13,1.14,0.48,1.48,1l36.2,55.09c0.16,0.25,0.46,0.37,0.75,0.3c0.42-0.1,0.85-0.2,1.28-0.28
					c12.38-2.51,24.92,2.09,32.72,12.01c0.05,0.06,0.09,0.12,0.13,0.18l77.85,118.47c3.1,4.72,1.79,11.09-2.94,14.19l-38.28,25.16
					C1375.26,1796.01,1373.32,1796.58,1371.35,1796.58z M1267.29,1570.36c-0.14,0-0.27,0.04-0.39,0.12l-13.98,9.19
					c-0.16,0.1-0.27,0.26-0.3,0.45c-0.04,0.18,0,0.37,0.1,0.53l36.2,55.09c0.54,0.82,0.51,1.9-0.09,2.69
					c-0.25,0.33-0.49,0.67-0.73,1.01c-6.86,9.87-7.57,22.55-1.87,33.09c0.01,0.02,0.02,0.04,0.04,0.06l77.86,118.48
					c1.26,1.92,3.2,3.24,5.45,3.71c2.25,0.47,4.55,0.03,6.48-1.24l38.28-25.16c1.92-1.26,3.24-3.2,3.7-5.45
					c0.47-2.25,0.03-4.55-1.24-6.48l-77.86-118.48c-0.01-0.02-0.03-0.04-0.04-0.06c-7.42-9.42-19.34-13.8-31.11-11.42
					c-0.41,0.08-0.81,0.17-1.22,0.27c-0.95,0.23-1.96-0.16-2.5-0.99l-36.2-55.09c-0.1-0.16-0.26-0.27-0.45-0.3
					C1267.38,1570.37,1267.34,1570.36,1267.29,1570.36z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1367.76,1791.14c-1.03,0-2.07-0.16-3.09-0.48l0.49-1.56c2.48,0.78,5.15,0.41,7.32-1.02l15.3-10.05l0.9,1.36
					l-15.3,10.05C1371.68,1790.56,1369.73,1791.14,1367.76,1791.14z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1365.21,1787.25c-0.7,0-1.4-0.07-2.1-0.22l0.33-1.6c2.26,0.47,4.55,0.03,6.48-1.24l3.89-2.56l0.89,1.36l-3.89,2.56
					C1369.13,1786.67,1367.19,1787.25,1365.21,1787.25z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1333.6,1643.49c-5.36-6.84-14.34-10.15-22.87-8.42l-0.32-1.6c9.27-1.88,18.65,1.58,24.48,9.01L1333.6,1643.49z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1247.44,1582.01c-0.27,0-0.54-0.03-0.81-0.08c-1.03-0.21-1.92-0.82-2.5-1.7l-4.89-7.45c-1.2-1.82-0.69-4.27,1.13-5.47
					l20.18-13.26c0.88-0.58,1.94-0.78,2.97-0.57c1.03,0.21,1.92,0.82,2.5,1.7l4.89,7.45c1.2,1.82,0.69,4.27-1.13,5.47l-20.18,13.26
					C1248.94,1581.79,1248.2,1582.01,1247.44,1582.01z M1262.71,1555.04c-0.45,0-0.89,0.13-1.27,0.38l-20.18,13.26
					c-1.07,0.7-1.37,2.14-0.66,3.21l4.89,7.45c0.34,0.52,0.86,0.87,1.47,1c0.61,0.13,1.23,0.01,1.74-0.33l20.18-13.26
					c1.07-0.7,1.37-2.14,0.66-3.21l-4.89-7.45c-0.34-0.52-0.86-0.87-1.47-1C1263.03,1555.05,1262.87,1555.04,1262.71,1555.04z"
                        />
                    </g>
                </g>
                <path
                    className="st0"
                    d="M1315.03,1716.36l29.26,44.53c13.36-5.83,33.07-16.53,52.58-34.78l-37.36-56.85c-19.48,18.17-39.15,28.9-52.58,34.78
			l4.04,6.15c5.98-2.93,12.02-6.19,17.97-9.72l0.83,1.4c-5.93,3.51-11.94,6.76-17.9,9.69l2.25,3.42c5.97-2.92,12.02-6.19,17.97-9.72
			c0.1-0.06,10.3-6.16,12.26-7.81l1.05,1.25c-2.06,1.73-12.05,7.71-12.47,7.96C1327,1710.19,1320.98,1713.44,1315.03,1716.36z"
                />
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="1255.94"
                            y="1572.15"
                            transform="matrix(0.8357 -0.5492 0.5492 0.8357 -661.9615 955.3831)"
                            width="19.74"
                            height="23.84"
                        />
                    </g>
                    <g>
                        <path
                            className="st0"
                            d="M1263.87,1600.58l-13.99-21.29l17.86-11.74l13.99,21.29L1263.87,1600.58z M1252.14,1579.76l12.2,18.57l15.14-9.95
					l-12.2-18.57L1252.14,1579.76z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1103.73,1749.59c-1.5-0.45-3.08-0.69-4.73-0.69c-9.07,0-16.42,7.35-16.42,16.42c0,2.49,0.55,4.85,1.55,6.96l-0.92,1.06
					l-18.05,3.26l10.56-16.9c0,0-26.12,2.3-26.69,2.3c-0.58,0,13.06-15.75,13.06-15.75l-19.39-21.32l25.16,6.34v-22.66l20.16,20.36
					l9.03-17.48L1103.73,1749.59z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1115.13,1852.27c-9.5,0-17.24-7.73-17.24-17.24c0-2.45,0.5-4.81,1.49-7.02l0.24-0.53l0.58,0.05
						c0.45,0.04,0.92,0.06,1.39,0.06c6.15,0,11.75-3.63,14.26-9.25l0.24-0.53l0.58,0.05c8.81,0.77,15.7,8.31,15.7,17.17
						C1132.37,1844.54,1124.64,1852.27,1115.13,1852.27z M1100.66,1829.2c-0.75,1.85-1.13,3.81-1.13,5.84c0,8.6,7,15.61,15.61,15.61
						c8.6,0,15.61-7,15.61-15.61c0-7.83-5.95-14.52-13.65-15.48c-2.88,5.89-8.9,9.67-15.49,9.67
						C1101.28,1829.23,1100.97,1829.22,1100.66,1829.2z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1100.06,1829.16c-8.81-0.77-15.7-8.31-15.7-17.17c0-9.5,7.73-17.24,17.24-17.24c9.5,0,17.24,7.73,17.24,17.24
						c0,2.45-0.5,4.81-1.49,7.02l-1.49-0.66c0.9-2.01,1.35-4.15,1.35-6.36c0-8.6-7-15.61-15.61-15.61c-8.6,0-15.61,7-15.61,15.61
						c0,8.02,6.24,14.84,14.22,15.54L1100.06,1829.16z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1130.98,1831.24h-0.08v-1.63h0.08c8.6,0,15.61-7,15.61-15.61c0-3.75-1.35-7.38-3.81-10.22
						c-2.97-3.42-7.27-5.39-11.8-5.39c-0.19,0-0.38,0-0.57,0.01l-0.06-1.63c0.21-0.01,0.42-0.01,0.63-0.01c5,0,9.75,2.17,13.03,5.95
						c2.71,3.13,4.21,7.14,4.21,11.29C1148.22,1823.51,1140.48,1831.24,1130.98,1831.24z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1117.51,1808.32l-0.22-1.61c7.67-1.06,13.45-7.7,13.45-15.46c0-8.6-7-15.61-15.61-15.61c-8.6,0-15.61,7-15.61,15.61
						c0,1.46,0.2,2.89,0.59,4.25l-1.57,0.45c-0.43-1.51-0.65-3.09-0.65-4.7c0-9.5,7.73-17.24,17.24-17.24s17.24,7.73,17.24,17.24
						C1132.37,1799.81,1125.98,1807.15,1117.51,1808.32z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1086.68,1805.61c-8.19-1.49-14.13-8.62-14.13-16.95c0-9.5,7.73-17.24,17.23-17.24c5.82,0,11.21,2.91,14.41,7.78
						l-1.36,0.9c-2.9-4.41-7.78-7.05-13.05-7.05c-8.6,0-15.6,7-15.6,15.61c0,7.55,5.38,14,12.79,15.35L1086.68,1805.61z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1143.48,1804.07l-0.17-1.62c7.94-0.84,13.94-7.52,13.94-15.52c0-8.6-7-15.61-15.61-15.61
						c-6.03,0-11.41,3.37-14.04,8.79l-1.47-0.71c2.87-5.9,8.95-9.71,15.51-9.71c9.5,0,17.24,7.73,17.24,17.24
						C1158.87,1795.77,1152.25,1803.13,1143.48,1804.07z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1140.76,1770.99l-1.41-0.81c1.34-2.34,2.05-5.02,2.05-7.74c0-8.6-7-15.61-15.61-15.61c-5.7,0-10.94,3.1-13.69,8.1
						l-1.43-0.78c3.03-5.52,8.82-8.95,15.11-8.95c9.5,0,17.24,7.73,17.24,17.24C1143.03,1765.44,1142.25,1768.4,1140.76,1770.99z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1112.86,1775.57l-1.31-0.97c0.41-0.56,0.8-1.16,1.14-1.78c1.25-2.29,1.92-4.88,1.92-7.5c0-3.77-1.36-7.4-3.83-10.24
						c-1.94-2.23-4.46-3.86-7.28-4.7c-1.44-0.44-2.96-0.66-4.5-0.66c-8.6,0-15.61,7-15.61,15.61c0,2.32,0.49,4.54,1.47,6.61
						c0.12,0.27,0.25,0.52,0.38,0.77l-1.43,0.78c-0.15-0.28-0.3-0.57-0.43-0.86c-1.07-2.28-1.62-4.74-1.62-7.3
						c0-9.5,7.73-17.24,17.24-17.24c1.7,0,3.37,0.24,4.97,0.73c3.11,0.93,5.89,2.73,8.04,5.2c2.73,3.14,4.23,7.15,4.23,11.31
						c0,2.89-0.73,5.76-2.12,8.29C1113.74,1774.3,1113.32,1774.96,1112.86,1775.57z"
                            />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1112.69,1752.5c-2.1-4.93-2.4-8.9-2.28-11.64c0.22-5.05,1.91-6.82,2.86-14.58c0.43-3.53,0.71-5.81,0.29-8.93
					c-0.39-2.91-1.04-7.73-4.9-11.81c-4.24-4.49-9.68-5.35-11.63-5.51c-3.92-0.32-7.09,0.32-7.09,0.32
					c21.03,0.58,23.33,19.3,19.3,30.82c-4.03,11.52,1.15,22.76,1.15,22.76l1.01,0.6l1.59-2.19"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1111.68,1726.29c0,0,9.36-3.02,8.79-12.96c-0.58-9.94-3.17-13.25-3.17-13.25s3.89,3.6,4.61,7.06
					c0.72,3.46,2.59,11.52-2.45,15.99s-7.49,6.34-8.21,9.22L1111.68,1726.29z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="1087.27" y="1819.19" width="15.15" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="1090.45" y="1823.23" width="11.97" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="1084.94" y="1810.94" width="17.48" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="1085.62" y="1814.97" width="16.8" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="1087.91" y="1802.1" width="14.5" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="1085.96" y="1806.13" width="16.45" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <rect className="st0" x="1075.55" y="1795.57" width="12.22" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="1078.73" y="1799.6" width="5.44" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="1073.22" y="1787.31" width="17.48" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="1073.9" y="1791.34" width="16.8" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="1076.2" y="1778.48" width="7.97" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="1074.25" y="1782.51" width="13.38" height="1.63" />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <rect className="st0" x="1081.17" y="1766.91" width="12.22" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="1078.84" y="1758.65" width="17.48" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="1079.52" y="1762.68" width="16.8" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="1079.87" y="1753.85" width="13.38" height="1.63" />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="1098.72" y="1836.81" width="15.99" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="1099.72" y="1840.85" width="11.52" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <polygon
                            className="st0"
                            points="1174.22,1719.89 1158.64,1686.43 1147.23,1710.41 1133.38,1663.28 1134.95,1662.82 1147.59,1705.86 
					1158.66,1682.6 1174.19,1715.97 1185.2,1691.47 1215.97,1692.3 1197.86,1669.73 1217.85,1651.9 1176.9,1656.39 1186.9,1634.45 
					1136.77,1660.05 1136.03,1658.6 1190.32,1630.88 1179.57,1654.45 1222.74,1649.72 1200.1,1669.92 1219.44,1694.02 
					1186.25,1693.12 				"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1136.38,1659.35l-2.8,1.26l0.58,2.44c-1.7-1.27-4.33-2.9-8.36-4.8c-9.79-4.61-5.57-14.98-5.57-14.98
					s0.24,2.64,1.08,5.45c1.18,3.95,4.07,5.97,4.68,6.46c1.85,1.47,3.54,2.08,5.76,2.88c1.73,0.62,3.31,1.01,4.64,1.26
					L1136.38,1659.35z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1079.27,1680.15c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
					S1082.26,1680.15,1079.27,1680.15z M1079.27,1670.93c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79c2.09,0,3.79-1.7,3.79-3.79
					S1081.36,1670.93,1079.27,1670.93z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1038.37,1797.97c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
					C1043.79,1795.54,1041.36,1797.97,1038.37,1797.97z M1038.37,1788.75c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
					c2.09,0,3.79-1.7,3.79-3.79C1042.16,1790.45,1040.46,1788.75,1038.37,1788.75z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1164.54,1766.28c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
					S1167.53,1766.28,1164.54,1766.28z M1164.54,1757.06c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79c2.09,0,3.79-1.7,3.79-3.79
					S1166.63,1757.06,1164.54,1757.06z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1162.24,1830.52c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
					S1165.23,1830.52,1162.24,1830.52z M1162.24,1821.3c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79c2.09,0,3.79-1.7,3.79-3.79
					S1164.33,1821.3,1162.24,1821.3z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <polygon
                            className="st0"
                            points="1217.41,1693.92 1139.05,1663.38 1133.32,1661.38 1133.85,1659.84 1139.62,1661.85 1218,1692.4 				"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <polygon
                            className="st0"
                            points="1170.21,1687.66 1167.82,1673.52 1180.79,1667.52 1181.48,1668.99 1169.64,1674.48 1171.82,1687.38 				"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <polygon
                            className="st0"
                            points="1149.93,1679.86 1148.3,1679.69 1149.66,1666.69 1157.95,1659.27 1159.04,1660.48 1151.22,1667.49 				"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1300.62,1832.32l-3.25,13.48c-0.08,0.38-0.16,0.76-0.25,1.14c-3.67,15.22-18.99,24.59-34.21,20.92
					c-15.23-3.67-24.59-18.99-20.92-34.22c0.1-0.4,0.2-0.8,0.32-1.2l3.23-13.4c0,0,8.64,15.52,20.15,15.33
					C1277.18,1834.17,1285.43,1818.58,1300.62,1832.32z"
                        />
                    </g>
                    <g>
                        <path
                            className="st0"
                            d="M1269.55,1869.46c-2.26,0-4.55-0.26-6.84-0.82c-7.58-1.83-13.99-6.49-18.05-13.14c-4.07-6.65-5.3-14.48-3.47-22.06
					c0.1-0.42,0.21-0.83,0.33-1.23l3.71-15.4l1.02,1.82c0.08,0.15,8.44,14.91,19.22,14.91c0.06,0,0.13,0,0.19,0
					c3.74-0.06,7.12-1.83,10.69-3.71c7.07-3.71,14.39-7.55,24.81,1.88l0.36,0.32l-3.36,13.95c-0.07,0.35-0.15,0.75-0.25,1.14
					C1294.7,1860.47,1282.72,1869.46,1269.55,1869.46z M1245.86,1821.14l-2.77,11.49c-0.12,0.42-0.22,0.81-0.32,1.2
					c-1.72,7.15-0.56,14.55,3.28,20.82c3.84,6.28,9.89,10.68,17.04,12.41c14.76,3.56,29.67-5.56,33.23-20.32
					c0.09-0.37,0.17-0.74,0.24-1.11l3.14-13.03c-9.38-8.25-15.8-4.88-22.6-1.32c-3.59,1.88-7.3,3.83-11.42,3.9
					C1256.06,1835.33,1248.54,1825.27,1245.86,1821.14z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1264.26,1824.96c-11.6,0-16.45-14.38-16.5-14.53l-0.07-0.22l6.6-27.39l1.58,0.38l-6.49,26.93
					c0.65,1.78,5.59,14.19,16.17,13.13l0.16,1.62C1265.22,1824.94,1264.73,1824.96,1264.26,1824.96z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1264.82,1808.41c-0.1-0.01-10.25-0.71-13.69-12l1.56-0.47c3.09,10.17,11.85,10.82,12.22,10.84L1264.82,1808.41z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1249.58,1950.66l-1.62-0.14c2.67-30.48,10.63-55.61,15.14-67.87c0.43-1.17,1.59-1.93,2.85-1.84
						c17.88,1.31,33.91-10.41,38.12-27.87c0.12-0.49,0.23-0.98,0.32-1.47l16.13-66.92c0.07-0.31,0.02-0.63-0.14-0.9
						c-0.17-0.27-0.43-0.46-0.74-0.54l-68.64-16.55c-0.31-0.07-0.63-0.02-0.9,0.14c-0.27,0.17-0.46,0.43-0.54,0.74l-16.1,66.79
						c-0.15,0.53-0.29,1.03-0.42,1.58c-4.21,17.45,4.72,35.19,21.23,42.18c1.15,0.49,1.85,1.7,1.7,2.94
						c-1.58,12.97-5.94,38.97-17.45,67.32l-1.51-0.61c11.44-28.18,15.78-54.01,17.34-66.9c0.06-0.53-0.22-1.03-0.72-1.24
						c-17.25-7.3-26.57-25.83-22.17-44.06c0.14-0.57,0.28-1.1,0.43-1.62l16.09-66.75c0.18-0.73,0.63-1.35,1.27-1.74
						c0.64-0.39,1.4-0.51,2.13-0.34l68.64,16.55c0.73,0.18,1.35,0.63,1.74,1.27c0.39,0.64,0.51,1.4,0.34,2.13l-16.13,66.88
						c-0.09,0.48-0.2,0.99-0.33,1.5c-4.4,18.23-21.15,30.48-39.82,29.11c-0.53-0.04-1.02,0.28-1.2,0.77
						C1260.15,1895.4,1252.24,1920.37,1249.58,1950.66z"
                            />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1268.92,1968.27c-8.69,0-18.95-1.34-29.48-3.88c-12.57-3.03-24.03-7.41-32.27-12.33c-8.5-5.08-12.62-10.23-11.59-14.49
					c1.96-8.14,22.46-10.17,47.68-4.7l-0.34,1.59c-23.53-5.1-44.06-3.53-45.75,3.49c-0.83,3.46,3.12,8.1,10.84,12.71
					c8.1,4.84,19.4,9.15,31.81,12.15c12.41,2.99,24.44,4.3,33.85,3.69c8.98-0.59,14.61-2.91,15.44-6.37
					c1.68-6.96-15.71-17.67-38.76-23.86l0.42-1.57c11.65,3.13,22.14,7.41,29.52,12.06c7.79,4.9,11.39,9.66,10.4,13.76
					c-1.03,4.27-7.04,6.97-16.92,7.62C1272.22,1968.22,1270.6,1968.27,1268.92,1968.27z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="1211.51"
                            y="1925.19"
                            transform="matrix(0.2344 -0.9721 0.9721 0.2344 -959.346 2665.0713)"
                            width="1.63"
                            height="32.84"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="1207.37"
                            y="1936.08"
                            transform="matrix(0.2344 -0.9721 0.9721 0.2344 -967.0244 2664.5791)"
                            width="1.63"
                            height="20.31"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="1211"
                            y="1925.33"
                            transform="matrix(0.2344 -0.9721 0.9721 0.2344 -952.987 2659.2783)"
                            width="1.63"
                            height="18.68"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1416.53,1656.11c-0.93,0.73-2.11,1.15-3.27,1.23l-0.57,0.05c-0.17,0.02-0.35,0.03-0.52,0.04
						c-0.35,0.02-0.7,0.02-1.05,0l-4.19-0.39l-2.09-0.22l-0.26-0.03c-0.08-0.01-0.2-0.02-0.22-0.03c-0.1-0.02-0.2-0.03-0.3-0.03
						c-0.21-0.01-0.42,0.02-0.63,0.08c-0.21,0.06-0.42,0.16-0.62,0.29c-0.1,0.07-0.19,0.14-0.28,0.22l-0.13,0.14l-0.17,0.2
						l-1.34,1.63c-0.91,1.08-1.8,2.17-2.73,3.23c0.49-1.32,1.02-2.63,1.53-3.94l0.79-1.96l0.1-0.25c0.04-0.09,0.1-0.2,0.15-0.3
						c0.11-0.2,0.24-0.4,0.38-0.58c0.28-0.38,0.63-0.71,1.03-0.99c0.39-0.28,0.84-0.5,1.31-0.65c0.24-0.07,0.48-0.13,0.73-0.17
						l0.55-0.09l2.08-0.35l4.15-0.66c0.35-0.05,0.69-0.07,1.04-0.07c0.17,0,0.35,0,0.52,0l0.26,0.01l0.13,0l0.06,0l0.02-0.01
						c0.23,0.01,0.41-0.07,0.58-0.19c0.16-0.13,0.29-0.32,0.36-0.53c0.06-0.21,0.05-0.41,0-0.61c0-0.05-0.05-0.1-0.06-0.16
						c0,0-0.02-0.05-0.04-0.08l-0.06-0.12l-0.12-0.24l-0.47-0.93l-0.94-1.86l-1.88-3.72l-3.76-7.45l-0.12-0.23
						c-0.04-0.08-0.07-0.13-0.14-0.29c-0.11-0.27-0.24-0.53-0.31-0.82c-0.09-0.28-0.16-0.57-0.19-0.87
						c-0.05-0.29-0.06-0.59-0.06-0.89c0.01-1.19,0.41-2.35,1.08-3.3c0.67-0.96,1.66-1.71,2.78-2.11c0.56-0.21,1.14-0.31,1.72-0.34
						l1.07-0.04l8.34-0.27l4.17-0.13l2.08-0.06c0.06,0,0.05,0,0.07-0.01l0.04-0.01l0.08,0c0.05-0.03,0.11-0.02,0.15-0.04
						c0.04-0.02,0.1-0.03,0.14-0.06c0.02-0.01,0.05-0.02,0.07-0.03c0.02-0.02,0.04-0.04,0.06-0.05c0.17-0.11,0.31-0.31,0.38-0.51
						c0.08-0.21,0.08-0.41,0.02-0.61c-0.01-0.05-0.03-0.1-0.06-0.15c0.01-0.01-0.11-0.23-0.18-0.37l-0.47-0.93l-0.94-1.86
						l-3.76-7.45l-1.88-3.72l-0.24-0.47l-0.06-0.12l-0.03-0.06l-0.04-0.1l-0.17-0.4c-0.12-0.26-0.18-0.56-0.26-0.84
						c-0.06-0.29-0.1-0.59-0.13-0.88c-0.07-1.19,0.22-2.38,0.82-3.39c0.61-1,1.52-1.83,2.6-2.32c0.55-0.23,1.12-0.4,1.7-0.47
						l0.44-0.04c0.08-0.01,0.12-0.01,0.16-0.01l0.13,0l0.52-0.02l4.17-0.13l1.04-0.03l0.52-0.02l0.12,0l0.04,0
						c0.03,0,0.05-0.01,0.08-0.01c0.21-0.03,0.41-0.13,0.56-0.27c0.04-0.03,0.07-0.08,0.11-0.12l0.05-0.07
						c0.02-0.03,0.01-0.01,0.07-0.11l3.73-6.28c0.35-0.6,0.72-1.19,1.05-1.8c0.17-0.3,0.32-0.62,0.49-0.92
						c0.18-0.3,0.37-0.59,0.58-0.87c0.69-0.88,1.38-1.77,2.07-2.65c0.11-0.15,0.23-0.29,0.34-0.44c0.12-0.15,0.23-0.29,0.35-0.44
						l3.13,1.86c-0.07,0.17-0.14,0.34-0.22,0.51c-0.07,0.17-0.14,0.34-0.22,0.51c-0.44,1.03-0.89,2.06-1.34,3.09
						c-0.15,0.32-0.3,0.63-0.49,0.93c-0.18,0.3-0.38,0.58-0.57,0.87c-0.37,0.59-0.72,1.19-1.07,1.79l-3.73,6.28
						c-0.03,0.05-0.13,0.22-0.21,0.33c-0.08,0.12-0.17,0.24-0.26,0.36c-0.18,0.23-0.38,0.45-0.6,0.65
						c-0.86,0.81-1.97,1.34-3.13,1.52c-0.15,0.02-0.29,0.04-0.44,0.05l-0.22,0.02l-0.14,0.01l-0.52,0.02l-1.04,0.03l-4.17,0.13
						l-0.52,0.02l-0.13,0c-0.04,0-0.09,0-0.09,0.01c-0.03,0-0.05,0.01-0.08,0.01c-0.11,0-0.21,0.04-0.31,0.08
						c-0.18,0.09-0.34,0.23-0.46,0.42c-0.11,0.19-0.16,0.41-0.15,0.61c0.01,0.05,0.01,0.1,0.02,0.15c0.03,0.05,0.02,0.1,0.05,0.15
						c0.01,0.02,0.02,0.05,0.03,0.08l0.01,0.02l0.03,0.05l0.06,0.12l0.23,0.47l1.88,3.73l3.76,7.45l0.94,1.86l0.47,0.93
						c0.08,0.17,0.14,0.25,0.27,0.57c0.12,0.27,0.23,0.54,0.3,0.82c0.32,1.14,0.28,2.39-0.12,3.49c-0.39,1.11-1.11,2.09-2.07,2.78
						l-0.37,0.25c-0.13,0.08-0.26,0.15-0.39,0.21c-0.26,0.15-0.54,0.25-0.82,0.35c-0.28,0.1-0.57,0.15-0.86,0.22
						c-0.14,0.02-0.29,0.04-0.43,0.05l-0.22,0.02c-0.07,0-0.16,0.01-0.19,0.01l-2.09,0.07l-4.17,0.13l-8.35,0.26l-1.02,0.03
						c-0.12,0.02-0.22,0.02-0.32,0.06c-0.18,0.07-0.37,0.18-0.49,0.37c-0.12,0.18-0.21,0.39-0.2,0.6c0,0.05,0,0.1,0.01,0.15
						c0,0.05,0.02,0.1,0.03,0.15c0,0.06,0.05,0.1,0.06,0.16c0,0,0.05,0.11,0.09,0.18l0.12,0.23l3.76,7.45l1.88,3.72l0.94,1.86
						l0.47,0.93l0.18,0.35c0.02,0.04,0.03,0.07,0.07,0.15c0.11,0.27,0.24,0.53,0.32,0.82c0.36,1.12,0.36,2.38,0,3.5
						C1418.15,1654.38,1417.46,1655.39,1416.53,1656.11z"
                            />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1479.04,1600.67c-2.05,0-4.07-0.55-5.88-1.62c-5.49-3.29-7.28-10.38-4.04-15.84c3.24-5.46,10.32-7.28,15.79-4.07
					c2.7,1.6,4.58,4.12,5.35,7.11c0.76,2.99,0.31,6.1-1.26,8.76l0,0c-1.58,2.65-4.09,4.54-7.08,5.3
					C1480.96,1600.55,1480,1600.67,1479.04,1600.67z M1479.07,1579.19c-3.4,0-6.7,1.74-8.55,4.85c-2.78,4.69-1.24,10.78,3.43,13.58
					c2.32,1.38,4.99,1.76,7.56,1.11c2.57-0.65,4.73-2.27,6.08-4.55l0,0c1.35-2.28,1.74-4.95,1.08-7.52
					c-0.66-2.57-2.27-4.73-4.55-6.08C1482.54,1579.63,1480.79,1579.19,1479.07,1579.19z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1473.12,1599.02l-46.79-27.78c-2.65-1.58-4.54-4.09-5.3-7.08c-0.76-2.99-0.32-6.1,1.26-8.75
					c1.58-2.66,4.09-4.54,7.08-5.3c2.99-0.76,6.1-0.31,8.76,1.26l46.79,27.78l-0.83,1.4c-4.7-2.76-10.78-1.19-13.56,3.49
					c-2.78,4.69-1.24,10.78,3.43,13.58L1473.12,1599.02z M1432.24,1551.37c-0.82,0-1.65,0.1-2.47,0.31
					c-2.57,0.65-4.73,2.27-6.08,4.55c-1.35,2.28-1.74,4.95-1.08,7.52c0.66,2.57,2.27,4.73,4.55,6.08l41.76,24.79
					c-1.87-3.46-1.94-7.8,0.21-11.42c2.15-3.62,5.99-5.64,9.93-5.65l-41.76-24.79C1435.74,1551.84,1434,1551.37,1432.24,1551.37z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1473.11,1599.02c-5.44-3.26-7.23-10.35-3.99-15.81c3.24-5.46,10.32-7.28,15.79-4.07l-0.83,1.4
					c-4.7-2.76-10.78-1.19-13.56,3.49c-2.78,4.69-1.24,10.78,3.43,13.58L1473.11,1599.02z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="1437.78"
                            y="1582.17"
                            transform="matrix(0.8599 0.5105 -0.5105 0.8599 1013.2207 -514.9555)"
                            width="13.77"
                            height="12.12"
                        />
                    </g>
                    <g>
                        <path
                            className="st0"
                            d="M1447.78,1598.07l-13.24-7.86l7.02-11.82l13.24,7.86L1447.78,1598.07z M1436.77,1589.64l10.44,6.2l5.36-9.02l-10.44-6.2
					L1436.77,1589.64z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1091.31,1631.24c-47.23-24.74-57.82-87.48-59.47-99.81l1.62-0.22c1.62,12.19,12.09,74.21,58.61,98.58L1091.31,1631.24z
					"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1032.89,1532.35l-0.43-0.2c-0.13-0.06-13.34-6.55-4.37-28.18l0.51-1.23l0.86,1.02c0.4,0.47,9.69,11.53,8.51,20.73
					c-0.4,3.14-1.99,5.7-4.7,7.59L1032.89,1532.35z M1029.08,1505.89c-6.62,17.21,1.63,23.36,3.66,24.56
					c2.08-1.59,3.29-3.66,3.62-6.17C1037.25,1517.36,1031.35,1508.85,1029.08,1505.89z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1038.01,1542.91c-1.2,0-2.42-0.2-3.67-0.61l-0.45-0.15l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.41l1.29-0.33
					l-0.29,1.3c-0.14,0.6-3.41,14.67-11.48,19.25C1041.57,1542.44,1039.83,1542.91,1038.01,1542.91z M1035.32,1540.89
					c2.52,0.71,4.91,0.43,7.11-0.81c6.07-3.44,9.33-13.27,10.34-16.86C1035.03,1528.26,1035.07,1538.55,1035.32,1540.89z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1041.94,1558.3c-1.2,0-2.42-0.2-3.67-0.61l-0.45-0.15l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.41l1.29-0.33l-0.29,1.3
					c-0.14,0.6-3.41,14.67-11.48,19.25C1045.51,1557.83,1043.76,1558.3,1041.94,1558.3z M1039.26,1556.28
					c2.52,0.71,4.91,0.43,7.11-0.81c6.07-3.44,9.33-13.27,10.34-16.86C1038.96,1543.65,1039,1553.94,1039.26,1556.28z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1047.74,1574.78c-1.2,0-2.42-0.2-3.67-0.61l-0.45-0.15l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.41l1.29-0.33l-0.3,1.3
					c-0.14,0.6-3.41,14.67-11.48,19.25C1051.3,1574.31,1049.55,1574.78,1047.74,1574.78z M1045.05,1572.76
					c2.52,0.71,4.91,0.43,7.11-0.81c6.07-3.44,9.33-13.27,10.34-16.86C1044.75,1560.13,1044.79,1570.42,1045.05,1572.76z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1055.47,1591c-1.2,0-2.42-0.2-3.67-0.61l-0.45-0.15l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.4l1.29-0.33l-0.29,1.3
					c-0.14,0.6-3.41,14.67-11.48,19.24C1059.04,1590.53,1057.29,1591,1055.47,1591z M1052.79,1588.98c2.52,0.71,4.91,0.44,7.11-0.81
					c6.07-3.43,9.33-13.26,10.34-16.85C1052.49,1576.35,1052.53,1586.64,1052.79,1588.98z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1064.79,1605.68c-1.2,0-2.42-0.2-3.67-0.61l-0.45-0.15l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.4l1.29-0.33l-0.29,1.3
					c-0.14,0.6-3.41,14.67-11.48,19.24C1068.35,1605.2,1066.6,1605.68,1064.79,1605.68z M1062.11,1603.66
					c2.52,0.7,4.9,0.43,7.1-0.81c6.07-3.44,9.33-13.26,10.34-16.85C1061.98,1590.99,1061.89,1601.35,1062.11,1603.66z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1027.66,1549.07c-0.12,0-0.24,0-0.37-0.01c-9.27-0.28-18.73-11.21-19.12-11.68l-0.87-1.01l1.3-0.31
					c22.78-5.44,27.09,8.63,27.13,8.77l0.13,0.45l-0.33,0.34C1033.32,1547.91,1030.67,1549.07,1027.66,1549.07z M1010.34,1537.35
					c2.57,2.7,10.04,9.88,17.01,10.09c2.54,0.08,4.77-0.8,6.67-2.59C1033.14,1542.65,1028.39,1533.53,1010.34,1537.35z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1033.32,1568.37c-0.12,0-0.24,0-0.37-0.01c-9.27-0.28-18.73-11.21-19.12-11.68l-0.87-1.01l1.3-0.31
					c22.78-5.44,27.09,8.62,27.13,8.77l0.13,0.45l-0.33,0.34C1038.99,1567.21,1036.34,1568.37,1033.32,1568.37z M1016.01,1556.65
					c2.57,2.7,10.04,9.88,17.01,10.09c2.54,0.07,4.77-0.8,6.67-2.59C1038.81,1561.95,1034.05,1552.83,1016.01,1556.65z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1040.25,1585.72c-0.12,0-0.24,0-0.37-0.01c-9.27-0.28-18.73-11.21-19.12-11.68l-0.87-1.01l1.3-0.31
					c22.78-5.44,27.09,8.62,27.13,8.77l0.13,0.45l-0.33,0.34C1045.91,1584.57,1043.26,1585.72,1040.25,1585.72z M1022.93,1574
					c2.57,2.7,10.04,9.88,17.01,10.09c2.52,0.07,4.77-0.8,6.66-2.59C1045.73,1579.3,1040.97,1570.18,1022.93,1574z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1049.31,1604.31c-8.92,0-18.77-8.75-19.2-9.14l-0.99-0.89l1.25-0.48c21.87-8.36,27.99,5.01,28.05,5.15l0.19,0.43
					l-0.28,0.38c-1.97,2.66-4.57,4.17-7.72,4.49C1050.16,1604.29,1049.74,1604.31,1049.31,1604.31z M1032.25,1594.84
					c2.9,2.35,11.24,8.49,18.18,7.79c2.52-0.25,4.62-1.41,6.27-3.44C1055.55,1597.12,1049.65,1588.7,1032.25,1594.84z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1057.09,1620.88c-7.57,0-15.58-3.74-16.01-3.94l-1.21-0.57l1.06-0.81c18.57-14.27,28.25-3.19,28.35-3.08l0.3,0.36
					l-0.16,0.44c-1.13,3.11-3.19,5.3-6.12,6.51C1061.4,1620.57,1059.26,1620.88,1057.09,1620.88z M1043.04,1616.01
					c3.45,1.42,13.2,4.92,19.65,2.27c2.34-0.96,4.03-2.67,5.02-5.09C1066.03,1611.54,1057.96,1605.16,1043.04,1616.01z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1401.46,1953.03c-1.53-6.09-3.51-12.35-5.89-18.6c-5.16-13.58-4.97-25.87,0.57-36.53c11-21.18,39.75-28.72,40.04-28.79
					l0.4,1.58c-0.28,0.07-28.34,7.44-39,27.97c-5.31,10.23-5.47,22.07-0.48,35.19c2.4,6.31,4.4,12.63,5.94,18.78L1401.46,1953.03z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1442.9,1873.38c-2.86,0-5.41-1.07-7.61-3.19l-0.34-0.33l0.12-0.46c0.04-0.14,3.89-14.34,26.83-9.65l1.31,0.27
					l-0.83,1.04c-0.38,0.48-9.48,11.71-18.73,12.3C1443.4,1873.37,1443.15,1873.38,1442.9,1873.38z M1436.8,1869.36
					c1.96,1.74,4.22,2.54,6.75,2.38c6.96-0.44,14.19-7.86,16.67-10.64C1442.05,1857.86,1437.59,1867.14,1436.8,1869.36z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1424.46,1874.86l-0.32-0.35c-0.1-0.11-9.83-11.14,6.71-27.71l0.94-0.94l0.41,1.27c0.19,0.58,4.56,14.36-0.05,22.41
					c-1.57,2.75-4.01,4.51-7.24,5.23L1424.46,1874.86z M1431.04,1848.95c-12.69,13.39-7.4,22.21-5.99,24.1
					c2.53-0.68,4.44-2.13,5.7-4.32C1434.21,1862.67,1432,1852.55,1431.04,1848.95z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1409.13,1883.82l-0.36-0.31c-0.11-0.1-11.03-9.96,3.54-28.29l0.83-1.04l0.55,1.21c0.25,0.56,6.15,13.75,2.48,22.27
					c-1.25,2.91-3.47,4.93-6.61,6.01L1409.13,1883.82z M1412.74,1857.33c-10.98,14.6-4.78,22.91-3.22,24.62
					c2.43-0.96,4.17-2.62,5.17-4.94C1417.44,1870.6,1414.1,1860.8,1412.74,1857.33z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1399.17,1895.45l-0.46-0.12c-0.14-0.04-14.29-4.09-9.27-26.97l0.28-1.3l1.03,0.85c0.47,0.39,11.58,9.64,12.03,18.9
					c0.16,3.16-0.95,5.96-3.29,8.31L1399.17,1895.45z M1390.75,1870.08c-3.48,18.11,5.73,22.7,7.94,23.53
					c1.76-1.93,2.59-4.19,2.47-6.71C1400.82,1879.93,1393.5,1872.6,1390.75,1870.08z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1392.38,1905.34c-4.26,0-13.93-2.03-19.08-19.28l-0.38-1.28l1.31,0.24c0.6,0.11,14.8,2.82,19.69,10.7
					c1.67,2.69,2.06,5.67,1.15,8.86l-0.13,0.45l-0.46,0.11C1394.45,1905.16,1393.65,1905.34,1392.38,1905.34z M1375.27,1886.93
					c5.74,17.53,16.02,17.08,18.35,16.73c0.61-2.54,0.24-4.92-1.09-7.07C1388.85,1890.66,1378.9,1887.8,1375.27,1886.93z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1387.92,1922.54c-4.94,0-12.63-2.44-19.68-14.88l-0.66-1.16l1.33-0.06c0.61-0.03,15.05-0.56,21.58,6.03
					c2.23,2.25,3.27,5.07,3.1,8.38l-0.02,0.47l-0.42,0.21C1393.1,1921.55,1391.06,1922.54,1387.92,1922.54z M1370.35,1908.05
					c9.51,15.8,19.43,13.06,21.63,12.2c0.02-2.61-0.87-4.85-2.65-6.64C1384.43,1908.66,1374.08,1908.09,1370.35,1908.05z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1433.95,1888.41c-4.18,0-8.84-0.66-12.23-2.98c-2.61-1.79-4.17-4.36-4.62-7.64l-0.06-0.47l0.37-0.29
					c0.12-0.09,11.9-8.89,27.08,8.94l0.86,1.02l-1.3,0.31C1443.72,1887.37,1439.21,1888.41,1433.95,1888.41z M1418.8,1878.06
					c0.47,2.57,1.76,4.59,3.85,6.02c5.76,3.94,16.01,2.56,19.68,1.89C1430.13,1872.39,1420.78,1876.86,1418.8,1878.06z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1414.74,1897.61c-2.26,0-4.48-0.32-6.46-1.16c-2.92-1.24-4.95-3.45-6.05-6.57l-0.16-0.44l0.31-0.36
					c0.1-0.11,9.9-11.08,28.31,3.39l1.05,0.82l-1.21,0.56C1430.11,1894.04,1422.24,1897.61,1414.74,1897.61z M1403.95,1889.8
					c0.97,2.43,2.64,4.16,4.97,5.15c6.42,2.72,16.2-0.67,19.67-2.05C1413.79,1881.88,1405.65,1888.17,1403.95,1889.8z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1405.33,1916c-2.26,0-4.48-0.32-6.46-1.16c-2.92-1.24-4.95-3.45-6.05-6.57l-0.16-0.44l0.31-0.36
					c0.1-0.11,9.9-11.08,28.31,3.39l1.05,0.82l-1.21,0.56C1420.7,1912.43,1412.82,1916,1405.33,1916z M1394.54,1908.19
					c0.97,2.43,2.64,4.16,4.97,5.15c6.42,2.72,16.2-0.67,19.67-2.05C1404.38,1900.27,1396.24,1906.56,1394.54,1908.19z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1401.75,1933.22c-2.75,0-5.24-1.01-7.41-3.02l-0.35-0.32l0.1-0.46c0.03-0.14,3.56-14.43,26.6-10.28l1.31,0.24
					l-0.81,1.06c-0.37,0.49-9.2,11.93-18.44,12.73C1402.42,1933.2,1402.08,1933.22,1401.75,1933.22z M1395.82,1929.34
					c1.99,1.69,4.28,2.43,6.79,2.21c6.94-0.6,14-8.19,16.41-11.03C1400.97,1917.76,1396.58,1927.15,1395.82,1929.34z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1154.34,1908.4c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C1159.76,1905.97,1157.33,1908.4,1154.34,1908.4z M1154.34,1899.18c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79S1156.43,1899.18,1154.34,1899.18z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1137.76,1961.23c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42s5.42,2.43,5.42,5.42
				C1143.18,1958.8,1140.75,1961.23,1137.76,1961.23z M1137.76,1952.01c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79S1139.85,1952.01,1137.76,1952.01z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1350.13,1864.72c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42s5.42,2.43,5.42,5.42S1353.12,1864.72,1350.13,1864.72
				z M1350.13,1855.5c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79s3.79-1.7,3.79-3.79S1352.22,1855.5,1350.13,1855.5z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1454.72,1802.85c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C1460.14,1800.42,1457.71,1802.85,1454.72,1802.85z M1454.72,1793.64c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79S1456.81,1793.64,1454.72,1793.64z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1257.65,1729.73c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C1263.08,1727.29,1260.64,1729.73,1257.65,1729.73z M1257.65,1720.51c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79S1259.75,1720.51,1257.65,1720.51z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1202.17,1613.44c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				S1205.16,1613.44,1202.17,1613.44z M1202.17,1604.22c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79c2.09,0,3.79-1.7,3.79-3.79
				S1204.26,1604.22,1202.17,1604.22z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1310.87,1530.96c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42s5.42,2.43,5.42,5.42S1313.86,1530.96,1310.87,1530.96
				z M1310.87,1521.74c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79s3.79-1.7,3.79-3.79S1312.96,1521.74,1310.87,1521.74z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1130.74,1544.56c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C1136.16,1542.13,1133.73,1544.56,1130.74,1544.56z M1130.74,1535.35c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79S1132.83,1535.35,1130.74,1535.35z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1452.81,1962.93c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42s5.42,2.43,5.42,5.42S1455.8,1962.93,1452.81,1962.93z
				 M1452.81,1953.71c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79c2.09,0,3.79-1.7,3.79-3.79S1454.9,1953.71,1452.81,1953.71z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1344.39,1965.48c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C1349.81,1963.05,1347.38,1965.48,1344.39,1965.48z M1344.39,1956.26c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79C1348.18,1957.97,1346.48,1956.26,1344.39,1956.26z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1120.24,1953.42l-6.88,3.15l-0.3-0.92c-2.44-5.35-8.79-7.71-14.13-5.26c-5.35,2.45-7.71,8.78-5.26,14.13l0.02,0.05
				l0.42,0.77l-0.78,0.39l-44.17,20.19l-18.21-39.83l0.82-0.3c0.11-0.04,0.22-0.09,0.33-0.14c5.35-2.45,7.71-8.78,5.26-14.13
				c-2.44-5.35-8.78-7.71-14.13-5.26c-0.11,0.05-0.22,0.1-0.32,0.16l-0.77,0.43l-5.48-11.99l12.29-5.62l0.35,0.7
				c2.58,5.15,8.71,7.3,13.96,4.91s7.62-8.44,5.42-13.77l-0.3-0.73l39.36-18l3.15,6.89l-0.74,0.34c-5.36,2.45-7.72,8.79-5.28,14.14
				c2.44,5.35,8.78,7.71,14.13,5.26l0.74-0.34l0.35,0.73L1120.24,1953.42z M1114.25,1954.37l3.84-1.75l-19.13-41.85
				c-6.01,2.29-12.85-0.46-15.56-6.38c-2.71-5.92-0.3-12.9,5.35-15.94l-1.81-3.95l-36.47,16.67c2.04,5.95-0.72,12.48-6.53,15.13
				s-12.55,0.47-15.72-4.96l-9.4,4.3l4.1,8.96c6.09-2.56,13.17,0.18,15.93,6.23c2.76,6.05,0.2,13.19-5.72,16.12l16.83,36.81
				l41.95-19.18c-2.37-6.03,0.37-12.95,6.33-15.68C1104.21,1946.17,1111.24,1948.63,1114.25,1954.37z"
                    />
                </g>
                <g>
                    <path
                        className="st0"
                        d="M1068.87,1947.31c-7.24,3.31-15.82,0.11-19.13-7.13s-0.11-15.82,7.13-19.13s15.82-0.11,19.13,7.13
				S1076.11,1944,1068.87,1947.31z M1057.54,1922.53c-6.42,2.94-9.26,10.55-6.32,16.97c2.94,6.42,10.55,9.26,16.97,6.32
				c6.42-2.94,9.26-10.55,6.32-16.97C1071.58,1922.43,1063.97,1919.6,1057.54,1922.53z"
                    />
                </g>
                <g>
                    <path
                        className="st0"
                        d="M1066.21,1968.77c-2.97,1.36-6.48,0.05-7.84-2.92c-1.36-2.97-0.05-6.48,2.92-7.84c2.97-1.36,6.49-0.05,7.84,2.92
				S1069.18,1967.41,1066.21,1968.77z M1061.97,1959.49c-2.15,0.98-3.1,3.53-2.12,5.68s3.53,3.1,5.68,2.12
				c2.15-0.98,3.1-3.53,2.12-5.68C1066.67,1959.45,1064.12,1958.5,1061.97,1959.49z"
                    />
                </g>
                <g>
                    <path
                        className="st0"
                        d="M1093.52,1935.09c-2.97,1.36-6.49,0.05-7.84-2.92s-0.05-6.48,2.92-7.84c2.97-1.36,6.48-0.05,7.84,2.92
				C1097.79,1930.22,1096.48,1933.74,1093.52,1935.09z M1089.27,1925.81c-2.15,0.98-3.1,3.53-2.12,5.68
				c0.98,2.15,3.53,3.1,5.68,2.12c2.15-0.98,3.1-3.53,2.12-5.68S1091.42,1924.83,1089.27,1925.81z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <circle className="st0" cx="1799.67" cy="1815.15" r="4.61" />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1806.44,1794.69c0.17,0.52,1.79,5.47-1.01,8.07c-2.6,2.41-7.32,1.2-9.79-0.86c-1.51-1.26-1.27-2.08-2.88-4.47
					c-3.59-5.31-7.5-5.22-7.78-7.78c-0.44-3.98,8.47-9.09,10.66-7.35c1.47,1.17-0.79,4.87,1.15,6.63c1.55,1.4,3.66-0.33,6.34,1.15
					C1805.33,1791.31,1806.12,1793.73,1806.44,1794.69z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1789.87,1811.11c0.13,1.37-0.3,2.5-1.15,4.75c-1.22,3.2-1.83,4.8-3.02,5.04c-2.15,0.43-5.53-3.7-4.75-6.19
					c0.54-1.74,2.72-1.42,3.89-3.6c1.42-2.65-0.53-5.46,0.29-5.91C1786.02,1804.72,1789.53,1807.55,1789.87,1811.11z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1871.35,1796.58c-0.7,0-1.4-0.07-2.1-0.22c-2.68-0.55-4.98-2.12-6.49-4.41l-77.85-118.48
					c-0.04-0.06-0.08-0.12-0.11-0.18c-6-11.09-5.25-24.42,1.96-34.8c0.25-0.36,0.51-0.71,0.77-1.06c0.18-0.24,0.19-0.56,0.03-0.81
					l-36.2-55.09c-0.34-0.52-0.46-1.14-0.34-1.76c0.13-0.61,0.48-1.14,1-1.48l13.98-9.19c0.52-0.34,1.15-0.46,1.76-0.34
					c0.61,0.13,1.14,0.48,1.48,1l36.2,55.09c0.16,0.25,0.46,0.37,0.75,0.3c0.42-0.1,0.85-0.2,1.28-0.28
					c12.38-2.51,24.92,2.09,32.72,12.01c0.05,0.06,0.09,0.12,0.13,0.18l77.85,118.47c3.1,4.72,1.79,11.09-2.94,14.19l-38.28,25.16
					C1875.26,1796.01,1873.32,1796.58,1871.35,1796.58z M1767.29,1570.36c-0.14,0-0.27,0.04-0.39,0.12l-13.98,9.19
					c-0.16,0.1-0.27,0.26-0.3,0.45c-0.04,0.18,0,0.37,0.1,0.53l36.2,55.09c0.54,0.82,0.51,1.9-0.09,2.69
					c-0.25,0.33-0.49,0.67-0.73,1.01c-6.86,9.87-7.57,22.55-1.87,33.09c0.01,0.02,0.02,0.04,0.04,0.06l77.86,118.48
					c1.26,1.92,3.2,3.24,5.45,3.71c2.25,0.47,4.55,0.03,6.48-1.24l38.28-25.16c1.92-1.26,3.24-3.2,3.7-5.45
					c0.47-2.25,0.03-4.55-1.24-6.48l-77.86-118.48c-0.01-0.02-0.03-0.04-0.04-0.06c-7.42-9.42-19.34-13.8-31.11-11.42
					c-0.41,0.08-0.81,0.17-1.22,0.27c-0.95,0.23-1.96-0.16-2.5-0.99l-36.2-55.09c-0.1-0.16-0.26-0.27-0.45-0.3
					C1767.38,1570.37,1767.34,1570.36,1767.29,1570.36z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1867.76,1791.14c-1.03,0-2.07-0.16-3.09-0.48l0.49-1.56c2.48,0.78,5.15,0.41,7.32-1.02l15.3-10.05l0.9,1.36
					l-15.3,10.05C1871.68,1790.56,1869.73,1791.14,1867.76,1791.14z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1865.21,1787.25c-0.7,0-1.4-0.07-2.1-0.22l0.33-1.6c2.26,0.47,4.55,0.03,6.48-1.24l3.89-2.56l0.89,1.36l-3.89,2.56
					C1869.13,1786.67,1867.19,1787.25,1865.21,1787.25z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1833.6,1643.49c-5.36-6.84-14.34-10.15-22.87-8.42l-0.32-1.6c9.27-1.88,18.65,1.58,24.48,9.01L1833.6,1643.49z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1747.44,1582.01c-0.27,0-0.54-0.03-0.81-0.08c-1.03-0.21-1.92-0.82-2.5-1.7l-4.89-7.45c-1.2-1.82-0.69-4.27,1.13-5.47
					l20.18-13.26c0.88-0.58,1.94-0.78,2.97-0.57c1.03,0.21,1.92,0.82,2.5,1.7l4.89,7.45c1.2,1.82,0.69,4.27-1.13,5.47l-20.18,13.26
					C1748.94,1581.79,1748.2,1582.01,1747.44,1582.01z M1762.71,1555.04c-0.45,0-0.89,0.13-1.27,0.38l-20.18,13.26
					c-1.07,0.7-1.37,2.14-0.66,3.21l4.89,7.45c0.34,0.52,0.86,0.87,1.47,1c0.61,0.13,1.23,0.01,1.74-0.33l20.18-13.26
					c1.07-0.7,1.37-2.14,0.66-3.21l-4.89-7.45c-0.34-0.52-0.86-0.87-1.47-1C1763.03,1555.05,1762.87,1555.04,1762.71,1555.04z"
                        />
                    </g>
                </g>
                <path
                    className="st0"
                    d="M1815.03,1716.36l29.26,44.53c13.36-5.83,33.07-16.53,52.58-34.78l-37.36-56.85c-19.48,18.17-39.15,28.9-52.58,34.78
			l4.04,6.15c5.98-2.93,12.02-6.19,17.97-9.72l0.83,1.4c-5.93,3.51-11.94,6.76-17.9,9.69l2.25,3.42c5.97-2.92,12.02-6.19,17.97-9.72
			c0.1-0.06,10.3-6.16,12.26-7.81l1.05,1.25c-2.06,1.73-12.05,7.71-12.47,7.96C1827,1710.19,1820.98,1713.44,1815.03,1716.36z"
                />
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="1755.94"
                            y="1572.15"
                            transform="matrix(0.8357 -0.5492 0.5492 0.8357 -579.818 1229.9663)"
                            width="19.74"
                            height="23.84"
                        />
                    </g>
                    <g>
                        <path
                            className="st0"
                            d="M1763.87,1600.58l-13.99-21.29l17.86-11.74l13.99,21.29L1763.87,1600.58z M1752.14,1579.76l12.2,18.57l15.14-9.95
					l-12.2-18.57L1752.14,1579.76z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1603.73,1749.59c-1.5-0.45-3.08-0.69-4.73-0.69c-9.07,0-16.42,7.35-16.42,16.42c0,2.49,0.55,4.85,1.55,6.96l-0.92,1.06
					l-18.05,3.26l10.56-16.9c0,0-26.12,2.3-26.69,2.3c-0.58,0,13.06-15.75,13.06-15.75l-19.39-21.32l25.16,6.34v-22.66l20.16,20.36
					l9.03-17.48L1603.73,1749.59z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1615.13,1852.27c-9.5,0-17.24-7.73-17.24-17.24c0-2.45,0.5-4.81,1.49-7.02l0.24-0.53l0.58,0.05
						c0.45,0.04,0.92,0.06,1.39,0.06c6.15,0,11.75-3.63,14.26-9.25l0.24-0.53l0.58,0.05c8.81,0.77,15.7,8.31,15.7,17.17
						C1632.37,1844.54,1624.64,1852.27,1615.13,1852.27z M1600.66,1829.2c-0.75,1.85-1.13,3.81-1.13,5.84c0,8.6,7,15.61,15.61,15.61
						s15.61-7,15.61-15.61c0-7.83-5.95-14.52-13.65-15.48c-2.88,5.89-8.9,9.67-15.49,9.67
						C1601.28,1829.23,1600.97,1829.22,1600.66,1829.2z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1600.06,1829.16c-8.81-0.77-15.7-8.31-15.7-17.17c0-9.5,7.73-17.24,17.24-17.24s17.24,7.73,17.24,17.24
						c0,2.45-0.5,4.81-1.49,7.02l-1.49-0.66c0.9-2.01,1.35-4.15,1.35-6.36c0-8.6-7-15.61-15.61-15.61s-15.61,7-15.61,15.61
						c0,8.02,6.24,14.84,14.22,15.54L1600.06,1829.16z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1630.98,1831.24h-0.08v-1.63h0.08c8.6,0,15.61-7,15.61-15.61c0-3.75-1.35-7.38-3.81-10.22
						c-2.97-3.42-7.27-5.39-11.8-5.39c-0.19,0-0.38,0-0.57,0.01l-0.06-1.63c0.21-0.01,0.42-0.01,0.63-0.01c5,0,9.75,2.17,13.03,5.95
						c2.71,3.13,4.21,7.14,4.21,11.29C1648.22,1823.51,1640.48,1831.24,1630.98,1831.24z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1617.51,1808.32l-0.22-1.61c7.67-1.06,13.45-7.7,13.45-15.46c0-8.6-7-15.61-15.61-15.61s-15.61,7-15.61,15.61
						c0,1.46,0.2,2.89,0.59,4.25l-1.57,0.45c-0.43-1.51-0.65-3.09-0.65-4.7c0-9.5,7.73-17.24,17.24-17.24s17.24,7.73,17.24,17.24
						C1632.37,1799.81,1625.98,1807.15,1617.51,1808.32z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1586.68,1805.61c-8.19-1.49-14.13-8.62-14.13-16.95c0-9.5,7.73-17.24,17.23-17.24c5.82,0,11.21,2.91,14.41,7.78
						l-1.36,0.9c-2.9-4.41-7.78-7.05-13.05-7.05c-8.6,0-15.6,7-15.6,15.61c0,7.55,5.38,14,12.79,15.35L1586.68,1805.61z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1643.48,1804.07l-0.17-1.62c7.94-0.84,13.94-7.52,13.94-15.52c0-8.6-7-15.61-15.61-15.61
						c-6.03,0-11.41,3.37-14.04,8.79l-1.47-0.71c2.87-5.9,8.95-9.71,15.51-9.71c9.5,0,17.24,7.73,17.24,17.24
						C1658.87,1795.77,1652.25,1803.13,1643.48,1804.07z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1640.76,1770.99l-1.41-0.81c1.34-2.34,2.05-5.02,2.05-7.74c0-8.6-7-15.61-15.61-15.61c-5.7,0-10.94,3.1-13.69,8.1
						l-1.43-0.78c3.03-5.52,8.82-8.95,15.11-8.95c9.5,0,17.24,7.73,17.24,17.24C1643.03,1765.44,1642.25,1768.4,1640.76,1770.99z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1612.86,1775.57l-1.31-0.97c0.41-0.56,0.8-1.16,1.14-1.78c1.25-2.29,1.92-4.88,1.92-7.5c0-3.77-1.36-7.4-3.83-10.24
						c-1.94-2.23-4.46-3.86-7.28-4.7c-1.44-0.44-2.96-0.66-4.5-0.66c-8.6,0-15.61,7-15.61,15.61c0,2.32,0.49,4.54,1.47,6.61
						c0.12,0.27,0.25,0.52,0.38,0.77l-1.43,0.78c-0.15-0.28-0.3-0.57-0.43-0.86c-1.07-2.28-1.62-4.74-1.62-7.3
						c0-9.5,7.73-17.24,17.24-17.24c1.7,0,3.37,0.24,4.97,0.73c3.11,0.93,5.89,2.73,8.04,5.2c2.73,3.14,4.23,7.15,4.23,11.31
						c0,2.89-0.73,5.76-2.12,8.29C1613.74,1774.3,1613.32,1774.96,1612.86,1775.57z"
                            />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1612.69,1752.5c-2.1-4.93-2.4-8.9-2.28-11.64c0.22-5.05,1.91-6.82,2.86-14.58c0.43-3.53,0.71-5.81,0.29-8.93
					c-0.39-2.91-1.04-7.73-4.9-11.81c-4.24-4.49-9.68-5.35-11.63-5.51c-3.93-0.32-7.09,0.32-7.09,0.32
					c21.03,0.58,23.33,19.3,19.3,30.82c-4.03,11.52,1.15,22.76,1.15,22.76l1.01,0.6l1.59-2.19"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1611.68,1726.29c0,0,9.36-3.02,8.79-12.96s-3.17-13.25-3.17-13.25s3.89,3.6,4.61,7.06c0.72,3.46,2.59,11.52-2.45,15.99
					c-5.04,4.47-7.49,6.34-8.21,9.22L1611.68,1726.29z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="1587.27" y="1819.19" width="15.15" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="1590.45" y="1823.23" width="11.97" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="1584.94" y="1810.94" width="17.48" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="1585.62" y="1814.97" width="16.8" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="1587.91" y="1802.1" width="14.5" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="1585.96" y="1806.13" width="16.45" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <rect className="st0" x="1575.55" y="1795.57" width="12.22" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="1578.73" y="1799.6" width="5.44" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="1573.22" y="1787.31" width="17.48" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="1573.9" y="1791.34" width="16.8" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="1576.2" y="1778.48" width="7.97" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="1574.25" y="1782.51" width="13.38" height="1.63" />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <rect className="st0" x="1581.17" y="1766.91" width="12.22" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="1578.84" y="1758.65" width="17.48" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="1579.52" y="1762.68" width="16.8" height="1.63" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="1579.87" y="1753.85" width="13.38" height="1.63" />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="1598.72" y="1836.81" width="15.99" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="1599.72" y="1840.85" width="11.52" height="1.63" />
                    </g>
                </g>
                <g>
                    <g>
                        <polygon
                            className="st0"
                            points="1674.22,1719.89 1658.64,1686.43 1647.23,1710.41 1633.38,1663.28 1634.95,1662.82 1647.59,1705.86 
					1658.66,1682.6 1674.19,1715.97 1685.2,1691.47 1715.97,1692.3 1697.86,1669.73 1717.85,1651.9 1676.9,1656.39 1686.9,1634.45 
					1636.77,1660.05 1636.03,1658.6 1690.32,1630.88 1679.57,1654.45 1722.74,1649.72 1700.1,1669.92 1719.44,1694.02 
					1686.25,1693.12 				"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1636.38,1659.35l-2.8,1.26l0.58,2.44c-1.7-1.27-4.33-2.9-8.36-4.8c-9.79-4.61-5.57-14.98-5.57-14.98
					s0.24,2.64,1.08,5.45c1.18,3.95,4.07,5.97,4.68,6.46c1.85,1.47,3.54,2.08,5.76,2.88c1.73,0.62,3.31,1.01,4.64,1.26
					L1636.38,1659.35z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1579.27,1680.15c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
					S1582.26,1680.15,1579.27,1680.15z M1579.27,1670.93c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79c2.09,0,3.79-1.7,3.79-3.79
					S1581.36,1670.93,1579.27,1670.93z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1538.37,1797.97c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
					C1543.79,1795.54,1541.36,1797.97,1538.37,1797.97z M1538.37,1788.75c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
					c2.09,0,3.79-1.7,3.79-3.79C1542.16,1790.45,1540.46,1788.75,1538.37,1788.75z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1664.54,1766.28c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42s5.42,2.43,5.42,5.42
					S1667.53,1766.28,1664.54,1766.28z M1664.54,1757.06c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79c2.09,0,3.79-1.7,3.79-3.79
					S1666.63,1757.06,1664.54,1757.06z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1662.24,1830.52c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
					S1665.23,1830.52,1662.24,1830.52z M1662.24,1821.3c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79c2.09,0,3.79-1.7,3.79-3.79
					S1664.33,1821.3,1662.24,1821.3z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <polygon
                            className="st0"
                            points="1717.41,1693.92 1639.05,1663.38 1633.32,1661.38 1633.85,1659.84 1639.61,1661.85 1718,1692.4 				"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <polygon
                            className="st0"
                            points="1670.21,1687.66 1667.82,1673.52 1680.79,1667.52 1681.48,1668.99 1669.64,1674.48 1671.82,1687.38 				"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <polygon
                            className="st0"
                            points="1649.93,1679.86 1648.3,1679.69 1649.66,1666.69 1657.95,1659.27 1659.04,1660.48 1651.22,1667.49 				"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1800.62,1832.32l-3.25,13.48c-0.08,0.38-0.16,0.76-0.25,1.14c-3.67,15.22-18.99,24.59-34.21,20.92
					c-15.23-3.67-24.59-18.99-20.92-34.22c0.1-0.4,0.2-0.8,0.32-1.2l3.23-13.4c0,0,8.64,15.52,20.14,15.33
					C1777.18,1834.17,1785.43,1818.58,1800.62,1832.32z"
                        />
                    </g>
                    <g>
                        <path
                            className="st0"
                            d="M1769.55,1869.46c-2.26,0-4.55-0.26-6.84-0.82c-7.58-1.83-13.99-6.49-18.05-13.14c-4.07-6.65-5.3-14.48-3.47-22.06
					c0.1-0.42,0.21-0.83,0.33-1.23l3.71-15.4l1.02,1.82c0.08,0.15,8.44,14.91,19.22,14.91c0.06,0,0.13,0,0.19,0
					c3.74-0.06,7.12-1.83,10.69-3.71c7.07-3.71,14.39-7.55,24.81,1.88l0.36,0.32l-3.36,13.95c-0.07,0.35-0.15,0.75-0.25,1.14
					C1794.7,1860.47,1782.72,1869.46,1769.55,1869.46z M1745.86,1821.14l-2.77,11.49c-0.12,0.42-0.22,0.81-0.32,1.2
					c-1.72,7.15-0.56,14.55,3.28,20.82c3.84,6.28,9.89,10.68,17.04,12.41c14.76,3.56,29.67-5.56,33.23-20.32
					c0.09-0.37,0.17-0.74,0.24-1.11l3.14-13.03c-9.38-8.25-15.8-4.88-22.6-1.32c-3.59,1.88-7.3,3.83-11.42,3.9
					C1756.06,1835.33,1748.54,1825.27,1745.86,1821.14z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1764.26,1824.96c-11.6,0-16.45-14.38-16.5-14.53l-0.07-0.22l6.6-27.39l1.58,0.38l-6.49,26.93
					c0.65,1.78,5.59,14.19,16.17,13.13l0.16,1.62C1765.22,1824.94,1764.73,1824.96,1764.26,1824.96z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1764.82,1808.41c-0.1-0.01-10.25-0.71-13.69-12l1.56-0.47c3.09,10.17,11.85,10.82,12.22,10.84L1764.82,1808.41z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1749.58,1950.66l-1.62-0.14c2.67-30.48,10.63-55.61,15.14-67.87c0.43-1.17,1.59-1.93,2.85-1.84
						c17.88,1.31,33.91-10.41,38.12-27.87c0.12-0.49,0.23-0.98,0.32-1.47l16.13-66.92c0.07-0.31,0.02-0.63-0.14-0.9
						c-0.17-0.27-0.43-0.46-0.74-0.54l-68.64-16.55c-0.31-0.07-0.63-0.02-0.9,0.14c-0.27,0.17-0.46,0.43-0.54,0.74l-16.1,66.79
						c-0.15,0.53-0.29,1.03-0.42,1.58c-4.21,17.45,4.72,35.19,21.23,42.18c1.15,0.49,1.85,1.7,1.7,2.94
						c-1.58,12.97-5.94,38.97-17.45,67.32l-1.51-0.61c11.44-28.18,15.78-54.01,17.34-66.9c0.06-0.53-0.22-1.03-0.72-1.24
						c-17.25-7.3-26.57-25.83-22.17-44.06c0.14-0.57,0.28-1.1,0.43-1.62l16.09-66.75c0.18-0.73,0.63-1.35,1.27-1.74
						c0.64-0.39,1.4-0.51,2.13-0.34l68.64,16.55c0.73,0.18,1.35,0.63,1.74,1.27c0.39,0.64,0.51,1.4,0.34,2.13l-16.13,66.88
						c-0.09,0.48-0.2,0.99-0.33,1.5c-4.4,18.23-21.15,30.48-39.82,29.11c-0.53-0.04-1.02,0.28-1.2,0.77
						C1760.15,1895.4,1752.24,1920.37,1749.58,1950.66z"
                            />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1768.92,1968.27c-8.69,0-18.95-1.34-29.48-3.88c-12.57-3.03-24.03-7.41-32.27-12.33c-8.5-5.08-12.62-10.23-11.59-14.49
					c1.96-8.14,22.46-10.17,47.68-4.7l-0.34,1.59c-23.53-5.1-44.06-3.53-45.75,3.49c-0.84,3.46,3.12,8.1,10.84,12.71
					c8.1,4.84,19.4,9.15,31.81,12.15c12.41,2.99,24.44,4.3,33.85,3.69c8.98-0.59,14.61-2.91,15.44-6.37
					c1.68-6.96-15.71-17.67-38.76-23.86l0.42-1.57c11.65,3.13,22.14,7.41,29.52,12.06c7.79,4.9,11.39,9.66,10.4,13.76
					c-1.03,4.27-7.04,6.97-16.92,7.62C1772.22,1968.22,1770.6,1968.27,1768.92,1968.27z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="1711.51"
                            y="1925.19"
                            transform="matrix(0.2344 -0.9721 0.9721 0.2344 -576.5405 3151.1426)"
                            width="1.63"
                            height="32.84"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="1707.37"
                            y="1936.08"
                            transform="matrix(0.2344 -0.9721 0.9721 0.2344 -584.22 3150.6504)"
                            width="1.63"
                            height="20.31"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="1711"
                            y="1925.33"
                            transform="matrix(0.2344 -0.9721 0.9721 0.2344 -570.1768 3145.3508)"
                            width="1.63"
                            height="18.68"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1916.53,1656.11c-0.93,0.73-2.11,1.15-3.27,1.23l-0.57,0.05c-0.17,0.02-0.35,0.03-0.52,0.04
						c-0.35,0.02-0.7,0.02-1.05,0l-4.19-0.39l-2.09-0.22l-0.26-0.03c-0.08-0.01-0.2-0.02-0.22-0.03c-0.1-0.02-0.2-0.03-0.3-0.03
						c-0.21-0.01-0.42,0.02-0.63,0.08c-0.21,0.06-0.42,0.16-0.62,0.29c-0.1,0.07-0.19,0.14-0.28,0.22l-0.13,0.14l-0.17,0.2
						l-1.34,1.63c-0.91,1.08-1.8,2.17-2.73,3.23c0.49-1.32,1.02-2.63,1.53-3.94l0.79-1.96l0.1-0.25c0.04-0.09,0.1-0.2,0.15-0.3
						c0.11-0.2,0.24-0.4,0.38-0.58c0.28-0.38,0.63-0.71,1.03-0.99c0.39-0.28,0.84-0.5,1.31-0.65c0.24-0.07,0.48-0.13,0.73-0.17
						l0.55-0.09l2.08-0.35l4.15-0.66c0.35-0.05,0.69-0.07,1.04-0.07c0.17,0,0.35,0,0.52,0l0.26,0.01l0.13,0l0.06,0l0.02-0.01
						c0.23,0.01,0.41-0.07,0.58-0.19c0.16-0.13,0.29-0.32,0.36-0.53c0.06-0.21,0.05-0.41,0-0.61c0-0.05-0.05-0.1-0.06-0.16
						c0,0-0.02-0.05-0.04-0.08l-0.06-0.12l-0.12-0.24l-0.47-0.93l-0.94-1.86l-1.88-3.72l-3.76-7.45l-0.12-0.23
						c-0.04-0.08-0.07-0.13-0.14-0.29c-0.11-0.27-0.24-0.53-0.31-0.82c-0.09-0.28-0.16-0.57-0.19-0.87
						c-0.05-0.29-0.06-0.59-0.06-0.89c0.01-1.19,0.41-2.35,1.08-3.3c0.67-0.96,1.66-1.71,2.78-2.11c0.56-0.21,1.14-0.31,1.72-0.34
						l1.07-0.04l8.34-0.27l4.17-0.13l2.08-0.06c0.06,0,0.05,0,0.07-0.01l0.04-0.01l0.08,0c0.05-0.03,0.11-0.02,0.15-0.04
						c0.04-0.02,0.1-0.03,0.14-0.06c0.02-0.01,0.05-0.02,0.07-0.03c0.02-0.02,0.04-0.04,0.06-0.05c0.17-0.11,0.31-0.31,0.38-0.51
						c0.08-0.21,0.08-0.41,0.02-0.61c-0.01-0.05-0.03-0.1-0.06-0.15c0.01-0.01-0.11-0.23-0.18-0.37l-0.47-0.93l-0.94-1.86
						l-3.76-7.45l-1.88-3.72l-0.24-0.47l-0.06-0.12l-0.03-0.06l-0.04-0.1l-0.17-0.4c-0.12-0.26-0.18-0.56-0.26-0.84
						c-0.06-0.29-0.1-0.59-0.13-0.88c-0.07-1.19,0.22-2.38,0.82-3.39c0.61-1,1.52-1.83,2.6-2.32c0.55-0.23,1.12-0.4,1.7-0.47
						l0.44-0.04c0.08-0.01,0.12-0.01,0.16-0.01l0.13,0l0.52-0.02l4.17-0.13l1.04-0.03l0.52-0.02l0.12,0l0.04,0
						c0.03,0,0.05-0.01,0.08-0.01c0.21-0.03,0.41-0.13,0.56-0.27c0.04-0.03,0.07-0.08,0.11-0.12l0.05-0.07
						c0.02-0.03,0.01-0.01,0.07-0.11l3.73-6.28c0.35-0.6,0.72-1.19,1.05-1.8c0.17-0.3,0.32-0.62,0.49-0.92
						c0.18-0.3,0.37-0.59,0.58-0.87c0.69-0.88,1.38-1.77,2.07-2.65c0.11-0.15,0.23-0.29,0.34-0.44c0.12-0.15,0.23-0.29,0.35-0.44
						l3.13,1.86c-0.07,0.17-0.14,0.34-0.22,0.51c-0.07,0.17-0.14,0.34-0.22,0.51c-0.44,1.03-0.89,2.06-1.34,3.09
						c-0.15,0.32-0.3,0.63-0.49,0.93c-0.18,0.3-0.38,0.58-0.57,0.87c-0.37,0.59-0.72,1.19-1.07,1.79l-3.73,6.28
						c-0.03,0.05-0.13,0.22-0.21,0.33c-0.08,0.12-0.17,0.24-0.26,0.36c-0.18,0.23-0.38,0.45-0.6,0.65
						c-0.86,0.81-1.97,1.34-3.13,1.52c-0.15,0.02-0.29,0.04-0.44,0.05l-0.22,0.02l-0.14,0.01l-0.52,0.02l-1.04,0.03l-4.17,0.13
						l-0.52,0.02l-0.13,0c-0.04,0-0.09,0-0.09,0.01c-0.03,0-0.05,0.01-0.08,0.01c-0.11,0-0.21,0.04-0.31,0.08
						c-0.18,0.09-0.34,0.23-0.46,0.42c-0.11,0.19-0.16,0.41-0.15,0.61c0.01,0.05,0.01,0.1,0.02,0.15c0.03,0.05,0.02,0.1,0.05,0.15
						c0.01,0.02,0.02,0.05,0.03,0.08l0.01,0.02l0.03,0.05l0.06,0.12l0.23,0.47l1.88,3.73l3.76,7.45l0.94,1.86l0.47,0.93
						c0.08,0.17,0.14,0.25,0.27,0.57c0.12,0.27,0.23,0.54,0.3,0.82c0.32,1.14,0.28,2.39-0.12,3.49c-0.39,1.11-1.11,2.09-2.07,2.78
						l-0.37,0.25c-0.13,0.08-0.26,0.15-0.39,0.21c-0.26,0.15-0.54,0.25-0.82,0.35c-0.28,0.1-0.57,0.15-0.86,0.22
						c-0.14,0.02-0.29,0.04-0.43,0.05l-0.22,0.02c-0.07,0-0.16,0.01-0.19,0.01l-2.09,0.07l-4.17,0.13l-8.35,0.26l-1.02,0.03
						c-0.12,0.02-0.22,0.02-0.32,0.06c-0.18,0.07-0.37,0.18-0.49,0.37c-0.12,0.18-0.21,0.39-0.2,0.6c0,0.05,0,0.1,0.01,0.15
						c0,0.05,0.02,0.1,0.03,0.15c0,0.06,0.05,0.1,0.06,0.16c0,0,0.05,0.11,0.09,0.18l0.12,0.23l3.76,7.45l1.88,3.72l0.94,1.86
						l0.47,0.93l0.18,0.35c0.02,0.04,0.03,0.07,0.07,0.15c0.11,0.27,0.24,0.53,0.32,0.82c0.36,1.12,0.36,2.38,0,3.5
						C1918.15,1654.38,1917.46,1655.39,1916.53,1656.11z"
                            />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1979.04,1600.67c-2.05,0-4.07-0.55-5.88-1.62c-5.49-3.29-7.28-10.38-4.04-15.84c3.24-5.46,10.32-7.28,15.79-4.07
					c2.7,1.6,4.58,4.12,5.35,7.11c0.76,2.99,0.31,6.1-1.26,8.76l0,0c-1.58,2.65-4.09,4.54-7.08,5.3
					C1980.96,1600.55,1980,1600.67,1979.04,1600.67z M1979.07,1579.19c-3.4,0-6.7,1.74-8.55,4.85c-2.78,4.69-1.24,10.78,3.43,13.58
					c2.32,1.38,4.99,1.76,7.56,1.11c2.57-0.65,4.73-2.27,6.08-4.55l0,0c1.35-2.28,1.74-4.95,1.08-7.52
					c-0.66-2.57-2.27-4.73-4.55-6.08C1982.54,1579.63,1980.79,1579.19,1979.07,1579.19z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1973.12,1599.02l-46.79-27.78c-2.65-1.58-4.54-4.09-5.3-7.08c-0.76-2.99-0.32-6.1,1.26-8.75
					c1.58-2.66,4.09-4.54,7.08-5.3c2.99-0.76,6.1-0.31,8.76,1.26l46.79,27.78l-0.83,1.4c-4.7-2.76-10.78-1.19-13.56,3.49
					c-2.78,4.69-1.24,10.78,3.43,13.58L1973.12,1599.02z M1932.24,1551.37c-0.82,0-1.65,0.1-2.47,0.31
					c-2.57,0.65-4.73,2.27-6.08,4.55c-1.35,2.28-1.74,4.95-1.08,7.52c0.66,2.57,2.27,4.73,4.55,6.08l41.76,24.79
					c-1.87-3.46-1.94-7.8,0.21-11.42c2.15-3.62,5.99-5.64,9.93-5.65l-41.76-24.79C1935.74,1551.84,1934,1551.37,1932.24,1551.37z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1973.11,1599.02c-5.44-3.26-7.23-10.35-3.99-15.81c3.24-5.46,10.32-7.28,15.79-4.07l-0.83,1.4
					c-4.7-2.76-10.78-1.19-13.56,3.49c-2.78,4.69-1.24,10.78,3.43,13.58L1973.11,1599.02z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="1937.78"
                            y="1582.17"
                            transform="matrix(0.8599 0.5105 -0.5105 0.8599 1083.2819 -770.2056)"
                            width="13.77"
                            height="12.12"
                        />
                    </g>
                    <g>
                        <path
                            className="st0"
                            d="M1947.78,1598.07l-13.24-7.86l7.02-11.82l13.24,7.86L1947.78,1598.07z M1936.77,1589.64l10.44,6.2l5.36-9.02l-10.44-6.2
					L1936.77,1589.64z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1591.31,1631.24c-47.23-24.74-57.82-87.48-59.47-99.81l1.62-0.22c1.62,12.19,12.09,74.21,58.61,98.58L1591.31,1631.24z
					"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1532.89,1532.35l-0.43-0.2c-0.13-0.06-13.34-6.55-4.37-28.18l0.51-1.23l0.86,1.02c0.4,0.47,9.69,11.53,8.51,20.73
					c-0.4,3.14-1.99,5.7-4.7,7.59L1532.89,1532.35z M1529.08,1505.89c-6.62,17.21,1.63,23.36,3.66,24.56
					c2.08-1.59,3.29-3.66,3.62-6.17C1537.25,1517.36,1531.35,1508.85,1529.08,1505.89z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1538.01,1542.91c-1.2,0-2.42-0.2-3.67-0.61l-0.45-0.15l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.41l1.29-0.33
					l-0.29,1.3c-0.14,0.6-3.41,14.67-11.48,19.25C1541.57,1542.44,1539.83,1542.91,1538.01,1542.91z M1535.32,1540.89
					c2.52,0.71,4.91,0.43,7.11-0.81c6.07-3.44,9.33-13.27,10.34-16.86C1535.03,1528.26,1535.07,1538.55,1535.32,1540.89z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1541.94,1558.3c-1.2,0-2.42-0.2-3.67-0.61l-0.45-0.15l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.41l1.29-0.33l-0.29,1.3
					c-0.14,0.6-3.41,14.67-11.48,19.25C1545.51,1557.83,1543.76,1558.3,1541.94,1558.3z M1539.26,1556.28
					c2.52,0.71,4.91,0.43,7.11-0.81c6.07-3.44,9.33-13.27,10.34-16.86C1538.96,1543.65,1539,1553.94,1539.26,1556.28z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1547.74,1574.78c-1.2,0-2.42-0.2-3.67-0.61l-0.45-0.15l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.41l1.29-0.33
					l-0.29,1.3c-0.14,0.6-3.41,14.67-11.48,19.25C1551.3,1574.31,1549.55,1574.78,1547.74,1574.78z M1545.05,1572.76
					c2.52,0.71,4.91,0.43,7.11-0.81c6.07-3.44,9.33-13.27,10.34-16.86C1544.75,1560.13,1544.79,1570.42,1545.05,1572.76z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1555.47,1591c-1.2,0-2.42-0.2-3.67-0.61l-0.45-0.15l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.4l1.29-0.33l-0.29,1.3
					c-0.14,0.6-3.41,14.67-11.48,19.24C1559.04,1590.53,1557.29,1591,1555.47,1591z M1552.79,1588.98c2.52,0.71,4.91,0.44,7.11-0.81
					c6.07-3.43,9.33-13.26,10.34-16.85C1552.49,1576.35,1552.53,1586.64,1552.79,1588.98z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1564.79,1605.68c-1.2,0-2.42-0.2-3.67-0.61l-0.45-0.15l-0.1-0.46c-0.03-0.15-2.77-14.6,19.92-20.4l1.29-0.33l-0.3,1.3
					c-0.14,0.6-3.41,14.67-11.48,19.24C1568.35,1605.2,1566.6,1605.68,1564.79,1605.68z M1562.11,1603.66
					c2.52,0.7,4.9,0.43,7.1-0.81c6.07-3.44,9.33-13.26,10.34-16.85C1561.98,1590.99,1561.89,1601.35,1562.11,1603.66z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1527.66,1549.07c-0.12,0-0.24,0-0.37-0.01c-9.27-0.28-18.73-11.21-19.12-11.68l-0.87-1.01l1.3-0.31
					c22.78-5.44,27.09,8.63,27.13,8.77l0.13,0.45l-0.33,0.34C1533.32,1547.91,1530.67,1549.07,1527.66,1549.07z M1510.34,1537.35
					c2.57,2.7,10.04,9.88,17.01,10.09c2.54,0.08,4.77-0.8,6.67-2.59C1533.14,1542.65,1528.39,1533.53,1510.34,1537.35z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1533.32,1568.37c-0.12,0-0.24,0-0.37-0.01c-9.27-0.28-18.73-11.21-19.12-11.68l-0.87-1.01l1.3-0.31
					c22.78-5.44,27.09,8.62,27.13,8.77l0.13,0.45l-0.33,0.34C1538.99,1567.21,1536.34,1568.37,1533.32,1568.37z M1516.01,1556.65
					c2.57,2.7,10.04,9.88,17.01,10.09c2.54,0.07,4.77-0.8,6.67-2.59C1538.81,1561.95,1534.05,1552.83,1516.01,1556.65z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1540.25,1585.72c-0.12,0-0.24,0-0.36-0.01c-9.27-0.28-18.73-11.21-19.12-11.68l-0.87-1.01l1.3-0.31
					c22.78-5.44,27.09,8.62,27.13,8.77l0.13,0.45l-0.33,0.34C1545.91,1584.57,1543.26,1585.72,1540.25,1585.72z M1522.93,1574
					c2.57,2.7,10.04,9.88,17.01,10.09c2.52,0.07,4.77-0.8,6.66-2.59C1545.73,1579.3,1540.97,1570.18,1522.93,1574z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1549.31,1604.31c-8.92,0-18.77-8.75-19.2-9.14l-0.99-0.89l1.25-0.48c21.87-8.36,27.99,5.01,28.05,5.15l0.19,0.43
					l-0.28,0.38c-1.97,2.66-4.57,4.17-7.72,4.49C1550.16,1604.29,1549.74,1604.31,1549.31,1604.31z M1532.25,1594.84
					c2.9,2.35,11.24,8.49,18.18,7.79c2.52-0.25,4.62-1.41,6.27-3.44C1555.55,1597.12,1549.65,1588.7,1532.25,1594.84z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1557.09,1620.88c-7.57,0-15.58-3.74-16.01-3.94l-1.21-0.57l1.06-0.81c18.57-14.27,28.25-3.19,28.35-3.08l0.3,0.36
					l-0.16,0.44c-1.13,3.11-3.19,5.3-6.12,6.51C1561.4,1620.57,1559.26,1620.88,1557.09,1620.88z M1543.04,1616.01
					c3.45,1.42,13.2,4.92,19.65,2.27c2.34-0.96,4.03-2.67,5.02-5.09C1566.03,1611.54,1557.96,1605.16,1543.04,1616.01z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1901.45,1953.03c-1.53-6.09-3.51-12.35-5.89-18.6c-5.16-13.58-4.97-25.87,0.56-36.53c11-21.18,39.75-28.72,40.04-28.79
					l0.4,1.58c-0.28,0.07-28.34,7.44-39,27.97c-5.31,10.23-5.47,22.07-0.48,35.19c2.4,6.31,4.4,12.63,5.94,18.78L1901.45,1953.03z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1942.9,1873.38c-2.86,0-5.41-1.07-7.61-3.19l-0.34-0.33l0.12-0.46c0.04-0.14,3.89-14.34,26.83-9.65l1.31,0.27
					l-0.83,1.04c-0.38,0.48-9.48,11.71-18.73,12.3C1943.4,1873.37,1943.15,1873.38,1942.9,1873.38z M1936.8,1869.36
					c1.96,1.74,4.22,2.54,6.75,2.38c6.96-0.44,14.19-7.86,16.67-10.64C1942.05,1857.86,1937.59,1867.14,1936.8,1869.36z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1924.46,1874.86l-0.32-0.35c-0.1-0.11-9.83-11.14,6.71-27.71l0.94-0.94l0.41,1.27c0.19,0.58,4.56,14.36-0.05,22.41
					c-1.57,2.75-4.01,4.51-7.24,5.23L1924.46,1874.86z M1931.04,1848.95c-12.69,13.39-7.4,22.21-5.99,24.1
					c2.53-0.68,4.44-2.13,5.7-4.32C1934.21,1862.67,1932,1852.55,1931.04,1848.95z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1909.13,1883.82l-0.36-0.31c-0.11-0.1-11.03-9.96,3.54-28.29l0.83-1.04l0.55,1.21c0.25,0.56,6.15,13.75,2.48,22.27
					c-1.25,2.91-3.47,4.93-6.61,6.01L1909.13,1883.82z M1912.74,1857.33c-10.98,14.6-4.78,22.91-3.22,24.62
					c2.43-0.96,4.17-2.62,5.17-4.94C1917.44,1870.6,1914.1,1860.8,1912.74,1857.33z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1899.17,1895.45l-0.46-0.12c-0.14-0.04-14.29-4.09-9.27-26.97l0.28-1.3l1.03,0.85c0.47,0.39,11.58,9.64,12.03,18.9
					c0.16,3.16-0.95,5.96-3.29,8.31L1899.17,1895.45z M1890.75,1870.08c-3.48,18.11,5.73,22.7,7.94,23.53
					c1.76-1.93,2.59-4.19,2.47-6.71C1900.82,1879.93,1893.5,1872.6,1890.75,1870.08z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1892.38,1905.34c-4.26,0-13.93-2.03-19.08-19.28l-0.38-1.28l1.31,0.24c0.6,0.11,14.8,2.82,19.69,10.7
					c1.67,2.69,2.06,5.67,1.15,8.86l-0.13,0.45l-0.46,0.11C1894.44,1905.16,1893.65,1905.34,1892.38,1905.34z M1875.27,1886.93
					c5.74,17.53,16.02,17.08,18.35,16.73c0.61-2.54,0.24-4.92-1.09-7.07C1888.85,1890.66,1878.9,1887.8,1875.27,1886.93z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1887.92,1922.54c-4.94,0-12.63-2.44-19.68-14.88l-0.66-1.16l1.33-0.06c0.61-0.03,15.05-0.56,21.58,6.03
					c2.23,2.25,3.27,5.07,3.1,8.38l-0.02,0.47l-0.42,0.21C1893.1,1921.55,1891.06,1922.54,1887.92,1922.54z M1870.35,1908.05
					c9.51,15.8,19.43,13.06,21.63,12.2c0.02-2.61-0.87-4.85-2.65-6.64C1884.43,1908.66,1874.08,1908.09,1870.35,1908.05z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1933.95,1888.41c-4.18,0-8.84-0.66-12.23-2.98c-2.61-1.79-4.17-4.36-4.62-7.64l-0.06-0.47l0.37-0.29
					c0.12-0.09,11.9-8.89,27.08,8.94l0.86,1.02l-1.3,0.31C1943.72,1887.37,1939.21,1888.41,1933.95,1888.41z M1918.8,1878.06
					c0.47,2.57,1.76,4.59,3.85,6.02c5.76,3.94,16.01,2.56,19.68,1.89C1930.13,1872.39,1920.78,1876.86,1918.8,1878.06z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1914.74,1897.61c-2.26,0-4.48-0.32-6.46-1.16c-2.92-1.24-4.95-3.45-6.05-6.57l-0.16-0.44l0.31-0.36
					c0.1-0.11,9.9-11.08,28.31,3.39l1.05,0.82l-1.21,0.56C1930.11,1894.04,1922.24,1897.61,1914.74,1897.61z M1903.95,1889.8
					c0.97,2.43,2.64,4.16,4.97,5.15c6.42,2.72,16.2-0.67,19.67-2.05C1913.79,1881.88,1905.65,1888.17,1903.95,1889.8z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1905.33,1916c-2.26,0-4.48-0.32-6.46-1.16c-2.92-1.24-4.95-3.45-6.05-6.57l-0.16-0.44l0.31-0.36
					c0.1-0.11,9.9-11.08,28.31,3.39l1.05,0.82l-1.21,0.56C1920.7,1912.43,1912.82,1916,1905.33,1916z M1894.54,1908.19
					c0.97,2.43,2.64,4.16,4.97,5.15c6.42,2.72,16.2-0.67,19.67-2.05C1904.38,1900.27,1896.24,1906.56,1894.54,1908.19z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1901.75,1933.22c-2.75,0-5.24-1.01-7.41-3.02l-0.35-0.32l0.1-0.46c0.03-0.14,3.56-14.43,26.6-10.28l1.31,0.24
					l-0.81,1.06c-0.37,0.49-9.2,11.93-18.44,12.73C1902.42,1933.2,1902.08,1933.22,1901.75,1933.22z M1895.82,1929.34
					c1.99,1.69,4.28,2.43,6.79,2.21c6.94-0.6,14-8.19,16.41-11.03C1900.97,1917.76,1896.58,1927.15,1895.82,1929.34z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1654.34,1908.4c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C1659.76,1905.97,1657.33,1908.4,1654.34,1908.4z M1654.34,1899.18c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79S1656.43,1899.18,1654.34,1899.18z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1637.76,1961.23c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C1643.18,1958.8,1640.75,1961.23,1637.76,1961.23z M1637.76,1952.01c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79S1639.85,1952.01,1637.76,1952.01z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1850.13,1864.72c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				S1853.12,1864.72,1850.13,1864.72z M1850.13,1855.5c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79c2.09,0,3.79-1.7,3.79-3.79
				S1852.22,1855.5,1850.13,1855.5z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1954.72,1802.85c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C1960.14,1800.42,1957.71,1802.85,1954.72,1802.85z M1954.72,1793.64c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79S1956.81,1793.64,1954.72,1793.64z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1757.65,1729.73c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C1763.08,1727.29,1760.64,1729.73,1757.65,1729.73z M1757.65,1720.51c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79S1759.75,1720.51,1757.65,1720.51z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1702.17,1613.44c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				S1705.16,1613.44,1702.17,1613.44z M1702.17,1604.22c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79c2.09,0,3.79-1.7,3.79-3.79
				S1704.26,1604.22,1702.17,1604.22z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1810.87,1530.96c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42s5.42,2.43,5.42,5.42S1813.86,1530.96,1810.87,1530.96
				z M1810.87,1521.74c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79c2.09,0,3.79-1.7,3.79-3.79S1812.96,1521.74,1810.87,1521.74z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1630.74,1544.56c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C1636.16,1542.13,1633.73,1544.56,1630.74,1544.56z M1630.74,1535.35c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79S1632.83,1535.35,1630.74,1535.35z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1952.81,1962.93c-2.99,0-5.42-2.43-5.42-5.42s2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				S1955.8,1962.93,1952.81,1962.93z M1952.81,1953.71c-2.09,0-3.79,1.7-3.79,3.79s1.7,3.79,3.79,3.79c2.09,0,3.79-1.7,3.79-3.79
				S1954.9,1953.71,1952.81,1953.71z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1844.39,1965.48c-2.99,0-5.42-2.43-5.42-5.42c0-2.99,2.43-5.42,5.42-5.42c2.99,0,5.42,2.43,5.42,5.42
				C1849.81,1963.05,1847.38,1965.48,1844.39,1965.48z M1844.39,1956.26c-2.09,0-3.79,1.7-3.79,3.79c0,2.09,1.7,3.79,3.79,3.79
				c2.09,0,3.79-1.7,3.79-3.79C1848.18,1957.97,1846.48,1956.26,1844.39,1956.26z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1620.24,1953.42l-6.88,3.15l-0.3-0.92c-2.45-5.35-8.79-7.71-14.13-5.26c-5.35,2.45-7.71,8.78-5.26,14.13l0.02,0.05
				l0.42,0.77l-0.78,0.39l-44.17,20.19l-18.21-39.83l0.82-0.3c0.11-0.04,0.22-0.09,0.33-0.14c5.35-2.45,7.71-8.78,5.26-14.13
				c-2.45-5.35-8.78-7.71-14.13-5.26c-0.11,0.05-0.22,0.1-0.32,0.16l-0.77,0.43l-5.48-11.99l12.29-5.62l0.35,0.7
				c2.58,5.15,8.71,7.3,13.96,4.91s7.62-8.44,5.42-13.77l-0.3-0.73l39.36-18l3.15,6.89l-0.74,0.34c-5.36,2.45-7.72,8.79-5.28,14.14
				c2.45,5.35,8.78,7.71,14.13,5.26l0.74-0.34l0.35,0.73L1620.24,1953.42z M1614.25,1954.37l3.84-1.75l-19.13-41.85
				c-6.01,2.29-12.85-0.46-15.56-6.38c-2.71-5.92-0.3-12.9,5.35-15.94l-1.81-3.95l-36.47,16.67c2.04,5.95-0.72,12.48-6.53,15.13
				s-12.55,0.47-15.72-4.96l-9.4,4.3l4.1,8.96c6.09-2.56,13.17,0.18,15.93,6.23c2.76,6.05,0.2,13.19-5.72,16.12l16.83,36.81
				l41.95-19.18c-2.37-6.03,0.37-12.95,6.33-15.68C1604.21,1946.17,1611.24,1948.63,1614.25,1954.37z"
                    />
                </g>
                <g>
                    <path
                        className="st0"
                        d="M1568.87,1947.31c-7.24,3.31-15.82,0.11-19.13-7.13s-0.11-15.82,7.13-19.13s15.82-0.11,19.13,7.13
				S1576.11,1944,1568.87,1947.31z M1557.54,1922.53c-6.42,2.94-9.26,10.55-6.32,16.97c2.94,6.42,10.55,9.26,16.97,6.32
				s9.26-10.55,6.32-16.97C1571.58,1922.43,1563.97,1919.6,1557.54,1922.53z"
                    />
                </g>
                <g>
                    <path
                        className="st0"
                        d="M1566.21,1968.77c-2.97,1.36-6.48,0.05-7.84-2.92c-1.36-2.97-0.05-6.48,2.92-7.84c2.97-1.36,6.48-0.05,7.84,2.92
				C1570.49,1963.89,1569.18,1967.41,1566.21,1968.77z M1561.97,1959.49c-2.15,0.98-3.1,3.53-2.12,5.68
				c0.98,2.15,3.53,3.1,5.68,2.12c2.15-0.98,3.1-3.53,2.12-5.68S1564.12,1958.5,1561.97,1959.49z"
                    />
                </g>
                <g>
                    <path
                        className="st0"
                        d="M1593.52,1935.09c-2.97,1.36-6.48,0.05-7.84-2.92c-1.36-2.97-0.05-6.48,2.92-7.84c2.97-1.36,6.48-0.05,7.84,2.92
				C1597.79,1930.22,1596.48,1933.74,1593.52,1935.09z M1589.27,1925.81c-2.15,0.98-3.1,3.53-2.12,5.68
				c0.98,2.15,3.53,3.1,5.68,2.12c2.15-0.98,3.1-3.53,2.12-5.68S1591.42,1924.83,1589.27,1925.81z"
                    />
                </g>
            </g>
        </g>
    </svg>
);
