import styled from 'styled-components';

import { RawSlider, RawSliderProps } from './RawSlider';

export interface SliderProps extends RawSliderProps {}

export const Slider = styled(RawSlider)<SliderProps>`
    display: flex;
    flex-direction: row;
    width: 100%;
    position: relative;
    cursor: pointer;
    color: ${(props) => props.theme.colors.primary[0]};

    .sides {
        z-index: 2;
        color: ${(props) => props.theme.colors.primary[0]};
        font-weight: bold;
        transition-property: color;
        transition-duration: 0.5s;

        &.toggled {
            color: ${(props) => props.theme.colors.white[0]};
        }
    }

    .slider {
        z-index: 1;
        position: absolute;
        top: 0;
        width: calc(50% - 8px);
        height: calc(100% - 8px);
        transition-property: left, background-color, color;
        transition-duration: 0.5s;

        left: 50%;
        background-color: ${(props) => props.theme.colors.primary[0]};

        &.toggled {
            left: 0;
            background-color: ${(props) => props.theme.colors.primary[5]};
        }
    }
`;
