import { theme } from '../../../../@Style/theme';
import { IconProps } from '../../../../components/Icon/Icon';

export const Other = (props: IconProps) => {
    const svgProps = {
        fill: 'none',
        stroke: props.color || theme.colors.primary[0],
        strokeWidth: 17,
        strokeLinecap: 'round',
        strokeMiterlimit: 10,
    } as const;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={props.size || 36} viewBox="0 0 777.255 541.964">
            <g>
                <path
                    {...svgProps}
                    d="M243.966,523.504L243.966,523.504c-116.02,0-210.073-94.053-210.073-210.073v-84.898
		c0-116.02,94.053-210.073,210.073-210.073h0"
                />
                <path
                    {...svgProps}
                    d="M533.289,18.46L533.289,18.46c116.02,0,210.073,94.053,210.073,210.073v84.898
		c0,116.02-94.053,210.073-210.073,210.073h0"
                />
                <circle {...svgProps} cx="388.347" cy="270.982" r="50.455" />
                <circle {...svgProps} cx="562.665" cy="270.982" r="50.455" />
                <circle {...svgProps} cx="214.029" cy="270.982" r="50.455" />
            </g>
        </svg>
    );
};
