import styled from 'styled-components';
import { space, SpaceProps } from 'styled-system';

import { RawSelector, RawSelectorProps } from './RawSeletor';

export interface SelectorProps extends SpaceProps, RawSelectorProps {}

export const Selector = styled(RawSelector)<SelectorProps>`
    ${space}
`;
