import { action } from 'typesafe-actions';

import { CharacteristicEntity, DishEntity, IngredientEntity, StyleEntity } from '../../@Typings';
import * as type from './constants';

export const setIngredients = (data: IngredientEntity[]) => action(type.SET_INGREDIENTS, data);
export const setSelectableDishes = (data: DishEntity[]) => action(type.SET_SELECTABLE_DISHES, data);
export const setCharacteristics = (data: string[]) => action(type.SET_CHARACTERISTICS, data);
export const setWeights = (data: string[]) => action(type.SET_WEIGHTS, data);
export const setStyle = (style: StyleEntity) => action(type.SET_STYLE, style);

export const createDish = () => action(type.CREATE_DISH);
export const saveDish = () => action(type.SAVE_DISH);
export const editDish = (dish: DishEntity) => action(type.EDIT_DISH, dish);
export const removeDish = (id: string) => action(type.REMOVE_DISH, id);
export const clearDishList = () => action(type.CLEAR_DISH_LIST);

export const addIngredient = (ingredient: IngredientEntity) => action(type.ADD_INGREDIENT, ingredient);
export const removeIngredient = (ingredient: IngredientEntity) => action(type.REMOVE_INGREDIENT, ingredient);

export const addCharacteristic = (characteristic: CharacteristicEntity) =>
    action(type.ADD_CHARACTERISTIC, characteristic);
export const removeCharacteristic = (characteristic: CharacteristicEntity) =>
    action(type.REMOVE_CHARACTERISTIC, characteristic);

export const addSelectableDish = (dish: DishEntity) => action(type.ADD_SELECTABLE_DISH, dish);
export const removeSelectableDish = (dish: DishEntity) => action(type.REMOVE_SELECTABLE_DISH, dish);

export const harmonizeDishes = (dishes: DishEntity[]) => action(type.HARMONIZE_DISHES, dishes);
export const harmonizeStyles = (styles: StyleEntity[]) => action(type.HARMONIZE_STYLES, styles);
