export enum Routes {
    dishes = '/dishes',
    ingredients = '/ingredients',
    selector = '/selector',
    characteristics = '/characteristics',
    results = '/results',
    details = '/details',
    type = '/type',
    alcohool = '/alcohool',
    wood = '/wood',
    style = '/style',
}
