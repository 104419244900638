import { characteristics as characteristicsData, dishes, ingredients } from '../services/databaseService';
import { DishEntity, IngredientEntity, StyleEntity } from '../../@Typings';
import {
    harmonizeDishes,
    harmonizeStyles,
    setCharacteristics,
    setIngredients,
    setSelectableDishes,
    setWeights,
} from './actions';
import { HarmonizerDispatch } from './store';
import harmonizeAlgorithm from '../services/harmonizeService.js';

export const getIngredients = () => {
    return async (dispatch: HarmonizerDispatch) => {
        try {
            dispatch(setIngredients(ingredients as IngredientEntity[]));
            // return axios.get(`${process.env.REACT_APP_API_URI}/ingredients`).then(({ data }) => {
            //     dispatch(setIngredients(data));
            // });
        } catch (e) {
            console.error('Error: ', e);
        }
    };
};

export const getSelectableDishes = () => {
    return async (dispatch: HarmonizerDispatch) => {
        try {
            dispatch(setSelectableDishes(dishes as DishEntity[]));

            // return axios.get(`${process.env.REACT_APP_API_URI}/dishes`).then(({ data }) => {
            //     dispatch(setSelectableDishes(data));
            // });
        } catch (e) {
            console.error('Error: ', e);
        }
    };
};

export const getCharacteristics = () => {
    return async (dispatch: HarmonizerDispatch) => {
        try {
            const { characteristics, weights } = characteristicsData;

            dispatch(setCharacteristics(characteristics));
            dispatch(setWeights(weights));

            // return axios.get(`${process.env.REACT_APP_API_URI}/characteristics`).then(({ data }) => {
            //     const { characteristics, weights } = data;
            //     dispatch(setCharacteristics(characteristics));
            //     dispatch(setWeights(weights));
            // });

        } catch (e) {
            console.error('Error: ', e);
        }
    };
};

export const harmonize = (dishes: DishEntity[]) => {
    return async (dispatch: HarmonizerDispatch) => {
        try {
            const data = harmonizeAlgorithm(dishes);
            dispatch(harmonizeDishes(data.dishes as DishEntity[]));
            dispatch(harmonizeStyles(data.styles as StyleEntity[]));

        } catch (e) {
            console.error('Error: ', e);
        }
    };
};
