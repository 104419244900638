import { useContext } from 'react';
import { DefaultTheme, ThemeContext } from 'styled-components';

import colors from './styles/colors';
import layout from './styles/layout';
import typography from './styles/typography';
import buttons from './variants/buttons';
import tags from './variants/tags';

const theme = { ...layout, colors, ...typography, buttons, tags };
export type Theme = typeof theme;

const useTheme = (): DefaultTheme => useContext(ThemeContext);

export { theme, useTheme };
