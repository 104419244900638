import { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { layout, LayoutProps } from 'styled-system';

export interface LogoProps extends HTMLAttributes<HTMLImageElement>, LayoutProps {}

export const Logo = styled.img<LogoProps>`
    height: inherit;

    ${layout}
`;
