import React, { HTMLAttributes, useEffect, useRef, useState } from 'react';

import { Box } from '../Box/Box';
import { Button } from '../Button/Button';
import { Flex } from '../Flex/Flex';
import { Modal } from '../Modal/Modal';
import { Radio } from '../Radio/Radio';
import { Tag } from '../Tag/Tag';
import { Paragraph, Subtitle } from '../Typography/Typography';

export type SelectOption = {
    label: string;
    value: string;
};

export interface RawSelectorProps extends HTMLAttributes<HTMLDivElement> {
    title: string;
    hint: string;
    selected?: SelectOption;
    options: SelectOption[];
    onChoose: (value: string) => void;
}

export const RawSelector = (props: RawSelectorProps) => {
    const { title, hint, options, selected, onChoose } = props;

    const ref = useRef<HTMLDivElement>(null);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (ref.current && !ref.current.contains(event.target as HTMLDivElement)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);

    const onToggle = () => {
        setIsOpen(!isOpen);
    };

    const onRemove = () => {
        if (selected) {
            onChoose('');
        }
        setIsOpen(false);
    };

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const chosen = options.find((option) => option.value === event.target.value);
        onChoose(chosen ? chosen.value : '');
        setIsOpen(false);
    };

    const variant = selected ? 'selected' : isOpen ? 'open' : 'default';

    return (
        <Box ref={ref} position="relative" className={props.className}>
            <Tag title={title} variant={variant} onClick={onToggle} icon="menu">
                {title} {!!selected && `(${selected.label})`}
            </Tag>
            {isOpen && (
                <Modal onBackdrop={onToggle} minWidth={260}>
                    <Flex p={5} pr={4} pb={0} alignItems="center" justifyContent="space-between">
                        <Subtitle fontSize={4} color="primary.0">
                            {title}
                        </Subtitle>
                        {!!selected && (
                            <Button
                                data-testid="trash-icon"
                                ml={5}
                                mr={3}
                                variant="icon"
                                small
                                icon="trash"
                                onClick={onRemove}
                            />
                        )}
                    </Flex>
                    <Paragraph px={5} py={4} fontSize={1} textColor="black.2">
                        {hint}
                    </Paragraph>
                    <Box pb={4}>
                        {options.map(({ label, value }) => (
                            <Radio
                                key={value}
                                px={5}
                                name="radio"
                                checked={!!selected && value === selected.value}
                                onChange={onChange}
                                value={value}
                                title={label}
                            >
                                {label}
                            </Radio>
                        ))}
                    </Box>
                </Modal>
            )}
        </Box>
    );
};
