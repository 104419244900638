import React, { HTMLAttributes } from 'react';

import { Box } from '../Box/Box';
import { Flex } from '../Flex/Flex';

export interface RawModalProps extends HTMLAttributes<HTMLDivElement> {
    onBackdrop?: () => void;
}

export const RawModal = (props: RawModalProps) => {
    const onClick = (event: React.MouseEvent) => {
        event.stopPropagation();
    };

    return (
        <Flex
            position="fixed"
            top={0}
            left={0}
            zIndex={100}
            width="100vw"
            height="100vh"
            bg="black.1"
            centerContent
            onClick={props.onBackdrop}
            data-testId="modal"
        >
            <Box className={props.className} onClick={onClick}>
                {props.children}
            </Box>
        </Flex>
    );
};
