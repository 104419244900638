import { stylesParsed, styles } from './databaseService';
import { mean } from 'lodash';

const harmonizeAlgorithm = (dishes) => {

    const summary = dishes.map((dish, index) => {
        return {
            id: dish.id,
            index: index.toString(),
            ingredients: dish.ingredients.map((ingredient) => ingredient.id),
            characteristics: dish.characteristics.map((characteristic) => characteristic.key + characteristic.weight),
        };
    });


    const result = styles.reduce((acc, style) => {
        let sum = [];
        summary.forEach((summaryData) => {
            const ingredientsAvg = mean(summaryData.ingredients.map((ingredient) => stylesParsed[style.id].ingredients[ingredient]));
            const characteristicsAvg = mean(summaryData.characteristics.map((characteristic) => stylesParsed[style.id].characteristics[characteristic]));
            const styleAvg = mean([ingredientsAvg, characteristicsAvg]);
            sum.push(styleAvg);
            if (!acc.dishes[summaryData.index]) {
                acc.dishes[summaryData.index] = {
                    styles: {
                        [style.id]: styleAvg,
                    },
                };
            } else {
                acc.dishes[summaryData.index].styles[style.id] = styleAvg;
            }
        });
        acc.styles[style.id] = mean(sum);
        return acc;
    }, {
        dishes: {},
        styles: {},
    });


    // Function to map dish styles
    const mapDishStyles = (id, styles) => {
        return Object.entries(styles)
            .sort((a, b) => b[1] - a[1])
            .map(([id, score], index) => {
                const style = stylesParsed[id];
                return {
                    id,
                    type: style.typeKey,
                    body: style.bodyKey,
                    score,
                    position: index + 1,
                };
            });
    };

    const mapStyles = (id, score, index) => {
        const style = stylesParsed[id];
        return {
            id,
            type: style.typeKey,
            body: style.bodyKey,
            minAlcohool: style.minAlcohool,
            maxAlcohool: style.maxAlcohool,
            maxWood: style.maxWood,
            minWood: style.minWood,
            score,
            position: index + 1,
        };
    };

    result.dishes = Object.entries(result.dishes).map(([id, { styles }]) => {
        const dish = dishes[id];
        return {
            id,
            ...dish,
            styles: mapDishStyles(id, styles),
        };
    });

    result.styles = Object.entries(result.styles)
        .sort((a, b) => b[1] - a[1])
        .map(([id, score], index) => mapStyles(id, score, index));

    return result;
};

export default harmonizeAlgorithm;


