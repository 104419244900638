import { theme } from '../../../../@Style/theme';
import { IconProps } from '../../../../components/Icon/Icon';

export const Bovine = (props: IconProps) => {
    const svgProps = {
        fill: 'none',
        stroke: props.color || theme.colors.primary[0],
        strokeWidth: 7,
        strokeMiterlimit: 10,
    } as const;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={props.size || 36} viewBox="0 0 631.279 722.663">
            <g>
                <g>
                    <g>
                        <g>
                            <path
                                {...svgProps}
                                d="M224.879,479.928c-0.864-0.152-1.727-0.395-2.585-0.732c-17.884-7.034-27.917-51.579-25.558-113.482
					c0.76-19.952,2.016-36.145,3.026-49.156c3.501-45.136,3.196-48.682-20.843-53.855c-19.041-4.097-30.743-10.621-34.782-19.391
					c-2.414-5.242-1.564-10.311-0.088-13.096c0.831-1.611,6.512-11.712,19.462-14.201c14.546-2.795,31.983,5.048,51.821,23.313
					c26.202,24.124,43.875,19.016,56.779,15.286c7.949-2.296,18.839-5.445,24.414,6.152c5.93,12.335-5.57,18.451-13.967,22.917
					c-13.97,7.43-33.101,17.606-36.573,52.73c-2.692,27.224-2.203,53.502-1.772,76.685c0.592,31.799,0.983,52.806-6.902,61.955
					C233.91,478.999,229.406,480.724,224.879,479.928z M155.238,235.86c-0.085,0.258-0.292,1.172,0.445,2.533
					c1.196,2.209,6.11,7.805,25.871,12.057c35.317,7.599,34.154,22.587,30.703,67.076c-1.001,12.902-2.246,28.958-2.997,48.664
					c-2.872,75.369,13.023,100.849,17.799,101.4c-0.007-0.022,0.292-0.178,0.759-0.719c4.758-5.521,4.304-29.931,3.865-53.539
					c-0.417-22.364-0.935-50.196,1.829-78.151c4.133-41.804,28.564-54.798,43.161-62.562c3.39-1.803,7.994-4.252,8.865-5.604
					c-0.031-0.14-0.111-0.406-0.309-0.817c-0.793-1.65-0.981-2.042-9.64,0.458c-13.969,4.037-37.354,10.795-68.746-18.106
					c-20.717-19.074-33.6-21.548-40.761-20.265C158.732,229.602,155.552,235.269,155.238,235.86z M155.229,235.877
					c-0.005,0.011-0.01,0.019-0.012,0.024l-0.101,0.198C155.131,236.077,155.177,236.002,155.229,235.877z M149.599,233.126
					l0.017,0.003L149.599,233.126z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                {...svgProps}
                                d="M242.181,709.415c-6.188,0-12.527-0.263-18.839-0.778c-55.844-4.572-105.271-28.72-132.218-64.597
					c-29.042-38.667-21.816-77.16-15.437-111.123c6.08-32.374,12.971-69.068-2.689-121.94
					c-8.504-28.718-19.124-49.804-28.492-68.408c-14.903-29.588-28.974-57.528-28.054-100.407
					c1.385-64.761,35.763-111.27,47.059-126.552c1.236-1.67,2.836-3.829,4.947-6.538c25.336-32.499,88.028-88.328,162.295-91.414
					c92.473-3.976,191.795,79.271,204.545,171.172c7.301,52.627-16.051,88.295-36.657,119.766
					c-19.65,30.015-35.171,53.723-30.206,92.595c2.615,20.481,9.92,34.776,17.655,49.911c11.821,23.137,25.217,49.359,22.784,93.404
					c-0.09,1.629-0.228,4.092-0.495,7.264c-4.498,53.039-32.63,97.244-55.353,118.983
					C326.962,695.685,287.597,709.415,242.181,709.415z M237.192,30.988c-1.962,0-3.919,0.039-5.874,0.123
					c-69.355,2.884-128.327,55.566-152.238,86.241c-2.025,2.595-3.557,4.666-4.686,6.19c-10.72,14.504-43.186,58.424-44.48,118.909
					c-0.846,39.528,12.491,66.012,26.614,94.053c9.629,19.117,20.536,40.775,29.384,70.65c16.581,55.985,9.37,94.377,3.009,128.248
					c-6.433,34.263-12.509,66.626,12.969,100.55c24.672,32.845,70.484,55.001,122.552,59.262c5.947,0.487,11.915,0.734,17.739,0.734
					c41.93,0,77.991-12.404,101.534-34.927c21.029-20.115,47.066-61.094,51.246-110.389c0.254-2.998,0.384-5.331,0.469-6.869
					c2.231-40.414-10.286-64.914-21.331-86.533c-8.285-16.211-16.108-31.523-19.021-54.334
					c-5.596-43.8,12.809-71.911,32.295-101.674c19.31-29.494,41.194-62.915,34.585-110.542
					C410.343,106.954,321.59,30.988,237.192,30.988z M241.484,660.006c-4.649,0-9.364-0.193-14.118-0.583
					c-41.358-3.386-78.438-20.643-96.762-45.039l0,0c-15.299-20.371-12.864-37.856-6.387-72.354
					c6.402-34.092,15.17-80.781-3.875-145.078c-9.76-32.959-21.439-56.145-31.74-76.601c-13.607-27.017-23.44-46.538-22.784-77.134
					c1.052-49.078,27.534-84.906,37.503-98.393c1.059-1.429,2.367-3.196,4.079-5.392c18.736-24.032,69.057-70.098,125.406-72.441
					c66.894-2.86,144.361,62.159,153.584,128.623c4.756,34.279-10.911,58.212-29.051,85.921
					c-20.121,30.732-45.155,68.967-37.882,125.91c3.704,29.02,13.776,48.732,22.66,66.122c10.28,20.119,19.155,37.488,17.454,68.253
					c-0.075,1.366-0.182,3.299-0.392,5.776c-3.568,42.119-27.911,75.637-40.284,87.477
					C302.363,650.886,273.52,660.006,241.484,660.006z M141.37,606.297c16.082,21.41,49.457,36.624,87.098,39.703
					c33.785,2.766,64.884-5.133,81.112-20.658c11.102-10.622,32.948-40.777,36.177-78.883c0.195-2.308,0.294-4.105,0.359-5.291
					c1.506-27.223-5.833-41.586-15.99-61.464c-9.39-18.379-20.027-39.202-24.029-70.552c-7.904-61.87,18.64-102.408,39.967-134.982
					c18.026-27.536,31.048-47.428,26.984-76.704C364.66,137,294.203,77.967,233.361,80.446
					c-51.419,2.141-97.93,44.935-115.34,67.266c-1.622,2.082-2.867,3.761-3.724,4.921c-9.421,12.744-34.057,46.071-35.017,90.875
					c-0.581,27.243,7.674,43.634,21.351,70.786c10.556,20.963,22.519,44.721,32.626,78.837
					c19.968,67.411,10.852,115.945,4.195,151.386C131.031,578.712,129.918,591.048,141.37,606.297L141.37,606.297z"
                            />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <path
                                {...svgProps}
                                d="M299.06,692.103c-2.379-0.584-4.803-1.257-7.227-1.975c-7.407,2.065-15.128,3.591-23.208,4.579
					c3.726,1.526,7.496,2.963,11.357,4.265c4.803,1.661,9.696,3.142,14.499,4.354c7.766-2.02,15.173-4.579,22.22-7.676
					C310.91,694.796,305.03,693.629,299.06,692.103z"
                            />
                            <path
                                {...svgProps}
                                d="M437.544,103.512c-13.691-2.873-27.472-3.591-40.984-2.738c-5.297,0.314-10.594,0.898-15.756,1.706
					c3.052,3.546,5.97,7.182,8.798,10.908c7.362,9.831,13.826,20.245,19.078,31.063c1.975,4.04,3.771,8.125,5.387,12.255
					c4.399,11.177,7.541,22.714,9.157,34.385c6.599,47.628-15.263,81.025-34.565,110.518c-0.718,1.122-1.481,2.244-2.244,3.367
					c-2.02,3.187-4.085,6.374-6.105,9.561c-15.981,25.318-28.729,51.309-23.971,88.747c0.629,4.848,1.436,9.337,2.469,13.556
					c1.75,7.227,3.995,13.736,6.599,19.841c2.963,7.272,6.419,14.005,9.966,20.963c11.043,21.592,23.567,46.102,21.322,86.502
					c-0.09,1.571-0.225,3.86-0.449,6.868c-3.636,42.69-23.612,79.14-42.555,101.181c-2.963,3.412-5.88,6.509-8.709,9.203
					c-1.347,1.302-2.738,2.559-4.175,3.771c-13.242,11.357-29.897,19.796-48.975,24.959c2.424,0.718,4.848,1.392,7.227,1.975
					c5.97,1.526,11.851,2.693,17.642,3.546c14.364-6.195,27.068-14.41,37.617-24.51c2.693-2.559,5.431-5.432,8.26-8.619
					c5.925-1.481,11.312-3.546,16.07-6.24c13.287-7.496,41.657-31.153,53.957-66.751c0.718-2.11,1.257-3.771,1.616-4.893
					c8.17-26.215,4.309-43.184-0.18-62.8c-3.86-16.968-8.26-36.226-5.117-62.127c6.195-50.77,36.181-78.332,60.286-100.508
					c21.771-19.976,40.535-37.214,43.902-67.873c6.599-59.479-46.146-132.604-104.547-144.769c-1.526-0.314-3.008-0.584-4.534-0.808
					c-5.073-11.537-11.402-22.714-18.764-33.308c9.876-0.135,19.886,0.718,29.852,2.783c1.706,0.359,3.411,0.763,5.072,1.212
					c72.945,18.45,133.007,103.515,124.748,178.391c-4.713,42.6-30.929,66.706-54.047,87.939
					c-23.387,21.457-45.428,41.747-50.186,80.801c-2.469,20.38,0.943,35.283,4.579,51.129c4.803,21.098,10.28,45.024-0.494,79.454
					c-0.404,1.302-1.033,3.277-1.93,5.836c-14.41,41.657-45.832,71.374-68.411,84.168c-21.098,11.985-48.256,15.891-77.749,11.492
					c-7.048,3.097-14.455,5.656-22.22,7.676c0.539,0.18,1.077,0.314,1.616,0.449c39.278,9.92,76.267,6.688,104.278-9.158
					c24.375-13.826,58.356-45.877,73.843-90.721c0.943-2.694,1.571-4.803,2.02-6.195c11.716-37.528,5.881-63.115,0.718-85.694
					c-3.367-14.769-6.554-28.684-4.309-46.954c4.22-34.699,22.804-51.802,46.371-73.439c24.689-22.669,52.655-48.391,57.862-95.48
					C585.948,213.94,518.345,120.301,437.544,103.512z M436.556,189.206c-1.392-10.19-3.86-20.245-7.272-30.121
					c51.848,13.063,97.769,77.883,91.889,130.898c-2.873,26.216-18.494,40.535-40.086,60.376
					c-25.542,23.477-57.369,52.7-64.057,107.914c-3.412,27.966,1.257,48.301,5.342,66.212c4.399,19.392,7.631,33.442,0.359,56.606
					c-0.314,1.032-0.808,2.559-1.481,4.489c-9.786,28.46-30.839,48.391-43.812,57.369c15.487-23.522,29.178-55.169,32.231-90.766
					c0.269-3.187,0.404-5.656,0.494-7.272c2.424-44.037-10.998-70.297-22.804-93.415c-6.285-12.345-12.345-24.151-15.756-39.189
					c-0.763-3.411-1.392-6.958-1.885-10.728c-0.853-6.599-1.122-12.749-0.808-18.584c0.987-23.208,10.1-41.164,22.31-60.511
					c1.93-3.097,3.95-6.24,6.015-9.427c0.898-1.347,1.795-2.693,2.693-4.085C420.531,277.504,443.873,241.861,436.556,189.206z"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};
