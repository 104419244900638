import { HTMLAttributes } from 'react';

import { Flex } from '../Flex/Flex';
import { Icon, IconType } from '../Icon/Icon';
import { Paragraph } from '../Typography/Typography';

export interface RawTagProps extends HTMLAttributes<HTMLDivElement> {
    loading?: boolean;
    icon?: IconType;
    'data-testid'?: string;
}

export const RawTag = (props: RawTagProps) => {
    const { className, loading, title, icon, onClick } = props;

    const selectedIcon = loading ? 'spinnerIcon' : icon;

    return (
        <Flex
            data-testid={`tag-${title}`}
            px={3}
            py={1}
            className={className}
            onClick={onClick}
            alignItems="center"
        >
            <Paragraph fontSize={2} fontWeight={2} mr={2} color="inherit">
                {props.children}
            </Paragraph>
            {selectedIcon && (
                <Flex className={loading ? 'spin' : ''} ml={2} mr={1} centerContent>
                    <Icon type={selectedIcon} />
                </Flex>
            )}
        </Flex>
    );
};
