import { theme } from '../../../../@Style/theme';
import { IconProps } from '../../../../components/Icon/Icon';

export const Veggie = (props: IconProps) => {
    const svgProps = {
        fill: 'none',
        stroke: props.color || theme.colors.primary[0],
        strokeWidth: 17,
        strokeMiterlimit: 10,
    } as const;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={props.size || 36} viewBox="0 0 424.045 715.151">
            <g>
                <polyline
                    {...svgProps}
                    points="245.779,715.151 246.722,618.074 199.797,552.704 199.797,505.308 199.797,499.184 199.797,417.159 	
		"
                />
                <polyline
                    {...svgProps}
                    points="334.918,391.436 334.918,497.346 334.918,505.308 334.918,550.867 288.016,616.237 288.959,715.151 	
		"
                />
                <polyline {...svgProps} points="334.918,505.308 293.175,505.308 241.539,505.308 199.797,505.308 	" />
                <line {...svgProps} x1="293.175" y1="505.308" x2="293.175" y2="423.308" />
                <line {...svgProps} x1="241.539" y1="505.308" x2="241.539" y2="424.368" />
                <g>
                    <path
                        {...svgProps}
                        d="M311.296,412.322c-0.004,0.028-0.009,0.056-0.013,0.083c-0.767,5.009-4.769,8.894-9.77,9.704
			c-74.873,12.123-154.29-10.683-212.027-68.42C29.605,293.808,7.297,210.606,22.562,133.34
			c58.515-11.543,120.422-1.555,173.024,29.988"
                    />
                    <line {...svgProps} x1="22.559" y1="133.336" x2="230.879" y2="341.655" />
                </g>
                <g>
                    <path
                        {...svgProps}
                        d="M406.135,219.016c0,78.417-36.9,148.194-94.314,192.898c-0.188,0.146-0.457-0.014-0.419-0.249
			c6.821-42.74,2.239-86.924-13.721-127.704c-11.896-30.482-30.152-59.056-54.769-83.673
			c-14.488-14.488-30.356-26.775-47.162-36.862c-0.098-0.059-0.146-0.174-0.12-0.285c14.27-61.036,51.406-113.264,101.901-147.154
			c0.09-0.061,0.21-0.061,0.3,0C363.163,59.863,406.135,134.418,406.135,219.016z"
                    />
                    <line {...svgProps} x1="297.68" y1="283.961" x2="297.68" y2="15.886" />
                </g>
            </g>
        </svg>
    );
};
