import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';

import { ActionBar, Button, Flex, HeavyParagraph, Modal, Paragraph, Selector, Tag } from '../../../@DesignSystem';
import { Card } from '../../../@DesignSystem/components/Card/Card';
import { Routes } from '../../../@Enums/routes';
import { CharacteristicEntity } from '../../../@Typings';
import { Header } from '../../components/Header/Header';
import { addCharacteristic, removeCharacteristic, saveDish } from '../../store/actions';
import { HarmonizerState } from '../../store/reducer';
import { HarmonizerDispatch } from '../../store/store';

export interface CharacteristicsComponentsProps
    extends RouteComponentProps,
        ReturnType<typeof mapStateToProps>,
        ReturnType<typeof mapDispatchToProps> {}

export const CharacteristicsComponent = (props: CharacteristicsComponentsProps) => {
    window.scrollTo(0, 0);

    const { t } = useTranslation();

    const { characteristics, weights, dish, history, onAddCharacteristic, onRemoveCharacteristic, onSaveDish } = props;

    useEffect(() => {
        if (!dish.id) {
            history.replace(Routes.dishes);
        }
    }, [dish, history]);

    const onSelectWeight = (key: string, weight: string) => {
        !weight ? onRemoveCharacteristic({ key }) : onAddCharacteristic({ key, weight });
    };

    const onBack = () => {
        history.push(Routes.ingredients);
    };

    const [error, setError] = useState(false);

    const onSave = () => {
        if (dish.ingredients.length || dish.characteristics.length) {
            onSaveDish();
            history.push(Routes.dishes);
        }
    };

    const errorModal = (
        <Modal maxWidth={280}>
            <Flex m={5} centerContent>
                <Paragraph textAlign="center">{t('errors.noContent')}</Paragraph>
            </Flex>
            <Flex m={4} centerContent>
                <Button variant="primary" onClick={() => setError(false)}>
                    {t('components.buttons.confirm')}
                </Button>
            </Flex>
        </Modal>
    );

    return (
        <Flex flexDirection="column" flex="1" width="100%" minWidth={360}>
            <Header>
                <HeavyParagraph textAlign="center" fontWeight={900} textColor="white.0">
                    {t('pages.characteristics.header')}
                </HeavyParagraph>
            </Header>
            <Flex height="100%" maxWidth={{ _: '100%', md: 800 }} m="auto" flexDirection="column">
                <Card>
                    <HeavyParagraph textColor="black.2">{t('components.dish.ingredients')}</HeavyParagraph>
                    <Flex my={4} flexWrap="wrap">
                        {dish.ingredients.map(({ key, cooking }) => {
                            return (
                                <Tag key={key} mr={3} mb={3} variant="readOnly">
                                    {t(`ingredients.${key}`)} {t(`cooking.${cooking}`, '')}
                                </Tag>
                            );
                        })}
                    </Flex>
                    <HeavyParagraph textColor="black.2">{t('components.dish.characteristics')}</HeavyParagraph>
                    <Flex my={4} flexWrap="wrap">
                        {characteristics.map((key) => {
                            const characteristic = dish.characteristics.find(
                                (characteristic) => characteristic.key === key
                            );
                            const weight =
                                !!characteristic && characteristic.weight
                                    ? {
                                          label: t(`weights.${characteristic.weight}`),
                                          value: characteristic.weight,
                                      }
                                    : undefined;
                            return (
                                <Selector
                                    mr={3}
                                    mb={3}
                                    key={key}
                                    selected={weight}
                                    hint={t('pages.characteristics.intensity')}
                                    onChoose={onSelectWeight.bind(null, key)}
                                    options={weights.map((weight) => ({
                                        label: t(`weights.${weight}`),
                                        value: weight,
                                    }))}
                                    title={t(`characteristics.${key}`)}
                                />
                            );
                        })}
                    </Flex>
                </Card>
            </Flex>
            <ActionBar>
                <Button
                    data-testid="components-buttons-back-characteristics"
                    variant="secondary"
                    height="100%"
                    onClick={onBack}
                >
                    {t('components.buttons.back')}
                </Button>
                <Button
                    data-testid="components-buttons-continue-characteristics"
                    variant="primary"
                    height="100%"
                    ml={3}
                    disabled={!(dish.ingredients.length || dish.characteristics.length)}
                    onClick={onSave}
                >
                    {t('components.buttons.finalizeDish')}
                </Button>
            </ActionBar>
            {error && errorModal}
        </Flex>
    );
};

const mapStateToProps = (state: HarmonizerState) => ({
    characteristics: state.characteristics,
    weights: state.weights,
    dish: state.dish,
});

const mapDispatchToProps = (dispatch: HarmonizerDispatch) => ({
    onAddCharacteristic: (characteristic: CharacteristicEntity) => dispatch(addCharacteristic(characteristic)),
    onRemoveCharacteristic: (characteristic: CharacteristicEntity) => dispatch(removeCharacteristic(characteristic)),
    onSaveDish: () => dispatch(saveDish()),
});

export const Characteristics = connect(mapStateToProps, mapDispatchToProps)(withRouter(CharacteristicsComponent));
