import { theme } from '../../../../@Style/theme';
import { IconProps } from '../../../../components/Icon/Icon';

export const Fungi = (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.size || 35.831} viewBox="0 0 35.831 31.099">
        <g transform="translate(1952.071 -142.364)">
            <g transform="translate(-1949.964 155.086)">
                <path
                    fill={props.color || theme.colors.primary[0]}
                    d="M-1888.315,446.355h-14.557l-.077-.177a7.931,7.931,0,0,1-.661-3.191,8.026,8.026,0,0,1,8.015-8.018,8.027,8.027,0,0,1,8.018,8.018,7.923,7.923,0,0,1-.661,3.191Zm-14.169-.588h13.781a7.357,7.357,0,0,0,.538-2.779,7.438,7.438,0,0,0-7.429-7.429,7.437,7.437,0,0,0-7.427,7.429A7.327,7.327,0,0,0-1902.484,445.767Z"
                    transform="translate(1903.61 -434.97)"
                />
            </g>
            <g transform="translate(-1945.909 165.883)">
                <path
                    fill={props.color || theme.colors.primary[0]}
                    d="M-1804.61,690.342h-3.975a1.767,1.767,0,0,1-1.765-1.765v-4.984a.294.294,0,0,1,.294-.294.294.294,0,0,1,.294.294v4.984a1.178,1.178,0,0,0,1.176,1.177h3.975a1.178,1.178,0,0,0,1.177-1.177v-4.984a.294.294,0,0,1,.294-.294.294.294,0,0,1,.294.294v4.984A1.765,1.765,0,0,1-1804.61,690.342Z"
                    transform="translate(1810.35 -683.3)"
                />
            </g>
            <g transform="translate(-1938.993 166.384)">
                <path
                    fill={props.color || theme.colors.primary[0]}
                    d="M-1643.29,701.908a9.956,9.956,0,0,1-7.917-3.879.294.294,0,0,1,.052-.413.294.294,0,0,1,.413.052,9.373,9.373,0,0,0,7.452,3.652,9.439,9.439,0,0,0,8.892-6.294.3.3,0,0,1,.375-.18.294.294,0,0,1,.18.375A10.03,10.03,0,0,1-1643.29,701.908Z"
                    transform="translate(1651.269 -694.829)"
                />
            </g>
            <g transform="translate(-1938.155 153.436)">
                <path
                    fill={props.color || theme.colors.primary[0]}
                    d="M-1631.7,400.41a.293.293,0,0,1-.209-.087.294.294,0,0,1,0-.416,9.95,9.95,0,0,1,7.054-2.907,10.01,10.01,0,0,1,4.869,1.262.3.3,0,0,1,.114.4.294.294,0,0,1-.4.113,9.418,9.418,0,0,0-4.582-1.187,9.367,9.367,0,0,0-6.639,2.736A.3.3,0,0,1-1631.7,400.41Z"
                    transform="translate(1631.994 -397)"
                />
            </g>
            <g transform="translate(-1952.071 142.365)">
                <path
                    fill={props.color || theme.colors.primary[0]}
                    d="M-1948.826,159.884a.293.293,0,0,1-.264-.165c-.013-.027-1.335-2.733-2.7-5.208-.965-1.75.736-2.868,2.237-3.854.9-.592,1.831-1.2,2.044-1.86.089-.273.163-.618.241-.983.441-2.056,1.179-5.5,6.831-4.9a15.675,15.675,0,0,0,5.757-.327c1.318-.3,2.113-.48,3.336.773,2.167,2.22,1.662,10.112,1.639,10.447a.293.293,0,0,1-.313.273.294.294,0,0,1-.274-.313c.005-.079.511-7.963-1.473-10-.994-1.017-1.518-.9-2.785-.61a16.386,16.386,0,0,1-5.948.339c-5.125-.537-5.744,2.341-6.195,4.442-.082.38-.158.74-.257,1.042-.276.852-1.25,1.492-2.281,2.17-1.659,1.091-2.7,1.894-2.045,3.079,1.371,2.488,2.7,5.207,2.712,5.234a.3.3,0,0,1-.135.393A.3.3,0,0,1-1948.826,159.884Z"
                    transform="translate(1952.071 -142.365)"
                />
            </g>
            <g transform="translate(-1941.146 146.903)">
                <path
                    fill={props.color || theme.colors.primary[0]}
                    d="M-1697.959,256.439l-1.818-.707-1.007-8.645a.294.294,0,0,1,.258-.326.292.292,0,0,1,.326.258l.966,8.294.975.379,2.137-4.563a.294.294,0,0,1,.391-.142.3.3,0,0,1,.142.391Z"
                    transform="translate(1700.786 -246.759)"
                />
            </g>
            <g transform="translate(-1945.909 151.667)">
                <path
                    fill={props.color || theme.colors.primary[0]}
                    d="M-1807.107,360.374a.294.294,0,0,1-.224-.1l-2.943-3.453a.294.294,0,0,1,.033-.415.294.294,0,0,1,.414.033l2.943,3.453a.3.3,0,0,1-.033.415A.294.294,0,0,1-1807.107,360.374Z"
                    transform="translate(1810.344 -356.333)"
                />
            </g>
            <g transform="translate(-1927.953 150.047)">
                <path
                    fill={props.color || theme.colors.primary[0]}
                    d="M-1389.7,339.207c-.121,0-.268-.067-.387-.324l-3.009-6.474a1.01,1.01,0,0,0-.438-.414l-3.432-1.364a.591.591,0,0,1-.378-.42.592.592,0,0,1,.175-.537l1.688-1.726a.66.66,0,0,0,.148-.489l-1.476-7.842c-.066-.351.09-.48.189-.527s.3-.083.526.193l4.459,5.412a.09.09,0,0,0,.063.037.082.082,0,0,0,.065-.034l1.666-1.864a.47.47,0,0,1,.532-.181.469.469,0,0,1,.276.49V327.4a1.221,1.221,0,0,0,.209.61l3.045,3.926a.48.48,0,0,1,.085.556.478.478,0,0,1-.524.2l-2.968-.406a.1.1,0,0,0-.078.018.1.1,0,0,0-.029.074l-.045,6.341c0,.345-.158.451-.288.479A.4.4,0,0,1-1389.7,339.207Zm.175-.52Zm-7.223-8.6,3.432,1.364a1.566,1.566,0,0,1,.754.712l2.643,5.688.039-5.474a.686.686,0,0,1,.232-.514.686.686,0,0,1,.542-.156l2.691.368-2.875-3.706a1.791,1.791,0,0,1-.332-.97V323.47l-1.446,1.618a.669.669,0,0,1-.515.23.675.675,0,0,1-.506-.251l-4.012-4.869,1.346,7.15a1.244,1.244,0,0,1-.306,1.009Zm1.477-1.931h0Zm5.9-4.955Z"
                    transform="translate(1397.355 -319.058)"
                />
            </g>
        </g>
    </svg>
);
