export interface ShortPatternProps {
    color: string;
    className?: string;
}

export const ShortPattern = (props: ShortPatternProps) => (
    <svg
        className={props.className}
        version="1.1"
        id="Camada_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 2000 2000"
    >
        <style type="text/css">{`.st0{fill:${props.color};}`}</style>
        <g>
            <g>
                <g>
                    <g>
                        <circle className="st0" cx="1198.67" cy="1260.49" r="18.44" />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1225.75,1178.68c0.68,2.09,7.14,21.9-4.03,32.26c-10.4,9.65-29.28,4.8-39.18-3.46c-6.06-5.06-5.07-8.32-11.52-17.86
					c-14.38-21.25-29.98-20.88-31.11-31.11c-1.75-15.92,33.87-36.34,42.63-29.38c5.89,4.68-3.18,19.48,4.61,26.5
					c6.19,5.59,14.66-1.33,25.35,4.61C1221.34,1165.15,1224.5,1174.84,1225.75,1178.68z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1159.49,1244.36c0.53,5.49-1.18,10-4.61,19.01c-4.87,12.8-7.31,19.22-12.1,20.17c-8.62,1.71-22.13-14.82-19.01-24.77
					c2.18-6.94,10.87-5.68,15.56-14.4c5.69-10.6-2.11-21.85,1.15-23.62C1144.09,1218.78,1158.11,1230.12,1159.49,1244.36z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1485.39,1186.23c-2.79,0-5.59-0.29-8.39-0.86c-10.72-2.22-19.93-8.48-25.95-17.63l-311.42-473.91
					c-0.15-0.23-0.3-0.48-0.44-0.74c-24.01-44.36-21-97.69,7.84-139.19c1-1.43,2.02-2.85,3.08-4.25c0.71-0.94,0.75-2.24,0.1-3.23
					l-144.81-220.37c-1.37-2.08-1.85-4.58-1.34-7.02s1.93-4.54,4.02-5.91l55.93-36.75c2.09-1.37,4.58-1.84,7.02-1.34
					c2.44,0.51,4.54,1.93,5.91,4.02l144.81,220.37c0.65,0.99,1.85,1.47,3,1.19c1.7-0.41,3.4-0.79,5.12-1.14
					c49.52-10.03,99.68,8.38,130.87,48.03c0.18,0.23,0.35,0.47,0.51,0.71l311.41,473.9c12.41,18.89,7.14,44.34-11.74,56.75
					l-153.13,100.63C1501.04,1183.93,1493.3,1186.23,1485.39,1186.23z M1069.15,281.36c-0.55,0-1.08,0.16-1.54,0.46l-55.93,36.76
					c-0.63,0.41-1.06,1.05-1.22,1.79c-0.15,0.74-0.01,1.49,0.41,2.12l144.81,220.37c2.16,3.29,2.02,7.6-0.35,10.74
					c-1,1.33-1.98,2.67-2.92,4.04c-27.44,39.46-30.29,90.18-7.46,132.37c0.04,0.08,0.1,0.17,0.15,0.25l311.42,473.92
					c5.05,7.69,12.8,12.96,21.82,14.82c9.02,1.87,18.22,0.11,25.91-4.95l153.14-100.63c7.69-5.06,12.96-12.8,14.82-21.82
					c1.87-9.01,0.11-18.21-4.95-25.91l-311.42-473.92c-0.05-0.08-0.11-0.16-0.17-0.23c-29.66-37.7-77.34-55.2-124.46-45.66
					c-1.64,0.33-3.25,0.69-4.87,1.08c-3.81,0.93-7.83-0.66-9.99-3.94l-144.81-220.37c-0.41-0.63-1.05-1.06-1.78-1.21
					C1069.53,281.38,1069.34,281.36,1069.15,281.36z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1471.03,1164.45c-4.13,0-8.29-0.63-12.35-1.9l1.96-6.22c9.92,3.12,20.59,1.64,29.28-4.07l61.2-40.21l3.58,5.45
					l-61.2,40.21C1486.72,1162.16,1478.93,1164.45,1471.03,1164.45z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1460.86,1148.9c-2.79,0-5.59-0.29-8.39-0.86l1.32-6.38c9.02,1.86,18.21,0.11,25.91-4.95l15.58-10.24l3.58,5.45
					l-15.58,10.24C1476.51,1146.6,1468.77,1148.9,1460.86,1148.9z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1334.41,573.88c-21.44-27.37-57.37-40.59-91.49-33.69l-1.29-6.39c37.09-7.51,74.62,6.31,97.92,36.06L1334.41,573.88z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M989.74,327.96c-1.07,0-2.16-0.11-3.23-0.33c-4.13-0.86-7.69-3.27-10-6.8l-19.57-29.78c-4.78-7.28-2.75-17.1,4.53-21.88
					l80.72-53.04c3.52-2.32,7.75-3.12,11.88-2.27c4.13,0.86,7.69,3.27,10,6.8l19.57,29.78c4.78,7.28,2.75,17.1-4.53,21.88
					l-80.72,53.04C995.78,327.08,992.79,327.96,989.74,327.96z M1050.84,220.05c-1.79,0-3.55,0.52-5.08,1.53l-80.72,53.04
					c-4.28,2.81-5.47,8.58-2.66,12.85l19.57,29.78c1.36,2.07,3.45,3.49,5.87,3.99c2.43,0.5,4.91,0.03,6.98-1.33l80.72-53.04
					c4.27-2.81,5.47-8.58,2.66-12.85l-19.57-29.78c-1.36-2.07-3.45-3.49-5.87-3.99C1052.1,220.11,1051.47,220.05,1050.84,220.05z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1438.66,671.9l153.06,232.92c-81.54,76.86-164.41,120.56-215.75,142.71l-153.06-232.92
					C1274.25,792.46,1357.12,748.76,1438.66,671.9z"
                        />
                    </g>
                    <g>
                        <path
                            className="st0"
                            d="M1374.74,1051.61l-1.5-2.29l-155.19-236.17l3.56-1.54c53.9-23.26,134.92-66.8,214.81-142.09l2.83-2.66l2.13,3.25
					l154.56,235.21l-1.99,1.88c-80.57,75.95-162.31,119.87-216.69,143.33L1374.74,1051.61z M1227.76,816.06l149.43,227.4
					c53.43-23.32,132.3-66.13,210.3-139.13l-149.43-227.39C1360.15,749.62,1281.46,792.51,1227.76,816.06z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1244.22,847.8l-2.85-5.86c24.75-12.04,49.8-25.55,74.45-40.16l3.32,5.61
					C1294.34,822.09,1269.13,835.69,1244.22,847.8z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1256.82,866.98l-2.85-5.86c24.72-12.01,49.77-25.52,74.45-40.16c0.4-0.24,41.19-24.65,49.03-31.24
					l4.2,4.99c-8.23,6.92-48.19,30.84-49.89,31.85C1306.91,841.3,1281.7,854.89,1256.82,866.98z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="1023.76"
                            y="288.49"
                            transform="matrix(0.8357 -0.5492 0.5492 0.8357 -9.9421 639.1282)"
                            width="78.98"
                            height="95.38"
                        />
                    </g>
                    <g>
                        <path
                            className="st0"
                            d="M1055.5,402.24l-55.96-85.16l71.45-46.95l55.96,85.16L1055.5,402.24z M1008.57,318.95l48.8,74.26l60.56-39.79
					l-48.8-74.26L1008.57,318.95z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M414.94,998.28c-5.99-1.81-12.34-2.77-18.92-2.77c-36.28,0-65.68,29.4-65.68,65.68c0,9.95,2.21,19.39,6.18,27.84
					l-3.68,4.23l-72.22,13.06l42.26-67.6c0,0-104.48,9.22-106.78,9.22c-2.31,0,52.22-62.99,52.22-62.99l-77.58-85.28l100.64,25.35
					v-90.65l80.66,81.44l36.1-69.91L414.94,998.28z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M460.54,1408.99c-38.01,0-68.94-30.93-68.94-68.94c0-9.79,2.01-19.24,5.97-28.1l0.95-2.13l2.32,0.21
						c1.82,0.17,3.68,0.25,5.55,0.25c24.6,0,46.99-14.52,57.02-36.98l0.95-2.12l2.31,0.2c35.22,3.09,62.81,33.25,62.81,68.66
						C529.48,1378.06,498.55,1408.99,460.54,1408.99z M402.62,1316.7c-2.99,7.41-4.51,15.25-4.51,23.35
						c0,34.42,28,62.42,62.42,62.42s62.42-28,62.42-62.42c0-31.3-23.81-58.07-54.61-61.93c-11.53,23.58-35.59,38.69-61.97,38.69
						C405.13,1316.8,403.87,1316.77,402.62,1316.7z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M400.25,1316.53c-35.22-3.09-62.81-33.25-62.81-68.66c0-38.01,30.93-68.94,68.94-68.94s68.94,30.93,68.94,68.94
						c0,9.78-2.01,19.23-5.97,28.1l-5.95-2.66c3.58-8.02,5.4-16.58,5.4-25.44c0-34.42-28-62.42-62.42-62.42s-62.42,28-62.42,62.42
						c0,32.06,24.98,59.37,56.86,62.17L400.25,1316.53z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M523.92,1324.87h-0.32v-6.52h0.32c34.42,0,62.42-28,62.42-62.42c0-15.01-5.41-29.52-15.24-40.87
						c-11.88-13.69-29.08-21.55-47.18-21.55c-0.77,0-1.53,0.01-2.29,0.04l-0.24-6.52c0.84-0.03,1.68-0.05,2.53-0.05
						c20,0,38.99,8.67,52.11,23.8c10.85,12.54,16.83,28.57,16.83,45.14C592.86,1293.95,561.93,1324.87,523.92,1324.87z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M470.04,1233.19l-0.89-6.46c30.68-4.24,53.81-30.82,53.81-61.84c0-34.42-28-62.42-62.42-62.42s-62.42,28-62.42,62.42
						c0,5.82,0.79,11.54,2.35,17.01l-6.27,1.79c-1.72-6.05-2.6-12.37-2.6-18.8c0-38.01,30.93-68.94,68.94-68.94
						s68.94,30.93,68.94,68.94C529.48,1199.15,503.92,1228.51,470.04,1233.19z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M346.71,1222.34c-32.74-5.95-56.51-34.47-56.51-67.81c0-38.01,30.92-68.94,68.93-68.94
						c23.28,0,44.84,11.63,57.66,31.12l-5.45,3.58c-11.61-17.65-31.13-28.18-52.21-28.18c-34.41,0-62.41,28-62.41,62.42
						c0,30.19,21.51,56.01,51.15,61.4L346.71,1222.34z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M573.91,1216.17l-0.69-6.48c31.78-3.38,55.74-30.06,55.74-62.07c0-34.42-28-62.42-62.42-62.42
						c-24.1,0-45.62,13.48-56.17,35.18l-5.87-2.85c11.47-23.6,35.82-38.85,62.03-38.85c38.01,0,68.94,30.93,68.94,68.94
						C635.49,1182.96,609.02,1212.44,573.91,1216.17z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M563.06,1083.86l-5.66-3.24c5.36-9.38,8.2-20.08,8.2-30.95c0-34.42-28-62.42-62.42-62.42
						c-22.79,0-43.77,12.42-54.74,32.41l-5.72-3.14c12.12-22.08,35.29-35.79,60.46-35.79c38.01,0,68.94,30.93,68.94,68.94
						C572.12,1061.67,568.98,1073.49,563.06,1083.86z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M451.45,1102.2l-5.24-3.88c1.66-2.24,3.2-4.64,4.57-7.12c5.01-9.14,7.66-19.52,7.66-30c0-15.06-5.44-29.61-15.32-40.97
						c-7.78-8.93-17.85-15.44-29.12-18.82c-5.78-1.75-11.83-2.63-17.99-2.63c-34.42,0-62.42,28-62.42,62.42
						c0,9.27,1.98,18.17,5.87,26.45c0.49,1.08,1,2.08,1.53,3.07l-5.73,3.11c-0.61-1.13-1.19-2.27-1.72-3.45
						c-4.29-9.12-6.47-18.95-6.47-29.19c0-38.01,30.93-68.94,68.94-68.94c6.8,0,13.48,0.98,19.86,2.91
						c12.45,3.73,23.57,10.92,32.15,20.78c10.91,12.55,16.92,28.62,16.92,45.25c0,11.57-2.93,23.03-8.47,33.15
						C454.98,1097.09,453.28,1099.72,451.45,1102.2z"
                            />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M450.75,1009.92c-8.4-19.71-9.61-35.61-9.14-46.55c0.88-20.2,7.66-27.28,11.44-58.31c1.72-14.11,2.83-23.24,1.15-35.72
					c-1.57-11.63-4.16-30.91-19.59-47.24c-16.96-17.96-38.72-21.39-46.52-22.04c-15.7-1.3-28.37,1.3-28.37,1.3
					c84.12,2.3,93.33,77.2,77.2,123.29c-16.13,46.09,4.61,91.03,4.61,91.03l4.04,2.41l6.36-8.78"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M446.72,905.06c0,0,37.45-12.1,35.14-51.85c-2.3-39.75-12.68-53-12.68-53s15.56,14.4,18.44,28.23
					s10.37,46.09-9.79,63.95c-20.16,17.86-29.96,25.35-32.84,36.87L446.72,905.06z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="349.07" y="1276.67" width="60.59" height="6.52" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="361.79" y="1292.81" width="47.87" height="6.52" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="339.75" y="1243.64" width="69.9" height="6.52" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="342.47" y="1259.77" width="67.18" height="6.52" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="351.64" y="1208.31" width="58.01" height="6.52" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="343.86" y="1224.43" width="65.8" height="6.52" />
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <rect className="st0" x="302.21" y="1182.18" width="48.87" height="6.52" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="314.93" y="1198.32" width="21.75" height="6.52" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="292.89" y="1149.16" width="69.9" height="6.52" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="295.61" y="1165.28" width="67.18" height="6.52" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="304.78" y="1113.82" width="31.89" height="6.52" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="297" y="1129.95" width="53.51" height="6.52" />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <rect className="st0" x="324.68" y="1067.53" width="48.87" height="6.52" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="315.36" y="1034.5" width="69.9" height="6.52" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="318.08" y="1050.63" width="67.18" height="6.52" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect className="st0" x="319.47" y="1015.29" width="53.51" height="6.52" />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="394.87" y="1347.16" width="63.95" height="6.52" />
                    </g>
                </g>
                <g>
                    <g>
                        <rect className="st0" x="398.9" y="1363.29" width="46.09" height="6.52" />
                    </g>
                </g>
                <g>
                    <g>
                        <polygon
                            className="st0"
                            points="696.87,879.47 634.56,745.63 588.92,841.56 533.54,653.02 539.8,651.19 590.37,823.35 634.63,730.32 
					696.76,863.8 740.82,765.77 863.86,769.1 791.43,678.82 871.4,607.5 707.6,625.45 747.59,537.72 547.08,640.11 544.11,634.3 
					761.28,523.41 718.28,617.72 890.95,598.8 800.38,679.57 877.75,775.99 744.99,772.4 				"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M545.53,637.31l-11.19,5.02l2.33,9.78c-6.82-5.06-17.33-11.6-33.44-19.18C464.05,614.49,480.95,573,480.95,573
					s0.96,10.57,4.31,21.81c4.73,15.8,16.27,23.87,18.73,25.83c7.39,5.89,14.18,8.33,23.04,11.53c6.91,2.47,13.22,4.05,18.55,5.05
					L545.53,637.31z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M317.09,720.5c-11.96,0-21.69-9.73-21.69-21.7s9.73-21.7,21.69-21.7c11.96,0,21.7,9.73,21.7,21.7
					S329.05,720.5,317.09,720.5z M317.09,683.62c-8.37,0-15.18,6.81-15.18,15.18s6.81,15.18,15.18,15.18
					c8.37,0,15.18-6.81,15.18-15.18S325.46,683.62,317.09,683.62z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M153.46,1191.78c-11.96,0-21.7-9.73-21.7-21.7c0-11.96,9.73-21.7,21.7-21.7c11.96,0,21.69,9.73,21.69,21.7
					C175.16,1182.05,165.43,1191.78,153.46,1191.78z M153.46,1154.91c-8.37,0-15.18,6.81-15.18,15.18c0,8.37,6.81,15.18,15.18,15.18
					c8.37,0,15.18-6.81,15.18-15.18C168.64,1161.72,161.83,1154.91,153.46,1154.91z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M658.16,1065.03c-11.96,0-21.7-9.73-21.7-21.7c0-11.96,9.73-21.7,21.7-21.7s21.7,9.73,21.7,21.7
					C679.86,1055.3,670.13,1065.03,658.16,1065.03z M658.16,1028.16c-8.37,0-15.18,6.81-15.18,15.18c0,8.37,6.81,15.18,15.18,15.18
					s15.18-6.81,15.18-15.18C673.34,1034.96,666.53,1028.16,658.16,1028.16z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M648.94,1321.99c-11.96,0-21.69-9.73-21.69-21.7c0-11.96,9.73-21.7,21.69-21.7c11.96,0,21.7,9.73,21.7,21.7
					C670.64,1312.26,660.91,1321.99,648.94,1321.99z M648.94,1285.12c-8.37,0-15.18,6.81-15.18,15.18c0,8.37,6.81,15.18,15.18,15.18
					c8.37,0,15.18-6.81,15.18-15.18C664.12,1291.92,657.31,1285.12,648.94,1285.12z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <polygon
                            className="st0"
                            points="869.62,775.58 556.2,653.43 533.26,645.4 535.42,639.25 558.46,647.32 871.99,769.51 				"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <polygon
                            className="st0"
                            points="680.84,750.53 671.29,694 723.17,669.97 725.91,675.88 678.55,697.82 687.27,749.44 				"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <polygon
                            className="st0"
                            points="599.7,719.34 593.22,718.67 598.64,666.67 631.8,636.98 636.15,641.83 604.86,669.85 				"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1202.48,1329.17l-13,53.9c-0.3,1.5-0.63,3.04-0.99,4.56c-14.68,60.89-75.97,98.36-136.86,83.68
					c-60.9-14.68-98.38-75.97-83.7-136.86c0.39-1.62,0.82-3.21,1.28-4.8l12.93-53.62c0,0,34.57,62.06,80.58,61.3
					S1141.74,1274.23,1202.48,1329.17z"
                        />
                    </g>
                    <g>
                        <path
                            className="st0"
                            d="M1078.21,1477.75c-9.03,0-18.2-1.06-27.35-3.26c-30.3-7.3-55.95-25.98-72.21-52.57c-16.26-26.59-21.2-57.93-13.89-88.22
					c0.4-1.67,0.84-3.31,1.31-4.94l14.85-61.58l4.06,7.28c0.33,0.6,33.76,59.64,76.9,59.64c0.26,0,0.52,0,0.78-0.01
					c14.95-0.25,28.46-7.34,42.77-14.84c28.3-14.84,57.56-30.19,99.23,7.51l1.44,1.3l-13.45,55.79c-0.28,1.42-0.62,3-0.99,4.56
					C1178.78,1441.79,1130.87,1477.75,1078.21,1477.75z M983.46,1284.46l-11.08,45.96c-0.48,1.68-0.9,3.23-1.28,4.8
					c-6.9,28.6-2.24,58.18,13.12,83.29c15.36,25.11,39.57,42.74,68.18,49.64c59.05,14.24,118.69-22.22,132.93-81.28
					c0.35-1.47,0.67-2.98,0.97-4.44l12.57-52.13c-37.51-33.02-63.21-19.54-90.39-5.28c-14.35,7.53-29.2,15.31-45.69,15.59
					C1024.26,1341.21,994.15,1300.97,983.46,1284.46z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1057.04,1299.75c-46.41,0-65.79-57.52-65.99-58.13l-0.29-0.88l26.41-109.56l6.34,1.53l-25.97,107.72
					c2.6,7.13,22.34,56.77,64.66,52.53l0.65,6.49C1060.87,1299.65,1058.93,1299.74,1057.04,1299.75z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1059.27,1233.53c-0.41-0.02-41.02-2.84-54.74-47.99l6.24-1.9c12.37,40.68,47.39,43.28,48.88,43.37L1059.27,1233.53z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M998.33,1802.56l-6.49-0.57c10.68-121.93,42.53-222.43,60.57-271.49c1.73-4.69,6.38-7.73,11.42-7.35
						c71.53,5.25,135.64-41.66,152.48-111.49c0.47-1.96,0.9-3.93,1.28-5.88l64.53-267.68c0.3-1.24,0.1-2.51-0.57-3.6
						c-0.66-1.08-1.71-1.85-2.95-2.14l-274.54-66.19c-1.24-0.3-2.51-0.1-3.6,0.57c-1.08,0.66-1.84,1.71-2.14,2.95l-64.42,267.17
						c-0.61,2.11-1.15,4.13-1.67,6.32c-16.83,69.82,18.87,140.77,84.91,168.72c4.61,1.95,7.41,6.78,6.81,11.74
						c-6.3,51.89-23.75,155.86-69.81,269.26l-6.04-2.45c45.78-112.7,63.12-216.03,69.38-267.6c0.26-2.12-0.9-4.11-2.88-4.95
						c-68.98-29.2-106.28-103.32-88.7-176.25c0.55-2.3,1.11-4.41,1.71-6.47l64.38-267.02c0.7-2.93,2.51-5.41,5.08-6.98
						c2.57-1.57,5.6-2.05,8.53-1.34l274.54,66.19c2.93,0.71,5.41,2.51,6.98,5.08c1.57,2.57,2.05,5.6,1.34,8.53l-64.51,267.54
						c-0.37,1.9-0.82,3.96-1.31,6.02c-17.59,72.94-84.58,121.91-159.29,116.46c-2.12-0.17-4.09,1.12-4.82,3.1
						C1040.6,1581.5,1008.95,1681.38,998.33,1802.56z"
                            />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1075.67,1872.98c-34.74,0-75.81-5.36-117.94-15.52c-50.28-12.12-96.12-29.64-129.07-49.33
					c-34.01-20.32-50.48-40.9-46.36-57.97c7.85-32.58,89.85-40.67,190.72-18.81l-1.38,6.37c-94.14-20.39-176.23-14.13-183.01,13.97
					c-3.34,13.85,12.47,32.39,43.37,50.84c32.41,19.36,77.6,36.61,127.26,48.59c49.66,11.97,97.74,17.21,135.41,14.75
					c35.91-2.35,58.42-11.64,61.76-25.5c6.71-27.85-62.84-70.67-155.04-95.44l1.69-6.3c46.61,12.53,88.55,29.66,118.1,48.23
					c31.17,19.6,45.55,38.63,41.59,55.03c-4.11,17.07-28.15,27.89-67.68,30.47C1088.88,1872.78,1082.39,1872.98,1075.67,1872.98z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="846.05"
                            y="1700.64"
                            transform="matrix(0.2344 -0.9721 0.9721 0.2344 -1066.8767 2177.9734)"
                            width="6.52"
                            height="131.36"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="829.46"
                            y="1744.22"
                            transform="matrix(0.2344 -0.9721 0.9721 0.2344 -1097.5842 2176.021)"
                            width="6.52"
                            height="81.26"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="843.99"
                            y="1701.21"
                            transform="matrix(0.2344 -0.9721 0.9721 0.2344 -1041.4646 2154.7358)"
                            width="6.52"
                            height="74.71"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                d="M1666.11,624.36c-3.73,2.94-8.46,4.58-13.08,4.94l-2.28,0.18c-0.69,0.07-1.39,0.12-2.08,0.15
						c-1.39,0.06-2.79,0.08-4.18-0.02l-16.75-1.57l-8.37-0.86l-1.05-0.11c-0.32-0.04-0.78-0.07-0.9-0.1
						c-0.4-0.08-0.8-0.12-1.21-0.13c-0.83-0.05-1.68,0.08-2.52,0.32c-0.85,0.23-1.69,0.62-2.47,1.16c-0.4,0.26-0.75,0.57-1.11,0.9
						l-0.53,0.54l-0.67,0.82l-5.35,6.53c-3.65,4.3-7.2,8.67-10.92,12.92c1.95-5.3,4.08-10.51,6.11-15.77l3.17-7.82l0.39-0.98
						c0.17-0.38,0.4-0.81,0.6-1.22c0.45-0.79,0.95-1.6,1.52-2.33c1.13-1.5,2.51-2.84,4.11-3.95c1.58-1.1,3.35-2,5.26-2.6
						c0.95-0.29,1.94-0.53,2.93-0.67l2.22-0.36l8.31-1.39l16.62-2.64c1.39-0.18,2.78-0.27,4.17-0.28c0.69-0.02,1.39,0,2.09,0.01
						l1.05,0.02l0.52,0.02l0.24,0l0.08-0.02c0.93,0.05,1.65-0.29,2.33-0.76c0.65-0.52,1.16-1.3,1.46-2.11
						c0.25-0.83,0.22-1.63-0.01-2.44c-0.02-0.2-0.2-0.39-0.25-0.63c0.01-0.01-0.08-0.18-0.16-0.34l-0.24-0.47l-0.47-0.94l-1.88-3.72
						l-3.76-7.45l-7.53-14.9l-15.04-29.8l-0.47-0.93c-0.17-0.32-0.29-0.53-0.56-1.16c-0.46-1.08-0.97-2.12-1.25-3.28
						c-0.34-1.13-0.62-2.27-0.75-3.46c-0.21-1.17-0.25-2.35-0.24-3.55c0.02-4.77,1.64-9.4,4.33-13.21
						c2.67-3.84,6.66-6.83,11.13-8.42c2.23-0.84,4.58-1.22,6.9-1.35l4.26-0.14l33.37-1.06l16.69-0.54l8.34-0.26
						c0.24,0,0.2-0.02,0.27-0.03l0.17-0.03l0.33-0.01c0.2-0.1,0.43-0.07,0.61-0.17c0.18-0.1,0.39-0.11,0.55-0.24
						c0.08-0.05,0.19-0.09,0.28-0.12c0.08-0.07,0.16-0.15,0.24-0.2c0.69-0.45,1.23-1.24,1.52-2.04c0.32-0.82,0.3-1.64,0.09-2.43
						c-0.03-0.21-0.14-0.41-0.23-0.62c0.03-0.05-0.43-0.92-0.71-1.48l-1.88-3.72l-3.76-7.45l-15.04-29.8l-7.53-14.9l-0.95-1.86
						l-0.24-0.47l-0.12-0.26l-0.18-0.4l-0.69-1.61c-0.48-1.06-0.73-2.23-1.05-3.36c-0.25-1.16-0.4-2.35-0.52-3.53
						c-0.3-4.75,0.88-9.53,3.27-13.55c2.42-3.99,6.07-7.31,10.4-9.29c2.19-0.92,4.49-1.62,6.81-1.86l1.75-0.16
						c0.33-0.03,0.48-0.02,0.66-0.04l0.52-0.02l2.08-0.07l16.68-0.52l4.17-0.13l2.1-0.07l0.49-0.02l0.16-0.01
						c0.12,0,0.22-0.02,0.33-0.03c0.83-0.12,1.63-0.5,2.24-1.1c0.17-0.14,0.3-0.3,0.43-0.46l0.19-0.26
						c0.08-0.13,0.04-0.04,0.28-0.45l14.91-25.12c1.4-2.41,2.88-4.76,4.21-7.21c0.67-1.21,1.29-2.47,1.98-3.67
						c0.7-1.21,1.48-2.36,2.34-3.48c2.75-3.54,5.51-7.07,8.28-10.6c0.45-0.59,0.92-1.17,1.37-1.76c0.46-0.58,0.92-1.17,1.38-1.75
						l12.52,7.43c-0.29,0.69-0.57,1.37-0.87,2.05c-0.29,0.69-0.57,1.37-0.87,2.05c-1.78,4.12-3.55,8.23-5.34,12.34
						c-0.59,1.28-1.21,2.52-1.94,3.71c-0.72,1.18-1.53,2.33-2.28,3.49c-1.5,2.35-2.87,4.77-4.3,7.16l-14.91,25.12
						c-0.12,0.2-0.53,0.87-0.83,1.32c-0.33,0.49-0.68,0.97-1.04,1.44c-0.73,0.92-1.52,1.8-2.39,2.61
						c-3.43,3.24-7.87,5.38-12.54,6.07c-0.58,0.08-1.16,0.17-1.76,0.19l-0.88,0.06l-0.54,0.02l-2.09,0.06l-4.17,0.13l-16.69,0.54
						l-2.09,0.06l-0.52,0.02c-0.17,0-0.36,0.01-0.38,0.03c-0.11,0.02-0.22,0.02-0.33,0.04c-0.46,0.02-0.83,0.18-1.22,0.3
						c-0.71,0.35-1.36,0.94-1.85,1.68c-0.43,0.77-0.63,1.63-0.58,2.43c0.04,0.19,0.05,0.41,0.09,0.6c0.1,0.2,0.06,0.41,0.2,0.62
						c0.05,0.1,0.08,0.2,0.12,0.31l0.02,0.08l0.12,0.21l0.24,0.47l0.93,1.87l7.54,14.9l15.04,29.8l3.77,7.44l1.88,3.73
						c0.34,0.7,0.55,1.01,1.1,2.28c0.48,1.07,0.9,2.14,1.19,3.29c1.29,4.55,1.14,9.56-0.49,13.97c-1.57,4.43-4.42,8.37-8.29,11.14
						l-1.47,1c-0.51,0.31-1.03,0.59-1.57,0.85c-1.04,0.6-2.16,0.99-3.28,1.39s-2.28,0.61-3.44,0.87c-0.57,0.09-1.15,0.14-1.73,0.2
						l-0.87,0.08c-0.27,0.01-0.64,0.05-0.76,0.04l-8.34,0.27l-16.68,0.52l-33.38,1.05l-4.07,0.14c-0.47,0.07-0.89,0.09-1.27,0.26
						c-0.73,0.28-1.47,0.74-1.95,1.5c-0.49,0.72-0.83,1.58-0.8,2.38c0.01,0.21-0.02,0.4,0.05,0.61c-0.01,0.2,0.07,0.4,0.13,0.59
						c0,0.22,0.18,0.42,0.22,0.63c-0.01,0.01,0.21,0.43,0.34,0.72l0.47,0.93l15.06,29.81l7.53,14.9l3.76,7.45l1.88,3.72l0.71,1.4
						c0.07,0.14,0.14,0.29,0.28,0.59c0.46,1.08,0.97,2.12,1.28,3.26c1.42,4.49,1.43,9.51-0.01,13.98
						C1672.59,617.44,1669.84,621.46,1666.11,624.36z"
                            />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1916.16,402.58c-8.2,0-16.29-2.2-23.53-6.5c-21.94-13.16-29.1-41.53-16.15-63.34c12.96-21.82,41.3-29.12,63.16-16.27
					c10.81,6.41,18.33,16.48,21.39,28.44c3.05,11.97,1.26,24.4-5.05,35.02l0,0c-6.3,10.62-16.36,18.14-28.33,21.19
					C1923.85,402.1,1919.99,402.58,1916.16,402.58z M1916.3,316.67c-13.6,0-26.81,6.95-34.2,19.4
					c-11.13,18.74-4.97,43.11,13.71,54.32c9.29,5.51,19.97,7.05,30.24,4.43c10.28-2.62,18.92-9.08,24.33-18.2l0,0
					c5.42-9.12,6.96-19.81,4.34-30.09s-9.09-18.92-18.21-24.34C1930.14,318.44,1923.17,316.67,1916.3,316.67z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1892.46,395.98l-187.17-111.12c-10.62-6.3-18.15-16.37-21.2-28.33c-3.05-11.96-1.26-24.4,5.04-35.02
					c6.3-10.62,16.37-18.15,28.33-21.2c11.96-3.05,24.4-1.26,35.02,5.05l187.17,111.12l-3.31,5.61
					c-18.79-11.04-43.13-4.77-54.26,13.98c-11.13,18.74-4.97,43.11,13.71,54.32L1892.46,395.98z M1728.96,205.38
					c-3.29,0-6.6,0.41-9.88,1.25c-10.28,2.62-18.92,9.09-24.34,18.21c-5.42,9.12-6.95,19.8-4.33,30.08s9.09,18.92,18.21,24.34
					l167.03,99.16c-7.5-13.85-7.75-31.21,0.84-45.68c8.59-14.48,23.95-22.56,39.71-22.61l-167.03-99.16
					C1742.95,207.27,1736,205.38,1728.96,205.38z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1892.45,395.97c-21.76-13.05-28.92-41.42-15.96-63.23c12.96-21.82,41.3-29.12,63.16-16.27l-3.3,5.62
					c-18.79-11.04-43.13-4.77-54.26,13.98c-11.13,18.74-4.97,43.11,13.71,54.32L1892.45,395.97z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <rect
                            className="st0"
                            x="1751.12"
                            y="328.58"
                            transform="matrix(0.8599 0.5105 -0.5105 0.8599 429.3422 -858.569)"
                            width="55.09"
                            height="48.48"
                        />
                    </g>
                    <g>
                        <path
                            className="st0"
                            d="M1791.11,392.19l-52.98-31.45l28.08-47.29l52.98,31.45L1791.11,392.19z M1747.07,358.46l41.76,24.79l21.42-36.08
					l-41.76-24.8L1747.07,358.46z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M365.24,524.88c-188.93-98.97-231.29-349.94-237.86-399.24l6.46-0.86c6.5,48.74,48.34,296.85,234.43,394.33
					L365.24,524.88z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M131.56,129.29l-1.71-0.8c-0.54-0.25-53.36-26.2-17.47-112.72l2.04-4.93l3.45,4.06c1.59,1.87,38.77,46.13,34.03,82.92
					c-1.62,12.56-7.95,22.78-18.8,30.38L131.56,129.29z M116.33,23.45c-26.48,68.86,6.53,93.42,14.65,98.24
					c8.31-6.36,13.17-14.65,14.46-24.68C149.01,69.35,125.39,35.32,116.33,23.45z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M152.04,171.55c-4.78,0-9.69-0.82-14.69-2.46l-1.79-0.59l-0.38-1.85c-0.12-0.58-11.06-58.4,79.67-81.62l5.17-1.32
					l-1.18,5.2c-0.54,2.39-13.63,58.7-45.9,76.98C166.29,169.66,159.3,171.55,152.04,171.55z M141.29,163.48
					c10.08,2.83,19.63,1.74,28.43-3.25c24.28-13.76,37.31-53.06,41.36-67.42C140.11,112.95,140.27,154.1,141.29,163.48z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M167.78,233.11c-4.79,0-9.69-0.82-14.69-2.46l-1.79-0.59l-0.39-1.85c-0.12-0.58-11.06-58.4,79.67-81.62l5.17-1.32
					l-1.18,5.2c-0.54,2.39-13.63,58.7-45.9,76.98C182.03,231.23,175.04,233.11,167.78,233.11z M157.03,225.04
					c10.07,2.83,19.62,1.74,28.42-3.25c24.26-13.74,37.3-53.06,41.36-67.43C155.84,174.51,156.01,215.66,157.03,225.04z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M190.95,299.03c-4.78,0-9.69-0.82-14.69-2.46l-1.79-0.59l-0.38-1.85c-0.12-0.58-11.06-58.4,79.68-81.62l5.17-1.32
					l-1.18,5.2c-0.54,2.39-13.63,58.7-45.9,76.98C205.2,297.15,198.21,299.03,190.95,299.03z M180.2,290.96
					c10.07,2.83,19.63,1.74,28.42-3.25c24.26-13.74,37.31-53.06,41.37-67.43C179.01,240.43,179.18,281.58,180.2,290.96z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M221.9,363.91c-4.79,0-9.69-0.82-14.69-2.46l-1.79-0.59l-0.38-1.85c-0.12-0.58-11.06-58.4,79.69-81.61l5.17-1.32
					l-1.18,5.2c-0.54,2.39-13.64,58.69-45.91,76.97C236.15,362.02,229.16,363.91,221.9,363.91z M211.14,355.83
					c10.07,2.83,19.63,1.74,28.43-3.25c24.26-13.74,37.31-53.05,41.37-67.42C209.96,305.3,210.12,346.45,211.14,355.83z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M259.15,422.6c-4.79,0-9.69-0.82-14.69-2.46l-1.79-0.59l-0.38-1.85c-0.12-0.58-11.06-58.4,79.69-81.61l5.17-1.32
					l-1.18,5.2c-0.54,2.39-13.64,58.69-45.9,76.97C273.41,420.72,266.42,422.6,259.15,422.6z M248.43,414.54
					c10.06,2.82,19.61,1.73,28.4-3.25c24.26-13.74,37.31-53.05,41.37-67.42C247.91,363.86,247.57,405.31,248.43,414.54z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M110.64,196.19c-0.48,0-0.97-0.01-1.46-0.02c-37.07-1.14-74.9-44.85-76.5-46.71l-3.47-4.05l5.19-1.24
					c91.1-21.76,108.37,34.5,108.54,35.07l0.52,1.81l-1.31,1.35C133.29,191.56,122.69,196.19,110.64,196.19z M41.36,149.29
					c10.29,10.82,40.16,39.52,68.03,40.37c10.15,0.33,19.07-3.18,26.66-10.38C132.57,170.5,113.55,134.01,41.36,149.29z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M133.3,273.4c-0.49,0-0.97-0.01-1.46-0.02c-37.07-1.14-74.9-44.85-76.5-46.71l-3.47-4.05l5.19-1.24
					c91.11-21.76,108.38,34.5,108.54,35.07l0.52,1.81l-1.31,1.35C155.95,268.76,145.35,273.4,133.3,273.4z M64.02,226.49
					c10.29,10.82,40.16,39.52,68.03,40.37c10.14,0.3,19.07-3.18,26.66-10.38C155.24,247.71,136.19,211.22,64.02,226.49z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M160.99,342.8c-0.48,0-0.97-0.01-1.46-0.02c-37.07-1.14-74.91-44.85-76.5-46.71l-3.47-4.05l5.19-1.24
					c91.11-21.76,108.38,34.5,108.54,35.07l0.52,1.81l-1.31,1.36C183.64,338.17,173.05,342.8,160.99,342.8z M91.71,295.89
					c10.29,10.82,40.16,39.52,68.03,40.37c10.09,0.28,19.07-3.19,26.66-10.38C182.93,317.11,163.89,280.63,91.71,295.89z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M197.22,417.15c-35.66,0-75.07-35.02-76.8-36.58l-3.97-3.56l4.98-1.9c87.48-33.46,111.95,20.06,112.19,20.6l0.75,1.73
					l-1.12,1.51c-7.9,10.64-18.29,16.68-30.89,17.95C200.66,417.07,198.94,417.15,197.22,417.15z M129,379.27
					c11.61,9.38,44.97,33.94,72.71,31.15c10.06-1.02,18.49-5.64,25.08-13.77C222.2,388.4,198.58,354.71,129,379.27z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M228.36,483.42c-30.29,0-62.31-14.95-64.03-15.76l-4.82-2.28l4.23-3.25c74.28-57.07,113.02-12.77,113.4-12.32l1.21,1.44
					l-0.64,1.77c-4.53,12.45-12.76,21.21-24.47,26.03C245.59,482.2,237.04,483.42,228.36,483.42z M172.18,463.96
					c13.8,5.67,52.79,19.67,78.58,9.06c9.35-3.85,16.11-10.69,20.1-20.36C264.1,446.07,231.83,420.54,172.18,463.96z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1605.82,1812.01c-6.12-24.36-14.04-49.39-23.55-74.38c-20.64-54.31-19.88-103.47,2.26-146.11
					c43.99-84.71,158.99-114.87,160.15-115.17l1.61,6.32c-1.13,0.29-113.37,29.77-155.99,111.88
					c-21.24,40.92-21.89,88.27-1.94,140.76c9.59,25.23,17.59,50.5,23.77,75.11L1605.82,1812.01z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1771.62,1493.43c-11.43,0-21.66-4.29-30.43-12.77l-1.35-1.31l0.46-1.83c0.14-0.58,15.57-57.36,107.33-38.61l5.22,1.07
					l-3.33,4.16c-1.53,1.91-37.91,46.84-74.93,49.19C1773.59,1493.4,1772.6,1493.43,1771.62,1493.43z M1747.19,1477.33
					c7.82,6.94,16.89,10.15,26.99,9.5c27.83-1.76,56.74-31.43,66.67-42.57C1768.18,1431.36,1750.37,1468.45,1747.19,1477.33z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1697.85,1499.34l-1.27-1.39c-0.4-0.44-39.32-44.57,26.85-110.86l3.77-3.77l1.64,5.07c0.75,2.33,18.23,57.43-0.18,89.63
					c-6.29,11-16.04,18.03-28.97,20.91L1697.85,1499.34z M1724.15,1395.7c-50.75,53.55-29.6,88.85-23.94,96.39
					c10.1-2.71,17.76-8.52,22.79-17.3C1736.84,1450.59,1727.99,1410.12,1724.15,1395.7z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1636.52,1535.17l-1.42-1.24c-0.45-0.39-44.1-39.85,14.17-113.18l3.32-4.18l2.2,4.86c1.01,2.23,24.6,55.01,9.94,89.08
					c-5.01,11.64-13.9,19.73-26.42,24.05L1636.52,1535.17z M1650.95,1429.22c-43.92,58.4-19.14,91.63-12.88,98.47
					c9.72-3.83,16.67-10.47,20.66-19.76C1669.77,1482.29,1656.4,1443.11,1650.95,1429.22z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1596.68,1581.69l-1.82-0.49c-0.57-0.15-57.14-16.37-37.1-107.86l1.14-5.21l4.12,3.39c1.89,1.56,46.3,38.56,48.13,75.61
					c0.63,12.65-3.79,23.83-13.14,33.22L1596.68,1581.69z M1563.01,1480.21c-13.91,72.45,22.92,90.8,31.76,94.11
					c7.05-7.72,10.37-16.75,9.88-26.85C1603.27,1519.62,1574.01,1490.29,1563.01,1480.21z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1569.5,1621.28c-17.03,0-55.7-8.11-76.33-77.12l-1.53-5.11l5.24,0.97c2.41,0.45,59.19,11.28,78.75,42.8
					c6.68,10.76,8.23,22.68,4.61,35.43l-0.52,1.81l-1.83,0.46C1577.78,1620.54,1574.62,1621.28,1569.5,1621.28z M1501.09,1547.61
					c22.95,70.11,64.06,68.31,73.4,66.92c2.42-10.17,0.95-19.68-4.38-28.27C1555.42,1562.56,1515.61,1551.1,1501.09,1547.61z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1551.66,1690.05c-19.77,0-50.5-9.76-78.74-59.54l-2.63-4.64l5.33-0.23c2.44-0.1,60.22-2.24,86.33,24.1
					c8.92,9,13.1,20.27,12.42,33.5l-0.1,1.88l-1.68,0.86C1572.38,1686.09,1564.25,1690.05,1551.66,1690.05z M1481.41,1632.12
					c38.05,63.2,77.72,52.25,86.5,48.81c0.09-10.46-3.47-19.39-10.59-26.58C1537.7,1634.54,1496.34,1632.27,1481.41,1632.12z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1735.8,1553.55c-16.74,0-35.35-2.63-48.91-11.92c-10.45-7.16-16.67-17.45-18.49-30.57l-0.26-1.87l1.49-1.16
					c0.47-0.36,47.62-35.57,108.31,35.76l3.45,4.06l-5.19,1.22C1774.89,1549.39,1756.83,1553.55,1735.8,1553.55z M1675.19,1512.16
					c1.88,10.28,7.06,18.38,15.4,24.09c23.02,15.78,64.05,10.23,78.73,7.57C1720.5,1489.45,1683.11,1507.34,1675.19,1512.16z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1658.96,1590.35c-9.04,0-17.93-1.3-25.84-4.65c-11.66-4.94-19.8-13.79-24.19-26.29l-0.63-1.78l1.23-1.43
					c0.39-0.45,39.61-44.32,113.26,13.56l4.19,3.29l-4.84,2.23C1720.43,1576.07,1688.94,1590.35,1658.96,1590.35z M1615.78,1559.12
					c3.88,9.71,10.56,16.63,19.87,20.58c25.67,10.89,64.81-2.69,78.68-8.21C1655.17,1527.43,1622.61,1552.6,1615.78,1559.12z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1621.31,1663.91c-9.03,0-17.93-1.3-25.84-4.65c-11.66-4.94-19.8-13.79-24.19-26.29l-0.63-1.78l1.23-1.43
					c0.39-0.45,39.61-44.32,113.26,13.56l4.19,3.29l-4.84,2.23C1682.79,1649.62,1651.3,1663.91,1621.31,1663.91z M1578.14,1632.67
					c3.88,9.71,10.56,16.63,19.87,20.58c25.67,10.89,64.8-2.69,78.67-8.21C1617.52,1600.99,1584.97,1626.15,1578.14,1632.67z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            d="M1606.99,1732.77c-11.01,0-20.96-4.05-29.65-12.06l-1.39-1.28l0.42-1.84c0.13-0.58,14.24-57.72,106.39-41.12l5.25,0.94
					l-3.23,4.24c-1.49,1.95-36.8,47.72-73.75,50.94C1609.66,1732.71,1608.32,1732.77,1606.99,1732.77z M1583.27,1717.25
					c7.98,6.74,17.1,9.72,27.18,8.84c27.78-2.41,55.98-32.75,65.65-44.13C1603.86,1670.94,1586.33,1708.5,1583.27,1717.25z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M617.35,1633.52c-11.96,0-21.69-9.73-21.69-21.7c0-11.96,9.73-21.7,21.69-21.7c11.96,0,21.7,9.73,21.7,21.7
				C639.04,1623.78,629.31,1633.52,617.35,1633.52z M617.35,1596.64c-8.37,0-15.18,6.81-15.18,15.18c0,8.37,6.81,15.18,15.18,15.18
				c8.37,0,15.18-6.81,15.18-15.18C632.52,1603.45,625.72,1596.64,617.35,1596.64z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M551.02,1844.83c-11.96,0-21.7-9.73-21.7-21.7c0-11.96,9.73-21.7,21.7-21.7c11.96,0,21.7,9.73,21.7,21.7
				C572.72,1835.09,562.98,1844.83,551.02,1844.83z M551.02,1807.95c-8.37,0-15.18,6.81-15.18,15.18c0,8.37,6.81,15.18,15.18,15.18
				c8.37,0,15.18-6.81,15.18-15.18C566.2,1814.76,559.39,1807.95,551.02,1807.95z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1400.51,1458.77c-11.96,0-21.7-9.73-21.7-21.7c0-11.96,9.73-21.7,21.7-21.7s21.7,9.73,21.7,21.7
				C1422.21,1449.04,1412.47,1458.77,1400.51,1458.77z M1400.51,1421.9c-8.37,0-15.18,6.81-15.18,15.18
				c0,8.37,6.81,15.18,15.18,15.18c8.37,0,15.18-6.81,15.18-15.18C1415.69,1428.71,1408.88,1421.9,1400.51,1421.9z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1818.88,1211.32c-11.96,0-21.69-9.73-21.69-21.7c0-11.96,9.73-21.7,21.69-21.7c11.96,0,21.7,9.73,21.7,21.7
				C1840.57,1201.59,1830.84,1211.32,1818.88,1211.32z M1818.88,1174.45c-8.37,0-15.18,6.81-15.18,15.18
				c0,8.37,6.81,15.18,15.18,15.18c8.37,0,15.18-6.81,15.18-15.18C1834.05,1181.26,1827.25,1174.45,1818.88,1174.45z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1030.61,918.81c-11.96,0-21.69-9.73-21.69-21.7s9.73-21.7,21.69-21.7c11.96,0,21.7,9.73,21.7,21.7
				S1042.58,918.81,1030.61,918.81z M1030.61,881.93c-8.37,0-15.18,6.81-15.18,15.18c0,8.37,6.81,15.18,15.18,15.18
				c8.37,0,15.18-6.81,15.18-15.18C1045.79,888.74,1038.98,881.93,1030.61,881.93z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M808.67,453.67c-11.96,0-21.7-9.73-21.7-21.7s9.73-21.7,21.7-21.7c11.96,0,21.69,9.73,21.69,21.7
				S820.64,453.67,808.67,453.67z M808.67,416.8c-8.37,0-15.18,6.81-15.18,15.18s6.81,15.18,15.18,15.18
				c8.37,0,15.18-6.81,15.18-15.18S817.04,416.8,808.67,416.8z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1243.47,123.74c-11.96,0-21.7-9.73-21.7-21.7s9.73-21.7,21.7-21.7s21.7,9.73,21.7,21.7S1255.43,123.74,1243.47,123.74z
				 M1243.47,86.86c-8.37,0-15.18,6.81-15.18,15.18s6.81,15.18,15.18,15.18c8.37,0,15.18-6.81,15.18-15.18
				S1251.84,86.86,1243.47,86.86z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M522.96,178.16c-11.96,0-21.69-9.73-21.69-21.7s9.73-21.7,21.69-21.7c11.96,0,21.7,9.73,21.7,21.7
				S534.92,178.16,522.96,178.16z M522.96,141.29c-8.37,0-15.18,6.81-15.18,15.18c0,8.37,6.81,15.18,15.18,15.18
				c8.37,0,15.18-6.81,15.18-15.18C538.14,148.09,531.33,141.29,522.96,141.29z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1811.22,1851.63c-11.96,0-21.7-9.73-21.7-21.7c0-11.96,9.73-21.7,21.7-21.7s21.7,9.73,21.7,21.7
				C1832.92,1841.9,1823.19,1851.63,1811.22,1851.63z M1811.22,1814.76c-8.37,0-15.18,6.81-15.18,15.18s6.81,15.18,15.18,15.18
				c8.37,0,15.18-6.81,15.18-15.18S1819.59,1814.76,1811.22,1814.76z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M1377.55,1861.83c-11.96,0-21.69-9.73-21.69-21.7c0-11.96,9.73-21.7,21.69-21.7c11.96,0,21.7,9.73,21.7,21.7
				C1399.25,1852.1,1389.52,1861.83,1377.55,1861.83z M1377.55,1824.96c-8.37,0-15.18,6.81-15.18,15.18
				c0,8.37,6.81,15.18,15.18,15.18c8.37,0,15.18-6.81,15.18-15.18C1392.73,1831.77,1385.92,1824.96,1377.55,1824.96z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M480.97,1813.58l-27.53,12.59l-1.2-3.68c-9.78-21.39-35.14-30.84-56.53-21.06c-21.39,9.78-30.84,35.14-21.06,56.53
				l0.09,0.2l1.67,3.07l-3.1,1.58l-176.67,80.77l-72.84-159.33l3.3-1.22c0.46-0.17,0.89-0.37,1.33-0.57
				c21.39-9.78,30.84-35.14,21.06-56.53c-9.78-21.39-35.14-30.84-56.53-21.06c-0.43,0.2-0.87,0.39-1.29,0.63l-3.08,1.71
				l-21.93-47.97l49.16-22.48l1.41,2.81c10.33,20.59,34.86,29.21,55.83,19.62s30.5-33.78,21.69-55.06l-1.2-2.9l157.45-71.98
				l12.6,27.57l-2.96,1.36c-21.45,9.81-30.9,35.17-21.12,56.56c9.78,21.39,35.14,30.84,56.53,21.06l2.96-1.36l1.42,2.94
				L480.97,1813.58z M456.99,1817.37l15.34-7.01l-76.54-167.41c-24.04,9.14-51.4-1.84-62.22-25.51
				c-10.83-23.69-1.21-51.59,21.42-63.75l-7.23-15.81l-145.89,66.7c8.15,23.79-2.9,49.92-26.11,60.54s-50.21,1.87-62.87-19.86
				l-37.61,17.19l16.39,35.85c24.35-10.22,52.68,0.72,63.74,24.9c11.06,24.19,0.8,52.77-22.87,64.5l67.31,147.22l167.82-76.72
				c-9.49-24.11,1.48-51.81,25.32-62.71C416.84,1784.59,444.97,1794.42,456.99,1817.37z"
                    />
                </g>
                <g>
                    <path
                        className="st0"
                        d="M275.49,1789.15c-28.96,13.24-63.29,0.45-76.53-28.51s-0.45-63.29,28.51-76.53c28.96-13.24,63.29-0.45,76.53,28.51
				C317.23,1741.58,304.45,1775.91,275.49,1789.15z M230.17,1690.04c-25.69,11.75-37.04,42.2-25.29,67.89s42.2,37.04,67.89,25.29
				c25.69-11.74,37.03-42.2,25.29-67.89C286.32,1689.64,255.86,1678.29,230.17,1690.04z"
                    />
                </g>
                <g>
                    <path
                        className="st0"
                        d="M264.85,1874.97c-11.87,5.43-25.94,0.18-31.37-11.68s-0.18-25.94,11.68-31.37c11.87-5.43,25.94-0.18,31.37,11.68
				C281.96,1855.48,276.72,1869.55,264.85,1874.97z M247.88,1837.85c-8.6,3.93-12.4,14.13-8.47,22.73
				c3.93,8.6,14.13,12.4,22.73,8.47c8.6-3.93,12.4-14.13,8.47-22.73C266.68,1837.72,256.48,1833.92,247.88,1837.85z"
                    />
                </g>
                <g>
                    <path
                        className="st0"
                        d="M374.06,1740.27c-11.87,5.43-25.94,0.18-31.37-11.68c-5.43-11.87-0.18-25.94,11.68-31.37
				c11.87-5.43,25.94-0.18,31.37,11.68C391.17,1720.77,385.93,1734.84,374.06,1740.27z M357.09,1703.15
				c-8.6,3.93-12.4,14.13-8.47,22.73s14.13,12.4,22.73,8.47c8.6-3.93,12.4-14.13,8.47-22.73
				C375.89,1703.02,365.69,1699.22,357.09,1703.15z"
                    />
                </g>
            </g>
        </g>
    </svg>
);
