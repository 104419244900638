import { lighten } from 'polished';

import colors from '../styles/colors';

const tags = {
    default: {
        color: colors.primary[0],
        backgroundColor: colors.white[0],
        borderColor: colors.black[3],
        '&:hover': {
            backgroundColor: lighten(0.75, colors.black[3]),
        },
        path: {
            fill: colors.secondary[0],
        },
        circle: {
            fill: colors.secondary[0],
        },
    },
    open: {
        color: colors.primary[0],
        backgroundColor: colors.white[0],
        borderColor: colors.primary[0],
        '&:hover': {
            backgroundColor: lighten(0.75, colors.black[3]),
        },
        path: {
            fill: colors.secondary[0],
        },
        circle: {
            fill: colors.secondary[0],
        },
    },
    selected: {
        color: colors.white[0],
        backgroundColor: colors.primary[0],
        borderColor: colors.primary[0],
        '&:hover': {
            backgroundColor: lighten(0.1, colors.primary[0]),
            borderColor: lighten(0.1, colors.primary[0]),
        },
        path: {
            fill: colors.white[0],
        },
        circle: {
            fill: colors.white[0],
        },
    },
    readOnly: {
        color: colors.white[0],
        backgroundColor: colors.secondary[0],
        borderColor: colors.secondary[0],
        '&:hover': {
            backgroundColor: lighten(0.1, colors.secondary[0]),
            borderColor: lighten(0.1, colors.secondary[0]),
        },
        path: {
            fill: colors.white[0],
        },
        circle: {
            fill: colors.white[0],
        },
    },
};

export default tags;
