import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Flex, Icon, Paragraph, ShortPattern, Title } from '../../../@DesignSystem';
import { theme } from '../../../@DesignSystem/@Style/theme';
import { StyleEntity } from '../../../@Typings';

export interface ScoreCardProps {
    style: StyleEntity;
    position?: number;
    width: number | string;
    onClick: (style: StyleEntity) => void;
}

export const ScoreCard = (props: ScoreCardProps) => {
    const { t } = useTranslation();

    const { style, position, width, onClick } = props;

    return (
        <Flex
            position="relative"
            m={2}
            my={3}
            width={width}
            height={120}
            bg="primary.0"
            p={3}
            flexDirection="column"
            overflow="hidden"
            borderBottomLeftRadius={3}
            borderTopRightRadius={3}
            cursor="pointer"
            onClick={onClick.bind(null, style)}
            data-testid="score-card"
        >
            <Background color={theme.colors.terciary[2]} />
            <Flex zIndex={1} position="absolute" bottom={10} right={15} justifyContent="space-between">
                <Title letterSpacing={-10} fontSize={120} lineHeight={1} textColor="terciary.2">
                    {position}
                </Title>
            </Flex>
            <Flex zIndex={1} position="absolute" top={10} left={10} justifyContent="flex-start" alignItems="center">
                <Icon type="star" color={theme.colors.terciary[1]} />
                <Paragraph fontSize={3} lineHeight={1} textColor="terciary.1">
                    {style.score.toFixed(2)}
                </Paragraph>
            </Flex>
            <Flex
                zIndex={2}
                px={3}
                py={1}
                justifyContent="flex-start"
                alignItems="end"
                flex="1"
                data-testid={`style-${t(`styles.${style.type}.${style.body}`)}`}
            >
                <Paragraph fontSize={3} fontWeight={900} textColor="white.0">
                    {t(`styles.${style.type}.${style.body}`)}
                </Paragraph>
            </Flex>
        </Flex>
    );
};

const Background = styled(ShortPattern)`
    mask-image: linear-gradient(to left top, transparent 0%, black 100%);
    z-index: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    transform: translateY(30%) translateX(30%) scale(0.7);

    circle,
    path,
    polygon,
    rect {
        fill: ${(props) => props.color};
    }
`;
