import { theme } from '../../../@Style/theme';
import { IconProps } from '../../../components/Icon/Icon';

export const SpinnerIcon = (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.size || 16} height={props.size || 16} viewBox="0 0 16 16">
        <g fill="transparent" fillRule="evenodd">
            <g transform="translate(-648 -1008) translate(648 1008)">
                <circle
                    cx="8"
                    cy="14"
                    r="2"
                    fill={props.color || theme.colors.primary[0]}
                    fillOpacity=".4"
                    transform="matrix(-1 0 0 1 16 0)"
                />
                <circle
                    cx="8"
                    cy="2"
                    r="2"
                    fill={props.color || theme.colors.primary[0]}
                    transform="matrix(-1 0 0 1 16 0)"
                />
                <circle
                    cx="3"
                    cy="5"
                    r="2"
                    fill={props.color || theme.colors.primary[0]}
                    fillOpacity=".8"
                    transform="matrix(-1 0 0 1 6 0)"
                />
                <circle
                    cx="3"
                    cy="11"
                    r="2"
                    fill={props.color || theme.colors.primary[0]}
                    fillOpacity=".6"
                    transform="matrix(-1 0 0 1 6 0)"
                />
                <circle
                    cx="13"
                    cy="5"
                    r="2"
                    fill={props.color || theme.colors.primary[0]}
                    fillOpacity=".2"
                    transform="matrix(-1 0 0 1 26 0)"
                />
                <circle
                    cx="13"
                    cy="11"
                    r="2"
                    fill={props.color || theme.colors.primary[0]}
                    fillOpacity=".3"
                    transform="matrix(-1 0 0 1 26 0)"
                />
            </g>
        </g>
    </svg>
);
