import styled from 'styled-components';
import { space, SpaceProps } from 'styled-system';

import { RawAccordion, RawAccordionProps } from './RawAccordion';

export interface AccordionProps extends SpaceProps, RawAccordionProps {}

export const Accordion = styled(RawAccordion)<AccordionProps>`
    ${space}

    svg {
        max-height: 100%;
        max-width: 100%;
    }
`;
