import { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { space, SpaceProps } from 'styled-system';

import { Flex } from '../Flex/Flex';
import { icons } from './icons';

export type IconType = keyof typeof icons;

export interface IconProps extends SpaceProps, HTMLAttributes<HTMLElement> {
    color?: string;
    size?: number;
    type: IconType;
}

export const RawIcon: React.FC<IconProps> = (props: IconProps) => (
    <Flex className={props.className} height={props.size || 16} centerContent>
        {icons[props.type](props)}
    </Flex>
);

export const Icon = styled(RawIcon)`
    ${space};
`;
