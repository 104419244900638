import produce, { Draft } from 'immer';
import { ActionType } from 'typesafe-actions';

import { DishEntity, IngredientEntity, StyleEntity } from '../../@Typings';
import { compareIngredients, getSelectedIngredient } from '../domain/domainHelper';
import * as actions from './actions';
import * as type from './constants';

export type HarmonizerActions = ActionType<typeof actions>;

export type HarmonizerState = {
    ingredients: IngredientEntity[];
    characteristics: string[];
    weights: string[];
    styles: StyleEntity[];
    style?: StyleEntity;
    dishes: DishEntity[];
    selectableDishes: DishEntity[];
    dish: DishEntity;
};

export const harmonizerState: HarmonizerState = {
    ingredients: [],
    characteristics: [],
    weights: [],
    styles: [],
    dish: {
        id: '',
        name: '',
        ingredients: [],
        characteristics: [],
        description: '',
        categoryKey: '',
    },
    dishes: [],
    selectableDishes: [],
};

const generateRandomId = () => (Math.random() + 1).toString(36).substring(6);

const harmonizeReducer = (state: HarmonizerState = harmonizerState, action: HarmonizerActions) => {
    return produce(state, (draft: Draft<HarmonizerState>) => {
        switch (action.type) {
            case type.SET_INGREDIENTS:
                draft.ingredients = action.payload;
                return;
            case type.SET_SELECTABLE_DISHES:
                draft.selectableDishes = action.payload;
                return;
            case type.SET_CHARACTERISTICS:
                draft.characteristics = action.payload;
                return;
            case type.SET_WEIGHTS:
                draft.weights = action.payload;
                return;
            case type.SET_STYLE:
                draft.style = action.payload;
                return;

            case type.CREATE_DISH:
                draft.dish = {
                    id: generateRandomId(),
                    ingredients: [],
                    characteristics: [],
                    name: '',
                    description: '',
                    categoryKey: '',
                };
                return;

            case type.SAVE_DISH:
                {
                    const dishToEdit = draft.dishes.find((dish) => dish.id === draft.dish.id);
                    if (dishToEdit) {
                        dishToEdit.ingredients = draft.dish.ingredients;
                        dishToEdit.characteristics = draft.dish.characteristics;
                    } else {
                        draft.dishes.push(draft.dish);
                    }

                    draft.dish = { ...harmonizerState.dish };
                }
                return;
            case type.EDIT_DISH:
                draft.dish = action.payload;
                return;
            case type.REMOVE_DISH:
                draft.dishes = draft.dishes.filter((dish) => dish.id !== action.payload);
                return;
            case type.CLEAR_DISH_LIST:
                draft.dishes = [];
                return;

            case type.ADD_INGREDIENT:
                {
                    const ingredient = draft.ingredients.find((ingredient) =>
                        compareIngredients(ingredient, action.payload)
                    );
                    if (ingredient) {
                        const selected = getSelectedIngredient(ingredient.key, draft.dish.ingredients);
                        if (selected) {
                            selected.cooking = ingredient.cooking;
                        } else {
                            draft.dish.ingredients.push(ingredient);
                        }
                    }
                }
                return;
            case type.REMOVE_INGREDIENT:
                draft.dish.ingredients = draft.dish.ingredients.filter(
                    (ingredient) => ingredient.key !== action.payload.key
                );
                return;

            case type.ADD_CHARACTERISTIC:
                {
                    const characteristic = draft.dish.characteristics.find(
                        (characteristic) => characteristic.key === action.payload.key
                    );
                    if (characteristic) {
                        characteristic.weight = action.payload.weight;
                    } else {
                        draft.dish.characteristics.push(action.payload);
                    }
                }
                return;
            case type.REMOVE_CHARACTERISTIC:
                draft.dish.characteristics = draft.dish.characteristics.filter(
                    (characteristic) => characteristic.key !== action.payload.key
                );
                return;

            case type.ADD_SELECTABLE_DISH:
                draft.dishes.push({ ...action.payload, id: generateRandomId() });
                return;
            case type.REMOVE_SELECTABLE_DISH:
                draft.dishes = draft.dishes.filter((dish) => dish.name !== action.payload.name);
                return;

            case type.HARMONIZE_DISHES:
                draft.dishes = action.payload;
                return;
            case type.HARMONIZE_STYLES:
                draft.styles = action.payload;
                return;
        }
    });
};

export default harmonizeReducer;
