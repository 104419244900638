import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import styled from 'styled-components';

import { ActionBar, Box, Button, Flex, Paragraph, Pattern, Subtitle, Title } from '../../../@DesignSystem';
import { theme } from '../../../@DesignSystem/@Style/theme';
import { Card } from '../../../@DesignSystem/components/Card/Card';
import { Routes } from '../../../@Enums/routes';
import { Header } from '../../components/Header/Header';
import { HarmonizerState } from '../../store/reducer';

export interface DetailsComponentsProps extends RouteComponentProps, ReturnType<typeof mapStateToProps> {}

export const DetailsComponent = (props: DetailsComponentsProps) => {
    window.scrollTo(0, 0);

    const { t } = useTranslation();

    const { style, history } = props;

    useEffect(() => {
        if (!style) {
            history.replace(Routes.dishes);
        }
    }, [style, history]);

    useEffect(() => {
        window.scrollTo(0, 0);
    });

    const onBack = () => history.goBack();

    const sections = [
        ['about', 'primary.0'],
        ['hint', 'primary.0'],
        ['grapes', 'primary.0'],
        ['regions', 'primary.0'],
        ['alcohool', 'primary.0'],
        ['temperature', 'primary.0'],
        ['wood', 'primary.0'],
        // ['harmonization', 'primary.0'],
    ];

    return style ? (
        <Flex flexDirection="column" flex="1" width="100%" position="relative" minWidth={360}>
            <Background color={theme.colors.terciary[3]} />
            <Header />
            <Flex height="100%" maxWidth={{ _: '100%', md: 800 }} m="auto" p={4} flexDirection="column">
                <Title textAlign="center" m={4} fontWeight={900} fontSize={6} textColor="primary.0">
                    {t(`styles.${style.type}.${style.body}`)}
                </Title>
                <Card>
                    {sections.map(([section, color]) => (
                        <Box key={section}>
                            <Subtitle fontSize={4} mt={4} textColor={color}>
                                {t(`pages.details.${section}.title`)}
                            </Subtitle>
                            <Paragraph mt={4}>{t(`pages.details.${section}.${style.type}.${style.body}`)}</Paragraph>
                        </Box>
                    ))}
                </Card>
            </Flex>
            <ActionBar>
                <Button data-testid="components-buttons-back-details" variant="link" onClick={onBack}>
                    {t('components.buttons.back')}
                </Button>
            </ActionBar>
        </Flex>
    ) : null;
};

const Background = styled(Pattern)`
    mask-image: linear-gradient(to left top, transparent 0%, black 75%);
    z-index: -1;
    position: absolute;
    top: 0;
    right: 0;
    transform: scale(2);
`;

const mapStateToProps = (state: HarmonizerState) => ({
    style: state.style,
});

export const Details = connect(mapStateToProps)(withRouter(DetailsComponent));
