import { theme } from '../../../../@Style/theme';
import { IconProps } from '../../../../components/Icon/Icon';

export const Souces = (props: IconProps) => {
    const svgProps = {
        fill: 'none',
        stroke: props.color || theme.colors.primary[0],
        strokeWidth: 17,
        strokeLinecao: 'round',
        strokeMiterlimit: 10,
    } as const;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={props.size || 36} viewBox="0 0 703.496 725.499">
            <g>
                <path
                    {...svgProps}
                    d="M394.116,592.916v31.022v86.022H656.16c15.957,0,28.913-12.935,28.913-28.891v-57.13V171.263H394.138h-0.022
		v0.152v45.565v47.283v190.283"
                />
                <path
                    {...svgProps}
                    d="M685.073,623.937V171.263v-28.217c0-15.957-12.935-28.913-28.913-28.913h-21.435H444.464h-21.435
		c-15.957,0-28.891,12.957-28.891,28.913v28.217v283.261"
                />
                <line {...svgProps} x1="394.138" y1="623.937" x2="394.138" y2="592.894" />
                <path
                    {...svgProps}
                    d="M634.73,114.128H444.468l79.698-95.816c8.026-9.649,22.839-9.649,30.865,0L634.73,114.128z"
                />
                <g>
                    <polyline
                        {...svgProps}
                        points="394.1,216.98 394.1,264.263 394.122,264.263 394.122,216.98 394.122,171.415 		"
                    />
                    <polyline {...svgProps} points="394.116,171.415 394.116,216.98 394.116,264.263 394.116,454.546 		" />
                    <line {...svgProps} x1="394.116" y1="623.937" x2="394.116" y2="592.916" />
                    <polyline {...svgProps} points="232.964,463.828 232.964,264.263 394.094,264.263 394.094,454.546 		" />
                    <polyline {...svgProps} points="394.094,592.959 394.094,709.959 363.377,709.959 256.877,709.959 		" />
                    <line {...svgProps} x1="232.964" y1="652.829" x2="232.964" y2="650.894" />
                    <path
                        {...svgProps}
                        d="M232.964,463.828V264.263v-23.348c0-13.217,10.696-23.935,23.913-23.935h17.739h119.478h0.022"
                    />
                    <path
                        {...svgProps}
                        d="M394.122,171.415v45.565h-119.5l65.935-79.261c6.652-8,18.891-8,25.544,0l28,33.652L394.122,171.415z"
                    />
                </g>
                <g>
                    <path
                        {...svgProps}
                        d="M19.863,423.474c-6.916-3.02-6.45-12.957,0.666-15.47c21.512-7.595,61.467-13.012,117.16,15.733
			c86.89,44.846,167.24,75.678,284.027,18.686c0,0,31.766,135.473-75.678,189.663s-227.035-5.726-249.458-88.818
			C77.059,470.928,56.829,439.615,19.863,423.474z"
                    />
                    <path
                        {...svgProps}
                        d="M306.227,652.82H207.07c-31.557,0-57.139,25.582-57.139,57.139v0h213.435v0
			C363.366,678.402,337.784,652.82,306.227,652.82z"
                    />
                    <path
                        {...svgProps}
                        d="M486.211,511.566c35.62,0,64.495-28.875,64.495-64.495s-28.875-64.495-64.495-64.495
			s-64.495,28.875-64.495,64.495"
                    />
                </g>
            </g>
        </svg>
    );
};
