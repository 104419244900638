import { theme } from '../../../../@Style/theme';
import { IconProps } from '../../../../components/Icon/Icon';

export const Cheese = (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.size || 35.831} viewBox="0 0 35.831 28.598">
        <g transform="translate(-0.01)">
            <g transform="translate(0.01)">
                <path
                    fill={props.color || theme.colors.primary[0]}
                    d="M616.838,355.056a1.5,1.5,0,1,1,1.5-1.5A1.5,1.5,0,0,1,616.838,355.056Zm0-2.545a1.047,1.047,0,1,0,1.048,1.048A1.047,1.047,0,0,0,616.838,352.511Z"
                    transform="translate(-582.505 -333.273)"
                />
                <path
                    fill={props.color || theme.colors.primary[0]}
                    d="M1.507,261.2a1.5,1.5,0,1,1,1.5-1.5A1.5,1.5,0,0,1,1.507,261.2Zm0-2.545a1.048,1.048,0,1,0,1.047,1.047A1.048,1.048,0,0,0,1.507,258.66Z"
                    transform="translate(-0.01 -244.431)"
                />
                <path
                    fill={props.color || theme.colors.primary[0]}
                    d="M45.488,19.625l-1.9.869L43.5,20.24a2.944,2.944,0,1,0-5.356,2.449l.007.014.115.213-.213.107L25.861,28.6l-5.026-11,.227-.084c.031-.011.062-.026.092-.039a2.944,2.944,0,1,0-2.448-5.355c-.03.013-.06.027-.09.043l-.213.118L16.89,8.972l3.393-1.551.1.194a2.943,2.943,0,0,0,5.351-2.446l-.084-.2L36.515,0l.871,1.9-.2.093a2.945,2.945,0,1,0,2.446,5.358l.2-.093.1.2Zm-1.654.262,1.059-.484L39.61,7.848a3.394,3.394,0,0,1-2.818-6.161L36.293.6,26.223,5.2a3.394,3.394,0,0,1-6.141,2.808l-2.6,1.186,1.132,2.475a3.394,3.394,0,0,1,2.821,6.171L26.084,28l11.583-5.3a3.394,3.394,0,0,1,6.165-2.819Z"
                    transform="translate(-15.989)"
                />
                <path
                    fill={props.color || theme.colors.primary[0]}
                    d="M186.862,201.149a3.986,3.986,0,1,1,1.967-5.283A3.986,3.986,0,0,1,186.862,201.149Zm-3.126-6.84a3.536,3.536,0,1,0,4.686,1.746A3.536,3.536,0,0,0,183.736,194.309Z"
                    transform="translate(-171.548 -183.21)"
                />
                <path
                    fill={props.color || theme.colors.primary[0]}
                    d="M232.24,391.858a1.633,1.633,0,1,1,.807-2.165A1.633,1.633,0,0,1,232.24,391.858Zm-1.171-2.561a1.184,1.184,0,1,0,1.569.584,1.184,1.184,0,0,0-1.569-.584Z"
                    transform="translate(-217.659 -367.994)"
                />
                <path
                    fill={props.color || theme.colors.primary[0]}
                    d="M373.5,217.663a1.633,1.633,0,1,1,.806-2.165A1.633,1.633,0,0,1,373.5,217.663Zm-1.174-2.561a1.184,1.184,0,1,0,1.569.587,1.184,1.184,0,0,0-1.569-.587Z"
                    transform="translate(-351.379 -203.095)"
                />
            </g>
        </g>
    </svg>
);
