import colors from './colors';

export const fontWeights: number[] & {
    light?: number;
    regular?: number;
    medium?: number;
    bold?: number;
} = [300, 400, 500, 700, 900];

fontWeights.light = fontWeights[0];
fontWeights.regular = fontWeights[1];
fontWeights.medium = fontWeights[2];
fontWeights.bold = fontWeights[3];

const typography = {
    fontSizeBase: '62.5%',
    fontSizeBaseRem: '1.4rem',
    fontColorBase: colors.black[1],
    fontSizes: [10, 12, 14, 16, 20, 24, 32, 40, 48, 96].map((n: number) => n / 10 + 'rem'),
    fontWeights,
    fonts: {
        noto: 'Noto Sans Display, sans-serif',
    },
};

export default typography;
