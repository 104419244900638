import { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import {
    border,
    BorderProps,
    color,
    layout,
    LayoutProps,
    position,
    PositionProps,
    space,
    SpaceProps,
    typography,
    TypographyProps,
} from 'styled-system';

import { ColorProps, textColor } from '../../@Style/colorFix';
import { theme } from '../../@Style/theme';

export interface BoxProps
    extends HTMLAttributes<HTMLDivElement>,
        BorderProps,
        ColorProps,
        LayoutProps,
        PositionProps,
        SpaceProps,
        TypographyProps {
    cursor?: string;
    shadow?: boolean;
}

export const Box = styled.div<BoxProps>`
    cursor: ${(props) => props.cursor || 'inherit'};

    ${(props) =>
        props.shadow &&
        css`
            box-shadow: 0px 4px 16px ${theme.colors.black[4]};
        `}

    ${border}
    ${color}
    ${layout}
    ${position}
    ${space}
    ${textColor}
    ${typography}
`;
