import { theme } from '../../../../@Style/theme';
import { IconProps } from '../../../../components/Icon/Icon';

export const Bread = (props: IconProps) => {
    const svgProps = {
        fill: 'none',
        stroke: props.color || theme.colors.primary[0],
        strokeWidth: 17,
        strokeLinecap: 'round',
        strokeMiterlimit: 10,
    } as const;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={props.size || 36} viewBox="0 0 768.897 555.5">
            <g>
                <g>
                    <path
                        {...svgProps}
                        d="M596.471,417.508c0,62.779-43.848,113.688-97.929,113.688H120.001c-54.081,0-97.929-50.909-97.929-113.688
			c0-42.927,20.491-80.328,50.755-99.669c11.18-8.238,27.911-19.315,49.86-30.392c4.887-2.456,10.029-4.912,15.401-7.342
			c12.382-5.577,26.094-11,41.111-15.861c0.742-0.256,1.51-0.512,2.252-0.742c17.881-5.705,37.58-10.642,58.992-14.147
			c8.417-1.407,17.089-2.558,26.018-3.479c4.937-0.512,9.977-0.947,15.093-1.279c9.491-0.64,19.264-0.972,29.317-0.998
			c8.928-0.026,17.652,0.23,26.145,0.716c5.116,0.307,10.131,0.691,15.068,1.151c8.954,0.844,17.626,1.944,26.043,3.249
			c22.359,3.505,42.773,8.57,61.218,14.531c2.124,0.665,4.221,1.356,6.294,2.072c12.586,4.298,24.201,8.979,34.817,13.789
			c8.365,3.761,16.091,7.623,23.203,11.486c20.849,11.256,36.301,22.257,46.099,29.957c0.128,0.077,0.255,0.179,0.358,0.307
			C577.949,340.889,596.471,376.678,596.471,417.508z"
                    />
                    <line {...svgProps} x1="151.219" y1="477.11" x2="467.346" y2="477.11" />
                    <g>
                        <path
                            {...svgProps}
                            d="M179.198,264.244v102.585c0,11.358-9.184,20.568-20.543,20.568c-11.358,0-20.568-9.21-20.568-20.568v-86.724
				C150.47,274.528,164.182,269.105,179.198,264.244z"
                        />
                        <path
                            {...svgProps}
                            d="M281.553,244.597v122.232c0,11.358-9.21,20.568-20.543,20.568c-11.358,0-20.568-9.21-20.568-20.568V249.355
				c8.417-1.407,17.089-2.558,26.018-3.479C271.397,245.364,276.437,244.93,281.553,244.597z"
                        />
                        <path
                            {...svgProps}
                            d="M378.126,248.716v118.113c0,11.358-9.21,20.568-20.568,20.568c-11.358,0-20.543-9.21-20.543-20.568V244.316
				c5.116,0.307,10.131,0.691,15.068,1.151C361.037,246.311,369.71,247.411,378.126,248.716z"
                        />
                        <path
                            {...svgProps}
                            d="M480.455,279.107v87.722c0,11.358-9.184,20.568-20.543,20.568c-11.358,0-20.568-9.21-20.568-20.568V263.246
				c2.124,0.665,4.221,1.356,6.294,2.072C458.224,269.617,469.839,274.298,480.455,279.107z"
                        />
                    </g>
                </g>
                <g>
                    <path
                        {...svgProps}
                        d="M709.979,467.321c-39.268,39.269-96.215,47.711-135.304,22.691c13.635-19.519,21.796-44.411,21.796-71.502
			c0-40.829-18.522-76.619-46.355-96.65c-0.102-0.128-0.23-0.23-0.358-0.307c-9.798-7.7-25.25-18.701-46.099-29.957
			c-7.112-3.863-14.838-7.726-23.203-11.487c-10.616-4.81-22.231-9.491-34.817-13.789c-2.072-0.716-4.17-1.407-6.294-2.072
			c-7.521-2.43-15.375-4.707-23.536-6.779c-11.87-3.044-24.431-5.679-37.683-7.752c-8.417-1.305-17.089-2.405-26.043-3.249
			c-4.937-0.46-9.952-0.844-15.068-1.151c-3.402-0.205-6.83-0.358-10.31-0.46l-34.05-34.05
			c-38.245-38.246-33.231-105.246,11.154-149.631c30.341-30.366,71.298-42.313,106.371-34.587
			c13.712,2.072,33.385,6.063,56.741,13.763c5.193,1.714,10.566,3.607,16.066,5.679c12.714,4.835,26.247,10.694,40.292,17.857
			c0.716,0.358,1.433,0.716,2.124,1.074c16.68,8.621,34.101,19.059,51.727,31.722c6.933,4.937,13.891,10.258,20.849,15.938
			c3.837,3.121,7.726,6.37,11.563,9.747c7.163,6.268,14.326,12.945,21.438,20.031c6.344,6.293,12.33,12.663,17.984,19.008
			c3.402,3.837,6.677,7.649,9.849,11.461c5.73,6.933,11.077,13.84,16.117,20.722c13.328,18.291,24.175,36.301,33.001,53.544
			c1.049,1.97,2.047,3.965,2.994,5.935c5.858,11.921,10.77,23.459,14.863,34.357c3.275,8.57,5.986,16.782,8.289,24.533
			c6.779,22.691,9.926,41.418,11.41,53.774c0.051,0.154,0.051,0.307,0.051,0.486C751.039,400.065,738.836,438.464,709.979,467.321z"
                    />
                    <line {...svgProps} x1="415.809" y1="257.47" x2="352.979" y2="194.64" />
                    <g>
                        <path
                            {...svgProps}
                            d="M523.286,63.899l-72.539,72.539c-8.032,8.032-21.038,8.05-29.07,0.018c-8.032-8.032-8.032-21.056,0-29.088
				l61.323-61.323C495.699,50.857,509.23,56.718,523.286,63.899z"
                        />
                        <path
                            {...svgProps}
                            d="M609.554,122.382l-86.431,86.431c-8.032,8.032-21.056,8.031-29.07,0.018c-8.032-8.032-8.032-21.056,0-29.088
				l83.067-83.067c6.946,4.957,13.893,10.275,20.857,15.937C601.83,115.743,605.701,118.999,609.554,122.382z"
                        />
                        <path
                            {...svgProps}
                            d="M674.929,193.582l-83.519,83.519c-8.032,8.032-21.056,8.031-29.088,0c-8.032-8.032-8.014-21.038,0.018-29.07
				l86.63-86.63c3.401,3.835,6.675,7.652,9.841,11.469C664.546,179.798,669.9,186.708,674.929,193.582z"
                        />
                        <path
                            {...svgProps}
                            d="M725.797,287.43l-62.029,62.029c-8.032,8.032-21.038,8.05-29.07,0.018c-8.032-8.032-8.032-21.056,0-29.088
				l73.244-73.244c1.031,1.972,2.026,3.944,2.985,5.916C716.788,264.999,721.69,276.522,725.797,287.43z"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};
