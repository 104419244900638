import { DishEntity, IngredientEntity } from '../../@Typings';

type CookingList = string[];
type IngredientHash = { [key: string]: CookingList };
type CategoryHash = { [key: string]: IngredientHash };

type DishCategoryHash = { [key: string]: DishEntity[] };

export const getCategories = (ingredients: IngredientEntity[]) => {
    const categoryHash: CategoryHash = {};

    return ingredients.reduce((categoryHash, { key, cooking, category }) => {
        if (!category) {
            return categoryHash;
        }

        if (!categoryHash[category]) {
            categoryHash[category] = {};
        }

        if (!categoryHash[category][key]) {
            categoryHash[category][key] = [];
        }

        if (cooking) {
            categoryHash[category][key].push(cooking);
        }

        return categoryHash;
    }, categoryHash);
};

export const getDishCategories = (selectableDishes: DishEntity[]) => {
    const categoryHash: DishCategoryHash = {};

    return selectableDishes.reduce((categoryHash, dish) => {
        const { categoryKey } = dish;

        if (!categoryKey) {
            return categoryHash;
        }

        if (!categoryHash[categoryKey]) {
            categoryHash[categoryKey] = [];
        }

        categoryHash[categoryKey].push(dish);

        return categoryHash;
    }, categoryHash);
};

export const compareIngredients = (a: IngredientEntity, b: Partial<IngredientEntity>) =>
    a.key === b.key && (!a.cooking || a.cooking === b.cooking);

export const getSelectedIngredient = (key: string, ingredients: IngredientEntity[]) => {
    return ingredients.find((item) => item.key === key);
};
