import { theme } from '../../../../@Style/theme';
import { IconProps } from '../../../../components/Icon/Icon';

export const Pie = (props: IconProps) => {
    const svgProps_1 = {
        fill: 'none',
        stroke: props.color || theme.colors.primary[0],
        strokeWidth: 17,
        strokeLinecap: 'round',
        strokeMiterlimit: 10,
    } as const;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={props.size || 36} viewBox="0 0 878.642 729.643">
            <g>
                <path
                    {...svgProps_1}
                    d="M766.423,529.41c-7.566,3.614-18.391,8.783-33.841,16.162c-15.338,7.304-27.829,8.147-37.811,5.712
		c-11.068-2.715-20.076-10.488-25.807-20.338c-2.004-3.465-4.476-6.63-7.304-9.42c-0.019-0.019-0.019-0.019-0.019-0.019
		c-8.147-8.053-19.327-13.016-31.688-13.016c-8.072,0-15.656,2.116-22.211,5.843l-13.634,6.648c0,0,0,0-51.539,24.59
		c-15.338,7.323-27.848,8.147-37.83,5.693c-11.087-2.715-20.057-10.488-25.807-20.357c-2.004-3.427-4.457-6.592-7.266-9.364
		c-0.019-0.019-0.019-0.037-0.019-0.037c-8.147-8.053-19.346-13.016-31.706-13.016c-8.072,0-15.656,2.116-22.211,5.843
		l-13.634,6.648c0,0,0,0-51.52,24.59c-15.338,7.323-27.848,8.147-37.83,5.693c-11.087-2.715-20.076-10.506-25.826-20.376
		c-2.004-3.427-4.457-6.573-7.266-9.345c-0.019-0.019-0.019-0.037-0.019-0.037c-8.147-8.053-19.327-13.016-31.688-13.016
		c-8.09,0-15.675,2.116-22.23,5.843l-13.615,6.648c0,0,0,0-51.539,24.59c-15.338,7.304-27.83,8.147-37.812,5.712
		c-11.068-2.715-20.076-10.488-25.807-20.338c-0.262-0.468-0.543-0.918-0.843-1.386c30.92-156.321,168.794-274.194,334.18-274.194
		C597.629,255.365,735.448,373.163,766.423,529.41z"
                />
                <polygon {...svgProps_1} points="772.922,598.665 709.79,709.422 154.772,709.422 91.641,598.665 	" />
                <path
                    {...svgProps_1}
                    d="M865.044,552.726c0.449,25.264-20.844,45.939-46.108,45.939H60.946c-25.264,0-46.558-20.676-46.108-45.939
		c0.449-24.496,20.47-44.235,45.097-44.235c12.36,0,23.541,4.963,31.687,13.016c0,0,0,0,0.019,0.019
		c2.453,2.416,4.626,5.113,6.461,8.034c0.3,0.468,0.58,0.918,0.843,1.386c5.731,9.851,14.739,17.623,25.807,20.338
		c9.982,2.435,22.473,1.592,37.812-5.712c51.539-24.59,51.539-24.59,51.539-24.59l13.615-6.648c6.555-3.727,14.14-5.843,22.23-5.843
		c12.361,0,23.541,4.963,31.688,13.016c0,0,0,0.019,0.019,0.037c2.809,2.772,5.262,5.918,7.266,9.345
		c5.749,9.87,14.739,17.66,25.826,20.376c9.982,2.453,22.492,1.629,37.83-5.693c51.52-24.59,51.52-24.59,51.52-24.59l13.634-6.648
		c6.555-3.727,14.139-5.843,22.211-5.843c12.361,0,23.56,4.963,31.706,13.016c0,0,0,0.019,0.019,0.037
		c2.809,2.772,5.262,5.937,7.266,9.364c5.75,9.87,14.72,17.642,25.807,20.357c9.982,2.453,22.492,1.629,37.83-5.693
		c51.539-24.59,51.539-24.59,51.539-24.59l13.634-6.648c6.555-3.727,14.14-5.843,22.211-5.843c12.36,0,23.541,4.963,31.688,13.016
		c0,0,0,0,0.019,0.019c2.828,2.79,5.3,5.955,7.304,9.42c5.731,9.851,14.739,17.623,25.807,20.338
		c9.982,2.435,22.473,1.592,37.811-5.712c15.451-7.379,26.275-12.548,33.841-16.162c17.698-8.428,17.698-8.428,17.698-8.428
		l13.615-6.648c6.555-3.727,14.14-5.843,22.23-5.843C844.593,508.491,864.595,528.23,865.044,552.726z"
                />
                <path
                    {...svgProps_1}
                    d="M366.831,227.786l19.524-56.952c4.664-13.606,1.506-28.673-8.228-39.261l-18.135-19.725
		c-9.621-10.465-12.826-25.316-8.377-38.817l17.008-51.615"
                />
                <path
                    {...svgProps_1}
                    d="M424.249,227.786l19.524-56.952c4.664-13.606,1.506-28.673-8.228-39.261l-18.135-19.725
		c-9.621-10.465-12.826-25.316-8.377-38.817l17.008-51.615"
                />
                <path
                    {...svgProps_1}
                    d="M481.594,227.786l19.524-56.952c4.664-13.606,1.506-28.673-8.228-39.261l-18.135-19.725
		c-9.621-10.465-12.826-25.316-8.377-38.817l17.008-51.615"
                />
                <line {...svgProps_1} x1="302.469" y1="387.018" x2="349.643" y2="341.988" />
                <line {...svgProps_1} x1="432.263" y1="403.938" x2="432.263" y2="354.963" />
                <line {...svgProps_1} x1="515.409" y1="341.988" x2="560.821" y2="387.4" />
            </g>
        </svg>
    );
};
