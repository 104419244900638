import { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';

import { Flex, Icon, IconType, Paragraph, Subtitle, theme } from '../../../@DesignSystem';
import { DishEntity } from '../../../@Typings';

export interface RawSelectableDishProps extends HTMLAttributes<HTMLDivElement> {
    dish: DishEntity;
    onSelectDish: (dish: DishEntity) => void;
    variant: 'primary' | 'secondary';
    icon: IconType;
}

export const RawSelectableDish = (props: RawSelectableDishProps) => {
    const { t } = useTranslation();
    const { onSelectDish, icon, variant, dish } = props;
    const { name, ingredients, characteristics } = dish;

    const textColor = theme.colors.primary[0];
    const iconColor = theme.colors[`${variant === 'primary' ? 'secondary' : 'primary'}`][0];
    const backgroundColor = variant === 'primary' ? theme.colors.white[0] : theme.colors.secondary[4];

    const formatedIngredients = ingredients
        .map((ingredient) => `${t(`categories.${ingredient.category}`)} - ${t(`ingredients.${ingredient.key}`)}`)
        .join(', ');

    const formatedCharacteristics = characteristics
        .map((char) => `${t(`characteristics.${char.key}`)} - ${t(`weights.${char.weight}`)}`)
        .join(', ');

    const testIdName = t(`dishes.${name}`);

    return (
        <Flex
            shadow
            p={4}
            mb={2}
            width="100%"
            bg="white.0"
            borderRadius={2}
            flexDirection="column"
            backgroundColor={backgroundColor}
            data-testid={`dish-${testIdName}-selector`}
        >
            <Flex
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
                cursor="pointer"
                onClick={onSelectDish.bind(null, dish)}
            >
                <Flex flexDirection="column" maxWidth="90%">
                    <Subtitle fontSize={3} mb={3} textColor={textColor}>
                        {t(`dishes.${name}`)}
                    </Subtitle>
                    <Paragraph fontSize={1} textColor="black.2">
                        <b>{t('components.dish.ingredients')}: </b>
                        {formatedIngredients}
                    </Paragraph>

                    {characteristics && (
                        <Paragraph fontSize={1} textColor="black.2">
                            <b>{t('components.dish.characteristics')}: </b>
                            {formatedCharacteristics}
                        </Paragraph>
                    )}
                </Flex>
                <Flex ml={2} mr={1} centerContent>
                    <Icon color={iconColor} type={icon} />
                </Flex>
            </Flex>
        </Flex>
    );
};
