import { ButtonHTMLAttributes } from 'react';

import { Icon, IconType } from '../Icon/Icon';

export interface RawButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    icon?: IconType;
    small?: boolean;
    'data-testid'?: string;
}

export const RawButton = (props: RawButtonProps) => {
    const { className, children, type, icon, ...properties } = props;

    return (
        <button data-testid={props['data-testid']} className={className} type={type || 'button'} {...properties}>
            {children && <span>{children}</span>}
            {icon && <Icon type={icon} ml={3} size={16} />}
        </button>
    );
};
