import { theme } from '../../../../@Style/theme';
import { IconProps } from '../../../../components/Icon/Icon';

export const FruitsAndVegetables = (props: IconProps) => {
    const svgProps = {
        fill: 'none',
        stroke: props.color || theme.colors.primary[0],
        strokeWidth: 17,
        strokeMiterlimit: 10,
    } as const;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={props.size || 36} viewBox="0 0 816.001 647.771">
            <g>
                <g>
                    <path
                        {...svgProps}
                        d="M417.039,605.733h-0.021c-7.859,0.995-15.842,1.514-23.97,1.514c-104.362,0-188.962-84.601-188.962-188.962
			c0-3.981,0.124-7.962,0.373-11.881c2.322-37.386,15.51-71.848,36.474-100.235"
                    />
                    <path {...svgProps} d="M579.916,390.064c-3.566-23.846-11.612-46.261-23.244-66.312v-0.021" />
                    <path
                        {...svgProps}
                        d="M435.34,142.08c-59.323,9.641-42.701,67.304-42.701,67.304c80.341,0,149.996,45.712,184.372,112.545
			c1.965,3.82-1.701,8.101-5.77,6.726l-35.835-12.102l-91.788-30.998l-30.355,62.074l-31.719-55.184l-95.203,71.737l28.968-78.627
			l-54.696,15.163l-26.556,7.362c-7.776,2.156-13.865-6.706-9.119-13.232c29.133-40.065,72.279-69.285,122.227-80.461"
                    />
                </g>
                <g>
                    <path
                        {...svgProps}
                        d="M642.015,156.534l-30.246,14.035l2.245-36.606c0,0,53.611-17.725,64.246-59.753
			c0,0,15.105,41.127,1.127,66.757l25.681,69.001c0,0,146.804,45.921,81.449,206.586S583.644,628.39,505.25,633.116
			c-78.393,4.726-99.708-42.776-99.708-42.776s214.057-92.653,252.352-248.442C696.188,186.11,642.015,156.534,642.015,156.534z"
                    />
                    <path
                        {...svgProps}
                        d="M476.622,551.978c-27.638,9.187-73.75-5.75-97.991-15.103c-5.461-2.107-5.304-9.87,0.224-11.799
			c55.781-19.461,239.026-98.333,291.075-308.504"
                    />
                </g>
                <g>
                    <g>
                        <path
                            {...svgProps}
                            d="M188.062,156.074c-51.299-30.751-111.64-40.496-168.683-29.237c-14.888,75.332,6.863,156.428,65.234,214.819
				c34.089,34.089,75.933,55.675,119.851,64.757"
                        />
                        <line {...svgProps} x1="19.373" y1="126.831" x2="222.46" y2="329.919" />
                    </g>
                    <g>
                        <path
                            {...svgProps}
                            d="M274.752,246.16c-10.637-19.408-24.136-37.635-40.559-54.078c-14.142-14.1-29.61-26.085-45.991-35.914
				c-0.083-0.041-0.145-0.166-0.104-0.27c13.913-59.531,50.097-110.416,99.344-143.469c0.041-0.041,0.083-0.041,0.145-0.041
				c0.041,0,0.083,0,0.145,0.041c44.418,29.826,78.24,74.211,94.641,126.329"
                        />
                        <polyline {...svgProps} points="287.593,238.207 287.593,12.378 287.593,12.336 			" />
                    </g>
                </g>
            </g>
        </svg>
    );
};
