import { theme } from '../../../@Style/theme';
import { IconProps } from '../../../components/Icon/Icon';

export const Plus = (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.size || 16} height={props.size || 16} viewBox="0 0 16 16">
        <path
            fill={props.color || theme.colors.primary[0]}
            d="M8 1c.552 0 1 .448 1 1v5h5c.552 0 1 .448 1 1s-.448 1-1 1H9v5c0 .552-.448 1-1 1s-1-.448-1-1V9H2c-.552 0-1-.448-1-1s.448-1 1-1h5V2c0-.552.448-1 1-1z"
        />
    </svg>
);
