import { HTMLAttributes } from 'react';
import styled from 'styled-components';

import { Flex, Logo, RouterLink, ShortPattern } from '../../../@DesignSystem';
import { theme } from '../../../@DesignSystem/@Style/theme';
import FullLogo from '../../assets/logo/harmonizer.png';

export interface HeaderProps extends HTMLAttributes<HTMLDivElement> {}

export const Header = (props: HeaderProps) => {
    const { children } = props;
    return (
        <Flex
            height={160}
            minHeight={160}
            px={4}
            width="100%"
            bg="primary.0"
            flexDirection="column"
            position="relative"
            overflow="hidden"
            borderBottomRightRadius={6}
        >
            <RouterLink to="/">
                <Logo data-testid="logo" height={60} width="auto" src={FullLogo} />
            </RouterLink>
            <Background color={theme.colors.terciary[0]} />
            <Flex flex="1" px={4} width="100%" centerContent>
                {children}
            </Flex>
        </Flex>
    );
};

const Background = styled(ShortPattern)`
    mask-image: linear-gradient(to right, transparent 0%, black 100%);
    z-index: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    transform: translateY(30%) translateX(30%) scale(0.7);

    @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
        transform: translateY(40%) translateX(40%) scale(0.3);
    }
`;
