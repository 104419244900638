import { theme } from '../../../../@Style/theme';
import { IconProps } from '../../../../components/Icon/Icon';

export const Meats = (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.size || 36} viewBox="0 0 36 36">
        <g transform="translate(12590 -5934)">
            <g transform="translate(-12589.831 5934.021)">
                <path
                    fill={props.color || theme.colors.primary[0]}
                    d="M-824.581-804.7h-.081a8.307,8.307,0,0,1-6.4-3.255,6.045,6.045,0,0,1-1.067-3.29,13.833,13.833,0,0,0-.8-3.649,10.453,10.453,0,0,0-1.677-3.028,23.388,23.388,0,0,1-1.76-2.7c-1.634-2.931-3.486-6.252-3.112-9.626.521-4.7,5.611-9.287,11.347-10.226,6.594-1.08,12.05,2.927,14.718,6.686,4.108,5.792,2.659,12.556.72,17.21h0a30.675,30.675,0,0,1-2.994,5.537c-.119.171-.251.357-.387.551-.326.455-.764,1.053-1.277,1.67C-819.664-806.054-822.029-804.7-824.581-804.7Zm-1.449-35.19a12.214,12.214,0,0,0-1.979.161c-5.422.886-10.229,5.176-10.715,9.56-.347,3.133,1.442,6.342,3.02,9.173A22.664,22.664,0,0,0-834-818.375a11.075,11.075,0,0,1,1.779,3.217,14.674,14.674,0,0,1,.837,3.828,5.29,5.29,0,0,0,.93,2.928,7.513,7.513,0,0,0,5.8,2.938c2.35.027,4.537-1.234,6.717-3.851.5-.6.925-1.182,1.242-1.624l.385-.547a29.874,29.874,0,0,0,2.915-5.394c1.865-4.478,3.269-10.971-.638-16.479C-816.463-836.78-820.968-839.894-826.03-839.894Zm12.985,23.161h0Z"
                    transform="translate(839.528 840.661)"
                />
            </g>
            <g transform="translate(-12581.499 5944.854)">
                <path
                    fill={props.color || theme.colors.primary[0]}
                    d="M-684.28-632.383a.63.63,0,0,1-.166-.022c-.739-.2-.937-1.6-1.352-5.037-.172-1.421-.367-3.033-.644-4.681-.255-1.515-.657-2.468-1.194-2.833a1.79,1.79,0,0,0-1.451-.077c-.557.119-1.251.267-1.655-.362a.943.943,0,0,1,.109-1.246,4.065,4.065,0,0,1,2.707-.773c1.179-.014,4.632.04,5.131,1.145a.945.945,0,0,1-.274,1.074c-.686.752-.424,3.781-.215,6.215.24,2.792.448,5.2-.259,6.127A.98.98,0,0,1-684.28-632.383Zm-4.015-13.523a1.874,1.874,0,0,1,1.086.322c.726.493,1.221,1.584,1.516,3.335.28,1.666.476,3.286.649,4.716a18.68,18.68,0,0,0,.78,4.356,1,1,0,0,0,.122-.137c.528-.691.3-3.3.105-5.6-.257-2.98-.5-5.794.411-6.791.124-.137.154-.227.142-.251-.183-.406-2.291-.732-4.506-.7l-.535.008,0-.013c-.548.074-1.412.286-1.617.611-.023.037-.059.094.038.245s.3.149.857.029A4.524,4.524,0,0,1-688.294-645.906Z"
                    transform="translate(690.924 647.44)"
                />
            </g>
            <g transform="translate(-12587.262 5937.252)">
                <path
                    fill={props.color || theme.colors.primary[0]}
                    d="M-781.427-753.523h-.067a6.855,6.855,0,0,1-5.286-2.687,4.987,4.987,0,0,1-.883-2.718,11.255,11.255,0,0,0-.645-2.962,8.376,8.376,0,0,0-1.36-2.454,19.123,19.123,0,0,1-1.442-2.216h0c-1.339-2.4-2.858-5.126-2.549-7.9.429-3.866,4.611-7.639,9.322-8.41,5.416-.886,9.894,2.4,12.083,5.486,5.489,7.736-1.089,17.557-1.856,18.651C-775.391-756.909-777.767-753.523-781.427-753.523Zm-9.02-13.407a18.475,18.475,0,0,0,1.391,2.137,9.107,9.107,0,0,1,1.461,2.643,12.048,12.048,0,0,1,.687,3.141,4.266,4.266,0,0,0,.746,2.358,6.061,6.061,0,0,0,4.677,2.37c3.3.043,5.546-3.166,6.753-4.887.737-1.05,7.047-10.466,1.859-17.777-2.225-3.136-6.595-5.953-11.342-5.176-4.4.719-8.3,4.194-8.691,7.745-.281,2.533,1.175,5.144,2.459,7.446l-.331.184Z"
                    transform="translate(793.7 783.018)"
                />
            </g>
            <g transform="translate(-12564.646 5939.839)">
                <path
                    fill={props.color || theme.colors.primary[0]}
                    d="M-383.285-723.027h-.087a.38.38,0,0,1-.38-.379.381.381,0,0,1,.151-.3l.049-.033c.117-.075.235-.157.351-.244a6.684,6.684,0,0,0,2.731-5.416,6.748,6.748,0,0,0-6.742-6.739,6.673,6.673,0,0,0-2.618.529.379.379,0,0,1-.5-.2.379.379,0,0,1,.2-.5,7.42,7.42,0,0,1,2.915-.589,7.507,7.507,0,0,1,7.5,7.5,7.534,7.534,0,0,1-3.036,6.024c-.118.088-.239.173-.36.252l-.059.042Zm-.262-.718-.006,0Z"
                    transform="translate(390.359 736.9)"
                />
            </g>
            <g transform="translate(-12562.372 5952.676)">
                <path
                    fill={props.color || theme.colors.primary[0]}
                    d="M-348.7-501.2a1.148,1.148,0,0,1-.714-.269,3.126,3.126,0,0,1-.266-.234.38.38,0,0,1-.007-.537.38.38,0,0,1,.537-.007,2.374,2.374,0,0,0,.2.179c.169.132.262.112.293.1a.694.694,0,0,0,.344-.307,7.492,7.492,0,0,1,3-5.315l.1-.075c.1-.072.2-.144.317-.216a.38.38,0,0,1,.524.114.379.379,0,0,1-.114.524c-.132.085-.251.168-.363.253l-.044.031a6.733,6.733,0,0,0-2.676,4.831l-.006.072-.032.065a1.486,1.486,0,0,1-.884.763A1.016,1.016,0,0,1-348.7-501.2Z"
                    transform="translate(349.796 507.935)"
                />
            </g>
            <g transform="translate(-12564.02 5964.103)">
                <path
                    fill={props.color || theme.colors.primary[0]}
                    d="M-378.78-303.291a.371.371,0,0,1-.1-.015.38.38,0,0,1-.261-.469l.023-.08a.38.38,0,0,1,.469-.261.38.38,0,0,1,.261.469l-.023.08A.379.379,0,0,1-378.78-303.291Z"
                    transform="translate(379.159 304.129)"
                />
            </g>
            <g transform="translate(-12562.373 5958.208)">
                <path
                    fill={props.color || theme.colors.primary[0]}
                    d="M-349.425-408.472a.373.373,0,0,1-.1-.012.38.38,0,0,1-.273-.462l.013-.051a.38.38,0,0,1,.462-.273.38.38,0,0,1,.273.462l-.013.051A.38.38,0,0,1-349.425-408.472Z"
                    transform="translate(349.805 409.282)"
                />
            </g>
            <g transform="translate(-12568.333 5957.383)">
                <path
                    fill={props.color || theme.colors.primary[0]}
                    d="M-451.7-412.773a2.267,2.267,0,0,1-.607-.083,2.254,2.254,0,0,1-.988-.578,2.246,2.246,0,0,1-1.143-.018,2.27,2.27,0,0,1-1.648-2.334l.008-.122L-456-416c.5-.6.925-1.182,1.242-1.624l.385-.547a29.887,29.887,0,0,0,2.915-5.394l.045-.108.1-.064c.027-.017.051-.033.074-.05.663-.49,1.1.039,1.4.389a4.907,4.907,0,0,0,.355.4l.162.157-1.582,5.694a2.24,2.24,0,0,1,1.18,1.008,2.247,2.247,0,0,1,.207,1.72A2.263,2.263,0,0,1-451.7-412.773Zm-1.348-1.549.172.219a1.5,1.5,0,0,0,.774.516,1.5,1.5,0,0,0,1.849-1.042h0a1.493,1.493,0,0,0-.137-1.144,1.488,1.488,0,0,0-.9-.708,1.259,1.259,0,0,0-.141-.032l-.423-.065,1.68-6.047c-.094-.1-.175-.2-.251-.29a1.892,1.892,0,0,0-.29-.308.361.361,0,0,0-.069.042l-.014.01a30.669,30.669,0,0,1-2.95,5.432c-.119.171-.251.357-.387.551-.308.43-.717.988-1.194,1.569a1.509,1.509,0,0,0,1.1,1.435,1.478,1.478,0,0,0,.925-.04Z"
                    transform="translate(456.096 423.986)"
                />
            </g>
        </g>
    </svg>
);
