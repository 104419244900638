import { useTranslation } from 'react-i18next';

import { Box, Button, Flex, HeavyParagraph, LightParagraph, Tag } from '../../../@DesignSystem';
import { Card } from '../../../@DesignSystem/components/Card/Card';
import { DishEntity, StyleEntity } from '../../../@Typings';
import { ScoreCard } from '../ScoreCard/ScoreCard';

export interface DishProps {
    onEditDish?: (dish: DishEntity) => void;
    onRemoveDish?: (id: string) => void;
    onSelectStyle?: (style: StyleEntity) => void;
    dish: DishEntity;
    dishNumber: number;
}

export const Dish = (props: DishProps) => {
    const { t } = useTranslation();

    const { dish, dishNumber, onEditDish, onRemoveDish, onSelectStyle } = props;

    const dishName = `${t(`dishCategories.${dish.categoryKey}`)} - ${t(`dishes.${dish.name}`)}`;

    const testIdName = `${t(`dishes.${dish.name}`)}`;

    return (
        <Box data-testid="dish-card">
            <Card data-testid={`dish-${testIdName}-card`}>
                <Flex justifyContent="flex-end" py={3} borderBottom="1px solid lightgrey">
                    <Flex
                        fontWeight={700}
                        fontSize={3}
                        mt={3}
                        mb={3}
                        width="100%"
                        textAlign="center"
                        textColor="primary.0"
                    >
                        {dish.name ? `${dishNumber} - ${dishName}` : `${dishNumber} - ${t(`pages.dishes.createdDish`)}`}
                    </Flex>

                    {onEditDish && onRemoveDish && (
                        <>
                            <Button
                                data-testid={`dish-${testIdName}-edit`}
                                variant="icon"
                                small
                                icon="edit"
                                onClick={onEditDish.bind(null, dish)}
                            />
                            <Button
                                data-testid={`dish-${testIdName}-trash`}
                                variant="icon"
                                small
                                icon="trash"
                                onClick={onRemoveDish.bind(null, dish.id)}
                            />
                        </>
                    )}
                </Flex>

                <HeavyParagraph my={4} textColor="black.2">
                    {t('components.dish.ingredients')}
                </HeavyParagraph>
                <Flex flexWrap="wrap">
                    {dish.ingredients.map(({ key, cooking }) => (
                        <Tag key={key} mr={3} mb={3} variant="readOnly">
                            {t(`ingredients.${key}`)} {t(`cooking.${cooking}`, '')}
                        </Tag>
                    ))}
                    {!dish.ingredients.length && (
                        <HeavyParagraph width="100%" textAlign="center" m={4} textColor="secondary.1">
                            {t('components.dish.noIngredient')}
                        </HeavyParagraph>
                    )}
                </Flex>
                <HeavyParagraph my={4} textColor="black.2">
                    {t('components.dish.characteristics')}
                </HeavyParagraph>
                <Flex mb={3} flexWrap="wrap">
                    {dish.characteristics.map(({ key, weight }) => (
                        <Tag
                            title={`${t(`characteristics.${key}`)}-${t(`weights.${weight}`)}`}
                            key={key}
                            mr={3}
                            mb={3}
                            variant="readOnly"
                        >
                            {t(`characteristics.${key}`)} ({t(`weights.${weight}`)})
                        </Tag>
                    ))}
                    {!dish.characteristics.length && (
                        <HeavyParagraph width="100%" textAlign="center" m={4} textColor="secondary.1">
                            {t('components.dish.noCharacteristic')}
                        </HeavyParagraph>
                    )}
                </Flex>
                {dish.styles && onSelectStyle && (
                    <Box mb={4}>
                        <HeavyParagraph mt={4} textColor="black.2">
                            {t('components.dish.harmonizations')}
                        </HeavyParagraph>
                        <LightParagraph fontSize={1} my={3}>
                            {t('components.dish.hint')}
                        </LightParagraph>
                        <Box overflowX="scroll">
                            <Flex width="max-content" mx={-2}>
                                {dish.styles.map((style, index) => (
                                    <ScoreCard
                                        key={index}
                                        style={style}
                                        position={style.position}
                                        width={200}
                                        onClick={onSelectStyle}
                                    />
                                ))}
                            </Flex>
                        </Box>
                    </Box>
                )}
            </Card>
        </Box>
    );
};
