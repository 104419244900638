import { theme } from '../../../@Style/theme';
import { IconProps } from '../../../components/Icon/Icon';

export const Plate = (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.size || 36} viewBox="0 0 36 36">
        <g transform="translate(12590 -5934)">
            <g transform="translate(-2 -1.732)">
                <g transform="translate(-12588 5937)">
                    <path
                        fill={props.color || theme.colors.primary[0]}
                        d="M-961.226,116.86h-1.2a.959.959,0,0,1-.958-.958V100.081c-2.259-6.175-1.577-10.4-.6-12.852a9.282,9.282,0,0,1,2.735-3.938.642.642,0,0,1,.675-.108.643.643,0,0,1,.312.608V115.9A.96.96,0,0,1-961.226,116.86Zm.4-33.182a.323.323,0,0,0-.1.054,8.956,8.956,0,0,0-2.564,3.734c-.929,2.369-1.572,6.456.64,12.473l.017.046V115.9a.411.411,0,0,0,.411.411h1.2a.411.411,0,0,0,.411-.411V83.791A.327.327,0,0,0-960.831,83.678Z"
                        transform="translate(964.9 -83.128)"
                    />
                </g>
                <g transform="translate(-12558.294 5938.491)">
                    <g transform="translate(0 0)">
                        <path
                            fill={props.color || theme.colors.primary[0]}
                            d="M-226.484,152.2h-2.058a.538.538,0,0,1-.387-.163.536.536,0,0,1-.154-.39l.445-20.939a.322.322,0,0,0-.1-.205l-.035-.026-1.763-2.4-.013-.038a2.15,2.15,0,0,1-.108-.623v-7.187a.273.273,0,0,1,.273-.273.274.274,0,0,1,.273.273v7.187a1.684,1.684,0,0,0,.065.4l1.666,2.266a.858.858,0,0,1,.292.631l-.445,20.939,2.052-.006-.377-17.413-.074-3.519a.855.855,0,0,1,.292-.631l1.666-2.266a1.683,1.683,0,0,0,.065-.4v-7.187a.273.273,0,0,1,.274-.273.274.274,0,0,1,.274.273v7.187a2.153,2.153,0,0,1-.108.623l-.013.038-1.763,2.4-.035.025a.32.32,0,0,0-.1.205l.445,20.939a.538.538,0,0,1-.154.39A.537.537,0,0,1-226.484,152.2Z"
                            transform="translate(230.66 -119.96)"
                        />
                    </g>
                    <g transform="translate(1.946 0.001)">
                        <path
                            fill={props.color || theme.colors.primary[0]}
                            d="M-182.3,126.166a.273.273,0,0,1-.273-.274v-5.639a.273.273,0,0,1,.273-.273.274.274,0,0,1,.273.273v5.639A.274.274,0,0,1-182.3,126.166Z"
                            transform="translate(182.57 -119.98)"
                        />
                    </g>
                    <g transform="translate(3.717 0.001)">
                        <path
                            fill={props.color || theme.colors.primary[0]}
                            d="M-138.537,126.166a.273.273,0,0,1-.273-.274v-5.639a.273.273,0,0,1,.273-.273.273.273,0,0,1,.273.273v5.639A.273.273,0,0,1-138.537,126.166Z"
                            transform="translate(138.81 -119.98)"
                        />
                    </g>
                    <g transform="translate(0 5.64)">
                        <path
                            fill={props.color || theme.colors.primary[0]}
                            d="M-224.649,259.917H-230.4a.273.273,0,0,1-.273-.274.274.274,0,0,1,.273-.273h5.747a.273.273,0,0,1,.273.273A.273.273,0,0,1-224.649,259.917Z"
                            transform="translate(230.67 -259.37)"
                        />
                    </g>
                </g>
                <g transform="translate(-12581.782 5946.308)">
                    <g transform="translate(2.963 2.963)">
                        <path
                            fill={props.color || theme.colors.primary[0]}
                            d="M-728.945,404.54A9.065,9.065,0,0,1-738,395.485a9.065,9.065,0,0,1,9.055-9.055,9.065,9.065,0,0,1,9.055,9.055A9.066,9.066,0,0,1-728.945,404.54Zm0-17.562a8.517,8.517,0,0,0-8.507,8.508,8.517,8.517,0,0,0,8.507,8.508,8.517,8.517,0,0,0,8.508-8.508A8.517,8.517,0,0,0-728.945,386.978Z"
                            transform="translate(738 -386.43)"
                        />
                    </g>
                    <g transform="translate(0 0)">
                        <path
                            fill={props.color || theme.colors.primary[0]}
                            d="M-799.222,337.237a12.032,12.032,0,0,1-12.018-12.018A12.032,12.032,0,0,1-799.222,313.2,12.032,12.032,0,0,1-787.2,325.218,12.032,12.032,0,0,1-799.222,337.237Zm0-23.49a11.484,11.484,0,0,0-11.471,11.471,11.484,11.484,0,0,0,11.471,11.471,11.484,11.484,0,0,0,11.471-11.471A11.484,11.484,0,0,0-799.222,313.747Z"
                            transform="translate(811.24 -313.2)"
                        />
                    </g>
                </g>
            </g>
        </g>
    </svg>
);
