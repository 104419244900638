import { theme } from '../../../../@Style/theme';
import { IconProps } from '../../../../components/Icon/Icon';

export const Condiments = (props: IconProps) => {
    const svgProps = {
        fill: 'none',
        stroke: props.color || theme.colors.primary[0],
    } as const;
    const roundSvgProps = {
        ...svgProps,
        strokeLinecap: 'round',
        strokeWidth: 17,
        strokeMiterlimit: 10,
    } as const;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={props.size || 36} viewBox="0 0 601.96 674.034">
            <g>
                <path
                    {...roundSvgProps}
                    d="M277.167,643.673H25.823c-9.664,0-16.061-10.033-11.965-18.8L128.45,379.075
		c24.299-52.073,99.82-47.471,117.607,7.179l13.116,40.337"
                />
                <g>
                    <path
                        {...roundSvgProps}
                        d="M258.879,119.495c0,34.943-14.399,66.537-37.568,89.188c-23.169-22.651-37.567-54.245-37.567-89.188
			c0-34.978,14.399-66.572,37.567-89.189C244.48,52.923,258.879,84.517,258.879,119.495z"
                    />
                    <polyline
                        {...roundSvgProps}
                        points="221.319,328.331 221.319,321.391 221.319,302.262 221.319,208.688 221.319,93.292 		"
                    />
                    <path
                        {...roundSvgProps}
                        d="M138.138,165.986c24.708,24.708,36.868,57.23,36.501,89.63c-32.399,0.366-64.921-11.793-89.63-36.501
			c-24.733-24.733-36.892-57.255-36.502-89.63C80.883,129.094,113.405,141.253,138.138,165.986z"
                    />
                    <polyline
                        {...roundSvgProps}
                        points="221.311,302.257 174.628,255.608 174.628,255.573 93.071,174.016 		"
                    />
                </g>
                <circle {...svgProps} cx="370.638" cy="268.66" r="13.036" />
                <circle {...svgProps} cx="314.021" cy="294.733" r="13.036" />
                <circle {...svgProps} cx="332.389" cy="247.881" r="13.036" />
                <circle {...svgProps} cx="543.931" cy="594.801" r="13.036" />
                <circle {...svgProps} cx="530.894" cy="531.562" r="13.036" />
                <circle {...svgProps} cx="480.007" cy="581.764" r="13.036" />
                <path
                    {...roundSvgProps}
                    d="M549.955,643.727h-271.42c-23.824,0-42.197-23.636-39.056-50.243l43.034-364.507
		c2.604-22.06,19.304-38.537,39.056-38.537h185.351c19.752,0,36.452,16.478,39.056,38.537l43.034,364.507
		C592.152,620.091,573.779,643.727,549.955,643.727z"
                />
                <rect x="342.248" y="153.813" {...roundSvgProps} width="147.384" height="36.627" />
                <path
                    {...roundSvgProps}
                    d="M506.311,35.075H325.567c-13.577,0-24.583,11.006-24.583,24.583v69.572c0,13.577,11.006,24.583,24.583,24.583
		h180.744c13.577,0,24.583-11.006,24.583-24.583V59.658C530.894,46.081,519.888,35.075,506.311,35.075z"
                />
                <path
                    {...roundSvgProps}
                    d="M271.529,322.009c28.487,3.59,97.381,7.824,125.73-29.546c29.131-38.428,123.544,5.891,159.118,24.552"
                />
                <circle {...svgProps} cx="146.715" cy="446.65" r="13.036" />
                <circle {...svgProps} cx="71.807" cy="599.522" r="13.036" />
                <circle {...svgProps} cx="172.787" cy="493.098" r="13.036" />
                <circle {...svgProps} cx="191.634" cy="403.168" r="13.036" />
                <circle {...svgProps} cx="196.78" cy="450.902" r="13.036" />
            </g>
        </svg>
    );
};
