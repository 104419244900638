import { createGlobalStyle } from 'styled-components';

import typography from './styles/typography';

export default createGlobalStyle`
    html {
        font-size: ${typography.fontSizeBase};
        height: 100%;
        width: 100%;
        min-width: 360px;
    }

    body {
        height: 100%;
        margin: 0;
        padding: 0;
        font-size: ${typography.fontSizeBaseRem};
        font-family: ${typography.fonts.noto};
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: ${typography.fontColorBase};
        min-width: 360px;
    }

    #root {
        height: 100%;
    }

    .router {
        width: 100%;
    }

    div, span {
        box-sizing: border-box;
    }
`;
