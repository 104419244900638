import styled from 'styled-components';

import { RawLoading, RawLoadingProps } from './RawLoading';

export interface LoadingProps extends RawLoadingProps {}

export const Loading = styled(RawLoading)<LoadingProps>`
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;

    & div {
        position: absolute;
        top: 33px;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        background: ${({ theme }) => theme.colors.secondary[0]};
        animation-timing-function: cubic-bezier(0, 1, 1, 0);
    }

    & div:nth-child(1) {
        left: 8px;
        animation: fadeIn 0.6s infinite;
    }

    & div:nth-child(2) {
        left: 8px;
        animation: toRight 0.6s infinite;
    }

    & div:nth-child(3) {
        left: 32px;
        animation: toRight 0.6s infinite;
    }

    & div:nth-child(4) {
        left: 56px;
        animation: fadeOut 0.6s infinite;
    }

    @keyframes fadeIn {
        0% {
            transform: scale(0);
        }
        100% {
            transform: scale(1);
        }
    }

    @keyframes fadeOut {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(0);
        }
    }

    @keyframes toRight {
        0% {
            transform: translate(0, 0);
        }
        100% {
            transform: translate(24px, 0);
        }
    }
`;
