import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';

import {
    ActionBar,
    Button,
    Flex,
    HeavyParagraph,
    Icon,
    Loading,
    Modal,
    Paragraph,
    Subtitle,
} from '../../../@DesignSystem';
import { Card } from '../../../@DesignSystem/components/Card/Card';
import { Routes } from '../../../@Enums/routes';
import { DishEntity } from '../../../@Typings';
import { Dish } from '../../components/Dish/Dish';
import { Header } from '../../components/Header/Header';
import { clearDishList, createDish, editDish, removeDish } from '../../store/actions';
import { harmonize } from '../../store/api';
import { HarmonizerState } from '../../store/reducer';
import { HarmonizerDispatch } from '../../store/store';

export interface DishesComponentsProps
    extends RouteComponentProps,
        ReturnType<typeof mapStateToProps>,
        ReturnType<typeof mapDispatchToProps> {}

export const DishesComponent = (props: DishesComponentsProps) => {
    window.scrollTo(0, 0);

    const { t } = useTranslation();

    const { dishes, history, onCreateDish, onEditDish, onRemoveDish, onClearDishList, onHarmonize } = props;

    const [loading, setLoading] = useState(false);

    const addDish = () => {
        history.push(Routes.ingredients);
        onCreateDish();
    };

    const goToDishSelector = () => {
        history.push(`${Routes.dishes}${Routes.selector}`);
    };

    const onDone = () => {
        setLoading(true);
        onHarmonize(dishes).then(() => {
            setLoading(false);
            history.push(Routes.results);
        });
    };

    const onEdit = (dishToEdit: DishEntity) => {
        onEditDish(dishToEdit);
        history.push(Routes.ingredients);
    };

    const [isModalOn, setIsModalOn] = useState(false);

    const dishTypeModal = (
        <Modal onBackdrop={setIsModalOn.bind(null, false)} minWidth={260}>
            <Flex p={5} alignItems="normal" justifyContent="space-between" flexDirection="column">
                <Subtitle fontSize={3} color="primary.0">
                    {t('components.buttons.addDish')}
                </Subtitle>
                <Paragraph my={4} fontSize={1} textColor="black.2">
                    {t('pages.dishes.modalParagraph')}
                </Paragraph>
                <Button
                    width="100%"
                    variant="primary"
                    data-testid="component-button-ready-dishes"
                    onClick={goToDishSelector}
                >
                    {t('components.buttons.readyDishes')}
                </Button>
                <Button
                    mt={4}
                    width="100%"
                    data-testid="component-button-ingredients"
                    variant="primary"
                    onClick={addDish}
                >
                    {t('components.buttons.customDish')}
                </Button>
            </Flex>
        </Modal>
    );

    return (
        <Flex flexDirection="column" flex="1" width="100%" minWidth={360}>
            <Header>
                <HeavyParagraph textAlign="center" fontWeight={900} textColor="white.0">
                    {t(`pages.dishes.${!dishes.length ? 'header' : 'hint'}`)}
                </HeavyParagraph>
            </Header>
            <Flex
                height="100%"
                maxWidth={{ _: '100%', md: 800 }}
                m={{ _: 0, md: 'auto' }}
                p={4}
                flexDirection="column"
                width="100%"
            >
                {isModalOn && dishTypeModal}
                <Flex mt={4} alignItems="center" justifyContent="space-between">
                    <Flex>
                        <Paragraph fontWeight="bold" textColor="secondary.0">
                            {dishes.length}
                        </Paragraph>{' '}
                        <Paragraph ml={2} fontWeight="bold" textColor="black.0">
                            {t('pages.dishes.selection')}
                        </Paragraph>
                    </Flex>
                    <Button
                        data-testid="clear-list"
                        ml={2}
                        variant="link"
                        p={0}
                        m={0}
                        height="100%"
                        onClick={onClearDishList}
                    >
                        {t('components.buttons.clearList')}
                    </Button>
                </Flex>
                <Flex mt={4} justifyContent="space-evenly">
                    <Button
                        mr={2}
                        width="100%"
                        data-testid="component-button-add-dish"
                        variant="primary"
                        onClick={setIsModalOn.bind(null, true)}
                    >
                        {t('components.buttons.addDish')}
                    </Button>
                    {!!dishes.length && (
                        <Button
                            data-testid="component-button-harmonize"
                            ml={2}
                            width="100%"
                            variant="primary"
                            onClick={onDone}
                        >
                            {t('components.buttons.harmonize')}
                        </Button>
                    )}
                </Flex>
                {!dishes.length && (
                    <Card data-testid="page-dish-no-dish" centerContent>
                        <Flex height={56} width={56} p={3} borderRadius={2} centerContent alignItems="flex-end">
                            <Icon type="plate" size={50} />
                        </Flex>
                        <HeavyParagraph textColor="primary.0" textAlign="center">
                            {t('pages.dishes.noPlate')}
                        </HeavyParagraph>
                    </Card>
                )}

                {dishes.map((dish, index) => (
                    <Dish
                        dishNumber={index + 1}
                        key={`dish-${index}`}
                        dish={dish}
                        onEditDish={onEdit}
                        onRemoveDish={onRemoveDish}
                    />
                ))}
            </Flex>
            <ActionBar>
                <Button
                    variant="secondary"
                    data-testid="secondary-component-button-add-dish"
                    height="100%"
                    onClick={setIsModalOn.bind(null, true)}
                >
                    {t('components.buttons.addDish')}
                </Button>
                {!!dishes.length && (
                    <Button
                        data-testid="secondary-component-button-harmonize"
                        variant="primary"
                        height="100%"
                        ml={3}
                        onClick={onDone}
                    >
                        {t('components.buttons.harmonize')}
                    </Button>
                )}
            </ActionBar>
            {loading && <Loading loadingText={t('loading.harmonize')} />}
        </Flex>
    );
};

const mapStateToProps = (state: HarmonizerState) => ({
    dishes: state.dishes,
});

const mapDispatchToProps = (dispatch: HarmonizerDispatch) => ({
    onCreateDish: () => dispatch(createDish()),
    onEditDish: (dish: DishEntity) => dispatch(editDish(dish)),
    onRemoveDish: (id: string) => dispatch(removeDish(id)),
    onClearDishList: () => dispatch(clearDishList()),
    onHarmonize: (dishes: DishEntity[]) => dispatch(harmonize(dishes)),
});

export const Dishes = connect(mapStateToProps, mapDispatchToProps)(withRouter(DishesComponent));
