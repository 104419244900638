import { theme } from '../../../../@Style/theme';
import { IconProps } from '../../../../components/Icon/Icon';

export const Wines = (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.size || 14.696} viewBox="0 0 14.696 36.487">
        <g transform="translate(-328.25 -73.57)">
            <g transform="translate(328.262 88.742)">
                <path
                    fill={props.color || theme.colors.primary[0]}
                    d="M328.757,448.207a.217.217,0,0,1-.217-.217V428.407a.217.217,0,1,1,.435,0V447.99A.217.217,0,0,1,328.757,448.207Z"
                    transform="translate(-328.54 -428.19)"
                />
            </g>
            <g transform="translate(328.25 75.503)">
                <path
                    fill={props.color || theme.colors.primary[0]}
                    d="M335.911,153.314h-6.194a1.535,1.535,0,0,1-1.467-1.534V132.16a4.875,4.875,0,0,1,3.127-4.007.171.171,0,0,0,.018-.117v-5.954l-.007-3.322h3.1v9.275a.173.173,0,0,0,.019.118,4.872,4.872,0,0,1,3.127,4.007v19.525A1.6,1.6,0,0,1,335.911,153.314Zm-6.173-.434h6.172a1.162,1.162,0,0,0,1.29-1.2v-19.5a4.359,4.359,0,0,0-2.867-3.628.514.514,0,0,1-.278-.541v-8.817h-2.232l.006,2.887v5.928a.513.513,0,0,1-.274.54,4.362,4.362,0,0,0-2.871,3.629v19.595A1.11,1.11,0,0,0,329.738,152.88Z"
                    transform="translate(-328.25 -118.76)"
                />
            </g>
            <g transform="translate(330.919 73.57)">
                <path
                    fill={props.color || theme.colors.primary[0]}
                    d="M393.916,75.938H391.39a.761.761,0,0,1-.76-.76V74.33a.761.761,0,0,1,.76-.76h2.526a.761.761,0,0,1,.76.76v.847A.762.762,0,0,1,393.916,75.938ZM391.39,74a.326.326,0,0,0-.326.326v.847a.326.326,0,0,0,.326.326h2.526a.326.326,0,0,0,.326-.326V74.33a.326.326,0,0,0-.326-.326Z"
                    transform="translate(-390.63 -73.57)"
                />
            </g>
            <g transform="translate(328.25 93.269)">
                <path
                    fill={props.color || theme.colors.primary[0]}
                    d="M337.634,541.914H328.25V534h9.384Zm-8.95-.434H337.2v-7.045h-8.516Z"
                    transform="translate(-328.25 -534)"
                />
            </g>
            <g transform="translate(337.199 90.563)">
                <path
                    fill={props.color || theme.colors.primary[0]}
                    d="M539.294,480.844a3.837,3.837,0,0,1-1.744-.413.217.217,0,1,1,.2-.388,3.4,3.4,0,0,0,1.549.367,3.452,3.452,0,0,0,3.448-3.448v-5.766h-5.1a.217.217,0,0,1,0-.435h5.529v6.2A3.887,3.887,0,0,1,539.294,480.844Z"
                    transform="translate(-537.43 -470.76)"
                />
            </g>
            <g transform="translate(338.846 100.211)">
                <path
                    fill={props.color || theme.colors.primary[0]}
                    d="M576.127,704.827a.217.217,0,0,1-.217-.217V696.5a.217.217,0,0,1,.435,0v8.113A.217.217,0,0,1,576.127,704.827Z"
                    transform="translate(-575.91 -696.28)"
                />
            </g>
            <g transform="translate(337.193 108.324)">
                <path
                    fill={props.color || theme.colors.primary[0]}
                    d="M542.2,886.345h-4.7a.217.217,0,0,1,0-.435h4.7a.217.217,0,0,1,0,.435Z"
                    transform="translate(-537.28 -885.91)"
                />
            </g>
        </g>
    </svg>
);
