import { theme } from '../../../@Style/theme';
import { IconProps } from '../../../components/Icon/Icon';

export const ChevronDown = (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.size || 16} height={props.size || 16} viewBox="0 0 24 24">
        <path
            fill={props.color || theme.colors.primary[0]}
            d="M6.293.294a1,1,0,0,1,0,1.41l-3.88,3.88,3.88,3.88a1,1,0,1,1-1.41,1.41L.293,6.284a1,1,0,0,1,0-1.41L4.883.284A1,1,0,0,1,6.293.294Z"
            transform="translate(0 24) rotate(-90) translate(8.417 6.416)"
        />
    </svg>
);
