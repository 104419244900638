import { ReactNode } from 'react';

import { Flex } from '../Flex/Flex';

export interface CardProps {
    children: ReactNode;
    centerContent?: boolean;
    'data-testid'?: string;
}

export const Card = (props: CardProps) => {
    return (
        <Flex
            data-testid={props['data-testid']}
            centerContent={props.centerContent}
            my={4}
            p={4}
            alignContent="space-around"
            flexDirection="column"
            bg="white.0"
            borderRadius={3}
            shadow
            width="100%"
        >
            {props.children}
        </Flex>
    );
};
