import { theme } from '../../../../@Style/theme';
import { IconProps } from '../../../../components/Icon/Icon';

export const Base = (props: IconProps) => {
    const svgProps = {
        fill: 'none',
        stroke: props.color || theme.colors.primary[0],
        strokeWidth: 17,
        strokeMiterlimit: 10,
    } as const;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={props.size || 36} viewBox="0 0 851.538 499.352">
            <g>
                <path
                    {...svgProps}
                    d="M830.816,454.84L830.816,454.84c0,12.047-9.766,21.812-21.812,21.812H42.533
		c-12.046,0-21.812-9.766-21.812-21.812v0c0-12.046,9.766-21.812,21.812-21.812h766.471
		C821.051,433.028,830.816,442.794,830.816,454.84z"
                />
                <polyline {...svgProps} points="66.425,476.652 130.666,476.652 720.916,476.652 785.157,476.652 	" />
                <path
                    {...svgProps}
                    d="M66.425,433.029C77.663,244.287,234.24,94.744,425.769,94.744S773.92,244.287,785.157,433.029"
                />
                <line {...svgProps} x1="720.916" y1="476.652" x2="130.666" y2="476.652" />
                <path {...svgProps} d="M304.083,184.952c37.119-16.767,78.314-26.099,121.686-26.099" />
                <path {...svgProps} d="M133.857,406.106c13.135-79.468,57.939-148.24,121.005-192.936" />
                <circle {...svgProps} cx="425.769" cy="57.018" r="34.318" />
            </g>
        </svg>
    );
};
