import styled from 'styled-components';
import { space, SpaceProps } from 'styled-system';

import { RawSelectableDish, RawSelectableDishProps } from './RawSelectableDish';

export interface SelectableDishProps extends SpaceProps, RawSelectableDishProps {}

export const SelectableDish = styled(RawSelectableDish)<SelectableDishProps>`
    ${space}
`;
