import { theme } from '../../../../@Style/theme';
import { IconProps } from '../../../../components/Icon/Icon';

export const Dessert = (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.size || 36.489} viewBox="0 0 36.489 22.568">
        <g transform="translate(-1107.568 775.1)">
            <g transform="translate(1107.568 -757.761)">
                <path
                    fill={props.color || theme.colors.primary[0]}
                    d="M1139.637-377.909h-27.65a2.07,2.07,0,0,1-1.449-.7l-2.772-3.465a.686.686,0,0,1-.146-.729.686.686,0,0,1,.661-.341h35.063a.687.687,0,0,1,.661.341.687.687,0,0,1-.146.729l-2.772,3.465A2.07,2.07,0,0,1,1139.637-377.909Zm-31.459-4.622a.435.435,0,0,0,.056.088l2.772,3.465a1.471,1.471,0,0,0,.982.472h27.65a1.469,1.469,0,0,0,.982-.472l2.772-3.465a.435.435,0,0,0,.056-.088.425.425,0,0,0-.1-.011h-35.063A.425.425,0,0,0,1108.178-382.531Z"
                    transform="translate(-1107.568 383.14)"
                />
            </g>
            <g transform="translate(1113.821 -770.383)">
                <path
                    fill={props.color || theme.colors.primary[0]}
                    d="M1272.6-655.24a.3.3,0,0,1-.287-.215l-3.48-11.9a.763.763,0,0,0-.671-.5h-14.509a.762.762,0,0,0-.671.5l-3.48,11.9a.3.3,0,0,1-.371.2.3.3,0,0,1-.2-.371l3.48-11.9a1.357,1.357,0,0,1,1.245-.933h14.509a1.357,1.357,0,0,1,1.245.933l3.479,11.9a.3.3,0,0,1-.2.371A.319.319,0,0,1,1272.6-655.24Z"
                    transform="translate(-1248.916 668.46)"
                />
            </g>
            <g transform="translate(1123.154 -775.1)">
                <path
                    fill={props.color || theme.colors.primary[0]}
                    d="M1462.568-769.785a2.661,2.661,0,0,1-2.658-2.658,2.661,2.661,0,0,1,2.658-2.658,2.66,2.66,0,0,1,2.658,2.658A2.661,2.661,0,0,1,1462.568-769.785Zm0-4.717a2.062,2.062,0,0,0-2.06,2.06,2.062,2.062,0,0,0,2.06,2.06,2.061,2.061,0,0,0,2.059-2.06A2.061,2.061,0,0,0,1462.568-774.5Z"
                    transform="translate(-1459.91 775.1)"
                />
            </g>
            <g transform="translate(1115.72 -766.711)">
                <path
                    fill={props.color || theme.colors.primary[0]}
                    d="M1297-580.521a8.229,8.229,0,0,1-5.035-1.991.3.3,0,0,1-.037-.422.3.3,0,0,1,.422-.037c1.474,1.235,5.338,3.671,9.365-.357,4.226-4.226,8.547-1.124,10.219.418a.3.3,0,0,1,.107.229.3.3,0,0,1-.3.3h-.117l-.088-.08c-1.538-1.421-5.533-4.309-9.4-.443A7.167,7.167,0,0,1,1297-580.521Z"
                    transform="translate(-1291.861 585.459)"
                />
            </g>
        </g>
    </svg>
);
