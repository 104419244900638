import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk, { ThunkDispatch } from 'redux-thunk';
import { ActionType } from 'typesafe-actions';

import harmonizeReducer, { harmonizerState } from './reducer';

const middleware = [thunk];
const enhancer = composeWithDevTools(applyMiddleware(...middleware));

type RootAction = ActionType<typeof import('./actions')>;

// review the object type
export type HarmonizerDispatch = ThunkDispatch<object, object, RootAction>;

const store = createStore(harmonizeReducer, harmonizerState, enhancer);

export default store;
