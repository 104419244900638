import { HTMLAttributes } from 'react';

import { Box } from '../Box/Box';
import { Flex } from '../Flex/Flex';
import { Modal } from '../Modal/Modal';
import { HeavyParagraph } from '../Typography/Typography';

export interface RawLoadingProps extends HTMLAttributes<HTMLElement> {
    loadingText: string;
}

export const RawLoading = (props: RawLoadingProps) => {
    return (
        <Modal>
            <Flex minHeight={280} minWidth={280} p={5} flexDirection="column" centerContent>
                <Box className={props.className}>
                    <div />
                    <div />
                    <div />
                    <div />
                </Box>
                <HeavyParagraph>{props.loadingText}</HeavyParagraph>
            </Flex>
        </Modal>
    );
};
