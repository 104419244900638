export const sizes = [0, 10, 20, 30, 40, 50, 60, 80, 100, 200, 400, 600];
export const space = [0, 2, 4, 8, 16, 32, 64, 128, 256];
export const breakpoints: string[] & {
    sm?: string;
    md?: string;
    lg?: string;
    xl?: string;
} = ['360px', '640px', '960px', '1280px'];
export const radii = [0, 5, 10, 15, 20, 30, 60];

breakpoints.sm = breakpoints[0];
breakpoints.md = breakpoints[1];
breakpoints.lg = breakpoints[2];
breakpoints.xl = breakpoints[3];

const layout = { sizes, space, breakpoints, radii };

export default layout;
