import { theme } from '../../../../@Style/theme';
import { IconProps } from '../../../../components/Icon/Icon';

export const Chicken = (props: IconProps) => {
    const svgProps = {
        fill: 'none',
        stroke: props.color || theme.colors.primary[0],
        strokeWidth: 17,
        strokeMiterlimit: 10,
    } as const;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={props.size || 36} viewBox="0 0 895.447 652.401">
            <g>
                <path
                    {...svgProps}
                    d="M872.851,539.667l-110.624,82.044c-0.271,0.213-0.6,0.31-0.949,0.31H149.096c-0.31,0-0.62-0.078-0.872-0.252
		L22.671,539.725c-1.317-0.852-0.697-2.905,0.871-2.905h848.38C873.432,536.821,874.071,538.757,872.851,539.667z"
                />
                <line {...svgProps} x1="477.155" y1="536.821" x2="137.652" y2="536.821" />
                <path
                    {...svgProps}
                    d="M717.632,262.709c47.906,31.137,79.565,85.142,79.565,146.525c0,50.346-21.3,95.715-55.361,127.587H503.18"
                />
                <path
                    {...svgProps}
                    d="M521.285,230.952c-9.779-0.794-19.402-1.762-28.484-2.943c-7.958-1.026-15.491-2.227-22.307-3.602
		c-9.062-1.84-18.55-3.815-28.29-5.867c-3.989-0.833-8.036-1.665-12.083-2.517c-44.594-9.256-92.094-18.512-122.727-17.33
		c-116.898,0-211.664,94.766-211.664,211.664c0,47.402,15.588,91.183,41.922,126.464h339.503"
                />
                <line {...svgProps} x1="741.837" y1="536.821" x2="503.18" y2="536.821" />
                <g>
                    <path
                        {...svgProps}
                        d="M596.985,473.437c-41.922,9.551-87.734-2.328-119.96-35.622c-47.347-48.908-47.525-127.597-0.383-176.711
			c21.907-22.811,50.141-35.682,79.196-38.557c8.53-0.863,17.033-1.835,25.454-3.327c32.095-5.668,99.46-20.894,142.481-53.947
			l28.795,28.795c-43.938,57.22-56.357,157.46-56.74,160.582"
                    />
                    <path
                        {...svgProps}
                        d="M825.34,148.65c-8.599,8.599-21.606,10.05-31.711,4.354l-41.063,41.063l-28.795-28.795l41.063-41.063
			c-5.696-10.077-4.245-23.112,4.354-31.711c10.338-10.338,27.083-10.324,37.421,0.014c3.409,3.409,5.682,7.517,6.846,11.857
			c4.354,1.178,8.462,3.451,11.871,6.86C835.664,121.567,835.678,138.312,825.34,148.65z"
                    />
                </g>
                <g>
                    <path
                        {...svgProps}
                        d="M520.452,231.301h-0.019c-9.759-0.871-19.131-1.956-27.632-3.292c-0.097-0.019-0.174-0.019-0.252-0.039
			c-2.169-0.349-4.279-0.697-6.332-1.084c-13.806-2.537-28.619-5.422-43.723-8.288c-0.097-0.019-0.194-0.039-0.29-0.058
			c-4.124-0.775-8.268-1.549-12.431-2.324c0.116-0.058,0.232-0.136,0.349-0.194c7.881-4.337,16.169-7.823,24.727-10.398
			c8.21-2.498,16.362-5.093,24.34-8.191c30.401-11.734,93.546-39.657,129.407-80.379l33.809,22.694v0.019
			c-10.534,21.203-16.846,46.298-20.564,70.561"
                    />
                    <path
                        {...svgProps}
                        d="M705.057,81.17c-6.781,10.095-19.265,14.025-30.278,10.383l-32.38,48.206l-33.804-22.706l32.38-48.206
			c-7.531-8.791-8.619-21.861-1.838-31.956c8.152-12.136,24.586-15.349,36.722-7.197c4.002,2.688,7.024,6.281,9.002,10.316
			c4.499,0.316,8.968,1.755,12.971,4.444C709.969,52.606,713.209,69.034,705.057,81.17z"
                    />
                </g>
            </g>
        </svg>
    );
};
