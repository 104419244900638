import { Helmet } from 'react-helmet-async';

export const HarmonizeHelmet = () => (
    <Helmet>
        <title>Harmonize</title>
        <link
            href="https://fonts.googleapis.com/css2?family=Noto+Sans+Display:wght@100;400;500;700;900&display=swap"
            rel="stylesheet"
        />
    </Helmet>
);
