export const SET_WEIGHTS = 'SET_WEIGHTS';
export const SET_INGREDIENTS = 'SET_INGREDIENTS';
export const SET_SELECTABLE_DISHES = 'SET_SELECTABLE_DISHES';

export const SET_STYLES = 'SET_STYLES';
export const SET_CHARACTERISTICS = 'SET_CHARACTERISTICS';
export const SET_STYLE = 'SET_STYLE';

export const CREATE_DISH = 'CREATE_DISH';
export const SAVE_DISH = 'SAVE_DISH';
export const EDIT_DISH = 'EDIT_DISH';
export const REMOVE_DISH = 'REMOVE_DISH';
export const CLEAR_DISH_LIST = 'CLEAR_DISH_LIST';

export const ADD_INGREDIENT = 'ADD_INGREDIENT';
export const REMOVE_INGREDIENT = 'REMOVE_INGREDIENT';

export const ADD_MEAL = 'ADD_MEAL';
export const REMOVE_MEAL = 'REMOVE_MEAL';

export const ADD_CHARACTERISTIC = 'ADD_CHARACTERISTIC';
export const REMOVE_CHARACTERISTIC = 'REMOVE_CHARACTERISTIC';

export const ADD_SELECTABLE_DISH = 'ADD_SELECTABLE_DISH';
export const REMOVE_SELECTABLE_DISH = 'REMOVE_SELECTABLE_DISH';


export const HARMONIZE_DISHES = 'HARMONIZE_DISHES';
export const HARMONIZE_STYLES = 'HARMONIZE_STYLES';
