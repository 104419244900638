import { theme } from '../../../../@Style/theme';
import { IconProps } from '../../../../components/Icon/Icon';

export const Pasta = (props: IconProps) => {
    const svgProps = {
        fill: 'none',
        stroke: props.color || theme.colors.primary[0],
        strokeWidth: 17,
        strokeMiterlimit: 10,
    } as const;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={props.size || 36} viewBox="0 0 932.03 751.016">
            <g>
                <polygon {...svgProps} points="892.327,579.328 810.19,723.376 105.073,723.376 22.936,579.328 	" />
                <path
                    {...svgProps}
                    d="M371.751,579.333H206.04c0-45.755,37.09-82.866,82.845-82.866C334.64,496.467,371.751,533.578,371.751,579.333
		z"
                />
                <path
                    {...svgProps}
                    d="M371.751,579.333H206.04c0-45.755,37.09-82.866,82.845-82.866C334.64,496.467,371.751,533.578,371.751,579.333
		z"
                />
                <path
                    {...svgProps}
                    d="M446.518,579.333H131.272c0-87.057,70.577-157.633,157.613-157.633
		C375.942,421.7,446.518,492.276,446.518,579.333z"
                />
                <path
                    {...svgProps}
                    d="M747.734,579.333H582.043c0-45.755,37.09-82.845,82.845-82.845
		C710.643,496.487,747.734,533.578,747.734,579.333z"
                />
                <path
                    {...svgProps}
                    d="M822.501,579.333H507.275c0-87.057,70.556-157.613,157.613-157.613S822.501,492.276,822.501,579.333z"
                />
                <path
                    {...svgProps}
                    d="M432.488,414.796c14.84-14.03,34.863-22.635,56.911-22.635c38.447,0,70.779,26.178,80.112,61.689"
                />
                <path
                    {...svgProps}
                    d="M367.216,375.418c28.911-35.41,72.905-58.024,122.183-58.024c69.18,0,127.933,44.541,149.15,106.533"
                />
                <path
                    {...svgProps}
                    d="M507.275,579.333H70.495c0-120.624,97.787-218.39,218.391-218.39
		C409.509,360.942,507.275,458.709,507.275,579.333z"
                />
                <path
                    {...svgProps}
                    d="M299.656,17.879h107.869h6.843h39.155l56.182,40.309l361.182-3.497c11.807-0.114,21.439,9.425,21.439,21.233v0
		c0,11.807-9.632,21.347-21.439,21.233l-359.603-3.477l-56.182,40.329h-40.734h-5.264h-22.412"
                />
                <line {...svgProps} x1="329.802" y1="134.008" x2="301.235" y2="134.008" />
                <line {...svgProps} x1="414.37" y1="134.007" x2="414.37" y2="17.872" />
                <line {...svgProps} x1="414.37" y1="53.738" x2="301.241" y2="53.738" />
                <line {...svgProps} x1="329.802" y1="98.133" x2="301.235" y2="98.133" />
                <line {...svgProps} x1="414.368" y1="98.133" x2="386.692" y2="98.133" />
                <line {...svgProps} x1="329.795" y1="53.738" x2="329.795" y2="360.937" />
                <line {...svgProps} x1="357.802" y1="372.057" x2="357.802" y2="243.963" />
                <line {...svgProps} x1="357.802" y1="231.215" x2="357.802" y2="218.554" />
                <line {...svgProps} x1="357.802" y1="204.596" x2="357.802" y2="191.935" />
                <line {...svgProps} x1="386.701" y1="355.454" x2="386.701" y2="53.738" />
            </g>
        </svg>
    );
};
