import { theme } from '../../../../@Style/theme';
import { IconProps } from '../../../../components/Icon/Icon';

export const Risotto = (props: IconProps) => {
    const svgProps = {
        fill: 'none',
        stroke: props.color || theme.colors.primary[0],
        strokeWidth: 17,
        strokeMiterlimit: 10,
    } as const;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={props.size || 36} viewBox="0 0 843.971 522.599">
            <g>
                <polygon {...svgProps} points="827.868,331.069 751.175,494.235 92.796,494.235 16.104,331.069 	" />
                <line {...svgProps} x1="806.434" y1="376.691" x2="37.544" y2="376.691" />
                <path
                    {...svgProps}
                    d="M51.223,331.069l20.331-49.485c1.209-2.943,3.959-4.447,6.578-3.596l25.485,8.275
		c2.463,0.8,5.064-0.481,6.37-3.136l27.751-56.393c1.232-2.504,3.626-3.801,5.986-3.244l62.425,14.742
		c2.427,0.573,4.881-0.816,6.078-3.44l17.313-37.943c1.647-3.609,5.514-4.678,8.287-2.292l20.641,17.759
		c2.316,1.993,5.488,1.615,7.44-0.887l36.284-46.505c1.525-1.955,3.847-2.659,5.951-1.805l61.281,24.882
		c2.874,1.167,5.998-0.597,7.112-4.016l11.576-35.517c1.285-3.942,5.163-5.568,8.18-3.429l18.278,12.959
		c1.037,0.735,2.238,1.054,3.434,0.912l49.415-5.877c1.968-0.234,3.896,0.786,5.094,2.695l19.156,30.538
		c1.439,2.295,3.901,3.266,6.178,2.436l26.989-9.833c2.587-0.942,5.363,0.446,6.652,3.325l7.648,17.094
		c1.131,2.528,3.433,3.943,5.782,3.554l72.892-12.051c2.542-0.42,5.003,1.271,6.012,4.133l21.018,59.588
		c1.463,4.147,5.753,5.513,8.706,2.774l24.639-22.857c2.952-2.739,7.241-1.374,8.705,2.77l8.641,24.458
		c0.793,2.244,2.502,3.815,4.497,4.133l57.764,9.225c4.273,0.682,6.401,6.648,3.887,10.894h0c-2.65,4.474-0.135,10.728,4.403,10.948
		l7.838,0.38c3.988,0.194,6.582,5.169,5.018,9.623l-8.5,24.206"
                />
                <line {...svgProps} x1="164.759" y1="266.69" x2="195.004" y2="287.615" />
                <line {...svgProps} x1="255.682" y1="282.922" x2="285.927" y2="303.846" />
                <line {...svgProps} x1="336.97" y1="282.922" x2="367.215" y2="303.846" />
                <line {...svgProps} x1="429.018" y1="202.429" x2="459.263" y2="223.353" />
                <line {...svgProps} x1="523.476" y1="269.602" x2="553.721" y2="290.527" />
                <line {...svgProps} x1="595.677" y1="239.662" x2="625.922" y2="260.586" />
                <line {...svgProps} x1="123.5" y1="288.496" x2="153.746" y2="309.42" />
                <line {...svgProps} x1="262.643" y1="244.55" x2="229.06" y2="255.698" />
                <line {...svgProps} x1="460.932" y1="264.028" x2="427.349" y2="275.176" />
                <line {...svgProps} x1="546.516" y1="223.353" x2="512.933" y2="234.502" />
                <line {...svgProps} x1="684.61" y1="287.81" x2="651.027" y2="298.958" />
                <line {...svgProps} x1="384.007" y1="223.353" x2="350.424" y2="234.502" />
                <line {...svgProps} x1="295.502" y1="217.211" x2="304.692" y2="257.951" />
                <line {...svgProps} x1="392.687" y1="256.782" x2="401.877" y2="297.522" />
                <line {...svgProps} x1="487.609" y1="243.658" x2="496.799" y2="284.398" />
                <line {...svgProps} x1="591.082" y1="270.156" x2="600.272" y2="310.897" />
                <circle {...svgProps} cx="138.623" cy="44.304" r="32.939" />
                <circle {...svgProps} cx="684.61" cy="160.866" r="16.781" />
                <circle {...svgProps} cx="367.215" cy="92.176" r="16.781" />

                <rect
                    x="196.601"
                    y="82.882"
                    transform="matrix(0.6794 -0.7337 0.7337 0.6794 0.6116 204.056)"
                    {...svgProps}
                    width="74.482"
                    height="36.892"
                />

                <rect
                    x="528.633"
                    y="82.882"
                    transform="matrix(-0.5605 -0.8282 0.8282 -0.5605 799.1124 626.761)"
                    {...svgProps}
                    width="74.482"
                    height="36.892"
                />
            </g>
        </svg>
    );
};
