import { BackgroundColorProps, style } from 'styled-system';

export const textColor = style({
    key: 'colors',
    cssProperty: 'color',
    prop: 'textColor',
});

interface TextColorProps {
    textColor?: string;
}

export type ColorProps = TextColorProps & BackgroundColorProps;
