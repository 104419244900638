import React, { HTMLAttributes, useState } from 'react';

import { theme } from '../../@Style/theme';
import { Button } from '../Button/Button';
import { Flex } from '../Flex/Flex';
import { Icon, IconType } from '../Icon/Icon';
import { Paragraph, Subtitle } from '../Typography/Typography';

export interface RawAccordionProps extends HTMLAttributes<HTMLDivElement> {
    icon: IconType;
    title: string;
    hint?: string;
    isExternal?: boolean;
    variant: 'primary' | 'secondary';
}

export const RawAccordion = (props: RawAccordionProps) => {
    const { icon, title, hint, children, className, variant, isExternal } = props;

    const [isOpen, setIsOpen] = useState(false);

    const onClick = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        setIsOpen(!isOpen);
    };

    const color = theme.colors[variant][0];

    return (
        <Flex flexDirection="column" className={className}>
            <Flex
                border={isOpen ? `2px solid ${color}` : 'none'}
                shadow
                p={3}
                width="100%"
                bg="white.0"
                borderRadius={2}
                flexDirection="column"
                data-testid={`category-${title}`}
            >
                <Flex alignItems="center" width="100%" onClick={onClick} cursor="pointer">
                    <Flex mr={4} height={56} width={56} p={3} bg={color} borderRadius={2} centerContent>
                        <Icon color="white" type={icon} size={36} />
                    </Flex>
                    <Flex flexDirection="column">
                        <Subtitle fontSize={3} mb={3} textColor={color}>
                            {title}
                        </Subtitle>
                        {!!hint && (
                            <Paragraph fontSize={1} textColor="black.2">
                                {hint}
                            </Paragraph>
                        )}
                    </Flex>
                    <Button variant="icon" small icon={isOpen ? 'chevronUp' : 'chevronDown'} ml="auto" />
                </Flex>
                {isOpen && !isExternal && (
                    <Flex m={3} ml={72} flexWrap="wrap">
                        {children}
                    </Flex>
                )}
            </Flex>
            {isOpen && isExternal && (
                <Flex mt={3} flexDirection="column">
                    {children}
                </Flex>
            )}
        </Flex>
    );
};
