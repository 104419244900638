import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';

import { Accordion, ActionBar, Button, Flex, HeavyParagraph, IconType, Selector, Tag } from '../../../@DesignSystem';
import { Loading } from '../../../@DesignSystem/components/Loading/Loading';
import { Routes } from '../../../@Enums/routes';
import { IngredientEntity } from '../../../@Typings';
import { Header } from '../../components/Header/Header';
import { getCategories, getSelectedIngredient } from '../../domain/domainHelper';
import { addIngredient, removeIngredient } from '../../store/actions';
import { HarmonizerState } from '../../store/reducer';
import { HarmonizerDispatch } from '../../store/store';

export interface IngredientsComponentsProps
    extends RouteComponentProps,
        ReturnType<typeof mapStateToProps>,
        ReturnType<typeof mapDispatchToProps> {}

export const IngredientsComponent = (props: IngredientsComponentsProps) => {
    const { t } = useTranslation();

    const { ingredients, dish, history, onAddIngredient, onRemoveIngredient } = props;

    const [loading, setLoading] = useState(!ingredients.length);

    useEffect(() => {
        setLoading(!ingredients.length);
        if (!dish.id) {
            history.push(Routes.dishes);
        }
    }, [ingredients]);

    const categories = getCategories(ingredients);

    const onClick = (key: string, remove: boolean) => {
        remove ? onRemoveIngredient({ key }) : onAddIngredient({ key });
    };

    const onSelectCooking = (key: string, cooking: string) => {
        !cooking ? onRemoveIngredient({ key }) : onAddIngredient({ key, cooking });
    };

    const onContinue = () => {
        history.push(Routes.characteristics);
    };

    const goToDishes = () => {
        history.push(Routes.dishes);
    };

    return (
        <Flex flexDirection="column" flex="1" width="100%" minWidth={360}>
            <Header>
                <HeavyParagraph textAlign="center" fontWeight={900} textColor="white.0">
                    {t('pages.ingredients.header')}
                </HeavyParagraph>
            </Header>
            <Flex height="100%" width="100%" maxWidth={{ _: '100%', md: 800 }} m="auto" flexDirection="column">
                <Flex m={4} flexDirection="column" flex="1">
                    {Object.entries(categories).map(([category, ingredients]) => {
                        const isSelected = dish.ingredients.some((ingredient) => ingredient.category === category);
                        return (
                            <Accordion
                                key={category}
                                variant={isSelected ? 'secondary' : 'primary'}
                                mb={4}
                                title={t(`categories.${category}`)}
                                hint={t('pages.ingredients.category')}
                                icon={category as IconType}
                            >
                                {Object.entries(ingredients).map(([key, cookings]) => {
                                    const ingredient = getSelectedIngredient(key, dish.ingredients);
                                    const cooking =
                                        !!ingredient && ingredient.cooking
                                            ? { label: t(`cooking.${ingredient.cooking}`), value: ingredient.cooking }
                                            : undefined;
                                    return !cookings.length ? (
                                        <Tag
                                            key={key}
                                            title={t(`ingredients.${key}`)}
                                            mr={3}
                                            mb={3}
                                            variant={ingredient ? 'selected' : 'default'}
                                            icon={ingredient ? 'remove' : 'plus'}
                                            onClick={onClick.bind(null, key, !!ingredient)}
                                        >
                                            {t(`ingredients.${key}`)}
                                        </Tag>
                                    ) : (
                                        <Selector
                                            key={key}
                                            title={t(`ingredients.${key}`)}
                                            mr={3}
                                            mb={3}
                                            selected={cooking}
                                            hint={t('pages.ingredients.cooking')}
                                            onChoose={onSelectCooking.bind(null, key)}
                                            options={cookings.map((cooking) => ({
                                                label: t(`cooking.${cooking}`),
                                                value: cooking,
                                            }))}
                                        />
                                    );
                                })}
                            </Accordion>
                        );
                    })}
                </Flex>
            </Flex>

            <ActionBar>
                {dish.ingredients.length ? (
                    <>
                        <HeavyParagraph>
                            {t('pages.ingredients.selection', { number: dish.ingredients.length })}
                        </HeavyParagraph>
                        <Button
                            data-testid="components-buttons-continue-ingredients"
                            variant="primary"
                            height="100%"
                            ml={3}
                            onClick={onContinue}
                        >
                            {t('components.buttons.finalizeIngredients')}
                        </Button>
                    </>
                ) : (
                    <Button
                        data-testid="components-buttons-back-ingredients"
                        variant="secondary"
                        height="100%"
                        ml={3}
                        onClick={goToDishes}
                    >
                        {t('components.buttons.back')}
                    </Button>
                )}
            </ActionBar>

            {loading && <Loading loadingText={t('loading.ingredients')} />}
        </Flex>
    );
};

const mapStateToProps = (state: HarmonizerState) => ({
    ingredients: state.ingredients,
    dish: state.dish,
});

const mapDispatchToProps = (dispatch: HarmonizerDispatch) => ({
    onAddIngredient: (ingredient: IngredientEntity) => dispatch(addIngredient(ingredient)),
    onRemoveIngredient: (ingredient: IngredientEntity) => dispatch(removeIngredient(ingredient)),
});

export const Ingredients = connect(mapStateToProps, mapDispatchToProps)(withRouter(IngredientsComponent));
