import { theme } from '../../../../@Style/theme';
import { IconProps } from '../../../../components/Icon/Icon';

export const Soup = (props: IconProps) => {
    const svgProps = {
        fill: 'none',
        stroke: props.color || theme.colors.primary[0],
        strokeWidth: 17,
        strokeLinecap: 'round',
        strokeMiterlimit: 10,
    } as const;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={props.size || 36} viewBox="0 0 729.959 728.448">
            <g>
                <path
                    {...svgProps}
                    d="M711.253,369.801c-0.24,101.275-43.94,192.323-113.472,255.517c-59.596,14.37-138.113,27.468-230.6,27.677
		c-94.543,0.213-174.661-13.107-235.083-27.677c-28.397-28.495-74.783-78.305-98.189-154.381
		c-9.917-32.235-15.256-66.474-15.256-101.962c0,25.485,155.068,46.15,346.326,46.15
		C554.161,415.125,707.924,394.913,711.253,369.801z"
                />
                <path
                    {...svgProps}
                    d="M597.861,625.318v57.86c0,17.726-14.37,32.096-32.096,32.096h-401.57c-17.726,0-32.096-14.37-32.096-32.096
		v-57.86c60.426,14.568,140.543,27.886,235.083,27.677C459.701,652.789,538.246,639.69,597.861,625.318z"
                />
                <path
                    {...svgProps}
                    d="M711.306,368.975c0,0.266-0.027,0.559-0.053,0.826c-3.329,25.112-157.092,45.325-346.273,45.325
		c-191.258,0-346.326-20.665-346.326-46.15c0-25.485,155.068-46.15,346.326-46.15S711.306,343.49,711.306,368.975z"
                />
                <path {...svgProps} d="M66.668,392.425c0,0,352.874-50.503,588.165,0" />
                <path
                    {...svgProps}
                    d="M224.411,282.396l53.361-58.591c15.912-17.472,15.284-44.364-1.426-61.075l-26.942-26.942
		c-17.278-17.278-17.278-45.291,0-62.568l60.046-60.046"
                />
                <path
                    {...svgProps}
                    d="M310.405,282.396l53.361-58.591c15.912-17.472,15.284-44.364-1.426-61.075l-26.942-26.942
		c-17.278-17.278-17.278-45.291,0-62.568l60.046-60.046"
                />
                <path
                    {...svgProps}
                    d="M403.646,282.396l53.361-58.591c15.912-17.472,15.285-44.364-1.426-61.075l-26.942-26.942
		c-17.278-17.278-17.278-45.291,0-62.568l60.046-60.046"
                />
            </g>
        </svg>
    );
};
