import { useTranslation } from 'react-i18next';

import { Button, Flex, RouterLink, Subtitle, Title } from '../../../@DesignSystem';
import { Routes } from '../../../@Enums/routes';
import { Header } from '../../components/Header/Header';

export const Home = () => {
    const { t } = useTranslation();
    return (
        <Flex flexDirection="column" flex="1" width="100%" minWidth={360}>
            <Header />
            <Flex maxWidth={{ _: '100%', md: 800 }} m={{ _: 4, md: 'auto' }} flexDirection="column">
                <Flex m={5} height="100%" flexDirection="column" flex="1" justifyContent="space-between">
                    <Title lineHeight={1.5} textColor="primary.0" mt={5}>
                        {t('pages.home.title')}
                    </Title>
                    <Subtitle fontSize={4} lineHeight={1.5} textColor="black.2" mt={5}>
                        {t('pages.home.subtitle')}
                    </Subtitle>
                    <Subtitle textColor="primary.0" mt={5}>
                        {t('pages.home.tagline')}
                    </Subtitle>
                    <RouterLink to={Routes.dishes}>
                        <Button
                            data-testid="pages-home-action-button"
                            variant="primary"
                            mt={6}
                            fontSize={4}
                            width="100%"
                        >
                            {t('pages.home.action')}
                        </Button>
                    </RouterLink>
                </Flex>
            </Flex>
        </Flex>
    );
};
