import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { Accordion, ActionBar, Button, Flex, HeavyParagraph, IconType, Loading } from '../../../@DesignSystem';
import { Routes } from '../../../@Enums/routes';
import { DishEntity } from '../../../@Typings';
import { Header } from '../../components/Header/Header';
import { SelectableDish } from '../../components/SelectableDish/SelectableDish';
import { getDishCategories } from '../../domain/domainHelper';
import { addSelectableDish, removeSelectableDish } from '../../store/actions';
import { HarmonizerState } from '../../store/reducer';
import { HarmonizerDispatch } from '../../store/store';

export interface DishSelectorComponentsProps
    extends RouteComponentProps,
        ReturnType<typeof mapStateToProps>,
        ReturnType<typeof mapDispatchToProps> {}

export const DishSelectorComponent = (props: DishSelectorComponentsProps) => {
    const { t } = useTranslation();
    const { selectableDishes, history, dishes, onAddSelectableDish, onRemoveSelectableDish } = props;
    const [loading, setLoading] = useState(!selectableDishes.length);

    const selectedDishes = dishes.filter((dish) => dish.name !== '').map((dish) => dish.name);

    const categories = getDishCategories(selectableDishes);

    const goToDishes = () => {
        history.push(Routes.dishes);
    };

    useEffect(() => {
        setLoading(!selectableDishes.length);
    }, [selectableDishes]);

    const onSelectDish = (dish: DishEntity) => {
        if (!selectedDishes.includes(dish.name)) {
            onAddSelectableDish(dish);
        } else {
            onRemoveSelectableDish(dish);
        }
    };

    return (
        <Flex flexDirection="column" flex="1" width="100%" minWidth={360}>
            <Header>
                <HeavyParagraph textAlign="center" fontWeight={900} textColor="white.0">
                    {t('pages.dishSelector.header')}
                </HeavyParagraph>
            </Header>
            <Flex height="100%" width="100%" maxWidth={{ _: '100%', md: 800 }} m="auto" flexDirection="column">
                <Flex m={4} flexDirection="column" flex="1">
                    {Object.entries(categories).map(([category, dishArray]) => {
                        const isMarked = props.dishes.some((dish) => dish.categoryKey === category);
                        return (
                            <Accordion
                                mb={4}
                                isExternal
                                icon={category as IconType}
                                key={category}
                                variant={isMarked ? 'secondary' : 'primary'}
                                title={t(`dishCategories.${category}`)}
                            >
                                {dishArray.map((dish, index) => {
                                    const isSelected = props.dishes.some((item) => item.name === dish.name);
                                    return (
                                        <SelectableDish
                                            key={index}
                                            dish={dish}
                                            onSelectDish={onSelectDish}
                                            variant={isSelected ? 'secondary' : 'primary'}
                                            icon={isSelected ? 'remove' : 'plus'}
                                        />
                                    );
                                })}
                            </Accordion>
                        );
                    })}
                </Flex>
            </Flex>

            {selectedDishes.length ? (
                <ActionBar>
                    <HeavyParagraph>
                        {t('pages.dishSelector.selection', { number: selectedDishes.length })}
                    </HeavyParagraph>
                    <Button
                        data-testid="components-buttons-continue-dish-selector"
                        variant="primary"
                        height="100%"
                        ml={3}
                        onClick={goToDishes}
                    >
                        {t('components.buttons.continue')}
                    </Button>
                </ActionBar>
            ) : (
                <ActionBar>
                    <Button
                        data-testid="components-buttons-back-dish-selector"
                        variant="secondary"
                        height="100%"
                        ml={3}
                        onClick={goToDishes}
                    >
                        {t('components.buttons.back')}
                    </Button>
                </ActionBar>
            )}

            {loading && <Loading loadingText={t('loading.readyDishes')} />}
        </Flex>
    );
};

const mapStateToProps = (state: HarmonizerState) => ({
    selectableDishes: state.selectableDishes,
    dishes: state.dishes,
});

const mapDispatchToProps = (dispatch: HarmonizerDispatch) => ({
    onAddSelectableDish: (dish: DishEntity) => dispatch(addSelectableDish(dish)),
    onRemoveSelectableDish: (dish: DishEntity) => dispatch(removeSelectableDish(dish)),
});

export const DishSelector = connect(mapStateToProps, mapDispatchToProps)(withRouter(DishSelectorComponent));
