import styled, { css } from 'styled-components';
import { color, layout, LayoutProps, space, SpaceProps, typography, TypographyProps } from 'styled-system';

import { ColorProps, textColor } from '../../@Style/colorFix';

export interface CustomTypographyProps extends SpaceProps, ColorProps, LayoutProps, TypographyProps {}

const commomProps = css`
    line-height: 1.5;
    --webkit-text-size-adjust: none;

    ${space};
    ${color};
    ${textColor};
    ${layout};
    ${typography};
`;

export const Title = styled.h1<CustomTypographyProps>`
    ${({ theme }) => css`
        font-family: ${theme.fonts.noto};
        font-size: ${theme.fontSizes[6]};
        font-weight: ${theme.fontWeights[4]};
        color: ${theme.fontColorBase};
        margin: ${theme.space[0]};
    `};

    ${commomProps};
`;

export const Subtitle = styled.h2<CustomTypographyProps>`
    ${({ theme }) => css`
        font-family: ${theme.fonts.noto};
        font-size: ${theme.fontSizes[5]};
        font-weight: ${theme.fontWeights[3]};
        color: ${theme.fontColorBase};
        margin: ${theme.space[0]};
    `};

    ${commomProps};
`;

export const Paragraph = styled.p<CustomTypographyProps>`
    ${({ theme }) => css`
        font-family: ${theme.fonts.noto};
        font-size: ${theme.fontSizes[2]};
        font-weight: ${theme.fontWeights[1]};
        color: ${theme.fontColorBase};
        margin: ${theme.space[0]};
    `};

    ${commomProps};
`;

export const LightParagraph = styled(Paragraph)`
    font-weight: ${({ theme }) => theme.fontWeights[0]};
    color: ${({ theme }) => theme.colors.black[0]};

    ${commomProps};
`;

export const HeavyParagraph = styled(Paragraph)`
    font-weight: ${({ theme }) => theme.fontWeights[3]};
    color: ${({ theme }) => theme.colors.black[2]};

    ${commomProps};
`;
