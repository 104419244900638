import { theme } from '../../../@Style/theme';
import { IconProps } from '../../../components/Icon/Icon';

export const Star = (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.size || 16} height={props.size || 16} viewBox="0 0 11 11">
        <path
            fill={props.color || theme.colors.primary[0]}
            d="M7.365,9.85,9.267,11a.457.457,0,0,0,.683-.495l-.5-2.163,1.682-1.457a.457.457,0,0,0-.261-.8L8.653,5.9,7.786,3.851a.458.458,0,0,0-.843,0L6.077,5.89l-2.214.188a.457.457,0,0,0-.261.8L5.284,8.338,4.78,10.5A.457.457,0,0,0,5.463,11Z"
            transform="translate(-3.443 -3.573)"
        />
    </svg>
);
